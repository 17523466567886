import React, { useCallback } from 'react';
import FontAwesome from 'components/FontAwesome';
import { ModalManagerContextType } from '../ModalManager/types';
import { replaceMainImage } from '../state';
import { MediaIntegrationId } from '../types';
import {
  DynamicImageIntegration,
  IntegrationPostProcessor,
  IntegrationProps,
  OnAddIntegrationMedia,
  OnDeleteMediaIntegration,
  OnReplaceIntegrationMedia,
} from '../types/integrations';

const INTEGRATION_ID = MediaIntegrationId.DYNAMIC as const;
const INTEGRATION_DATA = { id: INTEGRATION_ID };
const IMAGE_URL_PLACEHOLDER = '%%EpisodeImage%%';

interface UseDynamicImageIntegrationConfig extends IntegrationProps {
  placeholderImageSrc: string;
}

export default function useDynamicEpisodeImageIntegration({
  placeholderImageSrc,
  priority,
}: UseDynamicImageIntegrationConfig): DynamicImageIntegration {
  const openDeleteConfirmationModal = useCallback(
    (modalManager: ModalManagerContextType, onSubmit: () => void) => {
      modalManager.showModal('delete-dynamic-image', {
        details: (
          <>
            We will not automatically add the selected
            <br />
            podcast’s episode art to each video.
          </>
        ),
        icon: (
          <FontAwesome icon="microphone" style={{ height: 50, width: 34 }} />
        ),
        onSubmit,
        title: 'Remove episode art?',
      });
    },
    [],
  );

  const handleDeleteImage: OnDeleteMediaIntegration = useCallback(
    (modalManager, deleteImage) => {
      openDeleteConfirmationModal(modalManager, deleteImage);
    },
    [openDeleteConfirmationModal],
  );

  const handleReplaceImage: OnReplaceIntegrationMedia = useCallback(
    (modalManager, addImagePrompt) => {
      openDeleteConfirmationModal(modalManager, addImagePrompt);
    },
    [openDeleteConfirmationModal],
  );

  const handleAddImage: OnAddIntegrationMedia = useCallback(
    handlers => {
      handlers.accept(placeholderImageSrc, INTEGRATION_DATA);
    },
    [placeholderImageSrc],
  );

  const postProcessor: IntegrationPostProcessor = useCallback(exportState => {
    const slideshow = exportState.slideshow.map(slide => {
      if (slide.integrationData?.id !== INTEGRATION_ID) {
        return slide;
      }

      return {
        ...slide,
        imageSrc: IMAGE_URL_PLACEHOLDER,
        imageType: 'mainImage' as const,
      };
    });

    return {
      ...exportState,
      slideshow,
    };
  }, []);

  return {
    postProcessor,
    priority,
    childViewProps: {
      icon: (
        <FontAwesome icon="microphone" style={{ height: 22, width: 'auto' }} />
      ),
      body:
        "We’ll automatically replace this image with the selected clip's corresponding chapter art, if chapter art is unavailable, we'll use episode art.",
      footer: "If you don't use those, we'll use your podcast cover art.",
    },
    creationType: 'dynamic',
    id: INTEGRATION_ID,
    compatibleIntegrationIds: [INTEGRATION_ID],
    mediaTypeCardProps: {
      icon: (
        <FontAwesome icon="microphone" style={{ height: 35, width: 'auto' }} />
      ),
      info: (
        <>
          <strong>Automatically</strong> add art from the RSS feed into every
          video
        </>
      ),
    },
    onAddMedia: handleAddImage,
    onDeleteMedia: handleDeleteImage,
    onReplaceMedia: handleReplaceImage,
    replaceKeyImage: state =>
      replaceMainImage(state, placeholderImageSrc, {
        integrationData: INTEGRATION_DATA,
      }),
    type: 'media-source',
  };
}

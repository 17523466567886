import { MixpanelWizardStep } from 'redux/modules/mixpanel/types';
import { ClipSelectionMode } from '../ClipVideoStep';
import { Step } from './types';

export const MIXPANEL_WIZARD_STEP_MAP: Record<Step, MixpanelWizardStep> = {
  objective: 'objective',
  aspectRatio: 'Choose Aspect Ratio',
  clipVideo: 'Clip Video',
  uploadVideo: 'Upload Video',
  submitting: 'loading',
  template: 'template',
};

export const CLIP_STEP_CONTINUE_BTN_TEXT: Record<ClipSelectionMode, string> = {
  clip: 'continue with this clip',
  'full-episode': 'continue with full episode',
};

import { Button, Card, PressEvent, Spacer, Text } from '@sparemin/blockhead';
import React from 'react';
import { block } from './utils';

export interface AnimationModuleButtonProps {
  label?: React.ReactNode;
  icon?: React.ReactNode;
  isSelected?: boolean;
  onPress?: (event: PressEvent) => void;
}

const AnimationModuleButton: React.FC<AnimationModuleButtonProps> = ({
  label,
  icon,
  isSelected,
  onPress,
}) => {
  return (
    <Card
      header={<></>}
      footer={<></>}
      className={block('module-card', { selected: isSelected })}
    >
      <Spacer
        as={Button}
        className={block('module-button')}
        orientation="vertical"
        space="28px"
        align="center"
        justify="center"
        onPress={onPress}
      >
        {icon}

        {label && <Text variant="p">{label}</Text>}
      </Spacer>
    </Card>
  );
};

export default AnimationModuleButton;

import { createSelector } from 'reselect';
import { DEFAULT_PROJECT_NAME } from 'utils/constants';
import { getFileNameWithtoutExtension } from 'utils/file';

import {
  podcastDetailEpisodeSelector,
  projectsDetailsSelector,
} from '../entities/selectors';

export const projectHistoryIdsSelector = state =>
  state.getIn(['project', 'history', 'data']);
export const projectHistoryNextTokenSelector = state =>
  state.getIn(['project', 'history', 'nextToken']);
export const projectIdSelector = state => state.getIn(['project', 'id']);
export const isLoadingHistorySelector = state =>
  state.getIn(['project', 'history', 'isFetching']);
export const projectUpdateSelector = state =>
  state.getIn(['project', 'update']);
export const newProjectSettingsSelector = state =>
  state.getIn(['project', 'newProject']);
export const projectDeleteStatusSelector = state =>
  state.getIn(['project', 'deleteProjectStatus']);
export const projectCopyStatusSelector = state =>
  state.getIn(['project', 'copyStatus']);
export const projectsCountSelector = state =>
  state.getIn(['project', 'projectsCount']);
export const redirectIdSelector = state =>
  state.getIn(['project', 'redirectId']);
export const isProjectsTabActiveSelector = state =>
  state.getIn(['project', 'isProjectsTabActive']);
export const projectsPageFetchCountSelector = state =>
  state.getIn(['project', 'projectsPageFetchCount']);

export const projectHistoryHasMoreSelector = createSelector(
  projectHistoryNextTokenSelector,
  nextToken => !!nextToken,
);

export const projectSelector = createSelector(
  [projectsDetailsSelector, projectIdSelector],
  (projects, id) => {
    return id && projects && projects.get(id);
  },
);

export const projectNameSelector = createSelector(
  projectSelector,
  project => project && project.getIn(['projectConfig', 'projectName']),
);

export const projectNameUpdateInProgressSelector = createSelector(
  [projectSelector, projectUpdateSelector],
  (project, projectUpdate) => {
    if (!project || !projectUpdate) return false;
    if (project.get('projectUuid') !== projectUpdate.get('id')) return false;

    return projectUpdate.get('inProgress');
  },
);

export const newProjectAspectRatioSelector = createSelector(
  newProjectSettingsSelector,
  settings =>
    settings && {
      height: settings.getIn(['aspectRatio', 'height']),
      width: settings.getIn(['aspectRatio', 'width']),
    },
);

export const projectAspectRatioDimensionsSelector = createSelector(
  projectSelector,
  project => project && project.getIn(['projectConfig', 'aspectRatio']),
);

export const projectAspectRatioSelector = createSelector(
  projectAspectRatioDimensionsSelector,
  dimensions =>
    dimensions && dimensions.get('width') / dimensions.get('height'),
);

export const projectEmbedConfigurationIdSelector = createSelector(
  projectSelector,
  project =>
    project && project.getIn(['projectConfig', 'embedConfigurationUuid']),
);

export const createDefaultProjectNameSelector = (
  options: {
    podcastId?: string;
    episodeId?: string;
    source?: string | File;
  },
  defaultValue?: string,
) => {
  const { episodeId, source } = options;
  return createSelector(podcastDetailEpisodeSelector(episodeId), episode => {
    const fileName = getFileNameWithtoutExtension(source);
    return fileName || episode?.title || defaultValue || DEFAULT_PROJECT_NAME;
  });
};

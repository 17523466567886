import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCaptionSupportedLanguages } from 'redux/middleware/api/headliner-user-service';
import { captionSupportedLanguagesListSelector } from 'redux/modules/entities';

export default function useCaptionSupportedLanguages() {
  const dispatch = useDispatch();

  const captionSupportedLanguages = useSelector(
    captionSupportedLanguagesListSelector,
  );

  useEffect(() => {
    if (!captionSupportedLanguages.length) {
      dispatch(fetchCaptionSupportedLanguages());
    }
  }, [captionSupportedLanguages.length, dispatch]);

  return captionSupportedLanguages;
}

/**
 * This module contains utilities for converting VideoTemplateState to common
 * redux shapes used throughout the app for text overlays, slideshow, etc. These
 * are the same shapes found in the $.embed redux key.
 */

import _ from 'underscore';

import { ITextOverlayV2 } from 'blocks/TextOverlayModal/v2';
import {
  IEdgeVideos,
  ImageOriginId,
  IVideoClip,
  ProgressAnimationOptions,
  Size,
  Soundwave,
  SourceImageOrigin,
  TimerOptions,
} from 'types';
import { DEFAULT_TRANSITION_DURATION_IN_SEC } from 'utils/constants';
import { scaleInlineStyles } from 'utils/embed/text-overlay';
import { timerMeasurementToString } from 'utils/embed/timer';
import { getVideoConfigFromUCS } from 'utils/embed/video';
import measurement, { Measurement, Pixels } from 'utils/measurement';
import {
  measurementToPx,
  measurementToString,
  placementTransformer,
} from 'utils/placement';

import {
  MediaIntegrationId,
  Slide,
  TextOverlay,
  VideoTemplateState,
  VideoTemplateStateContent,
} from '../types';
import { TemplateEditorStateExport } from '../types/export-formats';
import { IntegrationPostProcessor } from '../types/integrations';
import { getCaptionsExportConfig } from './captions';
import { getStateContent } from './state-utils';
import { formatPadding } from './text-overlay';

const { compose, isEmpty, partial, pick } = _;

export function exportTextOverlay(
  overlay: TextOverlay,
  state: VideoTemplateStateContent,
): ITextOverlayV2 & { layerId: number } {
  const { canvas, layers } = state;

  // TODO types
  const convertToNumericPx = compose(
    partial(placementTransformer, _, (v: Pixels) => v.value),
    partial(measurementToPx, _, canvas),
  );

  return {
    ...overlay,
    advancedTextConfigs: overlay.advancedTextConfigs,
    editor: overlay.editor,
    layerId: layers.order.indexOf(overlay.layerId),
    position: convertToNumericPx(pick(overlay.position, 'top', 'left')),
    size: convertToNumericPx(pick(overlay.size, 'height', 'width')),
    style: {
      ...formatPadding(
        overlay.style,
        (val: Measurement) => val.toUnit('vw', canvas).value,
      ),
    },
    textBuilderStyles: overlay.textBuilderStyles,
    // TODO scaleInlineStyles strips unit for legacy reasons but it would
    // be useful here to know that it's actually in vw without making an assumption
    textHtml: scaleInlineStyles(overlay.textHtml, ['fontSize'], size =>
      measurement(size, 'vw')
        .toUnit('px', canvas)
        .toString(),
    ),
    time: {
      endMillis: 5000,
      startMillis: 0,
    },
    version: overlay.version,
    viewport: canvas,
    transitions: {
      in: {
        duration: DEFAULT_TRANSITION_DURATION_IN_SEC,
        value: 'cut',
      } as any,
      out: {
        duration: DEFAULT_TRANSITION_DURATION_IN_SEC,
        value: 'cut',
      } as any,
    },
  };
}

export function exportTextOverlays(
  state: VideoTemplateStateContent,
): Array<ITextOverlayV2 & { layerId: number }> {
  const { textOverlays } = state;

  if (!textOverlays?.order || textOverlays.order.length === 0) {
    return undefined;
  }

  return textOverlays.order.map(id =>
    exportTextOverlay(textOverlays.data[id], state),
  );
}

export function getBackgroundColor(state: VideoTemplateStateContent): string {
  return state.backgroundColor;
}

export function getSoundwave(
  state: VideoTemplateStateContent,
): Soundwave & { layerId: number } {
  const { soundwave, layers } = state;
  const layerIndex = layers.order.findIndex(
    id => layers.data[id].type === 'waveform',
  );

  if (!soundwave || layerIndex < 0) return undefined;

  const { original, ...restSoundwave } = soundwave;
  if (isEmpty(restSoundwave)) return undefined;

  return {
    layerId: layerIndex,
    waveColor: restSoundwave.color,
    wavePosition: {
      left: restSoundwave.left.toString(),
      top: restSoundwave.top.toString(),
    },
    waveGeneration:
      restSoundwave.fidelity === 'hi-fi' ? 'accurate' : 'amplitudeBased',
    waveType: restSoundwave.type,
    waveSize: {
      height: restSoundwave.height.toString(),
      width: restSoundwave.width.toString(),
    },
    waveformPrefId: restSoundwave.waveformPrefId,
  };
}

export function getProgressAnimationOptions(
  state: VideoTemplateStateContent,
): ProgressAnimationOptions {
  const { progress } = state;
  return measurementToString(progress);
}

export const getEdgeVideosOptions = (
  introOutro: VideoTemplateStateContent['introOutro'],
): IEdgeVideos => {
  const intro = introOutro?.intro
    ? { videoId: introOutro.intro.id }
    : undefined;
  const outro = introOutro?.outro
    ? { videoId: introOutro.outro.id }
    : undefined;
  return {
    intro,
    outro,
  };
};

export function getTimerOptions(
  state: VideoTemplateStateContent,
): TimerOptions<string> {
  const { timer } = state;
  return timerMeasurementToString(timer);
}

export function getPlacementFromVideoClip(
  videoClip: IVideoClip,
  canvas: Size<number>,
) {
  const { position, style } = videoClip;

  return {
    left: measurement(position.left).toUnit('vw', canvas),
    top: measurement(position.top).toUnit('vh', canvas),
    height: measurement(style.height).toUnit('vh', canvas),
    width: measurement(style.width).toUnit('vw', canvas),
  };
}

// TODO do we still need this?
export function getSourceImageOrigin(slide: Slide): SourceImageOrigin {
  const integrationData = slide?.integrationData;
  if (integrationData?.id === MediaIntegrationId.CANVA) {
    return {
      origin: ImageOriginId.CANVA,
      canvaDesignId: integrationData.designId,
    };
  }

  return undefined;
}

export function exportState(
  state: VideoTemplateState,
  postProcessors: IntegrationPostProcessor[] = [],
): TemplateEditorStateExport {
  const content = getStateContent(state);
  const {
    canvas,
    introOutro,
    layers,
    mainImage,
    slideEffectText,
    slideshow,
    watermark,
    videoClips,
  } = content;

  const soundwave = getSoundwave(content);
  const trackTypes = layers.order.map(id => layers.data[id].type);

  const exportedState: TemplateEditorStateExport = {
    captions: getCaptionsExportConfig(content),
    soundwave,
    backgroundColor: content.backgroundColor,
    edgeVideos: getEdgeVideosOptions(introOutro),
    layers: trackTypes,
    mainImage: !mainImage?.ids.length
      ? undefined
      : {
          indexes: mainImage?.ids
            .map(id => slideshow.order.indexOf(id))
            .filter(id => id !== -1),
        },
    progress: getProgressAnimationOptions(content),
    slideshow: !slideshow
      ? undefined
      : slideshow.order.map(slideId => {
          const stateSlide = slideshow.data[slideId];
          const embeddedTexts = stateSlide.imageEffect.options?.embeddedTexts;

          return {
            ...stateSlide,
            layerId: layers.order.indexOf(slideshow.data[slideId].layerId),
            placement: measurementToString(slideshow.data[slideId].placement),
            sourceImageOrigin: getSourceImageOrigin(slideshow.data[slideId]),
            blurRadius: slideshow.data[slideId].blurRadius?.toString(),
            ...(!embeddedTexts
              ? undefined
              : {
                  imageEffect: {
                    ...stateSlide.imageEffect,
                    options: {
                      ...stateSlide.imageEffect.options,
                      embeddedTexts: embeddedTexts.map(textId => {
                        const effectText = slideEffectText[textId];
                        return pick(effectText, 'text', 'textType');
                      }),
                    },
                  },
                }),
          };
        }),
    templateId: content.template?.templateId,
    textOverlays: exportTextOverlays(content),
    timer: getTimerOptions(content),
    watermark: !watermark
      ? undefined
      : {
          ...watermark,
          position: {
            left: watermark.position.left.toUnit('vw', canvas).toString(),
            top: watermark.position.top.toUnit('vh', canvas).toString(),
          },
          size: {
            height: watermark.size.height.toUnit('vh', canvas).toString(),
            width: watermark.size.width.toUnit('vw', canvas).toString(),
          },
        },

    videoClips: !videoClips
      ? undefined
      : videoClips.order.map(videoClipId => {
          const videoClip = videoClips.data[videoClipId];
          const { layerId, original, placement, enableBlur, metadata } =
            videoClip ?? {};
          const layerIndex = layers.order.indexOf(layerId);

          return getVideoConfigFromUCS(
            original,
            canvas,
            layerIndex,
            placement || getPlacementFromVideoClip(original, canvas),
            enableBlur,
            metadata,
          );
        }),
  };

  return postProcessors.reduce((current, processor) => {
    return processor(current, content);
  }, exportedState);
}

import * as React from 'react';

import { useSelector } from 'react-redux';
import ProjectDeleteModal from 'containers/ProjectDeleteModal';
import { projectHistoryIdsSelector } from 'redux/modules/project/selectors';
import ProjectsEmptyList from '../../components/ProjectsEmptyList';
import ProjectsList from '../../components/ProjectsList';
import { block } from '../../utils';
import useProjectControlActions from './useProjectControlActions';

export interface ProjectHistoryProps {
  active: boolean;
  isLoadingHistory: boolean;
}

const ProjectHistory: React.FC<ProjectHistoryProps> = ({
  active,
  isLoadingHistory,
}) => {
  const projectsIds = useSelector(projectHistoryIdsSelector);
  const projects = projectsIds?.toArray();

  const { onControlClick, onDeleteProject } = useProjectControlActions();

  return (
    <div className={block()}>
      {(() => {
        if (isLoadingHistory === undefined || (isLoadingHistory && !projects)) {
          return null;
        }

        if (!projects || projects.length === 0) {
          return <ProjectsEmptyList />;
        }

        return <ProjectsList {...{ active, projects, onControlClick }} />;
      })()}

      <ProjectDeleteModal onSubmit={onDeleteProject} />
    </div>
  );
};

export default ProjectHistory;

import * as React from 'react';
import AutomationOptionsCards from 'blocks/AutomationOptions/AutomationOptionsCards';
import {
  AutoPostOptions,
  DestinationSteps,
} from 'blocks/AutomationOptions/types';
import useAutomationStepOptions from 'blocks/AutomationOptions/useAutomationStepOptions';
import DestinationPlatforms, {
  checkIfDestinationPresetExists,
  DestinationPlatform,
  DestinationPlatformsVideoTypes,
} from 'blocks/DestinationPlatforms';

import { audiogramDestinationConfig } from 'constants/audiogram';
import { AudiogramVideoTypeConfig, VideoTypes } from 'types';
import { DestinationOptions } from '../containers/AutomationWorkflowWizard/types';
import {
  checkViewIsAutoPosting,
  checkViewIsClipSelection,
} from '../containers/AutomationWorkflowWizard/utils';
import { destinationStepBlock as block } from '../utils';

export interface AutomationDestinationStepProps {
  destinationOptions: DestinationOptions;
  onDestinationOptionsChange?: (props: DestinationOptions) => void;
}

const AutomationDestinationStep: React.FC<AutomationDestinationStepProps> = props => {
  const { destinationOptions, onDestinationOptionsChange } = props;
  const { stepView, automationOption, platform, clipSelectionMethod } =
    destinationOptions || {};

  const { autoPostingOptions } = useAutomationStepOptions();

  const handleAutoPostingOptionsChange = React.useCallback(
    (options: DestinationOptions): void => {
      onDestinationOptionsChange({
        ...options,
        autopostVideoToYouTube:
          options.automationOption === AutoPostOptions.AUTO_POST,
      });
    },
    [onDestinationOptionsChange],
  );

  const handleVideoTypeClick = (
    selectedVideoType: AudiogramVideoTypeConfig,
  ) => {
    const {
      destination,
      videoType,
      aspectRatioName,
      clipDuration,
    } = selectedVideoType.autogramWizardSettings;

    onDestinationOptionsChange({
      stepView: destination,
      isDestinationOptionsComplete: !destination,
      videoType,
      platformVideoType: selectedVideoType.id,
      aspectRatioName,
      clipDuration,
    });
  };

  const handlePlatformSelection = React.useCallback(
    (newPlatform: DestinationPlatform): void => {
      const shouldSelectVideoTypes = checkIfDestinationPresetExists(
        newPlatform,
      );

      const nextStepView = ['linkedin', 'threads', 'twitter'].includes(
        newPlatform,
      )
        ? DestinationSteps.AUTO_POSTING
        : undefined;

      onDestinationOptionsChange({
        stepView:
          nextStepView ||
          (shouldSelectVideoTypes
            ? DestinationSteps.VIDEO_TYPES
            : DestinationSteps.CLIP_SELECTION),
        platform: newPlatform,
        videoType: shouldSelectVideoTypes ? null : VideoTypes.RANDOM_CLIP,
        platformVideoType: shouldSelectVideoTypes ? null : 'post',
        aspectRatioName: !shouldSelectVideoTypes ? 'square' : null,
      });
    },
    [onDestinationOptionsChange],
  );

  if (stepView === DestinationSteps.VIDEO_TYPES) {
    return (
      <div className={block('video-types-list-container')}>
        <DestinationPlatformsVideoTypes<AudiogramVideoTypeConfig>
          icon={audiogramDestinationConfig[platform]?.icon}
          videoTypes={audiogramDestinationConfig[platform]?.videoTypes}
          onClick={handleVideoTypeClick}
          onBackClick={() =>
            onDestinationOptionsChange({
              stepView: DestinationSteps.CHOOSE_DESTINATION,
            })
          }
        />
      </div>
    );
  }

  if (checkViewIsClipSelection(stepView)) {
    return (
      <AutomationOptionsCards
        options={autoPostingOptions.clipSelection}
        value={clipSelectionMethod}
        currentStep={stepView}
        onChange={onDestinationOptionsChange}
      />
    );
  }

  if (checkViewIsAutoPosting(stepView)) {
    return (
      <AutomationOptionsCards
        options={autoPostingOptions[platform]}
        value={automationOption}
        currentStep={stepView}
        onChange={handleAutoPostingOptionsChange}
      />
    );
  }

  return (
    <DestinationPlatforms
      contentsClassName={block('destination-options')}
      onPlatformSelection={handlePlatformSelection}
    />
  );
};

export default AutomationDestinationStep;

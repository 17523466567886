import { LOCATION_CHANGE } from 'connected-react-router/immutable';
import Immutable from 'immutable';
import { ServiceMethod } from 'redux/middleware/api/video-project-management-service/types';

import { pick } from 'utils/collections';
import { isProjectsActive } from 'utils/routes';
import * as embedTypes from '../embed/action-types';
import * as types from './types';

const defaultState = Immutable.Map();

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case embedTypes.EMBED_EDITOR_LOAD_REQUEST:
    case types.PROJECT_CLEAR:
      return pick(state, 'history', 'projectsCount');

    case types.PROJECT_HISTORY_CLEAR:
      return state.delete('history');

    case types.PROJECT_UPDATE_REQUEST:
      return state.set(
        'update',
        Immutable.Map({
          ...action.payload,
          inProgress: true,
        }),
      );

    case types.PROJECT_UPDATE_SUCCESS:
      return state.setIn(['update', 'inProgress'], false);

    case types.PROJECT_UPDATE_FAILURE:
      return state.setIn(['update', 'inProgress'], false);

    case types.PROJECT_DELETE:
      return state.updateIn(['history', 'data'], Immutable.List(), d => {
        const index = d.indexOf(action.payload.id);
        return index >= 0 ? d.delete(index) : d;
      });

    case types.PROJECT_CREATE_SUCCESS: {
      const { id } = action.payload;
      return state.withMutations(s => s.set('id', id).delete('newProject'));
    }

    case types.PROJECT_HISTORY_GET_REQUEST:
      return state.setIn(['history', 'isFetching'], true);

    case types.PROJECT_HISTORY_GET_SUCCESS: {
      const { content, nextToken } = action.payload;

      if (!content) return state;

      return state.withMutations(s => {
        s.setIn(['history', 'nextToken'], nextToken)
          .setIn(['history', 'isFetching'], false)
          .updateIn(['history', 'data'], Immutable.List(), d =>
            d
              .concat(content)
              .toOrderedSet()
              .toList(),
          );
      });
    }

    case types.PROJECT_HISTORY_GET_FAILURE:
      return state.setIn(['history', 'isFetching'], false);

    case types.PROJECTS_COUNT_GET_REQUEST:
      return state.delete('projectsCount');

    case types.PROJECTS_COUNT_GET_SUCCESS: {
      const { totalResults } = action.payload;

      return state.set('projectsCount', totalResults);
    }

    case types.PROJECTS_COUNT_GET_FAILURE:
      return state.delete('projectsCount');

    case types.PROJECT_SET: {
      const { id } = action.payload;
      return state.withMutations(s => {
        if (id) {
          s.set('id', id).delete('redirectId');
        } else {
          s.delete('id');
        }
      });
    }

    case types.PROJECT_COPY_REQUEST:
      return state.set('copyStatus', 'requested');

    case types.PROJECT_COPY_FAILURE:
      return state.set('copyStatus', 'failed');

    case types.PROJECT_COPY_SUCCESS: {
      const { id, isRedirect } = action.payload;

      return state.withMutations(s => {
        s.set('copyStatus', 'succeeded');
        s.updateIn(['history', 'data'], Immutable.List(), d => {
          return d.insert(0, id);
        });

        if (isRedirect) s.set('redirectId', id);
      });
    }

    case types.NEW_PROJECT_SETTINGS_SET: {
      const { aspectRatio } = action.payload;
      return state.setIn(['newProject', 'aspectRatio'], aspectRatio);
    }

    case types.NEW_PROJECT_SETTINGS_CLEAR:
      return state.delete('newProject');

    case types.PROJECT_TEMPLATE_CREATE_SUCCESS: {
      const { id } = action.payload;
      return state.set('id', id);
    }

    case LOCATION_CHANGE: {
      const isProjectsTabActive = isProjectsActive();
      const isPrevProjectsTabActive = state.get('isProjectsTabActive');

      if (!isPrevProjectsTabActive && isProjectsTabActive) {
        return state.withMutations(s => {
          s.set('isProjectsTabActive', true);
          s.set('projectsPageFetchCount', 0);
        });
      }
      if (isPrevProjectsTabActive && !isProjectsTabActive) {
        return state.withMutations(s => {
          s.set('isProjectsTabActive', false);
          s.set('projectsPageFetchCount', 0);
        });
      }
      return state;
    }

    case `${ServiceMethod.GET_ALL_PROJECTS}_REQUEST`: {
      if (state.get('isProjectsTabActive')) {
        return state.update('projectsPageFetchCount', val => val + 1);
      }
      return state;
    }

    default:
      return state;
  }
}

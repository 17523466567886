import { isString } from 'underscore';
import { FeedbackType } from '../podcast-service';
import { createApiAction } from '../utils';
import {
  ACTION_KEY,
  CreateCreationClipSuggestionArgs,
  CreateSocialPostCaptionsAction,
  CreationAction,
  CreationActionOptions,
  EnableCreationClipSuggestionArgs,
  GetCreationAction,
  GetCreationClipSuggestionAction,
  GetCreationClipSuggestionArgs,
  GetCreationClipSuggestionsArgs,
  GetCreationClipSuggestionsProgressArgs,
  GetSocialPostCaptionsAction,
  GetSocialPostCaptionsArgs,
  GetSocialPostCaptionsJobAction,
  GetSocialPostCaptionsJobArgs,
  ReceiveCreationSuggestionEventArgs,
  ReprocessCreationSuggestionArgs,
  ServiceMethod,
} from './types';

function apiCallAction<M extends ServiceMethod, A>(method: M, args: A) {
  return createApiAction(ACTION_KEY, method, args);
}

export function create(opts: CreationActionOptions): CreationAction {
  const { audioSource, videoSource, baseConfigJson, ...restOpts } = opts;

  return apiCallAction(ServiceMethod.CREATE, [
    {
      ...restOpts,
      ...(audioSource && {
        [isString(audioSource) ? 'audioUrl' : 'audioFile']: audioSource,
      }),
      ...(videoSource && {
        [isString(videoSource) ? 'videoUrl' : 'videoFile']: videoSource,
      }),
      baseConfigJson: JSON.stringify(baseConfigJson),
    },
  ]);
}

export function getCreation(id: number): GetCreationAction {
  return apiCallAction(ServiceMethod.CREATION_GET, [id]);
}

export function enableCreationClipSuggestion(
  creationRequestId: number,
  suggestionId: number,
) {
  return apiCallAction(ServiceMethod.ENABLE_CREATION_CLIP_SUGGESTION, [
    creationRequestId,
    suggestionId,
  ] as EnableCreationClipSuggestionArgs);
}

export function receiveCreationSuggestionEvent(
  args: ReceiveCreationSuggestionEventArgs,
) {
  return apiCallAction(ServiceMethod.RECEIVE_CREATION_SUGGESTION_EVENT, args);
}

export function getCreationClipSuggestions(creationRequestId: number) {
  return apiCallAction(ServiceMethod.GET_CREATION_CLIP_SUGGESTIONS, [
    creationRequestId,
  ] as GetCreationClipSuggestionsArgs);
}

export function getCreationClipSuggestion(
  creationRequestId?: number,
  suggestionId?: number,
): GetCreationClipSuggestionAction {
  return apiCallAction(ServiceMethod.GET_CREATION_CLIP_SUGGESTION, [
    creationRequestId,
    suggestionId,
  ] as GetCreationClipSuggestionArgs);
}

export function createCreationClipSuggestionFeedback(
  feedbackType: FeedbackType,
  creationRequestId?: number,
  suggestionId?: number,
) {
  return apiCallAction(ServiceMethod.CREATE_CREATION_CLIP_SUGGESTION_FEEDBACK, [
    feedbackType,
    creationRequestId,
    suggestionId,
  ]);
}

export function createCreationClipSuggestion(
  creationRequestId: number,
  replaceSuggestionId: number,
  newTrimStartMillis: number,
  newTrimEndMillis: number,
) {
  return apiCallAction(ServiceMethod.CREATE_CREATION_CLIP_SUGGESTION, [
    creationRequestId,
    replaceSuggestionId,
    newTrimStartMillis,
    newTrimEndMillis,
  ] as CreateCreationClipSuggestionArgs);
}

export function getCreationClipSuggestionsProgress(creationRequestId: number) {
  return apiCallAction(ServiceMethod.GET_CREATION_CLIP_SUGGESTIONS_PROGRESS, [
    creationRequestId,
  ] as GetCreationClipSuggestionsProgressArgs);
}

export function reprocessCreationClipSuggestion(
  args: ReprocessCreationSuggestionArgs,
) {
  return apiCallAction(ServiceMethod.REPROCESS_CREATION_SUGGESTION, args);
}

export function createVideoSocialPostCaptions(
  creationRequestId: number,
): CreateSocialPostCaptionsAction {
  return apiCallAction(ServiceMethod.CREATE_SOCIAL_POST_CAPTIONS, [
    creationRequestId,
  ]);
}

export function getVideoSocialPostCaptions(
  creationRequestId: number,
): GetSocialPostCaptionsAction {
  return apiCallAction(ServiceMethod.GET_SOCIAL_POST_CAPTIONS, [
    creationRequestId,
  ] as GetSocialPostCaptionsArgs);
}

export function getVideoSocialPostCaptionsJob(
  creationRequestId: number,
  jobId: number,
): GetSocialPostCaptionsJobAction {
  return apiCallAction(ServiceMethod.GET_SOCIAL_POST_CAPTIONS_JOB, [
    creationRequestId,
    jobId,
  ] as GetSocialPostCaptionsJobArgs);
}

import * as React from 'react';
import { noop } from 'underscore';

import AccountSelect from 'components/AccountSelect';
import { Overrides, SelectProps } from 'components/Select';
import PageOption from './PageOption';
import { Option } from './types';

type PickedSelectProps = Pick<SelectProps<Option>, 'onChange' | 'value'>;

export interface FacebookPageSelectorProps extends PickedSelectProps {
  onSwitchAccountSelect?: () => void;
  options: Option[];
}

const selectComponentOverrides: Overrides<Option> = {
  option: {
    component: props => <PageOption {...props} />,
  },
};

const FacebookPageSelector: React.SFC<FacebookPageSelectorProps> = ({
  onChange,
  onSwitchAccountSelect,
  options,
  value,
}) => {
  return (
    <AccountSelect
      onChange={onChange}
      options={options}
      overrides={selectComponentOverrides}
      value={value}
      onSwitchAccountSelect={onSwitchAccountSelect}
    />
  );
};

FacebookPageSelector.defaultProps = {
  onChange: noop,
  onSwitchAccountSelect: noop,
};

// TODO maybe put in types file
export { Option };

export default FacebookPageSelector;

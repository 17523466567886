import { Spacer } from '@sparemin/blockhead';
import React from 'react';

import { useTextOverlay } from '../../state/TextOverlayProvider';

import { block } from '../../utils';
import useTextOverlayAdvancedAnimation from './useTextOverlayAdvancedAnimation';
import { getAnimationChildView } from './utils';

const AnimationsTab: React.FunctionComponent = () => {
  const { animationsTabExtension } = useTextOverlay();
  const { animationModules } = animationsTabExtension;
  const { selectedAdvancedAnimation } = useTextOverlayAdvancedAnimation();

  const animationChildView = getAnimationChildView(
    selectedAdvancedAnimation,
    animationModules,
  );

  return (
    <Spacer
      orientation="vertical"
      space="24px"
      align="center"
      justify="center"
      className={block('animations-tab-container')}
    >
      <Spacer orientation="horizontal" space="24px">
        {animationModules.map(animationModule => (
          <animationModule.control key={animationModule.key} />
        ))}
      </Spacer>

      {animationChildView && (
        <Spacer
          orientation="horizontal"
          space="24px"
          justify="center"
          className={block('animations-tab-contents')}
        >
          {animationChildView}
        </Spacer>
      )}
    </Spacer>
  );
};

export default AnimationsTab;

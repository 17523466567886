import React from 'react';

import { useTemplateState } from 'components/VideoTemplateEditor/context/VideoTemplateStateContext';
import {
  CaptionsState,
  ProgressState,
  Slide,
  SoundwaveState,
  TextOverlay,
  TimerState,
  VideoClip,
  WatermarkState,
} from 'components/VideoTemplateEditor/types';
import {} from 'types';

interface BaseLayer {
  id: string;
}

interface CaptionsLayer extends BaseLayer {
  type: 'captions';
  captionsOverride: CaptionsState;
}

interface ProgressLayer extends BaseLayer {
  type: 'progress';
  progress?: ProgressState;
  timer?: TimerState;
}

interface SlideshowLayer extends BaseLayer {
  type: 'slideshow';
  slideshow: Slide;
}

interface SoundwaveLayer extends BaseLayer {
  type: 'soundwave';
  soundwave: SoundwaveState;
}

interface TextOverlayLayer extends BaseLayer {
  type: 'text-overlay';
  textOverlay: TextOverlay;
}

interface VideoLayer extends BaseLayer {
  type: 'video';
  videoLayer: VideoClip;
}

interface WatermarkLayer extends BaseLayer {
  type: 'watermark';
  watermark: WatermarkState;
}

type Layer =
  | CaptionsLayer
  | ProgressLayer
  | SlideshowLayer
  | SoundwaveLayer
  | TextOverlayLayer
  | VideoLayer
  | WatermarkLayer;

type UseEditorModalBackground = Layer[];

interface UseEditorModalBackgroundConfig {
  isCaptionsEditor?: boolean;
  selectedAssetId: string;
}

const useEditorModalBackground = (
  config: UseEditorModalBackgroundConfig,
): UseEditorModalBackground => {
  const { isCaptionsEditor, selectedAssetId } = config;

  const {
    captions,
    layers,
    progress,
    slideshow,
    soundwave,
    textOverlays,
    timer,
    transcription,
    videoClips,
    watermark,
  } = useTemplateState();

  const trancribeEnabled = transcription?.transcribe;

  const parsedLayers: Layer[] = React.useMemo(() => {
    const slideshowsEntries = Object.values(slideshow.data);
    const textOverlaysEntries = Object.values(textOverlays.data);
    const videoEntries = Object.values(videoClips.data);

    // The layers are first ordered for making them appear in the correct order
    // and ensure the layers are going to be overlapped in the order in which
    // they should be overlapped.
    const orderedLayers = [...layers?.order]
      // step 1: reverse the layers order: bottom layer will be rendered first
      // making the next in row to appear on top of it and following that order
      // until reaching the layer in the foreground.
      .reverse()
      // step 2: map the layers into a layer ids array
      .map(layerId => layers.data[layerId])
      // step 3: find the layer among the layer types that should be printed and
      // append them to the layers array
      .reduce((acc, layer) => {
        // check for the layer id among the video clip layers

        const foundVideo = videoEntries.find(
          video => video?.layerId === layer.id,
        );
        if (foundVideo) {
          acc.push({
            id: foundVideo.id,
            type: 'video',
            videoLayer: foundVideo,
          });
        }
        if (soundwave?.layerId === layer.id) {
          // check for the layer id at the soundwave layer
          acc.push({
            id: soundwave.layerId,
            type: 'soundwave',
            soundwave,
          });
          return acc;
        }

        // check for the layer id among the slideshow layers
        const foundSlideshow = slideshowsEntries.find(
          slide => slide?.layerId === layer.id,
        );
        if (foundSlideshow) {
          acc.push({
            id: foundSlideshow.id,
            type: 'slideshow',
            slideshow: foundSlideshow,
          });
          return acc;
        }

        // check for the layer id among the text overlay layers
        const foundTextOverlay = textOverlaysEntries.find(
          overlay => overlay?.layerId === layer.id,
        );
        if (foundTextOverlay) {
          acc.push({
            id: foundTextOverlay.id,
            type: 'text-overlay',
            textOverlay: foundTextOverlay,
          });
        }

        return acc;
      }, [] as Layer[])
      // step 4: Remove the current in edition layer from the layers list
      .filter(parsedLayer => parsedLayer && parsedLayer.id !== selectedAssetId);

    // step 5: Push progress layer if it exists, this layer should go in between
    // the orderable layers an the watermark
    if (progress?.enabled || timer?.enabled) {
      orderedLayers.push({
        id: 'progress-layer',
        type: 'progress',
        progress,
        timer,
      });
    }

    // step 6: Push watermark layer if it exists, this layer should go in between
    // the orderable layers an the captions
    if (watermark) {
      orderedLayers.push({
        id: 'watermark-layer',
        type: 'watermark',
        watermark,
      });
    }

    // step 7: if not editing captions (there can only be a single instance
    // of captions), the captions layer is added to the list if it exists.
    // By design captions layer should go to foreground.
    if (trancribeEnabled && !isCaptionsEditor && captions) {
      orderedLayers.push({
        id: 'captions-layer',
        type: 'captions',
        captionsOverride: captions,
      });
    }

    return orderedLayers;
  }, [
    captions,
    isCaptionsEditor,
    layers,
    progress,
    selectedAssetId,
    slideshow.data,
    soundwave,
    textOverlays.data,
    timer,
    trancribeEnabled,
    videoClips.data,
    watermark,
  ]);

  return parsedLayers;
};

export default useEditorModalBackground;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Link from 'components/Link';
import WarningModalContents from 'containers/WarningModal/WarningModalContents';
import { updateMySubscription } from 'redux/middleware/api/plan-service/actions';
import {
  onClickPlanCancelledModal,
  onShowPlanCancelledModal,
  onUnlockThisFeature,
} from 'redux/modules/mixpanel/actions';
import { hideModal } from 'redux/modules/modal';
import bem from 'utils/bem';
import { getMyPlanPath } from 'utils/routes';

const block = bem('plan-cancelled-modal');

export interface PlanCancelledModalContentsProps {}

const PlanCancelledModalContents: React.FC<PlanCancelledModalContentsProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onShowPlanCancelledModal());
  }, [dispatch]);

  const createCtaClickHandler = (upgrade: boolean) => () => {
    dispatch(onUnlockThisFeature('YourPlanWasCancelledModalClicked'));
    dispatch(onClickPlanCancelledModal(upgrade));
    dispatch(updateMySubscription({ forcedCancellationNotice: false }));
    dispatch(hideModal());
  };

  return (
    <WarningModalContents
      className={block('contents')}
      title="Your paid plan was cancelled"
      description={
        <>
          There is a problem with your card on file, so we couldn't process
          <br />
          your payment. To regain your paid features, just upgrade again.
        </>
      }
      actions={[
        {
          theme: 'submit-alt',
          onClick: createCtaClickHandler(false),
          children: 'use our free plan',
          id: 'keep',
        },
        {
          theme: 'submit',
          componentClass: Link,
          to: getMyPlanPath(),
          children: 'upgrade again',
          onClick: createCtaClickHandler(true),
          id: 'upgrade',
        } as any,
      ]}
    />
  );
};

export default PlanCancelledModalContents;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TextOverlayModal, {
  PersistentTextEditorState,
  TextOverlayV2,
} from 'blocks/TextOverlayModal/v2';
import { EditorVideoFramePreview } from 'containers/VideoFramePreview';
import {
  applyOverlayToTextTrack,
  saveLastGlobalTextEditorV2State,
} from 'redux/modules/embed/actions/text-overlay-ts';
import {
  aspectRatioSelector,
  globalStyleTrackIdSelector,
  lastGlobalTextEditorV2StateSelector,
} from 'redux/modules/embed/selectors';

import { block } from './utils';

interface TextTrackStyleModalProps {
  initialOverlay: TextOverlayV2;
  onExited: () => void;
  onHide: () => void;
  onSwitchToLegacy: () => void;
  show: boolean;
}

const TextTrackStyleModal: React.FunctionComponent<TextTrackStyleModalProps> = props => {
  const { initialOverlay, onExited, onHide, onSwitchToLegacy, show } = props;

  const lastEditorState = useSelector(lastGlobalTextEditorV2StateSelector);
  const trackId = useSelector(globalStyleTrackIdSelector);

  const dispatch = useDispatch();

  const handleSubmit = React.useCallback(
    (textOverlay: TextOverlayV2): void => {
      dispatch(applyOverlayToTextTrack(trackId, textOverlay));
      onHide();
    },
    [dispatch, onHide, trackId],
  );

  const handleSaveLastEditorState = React.useCallback(
    (editorState: PersistentTextEditorState): void => {
      dispatch(saveLastGlobalTextEditorV2State(editorState));
    },
    [dispatch],
  );

  const getLastEditorState = React.useCallback((): PersistentTextEditorState => {
    return lastEditorState?.toJS();
  }, [lastEditorState]);

  const aspectRatio = useSelector(aspectRatioSelector);

  return (
    <TextOverlayModal
      aspectRatio={aspectRatio}
      background={({ viewport }) => (
        <EditorVideoFramePreview
          // This preview component does not re render when the viewport
          // size is changed by a browser viewport size change.
          // This is not an event likely to happen in practise, but it is
          // good to support it.
          key={`${viewport?.height}-${viewport?.width}`}
          aspectRatio={aspectRatio}
          canvasDimensions={viewport}
          className={block('video-frame-preview')}
          backgroundFor={{
            type: 'textOverlayInfo',
          }}
        />
      )}
      defaultText="Overwrite your text box styles with this style."
      editable={false}
      extensions={{
        stylePersistor: {
          enabled: true,
          editorStateGetter: getLastEditorState,
          editorStateSetter: handleSaveLastEditorState,
        },
      }}
      isCompatibilityMode
      onExited={onExited}
      onHide={onHide}
      onSwitchToLegacy={onSwitchToLegacy}
      onSubmit={handleSubmit}
      showPresetSelector={false}
      submitButtonText="Save"
      shouldDisableSubmitIfEmpty
      showEmoji={false}
      show={show}
      textOverlay={initialOverlay}
      title="Track properties"
    />
  );
};

export default TextTrackStyleModal;

import { YouTube } from '@sparemin/blockhead';
import React from 'react';
import SocialCircle from './SocialCircle';

export interface YouTubeSocialCircleProps {
  className?: string;
}

const YouTubeSocialCircle: React.FC<YouTubeSocialCircleProps> = ({
  className,
}) => (
  <SocialCircle background="white" className={className}>
    <YouTube height="25px" width="36px" />
  </SocialCircle>
);

export default YouTubeSocialCircle;

import * as React from 'react';

import DestinationPlatformsContents, {
  DestinationPlatformsContentsProps,
} from './DestinationPlatformsContents';
import DestinationPlatformsHeader, {
  DestinationPlatformsHeaderProps,
} from './DestinationPlatformsHeader';

export interface DestinationPlatformsProps
  extends DestinationPlatformsHeaderProps,
    DestinationPlatformsContentsProps {
  contentsClassName?: string;
}

const DestinationPlatforms: React.FC<DestinationPlatformsProps> = ({
  title = 'Choose a destination',
  description = 'Where would you like to promote your podcast?',
  contentsClassName,
  onPlatformSelection,
}) => {
  return (
    <>
      <DestinationPlatformsHeader title={title} description={description} />
      <DestinationPlatformsContents
        onPlatformSelection={onPlatformSelection}
        className={contentsClassName}
      />
    </>
  );
};

export default DestinationPlatforms;

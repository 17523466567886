import cn from 'classnames';
import * as React from 'react';

import { CheckboxField } from 'components/Form';
import { ModalBodyCol, ModalBodyRow } from 'components/Modal';
import Spinner from 'components/Spinner';
import ImageCropper, {
  ImageCropperInstance,
  ImageCropperProps,
} from 'containers/ImageCropper';
import { block } from './utils';

const { useState } = React;

type PickedCropperProps = Pick<
  ImageCropperProps,
  'aspectRatio' | 'defaultMetadata' | 'fileType' | 'params' | 'onReady'
>;

export interface CropperModalBodyProps extends PickedCropperProps {
  defaultConstrainImage?: boolean;
  allowConstrainControl?: boolean;
  disabled?: boolean;
  isSubmitting?: boolean;
  imgSrc: ImageCropperProps['src'];
}

const columnClassName = block('col');

const CropperModalBody = React.forwardRef<
  ImageCropperInstance,
  CropperModalBodyProps
>(
  (
    {
      aspectRatio,
      defaultConstrainImage = true,
      defaultMetadata,
      disabled,
      fileType,
      imgSrc,
      isSubmitting,
      allowConstrainControl = true,
      onReady,
      params,
    },
    ref,
  ) => {
    const [constrain, setConstrain] = useState(defaultConstrainImage);

    return (
      <ModalBodyRow className={block('body-content')}>
        {isSubmitting && (
          <div className={block('submitting-overlay')}>
            <span className={block('text')}>Cropping</span>
            <Spinner
              className={cn(
                block('spinner'),
                'spinner--blue',
                'spinner--wave',
                'spinner--large',
              )}
            />
          </div>
        )}
        <ModalBodyCol
          size={allowConstrainControl ? 10 : 12}
          className={columnClassName}
        >
          <div className={cn(block('cropper-container', { default: true }))}>
            {imgSrc && (
              <ImageCropper
                constrainImage={constrain}
                src={imgSrc}
                {...{
                  aspectRatio,
                  defaultMetadata,
                  disabled,
                  fileType,
                  params,
                  onReady,
                  ref,
                }}
              />
            )}
          </div>
        </ModalBodyCol>
        {allowConstrainControl && (
          <ModalBodyCol size={2} className={columnClassName}>
            <CheckboxField
              horizontal={{ xs: 12 }}
              disabled={disabled}
              checked={constrain}
              controlClassName={block('constrain-control')}
              inputClassName={block('constrain-input')}
              labelClassName={block('form-label')}
              onChange={setConstrain}
            >
              <span>Constrain media</span>
            </CheckboxField>
          </ModalBodyCol>
        )}
      </ModalBodyRow>
    );
  },
);

export default CropperModalBody;

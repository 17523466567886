import {
  CaptionsAnimationConfig,
  CaptionsRenderer,
  StyleObject,
} from '@sparemin/text-effects';
import React from 'react';
import usePrevious from 'hooks/usePrevious';
import { Size } from 'types';

import { useCanvasSize } from './context/CanvasSizeContext';
import useOnTextAssetResize from './useOnTextAssetResize';
import { createCaptionCueObject, getDefaultCaptionWordStyling } from './utils';

interface UseCaptionsAssetAnimationsConfig {
  captionsContent: string;
  advancedAnimation?: CaptionsAnimationConfig;
  textStyles?: StyleObject;
  externalObservableData?: unknown;
}

interface UseCaptionsAssetAnimations {
  wrapperNodeRef: React.MutableRefObject<HTMLDivElement>;
  onResize: (size: Size) => void;
}

const useCaptionsAssetAnimations = (
  config: UseCaptionsAssetAnimationsConfig,
): UseCaptionsAssetAnimations => {
  const { captionsContent, advancedAnimation, textStyles } = config;

  const wrapperNodeRef = React.useRef<HTMLDivElement>(null);
  const captionsRendererRef = React.useRef<CaptionsRenderer | undefined>();

  const { resizeDelta, onResize } = useOnTextAssetResize();
  const { canvas } = useCanvasSize();

  const prevConfig = usePrevious(config);
  const prevResizeDelta = usePrevious(resizeDelta);

  React.useEffect(() => {
    if (
      !wrapperNodeRef.current ||
      !advancedAnimation?.enabled ||
      captionsRendererRef.current
    ) {
      return;
    }

    captionsRendererRef.current = new CaptionsRenderer(wrapperNodeRef.current, {
      cues: [createCaptionCueObject(captionsContent)],
      textStyles,
      animationConfig: advancedAnimation,
      ...getDefaultCaptionWordStyling(canvas?.width),
    });

    requestAnimationFrame(() => {
      captionsRendererRef?.current?.getAnimator();
    });
  }, [advancedAnimation, canvas, captionsContent, textStyles]);

  React.useEffect(() => {
    if (prevConfig !== config || prevResizeDelta !== resizeDelta) {
      requestAnimationFrame(() => {
        captionsRendererRef?.current?.updateConfig({
          cues: [createCaptionCueObject(captionsContent)],
          textStyles,
          animationConfig: advancedAnimation,
          ...getDefaultCaptionWordStyling(canvas?.width),
        });
      });
    }
  }, [
    advancedAnimation,
    canvas,
    captionsContent,
    config,
    prevConfig,
    prevResizeDelta,
    resizeDelta,
    textStyles,
  ]);

  return {
    wrapperNodeRef,
    onResize,
  };
};

export default useCaptionsAssetAnimations;

import {
  ActionButton,
  ActionButtonProps,
  Heading,
  Spacer,
  Text,
} from '@sparemin/blockhead';
import React from 'react';
import { grey2 } from 'utils/colors';
import { block } from './utils';

interface PodcastMediaOptionButtonProps
  extends Pick<ActionButtonProps, 'onPress'> {
  title: string;
  description: string;
  children: React.ReactNode;
}

const PodcastMediaOptionButton: React.FC<PodcastMediaOptionButtonProps> = props => {
  const { title, description, children, onPress } = props;

  return (
    <div className={block('button')}>
      <ActionButton
        color={grey2}
        variant="solid"
        onPress={onPress}
        size="large"
        label={
          <Spacer orientation="vertical" justify="center" align="center">
            <Heading level={3}>{title}</Heading>
            <Text variant="p" align="center">
              {description}
            </Text>
          </Spacer>
        }
      >
        {children}
      </ActionButton>
    </div>
  );
};
export default PodcastMediaOptionButton;

import { createApiAction } from '../utils';
import { ACTION_KEY, ProxyMediaArgs, ServiceMethod } from './types';

function apiCallAction<M extends ServiceMethod, A = undefined>(
  method: M,
  args?: A,
) {
  return createApiAction(ACTION_KEY, method, args);
}

export function getExternalMedia(url: string, appendXRequestQuery?: boolean) {
  return apiCallAction(ServiceMethod.PROXY_MEDIA, [
    url,
    appendXRequestQuery,
  ] as ProxyMediaArgs);
}

export function externalMediaExists(url: string) {
  return apiCallAction(ServiceMethod.EXTERNAL_MEDIA_EXISTS, [
    url,
  ] as ProxyMediaArgs);
}

import React from 'react';

export const DYNAMIC_VIDEO_AUTOFRAME_INFOBOX_MESSAGE = (
  <>
    <b>Low-res preview:</b> since AutoFrame is turned on your exported videos
    will automatically track and center people to keep them in frame, but this
    preview does not reflect that.
  </>
);

export const DYNAMIC_VIDEO_AUTOFRAME_TOOLTIP_MESSAGE =
  'Turn AutoFrame off to manually adjust size and position';

import { useCallback, useState } from 'react';

export type UseClipSelectorProps = {
  suggestionIds: number[];
};

/**
 * State for the clip selector / carousel
 */
export default function useClipSelector({
  suggestionIds,
}: UseClipSelectorProps) {
  const [activeSuggestionId, setActiveSuggestionId] = useState<number>(
    undefined,
  );

  const onChangeClip = useCallback((id: number) => {
    setActiveSuggestionId(id);
  }, []);

  if (suggestionIds?.length > 0 && activeSuggestionId === undefined) {
    setActiveSuggestionId(suggestionIds[0]);
  }

  return {
    activeSuggestionId,
    onChangeClip,
  };
}

import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Transcript, { TranscriptProps } from 'blocks/Transcript';
import { hasTimeRemaining } from 'containers/FreeTranscriptionBalance/utils';
import usePrevious from 'hooks/usePrevious';
import useTranscriptionBalance from 'hooks/useTranscriptionBalance';
import { generateCaptions } from 'redux/modules/automation-cms';
import {
  recordingIdSelector,
  trackDurationInMilliSelector,
} from 'redux/modules/embed/selectors';
import { pushModal } from 'redux/modules/modal';
import { isFreeSelector } from 'redux/modules/pricing';
import { updateMyCaptionsPref } from 'redux/modules/user-pref/actions';
import { captionsPrefsSelector } from 'redux/modules/user-pref/selectors';
import { TranscriptStatus } from 'utils/constants';
import dayjs from 'utils/dayjs';
import AssetsPaneContent from '../../components/AssetsPaneContent';
import { block } from '../../utils';
import CaptionsForm, { CaptionsFormProps } from './CaptionsForm';
import CaptionsModals, { CaptionsModal } from './CaptionsModals';
import TranscriptStatusDisplay from './TranscriptStatusDisplay';

const { useCallback, useEffect, useState } = React;

type PickedFormProps = Pick<CaptionsFormProps, 'captionsEnabled'>;
type PickedTranscriptProps = Pick<
  TranscriptProps,
  | 'onCopyPhraseToTimeline'
  | 'onKeywordClick'
  | 'onPause'
  | 'onPlayPhrase'
  | 'playing'
>;

export interface TranscriptTabProps
  extends PickedFormProps,
    PickedTranscriptProps {
  onModalHide?: () => void;
  onModalShow?: () => void;
  onUploadCaptionsClick?: CaptionsFormProps['onClickCaptionUpload'];
  positionMillis: number;
  syncTranscriptWithPlayback: boolean;
  transcriptStatus: TranscriptStatus;
}

const TranscriptTab: React.SFC<TranscriptTabProps> = ({
  captionsEnabled,
  onCopyPhraseToTimeline,
  onKeywordClick,
  onModalHide,
  onModalShow,
  onPause,
  onPlayPhrase,
  onUploadCaptionsClick,
  playing,
  positionMillis,
  syncTranscriptWithPlayback,
  transcriptStatus,
}) => {
  const { captionLanguage } = useSelector(captionsPrefsSelector);
  const [showModal, setShowModal] = useState<CaptionsModal>();
  const [language, setLanguage] = useState<string>(captionLanguage || 'en-US');
  const prevShowModal = usePrevious(showModal);
  const recordingId = useSelector(recordingIdSelector);
  const durationInMilli = useSelector(trackDurationInMilliSelector);

  const {
    balanceMillis,
    hasBalance,
    newBalanceMillis,
  } = useTranscriptionBalance({
    source: 'project',
    durationMillis: durationInMilli,
  });
  const isFree = useSelector(isFreeSelector);
  const showBalance = isFree && hasBalance;
  const canTranscribe =
    hasTimeRemaining(dayjs.duration(balanceMillis)) && newBalanceMillis > 0;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!prevShowModal && showModal) {
      onModalShow();
    } else if (prevShowModal && !showModal) {
      onModalHide();
    }
  }, [onModalShow, onModalHide, prevShowModal, showModal]);

  const handleStyleButtonClick = useCallback(() => setShowModal('edit'), [
    setShowModal,
  ]);

  const handleUploadButtonClick = useCallback(() => setShowModal('add'), [
    setShowModal,
  ]);

  const handleDownloadClick = useCallback(() => {
    dispatch(pushModal({ name: 'DownloadProjectCaptions' }));
  }, [dispatch]);

  const handleModalHide = useCallback(() => setShowModal(undefined), [
    setShowModal,
  ]);

  const handleUploadCaptionsClick = useCallback(() => {
    handleUploadButtonClick();
    onUploadCaptionsClick();
  }, [handleUploadButtonClick, onUploadCaptionsClick]);

  const handleCaptionGeneration = useCallback(() => {
    dispatch(generateCaptions(recordingId, language));
  }, [dispatch, recordingId, language]);

  return (
    <AssetsPaneContent className={block('transcript-tab')}>
      {transcriptStatus !== TranscriptStatus.TRANSCRIPT_STATUS_IN_PROGESS && (
        <CaptionsForm
          captionsEnabled={captionsEnabled}
          onClickCaptionUpload={handleUploadCaptionsClick}
          onStyleButtonClick={handleStyleButtonClick}
          transcriptStatus={transcriptStatus}
          onDownloadClick={handleDownloadClick}
        />
      )}
      {transcriptStatus === TranscriptStatus.TRANSCRIPT_STATUS_AVAILABLE ? (
        <Transcript
          onCopyPhraseToTimeline={onCopyPhraseToTimeline}
          onKeywordClick={onKeywordClick}
          onPause={onPause}
          onPlayPhrase={onPlayPhrase}
          playing={playing}
          position={positionMillis}
          scrollToPhrase={syncTranscriptWithPlayback}
        />
      ) : (
        <TranscriptStatusDisplay
          status={transcriptStatus}
          language={language}
          onClickCaptionUpload={handleUploadCaptionsClick}
          onClickGenerateCaption={handleCaptionGeneration}
          onLanguageSelected={selected => {
            setLanguage(selected);
            dispatch(
              updateMyCaptionsPref({
                transcribe: true,
                language: selected,
                forcePrefsLoading: true,
              }),
            );
          }}
          canTranscribe={canTranscribe}
          showBalance={showBalance}
          durationInMilliseconds={durationInMilli}
        />
      )}

      <CaptionsModals
        onHide={handleModalHide}
        onOpenEditModal={handleStyleButtonClick}
        show={showModal}
        transcriptStatus={transcriptStatus}
        openUploadCaptionsModal={handleUploadButtonClick}
      />
    </AssetsPaneContent>
  );
};

export default TranscriptTab;

import * as React from 'react';
import { useDispatch } from 'react-redux';

import ProjectsList from 'blocks/ProjectHistory/components/ProjectsList';
import LoadingMessage from 'components/LoadingMessage';
import { getMyEpisodeProjects } from 'redux/modules/automation-cms/actions';
import useEpisodeProjects from '../../hooks/useEpisodeProjects';
import { EpisodeRowContentsProps } from '../../types';

import { episodeListBlock as block } from '../../utils';

const { useEffect } = React;

const EpisodeRowContents: React.FC<EpisodeRowContentsProps> = ({
  entered,
  episodeId,
  onProjectControlClick,
  projectsUrl,
}) => {
  const dispatch = useDispatch();
  const { isLoading, projects } = useEpisodeProjects(episodeId);

  useEffect(() => {
    if (entered && !projects && isLoading === undefined) {
      dispatch(getMyEpisodeProjects(episodeId, projectsUrl));
    }
  }, [entered, projects, isLoading, dispatch, episodeId, projectsUrl]);

  if (isLoading || isLoading === undefined) {
    return <LoadingMessage />;
  }

  return (
    <ProjectsList
      onControlClick={onProjectControlClick}
      projectRowHeaderClassName={block('project-row-header')}
      projects={projects || []}
    />
  );
};

export default EpisodeRowContents;

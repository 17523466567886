import * as React from 'react';
import { useSelector } from 'react-redux';
import { noop, pick } from 'underscore';

import UseAuthModal from 'blocks/Authentication/containers/UseAuthModal';
import { DestinationPreset } from 'blocks/DestinationPlatforms';
import { AudioClipperComponent } from 'components/AudioClipper';
import { UseHook } from 'components/UseHook';
import {
  ActionType,
  TemplateEditorStateExport,
} from 'components/VideoTemplateEditor';
import { Style } from 'components/VideoTemplateEditor/useOnStyleChange';
import Wizard, { IndexedStep, Navigator, Step } from 'components/Wizard';
import { finalAudioDurationMillisSelector } from 'redux/modules/audio-wizard';
import { userIntegratorsSelector } from 'redux/modules/auth';
import {
  AspectRatioDimensions,
  AspectRatioName,
  AudioClipOffsets,
  Soundwave,
  TemplateType,
  VideoTypes,
} from 'types';
import { getAspectRatio, getAspectRatioValue } from 'utils/aspect-ratio';
import { BLANK_CANVAS_TEMPLATE_ID } from 'utils/constants';
import { soundwavePositionToDimensions } from 'utils/embed/soundwave';
import { FatalError } from 'utils/FatalError';
import { millisToSec } from 'utils/time';
import { Transcription } from '../../AutomatedWizard/types';
import CustomizeStep, { CustomizeStepSubmitType } from '../../CustomizeStep';
import { CustomizeStepView } from '../../CustomizeStep/CustomizeStep';
import CustomizeStepCta from '../../CustomizeStep/CustomizeStepCta';
import { TranscriptionConfig, TranscriptionFormValue } from '../../types';
import { UseAudioUploadValue } from '../../UseAudioUpload';
import { createDefaultSoundwave } from '../../utils';
import AudioWizardClipStep from '../AudioWizardClipStep';
import AudioWizardExportVideoStep from '../AudioWizardExportVideoStep';
import AudioWizardProgressStep from '../AudioWizardProgressStep';
import { WizardStep } from '../constants';
import UseAudioWizardAudio from '../UseAudioWizardAudio';
import useAudiogramWizardBlocker from '../useAudioWizardBlocker';
import { block } from '../utils';
import { getBrandingOptions } from './utils';

interface SoundwaveDimension {
  height: string | number;
  width: string | number;
  left: string | number;
  top: string | number;
}

export interface WizardNextStepMetadata {
  socialPreset?: DestinationPreset;
}

type SoundwavePosition = Pick<SoundwaveDimension, 'left' | 'top'>;
type SoundwaveSize = Pick<SoundwaveDimension, 'height' | 'width'>;

export interface SubmitConfig extends TemplateEditorStateExport {
  aspectRatio: AspectRatioDimensions;
  audioClip: AudioClipOffsets;
  isAudiogram: boolean;
  projectName: string;
  templateId: string | 'none';
  transcription: Transcription;
}

interface IProps {
  allowAudioClipping?: boolean;
  defaultProjectName?: string;
  defaultImageSrc?: string;
  defaultText?: string;
  episodeTitle?: string;
  defaultSoundwaveColor?: string;
  integratorName?: string;
  isAudiogram?: boolean;
  onComplete: () => void;
  onCancelClick?: (nav: Navigator) => void;
  onError?: (error: Error) => void;
  onMount?: () => void;
  onStepChange?: (
    prev: WizardStep,
    next: WizardStep,
    metadata: WizardNextStepMetadata,
  ) => void;
  onSubmitClick?: (type: CustomizeStepSubmitType, config: SubmitConfig) => void;
  onUnmount?: () => void;
  podcastTitle?: string;
  waitingForStandardization?: boolean;
  traceId?: string;
  customTraceId?: string;
  episodeId?: string;
  podcastId?: string;
  transcriptionConfig?: TranscriptionConfig;
  transcriptUrl?: string;
}

interface IState {
  aspectRatioName: AspectRatioName;
  clipperPlaying: boolean;
  customizeStepView: CustomizeStepView;
  socialPreset?: DestinationPreset;
  soundwave: Soundwave;
  templateId: string;
  transcription: TranscriptionFormValue;
  step: WizardStep;
  submitType: CustomizeStepSubmitType;
}

const getDefaultWavePosition = (): SoundwavePosition =>
  pick(soundwavePositionToDimensions('padded-bottom'), 'top', 'left');

const getDefaultWaveSize = (): SoundwaveSize =>
  pick(soundwavePositionToDimensions('padded-bottom'), 'height', 'width');

export default class AudioWizard extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    allowAudioClipping: false,
    onCancelClick: noop,
    onError: noop,
    onMount: noop,
    onSubmitClick: noop,
    onUnmount: noop,
  };

  private clipper?: AudioClipperComponent;

  private wizardRef = React.createRef<Wizard>();

  public state: Readonly<IState> = {
    aspectRatioName: 'square',
    clipperPlaying: undefined,
    customizeStepView: 'templates',
    socialPreset: undefined,
    soundwave: createDefaultSoundwave({
      ...(this.props.defaultSoundwaveColor
        ? {
            waveColor: this.props.defaultSoundwaveColor,
          }
        : {}),
      wavePosition: getDefaultWavePosition(),
      waveSize: getDefaultWaveSize(),
      waveType: 'wideRoundBars',
    }),
    step: undefined,
    submitType: undefined,
    // select template id for `aspectRatioName` above
    templateId: BLANK_CANVAS_TEMPLATE_ID.square,
    transcription: {
      language: 'en-US',
      transcribe:
        this.props.transcriptionConfig?.lockedConfig?.transcribe || false,
    },
  };

  public componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  public componentDidUpdate(
    { onStepChange }: IProps,
    { step: prevStep }: IState,
  ) {
    const { socialPreset, step } = this.state;

    if (step !== prevStep) {
      onStepChange(prevStep, step, { socialPreset });
    }
  }

  public componentWillUnmount() {
    const { onUnmount } = this.props;
    onUnmount();
  }

  private handleSubmitClick = async (
    { type, projectName, transcriptionEnabled },
    config,
  ) => {
    const { isAudiogram, onSubmitClick, onError } = this.props;
    const { aspectRatioName, templateId, transcription } = this.state;

    this.setState({ submitType: type });

    try {
      const submission = onSubmitClick(type, {
        ...config,
        isAudiogram,
        projectName,
        templateId,
        transcription: {
          ...transcription,
          transcribe: transcriptionEnabled ?? transcription?.transcribe,
        },
        aspectRatio: getAspectRatio(aspectRatioName)?.toJS(),
        audioClip: this.clipper?.clip,
      });
      this.wizardRef.current.next();

      await submission;
    } catch (error) {
      onError(new FatalError(error.message));
    }
  };

  private handleStepChange = (
    toStep: IndexedStep<WizardStep>,
    fromStep?: IndexedStep,
  ) => {
    this.setState({ step: toStep.stepId });
    if (fromStep && fromStep.stepId === WizardStep.CLIP_AUDIO) {
      this.setState({ clipperPlaying: false });
    }
  };

  private handleAspectRatioSelect = (aspectRatioName: AspectRatioName) =>
    this.setState({ aspectRatioName });

  private handleChangePreset = (socialPreset?: DestinationPreset): void => {
    this.setState({ socialPreset });
  };

  private handleTranscriptionChange = (transcription: TranscriptionFormValue) =>
    this.setState({ transcription });

  private handleStyleChange = (style: Style, actionType: ActionType) => {
    const { transcription } = style;

    if (actionType === 'TRANSCRIPTION_CHANGE') {
      this.handleTranscriptionChange(transcription);
    }
  };

  private handleTemplateSelect = (templateId: string) => {
    this.setState({ templateId });
  };

  private clipperRef = el => (this.clipper = el);

  private handleSubmitComplete = () => {
    const { isAudiogram, onComplete } = this.props;
    const { submitType } = this.state;

    if (isAudiogram && submitType !== 'export') {
      onComplete();
    } else {
      this.wizardRef.current.next();
    }
  };

  private handleCustomizeStepViewChange = (value: CustomizeStepView) =>
    this.setState({ customizeStepView: value });

  private getSteps({
    afterUploadTransfer,
    audioRegion,
    onAudioAdded,
  }: UseAudioUploadValue): Step[] {
    const {
      allowAudioClipping,
      defaultImageSrc,
      defaultProjectName,
      defaultText,
      episodeTitle,
      integratorName,
      isAudiogram,
      waitingForStandardization,
      onError,
      episodeId,
      podcastId,
      podcastTitle,
      transcriptUrl,
    } = this.props;
    const {
      aspectRatioName,
      clipperPlaying,
      customizeStepView,
      soundwave,
      templateId,
      transcription,
    } = this.state;

    const aspectRatio = getAspectRatioValue(aspectRatioName);
    const brandingOptions = getBrandingOptions({
      integratorName,
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const finalAudioDurationMillis = useSelector(
      finalAudioDurationMillisSelector,
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const integrators = useSelector(userIntegratorsSelector);
    const transcriptionEnabled = transcription.transcribe;

    return [
      !allowAudioClipping
        ? undefined
        : {
            component: (step: IndexedStep) => (
              <AudioWizardClipStep
                hidden={step.stepId !== WizardStep.CLIP_AUDIO}
                clipperRef={this.clipperRef}
                onLoadAudio={onAudioAdded}
                onReady={audioRegion.setSelected}
                onPresetChange={this.handleChangePreset}
                onTranscriptionChange={this.handleTranscriptionChange}
                playing={clipperPlaying}
                transcription={transcription}
              />
            ),
            description:
              'Audio clips over 10 minutes cannot be opened in the editor.',
            keepMounted: true,
            name: 'Clip Audio',
            renderCancelButton: () => null,
            renderNextButton: buttonProps => (
              <Wizard.Button
                {...buttonProps}
                disabled={
                  !audioRegion.regionSelected || waitingForStandardization
                }
                onClick={() => this.wizardRef.current.jump('customize')}
                theme="next"
              >
                next
              </Wizard.Button>
            ),
            stepId: WizardStep.CLIP_AUDIO,
            title: 'Trim your audio clip',
          },
      {
        component: (
          <CustomizeStep
            transcription={transcription}
            onStyleChange={this.handleStyleChange}
            addTextPlaceholder={defaultText}
            brandingOptions={brandingOptions}
            onSelectAspectRatio={this.handleAspectRatioSelect}
            audioClipDurationSec={millisToSec(finalAudioDurationMillis)}
            compatibilityTypes={
              isAudiogram
                ? [VideoTypes.SHORT_CLIP, VideoTypes.FULL_EPISODE]
                : [VideoTypes.FULL_EPISODE]
            }
            defaults={{
              aspectRatio,
              soundwave,
              backgroundColor: 'black',
              keyAssets: {
                episodeTitle,
                mainImage: defaultImageSrc,
                podcastTitle,
              },
            }}
            episodeId={episodeId}
            features={{
              progress: isAudiogram ? true : 'hidden',
              waveform: {
                fidelity: isAudiogram
                  ? true
                  : {
                      disabled: true,
                      reason: 'Full episodes use lo-fi wave generation',
                    },
              },
              captions: integrators.length ? 'hidden' : true,
            }}
            onChangeView={this.handleCustomizeStepViewChange}
            onError={onError}
            onSelectTemplate={this.handleTemplateSelect}
            podcastId={podcastId}
            renderCta={({ className, disabled, exportEditorState, state }) => (
              <UseAuthModal origin="audioWizard">
                {({ withAuthentication }) => (
                  <CustomizeStepCta
                    // duration is only used to force skipping editor for long projects.
                    // we already have access to isAudiogram, which is a proxy for whether
                    // or not the user must skip the editor
                    durationMillis={isAudiogram ? 0 : Infinity}
                    {...{
                      className,
                      defaultProjectName,
                      disabled,
                      exportEditorState,
                      state,
                      transcriptionEnabled,
                    }}
                    transcriptWithNoClipping={
                      transcriptionEnabled ||
                      (!!transcriptUrl && !allowAudioClipping)
                    }
                    isTranscriptionEnabled={transcriptionEnabled}
                    onClick={withAuthentication(
                      afterUploadTransfer(this.handleSubmitClick),
                    )}
                  />
                )}
              </UseAuthModal>
            )}
            templateId={templateId}
            templateTypes={[
              TemplateType.HEADLINER_DEFAULT,
              TemplateType.USER_GENERATED,
              'blank',
            ]}
            transcriptionEnabled={transcriptionEnabled}
            view={customizeStepView}
          />
        ),
        name: 'Customize',
        renderCancelButton: () => null,
        renderNextButton: () => null,
        stepId: WizardStep.CUSTOMIZE,
        title: customizeStepView === 'edit' ? null : 'Choose a template',
      },
      {
        component: (
          <AudioWizardProgressStep
            onCompleted={this.handleSubmitComplete}
            onError={onError}
          />
        ),
        name: 'Creating Project',
        animationEnabled: true,
        stepId: WizardStep.SUBMITTING,
        renderCancelButton: () => null,
        renderNextButton: () => null,
        showInNav: false,
      },
      {
        component: (
          <AudioWizardExportVideoStep
            onError={error => {
              this.wizardRef?.current.jump(WizardStep.CUSTOMIZE);
              onError(error);
            }}
          />
        ),
        name: 'Exporting video',
        animationEnabled: true,
        renderCancelButton: () => null,
        renderNextButton: () => null,
        showInNav: false,
        stepId: WizardStep.EXPORTING,
      },
    ].filter(Boolean);
  }

  public render() {
    const { onCancelClick } = this.props;
    const { step } = this.state;

    return (
      <UseAudioWizardAudio>
        {props => {
          const steps = this.getSteps(props);
          return (
            <UseHook hook={useAudiogramWizardBlocker} args={[step]}>
              {onStepClick => (
                <Wizard
                  ref={this.wizardRef}
                  canBacktrack={
                    step !== WizardStep.EXPORTING &&
                    step !== WizardStep.SUBMITTING
                  }
                  className={block()}
                  bodyClassName={block('body', { [step]: true })}
                  onCancelClick={onCancelClick}
                  onStepChange={this.handleStepChange}
                  onStepClick={onStepClick}
                  steps={steps}
                />
              )}
            </UseHook>
          );
        }}
      </UseAudioWizardAudio>
    );
  }
}

export { IProps as AudioWizardProps };

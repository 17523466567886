import { useMachine } from '@xstate/react';
import { assign, Machine } from 'xstate';

import { ChildView, VideoTemplateStateContent } from '../../types';
import {
  NavigationMachineEvent,
  OpenChildViewEvent,
} from '../../types/navigation-actions';
import { childViewMachine } from './child-view-machine';

export interface NavigationMachineContextType
  extends Pick<VideoTemplateStateContent['template'], 'templateType'> {
  pendingChildView: ChildView;
  id: string | undefined;
}

interface NavigationMachineSchema {
  states: {
    unknown: {};
    idle: {};
    child: {
      states: {
        effects: {};
        image: {};
        images: {};
        intro_outro: {};
        intro_outro_edit: {};
        watermark: {};
        progress: {};
        text: {};
        waveform: {};
        captions: {};
      };
    };
  };
}

const matchesChildView = (view: ChildView) => (
  ctx: NavigationMachineContextType,
  event,
) => {
  if (event.type === 'CHILD_VIEW_OPEN') {
    return event.payload === view;
  }

  return (
    ctx.pendingChildView !== event.data.childView &&
    ctx.pendingChildView === view
  );
};

const childViewTransitions = [
  { target: '#nav.child.effects', cond: 'isEffectsChildView' },
  { target: '#nav.child.image', cond: 'isImageChildView' },
  { target: '#nav.child.images', cond: 'isImagesChildView' },
  { target: '#nav.child.intro_outro', cond: 'isIntroOutroChildView' },
  { target: '#nav.child.intro_outro_edit', cond: 'isIntroOutroEditChildView' },
  { target: '#nav.child.watermark', cond: 'isWatermarkformChildView' },
  { target: '#nav.child.progress', cond: 'isProgressChildView' },
  { target: '#nav.child.text', cond: 'isTextChildView' },
  { target: '#nav.child.waveform', cond: 'isWaveformChildView' },
  { target: '#nav.child.captions', cond: 'isCaptionsChildView' },
];

const invokeChildViewMachine = (view: ChildView) => ({
  on: {
    CHILD_VIEW_OPEN: {
      actions: assign({
        pendingChildView: (
          ctx: NavigationMachineContextType,
          event: OpenChildViewEvent,
        ) => {
          if (
            event.payload &&
            event.payload !== view &&
            event.payload !== ctx.pendingChildView
          ) {
            return event.payload;
          }
          return ctx.pendingChildView;
        },
        id: (_: NavigationMachineContextType, event: OpenChildViewEvent) => {
          return event.meta.id;
        },
      }),
    },
  },
  invoke: {
    autoForward: true,
    data: {
      childView: view,
    },
    id: 'childView',
    src: childViewMachine,
    onDone: [...childViewTransitions, { target: '#nav.idle' }],
  },
});

const navigationMachine = Machine<
  NavigationMachineContextType,
  NavigationMachineSchema,
  NavigationMachineEvent
>(
  {
    id: 'nav',
    initial: 'unknown',
    on: {},
    states: {
      unknown: {
        on: {
          '': [{ target: 'idle' }],
        },
      },
      idle: {
        entry: assign({
          pendingChildView: () => undefined,
        }),
        on: {
          CHILD_VIEW_OPEN: childViewTransitions,
        },
      },
      child: {
        states: {
          effects: invokeChildViewMachine('effects'),
          image: invokeChildViewMachine('image'),
          images: invokeChildViewMachine('images'),
          intro_outro: invokeChildViewMachine('intro_outro'),
          intro_outro_edit: invokeChildViewMachine('intro_outro_edit'),
          watermark: invokeChildViewMachine('watermark'),
          progress: invokeChildViewMachine('progress'),
          text: invokeChildViewMachine('text'),
          waveform: invokeChildViewMachine('waveform'),
          captions: invokeChildViewMachine('captions'),
        },
        entry: assign({
          id(_: NavigationMachineContextType, event: OpenChildViewEvent) {
            return event?.meta?.id;
          },
        }),
      },
    },
  },
  {
    guards: {
      isEffectsChildView: matchesChildView('effects'),
      isImageChildView: matchesChildView('image'),
      isImagesChildView: matchesChildView('images'),
      isIntroOutroChildView: matchesChildView('intro_outro'),
      isIntroOutroEditChildView: matchesChildView('intro_outro_edit'),
      isWatermarkformChildView: matchesChildView('watermark'),
      isProgressChildView: matchesChildView('progress'),
      isTextChildView: matchesChildView('text'),
      isWaveformChildView: matchesChildView('waveform'),
      isCaptionsChildView: matchesChildView('captions'),
    },
  },
);

export function useNavigation(context: NavigationMachineContextType) {
  return useMachine(navigationMachine, {
    context,
  });
}

export default navigationMachine;

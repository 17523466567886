import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  canGenerateVideoSocialPostCaptionsSelector,
  createVideoSocialPostCaptions,
  hasVideoSocialPostCaptionInfoSelector,
  videoSocialPostCaptionInfoByPlatformSelector,
} from 'redux/modules/download';
import { UseSocialPostCaptionsResult } from './types';

export type UseVideoSocialPostCaptionsResult = UseSocialPostCaptionsResult;

export default function useVideoSocialPostCaptions(
  creationRequestId: number | undefined,
): UseVideoSocialPostCaptionsResult {
  const dispatch = useDispatch();

  const socialPostCaptionInfoByPlatform = useSelector(
    videoSocialPostCaptionInfoByPlatformSelector,
  );

  const hasSocialPostCaptions = useSelector(
    hasVideoSocialPostCaptionInfoSelector,
  );

  const canGenerateSocialPostCaptions = useSelector(
    canGenerateVideoSocialPostCaptionsSelector,
  );

  const handleCreateSocialPostCaptions = useCallback(() => {
    if (creationRequestId && canGenerateSocialPostCaptions) {
      dispatch(createVideoSocialPostCaptions(creationRequestId));
    }
  }, [canGenerateSocialPostCaptions, creationRequestId, dispatch]);

  return {
    canGenerateSocialPostCaptions,
    hasSocialPostCaptions,
    socialPostCaptionInfoByPlatform,
    createSocialPostCaptions: handleCreateSocialPostCaptions,
  };
}

import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import { SPAREMIN_SERVICES_KEYWORDEXTRACTOR_URL } from 'config';

import { getValue } from 'utils/collections';
import { createRequest } from '../utils';
import * as types from './types';

const keywordsSchema = new schema.Entity(
  'keywords',
  {},
  {
    idAttribute: keywords => keywords.id,
  },
);

const summariesSchema = new schema.Entity(
  'summaries',
  {},
  {
    idAttribute: summary => summary.urlSummaryId,
  },
);

function analyze(
  apiUrl: string,
  id: types.Id,
  recommendation: boolean,
  aspectRatio: types.IAspectRatio,
  request: SuperAgentStatic,
) {
  const fields = ['detail'];
  if (recommendation) {
    fields.push('typedRecommendation');
  }

  const { height: aspectRatioHeight, width: aspectRatioWidth } =
    aspectRatio || ({} as types.IAspectRatio);

  return request
    .get(apiUrl)
    .query({ fields, aspectRatioHeight, aspectRatioWidth })
    .then(({ body }) => {
      const detail = (body && body.detail) || [];
      const keywords = { detail, id };
      const normalizedKeywords = normalize(keywords, keywordsSchema);
      const { typedRecommendation } = body || ({} as any);
      const isResolved =
        !recommendation ||
        getValue(body, ['statuses', 'typedRecommendation', 'isResolved']);
      return {
        ...normalizedKeywords,
        isResolved,
        typedRecommendation,
      };
    });
}

const analyzeRecordingVersion = (
  [versionId, recommendation, aspectRatio]: types.AnalyzeRecordingVersionArgs,
  request: SuperAgentStatic,
) =>
  analyze(
    `/api/v1/keyword-extractor/analyzer/recording-version/${versionId}`,
    versionId,
    recommendation,
    aspectRatio,
    request,
  );

const analyzeTextBlob = (
  [id, recommendation, aspectRatio]: types.AnalyzeTextBlobArgs,
  request: SuperAgentStatic,
) =>
  analyze(
    `/api/v1/keyword-extractor/analyzer/text-blob/${id}`,
    id,
    recommendation,
    aspectRatio,
    request,
  );

const summarize = ([url]: types.SummarizeArgs, request: SuperAgentStatic) => {
  return request
    .post('/api/v1/keyword-extractor/summarize')
    .send({ url })
    .then(({ body }) => normalize(body, summariesSchema));
};

export const handle = (method: any, args: any, token?: string): any => {
  const request = createRequest({
    token,
    baseUrl: SPAREMIN_SERVICES_KEYWORDEXTRACTOR_URL,
  });

  switch (method) {
    case types.ServiceMethod.ANALYZE_RECORDING_VERSION:
      return analyzeRecordingVersion(args, request);

    case types.ServiceMethod.ANALYZE_TEXT_BLOB:
      return analyzeTextBlob(args, request);

    case types.ServiceMethod.SUMMARIZE:
      return summarize(args, request);

    default:
      throw new Error(`${types.ACTION_KEY} cannot handle method ${method}`);
  }
};

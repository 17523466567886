import { GooeyTextRenderer } from '@sparemin/text-effects';
import React from 'react';
import { throttle } from 'underscore';

import { useTextOverlay } from '../state/TextOverlayProvider';
import { Size } from '../types';

interface UseTextOverlayGooeyEffect {
  resizeDeltaObserver: (resizeDelta: Size) => void;
}

const RESIZE_THROTLE_MS = 20;

const useTextOverlayGooeyEffect = (): UseTextOverlayGooeyEffect => {
  const {
    canvasSize,
    draftEditorData,
    editorHtmlRef,
    textValue,
  } = useTextOverlay();

  const gooeyEffectRendererRef = React.useRef<GooeyTextRenderer>();

  const gooeyTextConfig = React.useMemo(() => {
    return draftEditorData.getIn(['advancedTextConfigs', 'gooeyText'])?.toJS();
  }, [draftEditorData]);

  const handleRedraw = React.useMemo(
    () =>
      throttle(
        () => {
          if (!gooeyEffectRendererRef.current) {
            return;
          }

          if (!gooeyTextConfig) {
            gooeyEffectRendererRef.current.clear();
          } else {
            requestAnimationFrame(() => {
              gooeyEffectRendererRef.current.redraw(gooeyTextConfig);
            });
          }
        },
        // Disabling the leading request prevents some blinks when activated at
        // the same time that the box fit checker is enabled.
        RESIZE_THROTLE_MS,
        { leading: false },
      ),
    [gooeyTextConfig],
  );

  React.useEffect(() => {
    if (!editorHtmlRef.current) {
      return;
    }

    gooeyEffectRendererRef.current = new GooeyTextRenderer(
      editorHtmlRef.current,
    );
  }, [editorHtmlRef]);

  React.useEffect(() => {
    handleRedraw();
    // The useEffect dependencies are overloaded with some non used
    // deps for triggering redrawings when any of the involved reactive
    // variables change.
  }, [canvasSize, handleRedraw, textValue]);

  // This effect is necessary because fonts might present a delay for being loaded.
  // In screen this can be seen as a small period of time with the font set to a serif
  // font and then for it to be switched to the real font face. For that reason when
  // the loadingdone event is fired, an extra redraw is done.
  React.useEffect(() => {
    document.fonts.addEventListener('loadingdone', handleRedraw);

    return () => {
      document.fonts.removeEventListener('loadingdone', handleRedraw);
    };
  }, [handleRedraw]);

  return {
    resizeDeltaObserver: handleRedraw,
  };
};

export default useTextOverlayGooeyEffect;

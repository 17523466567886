import { useCallback } from 'react';
import { DEFAULT_CLIP_START_MILLIS } from '../../constants';
import { ClipSuggestion } from '../../types';
import { VideoPlayerRefs } from './types';

export interface UseClipSelectVideoPlayerResult {
  onSelectionChange: (suggestionId: number) => void;
  play: () => void;
  pause: () => void;
}

/**
 * Hook to manage playback state for the suggestion video players.
 */
export default function useClipSelectVideoPlayer(
  videoPlayerRefs: VideoPlayerRefs,
  clipSuggestion: ClipSuggestion,
): UseClipSelectVideoPlayerResult {
  const activeSuggestionId = clipSuggestion?.id;
  const player = videoPlayerRefs?.[activeSuggestionId]?.current;

  const play = useCallback(
    (element?: HTMLVideoElement): void => {
      const currentPlayer = videoPlayerRefs?.[activeSuggestionId]?.current;
      const videoElement = element ?? currentPlayer;

      if (videoElement) {
        videoElement.play();
      }
    },
    [activeSuggestionId, videoPlayerRefs],
  );

  const pause = useCallback(
    (element?: HTMLVideoElement): void => {
      const currentPlayer = videoPlayerRefs?.[activeSuggestionId]?.current;
      const videoElement = element ?? currentPlayer;

      if (videoElement) {
        videoElement.pause();
        videoElement.currentTime = DEFAULT_CLIP_START_MILLIS;
      }
    },
    [activeSuggestionId, videoPlayerRefs],
  );

  const handleSelectionChange = useCallback(
    (suggestionId: number) => {
      const newPlayer = videoPlayerRefs?.[suggestionId].current;
      const isPlaying = player && !player?.paused;

      if (newPlayer === player || !newPlayer) {
        return;
      }

      newPlayer.currentTime = DEFAULT_CLIP_START_MILLIS;

      if (isPlaying) {
        pause(player);
        play(newPlayer);
      }
    },
    [pause, play, player, videoPlayerRefs],
  );

  return {
    onSelectionChange: handleSelectionChange,
    play: () => play(),
    pause: () => pause(),
  };
}

import { List, ListProps, ThemeProvider } from '@sparemin/blockhead';
import * as React from 'react';

import { useSelector } from 'react-redux';
import { podcastFeedEpisodesSelector } from 'redux/modules/entities';
import EpisodeListEpisodeRow from '../containers/EpisodeListEpisodeRow';
import {
  episodeListBlock as block,
  getPodcastFeedEpisodeFromId,
} from '../utils';
import EpisodeListAutomationsRow from './EpisodeListAutomationsRow';

export interface AutomationCMSEpisodeListProps
  extends Pick<ListProps, 'hasMore'> {
  episodes: string[];
  workflows: number[];
  onLoadMoreEpisodes?: () => void;
}

export const AUTOMATIONS_ROW_KEY = 'automations';

const AutomationCMSEpisodeList: React.FC<AutomationCMSEpisodeListProps> = ({
  episodes = [],
  workflows = [],
  hasMore,
  onLoadMoreEpisodes,
}) => {
  const [selectedEpisode, setSelectedEpisode] = React.useState<
    string | undefined
  >();

  const podcastFeedEpisodes = useSelector(podcastFeedEpisodesSelector);

  const { disabledKeys, items } = episodes.reduce(
    (acc, episodeId) => {
      const { projectInfo, title } =
        getPodcastFeedEpisodeFromId(episodeId, podcastFeedEpisodes) ?? {};

      const isProcessing = projectInfo?.status === 'processing';

      const item = (
        <List.Item key={episodeId} textValue={title}>
          <EpisodeListEpisodeRow
            active={episodeId === selectedEpisode}
            {...{ episodeId, workflows }}
          />
        </List.Item>
      );

      // Modeled as an array so that it can be spread into disabledKeys array
      // without having to worry about removing undefined values later.
      const disabledKey = isProcessing ? [episodeId] : [];

      return {
        disabledKeys: [...acc.disabledKeys, ...disabledKey],
        items: [...acc.items, item],
      };
    },
    {
      disabledKeys: [],
      items: [
        <List.Item key={AUTOMATIONS_ROW_KEY}>
          <EpisodeListAutomationsRow
            active={selectedEpisode === AUTOMATIONS_ROW_KEY}
            workflows={workflows}
          />
        </List.Item>,
      ],
    },
  );

  const handleRowClick = React.useCallback(
    (id: string): void => {
      if (id === selectedEpisode) {
        setSelectedEpisode(undefined);
      } else {
        setSelectedEpisode(id);
      }
    },
    [selectedEpisode],
  );

  const handleAutomationsRowClick = React.useCallback((): void => {
    if (!workflows?.length) {
      return undefined;
    }

    return handleRowClick(AUTOMATIONS_ROW_KEY);
  }, [handleRowClick, workflows]);

  const handleRowEpisodeClick = React.useCallback(
    (episodeRowId: string): void => {
      if (episodeRowId === AUTOMATIONS_ROW_KEY) {
        return handleAutomationsRowClick();
      }

      return handleRowClick(episodeRowId);
    },
    [handleAutomationsRowClick, handleRowClick],
  );

  return (
    <ThemeProvider theme="dark">
      <List
        className={block()}
        aria-label="episodes list"
        onAction={handleRowEpisodeClick}
        onLoadMore={onLoadMoreEpisodes}
        {...{ hasMore, disabledKeys }}
      >
        {items}
      </List>
    </ThemeProvider>
  );
};

export default AutomationCMSEpisodeList;

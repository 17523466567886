import React from 'react';
import useObjectUrl from 'hooks/useObjectUrl';

import useMainMedia from '../useMainMedia';
import { imageViewBlock as block } from '../utils';

const EffectsChildViewContent: React.FunctionComponent = () => {
  const { integration, asset } = useMainMedia();

  const childViewProps = React.useMemo(
    () => ('childViewProps' in integration ? integration.childViewProps : {}),
    [integration],
  );

  const thumbnailSrc =
    'original' in asset ? asset.original.previewThumbnail?.url : '';
  const backgroundImageUrl = useObjectUrl(thumbnailSrc);

  return (
    <div>
      <div
        className={block('background-image')}
        style={{
          background: [
            'linear-gradient(to bottom, rgba(50, 55, 50, 0.75), #323746)',
            backgroundImageUrl &&
              `url(${backgroundImageUrl}) center center / cover no-repeat`,
          ]
            .filter(Boolean)
            .join(','),
        }}
      />
      {childViewProps.body}
    </div>
  );
};

export default EffectsChildViewContent;

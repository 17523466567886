import { ButtonBase } from '@sparemin/blockhead';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  ImmutableTextPreset,
  makePresetSelector,
} from 'redux/modules/text-presets';

import { block } from './utils';

interface TextPresetButtonProps {
  isSelected: boolean;
  mode: 'horizontal' | 'vertical';
  onClick: (presetStyles: ImmutableTextPreset) => void;
  presetId: string;
}

const TextPresetButton: React.FunctionComponent<TextPresetButtonProps> = props => {
  const { isSelected, mode, onClick, presetId } = props;

  const preset = useSelector(makePresetSelector(presetId));

  const handleClickPreset = React.useCallback(() => {
    onClick(preset);
  }, [onClick, preset]);

  if (!preset || !preset.get('previewImageUrl')) {
    return null;
  }

  return (
    <ButtonBase
      className={block('preset', { [mode]: true, selected: isSelected })}
      onPress={handleClickPreset}
    >
      <img
        className={block('preset-preview', {
          [mode]: true,
          selected: isSelected,
        })}
        src={preset.get('previewImageUrl')}
        alt={preset.get('previewImageUrl')}
      />
    </ButtonBase>
  );
};

export default TextPresetButton;

import { fromJS } from 'immutable';
import { omit } from 'underscore';
import { DeepImmutableMap } from 'types';
import { omitUndefined } from 'utils/collections';

import { scaleInlineStyles } from 'utils/embed/text-overlay';
import { scale } from 'utils/numbers';
import { splitStyle } from 'utils/rte';

import { TEXT_STYLE_MAP } from '../utils';
import {
  EditorDataV2,
  ITextOverlayV2,
  Size,
  TextBuilderStyles,
  TextOverlayV2,
  TransitionsV2,
} from './types';

export const TEXT_OVERLAY_MODAL_VERSION = 2;

const DEFAULT_TRANSITIONS = fromJS({
  in: { duration: 0, value: 'cut' },
  out: { duration: 0, value: 'cut' },
});

/**
 * Sanitizes and builds the FE text builder styles:
 * - Replaces the fontsize which is in scaled px with corresponding vw value and unit.
 * - Removes any undefined styled.
 */
const buildTextBuilderStyles = (
  baseTextBuilderStyles: TextBuilderStyles,
  fontSizeVw: number,
): DeepImmutableMap<ITextOverlayV2['textBuilderStyles']> => {
  const textBuilderStyles = { ...baseTextBuilderStyles };

  textBuilderStyles.paragraphStyle.fontSize = `${fontSizeVw}vw`;
  textBuilderStyles.lineStyle.fontSize = `${fontSizeVw}vw`;
  textBuilderStyles.emptyLineStyle.fontSize = `${fontSizeVw}vw`;

  return fromJS({
    emptyLineStyle: omitUndefined(
      omit(textBuilderStyles.emptyLineStyle, ['fontSizeVw']),
    ),
    lineStyle: omitUndefined(omit(textBuilderStyles.lineStyle, ['fontSizeVw'])),
    paragraphStyle: omitUndefined(
      omit(textBuilderStyles.paragraphStyle, ['fontSizeVw']),
    ),
  });
};

/**
 * Calculates the fontSizeVw based on the overlay width percentage per font
 * px value and the current selected font size value.
 */
const calculateFontSizeVw = (draftEditorData: EditorDataV2): number =>
  draftEditorData.getIn(['editor', 'scaler', 'fontWidthPercPerPx']) *
  100 *
  draftEditorData.getIn(['editor', 'textStyle', 'fontSize']);

/**
 * Obtains the style data in the same way the legacy text overlay modal
 * would do.
 */
const getBakcwardCompatibleStyle = (draftEditorData: EditorDataV2) => {
  const containerStyle = draftEditorData.get('containerStyle');
  const textStyle = draftEditorData.getIn(['editor', 'textStyle']);
  const style = TEXT_STYLE_MAP.reduce((acc, [textStypeProp, styleProp]) => {
    const propValue = textStyle.get(textStypeProp);
    if (propValue) {
      return acc.set(styleProp, propValue);
    }
    return acc;
  }, containerStyle);

  return splitStyle(style).outerStyle.set(
    'lineHeight',
    style.get('lineHeight'),
  );
};

/**
 * The canvas node should not be sent to the backend as it is going to append
 * its own canvas while rendering, for that reason it is removed from the html.
 */
export const getHtmlString = (editorHtmlNode?: HTMLDivElement): string => {
  const clonedNode = editorHtmlNode.cloneNode(true) as HTMLDivElement;
  const canvasNode = clonedNode.getElementsByTagName('canvas')[0];
  if (canvasNode) {
    clonedNode.removeChild(canvasNode);
  }
  return String(clonedNode?.innerHTML);
};

/**
 * Maps the current editor data into an overlay object.
 */
export const mapDraftEditorDataToTextOverlay = (
  draftEditorData: EditorDataV2,
  htmlString: string,
  textValue: string,
  canvasSize: Size,
  baseTextBuilderStyles: TextBuilderStyles,
  transitions: TransitionsV2 = DEFAULT_TRANSITIONS,
): TextOverlayV2 => {
  // First step is to normalize the font size at the current html string.
  const unscaledHtmlString = scaleInlineStyles(
    htmlString,
    'fontSize',
    fontSize => {
      return `${scale(
        fontSize,
        canvasSize.width,
        draftEditorData.getIn(['viewport', 'width']),
      )}px`;
    },
  );

  // In order to be resilient to the original canvas dimension changes the
  // fontSize need to be saved also using vw.
  // In that way when restoring the style it is possible to recalculate the
  // font size using the same font scaler that was defined when initializing
  // the overlay.
  const fontSizeVw = calculateFontSizeVw(draftEditorData);

  // Taken from the original "convertEditorDataToOverlay" and kept for backwards compatibility:
  // the only data we need to store as part of style is the "outer" style (since it won't get
  // picked up by tinymce) and lineHeight, since it's an inner style that's applied from outside
  // of tinymce since it doesn't have a toolbar input.  all other styles are
  // applied via the RTE and exist on textHtml
  const backwardsCompatibleStyle = getBakcwardCompatibleStyle(draftEditorData);

  // Text builder styles are required by the backend for being able to style
  // the text overlay correctly for dynamic texts.
  const textBuilderStyles = buildTextBuilderStyles(
    baseTextBuilderStyles,
    fontSizeVw,
  );

  // Extracts the animation config from the editor data.
  const animation = draftEditorData.get('animation');

  // TODO: Add selected template when adding the text templates
  // TODO: Add transition when implementing text transitions
  const customizedTextOverlay: TextOverlayV2 = (draftEditorData.withMutations(
    s =>
      s
        .setIn(['editor', 'scaler', 'fontSizeVw'], fontSizeVw)
        .set('textBuilderStyles', textBuilderStyles)
        .set('animation', animation)
        .set('style', backwardsCompatibleStyle)
        .set('textHtml', unscaledHtmlString)
        .set('transitions', transitions)
        // Sets text value from the original text string at the editor textarea.
        // Opposed to previews crawling logic, the text value is now set by the editor
        .set('text', textValue)
        // Sets the version of the editor that has created the overlay config.
        .set('version', TEXT_OVERLAY_MODAL_VERSION)
        .delete('containerStyle'),
  ) as unknown) as TextOverlayV2;

  return customizedTextOverlay;
};

import React from 'react';
import { omit, pick } from 'underscore';

import { DisplayTextStyles, StateTextStyles, TextBuilderStyles } from './types';

const PARAGRAPH_INJECTED_STYLES: React.CSSProperties = {
  fontSize: 0,
  marginBottom: 0,
};

const SPAN_INJECTED_STYLES: React.CSSProperties = {
  left: 0,
  wordBreak: 'break-word',
  whiteSpace: 'normal',
};

/**
 * Obtains a css inline styles object for the overlay's html inner
 * style
 */
export const getInnerStyles = (
  textStyles: DisplayTextStyles | StateTextStyles,
): React.CSSProperties => {
  return {
    ...omit(textStyles, ['textAlign', 'textHighlight']),
    position: 'relative',
    display: 'inline',
  };
};

/**
 * Obtains a css inline styles object for the overlay's html outer
 * style
 */
export const getOuterStyles = (
  textStyles: DisplayTextStyles | StateTextStyles,
): React.CSSProperties => {
  return {
    ...pick(textStyles, ['textAlign']),
    position: 'relative',
    display: 'block',
    height: 'fit-content',
  };
};

/**
 * Parses the outer styles and appends the fixed paragraph styles for
 * styling an overlay html p element.
 */
export const getHtmlParagraphStyles = (
  outerStyles: React.CSSProperties,
): React.CSSProperties => {
  return {
    ...outerStyles,
    ...PARAGRAPH_INJECTED_STYLES,
  };
};

/**
 * Parses the inner styles and appends the fixed paragraph styles for
 * styling an overlay html span element.
 */
export const getHtmlSpanStyles = (
  innerStyles: React.CSSProperties,
): React.CSSProperties => {
  return {
    ...innerStyles,
    ...SPAN_INJECTED_STYLES,
  };
};

/**
 * Parses the inner styles and appends the fixed paragraph styles for
 * styling an overlay html empty span element.
 */
export const getEmptyLineStyles = (
  innerStyles: React.CSSProperties,
): React.CSSProperties => {
  return omit(innerStyles, ['background', 'textShadow', 'textDecoration']);
};

/**
 * Parses the config scaled text styles for obtaining the styles that should
 * be applied to the editor's text are
 */
export const getTextAreaStyles = (
  textStyles: DisplayTextStyles,
): React.CSSProperties => {
  const innerStyles = getInnerStyles(textStyles);
  const outerStyles = getOuterStyles(textStyles);

  return {
    ...outerStyles,
    ...omit(innerStyles, [
      'background',
      'textShadow',
      'textOutline',
      'webkitTextStroke',
    ]),
    background: 'transparent',
    color: 'transparent',
    caretColor: textStyles.color,
  };
};

/**
 * Gets the text builder style objects that will be applied to both
 * the preview and will also be sent to the backend for styling dynamic
 * text overlays.
 */
export const getTextBuilderStyles = (
  textStyles: DisplayTextStyles | StateTextStyles,
): TextBuilderStyles => {
  const innerStyles = getInnerStyles(textStyles);
  const outerStyles = getOuterStyles(textStyles);

  const paragraphStyle = getHtmlParagraphStyles(outerStyles);
  const lineStyle = getHtmlSpanStyles(innerStyles);
  const emptyLineStyle = getHtmlSpanStyles(getEmptyLineStyles(innerStyles));

  return {
    emptyLineStyle,
    lineStyle,
    paragraphStyle,
  };
};

import React from 'react';
import { SwitchTransition } from 'react-transition-group';

import BemCssTransition from 'components/BemCssTransition';
import DiscoSection from './DiscoSection';
import ExportVideoStepContainer from './ExportVideoStepContainer';
import ExportVideoStepMainSection from './ExportVideoStepMainSection';
import ProgressBlock from './ProgressBlock';
import { ExportVideoStepProps } from './types';
import useExportVideoStep from './useExportVideoStep';
import VideoReadyBlock from './VideoReadyBlock';

const ExportVideoStepWithActivity: React.FC<ExportVideoStepProps> = ({
  className,
  estimatedDurationSec,
  modalName,
  onError,
  pollIntervalMillis,
  widgetId,
}) => {
  const { animationConfig, progress, status } = useExportVideoStep({
    onError,
    pollIntervalMillis,
    widgetId,
  });

  return (
    // <BemCssTransition {...animationConfig.step}>
    <ExportVideoStepContainer className={className}>
      <BemCssTransition {...animationConfig.mainSection}>
        <ExportVideoStepMainSection>
          <SwitchTransition mode="out-in">
            <BemCssTransition {...animationConfig.infoBlock}>
              {status === 'completed' ? (
                <VideoReadyBlock widgetId={widgetId} />
              ) : (
                <ProgressBlock
                  layout="horizontal"
                  {...{ estimatedDurationSec, progress }}
                />
              )}
            </BemCssTransition>
          </SwitchTransition>
          <BemCssTransition {...animationConfig.discoSection}>
            <DiscoSection modalName={modalName} />
          </BemCssTransition>
        </ExportVideoStepMainSection>
      </BemCssTransition>
    </ExportVideoStepContainer>
    // </BemCssTransition>
  );
};

export default ExportVideoStepWithActivity;

import cn from 'classnames';
import * as React from 'react';

import { PopperTrigger } from 'components/Popper';
import { useEditorDispatch } from '../context/VideoEditorDispatchContext';
import Tile, { TileProps } from '../Tile';
import { popperTileBlock as block } from '../utils';

const { useCallback, useState } = React;

type PickedTileProps = Pick<
  TileProps,
  'onClick' | 'disabled' | 'id' | 'tooltip'
>;

export interface PopperTileProps extends PickedTileProps {
  children?: React.ReactNode;
  className?: string;
  icon: React.ReactElement;
}

const PopperTile: React.FC<PopperTileProps> = ({
  children,
  className,
  disabled,
  icon,
  id,
  onClick,
  tooltip,
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useEditorDispatch();

  const handleClick = useCallback(
    (params: any, e: React.MouseEvent) => {
      onClick(params, e);
      setOpen(current => {
        const value = !current;
        dispatch({ type: value ? 'OVERLAY_OPEN' : 'OVERLAY_CLOSE' });
        return value;
      });
    },
    [dispatch, onClick],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    dispatch({ type: 'OVERLAY_CLOSE' });
  }, [dispatch]);

  return (
    <div className={cn(block(), className)}>
      <Tile
        className={block('button')}
        onClick={handleClick}
        disabled={disabled}
        id={id}
        tooltip={tooltip}
        tooltipPlacement="bottom"
      >
        {React.cloneElement(icon, {
          className: cn(block('icon'), icon.props.className),
        })}
      </Tile>
      <PopperTrigger open={open} onClose={handleClose}>
        {children}
      </PopperTrigger>
    </div>
  );
};

export default PopperTile;

import { routerMiddleware } from 'connected-react-router/immutable';
import Immutable from 'immutable';
import { applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import MixpanelMiddleware from 'redux-mixpanel-middleware';
import thunk from 'redux-thunk';
import persistPurge from 'redux/middleware/persist-purge';
import { AUTOSAVE_INTERVAL_MILLIS } from 'utils/constants';

import { isReportableError } from 'utils/sentry';
import createApi from '../middleware/api';
import createAutosave from '../middleware/autosave';
import delay from '../middleware/delay';
import createSentry from '../middleware/sentry';
import {
  tokenSelector,
  userIdSelector,
  userRegisteredSelector,
} from '../modules/auth';
import { doSaveConfiguration } from '../modules/embed/actions/embed';
import modalMiddleware from '../modules/modal/middleware';
import history from './history';

const router = routerMiddleware(history);

const autosave = createAutosave(doSaveConfiguration, AUTOSAVE_INTERVAL_MILLIS);

const redactedString = 'xxxxxxxxxx';
const sentry = createSentry({
  actionTransformer(action) {
    if (!action || !action.type) return undefined;
    return action.type;
  },
  stateTransformer(state) {
    return state.reduce((newState, value, key) => {
      if (value.isEmpty()) {
        return newState;
      }

      function getStateValue() {
        if (key === 'auth') {
          return value.withMutations(v => {
            if (v.has('token')) {
              v.set('token', redactedString);
            }

            if (v.has('username')) {
              v.set('username', redactedString);
            }
            return v;
          });
        }

        if (key === 'oauth' && value.hasIn(['getty', 'token'])) {
          return value.setIn(['getty', 'token'], redactedString);
        }

        if (key === 'oauth' && value.hasIn(['thinkstock', 'token'])) {
          return value.setIn(['thinkstock', 'token'], redactedString);
        }

        if (key === 'imageEdit' && value.has('dataUrl')) {
          return value.delete('dataUrl');
        }

        if (key === 'asyncAudioClipper') {
          return value.delete('waveform');
        }

        return value;
      }

      return {
        ...newState,
        [key]: getStateValue().toJS(),
      };
    }, {});
  },
  filterErrors: isReportableError,
});

const api = createApi(userIdSelector, tokenSelector, userRegisteredSelector);

function getMixpanelMiddleware(mixpanel) {
  return new MixpanelMiddleware(mixpanel);
}

function getProdMiddleware(mixpanel) {
  return applyMiddleware(
    persistPurge,
    api,
    getMixpanelMiddleware(mixpanel),
    sentry,
    delay,
    thunk,
    modalMiddleware(),
    autosave,
    router,
  );
}

function getDevMiddleware(mixpanel) {
  const logger = createLogger({
    stateTransformer: state => {
      if (Immutable.Iterable.isIterable(state)) {
        return state.toJS();
      }
      return state;
    },
    actionTransformer: action => {
      const { payload, ...rest } = action;
      if (!payload) return action;

      const jsPayload = Object.keys(payload).reduce((acc, key) => {
        const value = payload[key];
        return {
          ...acc,
          [key]: Immutable.Iterable.isIterable(value) ? value.toJS() : value,
        };
      }, {});

      return {
        ...rest,
        payload: jsPayload,
      };
    },
  });

  return applyMiddleware(
    persistPurge,
    api,
    getMixpanelMiddleware(mixpanel),
    sentry,
    delay,
    thunk,
    modalMiddleware(),
    autosave,
    logger,
    router,
  );
}

export default process.env.NODE_ENV === 'production'
  ? getProdMiddleware
  : getDevMiddleware;

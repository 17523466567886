import cn from 'classnames';
import * as React from 'react';
import { DurationField, DurationFieldProps } from 'components/Form';
import { block } from './utils';

export type ClipperDurationFieldComponent = DurationFieldProps;

const ClipperDurationField: React.SFC<ClipperDurationFieldComponent> = ({
  className,
  controlClassName,
  ...props
}) => (
  <DurationField
    className={cn(block('duration-field'), className)}
    labelClassName={block('duration-label')}
    controlClassName={cn(block('duration-control'), controlClassName)}
    mask="000:00.000"
    {...props}
  />
);

export default ClipperDurationField;

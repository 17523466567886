import { GradientIcon, Heading, Spacer } from '@sparemin/blockhead';
import React from 'react';
import { ClosedCaptioning } from 'components/icons';
import { captionsViewBlock as block } from '../utils';

export interface CaptionsChildViewDefaultTopSlotProps {
  callout?: React.ReactNode;
}

const CaptionsChildViewDefaultTopSlot: React.FC<CaptionsChildViewDefaultTopSlotProps> = props => {
  const { callout } = props;

  return (
    <Spacer
      className={block('top-slot')}
      orientation="vertical"
      space="30px"
      align="center"
      justify="center"
    >
      <div className={block('captions-icon-container')}>
        <GradientIcon>
          <ClosedCaptioning width="40px" height="30px" color="#282e38" />
        </GradientIcon>
      </div>

      <Heading level={1} className={block('captions-heading')}>
        Captions
      </Heading>
      {callout}
    </Spacer>
  );
};

export default CaptionsChildViewDefaultTopSlot;

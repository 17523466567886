import { AutoframingEffect } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { getVideoClipFramingMethod } from '../assets/utils';
import { useEditorState } from '../context/VideoEditorStateContext';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { DOUBLECLICK_CHANGE_TYPE_MAP } from '../layers/utils';
import { OnIsAutoFrameChange } from '../types';
import useVideo from '../useVideo';
import ControlToggle from './ControlToggle';

export interface VideoPodcastSettingsFramingMethodProps {
  onIsAutoFrameChange?: OnIsAutoFrameChange;
}

const VideoPodcastSettingsFramingMethod: React.FC<VideoPodcastSettingsFramingMethodProps> = ({
  onIsAutoFrameChange,
}) => {
  const { selectedAsset } = useEditorState();
  const assetId = selectedAsset?.id;
  const videoClip = useVideo(assetId);

  const framingMethod = getVideoClipFramingMethod(videoClip);

  const dispatch = useTemplateDispatch();

  const handleFramingMethodChange = useCallback(
    (isAutoFrame: boolean): void => {
      onIsAutoFrameChange(isAutoFrame);

      dispatch({
        type: 'VIDEO_FRAMING_METHOD_CHANGE',
        payload: {
          id: assetId,
          framingMethod: isAutoFrame ? 'autoframe' : 'fixed',
        },
      });

      dispatch({
        type: DOUBLECLICK_CHANGE_TYPE_MAP.videoClip,
        payload: {
          id: assetId,
          fitType: isAutoFrame ? 'fill' : 'fit',
        },
      });

      if (!isAutoFrame) {
        dispatch({
          type: 'VIDEO_BLUR_CHANGE',
          payload: { id: assetId, enableBlur: true },
        });
      }
    },
    [onIsAutoFrameChange, dispatch, assetId],
  );

  return (
    <ControlToggle
      description="Automatically track and center people to keep them in-frame."
      isActive={framingMethod === 'autoframe'}
      icon={<AutoframingEffect fontSize={20} />}
      label="Autoframe"
      onChange={handleFramingMethodChange}
    />
  );
};

export default VideoPodcastSettingsFramingMethod;

import React from 'react';

import { TextAlign, TextCase } from 'components/TextToolbar';

import { useTextOverlay } from '../state/TextOverlayProvider';
import { FontStyle, FontWeight, TextDecoration, TextTransform } from '../types';

interface UseTextOverlayTextFormat {
  onChangeFontStyle: (fontStyle: FontStyle) => void;
  onChangeFontWeight: (fontWeight: FontWeight) => void;
  onChangeTextAlign: (textAlign: TextAlign) => void;
  onChangeTextDecoration: (textDecoration: TextDecoration) => void;
  onChangeTextCase: (textCase: TextCase) => void;
  selectedFontStyle: FontStyle;
  selectedFontWeight: FontWeight;
  selectedTextAlign: TextAlign;
  selectedTextDecoration: TextDecoration;
  selectedTextCase: TextCase;
}

const useTextOverlayTextFormat = (): UseTextOverlayTextFormat => {
  const { draftEditorData, onUpdateStyle } = useTextOverlay();

  const selectedFontStyle: FontStyle = draftEditorData.getIn([
    'editor',
    'textStyle',
    'fontStyle',
  ]);
  const selectedFontWeight: FontWeight = draftEditorData.getIn([
    'editor',
    'textStyle',
    'fontWeight',
  ]);
  const selectedTextAlign: TextAlign = draftEditorData.getIn([
    'editor',
    'textStyle',
    'textAlign',
  ]);
  const selectedTextDecoration: TextDecoration = draftEditorData.getIn([
    'editor',
    'textStyle',
    'textDecoration',
  ]);
  const selectedTextCase: TextTransform = draftEditorData.getIn([
    'editor',
    'textStyle',
    'textTransform',
  ]);

  const handleChangeFontStyle = React.useCallback(
    (nextFontStyle: FontStyle): void => {
      onUpdateStyle(textStyle => textStyle.set('fontStyle', nextFontStyle));
    },
    [onUpdateStyle],
  );

  const handleChangeFontWeight = React.useCallback(
    (nextFontWeight: FontWeight): void => {
      onUpdateStyle(textStyle => textStyle.set('fontWeight', nextFontWeight));
    },
    [onUpdateStyle],
  );

  const handleChangeTextAlign = React.useCallback(
    (nextTextAlign: TextAlign): void => {
      onUpdateStyle(textStyle => textStyle.set('textAlign', nextTextAlign));
    },
    [onUpdateStyle],
  );

  const handleChangeTextDecoration = React.useCallback(
    (nextTextDecoration: TextDecoration): void => {
      onUpdateStyle(textStyle =>
        textStyle.set('textDecoration', nextTextDecoration),
      );
    },
    [onUpdateStyle],
  );
  const handleChangeTextTransform = React.useCallback(
    (nextTextTransform: TextTransform): void => {
      onUpdateStyle(textStyle =>
        textStyle.set('textTransform', nextTextTransform),
      );
    },
    [onUpdateStyle],
  );

  return {
    onChangeFontStyle: handleChangeFontStyle,
    onChangeFontWeight: handleChangeFontWeight,
    onChangeTextAlign: handleChangeTextAlign,
    onChangeTextDecoration: handleChangeTextDecoration,
    onChangeTextCase: handleChangeTextTransform,
    selectedFontStyle,
    selectedFontWeight,
    selectedTextAlign,
    selectedTextDecoration,
    selectedTextCase,
  };
};

export default useTextOverlayTextFormat;

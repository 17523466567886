import { useLinkedinLogin, UseLinkedinLoginConfig } from '@sparemin/auth';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'underscore';
import { tokenSelector } from 'redux/modules/auth';
import {
  clearLinkedinUser,
  linkedinAuthorizeSuccess,
  waitForProfile,
} from 'redux/modules/social';
import { FatalError } from 'utils/FatalError';

export interface UseLinkedInAuthenticatorConfig
  extends Pick<UseLinkedinLoginConfig, 'force' | 'onError'> {
  onAuthSuccess?: () => void;
}

export default function useLinkedinAuthenticator({
  force,
  onAuthSuccess,
  onError,
}: UseLinkedInAuthenticatorConfig) {
  const dispatch = useDispatch();
  const spareminToken = useSelector(tokenSelector);

  return useLinkedinLogin({
    force,
    spareminToken,
    onError,
    onSuccess: async data => {
      dispatch(clearLinkedinUser());

      if (data) {
        dispatch(
          linkedinAuthorizeSuccess(
            pick(data.tokenInfo, 'accessToken', 'idToken', 'providerUserId'),
          ),
        );
      }

      try {
        await dispatch(waitForProfile());
        onAuthSuccess?.();
      } catch (error) {
        throw new FatalError(error.message);
      }
    },
  });
}

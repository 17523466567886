import { useInstagramLogin, UseInstagramLoginConfig } from '@sparemin/auth';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'underscore';
import { getSocialProfile } from 'redux/middleware/api/third-party-authentication-service/actions';
import { tokenSelector } from 'redux/modules/auth';
import {
  clearInstagramUser,
  getInstagramUser,
  instagramAuthorizeSuccess,
} from 'redux/modules/social';
import { Dispatch } from 'redux/types';
import { SocialSharePlatform } from 'types';
import { FatalError } from 'utils/FatalError';

export interface UseInstagramAuthenticatorConfig
  extends Pick<UseInstagramLoginConfig, 'force'> {
  onError?: (error: Error) => void;
  onAuthSuccess?: () => void;
}

export default function useInstagramAuthenticator({
  force,
  onAuthSuccess,
  onError,
}: UseInstagramAuthenticatorConfig) {
  const dispatch = useDispatch<Dispatch>();
  const spareminToken = useSelector(tokenSelector);

  return useInstagramLogin({
    force,
    spareminToken,
    onSuccess: async data => {
      dispatch(clearInstagramUser());

      if (data) {
        dispatch(
          instagramAuthorizeSuccess(
            pick(data.tokenInfo, 'accessToken', 'idToken', 'providerUserId'),
          ),
        );
      }

      try {
        const { accessToken, providerUserId, idToken } = data.tokenInfo;

        const { response } = await dispatch(
          getSocialProfile(
            SocialSharePlatform.INSTAGRAM,
            providerUserId,
            idToken,
            accessToken,
          ),
        );

        await dispatch(getInstagramUser(response));

        onAuthSuccess?.();
      } catch (error) {
        throw new FatalError(error.message);
      }
    },
    onError,
  });
}

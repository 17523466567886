import { AlertCircle, Banner, darkTheme } from '@sparemin/blockhead';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import { captionsViewBlock as block } from '../utils';

interface CaptionsChildViewCalloutProps {
  text: string;
}

const CaptionsChildViewCallout: React.FunctionComponent<CaptionsChildViewCalloutProps> = props => {
  const { text } = props;

  return (
    <ThemeProvider theme={darkTheme}>
      <Banner
        className={block('callout')}
        icon={
          <AlertCircle
            className={block('callout-icon')}
            height={40}
            width={40}
          />
        }
      >
        {text}
      </Banner>
    </ThemeProvider>
  );
};

export default CaptionsChildViewCallout;

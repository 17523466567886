import { useEffect } from 'react';

export type UseVideoTrimmerOptions = {
  enabled?: boolean;
  loop?: boolean;
  startTimeSec: number;
  endTimeSec: number;
};

/**
 * Trims a video playback to a specific time range. It ensures the video plays
 * within the given start and end time range, automatically resetting or pausing
 * when necessary.
 */
export function useVideoTrimmer(
  video: HTMLVideoElement,
  options: UseVideoTrimmerOptions,
) {
  const { enabled = false, loop = true, startTimeSec, endTimeSec } = options;

  useEffect(() => {
    if (!video || !enabled) {
      return undefined;
    }

    const handleLoadedMetadata = () => {
      video.currentTime = startTimeSec;
    };

    const handleCanPlay = () => {
      if (video.paused) {
        video.currentTime = startTimeSec;
        video.play();
      }
    };

    const handleTimeUpdate = () => {
      if (video.currentTime >= endTimeSec) {
        if (loop) {
          video.currentTime = startTimeSec;
          video.play();
        } else {
          video.pause();
          video.currentTime = startTimeSec;
        }
      }
    };

    video.addEventListener('loadedmetadata', handleLoadedMetadata);
    video.addEventListener('canplay', handleCanPlay);
    video.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      video.removeEventListener('canplay', handleCanPlay);
      video.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [enabled, endTimeSec, loop, startTimeSec, video]);
}

import { createSelector } from 'reselect';
import { isUndefined } from 'underscore';

import { PlanName, SubTier, Tier } from 'redux/middleware/api/plan-service';
import { plansSelector } from 'redux/modules/entities/selectors';
import { State } from 'redux/types';
import { RequestStatus } from 'types';
import { isEnterpriseSubTier } from 'utils/pricing';
import { PricingState } from './types';
import { getDaysUntilNow } from './utils';

export const stateSelector = (state: State): PricingState =>
  state.get('pricing');

export const isLoadingSelector = createSelector(
  stateSelector,
  state => state.isLoading,
);

export const pricingDataLoadedSelector = createSelector(
  isLoadingSelector,
  isLoading => (isUndefined(isLoading) ? false : !isLoading),
);

export const isSendingInvitesSelector = createSelector(
  stateSelector,
  state => state.isSendingInvites,
);

export const subscriptionSelector = createSelector(stateSelector, state => {
  return state.mySubscription;
});

export const subscriptionBillingPeriodEndSelector = createSelector(
  stateSelector,
  state => {
    return state.mySubscription.get('planEndSec');
  },
);

export const subscriptionPurchasePlatformSelector = createSelector(
  stateSelector,
  state => {
    return state.mySubscription?.get('purchaseManagementPlatform');
  },
);

export const videoUploadLimitMbSelector = createSelector(
  subscriptionSelector,
  sub => sub?.videoUploadLimitMb,
);

export const videoUploadLimitHoursSelector = createSelector(
  subscriptionSelector,
  sub => sub?.fullEpisodeDurationLimitHours,
);

const couponsSelector = createSelector(stateSelector, state => state.myCoupons);

export const myReferralUrlSelector = createSelector(
  stateSelector,
  state => state.myReferralUrl,
);

export const cardSelector = createSelector(
  stateSelector,
  state => state.myCard,
);

export const planNameSelector = createSelector(
  subscriptionSelector,
  sub => sub.plan,
);

export const tierSelector = createSelector(
  subscriptionSelector,
  sub => sub.tier,
);

export const subtierSelector = createSelector(
  subscriptionSelector,
  subscription => subscription.subtier,
);

export const savePaymentMethodStatusSelector = createSelector(
  stateSelector,
  state => state.savePaymentMethodStatus,
);

export const updateSubscriptionStatusSelector = createSelector(
  stateSelector,
  state => state.updateSubscriptionStatus,
);

export const isUpdatingSubscriptionSelector = createSelector(
  updateSubscriptionStatusSelector,
  status => status === RequestStatus.REQUEST,
);

export const createPlanAmountSelector = (name: PlanName) =>
  createSelector(
    plansSelector,
    plans => plans.getIn([name, 'amount']) as number,
  );

export const proMonthlyCostCentsSelector = createPlanAmountSelector(
  PlanName.PRO_20211112_MONTHLY,
);

export const proYearlyCostCentsSelector = createPlanAmountSelector(
  PlanName.PRO_20211112_YEARLY,
);

export const basicMonthlyCostCentsSelector = createPlanAmountSelector(
  PlanName.BASIC_20211112_MONTHLY,
);

export const basicYearlyCostCentsSelector = createPlanAmountSelector(
  PlanName.BASIC_20211112_YEARLY,
);

export const unlimitedYearlyCostCentsSelector = createPlanAmountSelector(
  PlanName.UNLIMITED_20211112_YEARLY,
);

export const planEndSecSelector = createSelector(
  subscriptionSelector,
  subscription => subscription.planEndSec,
);

export const planEndInDaysSelector = createSelector(
  planEndSecSelector,
  planEndSec => getDaysUntilNow(planEndSec),
);

export const organizationNameSelector = createSelector(
  subscriptionSelector,
  subscription => subscription.organizationName,
);

export const isFreeSelector = createSelector(
  subtierSelector,
  st => st === SubTier.FREE,
);

export const isFreeOrEduSelector = createSelector(
  tierSelector,
  tier => tier === Tier.FREE || tier === Tier.EDUCATION_NONPROFIT,
);

export const isBasicOrFreeSelector = createSelector(
  tierSelector,
  tier => tier === Tier.FREE || tier === Tier.BASIC,
);

export const isBasicOrFreeOrEducationNonprofitSelector = createSelector(
  tierSelector,
  tier =>
    tier === Tier.FREE ||
    tier === Tier.BASIC ||
    tier === Tier.EDUCATION_NONPROFIT,
);

export const isNotEnterpriseApiSelector = createSelector(subtierSelector, st =>
  st === undefined ? undefined : !isEnterpriseSubTier(st),
);

export const discountSelector = createSelector(
  couponsSelector,
  coupons => coupons && coupons.getIn([0, 'couponDisplayHint']),
);

export const exportBalanceSelector = createSelector(
  subscriptionSelector,
  sub => sub.exportBalance,
);

/**
 * returns
 *  - undefined if we have not yet queried for the balance (default state)
 *  - Infinity if the server returns a null balance
 *  - the numerical balance otherwise
 */
export const transcriptionBalanceMillisSelector = createSelector(
  subscriptionSelector,
  sub => {
    const balanceMillis = sub.transcriptionBalanceMillis;
    return balanceMillis === null ? Infinity : balanceMillis;
  },
);

export const transcriptionBalanceMinutesSelector = createSelector(
  transcriptionBalanceMillisSelector,
  millis => millis / 60000,
);

export const headlinerWatermarkEnabledSelector = createSelector(
  [isFreeSelector, exportBalanceSelector],
  (isFree, videoBalance) => (!isFree ? false : videoBalance <= 0),
);

const templateCompatibility: Record<Tier, Tier[]> = {
  [Tier.FREE]: [Tier.FREE],
  [Tier.BASIC]: [Tier.FREE, Tier.BASIC, Tier.PRO],
  [Tier.PRO]: [Tier.FREE, Tier.BASIC, Tier.PRO],
  [Tier.UNLIMITED]: [Tier.FREE, Tier.BASIC, Tier.PRO, Tier.UNLIMITED],
  [Tier.ENTERPRISE]: [
    Tier.FREE,
    Tier.BASIC,
    Tier.PRO,
    Tier.UNLIMITED,
    Tier.ENTERPRISE,
  ],
  [Tier.EDUCATION_NONPROFIT]: [Tier.FREE, Tier.EDUCATION_NONPROFIT],
};

// TODO this selector  is for template compatibility and should be named accordingly
export const isCompatibleSelector = createSelector(
  subscriptionSelector,
  (_, matches: Tier[]) => matches,
  (sub, matches) => {
    // if tier is unknown, user might be logged out (Audio wizard).  assume free
    const compatibleTiers = templateCompatibility[sub.tier || Tier.FREE];
    return compatibleTiers.some(tier => matches.includes(tier));
  },
);

export const seenFreeTrialRemindersSelector = createSelector(
  stateSelector,
  state => state.seenFreeTrialReminders,
);

export const externalPortalLinkSelector = createSelector(
  stateSelector,
  state => state.externalPortalLink,
);

export const autoRenewalSelector = createSelector(
  stateSelector,
  state => state.mySubscription?.autoRenewal,
);

export const customerIdSelector = createSelector(
  stateSelector,
  state => state.customerId,
);

export const isInvoicePastDueSelector = createSelector(
  subscriptionSelector,
  sub => sub.invoicePastDue,
);

export const showCancellationNoticeSelector = createSelector(
  subscriptionSelector,
  sub => sub.forcedCancellationNotice,
);

export const plansLimitSelector = createSelector(
  stateSelector,
  state => state.mySubscription,
);

export const discoEnabledSelector = createSelector(
  subscriptionSelector,
  sub => sub.discoEnabled,
);

export const fullEpisodeDurationLimitHoursSelector = createSelector(
  subscriptionSelector,
  sub => sub.fullEpisodeDurationLimitHours,
);

export const hasBackCatalogFeatureSelector = createSelector(
  tierSelector,
  tier => [Tier.ENTERPRISE, Tier.PRO, Tier.UNLIMITED].includes(tier),
);

export const fullEpisodeCaptionEnabledSelector = createSelector(
  subscriptionSelector,
  sub => sub.get('fullEpisodeCaptionEnabled'),
);

import * as React from 'react';
import { useSelector } from 'react-redux';

import LoadingOverlay from 'components/LoadingOverlay';
import VideoFormWithPreview, {
  dataFactory as videoFormDataFactory,
} from 'components/VideoFormWithPreview';
import { aspectRatioSelector } from 'redux/modules/embed/selectors';
import { StaticCrop } from 'redux/modules/embed/types';
import { Size } from 'types';
import { VideoCropperMetadata, VideoToEdit } from './types';

export interface EditVideoModalContentsProps {
  formRef: React.MutableRefObject<VideoFormWithPreview>;
  video: VideoToEdit;
  videoFileSize?: Size<number>;
  metadataLoaded: boolean;
  cropMetadata?: VideoCropperMetadata;
  boundingBox?: StaticCrop;
  onLoadedMetadata: () => void;
  onAdjustCrop: () => void;
  onDimensionsChange: (dimensions: Size<number>) => void;
}

const EditVideoModalContents: React.FC<EditVideoModalContentsProps> = ({
  formRef,
  video,
  videoFileSize,
  metadataLoaded,
  cropMetadata,
  boundingBox,
  onLoadedMetadata,
  onAdjustCrop,
  onDimensionsChange,
}) => {
  const aspectRatio = useSelector(aspectRatioSelector);

  const {
    startMillis,
    audioLevel,
    mainAudioLevel,
    audioFadeInDurationMillis,
    audioFadeOutDurationMillis,
    blurredBackground,
    position,
    scaling,
    src,
    transitionIn,
    transitionOut,
    width,
    height,
    zoom,
    id,
  } = video || ({} as VideoToEdit);

  const durationMillis = !video
    ? undefined
    : video.endMillis - video.startMillis;

  return (
    <>
      <div>
        {(!metadataLoaded || !videoFileSize) && (
          <LoadingOverlay title="Loading Video" />
        )}

        <VideoFormWithPreview
          showTransformOptions
          id={id}
          ref={formRef}
          videoSrc={src}
          previewColClassName="edit-video-modal__preview-col"
          showTranscriptionSection={false}
          defaultData={videoFormDataFactory({
            audioFadeInDurationMillis,
            audioFadeOutDurationMillis,
            audioLevel,
            blurredBackground,
            height,
            mainAudioLevel,
            position,
            scaling,
            startMillis,
            transitionIn,
            transitionOut,
            width,
            zoom,
          })}
          {...{
            aspectRatio,
            durationMillis,
            boundingBox,
            videoFileSize,
            cropMetadata,
            onLoadedMetadata,
            onAdjustCrop,
            onDimensionsChange,
          }}
        />
      </div>
    </>
  );
};

export default EditVideoModalContents;

import { Description } from '@sparemin/blockhead';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  makeProjectClipCountSelector,
  makeProjectNameSelector,
  makeProjectSelector,
} from 'redux/modules/entities';
import { block } from '../utils';
import { useProjectRow } from './ProjectRowContext';

export interface ProjectRowHeadlineProps {}

const ProjectRowHeadline: React.FC<ProjectRowHeadlineProps> = () => {
  const { projectId } = useProjectRow();

  const { reprAt } =
    useSelector(useMemo(() => makeProjectSelector(projectId), [projectId])) ??
    {};
  const projectName = useSelector(
    useMemo(() => makeProjectNameSelector(projectId), [projectId]),
  );
  const clipCount = useSelector(
    useMemo(() => makeProjectClipCountSelector(projectId), [projectId]),
  );

  return (
    <Description.Headline className={block('project-row-headline')}>
      <Description.Headline.PrimaryText className={block('project-name')}>
        {projectName}
      </Description.Headline.PrimaryText>

      <Description.Meta>
        <Description.Meta.Date epochMillis={reprAt} />

        {clipCount && (
          <>
            <Description.Meta.Separator /> {clipCount}{' '}
            {clipCount === 1 ? 'clip' : 'clips'}
          </>
        )}
      </Description.Meta>
    </Description.Headline>
  );
};

export default ProjectRowHeadline;

export const SPAREMIN_RECORDING_SELECT = 'app/embed/SPAREMIN_RECORDING_SELECT';

export const RECOMMENDED_EMBED_GENERATE_REQUEST =
  'app/embed/RECOMMENDED_EMBED_GENERATE_REQUEST';
export const RECOMMENDED_EMBED_GENERATE_SUCCESS =
  'app/embed/RECOMMENDED_EMBED_GENERATE_SUCCESS';
export const RECOMMENDED_EMBED_GENERATE_FAILURE =
  'app/embed/RECOMMENDED_EMBED_GENERATE_FAILURE';

// clears existing tracks out of the editor before loading a new video
export const EMBED_TRACK_STATE_CLEAR = 'app/embed/EMBED_TRACK_STATE_CLEAR';
export const EMBED_TRACK_CREATE = 'app/embed/EMBED_TRACK_CREATE';
export const EMBED_TRACK_UPDATE = 'app/embed/EMBED_TRACK_UPDATE';
export const EMBED_TRACK_DELETE = 'app/embed/EMBED_TRACK_DELETE';

export const EMBED_TRACK_ELEMENT_SELECT =
  'app/embed/EMBED_TRACK_ELEMENT_SELECT';
export const EMBED_TRACK_ELEMENT_CLEAR = 'app/embed/EMBED_TRACK_ELEMENT_CLEAR';
export const EMBED_REPLACED_TRACK_ELEMENT_CLEAR =
  'app/embed/EMBED_REPLACED_TRACK_ELEMENT_CLEAR';
export const EMBED_TRACK_ELEMENT_REPLACE =
  'app/embed/EMBED_TRACK_ELEMENT_REPLACE';

export const EMBED_DURATION_SET = 'app/embed/EMBED_DURATION_SET';

// set the id of a track the user wants to add content to
export const EMBED_ADDING_TO_TRACK_SET = 'app/embed/EMBED_ADDING_TO_TRACK_SET';

// clear the id
export const EMBED_ADDING_TO_TRACK_CLEAR =
  'app/embed/EMBED_ADDING_TO_TRACK_CLEAR';

export const EMBED_TRACK_MOVE = 'app/embed/EMBED_TRACK_MOVE';

// types for sending the local configuration to the server
export const EMBED_CONFIGURATION_CREATE_REQUEST =
  'app/embed/EMBED_CONFIGURATION_CREATE_REQUEST';
export const EMBED_CONFIGURATION_CREATE_SUCCESS =
  'app/embed/EMBED_CONFIGURATION_CREATE_SUCCESS';
export const EMBED_CONFIGURATION_CREATE_FAILURE =
  'app/embed/EMBED_CONFIGURATION_CREATE_FAILURE';

/*
 * a wrapper around the 3 types above.  "saving" is the same as "creating a configuration".  might
 * be able to just get by with the above types.  TODO do we need these?
 */
export const EMBED_CONFIGURATION_SAVE_REQUEST =
  'app/embed/EMBED_CONFIGURATION_SAVE_REQUEST';
export const EMBED_CONFIGURATION_SAVE_SUCCESS =
  'app/embed/EMBED_CONFIGURATION_SAVE_SUCCESS';
export const EMBED_CONFIGURATION_SAVE_FAILURE =
  'app/embed/EMBED_CONFIGURATION_SAVE_FAILURE';

export const EMBED_CONFIGURATION_SET = 'app/embed/EMBED_CONFIGURATION_SET';

// creates the iframe code from stored configuration
export const EMBED_CODE_CREATE = 'app/embed/EMBED_CODE_CREATE';

// update a slide or the entire slideshow
export const EMBED_SLIDE_UPDATE = 'app/embed/EMBED_SLIDE_UPDATE';
export const EMBED_SLIDE_DELETE = 'app/embed/EMBED_SLIDE_DELETE';
export const EMBED_SLIDESHOW_UPDATE = 'app/embed/EMBED_SLIDESHOW_UPDATE';

// similar to "slideshow" but can't think of a similar word for text overlay
export const EMBED_TEXT_OVERLAYS_UPDATE =
  'app/embed/EMBED_TEXT_OVERLAYS_UPDATE';

export const EMBED_SLIDE_EDIT_IMAGE_REQUEST =
  'app/embed/EMBED_SLIDE_IMAGE_UPLOAD_REQUEST';
export const EMBED_SLIDE_EDIT_IMAGE_SUCCESS =
  'app/embed/EMBED_SLIDE_IMAGE_UPLOAD_SUCCESS';
export const EMBED_SLIDE_EDIT_IMAGE_FAILURE =
  'app/embed/EMBED_SLIDE_IMAGE_UPLOAD_FAILURE';

// updates a single text overlay in the text "slideshow"
export const EMBED_TEXT_OVERLAY_UPDATE = 'app/embed/EMBED_TEXT_OVERLAY_UPDATE';

export const EMBED_LAST_TEXT_TEMPLATE_UPDATE =
  'app/embed/EMBED_LAST_TEXT_TEMPLATE_UPDATE';

export const EMBED_LAST_GLOBAL_TEMPLATE_UPDATE =
  'app/embed/EMBED_LAST_GLOBAL_TEMPLATE_UPDATE';

export const EMBED_SLIDE_ADD_REQUEST = 'app/embed/EMBED_SLIDE_ADD_REQUEST';
export const EMBED_SLIDE_ADD_SUCCESS = 'app/embed/EMBED_SLIDE_ADD_SUCCESS';
export const EMBED_SLIDE_ADD_FAILURE = 'app/embed/EMBED_SLIDE_ADD_FAILURE';

export const EMBED_TEXT_OVERLAY_ADD_REQUEST =
  'app/embed/EMBED_TEXT_OVERLAY_ADD_REQUEST';
export const EMBED_TEXT_OVERLAY_ADD_SUCCESS =
  'app/embed/EMBED_TEXT_OVERLAY_ADD_SUCCESS';
export const EMBED_TEXT_OVERLAY_ADD_FAILURE =
  'app/embed/EMBED_TEXT_OVERLAY_ADD_FAILURE';

export const EMBED_TEXT_OVERLAYS_DELETE =
  'app/embed/EMBED_TEXT_OVERLAYS_DELETE';

// wrapper around the delete process (remove from local state, save configuration, load embed, etc)
export const EMBED_TEXT_OVERLAY_REMOVE_REQUEST =
  'app/embed/EMBED_TEXT_OVERLAY_REMOVE_REQUEST';
export const EMBED_TEXT_OVERLAY_REMOVE_SUCCESS =
  'app/embed/EMBED_TEXT_OVERLAY_REMOVE_SUCCESS';
export const EMBED_TEXT_OVERLAY_REMOVE_FAILURE =
  'app/embed/EMBED_TEXT_OVERLAY_REMOVE_FAILURE';

// equivalent of EMBED_TEXT_OVERLAYS_UDPATE but "AUDIOS" isn't really a word
export const EMBED_AUDIO_COLLECTION_UPDATE =
  'app/embed/EMBED_AUDIO_COLLECTION_UPDATE';

export const EMBED_AUDIO_UPDATE = 'app/embed/EMBED_AUDIO_UPDATE';
export const EMBED_AUDIO_DELETE = 'app/embed/EMBED_AUDIO_DELETE';

export const EMBED_AUDIO_REMOVE_REQUEST =
  'app/embed/EMBED_AUDIO_REMOVE_REQUEST';
export const EMBED_AUDIO_REMOVE_SUCCESS =
  'app/embed/EMBED_AUDIO_REMOVE_SUCCESS';
export const EMBED_AUDIO_REMOVE_FAILURE =
  'app/embed/EMBED_AUDIO_REMOVE_FAILURE';

export const EMBED_AUDIO_ADD_REQUEST = 'app/embed/EMBED_AUDIO_ADD_REQUEST';
export const EMBED_AUDIO_ADD_SUCCESS = 'app/embed/EMBED_AUDIO_ADD_SUCCESS';
export const EMBED_AUDIO_ADD_FAILURE = 'app/embed/EMBED_AUDIO_ADD_FAILURE';

export const EMBED_SLIDE_REMOVE_REQUEST =
  'app/embed/EMBED_SLIDE_REMOVE_REQUEST';
export const EMBED_SLIDE_REMOVE_SUCCESS =
  'app/embed/EMBED_SLIDE_REMOVE_SUCCESS';
export const EMBED_SLIDE_REMOVE_FAILURE =
  'app/embed/EMBED_SLIDE_REMOVE_FAILURE';

export const EMBED_DATA_LOAD_REQUEST = 'app/embed/EMBED_DATA_LOAD_REQUEST';
export const EMBED_DATA_LOAD_SUCCESS = 'app/embed/EMBED_DATA_LOAD_SUCCESS';
export const EMBED_DATA_LOAD_FAILURE = 'app/embed/EMBED_DATA_LOAD_FAILURE';

export const EMBED_EDITOR_LOAD_REQUEST = 'app/embed/EMBED_EDITOR_LOAD_REQUEST';
export const EMBED_EDITOR_LOAD_SUCCESS = 'app/embed/EMBED_EDITOR_LOAD_SUCCESS';
export const EMBED_EDITOR_LOAD_FAILURE = 'app/embed/EMBED_EDITOR_LOAD_FAILURE';

export const EMBED_EDITOR_DATA_LOAD = 'app/embed/EMBED_EDITOR_DATA_LOAD';

export const EMBED_EDITOR_READY_SET = 'app/embed/EMBED_EDITOR_READY_SET';

export const EMBED_SLIDES_SET = 'app/embed/EMBED_SLIDES_SET';

export const EMBED_RECORDING_SET = 'app/embed/EMBED_RECORDING_SET';

export const EMBED_ADD_SLIDE_STATUS_CLEAR =
  'app/embed/EMBED_ADD_SLIDE_STATUS_CLEAR';

export const EMBED_STATE_CLEAR = 'app/embed/EMBED_STATE_CLEAR';

export const EMBED_AUTOSAVE_SCHEDULE = 'app/embed/EMBED_AUTOSAVE_SCHEDULE';
export const EMBED_AUTOSAVE_SCHEDULE_CLEAR =
  'app/embed/EMBED_AUTOSAVE_SCHEDULE_CLEAR';

export const EMBED_PLAYER_STATUS_SET = 'app/embed/EMBED_PLAYER_STATUS_SET';

export const EMBED_SOUNDWAVE_UPDATE = 'app/embed/EMBED_SOUNDWAVE_UPDATE';
export const EMBED_SOUNDWAVE_REMOVE = 'app/embed/EMBED_SOUNDWAVE_REMOVE';

export const EMBED_WATERMARK_ADD_REQUEST =
  'app/embed/EMBED_WATERMARK_ADD_REQUEST';
export const EMBED_WATERMARK_ADD_SUCCESS =
  'app/embed/EMBED_WATERMARK_ADD_SUCCESS';
export const EMBED_WATERMARK_ADD_FAILURE =
  'app/embed/EMBED_WATERMARK_ADD_FAILURE';

export const EMBED_WATERMARK_EDIT_REQUEST =
  'app/embed/EMBED_WATERMARK_EDIT_REQUEST';
export const EMBED_WATERMARK_EDIT_SUCCESS =
  'app/embed/EMBED_WATERMARK_EDIT_SUCCESS';
export const EMBED_WATERMARK_EDIT_FAILURE =
  'app/embed/EMBED_WATERMARK_EDIT_FAILURE';

export const EMBED_WATERMARKS_UPDATE = 'app/embed/EMBED_WATERMARKS_UPDATE';

export const EMBED_WATERMARK_DELETE_REQUEST =
  'app/embed/EMBED_WATERMARK_DELETE_REQUEST';
export const EMBED_WATERMARK_DELETE_SUCCESS =
  'app/embed/EMBED_WATERMARK_DELETE_SUCCESS';
export const EMBED_WATERMARK_DELETE_FAILURE =
  'app/embed/EMBED_WATERMARK_DELETE_FAILURE';

export const EMBED_CAPTIONS_CONFIG_SET = 'app/embed/EMBED_CAPTIONS_CONFIG_SET';
export const EMBED_CAPTIONS_STYLE_SET_REQUEST =
  'app/embed/EMBED_CAPTIONS_STYLE_SET_REQUEST';
export const EMBED_CAPTIONS_STYLE_SET_SUCCESS =
  'app/embed/EMBED_CAPTIONS_STYLE_SET_SUCCESS';
export const EMBED_CAPTIONS_STYLE_SET_FAILURE =
  'app/embed/EMBED_CAPTIONS_STYLE_SET_FAILURE';

export const EMBED_CAPTIONS_OFFSET_SET_REQUEST =
  'app/embed/EMBED_CAPTIONS_OFFSET_SET_REQUEST';
export const EMBED_CAPTIONS_OFFSET_SET_SUCCESS =
  'app/embed/EMBED_CAPTIONS_OFFSET_SET_SUCCESS';
export const EMBED_CAPTIONS_OFFSET_SET_FAILURE =
  'app/embed/EMBED_CAPTIONS_OFFSET_SET_FAILURE';

export const EMBED_CAPTIONS_MEDIA_SOURCE_SET =
  'app/embed/EMBED_CAPTIONS_MEDIA_SOURCE_SET';

export const EMBED_CAPTIONS_SOURCE_DELETE =
  'app/embed/EMBED_CAPTIONS_SOURCE_DELETE';

export const EMBED_CAPTIONS_UPLOAD_REQUEST =
  'app/embed/EMBED_CAPTIONS_UPLOAD_REQUEST';
export const EMBED_CAPTIONS_UPLOAD_SUCCESS =
  'app/embed/EMBED_CAPTIONS_UPLOAD_SUCCESS';
export const EMBED_CAPTIONS_UPLOAD_FAILURE =
  'app/embed/EMBED_CAPTIONS_UPLOAD_FAILURE';

export const FREE_FORM_CAPTIONS_UPLOAD_REQUEST =
  'app/embed/FREE_FORM_CAPTIONS_UPLOAD_REQUEST';
export const FREE_FORM_CAPTIONS_UPLOAD_SUCCESS =
  'app/embed/FREE_FORM_CAPTIONS_UPLOAD_SUCCESS';
export const FREE_FORM_CAPTIONS_UPLOAD_FAILURE =
  'app/embed/FREE_FORM_CAPTIONS_UPLOAD_FAILURE';

export const EMBED_PHRASE_UPDATE_REQUEST =
  'app/embed/EMBED_PHRASE_UPDATE_REQUEST';
export const EMBED_PHRASE_UPDATE_SUCCESS =
  'app/embed/EMBED_PHRASE_UPDATE_SUCCESS';
export const EMBED_PHRASE_UPDATE_FAILURE =
  'app/embed/EMBED_PHRASE_UPDATE_FAILURE';

export const EMBED_PHRASE_DELETE_REQUEST =
  'app/embed/EMBED_PHRASE_DELETE_REQUEST';
export const EMBED_PHRASE_DELETE_SUCCESS =
  'app/embed/EMBED_PHRASE_DELETE_SUCCESS';
export const EMBED_PHRASE_DELETE_FAILURE =
  'app/embed/EMBED_PHRASE_DELETE_FAILURE';

export const EMBED_PHRASE_ADD_REQUEST = 'app/embed/EMBED_PHRASE_ADD_REQUEST';
export const EMBED_PHRASE_ADD_SUCCESS = 'app/embed/EMBED_PHRASE_ADD_SUCCESS';
export const EMBED_PHRASE_ADD_FAILURE = 'app/embed/EMBED_PHRASE_ADD_FAILURE';

export const EMBED_VIDEO_ORDER_SET = 'app/embed/EMBED_VIDEO_ORDER_SET';
export const EMBED_VIDEO_COLLECTION_ADD =
  'app/embed/EMBED_VIDEO_COLLECTION_ADD';
export const EMBED_VIDEO_UPDATE = 'app/embed/EMBED_VIDEO_UPDATE';

export const EMBED_VIDEO_ADD_REQUEST = 'app/embed/EMBED_VIDEO_ADD_REQUEST';
export const EMBED_VIDEO_ADD_SUCCESS = 'app/embed/EMBED_VIDEO_ADD_SUCCESS';
export const EMBED_VIDEO_ADD_FAILURE = 'app/embed/EMBED_VIDEO_ADD_FAILURE';

export const EMBED_VIDEO_REMOVE_REQUEST =
  'app/embed/EMBED_VIDEO_REMOVE_REQUEST';
export const EMBED_VIDEO_REMOVE_SUCCESS =
  'app/embed/EMBED_VIDEO_REMOVE_SUCCESS';
export const EMBED_VIDEO_REMOVE_FAILURE =
  'app/embed/EMBED_VIDEO_REMOVE_FAILURE';

export const EMBED_CAPTIONS_LOCK_SET = 'app/embed/EMBED_CAPTIONS_LOCK_SET';

export const EMBED_STYLE_TRACK_SELECT = 'app/embed/EMBED_STYLE_TRACK_SELECT';
export const EMBED_STYLE_TRACK_CLEAR = 'app/embed/EMBED_STYLE_TRACK_CLEAR';

export const EMBED_CANVAS_COLOR_SET = 'app/embed/EMBED_CANVAS_COLOR_SET';

export const EMBED_PROGRESS_ANIMATION_SET =
  'app/embed/EMBED_PROGRESS_ANIMATION_SET';

export const EMBED_TIMER_CHANGE = '/app/embed/EMBED_TIMER_CHANGE';

export const EMBED_EDGE_VIDEOS_CHANGE = '/app/embed/EMBED_EDGE_VIDEOS_CHANGE';

export const EMBED_LAST_TEXT_EDITOR_V2_STATE_UPDATE =
  '/app/embed/EMBED_LAST_TEXT_EDITOR_V2_STATE_UPDATE';

export const EMBED_LAST_GLOBAL_TEXT_EDITOR_V2_STATE_UPDATE =
  '/app/embed/EMBED_LAST_GLOBAL_TEXT_EDITOR_V2_STATE_UPDATE';

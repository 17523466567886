import qs from 'query-string';

export function addSrcParamToUrl(src: string) {
  const url = new URL(src);
  url.searchParams.append('src', 'preview');
  return url.toString();
}

export const addParamsToUrl = (
  url: string,
  paramsDict: Record<string, string | number | string[] | number[]>,
) => {
  const urlObject = new URL(url);

  const searchParams = qs.parse(urlObject.search);

  const updatedParams = {
    ...searchParams,
    ...paramsDict,
  };

  urlObject.search = qs.stringify(updatedParams);

  return urlObject.toString();
};

import { useTemplateState } from './context/VideoTemplateStateContext';
import { Slide, VideoClip } from './types';

interface UseMediaLayerConfig {
  layerId: string;
}

interface UseMediaLayerImage {
  type: 'image';
  asset: Slide;
  id: string;
}

interface UseMediaLayerVideoClip {
  type: 'videoClip';
  asset: VideoClip;
  id: string;
}

interface UseMediaLayerNone {
  type: 'none';
}

export type UseMediaLayer =
  | UseMediaLayerImage
  | UseMediaLayerVideoClip
  | UseMediaLayerNone;

const useMediaLayer = (config: UseMediaLayerConfig): UseMediaLayer => {
  const { layerId } = config;

  const { slideshow, videoClips } = useTemplateState();

  // First, it tries to find a slide using the provided layer id. If it is found,
  // type is set to image and id/asset are set using the slideId and the slideshow
  // asset associated to it.
  const slideId = slideshow.order.find(
    id => slideshow.data[id]?.layerId === layerId,
  );

  if (slideId) {
    return { type: 'image', asset: slideshow.data[slideId], id: slideId };
  }

  // If the first condition is not met, it looks for a videoclip that matches the
  // provided layer id. If found, type is set to video and id/asset to the ones of
  // that particular asset.
  const videoClipId = videoClips.order.find(
    id => videoClips.data[id]?.layerId === layerId,
  );

  if (videoClipId) {
    return {
      type: 'videoClip',
      asset: videoClips.data[videoClipId],
      id: videoClipId,
    };
  }

  // Finally if none of the above is matched, type is set to none and no asset/id
  // is set.
  return { type: 'none' };
};

export default useMediaLayer;

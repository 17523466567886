import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { InstagramAccountOption } from 'containers/InstagramPostVideoModal/types';
import { InstagramMediaType } from 'redux/middleware/api/podcast-service';
import {
  instagramAccountsSelector,
  instagramUserDataSelector,
} from 'redux/modules/social';
import { MAX_CHARS_CAPTIONS_PREVIEW } from './constants';

function formatDescription(val: string) {
  if (typeof val !== 'string') return undefined;

  return val.substring(0, MAX_CHARS_CAPTIONS_PREVIEW);
}

interface UseInstagramPostModalConfig {
  defaultDescription?: string;
  defaultMediaType?: InstagramMediaType;
  defaultInstagramUserId?: string;
}

export default function useInstagramPostModalSelector(
  config: UseInstagramPostModalConfig = {},
) {
  const {
    defaultDescription,
    defaultMediaType,
    defaultInstagramUserId,
  } = config;
  const instagramAccounts = useSelector(instagramAccountsSelector);
  const { providerUserId } = useSelector(instagramUserDataSelector);

  const accountsList = useMemo((): InstagramAccountOption[] => {
    return instagramAccounts.map(
      ({ username, profilePictureUrl, instagramId }) => ({
        label: username,
        logo: profilePictureUrl,
        value: instagramId,
      }),
    );
  }, [instagramAccounts]);

  const selectedUserId =
    defaultInstagramUserId ?? providerUserId ?? accountsList[0]?.value;

  return {
    defaultDescription: formatDescription(defaultDescription),
    defaultMediaType,
    defaultUserId: selectedUserId,
    accountsList,
  };
}

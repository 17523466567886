import { ThunkAction } from 'redux/types';
import { AspectRatioName, IEdgeVideos } from 'types';
import { getAspectRatio } from 'utils/aspect-ratio';

import { round } from 'utils/numbers';
import { DEFAULT_PROJECT_NAME } from '../../../utils/constants';
import { defaultVideoExportSettingsSelectorByRatio } from '../display-pref';
import { mediaUploader } from '../embed/actions/edge-videos';
import { exportFromWizard } from '../wizard-export/actions';
import { defaultWatermarkSelector } from './selectors';
import { CreateVideoTranscriptionProjectArgs } from './types';

/**
 * As captions videos do not allow selecting the intro/outro, this option will obtain the default
 * preferences and, if necessary, upload the videos and set the edgeVideos object.
 * @param {string} aspectRatioName - Aspect ratio name, it is used for getting the default prefs
 * @returns {object} - edge videos object containing the video ids obtained (if required) for intro/outro
 */
export const processEdgeVideos = (
  aspectRatioName: AspectRatioName,
): ThunkAction<Promise<IEdgeVideos>> => (dispatch, getState) => {
  const {
    intro: introPrefs,
    outro: outroPrefs,
  } = defaultVideoExportSettingsSelectorByRatio(aspectRatioName)(getState());

  const targets = [introPrefs, outroPrefs].filter(target => !!target?.value);

  return Promise.all(
    targets.map(target => dispatch(mediaUploader(target.value, 'url'))),
  ).then(([introSrc, outroSrc]) => {
    const intro = introSrc ? { videoId: introSrc.id } : undefined;
    const outro = outroSrc ? { videoId: outroSrc.id } : undefined;
    return { intro, outro };
  });
};

export const createVideoTranscriptionProject = ({
  aspectRatioName,
  videoSrc,
  durationMillis,
  trimStartMillis,
  trimEndMillis,
  transcription,
  uploadedVideoId,
  isAutoFrame,
  initiateExport,
  isClipSuggestion,
  isFullEpisodeExport,
  clipSuggestionLengthSecs,
  projectName,
  enableBlur = false,
  exportState,
  isVideoConfigLocked = false,
  projectCreatMethod,
}: CreateVideoTranscriptionProjectArgs): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  const ratio = getAspectRatio(aspectRatioName);
  const isFullClip =
    round(trimEndMillis) - round(trimStartMillis) === round(durationMillis);
  const edgeVideos = await dispatch(processEdgeVideos(aspectRatioName));

  return dispatch(
    exportFromWizard({
      aspectRatio: {
        width: ratio.get('width'),
        height: ratio.get('height'),
      },
      edgeVideos,
      projectName: projectName ?? DEFAULT_PROJECT_NAME,
      initiateExport,
      fullEpisodeExport: isFullEpisodeExport,
      transcription,
      projectCreatMethod,
      video: {
        source: videoSrc,
        endMillis: isFullClip ? undefined : trimEndMillis,
        startMillis: isFullClip ? undefined : trimStartMillis,
        originalDurationMillis: durationMillis,
        uploadedVideoId,
      },
      watermark: exportState?.watermark ?? defaultWatermarkSelector(getState()),
      isAutoFrame,
      isClipSuggestion,
      clipSuggestionLengthSecs,
      enableBlur,
      isVideoConfigLocked,
      ...exportState,
    }),
  );
};

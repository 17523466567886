import { fromJS, Record, RecordOf } from 'immutable';
import * as React from 'react';
import { isUndefined, noop } from 'underscore';

import CaptionsAnimationForm from 'components/CaptionsAnimationsForm';
import FadingScrollBars from 'components/FadingScrollBars';
import FontLoader from 'components/hoc/FontLoader';
import Modal from 'components/Modal';
import RndTextEditor, { RndTextHandlers } from 'components/RndTextEditor';
import Tabs from 'components/Tabs';
import TextBoxOptions from 'components/TextBoxOptions';
import TextTemplateSelector from 'components/TextTemplateSelector';
import TextWorkspace from 'components/TextWorkspace';
import { EditorVideoFramePreview } from 'containers/VideoFramePreview';
import { AspectRatioName, Size } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { getValue, keyIn } from 'utils/collections';
import { GRID_SIZE, scaleToGrid } from 'utils/embed/ui';
import { getFontName } from 'utils/fonts';
import { percentageOf, round, scale } from 'utils/numbers';
import { TemplateId } from 'utils/text-templates';
import {
  calculateVisibleText,
  getFontStyle,
  getFontWeight,
  isBold,
  isItalic,
  isUnderline,
  prefix,
} from 'utils/ui';
import { Captions } from './types';
import { formatCaptionAsProp } from './utils';

export interface LegacyEditCaptionsModalBodyProps {
  caption?: Captions;
  fonts?: Array<{
    name: string;
    familyName: string;
    family: string;
    url?: string;
  }>;
  styleTemplates: Array<{
    id: string;
    displayName: string;
    imageUrl: string;
    template: Captions;
  }>;
  workspaceAspectRatio?: number;
  onVisibleTextChange: (offset: [number, number]) => void;
  onRechunkRequired?: () => void;
  onTemplateSelect?: (displayName: string) => void;
  visibleTextOffsets: [number, number];
}

interface IUiState {
  animationEnabled: boolean;
  aspectRatioName: AspectRatioName;
  fontName: string;
  fontWeight: number;
  fontStyle: string;
  isFontLoaded: boolean;
  textBoxResizing: boolean;
  animationToColor: string;
}

interface IState {
  ui: RecordOf<IUiState>;
  viewportSize: Size<number>;
}

const uiFactory = Record<IUiState>({
  animationEnabled: undefined,
  animationToColor: undefined,
  aspectRatioName: undefined,
  fontName: undefined,
  fontStyle: undefined,
  fontWeight: undefined,
  isFontLoaded: false,
  textBoxResizing: false,
});

export default class LegacyEditCaptionsModalBody extends React.Component<
  LegacyEditCaptionsModalBodyProps,
  IState
> {
  public static defaultProps: Partial<LegacyEditCaptionsModalBodyProps> = {
    onRechunkRequired: noop,
    onTemplateSelect: noop,
    styleTemplates: [],
  };

  private selectedTemplateId: TemplateId = this.props.caption.get('templateId');
  private textEditor: RndTextEditor;
  private workspace: TextWorkspace;
  private textElement: HTMLSpanElement;

  public state: Readonly<IState> = {
    ui: uiFactory({
      animationEnabled: getValue(this.props.caption, ['animation', 'enabled']),
      animationToColor: getValue(
        this.props.caption,
        ['animation', 'color'],
        'rgba(255, 0, 0, 1)',
      ),
      fontName: getFontName(
        this.props.caption.getIn(['containerStyle', 'fontFamily']),
        this.props.fonts,
      ),
      fontStyle: this.props.caption.getIn(['containerStyle', 'fontStyle']),
      fontWeight: this.props.caption.getIn(['containerStyle', 'fontWeight']),
      isFontLoaded: false,
      textBoxResizing: false,
    }),
    viewportSize: undefined,
  };

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { workspaceAspectRatio: nextRatio } = nextProps;
    const { workspaceAspectRatio: ratio } = this.props;

    if (isUndefined(ratio) && !isUndefined(nextRatio)) {
      this.setState(({ ui }) => ({
        ui: ui.set('aspectRatioName', getAspectRatioName(nextRatio)),
      }));
    }
  }

  /*
   * NOTE: this is necessary for the captions box resizing.  without this optimization, resizing
   * the box quickly can get pretty laggy.
   */
  public shouldComponentUpdate(nextProps, nextState) {
    const {
      caption: captionProp,
      visibleTextOffsets,
      workspaceAspectRatio,
    } = this.props;
    const { ui } = this.state;

    return (
      nextProps.workspaceAspectRatio !== workspaceAspectRatio ||
      !nextProps.caption.equals(captionProp) ||
      nextProps.visibleTextOffsets !== visibleTextOffsets ||
      !nextState.ui.equals(ui)
    );
  }

  public componentDidUpdate(_, prevState) {
    const { ui: prevUi } = prevState;
    const { ui } = this.state;

    if (!prevUi.get('isFontLoaded') && ui.get('isFontLoaded')) {
      this.textEditor &&
        this.setVisibleText(
          this.textEditor.getData(),
          this.textEditor.formatStyleRules,
        );
    }
  }

  private handleRechunkChange = changeFn => {
    const { onRechunkRequired } = this.props;

    return (...args) => {
      onRechunkRequired();
      changeFn(...args);
    };
  };

  private handleFontChange = (handlers: RndTextHandlers) => (value, field) => {
    const { caption, fonts, onRechunkRequired } = this.props;

    switch (field) {
      case 'font': {
        onRechunkRequired();
        const font = fonts.find(f => f.name === value);
        handlers.setFont(font);
        this.setFont({ name: value });
        break;
      }

      case 'fontColor':
        handlers.setFontColor(value);
        break;

      case 'fontSize':
        onRechunkRequired();
        handlers.setFontSize(value, caption.get('viewport'));
        break;

      case 'fontStyle': {
        onRechunkRequired();
        const bold = value.has('bold');
        const italic = value.has('italic');
        const underline = value.has('underline');

        handlers.setBold(bold);
        handlers.setItalic(italic);
        handlers.setUnderline(underline);

        this.setFont({
          style: getFontStyle(italic),
          weight: getFontWeight(bold),
        });

        break;
      }

      default:
    }
  };

  private handleLineSpacingBlur = (handlers: RndTextHandlers) => value => {
    const newValue = value === '' ? 1 : value;

    handlers.setLineHeight(newValue);
  };

  private handlePaddingChange = (handlers: RndTextHandlers) => value => {
    handlers.setPaddingTop(value.top);
    handlers.setPaddingBottom(value.bottom);
    handlers.setPaddingLeft(value.left);
    handlers.setPaddingRight(value.right);
  };

  private handlePaddingBlur = (handlers: RndTextHandlers) => value => {
    const newValue = {
      bottom: value.bottom === '' ? 0 : value.bottom,
      left: value.left === '' ? 0 : value.left,
      right: value.right === '' ? 0 : value.right,
      top: value.top === '' ? 0 : value.top,
    };

    handlers.setPaddingTop(newValue.top);
    handlers.setPaddingBottom(newValue.bottom);
    handlers.setPaddingLeft(newValue.left);
    handlers.setPaddingRight(newValue.right);
  };

  private handleTextShadowChange = (handlers: RndTextHandlers) => value => {
    handlers.setTextShadowX(value.x);
    handlers.setTextShadowY(value.y);
    handlers.setTextShadowBlur(value.blur);
    handlers.setTextShadowColor(value.color);
  };

  private handlePositionChange = (handlers: RndTextHandlers) => value =>
    handlers.setPosition(value, GRID_SIZE as any, true);

  private handlePositionBlur = (handlers: RndTextHandlers) => value => {
    const newValue = {
      x: value.x === '' ? 0 : value.x,
      y: value.y === '' ? 0 : value.y,
    };

    handlers.setPosition(newValue, GRID_SIZE as any, true);
  };

  private handleFontLoad = () =>
    this.setState(({ ui }) => ({
      ui: ui.set('isFontLoaded', true),
    }));

  private handleTemplateSelect = (handlers: RndTextHandlers) => ({
    template,
    id,
    displayName,
  }) => {
    const { fonts, onTemplateSelect } = this.props;
    this.selectedTemplateId = id;
    const formattedTemplate = formatCaptionAsProp(template);

    const fontName = getFontName(
      template.getIn(['containerStyle', 'fontFamily']),
      fonts,
    );
    const fontWeight = template.getIn(['containerStyle', 'fontWeight']) || 400;
    const fontStyle =
      template.getIn(['containerStyle', 'fontStyle']) || 'normal';

    this.setFont({
      name: fontName,
      style: fontStyle,
      weight: fontWeight,
    });

    handlers.setData(formattedTemplate);
    onTemplateSelect(displayName);
  };

  private handleTextBoxDrag = setPosition => ({ x, y }) =>
    setPosition({ x, y });

  private handleTextBoxDragStop = setPosition => ({ x, y }) =>
    setPosition({ x, y });

  private handleTextBoxResize = (data, formatStyleRules) => (
    _,
    { height: deltaHeight, width: deltaWidth },
  ) => {
    const newSize = data
      .get('size')
      .withMutations(s =>
        s
          .update('height', h => h + deltaHeight)
          .update('width', h => h + deltaWidth),
      );

    const updatedCaption = data.set('size', newSize);

    this.setVisibleText(updatedCaption, formatStyleRules);
  };

  private handleTextBoxResizeStart = () =>
    this.setState(({ ui }) => ({
      ui: ui.set('textBoxResizing', true),
    }));

  private handleTextBoxResizeStop = setSize => ({ height, width, x, y }) => {
    setSize(x, y, height, width);
    this.setState(({ ui }) => ({
      ui: ui.set('textBoxResizing', false),
    }));
  };

  private handleTextEditorUpdate = ({ data, prevData, formatStyleRules }) => {
    const { fonts } = this.props;
    const fontKeys = ['fontFamily', 'fontSize', 'fontStyle', 'fontWeight'];
    const lineHeightPath = ['containerStyle', 'lineHeight'];
    const paddingTopPath = ['containerStyle', 'paddingTop'];
    const paddingRightPath = ['containerStyle', 'paddingRight'];
    const paddingBottomPath = ['containerStyle', 'paddingBottom'];
    const paddingLeftPath = ['containerStyle', 'paddingLeft'];
    const heightPath = ['size', 'height'];
    const widthPath = ['size', 'width'];

    const extractFont = editorData =>
      editorData
        .get('containerStyle')
        .filter(keyIn(...fontKeys))
        .withMutations(d => {
          const name = getFontName(d.get('fontFamily'), fonts);
          d.delete('fontFamily');
          d.set('fontName', name);
        });

    const oldFont = extractFont(prevData);
    const font = extractFont(data);
    const isFontLoaded = this.isFontLoaded(
      font.get('fontName'),
      font.get('fontWeight'),
      font.get('fontStyle'),
    );

    // if font isn't loaded yet, we'll catch it in componentDidUpdate
    if (
      (!oldFont.equals(font) && isFontLoaded) ||
      prevData.getIn(lineHeightPath) !== data.getIn(lineHeightPath) ||
      prevData.getIn(paddingTopPath) !== data.getIn(paddingTopPath) ||
      prevData.getIn(paddingRightPath) !== data.getIn(paddingRightPath) ||
      prevData.getIn(paddingBottomPath) !== data.getIn(paddingBottomPath) ||
      prevData.getIn(paddingLeftPath) !== data.getIn(paddingLeftPath) ||
      prevData.getIn(heightPath) !== data.getIn(heightPath) ||
      prevData.getIn(widthPath) !== data.getIn(widthPath)
    ) {
      this.setVisibleText(data, formatStyleRules);
    }
  };

  private handleWorkspaceSizeChange = setWorkspaceSize => ({
    height,
    width,
  }) => {
    setWorkspaceSize(height, width);
    this.setState({ viewportSize: { height, width } });
  };

  private handleToggleAnimation = enabled => {
    this.setState(({ ui }) => ({
      ui: ui.set('animationEnabled', enabled),
    }));
  };

  private handleAnimationToColorChange = color => {
    this.setState(({ ui }) => ({
      ui: ui.set('animationToColor', color),
    }));
  };

  private setVisibleText(caption, formatStyleRules) {
    const {
      caption: captionProp,
      onVisibleTextChange: onChangeVisibleText,
    } = this.props;

    const containerStyle = {
      ...formatStyleRules(caption.get('containerStyle')),
      ...caption.get('size').toObject(),
    };

    const textStyle = formatStyleRules(caption.get('textStyle'));

    onChangeVisibleText(
      calculateVisibleText(captionProp.get('text'), containerStyle, textStyle),
    );
  }

  private setFont({
    name,
    weight,
    style,
  }: Partial<{ name: string; weight: number; style: string }>) {
    const { ui } = this.state;

    const fontName = isUndefined(name) ? ui.get('fontName') : name;
    const fontWeight = isUndefined(weight) ? ui.get('fontWeight') : weight;
    const fontStyle = isUndefined(style) ? ui.get('fontStyle') : style;

    if (!this.isFontLoaded(fontName, fontWeight, fontStyle)) {
      this.setState(({ ui: uiState }) => ({
        ui: uiState.withMutations(u =>
          u
            .set('fontName', fontName)
            .set('fontWeight', fontWeight)
            .set('fontStyle', fontStyle)
            .set('isFontLoaded', false),
        ),
      }));
    }
  }

  public get caption() {
    const { caption: captionProp, visibleTextOffsets } = this.props;
    const { ui } = this.state;

    const caption = this.textEditor
      .getData(captionProp.get('viewport'))
      .withMutations(c => {
        c.delete('templateId');
        c.delete('text');
        c.set('textBoxHeight', c.getIn(['size', 'height']));
        c.deleteIn(['size', 'height']);

        const animation = {
          color: ui.get('animationEnabled')
            ? ui.get('animationToColor')
            : undefined,
          enabled: ui.get('animationEnabled'),
        };

        c.set('animation', fromJS(animation));
      });

    return {
      maxChars: visibleTextOffsets[1] - visibleTextOffsets[0],
      style: caption,
      templateId: this.selectedTemplateId,
    };
  }

  private isFontLoaded(name, weight, style) {
    const { ui } = this.state;

    return (
      name === ui.get('fontName') &&
      weight === ui.get('fontWeight') &&
      style === ui.get('fontStyle') &&
      ui.get('isFontLoaded')
    );
  }

  private textElementHeight = () => {
    if (!this.workspace || !this.textElement) return undefined;
    return this.textElement.getBoundingClientRect().height;
  };

  private renderWorkspace(data, handlers, formatStyleRules) {
    const {
      caption: captionProp,
      workspaceAspectRatio,
      visibleTextOffsets,
    } = this.props;
    const { ui, viewportSize } = this.state;

    const text = captionProp.get('text').slice(...visibleTextOffsets);

    const containerRules = {
      ...formatStyleRules(data.get('containerStyle')),
      textTransform: 'none',
      '-webkit-text-stroke': 'unset',
    };

    const textRules = formatStyleRules(data.get('textStyle'));

    const position = ui.get('textBoxResizing')
      ? undefined
      : {
          x: data.getIn(['position', 'left']),
          y: data.getIn(['position', 'top']),
        };

    const size = {
      height: data.getIn(['size', 'height']),
      width: data.getIn(['size', 'width']),
    };

    const workspaceRef = el => {
      this.workspace = el;
    };

    return (
      <TextWorkspace
        aspectRatio={workspaceAspectRatio}
        background={
          <EditorVideoFramePreview
            aspectRatio={workspaceAspectRatio}
            canvasDimensions={viewportSize}
            backgroundFor={{
              type: 'captions',
            }}
          />
        }
        className="captions-modal__workspace"
        disabled={false}
        onTextBoxDrag={this.handleTextBoxDrag(handlers.setPosition)}
        onTextBoxDragStop={this.handleTextBoxDragStop(handlers.setPosition)}
        onTextBoxResize={this.handleTextBoxResize(data, formatStyleRules)}
        onTextBoxResizeStart={this.handleTextBoxResizeStart}
        onTextBoxResizeStop={this.handleRechunkChange(
          this.handleTextBoxResizeStop(handlers.setSize),
        )}
        onWorkspaceSizeChange={this.handleWorkspaceSizeChange(
          handlers.setWorkspaceSize,
        )}
        ref={workspaceRef}
        textBoxPosition={position}
        textBoxSize={size}
      >
        <div className="text-workspace__input" style={prefix(containerRules)}>
          <span
            className="text-workspace__text"
            style={prefix(textRules)}
            ref={el => {
              this.textElement = el;
            }}
          >
            {text}
          </span>
        </div>
      </TextWorkspace>
    );
  }

  private renderStyleForm(data, handlers) {
    const { caption, fonts } = this.props;
    const { ui } = this.state;

    const { x = 0, y = 0 } = scaleToGrid(
      data.getIn(['position', 'left']),
      data.getIn(['position', 'top']),
      data.getIn(['viewport', 'width']),
      data.getIn(['viewport', 'height']),
    );

    const position = { x, y };

    const textShadow = {
      blur: data.getIn(['containerStyle', 'textShadow', 'blur']),
      color: data.getIn(['containerStyle', 'textShadow', 'color']),
      x: data.getIn(['containerStyle', 'textShadow', 'x']),
      y: data.getIn(['containerStyle', 'textShadow', 'y']),
    };

    const fontSize = round(
      scale(
        data.getIn(['containerStyle', 'fontSize']),
        data.getIn(['viewport', 'width']),
        caption.getIn(['viewport', 'width']),
      ),
    );

    const fontStyle = new Set(
      [
        isBold(data.getIn(['containerStyle', 'fontWeight'])) && 'bold',
        isItalic(data.getIn(['containerStyle', 'fontStyle'])) && 'italic',
        isUnderline(data.getIn(['containerStyle', 'textDecoration'])) &&
          'underline',
      ].filter(Boolean),
    );

    const viewportWidth = data.getIn(['viewport', 'width']);
    const paddings = {
      bottom:
        data.getIn(['containerStyle', 'paddingBottom']) === ''
          ? ''
          : percentageOf(
              data.getIn(['containerStyle', 'paddingBottom']),
              viewportWidth,
            ),
      left:
        data.getIn(['containerStyle', 'paddingLeft']) === ''
          ? ''
          : percentageOf(
              data.getIn(['containerStyle', 'paddingLeft']),
              viewportWidth,
            ),
      right:
        data.getIn(['containerStyle', 'paddingRight']) === ''
          ? ''
          : percentageOf(
              data.getIn(['containerStyle', 'paddingRight']),
              viewportWidth,
            ),
      top:
        data.getIn(['containerStyle', 'paddingTop']) === ''
          ? ''
          : percentageOf(
              data.getIn(['containerStyle', 'paddingTop']),
              viewportWidth,
            ),
    };

    return (
      <TextBoxOptions className="captions-modal__style-form">
        <TextBoxOptions.Font
          color={data.getIn(['containerStyle', 'color'])}
          fonts={fonts.map(f => f.name)}
          name={ui.get('fontName')}
          size={fontSize}
          style={fontStyle}
          onChange={this.handleFontChange(handlers)}
        />
        <TextBoxOptions.Background
          value={data.getIn(['containerStyle', 'background'])}
          onChange={handlers.setBackground}
        />
        <TextBoxOptions.Highlight
          value={data.getIn(['textStyle', 'background'])}
          onChange={handlers.setTextHighlight}
        />
        <TextBoxOptions.TextAlign
          value={data.getIn(['containerStyle', 'textAlign'])}
          onChange={handlers.setAlignment}
        />
        <TextBoxOptions.LineSpacing
          value={data.getIn(['containerStyle', 'lineHeight'])}
          onChange={this.handleRechunkChange(handlers.setLineHeight)}
          onBlur={this.handleRechunkChange(
            this.handleLineSpacingBlur(handlers),
          )}
        />
        <TextBoxOptions.Padding
          value={paddings as any}
          onChange={this.handleRechunkChange(
            this.handlePaddingChange(handlers),
          )}
          onBlur={this.handleRechunkChange(this.handlePaddingBlur(handlers))}
        />
        <TextBoxOptions.TextShadow
          value={textShadow}
          onChange={this.handleTextShadowChange(handlers)}
        />
        <TextBoxOptions.Position
          value={position}
          onChange={this.handlePositionChange(handlers)}
          onBlur={this.handleRechunkChange(this.handlePositionBlur(handlers))}
        />
      </TextBoxOptions>
    );
  }

  private renderAnimationForm(data, handlers) {
    const { ui } = this.state;
    return (
      <div>
        <CaptionsAnimationForm
          karaokeAnimation={ui.get('animationEnabled')}
          fromColor={data.getIn(['containerStyle', 'color'])}
          toColor={ui.get('animationToColor')}
          onKaraokeAnimationChange={this.handleToggleAnimation}
          onFromColorChange={handlers.setFontColor}
          onToColorChange={this.handleAnimationToColorChange}
        />
      </div>
    );
  }

  private renderStyleTabs(data, handlers) {
    const { styleTemplates } = this.props;

    return (
      <Tabs
        defaultActiveTabKey="captionsTemplateTab"
        id="captions-style-tabs"
        tabs={[
          {
            content: (
              <FadingScrollBars
                className="captions-modal__fade-scroller"
                hideTracksWhenNotNeeded
              >
                <div className="captions-modal__template-tab-content">
                  <TextTemplateSelector<any>
                    templates={styleTemplates}
                    onSelect={this.handleRechunkChange(
                      this.handleTemplateSelect(handlers),
                    )}
                  />
                </div>
              </FadingScrollBars>
            ),
            tabKey: 'captionsTemplateTab',
            title: 'styles',
          },
          {
            content: (
              <FadingScrollBars
                className="captions-modal__fade-scroller"
                hideTracksWhenNotNeeded
              >
                <div className="captions-modal__custom-tab-content">
                  {this.renderStyleForm(data, handlers)}
                </div>
              </FadingScrollBars>
            ),
            tabKey: 'captionsCustomTab',
            title: 'custom',
          },
          {
            content: (
              <FadingScrollBars
                className="captions-modal__fade-scroller"
                hideTracksWhenNotNeeded
              >
                <div className="captions-modal__animations-tab-content">
                  {this.renderAnimationForm(data, handlers)}
                </div>
              </FadingScrollBars>
            ),
            tabKey: 'captionsAnimationsTab',
            title: 'animations',
          },
        ]}
      />
    );
  }

  public render() {
    const { caption: captionProp, fonts } = this.props;
    const { ui } = this.state;

    const textEditorRef = el => {
      this.textEditor = el;
    };

    const font = fonts.find(f => f.name === ui.get('fontName'));

    return (
      <RndTextEditor
        data={captionProp as any}
        getTextElementHeight={this.textElementHeight}
        onUpdate={this.handleTextEditorUpdate}
        ref={textEditorRef}
        render={({ data, handlers, formatStyleRules }) => (
          <Modal.Row className="captions-modal__row">
            <FontLoader
              family={font.familyName}
              onLoad={this.handleFontLoad}
              style={ui.get('fontStyle')}
              url={font.url}
              weight={ui.get('fontWeight')}
            />
            <Modal.Col size={8} className="captions-modal__workspace-col">
              <div className="captions-modal__workspace-container">
                {this.renderWorkspace(data, handlers, formatStyleRules)}
              </div>
            </Modal.Col>
            <Modal.Col size={4} className="captions-modal__style-col">
              {this.renderStyleTabs(data, handlers)}
            </Modal.Col>
          </Modal.Row>
        )}
      />
    );
  }
}

import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CropperData } from 'components/Cropper';
import usePrevious from 'hooks/usePrevious';
import { StaticCrop } from 'redux/modules/embed/types';
import { Size } from 'types';
import { getVideoSize } from 'utils/video';
import { getScaledCropDimensions, scaleCropDimensions } from 'utils/video-crop';
import {
  VideoCropperInstance,
  VideoCropperMetadata,
  VideoToEdit,
} from './types';

export interface UseCropVideoConfig {
  show?: boolean;
  video?: VideoToEdit;
  onCrop?: () => void;
}

export interface UseCropVideoResult {
  cropperRef?: MutableRefObject<VideoCropperInstance>;
  cropMetadata: VideoCropperMetadata | undefined;
  videoFileSize: Size<number> | undefined;
  scaledCropDimensions: StaticCrop | undefined;
  onReady?: () => void;
  onCrop: () => Promise<void>;
}

export default function useCropVideo({
  show,
  video,
  onCrop,
}: UseCropVideoConfig): UseCropVideoResult {
  const [cropMetadata, setCropMetadata] = useState<
    VideoCropperMetadata | undefined
  >();
  const [scaledCropDimensions, setScaledCropDimensions] = useState<
    StaticCrop | undefined
  >();
  const [videoFileSize, setVideoFileSize] = useState<
    Size<number> | undefined
  >();

  const cropperRef = useRef<VideoCropperInstance>();

  const prevShow = usePrevious(show);

  const getVideoFileSize = useCallback(async (): Promise<void> => {
    const fileSize = await getVideoSize(video?.src);

    setVideoFileSize(fileSize);
  }, [video]);

  const handleScaledVideoDimensions = useCallback(
    (cropData: CropperData): void => {
      const { crop, imageData } = cropData;
      const { naturalWidth, naturalHeight } = imageData;

      setScaledCropDimensions(
        scaleCropDimensions(crop, videoFileSize, {
          width: naturalWidth,
          height: naturalHeight,
        }),
      );
    },
    [videoFileSize],
  );

  const handleCrop = useCallback(async (): Promise<void> => {
    const { cropData } = await cropperRef.current.getValues();

    handleScaledVideoDimensions(cropData);
    setCropMetadata(cropData);
    onCrop();
  }, [handleScaledVideoDimensions, onCrop]);

  const handleReady = useCallback(async (): Promise<void> => {
    if (cropMetadata) {
      return;
    }

    const { cropData } = await cropperRef.current.getValues();

    setCropMetadata({
      ...cropData,
      crop: {
        ...cropData.crop,
        ...getScaledCropDimensions(
          video?.cropInfo?.staticCrop,
          videoFileSize,
          cropData?.crop,
        ),
      },
    });
  }, [cropMetadata, videoFileSize, video]);

  useEffect(() => {
    if (!prevShow && show) {
      if (!videoFileSize) {
        getVideoFileSize();
      }

      setScaledCropDimensions(video?.cropInfo?.staticCrop);
      setCropMetadata(undefined);
    }
  }, [getVideoFileSize, prevShow, show, video, videoFileSize]);

  return {
    cropperRef,
    cropMetadata,
    videoFileSize,
    scaledCropDimensions,
    onReady: handleReady,
    onCrop: handleCrop,
  };
}

import * as React from 'react';
import { connect } from 'react-redux';
import { ClickSaveTemplateFrom } from 'redux/modules/mixpanel/types';

import { projectEmbedConfigurationIdSelector } from 'redux/modules/project/selectors';
import { createProjectTemplateAction } from 'redux/modules/project-templates/actions';
import { Dispatch, State } from 'redux/types';
import { SaveAsTemplateModalContentsProps } from '.';

type StateProps = Pick<SaveAsTemplateModalContentsProps, 'disabled'>;
type DispatchProps = Pick<SaveAsTemplateModalContentsProps, 'onSave'>;
type OwnProps = Pick<SaveAsTemplateModalContentsProps, 'disabled'>;

const mapStateToProps = (state: State, props: OwnProps): StateProps => ({
  disabled: props.disabled || !projectEmbedConfigurationIdSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSave: (
    displayName: string,
    from?: ClickSaveTemplateFrom,
    useMockAudioForPreview?: boolean,
  ) =>
    dispatch(
      createProjectTemplateAction(displayName, from, useMockAudioForPreview),
    ),
});

export default function(
  component: React.ComponentType<SaveAsTemplateModalContentsProps>,
) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}

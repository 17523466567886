import { Card } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import bem from 'utils/bem';

export type ClipSelectEditViewContainerProps = {
  children?: React.ReactNode;
  className?: string;
};

const Null = () => null;

const block = bem('clip-select-edit-view-container');

/**
 * A container with a border used to frame the screens that are part of the
 * editing UI (Eddy iframe and clippers)
 */
const ClipSelectEditViewContainer: React.FC<ClipSelectEditViewContainerProps> = ({
  children,
  className,
}) => (
  <div className={cn(block(), className)}>
    <Card header={<Null />} className={block('inner')} footer={<Null />}>
      <Card.Contents className={block('contents')}>{children}</Card.Contents>
    </Card>
  </div>
);

export default ClipSelectEditViewContainer;

import qs from 'query-string';
import { SPAREMIN_SERVICES_AUDIO_PROXY_URL } from 'config';

export function createMediaProxyUrl(
  srcUrl: string,
  appendXRequestQuery = true,
) {
  return qs.stringifyUrl({
    url: `${SPAREMIN_SERVICES_AUDIO_PROXY_URL}/audio-proxy`,
    query: {
      url: qs.stringifyUrl({
        url: srcUrl,
        query: appendXRequestQuery ? { 'x-request': 'xhr' } : undefined,
      }),
    },
  });
}

import * as Sentry from '@sentry/browser';
import * as ids from 'short-id';
import { isString } from 'underscore';

import { directUpload } from 'redux/middleware/api/media-upload-service/actions';
import { fetchRecordingById } from 'redux/middleware/api/recording-service/actions';
import {
  clipAudio,
  getAudioUpload,
  getEntireAudios,
  uploadAudio,
} from 'redux/middleware/api/recording-upload-service/actions';
import { GetAudioUploadResult } from 'redux/middleware/api/recording-upload-service/types';
import { IApiResponse } from 'redux/middleware/api/types';
import { cancelPolling } from 'redux/modules/common/actions/core';
import { pollForTranscript } from 'redux/modules/common/actions/transcription';
import {
  entireAudioInstancesSelector,
  entireAudiosSelector,
} from 'redux/modules/entities/selectors';
import { ThunkAction } from 'redux/types';
import { AddAudioMeta, SoundWaveGeneration } from 'types';
import { ApplicationError } from 'utils/ApplicationError';
import {
  RECORDING_IMPORT_POLL_INTERVAL_MILLIS,
  RECORDING_IMPORT_POLL_MAX_ATTEMPTS,
  RECORDING_IMPORT_RETRY_ATTEMPTS,
} from 'utils/constants';
import { md5File } from 'utils/file';
import reduxPoll, { PollingTimeoutError } from 'utils/redux-poll';
import { preSignedUpload } from 'utils/request';
import { camelToSnake } from 'utils/string';
import { ActionTypes, PollEntireAudioInstanceConfig, Selectors } from './types';

export function createActionTypes(camelName: string): ActionTypes {
  const lowercaseName = camelToSnake(camelName).toLowerCase();
  const uppercaseName = lowercaseName.replace('-', '_').toUpperCase();

  const createActionType = (suffix: string) =>
    `app/${lowercaseName}/${uppercaseName}_${suffix}`;

  return {
    awaitImport: createActionType('ENTIRE_AUDIO_AWAIT_IMPORT'),
    awaitUploadFailure: createActionType('ENTIRE_AUDIO_AWAIT_UPLOAD_FAILURE'),
    awaitUploadRequest: createActionType('ENTIRE_AUDIO_AWAIT_UPLOAD_REQUEST'),
    awaitUploadSuccess: createActionType('ENTIRE_AUDIO_AWAIT_UPLOAD_SUCCESS'),
    clear: createActionType('ENTIRE_AUDIO_CLEAR'),
    clipFailure: createActionType('ENTIRE_AUDIO_CLIP_FAILURE'),
    clipRequest: createActionType('ENTIRE_AUDIO_CLIP_REQUEST'),
    clipSuccess: createActionType('ENTIRE_AUDIO_CLIP_SUCCESS'),
    setRecordingId: createActionType('ENTIRE_AUDIO_RECORDING_SET'),
    transferFailure: createActionType('ENTIRE_AUDIO_TRANSFER_FAILURE'),
    transferRequest: createActionType('ENTIRE_AUDIO_TRANSFER_REQUEST'),
    transferSuccess: createActionType('ENTIRE_AUDIO_TRANSFER_SUCCESS'),
    transferProgress: createActionType('ENTIRE_AUDIO_TRANSFER_PROGRESS'),
  };
}

export function getActionTypes(actionTypesOrName: string | ActionTypes) {
  return isString(actionTypesOrName)
    ? createActionTypes(actionTypesOrName)
    : actionTypesOrName;
}

export default function createActions(
  actionTypesOrName: ActionTypes | string,
  selectors: Selectors,
) {
  const actionTypes = getActionTypes(actionTypesOrName);
  const uploadPollId = ids.generate();
  const importPollId = ids.generate();
  const transcriptPollId = ids.generate();

  const pollEntireAudioInstance = ({
    pollId,
    entireAudioInstanceId,
    shouldContinue,
  }: PollEntireAudioInstanceConfig): ThunkAction<Promise<
    IApiResponse<GetAudioUploadResult>
  >> => (dispatch, getState) => {
    const instanceId =
      entireAudioInstanceId ??
      selectors.entireAudioInstanceIdSelector(getState());

    return reduxPoll(dispatch, () => dispatch(getAudioUpload(instanceId)), {
      id: pollId,
      retryAttempts: 3,
      shouldContinue: err => {
        const error = new ApplicationError('Error uploading audio', 'ER004');

        if (err) {
          Sentry.captureException(err);
          throw error;
        }

        const entireAudioInstances = entireAudioInstancesSelector(getState());
        const entireAudioInstance = entireAudioInstances.get(
          String(instanceId),
        );
        const entireAudioId = entireAudioInstance.get('entireAudio');

        const entireAudios = entireAudiosSelector(getState());
        const entireAudio = entireAudios.get(String(entireAudioId));

        if (!entireAudio) {
          return true;
        }

        const rehydratedEntireAudioInstance = entireAudioInstance.set(
          'entireAudio',
          entireAudio,
        );
        return shouldContinue(rehydratedEntireAudioInstance);
      },
    });
  };

  const waitForAudioImport = (): ThunkAction<Promise<IApiResponse<any>>> => (
    dispatch,
    getState,
  ) => {
    dispatch({ type: actionTypes.awaitImport });
    return reduxPoll(
      dispatch,
      () => {
        const recordingId = selectors.recordingIdSelector(getState());
        return dispatch(fetchRecordingById(recordingId));
      },
      {
        id: importPollId,
        retryAttempts: RECORDING_IMPORT_RETRY_ATTEMPTS,
        intervalMillis: RECORDING_IMPORT_POLL_INTERVAL_MILLIS,
        maxAttempts: RECORDING_IMPORT_POLL_MAX_ATTEMPTS,
        shouldContinue: err =>
          !err && !selectors.isImportedSelector(getState()),
      },
    );
  };

  const waitForEntireAudioUpload = (): ThunkAction<Promise<
    IApiResponse<GetAudioUploadResult>
  >> => dispatch => {
    dispatch({ type: actionTypes.awaitUploadRequest });

    const pollingJob = dispatch(
      pollEntireAudioInstance({
        pollId: uploadPollId,
        shouldContinue: instance =>
          instance.getIn(['entireAudio', 'status']) !== 'completed',
      }),
    );

    pollingJob
      .then(() => dispatch({ type: actionTypes.awaitUploadSuccess }))
      .catch(() => dispatch({ type: actionTypes.awaitUploadFailure }));

    return pollingJob;
  };

  const checkForExistingEntireAudio = (
    src: Blob | string,
  ): ThunkAction<Promise<string | undefined>> => async dispatch => {
    if (typeof src === 'string') {
      return undefined;
    }

    const md5 = await md5File(src);
    const result = await dispatch(getEntireAudios(md5));

    return result.response.result.length === 0 ? undefined : md5;
  };

  const uploadAudioUrl = (
    url: string,
    metadata: AddAudioMeta,
  ): ThunkAction<Promise<number | undefined>> => dispatch => {
    return dispatch(
      uploadAudio({
        src: metadata.originalAudioUrl ?? url,
        listenNotesPodcastId: metadata.podcastId,
        listenNotesEpisodeId: metadata.episodeId,
        onProgress(progress) {
          dispatch({
            type: actionTypes.transferProgress,
            payload: { progress },
          });
        },
      }),
    ).then(res => res.response.result);
  };

  const uploadAudioFile = (
    file: Blob,
    metadata: AddAudioMeta,
  ): ThunkAction<Promise<number | undefined>> => dispatch => {
    return dispatch(checkForExistingEntireAudio(file)).then(
      existingAudioMd5 => {
        if (existingAudioMd5) {
          return dispatch(uploadAudio({ md5: existingAudioMd5 })).then(
            res => res.response.result,
          );
        }

        return dispatch(directUpload(file)).then(({ response }) => {
          const { bucket, key, upload } = response;
          return preSignedUpload(upload.url, file, upload.fields, progress => {
            dispatch({
              type: actionTypes.transferProgress,
              payload: { progress },
            });
          }).then(() =>
            dispatch(
              uploadAudio({
                directUploadBucket: bucket,
                directUploadKey: key,
                listenNotesPodcastId: metadata.podcastId,
                listenNotesEpisodeId: metadata.episodeId,
              }),
            ).then(res => {
              const entireAudioInstanceId = res.response.result;
              return dispatch(
                pollEntireAudioInstance({
                  entireAudioInstanceId,
                  pollId: uploadPollId,
                  shouldContinue: instance =>
                    instance.get('entireAudio') === undefined,
                }),
              ).then(() => entireAudioInstanceId);
            }),
          );
        });
      },
    );
  };

  return {
    clearEntireAudio: () => ({ type: actionTypes.clear }),
    clipEntireAudio: (
      startMillis: number,
      endMillis: number,
      language?: string,
      waveType?: SoundWaveGeneration,
      transcribe?: boolean,
    ): ThunkAction<Promise<any>> => (dispatch, getState) => {
      return dispatch(waitForEntireAudioUpload())
        .then(() => {
          dispatch({ type: actionTypes.clipRequest });
          const instanceId = selectors.entireAudioInstanceIdSelector(
            getState(),
          );
          return dispatch(
            clipAudio(
              instanceId,
              startMillis,
              endMillis,
              language,
              waveType,
              transcribe,
            ),
          );
        })
        .then(({ response }) => {
          return dispatch({
            payload: { ...response },
            type: actionTypes.setRecordingId,
          });
        })
        .then(() => dispatch(waitForAudioImport()))
        .then(() => dispatch({ type: actionTypes.clipSuccess }))
        .then(() => selectors.recordingSelector(getState()))
        .catch(err => {
          dispatch({ type: actionTypes.clipFailure });
          throw err;
        });
    },
    uploadEntireAudio: (
      src: Blob | string,
      metadata: AddAudioMeta = {},
    ): ThunkAction<Promise<any>> => dispatch => {
      dispatch({ type: actionTypes.transferRequest });

      // if src is a string, we just upload it and don't worry about hashing to
      // see if the file exists
      const uploadResult =
        typeof src === 'string'
          ? dispatch(uploadAudioUrl(src, metadata))
          : dispatch(uploadAudioFile(src, metadata));

      return uploadResult
        .then(id => {
          dispatch({
            payload: { id },
            type: actionTypes.transferSuccess,
          });
        })
        .catch(err => {
          dispatch({ type: actionTypes.transferFailure });
          throw err;
        });
    },
    waitForTranscript: (): ThunkAction<Promise<any>> => async (
      dispatch,
      getState,
    ) => {
      try {
        const versionId = selectors.versionIdSelector(getState());
        return await dispatch(pollForTranscript(versionId, transcriptPollId));
      } catch (err) {
        const error =
          err instanceof PollingTimeoutError
            ? new Error(
                'Application timed out waiting for transcription to complete',
              )
            : err;
        throw error;
      }
    },
    cancelPolling: (): ThunkAction<Promise<any>> => async dispatch => {
      dispatch(cancelPolling(uploadPollId));
      dispatch(cancelPolling(importPollId));
      dispatch(cancelPolling(transcriptPollId));
    },
  };
}

import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNumber } from 'underscore';
import { DestinationPreset } from 'blocks/DestinationPlatforms';
import AudioClipper, {
  AudioClipperComponent,
  AudioClipperProps,
} from 'components/AudioClipper';
import Popover from 'components/Popover';
import useToggle from 'hooks/useToggle';
import {
  clearWaveform,
  getAudioWaveform,
  loadMediaFailure,
} from 'redux/modules/async-audio-clipper/actions';
import {
  standardizationProgressSelector,
  standardizationStatusSelector,
  waveformSelector,
} from 'redux/modules/async-audio-clipper/selectors';
import { showError } from 'redux/modules/notification/actions';
import { Dispatch } from 'redux/types';
import { Omit } from 'types';
import bem from 'utils/bem';
import { isFileLike } from 'utils/file';
import AsyncAudioClipperProgress from './AsyncAudioClipperProgress';
import useAudioUrl from './useAudioUrl';

export interface AsyncAudioClipperProps
  extends Omit<AudioClipperProps, 'backend'> {
  entireAudioInstanceId: number;
  initialPresetKey?: DestinationPreset['key'];
  uploadProgress?: number;
  estimatedDurationSec?: number;
  proxySrc?: string;
}

const STANDARDIZATION_KNOWLEDGE_BASE_URL =
  'https://learn.headliner.app/hc/en-us/articles/1500008562601-Why-is-my-audio-being-re-encoded-when-I-upload-to-Headliner-';

const block = bem('async-audio-clipper');

/**
 * "Async" because the user can interact with the audio clipper while the audio
 * is being uploaded and the waveform is being created.  With the standard
 * AudioClipper component, the UI is blocked until everything is fully loaded.
 */
const AsyncAudioClipper = React.forwardRef<
  AudioClipperComponent,
  AsyncAudioClipperProps
>((props, ref) => {
  const {
    entireAudioInstanceId,
    uploadProgress,
    className,
    src,
    ...rest
  } = props;
  const dispatch = useDispatch<Dispatch>();
  const audioPeaks = useSelector(waveformSelector);
  const standardizationProgress = useSelector(standardizationProgressSelector);
  const standardizationStatus = useSelector(standardizationStatusSelector);
  const [tooltipVisible, toggleTooltip] = useToggle(false);
  const audioUrl = useAudioUrl(props);
  const [popoverTrigger, setPopoverTrigger] = useState<HTMLDivElement>(null);

  const isStandardizing =
    standardizationStatus === 'processing' && isNumber(standardizationProgress);

  const handleError = (message: string) => {
    dispatch(loadMediaFailure(new Error(message)));
  };

  useEffect(() => {
    if (isStandardizing) {
      toggleTooltip(true);
    }
  }, [dispatch, isStandardizing, src, toggleTooltip]);

  useEffect(() => {
    let promise;
    if (entireAudioInstanceId) {
      promise = dispatch(getAudioWaveform(entireAudioInstanceId)).catch(() => {
        dispatch(
          showError({
            message: 'Error loading waveform',
            dismissAfterSec: 5,
            code: 'IN010',
          }),
        );
      });
    }
    return () => {
      promise?.cancel?.();
      dispatch(clearWaveform());
    };
  }, [dispatch, entireAudioInstanceId]);

  return (
    <>
      <div className={cn(block(), className)} ref={setPopoverTrigger}>
        <AudioClipper
          backend="MediaElement"
          ref={ref}
          src={audioUrl}
          audioPeaks={audioPeaks}
          onMediaError={handleError}
          renderOverlay={({ audioLoading }) => {
            return (
              <AsyncAudioClipperProgress
                audioLoading={audioLoading}
                uploadProgress={uploadProgress}
                isFile={isFileLike(src)}
                src={src}
              />
            );
          }}
          {...rest}
        />
        <Popover
          arrow
          autoFocus={false}
          className={block('popover')}
          contain={false}
          isDismissable={false}
          isOpen={tooltipVisible}
          offset={26}
          onClose={() => toggleTooltip(false)}
          restoreFocus={false}
          triggerElement={popoverTrigger}
        >
          <Popover.Title>
            We decided to re-encode this audio file to avoid errors
          </Popover.Title>
          To speed up your next visit, you can{' '}
          <a
            href={STANDARDIZATION_KNOWLEDGE_BASE_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            learn how to avoid this delay
          </a>
        </Popover>
      </div>
    </>
  );
});

export default AsyncAudioClipper;

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as React from 'react';
import { STRIPE_PUBLISHABLE_KEY } from 'config';

interface Props {
  children?: React.ReactNode;
}

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY, { locale: 'auto' });

function StripeProvider(props: Props) {
  const { children } = props;
  return <Elements stripe={stripePromise}>{children}</Elements>;
}

export default StripeProvider;

import { Button, Menu, ThemeProvider } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ClipCtaButtonProps } from 'blocks/ClipSelect/components/ClipCtaButton';
import { Dislike } from 'components/icons';

import { showError, showSuccess } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { ClipSuggestionDislikeReason } from 'types/common';
import { selectorBlock as block } from '../../utils';
import { useClipSelect } from '../ClipSelectContext';
import { useClipSelectNavigation } from '../ClipSelectNavigationContext/ClipSelectNavigationContext';
import { useClipSelectTracking } from '../ClipSelectTrackingContext';

export interface DislikeClipButtonProps
  extends Pick<ClipCtaButtonProps, 'disabled'> {
  suggestionId?: number;
}

const DislikeClipButton = (props: DislikeClipButtonProps) => {
  const { disabled, suggestionId } = props;
  const dispatch = useDispatch<Dispatch>();
  const { onDislikeClip } = useClipSelectTracking();
  const [, send] = useClipSelectNavigation();
  const {
    activeSuggestion,
    visibleSuggestionIds,
    onDislikeClip: onDislike,
  } = useClipSelect();

  const handleItemClick = useCallback(
    async value => {
      onDislikeClip(value);
      send({
        type: 'DISLIKE_CLIP',
        payload: {
          suggestionCount: visibleSuggestionIds.length - 1,
        },
      });

      if (!activeSuggestion) {
        return;
      }

      try {
        onDislike(value as ClipSuggestionDislikeReason, activeSuggestion);

        dispatch(
          showSuccess({
            dismissAfterSec: 5,
            message: "We'll suggest less clips like that in the future",
            title: 'Thanks for your feedback',
          }),
        );
      } catch (error) {
        dispatch(
          showError({
            message: 'There was an error saving your feedback',
            dismissAfterSec: 5,
          }),
        );
      }
    },
    [
      activeSuggestion,
      dispatch,
      onDislike,
      onDislikeClip,
      send,
      visibleSuggestionIds.length,
    ],
  );

  return (
    <ThemeProvider theme="dark">
      <Menu
        button={
          <Button
            isDisabled={disabled || !suggestionId}
            className={block('action-round-button')}
            color="tertiary"
          >
            <Dislike style={{ width: '16px', height: '16px' }} />
          </Button>
        }
        offset={10}
        placement="top"
        density="comfortable"
        onAction={handleItemClick}
      >
        <Menu.Item key={ClipSuggestionDislikeReason.Advertisement}>
          It's an advertisement
        </Menu.Item>

        <Menu.Item key={ClipSuggestionDislikeReason.NotInteresting}>
          It's not interesting
        </Menu.Item>

        <Menu.Item key={ClipSuggestionDislikeReason.CutsOffMidSentence}>
          It cuts off mid sentence
        </Menu.Item>

        <Menu.Item key={ClipSuggestionDislikeReason.Other}>
          Other reason
        </Menu.Item>
      </Menu>
    </ThemeProvider>
  );
};

export default DislikeClipButton;

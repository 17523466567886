import React from 'react';
import CaptionsChildViewCallout from './CaptionsChildViewCallout';

import { CaptionsChildViewContentsProps } from './CaptionsChildViewContents';
import CaptionsChildViewDefaultTopSlot from './CaptionsChildViewDefaultTopSlot';

export const DEFAULT_EDIT_CAPTION_BUTTON_LABEL = 'edit caption style';
export const DEFAULT_SWITCH_CAPTION_LABEL = 'Captions';
export const INCLUDE_CAPTIONS_SWITCH_LABEL = 'Include captions';

export const DEFAULT_CHILD_VIEW_LABELS: Partial<CaptionsChildViewContentsProps> = {
  switchLabel: DEFAULT_SWITCH_CAPTION_LABEL,
  editButtonLabel: DEFAULT_EDIT_CAPTION_BUTTON_LABEL,
};

export const DEFAULT_CHILD_VIEW_CONTENTS: Partial<CaptionsChildViewContentsProps> = {
  topSlot: <CaptionsChildViewDefaultTopSlot />,
  ...DEFAULT_CHILD_VIEW_LABELS,
};

export const INCLUDE_CAPTIONS_CHILD_VIEW_CONTENTS: Partial<CaptionsChildViewContentsProps> = {
  topSlot: <CaptionsChildViewDefaultTopSlot />,
  switchLabel: INCLUDE_CAPTIONS_SWITCH_LABEL,
  editButtonLabel: DEFAULT_EDIT_CAPTION_BUTTON_LABEL,
};

export const FULL_EPISODE_CHILD_VIEW_CONTENTS: Partial<CaptionsChildViewContentsProps> = {
  topSlot: (
    <CaptionsChildViewDefaultTopSlot
      callout={
        <CaptionsChildViewCallout text="Editing captions in full episodes isn’t supported yet, but it will be soon!" />
      }
    />
  ),
  ...DEFAULT_CHILD_VIEW_LABELS,
};

import cn from 'classnames';
import React from 'react';
import LinkButton, { LinkButtonProps } from 'components/LinkButton';
import { episodeListBlock as block } from '../../../utils';
import EpisodeRowActionButtonIcon from './EpisodeRowActionButtonIcon';
import EpisodeRowActionButtonLabel from './EpisodeRowActionButtonLabel';

export interface EpisodeRowActionButtonProps
  extends Pick<LinkButtonProps, 'className' | 'onClick' | 'disabled'> {
  children?: React.ReactNode;
}

interface EpisodeRowActionButtonComponent
  extends React.FC<EpisodeRowActionButtonProps> {
  Icon: typeof EpisodeRowActionButtonIcon;
  Label: typeof EpisodeRowActionButtonLabel;
}

const EpisodeRowActionButton: EpisodeRowActionButtonComponent = ({
  children,
  className,
  disabled,
  onClick,
}) => (
  <LinkButton
    theme="cta"
    className={cn(block('episode-action-button'), className)}
    {...{ disabled, onClick }}
  >
    {children}
  </LinkButton>
);

EpisodeRowActionButton.Icon = EpisodeRowActionButtonIcon;
EpisodeRowActionButton.Label = EpisodeRowActionButtonLabel;

export default EpisodeRowActionButton;

import { createPath } from 'history';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import ClipSelectPage, {
  ClipSelectPageProps,
  OnOpenInAdvancedEditor,
  OnSubmit,
} from 'blocks/ClipSelect';
import { ClipSuggestion } from 'blocks/ClipSelect/types';
import {
  enableCreationClipSuggestion,
  getCreationClipSuggestions,
} from 'redux/middleware/api/creation-service/actions';
import {
  createCreationClipSuggestion,
  dislikeCreationSuggestedClip,
  reprocessCreationSuggestion,
} from 'redux/modules/clip-select';
import { showError } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { ClipSuggestionDislikeReason } from 'types';
import { getDownloadPath, getProjectPath } from 'utils/routes';
import useVideoTranscriptionSuggestedClipInfo from './useVideoTranscriptionSuggestedClipInfo';

export interface VideoTranscriptionSuggestedClipsPageProps {}

type HandleSelectClip = ClipSelectPageProps['onSelectClip'];
type HandleDislikeClip = ClipSelectPageProps['onDislikeClip'];

const VideoTranscriptionSuggestedClipsPage: React.FC<VideoTranscriptionSuggestedClipsPageProps> = () => {
  const dispatch = useDispatch<Dispatch>();

  const {
    creationRequestId,
    eddyProjectId,
    suggestedClips,
    dislikedSuggestionIds,
    clipsPageInfo,
    source,
  } = useVideoTranscriptionSuggestedClipInfo();

  const handleSubmit: OnSubmit = async clipSuggestion => {
    try {
      await dispatch(
        enableCreationClipSuggestion(creationRequestId, clipSuggestion.id),
      );

      window.open(getDownloadPath(clipSuggestion.widgetId), '_blank');
    } catch {
      dispatch(showError('Error sharing clip', 5));
    }
  };

  const handleDislikeClip: HandleDislikeClip = useCallback(
    async (
      reason: ClipSuggestionDislikeReason,
      clipSuggestion: ClipSuggestion,
    ): Promise<void> => {
      await dispatch(
        dislikeCreationSuggestedClip(
          reason,
          clipSuggestion.id,
          clipSuggestion.id,
        ),
      );
    },
    [dispatch],
  );

  const handleSelectClip: HandleSelectClip = useCallback(
    async ({ region, activeSuggestionId }): Promise<number | void> => {
      const suggestionId = await dispatch(
        createCreationClipSuggestion(
          creationRequestId,
          activeSuggestionId,
          region.startMillis,
          region.endMillis,
        ),
      );

      return suggestionId;
    },
    [creationRequestId, dispatch],
  );

  const handleUpdateSuggestions = useCallback(() => {
    dispatch(getCreationClipSuggestions(creationRequestId));
  }, [creationRequestId, dispatch]);

  const handleReprocessClip: ClipSelectPageProps['onReprocessClip'] = useCallback(
    async suggestionId => {
      const clip = suggestedClips.find(c => c.id === suggestionId);

      if (!clip) {
        return undefined;
      }

      await dispatch(
        reprocessCreationSuggestion(
          creationRequestId,
          suggestionId,
          clip.startMillis,
          clip.endMillis,
        ),
      );

      return null;
    },
    [creationRequestId, dispatch, suggestedClips],
  );

  const handleOpenInAdvancedEditor: OnOpenInAdvancedEditor = useCallback(
    async ({ id, projectUuid }): Promise<void> => {
      try {
        await dispatch(enableCreationClipSuggestion(creationRequestId, id));
        window.open(createPath(getProjectPath(projectUuid)), '_blank');
      } catch {
        dispatch(showError('Error while opening the clip', 5));
      }
    },
    [creationRequestId, dispatch],
  );

  return (
    <ClipSelectPage
      {...{
        eddyProjectId,
        suggestedClips,
        dislikedSuggestionIds,
        clipsPageInfo,
        source,
      }}
      mediaType="video"
      onDislikeClip={handleDislikeClip}
      onReprocessClip={handleReprocessClip}
      onOpenInAdvancedEditor={handleOpenInAdvancedEditor}
      onSelectClip={handleSelectClip}
      onSubmit={handleSubmit}
      onUpdateSuggestions={handleUpdateSuggestions}
    />
  );
};

export default VideoTranscriptionSuggestedClipsPage;

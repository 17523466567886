import React from 'react';
import { useSelector } from 'react-redux';

import { WizardExportVideoStep } from 'containers/ProjectWizards/ExportVideoStep';
import { creationConfigurationIdSelector } from 'redux/modules/wizard-export/selectors';

export interface VideoWizardSingleClipExportStepProps {
  durationMillis: number;
  onError?: (error: Error) => void;
}

const VideoWizardSingleClipExportStep: React.FC<VideoWizardSingleClipExportStepProps> = props => {
  const { durationMillis, onError } = props;

  const widgetId = useSelector(creationConfigurationIdSelector);

  return (
    <WizardExportVideoStep
      widgetId={widgetId}
      estimatedDurationSec={Math.max((durationMillis / 1000) * 2, 60)}
      onError={onError}
    />
  );
};

export default VideoWizardSingleClipExportStep;

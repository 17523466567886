import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Step } from 'components/SteppedModal';
import usePrevious from 'hooks/usePrevious';
import { instagramPostStatusSelector } from 'redux/modules/social';
import { PostStatus } from 'redux/modules/social/constants';
import LoaderStep from '../LoaderStep';

export interface UseLoaderStepConfig {
  goToStep: (nextStepId: string) => void;
  onHide: () => void;
}

export default function useLoaderStep({
  goToStep,
  onHide,
}: UseLoaderStepConfig): Step {
  const postStatus = useSelector(instagramPostStatusSelector);

  const handleGoToInitialStep = useCallback((): void => {
    goToStep('select-account');
  }, [goToStep]);

  const prevPostStatus = usePrevious(postStatus);

  if (
    prevPostStatus === PostStatus.IN_PROGRESS &&
    (postStatus === PostStatus.SUCCESS || postStatus === PostStatus.FAILURE)
  ) {
    onHide();
    handleGoToInitialStep();
  }

  return {
    id: 'loader',
    component: <LoaderStep />,
    hideFooter: true,
  };
}

import { fromJS, List, Map } from 'immutable';

/*
 * https://stackoverflow.com/questions/43607652/typescript-immutable-proper-way-of-extending-immutable-map-type
 *
 * NOTE that this will only validate that the property name is part of the type on `get`.  it is
 * currently impossible to validate `getIn`
 */
export interface IImmutableMap<T> extends Map<string, any> {
  get<K extends keyof T>(key: K, defaultValue?: T[K]): T[K];

  mergeWith<T2>(
    merger: <K extends keyof T>(previous?: T[K], next?: T[K], key?: K) => T[K],
    ...iterables: [T2]
  ): IImmutableMap<T & T2>;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  set<K extends string, V>(k: string, v: V): this;
  set<S>(o: S): this;

  update<U>(
    updater: (value: IImmutableMap<T>) => IImmutableMap<U>,
  ): IImmutableMap<T & U>;
  update<V, V2>(
    k: string,
    updater: (value: V) => V2,
  ): IImmutableMap<T & { [k: string]: V2 }>;

  withMutations(fn: (mutable: IImmutableMap<T>) => any): IImmutableMap<T>;
}

export type DeepImmutableMap<T> = IImmutableMap<
  {
    [K in keyof T]: T[K] extends Array<infer U>
      ? U extends object
        ? List<DeepImmutableMap<U>>
        : List<U>
      : T[K] extends object
      ? DeepImmutableMap<T[K]>
      : T[K];
  }
>;

export function createMap<T extends object>(o: T): DeepImmutableMap<T> {
  return fromJS(o) as DeepImmutableMap<T>;
}

import { Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import * as React from 'react';

import PostTaskBanner from '../containers/PostTaskBanner';
import { PostTaskProps } from '../types';
import { block } from '../utils';

const PostTask: React.SFC<PostTaskProps> = ({
  banner,
  className,
  footerButton,
  title,
  subTitle,
  headerContent,
}) => (
  <div className={cn(block(), className)}>
    <div className={block('body')}>
      <Spacer
        orientation="vertical"
        space="16px"
        align="center"
        justify="center"
        className={block('body-heading')}
      >
        <div className={block('body-title')}>{title}</div>
        <div className={block('body-subtitle')}>{subTitle}</div>
        {headerContent}
      </Spacer>

      {banner}

      {footerButton && (
        <div className={block('footer-button')}>{footerButton}</div>
      )}
    </div>
  </div>
);

PostTask.defaultProps = {
  banner: <PostTaskBanner />,
};

export default PostTask;

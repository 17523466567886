import { Spacer } from '@sparemin/blockhead';
import * as React from 'react';

import { getAspectRatioName } from 'utils/aspect-ratio';
import ChildViewTip from '../assets/ChildViewTip';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { DynamicVideoIntegration } from '../types/integrations';

export interface DynamicVideoChildContentsProps {
  integration: DynamicVideoIntegration;
}

const DynamicVideoChildContents: React.FC<DynamicVideoChildContentsProps> = ({
  integration,
}) => {
  const { aspectRatio } = useTemplateState();

  return (
    <Spacer orientation="vertical" space="20px" align="center" justify="center">
      {integration?.childViewProps?.body}

      {getAspectRatioName(aspectRatio) === 'landscape' && <ChildViewTip />}
    </Spacer>
  );
};

export default DynamicVideoChildContents;

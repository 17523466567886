import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PodcastWorkflowTemplate } from 'redux/middleware/api/podcast-service';
import { loadWorkflowTemplates } from 'redux/modules/automation-cms';
import { workflowTemplateIdsSelector } from 'redux/modules/automation-cms/selectors';
import { createEmbedConfiguration } from 'redux/modules/embed/actions';
import { podcastWorkflowTemplatesSelector } from 'redux/modules/entities';
import { pushModal } from 'redux/modules/modal';
import {
  createProject,
  updateCurrentProject,
} from 'redux/modules/project/actions';
import { overwriteProjectTemplateAction } from 'redux/modules/project-templates';
import {
  createConfigurationFromScratch,
  createConfigurationFromTemplate,
  formatSoundwave,
  formatTextOverlayInfo,
} from 'redux/modules/wizard-export/actions';
import { ExportConfiguration } from 'redux/modules/wizard-export/types';
import { Dispatch } from 'redux/types';
import {
  AspectRatioDimensions,
  DeepImmutableMap,
  IEmbedConfig,
  ISoundwave,
  ITextOverlayItem,
  ProjectCreationMethod,
} from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { getValue } from 'utils/collections';
import { addDefaultAudioLayers } from 'utils/embed/audio';

import { exportState, getStateContent } from './state';
import { TemplateEditorStateExport, VideoTemplateState } from './types';
import useEditorLoading from './useEditorLoading';
import {
  CONFIGURATION_PROPS_FOR_START_END_INIT_KEYS,
  DEFAULT_END_MILLI,
  formatDynamicTextOverlays,
  initializeStartEndMilli,
} from './utils';

export interface UseSaveTemplateProps {
  aspectRatio: AspectRatioDimensions;
  creationMethod: ProjectCreationMethod;
  templateId: string;
}

export type OnSuccessCallback = () => void;

export default function useSaveTemplate({
  aspectRatio,
  creationMethod,
  templateId,
}: UseSaveTemplateProps) {
  const [isSaving, setIsSaving] = useState(false);
  const [, setEditorLoading] = useEditorLoading();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const dispatch = useDispatch<Dispatch>();
  const templates = useSelector(workflowTemplateIdsSelector);
  const aspectRatioName = getAspectRatioName(aspectRatio);

  const handleCreateProject = useCallback(async (): Promise<void> => {
    await dispatch(
      createProject(
        undefined,
        aspectRatio,
        undefined,
        creationMethod,
        undefined,
      ),
    );
  }, [aspectRatio, creationMethod, dispatch]);

  const handleCreateEmbedConfiguration = useCallback(
    async (configuration: IEmbedConfig): Promise<string> => {
      const finalConfiguration = {
        ...configuration,
        layerOrder: addDefaultAudioLayers(configuration.layerOrder),
        audioInfo: [],
        expectedDurationMilli: DEFAULT_END_MILLI,
      };

      CONFIGURATION_PROPS_FOR_START_END_INIT_KEYS.forEach(
        (prop: keyof IEmbedConfig) => {
          Object.assign(finalConfiguration, {
            [prop]: initializeStartEndMilli(configuration, prop),
          });
        },
      );

      const response = await dispatch(
        createEmbedConfiguration(finalConfiguration),
      );

      return getValue(response, ['response', 'configuration', 'wid']);
    },
    [dispatch],
  );

  const handleUpdateCurrentProject = useCallback(
    async (wid: string): Promise<void> => {
      await dispatch(
        updateCurrentProject(wid, undefined, undefined, aspectRatio),
      );
    },
    [aspectRatio, dispatch],
  );

  const getConfigurationPayload = useCallback(
    (currentState: TemplateEditorStateExport): ExportConfiguration => {
      return {
        ...currentState,
        aspectRatio,
        projectName: undefined,
        initiateExport: false,
        projectCreatMethod: creationMethod,
        transcription: {
          transcriptUrl: null,
        },
      };
    },
    [aspectRatio, creationMethod],
  );

  const handleTextOverlayInfoFormat = useCallback(
    (exportedState: TemplateEditorStateExport): ITextOverlayItem[] => {
      const formattedTextOverlays = formatDynamicTextOverlays(
        exportedState?.textOverlays,
      );

      return formatTextOverlayInfo(formattedTextOverlays);
    },
    [],
  );

  const getCurrentTemplateFromState = useCallback((): DeepImmutableMap<
    PodcastWorkflowTemplate
  > => {
    return dispatch((_, getState) => {
      const templatesList = podcastWorkflowTemplatesSelector(getState());

      return templatesList?.get(templateId);
    });
  }, [dispatch, templateId]);

  const getEmbedConfiguration = useCallback(
    async (
      exportedState: TemplateEditorStateExport,
      textOverlayInfo: ITextOverlayItem[],
      soundwave: ISoundwave,
    ): Promise<IEmbedConfig> => {
      if (creationMethod === 'blankProject') {
        return dispatch(
          createConfigurationFromScratch(
            getConfigurationPayload(exportedState),
            textOverlayInfo,
            soundwave,
          ),
        );
      }

      return dispatch(
        createConfigurationFromTemplate(
          getConfigurationPayload(exportedState),
          textOverlayInfo,
          soundwave,
          getCurrentTemplateFromState(),
        ),
      );
    },
    [
      creationMethod,
      dispatch,
      getConfigurationPayload,
      getCurrentTemplateFromState,
    ],
  );

  const handleCreateConfiguration = useCallback(
    async (state: VideoTemplateState): Promise<IEmbedConfig> => {
      const exportedState = exportState(state);
      const textOverlayInfo = handleTextOverlayInfoFormat(exportedState);
      const soundwave = formatSoundwave(exportedState.soundwave);

      const embedConfiguration = await getEmbedConfiguration(
        exportedState,
        textOverlayInfo,
        soundwave,
      );

      return embedConfiguration;
    },
    [getEmbedConfiguration, handleTextOverlayInfoFormat],
  );

  const handleLoadingState = useCallback(
    (isLoading: boolean): void => {
      setIsSaving(isLoading);
      setEditorLoading(isLoading);
    },
    [setEditorLoading],
  );

  const pushSaveAsTemplateModal = useCallback(async (): Promise<void> => {
    const successfullySaved = await dispatch(
      pushModal({
        name: 'SaveAsTemplate',
        params: {
          from: 'UCS',
          useMockAudioForPreview: true,
        },
      }),
    );

    if (successfullySaved) {
      // Reloading templates list so we can get the last created/updated template
      // in case we need the new ID to override current template.
      await dispatch(loadWorkflowTemplates(aspectRatioName));

      setSaveSuccess(true);
      handleLoadingState(false);
    }
  }, [aspectRatioName, dispatch, handleLoadingState]);

  const handleSaveTemplateConfiguration = useCallback(
    async (state: VideoTemplateState): Promise<void> => {
      const configuration = await handleCreateConfiguration(state);
      const wid = await handleCreateEmbedConfiguration(configuration);

      await handleUpdateCurrentProject(wid);
    },
    [
      handleCreateConfiguration,
      handleCreateEmbedConfiguration,
      handleUpdateCurrentProject,
    ],
  );

  const handleSaveTemplate = useCallback(
    async (state: VideoTemplateState): Promise<void> => {
      handleLoadingState(true);
      try {
        await handleCreateProject();
        await handleSaveTemplateConfiguration(state);

        handleLoadingState(false);

        await pushSaveAsTemplateModal();

        setSaveSuccess(true);
      } catch {
        setSaveSuccess(false);
        handleLoadingState(false);
      }
    },
    [
      handleCreateProject,
      handleLoadingState,
      handleSaveTemplateConfiguration,
      pushSaveAsTemplateModal,
    ],
  );

  const handleOverwriteTemplate = useCallback(
    async (state: VideoTemplateState): Promise<void> => {
      handleLoadingState(true);

      try {
        await handleCreateProject();
        await handleSaveTemplateConfiguration(state);
        await dispatch(
          overwriteProjectTemplateAction(
            saveSuccess
              ? templates.get(aspectRatioName).last()
              : getStateContent(state).template.templateId,
            true,
          ),
        );
        await dispatch(loadWorkflowTemplates(aspectRatioName));

        setSaveSuccess(true);
      } catch {
        setSaveSuccess(false);
      } finally {
        handleLoadingState(false);
      }
    },
    [
      aspectRatioName,
      dispatch,
      handleCreateProject,
      handleLoadingState,
      handleSaveTemplateConfiguration,
      saveSuccess,
      templates,
    ],
  );

  return {
    isSaving,
    saveSuccess,
    handleSaveTemplate,
    handleOverwriteTemplate,
  };
}

import {
  PopoverContainer,
  PopoverContainerProps,
  PopoverMenu,
  PopoverMenuProps,
  PopoverOverlayProps,
  ThemeProvider,
  UnstyledPopoverOverlay,
  useTheme,
} from '@sparemin/blockhead';
import cn from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import BemCSSTransition from 'components/BemCssTransition';
import PopoverDOM, { PopoverDOMProps } from './PopoverDOM';
import PopoverTitle from './PopoverTitle';
import { block } from './utils';

const ANIMATION_DURATION_MILLIS = 200;

export type PopoverProps = Pick<PopoverDOMProps, 'inline'> &
  Pick<
    PopoverContainerProps,
    | 'arrow'
    | 'isOpen'
    | 'flip'
    | 'offset'
    | 'placement'
    | 'preventOverflow'
    | 'triggerElement'
  > &
  Pick<
    PopoverOverlayProps,
    | 'autoFocus'
    | 'contain'
    | 'isDismissable'
    | 'onClose'
    | 'shouldCloseOnBlur'
    | 'shouldCloseOnInteractOutside'
    | 'restoreFocus'
  > &
  Pick<PopoverMenuProps, 'arrowSize'> & {
    children?: React.ReactNode;
    className?: string;
    bordered?: boolean;
    gutter?: React.CSSProperties['padding'];
    shape?: 'default' | 'chip';
  };

const PopoverComponent: React.FC<PopoverProps> = ({
  arrow,
  arrowSize,
  autoFocus = true,
  bordered,
  children,
  className,
  contain = true,
  flip,
  gutter,
  inline,
  isDismissable,
  isOpen,
  offset,
  onClose,
  placement,
  preventOverflow,
  restoreFocus = true,
  shape = 'default',
  shouldCloseOnBlur,
  shouldCloseOnInteractOutside,
  triggerElement,
}) => {
  const theme = useTheme();
  // TODO - might require parameterization of the BemCSSTransition component, like the
  // underlying CSSTransition component
  const overlayRef = useRef<HTMLDivElement>();

  const [animationExited, setAnimationExited] = useState(!isOpen);
  const [prevIsOpen, setPrevIsOpen] = useState(false);

  if (isOpen !== prevIsOpen) {
    setPrevIsOpen(isOpen);

    if (isOpen) {
      setAnimationExited(false);
    }
  }

  const handleAnimationExited = useCallback(() => {
    setAnimationExited(true);
  }, []);

  return (
    <ThemeProvider theme="dark">
      <PopoverDOM inline={inline}>
        <PopoverContainer
          keepMounted
          updatePositionOnTriggerResize
          {...{
            arrow,
            flip,
            isOpen,
            offset,
            placement,
            preventOverflow,
            triggerElement,
          }}
        >
          {animationExited ? null : (
            <BemCSSTransition<HTMLDivElement>
              className={block({ [shape]: true, bordered: !!bordered })}
              in={isOpen}
              mountOnEnter
              nodeRef={overlayRef}
              onExited={handleAnimationExited}
              timeout={ANIMATION_DURATION_MILLIS}
              transitionClassName={block('transition')}
              unmountOnExit
            >
              <UnstyledPopoverOverlay
                ref={overlayRef}
                style={{
                  ['--gutter' as string]: gutter,
                  ['--animation-duration' as string]: `${ANIMATION_DURATION_MILLIS}ms`,
                }}
                {...{
                  autoFocus,
                  contain,
                  isDismissable,
                  isOpen,
                  onClose,
                  restoreFocus,
                  shouldCloseOnBlur,
                  shouldCloseOnInteractOutside,
                }}
              >
                <PopoverMenu
                  arrowSize={arrowSize ?? shape === 'default' ? 30 : 16}
                  backgroundColor={theme.palette.d1}
                  borderColor={theme.legacyPalette.neutral1.dark}
                  borderWeight={bordered ? 2 : 0}
                  className={cn(block('container'), className)}
                >
                  {children}
                </PopoverMenu>
              </UnstyledPopoverOverlay>
            </BemCSSTransition>
          )}
        </PopoverContainer>
      </PopoverDOM>
    </ThemeProvider>
  );
};

const Popover = Object.assign(PopoverComponent, {
  Title: PopoverTitle,
});

export default Popover;

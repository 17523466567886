import * as React from 'react';
import { noop } from 'underscore';
import { VideoProps } from '../types';
import {
  block,
  getScaledVideoDimensions,
  getVideoContainerStyles,
  getVideoStyles,
} from '../utils';
import DragHandle from './DragHandle';

export default class DraggableVideo extends React.Component<VideoProps> {
  public static defaultProps: Partial<VideoProps> = {
    onPause: noop,
    onPlay: noop,
    videoRef: noop,
  };

  private video: HTMLVideoElement;

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<VideoProps>) {
    const { playing, seekToSec } = this.props;
    const {
      onPause,
      onPlay,
      playing: nextPlaying,
      seekToSec: nextSeekToSec,
    } = nextProps;

    if (playing && !nextPlaying) {
      this.video.pause();
      onPause();
    }

    if (!playing && nextPlaying) {
      this.video.play().then(onPlay);
    }

    if (nextSeekToSec && nextSeekToSec !== seekToSec) {
      this.video.currentTime = nextSeekToSec;
    }
  }

  private setVideoRefs = (el: HTMLVideoElement) => {
    const { videoRef, mediaRef } = this.props;
    this.video = el;
    videoRef(el);
    mediaRef && mediaRef(el);
  };

  public render() {
    const {
      src,
      top,
      left,
      cropInfo,
      videoFileSize,
      cropMetadata,
      dragging,
      boundingBox,
      onDragStart,
      onDragStop,
      onDrag,
    } = this.props;

    const { dimension } = cropInfo ?? {};

    const scaledVideoDimensions = getScaledVideoDimensions(
      videoFileSize,
      dimension,
      cropMetadata,
      boundingBox,
    );

    return (
      <div>
        <DragHandle
          cropInfo={{
            ...cropInfo,
            dimension: scaledVideoDimensions?.width
              ? scaledVideoDimensions
              : dimension,
          }}
          onDragStart={onDragStart}
          onDrag={onDrag}
          onDragStop={onDragStop}
          top={top}
          left={left}
          dragging={dragging}
        />
        <div
          className={block('video-container')}
          style={{
            ...getVideoContainerStyles(
              top,
              left,
              scaledVideoDimensions,
              boundingBox,
            ),
          }}
        >
          <video
            style={{
              ...(boundingBox
                ? getVideoStyles(boundingBox)
                : {
                    ...dimension,
                    top,
                    left,
                  }),
            }}
            className={block('video')}
            src={src}
            ref={this.setVideoRefs}
          />
        </div>
      </div>
    );
  }
}

import { Copy, PaletteCircle, TabItem, Tabs } from '@sparemin/blockhead';
import React from 'react';
import AnimationsTab from '../../extensions/AnimationsTab/AnimationsTab';

import { useTextOverlay } from '../../state/TextOverlayProvider';
import { block } from '../../utils';
import TextOverlayToolbar from '../TextOverlayToolbar';
import { TextOverlayControlPaneTab } from './types';

interface TextOverlayControlPaneTabsProps {
  showEmoji: boolean;
}

const TextOverlayControlPaneTabs: React.FunctionComponent<TextOverlayControlPaneTabsProps> = props => {
  const { showEmoji } = props;

  const { animationsTabExtension } = useTextOverlay();
  const {
    enabled: animationsTabEnabled,
    displayConfig: animationsTabDisplayConfig,
  } = animationsTabExtension ?? {};

  const tabs = React.useMemo<TextOverlayControlPaneTab[]>(() => {
    const baseTabs: TextOverlayControlPaneTab[] = [
      {
        key: 'customizations',
        children: <TextOverlayToolbar showEmoji={showEmoji} />,
        icon: <PaletteCircle />,
        title: 'customizations',
      },
    ];

    if (animationsTabEnabled) {
      baseTabs.push({
        key: 'animations',
        children: <AnimationsTab />,
        disabled: animationsTabDisplayConfig?.showTabDisabled,
        icon: <Copy />,
        title: 'animations',
        tooltip: animationsTabDisplayConfig?.tabTooltip,
      });
    }

    return baseTabs;
  }, [animationsTabDisplayConfig, animationsTabEnabled, showEmoji]);

  const disabledTabKeys = React.useMemo(
    () => tabs.filter(tab => tab.disabled).map(tab => tab.key),
    [tabs],
  );

  if (tabs.length <= 1) {
    return <TextOverlayToolbar showEmoji={showEmoji} />;
  }

  return (
    <Tabs className={block('tabs-wrapper')} disabledKeys={disabledTabKeys}>
      {tabs.map(tab => (
        <TabItem
          key={tab.key}
          icon={tab.icon}
          title={tab.title}
          tooltip={tab.tooltip}
        >
          {tab.children}
        </TabItem>
      ))}
    </Tabs>
  );
};

export default TextOverlayControlPaneTabs;

import { IApiAction, IApiResponse } from '../types';

export enum ServiceMethod {
  PROXY_MEDIA = 'PROXY_MEDIA',
  EXTERNAL_MEDIA_EXISTS = 'EXTERNAL_MEDIA_EXISTS',
}

export const ACTION_KEY = 'AUDIO_PROXY_SERVICE' as const;

type ApiAction<M extends ServiceMethod, A> = IApiAction<
  typeof ACTION_KEY,
  M,
  A
>;

export type ProxyMediaArgs = [
  /**
   * The proxy media url
   */
  string,
  /**
   * [optional] whether to append xRequest query or not (defaults to true)
   */
  boolean?,
];

export type ProxyMediaAction = ApiAction<
  ServiceMethod.PROXY_MEDIA,
  ProxyMediaArgs
>;

export type ExternalMediaExistsAction = ApiAction<
  ServiceMethod.EXTERNAL_MEDIA_EXISTS,
  ProxyMediaArgs
>;

export type ProxyMediaResult = Blob;

export type ExternalMediaExistsResult = boolean;

export interface AudioProxyServiceDispatch {
  (action: ProxyMediaAction): Promise<IApiResponse<ProxyMediaResult>>;
  (action: ExternalMediaExistsAction): Promise<
    IApiResponse<ExternalMediaExistsResult>
  >;
}

import * as React from 'react';
import { connect } from 'react-redux';

import request from 'superagent';
import { RejectedReason } from 'components/FileUploader';
import { Navigator } from 'components/Wizard';
import { MediaImportedArgs } from 'containers/MediaUploadModal/types';
import {
  INormalizedVideoUpload,
  IVideoUpload,
} from 'redux/middleware/api/media-upload-service';
import { uploadVideo } from 'redux/middleware/api/media-upload-service/actions';
import { Tier } from 'redux/middleware/api/plan-service';
import { waitForVideoUpload } from 'redux/modules/common/actions';
import * as mixpanelActions from 'redux/modules/mixpanel';
import { pushModal } from 'redux/modules/modal';
import {
  clearNotification,
  showError,
} from 'redux/modules/notification/actions';
import { tierSelector } from 'redux/modules/pricing';
import { goToCreate, goToProject } from 'redux/modules/router/actions';
import {
  handleUploadVideo,
  searchForVideo,
  VideoUploadArgs,
} from 'redux/modules/video-upload';
import { createVideoTranscriptionProject } from 'redux/modules/video-wizard/actions';
import { projectIdSelector } from 'redux/modules/wizard-export/selectors';
import { Dispatch, State, ThunkAction } from 'redux/types';
import { IVideoClip, Layerable } from 'types';
import { ApplicationError } from 'utils/ApplicationError';
import { DropZoneType, UPLOAD_VIDEO_MAX_FILE_SIZE_MB } from 'utils/constants';
import { FatalError } from 'utils/FatalError';

import { MIXPANEL_WIZARD_STEP_MAP } from './constants';
import { getProjectCreationMethod } from './utils';
import { VideoWizardProps } from './VideoWizard';

type StateProps = Pick<VideoWizardProps, 'tier'>;

type DispatchProps = Pick<
  VideoWizardProps,
  | 'onCancel'
  | 'onClickSampleFile'
  | 'onMount'
  | 'onStepChange'
  | 'onSubmit'
  | 'onError'
  | 'onCustomizeStepError'
  | 'onFileUpload'
  | 'onCompleted'
>;

const ERROR_ID = 'video-wizard-error';

const onCompleted = (): ThunkAction<void> => (dispatch, getState) => {
  const projectId = projectIdSelector(getState());

  dispatch(goToProject({ projectId, history: false, fromWizard: true }));
};

const updateMainVideoInfo = (
  videoClips: Layerable[],
  uploadedVideo: IVideoUpload,
): Partial<IVideoClip> => {
  return {
    ...videoClips[0], // Main video is always in the first position.
    assetType: 'video',
    videoId: uploadedVideo?.id,
    videoUrl: uploadedVideo?.transcodedVideoUrl,
    previewThumbnail: uploadedVideo?.previewThumbnail?.thumbnails?.find(
      thumbnail => thumbnail?.type === 'firstFrame',
    ),
  };
};

const createProject = (
  args: Partial<VideoUploadArgs>,
  projectName: string,
  mediaImportedArgs?: MediaImportedArgs,
): ThunkAction<void> => async dispatch => {
  const {
    aspectRatioName,
    src,
    durationMillis,
    trimStartMillis,
    trimEndMillis,
    language,
    isAutoFrame,
    initiateExport,
    isClipSuggestion,
    isFullEpisodeExport,
    clipSuggestionLengthSecs,
    transcribe,
    exportState,
  } = args;

  const uploadedVideo = await dispatch(
    handleVideoUpload(
      {
        ...args,
        // As per the comment here: https://sparemin.atlassian.net/browse/SPAR-24905?focusedCommentId=53689
        // clip suggestion projects required the uploaded video trasncript to be enabled. If not, the later
        // export will fail. For this reason, the transcribe value wis forsed to true when these kind
        // of projects are exported.
        transcribe: isClipSuggestion ? true : transcribe,
      },
      mediaImportedArgs,
    ),
  );
  const mainVideo = updateMainVideoInfo(exportState?.videoClips, uploadedVideo);
  const uploadedVideoId = Number(uploadedVideo?.id);

  dispatch(
    createVideoTranscriptionProject({
      aspectRatioName,
      videoSrc: !uploadedVideoId ? src : undefined,
      durationMillis,
      trimStartMillis,
      trimEndMillis,
      transcription: {
        transcribe,
        language,
      },
      uploadedVideoId,
      isAutoFrame,
      initiateExport,
      isClipSuggestion,
      isFullEpisodeExport,
      clipSuggestionLengthSecs,
      projectName,
      enableBlur: isClipSuggestion,
      exportState: {
        ...exportState,
        videoClips: [
          mainVideo as Layerable,
          ...exportState.videoClips.slice(1),
        ],
      },
      isVideoConfigLocked: true,
      projectCreatMethod: getProjectCreationMethod(
        isClipSuggestion,
        isFullEpisodeExport,
      ),
    }),
  ).catch(() => {
    dispatch(
      showError({
        message: 'There was an error creating your project.',
        code: 'ER009',
        id: ERROR_ID,
      }),
    );

    dispatch(goToCreate());
  });
};

const uploadVideoFromImportedFile = (
  args: Partial<VideoUploadArgs>,
  mediaImportedArgs?: MediaImportedArgs,
): ThunkAction<Promise<INormalizedVideoUpload>> => async dispatch => {
  const { trimStartMillis, trimEndMillis, language, transcribe } = args;
  const { provider } = mediaImportedArgs;

  const { response } = await dispatch(
    uploadVideo(undefined, {
      trimStart: trimStartMillis,
      trimEnd: trimEndMillis,
      transcribe,
      language,
      initiateProcessing: true,
      ...(provider === 'googleDrive'
        ? {
            providerUserId: mediaImportedArgs.providerUserId,
            googleDriveFileId: mediaImportedArgs.googleDriveFileId,
          }
        : {}),
      ...(provider === 'zoom'
        ? {
            providerUserId: mediaImportedArgs.providerUserId,
            zoomMeetingId: mediaImportedArgs.zoomMeetingId,
            zoomRecordingFileId: mediaImportedArgs.zoomRecordingFileId,
          }
        : {}),
      ...(provider === 'youtube'
        ? {
            youtubeUrl: mediaImportedArgs.youtubeUrl,
          }
        : {}),
    }),
  );

  return response;
};

const handleVideoUpload = (
  args: Partial<VideoUploadArgs>,
  mediaImportedArgs?: MediaImportedArgs,
): ThunkAction<Promise<IVideoUpload>> => async dispatch => {
  try {
    const { src, trimStartMillis, trimEndMillis, language } = args;

    if (mediaImportedArgs) {
      const uploadResult = await dispatch(
        uploadVideoFromImportedFile(args, mediaImportedArgs),
      );

      const uploadedVideo = await dispatch(
        waitForVideoUpload(uploadResult.result),
      );

      return uploadedVideo?.toJS();
    }

    let sourceFile;

    if (typeof src === 'string') {
      const { body } = await request.get(src).responseType('blob');

      sourceFile = body;
    } else {
      sourceFile = src;
    }

    const video = await dispatch(
      searchForVideo({
        src: sourceFile,
        trimStartMillis,
        trimEndMillis,
        language,
      }),
    );

    if (video) {
      return video;
    }

    const uploadResult = await dispatch(
      handleUploadVideo(sourceFile, { ...args, initiateProcessing: true }),
    );

    const uploadedVideo = await dispatch(
      waitForVideoUpload(uploadResult.result),
    );

    return uploadedVideo?.toJS();
  } catch {
    dispatch(
      showError({
        message: 'Video upload failed',
        code: 'ER003',
        id: ERROR_ID,
      }),
    );

    dispatch(goToCreate());

    return undefined;
  }
};

const onError = (
  err: ApplicationError,
  file?: File,
  reason?: RejectedReason,
): ThunkAction<void> => (dispatch, getState) => {
  const { message, code } = err;

  if (err instanceof FatalError) {
    dispatch(
      showError({
        message: 'There was an error creating your video',
        code: 'ER003',
        id: ERROR_ID,
      }),
    );
    dispatch(goToCreate());
    return;
  }

  if (file) {
    dispatch(
      mixpanelActions.onFileUpload({
        dropZoneType: DropZoneType.VIDEO_TRANSCRIPTION,
        source: 'localFile',
        file,
        error: message,
      }),
    );
  }

  const tier = tierSelector(getState());
  const size = file.size / 1024 ** 2;

  if (
    reason === RejectedReason.SIZE_LIMIT_EXCEEDED &&
    tier === Tier.FREE &&
    size < UPLOAD_VIDEO_MAX_FILE_SIZE_MB
  ) {
    dispatch(
      pushModal({
        name: 'FileSizeUpsell',
        params: {
          variant: 'file',
        },
      }),
    );
  } else {
    dispatch(showError({ message, code }));
  }
};

const mapStateToProps = (state: State): StateProps => ({
  tier: tierSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onCancel: (nav: Navigator) => {
    dispatch(
      mixpanelActions.onCancelWizard(
        'videoTranscription',
        MIXPANEL_WIZARD_STEP_MAP[nav.stepId],
      ),
    );
    dispatch(goToCreate());
  },
  onClickSampleFile: () => {
    dispatch(
      mixpanelActions.onFileUpload({
        source: 'sampleFile',
        type: 'videoTranscription',
      }),
    );
  },
  onError: (err, file, reason) => dispatch(onError(err, file, reason)),
  onCustomizeStepError: () => {
    dispatch(
      showError({
        message: 'There was an error creating your template',
        code: 'ER007',
        dismissAfterSec: 5,
        id: ERROR_ID,
      }),
    );

    dispatch(goToCreate());
  },
  onFileUpload: (file: File) => {
    dispatch(
      mixpanelActions.onFileUpload({
        dropZoneType: DropZoneType.VIDEO_TRANSCRIPTION,
        source: 'localFile',
        file,
      }),
    );
  },
  onMount: () => {
    dispatch(clearNotification(ERROR_ID));
    dispatch(mixpanelActions.clearMixpanel());
  },
  onStepChange: (stepId, props) => {
    dispatch(
      mixpanelActions.onWizardNext({
        step: MIXPANEL_WIZARD_STEP_MAP[stepId],
        type: 'videoTranscription',
        ...props,
      }),
    );
  },
  onSubmit: async (
    args: VideoUploadArgs,
    projectName: string,
    mediaImportedArgs?: MediaImportedArgs,
  ) => {
    const {
      aspectRatioName,
      durationMillis,
      trimStartMillis,
      trimEndMillis,
      platform,
      videoPodcastType,
      videoType,
    } = args;

    const clipDurationInMillis = trimEndMillis - trimStartMillis;

    dispatch(
      mixpanelActions.onCompleteWizard({
        aspectRatio: aspectRatioName,
        audioSource: 'upload',
        clipDuration: durationMillis,
        clipEnd: trimEndMillis,
        clipped: durationMillis - clipDurationInMillis !== 0,
        clipStart: trimStartMillis,
        transcription: true,
        type: 'videoTranscription',
        platform: platform ?? 'None',
        videoPodcastType,
        videoType: videoType?.id ?? 'None',
      }),
    );

    return dispatch(createProject(args, projectName, mediaImportedArgs));
  },
  onCompleted: () => dispatch(onCompleted()),
});

export default function(component: React.ComponentType<VideoWizardProps>) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}

import * as React from 'react';
import { connect } from 'react-redux';
import { noop } from 'underscore';

import LinkButton, { LinkButtonProps } from 'components/LinkButton';
import { onFileUpload } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import { SAMPLE_AUDIO_URL } from 'utils/constants';

type DispatchProps = Pick<LinkButtonProps, 'onClick'>;

interface OwnProps {
  onClick?: (src: string) => void;
  source?: string;
}

const mapDispatchToProps = (
  dispatch: Dispatch,
  { onClick = noop, source }: OwnProps,
): DispatchProps => ({
  onClick: () => {
    source && dispatch(onFileUpload({ source: 'sampleFile', type: source }));
    onClick(SAMPLE_AUDIO_URL);
  },
});

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { source, ...own } = ownProps;
  return {
    ...own,
    ...stateProps,
    ...dispatchProps,
  };
}

const component = connect(null, mapDispatchToProps, mergeProps)(LinkButton);

export type SelectSampleAudioButtonProps = React.ComponentPropsWithoutRef<
  typeof component
>;

export default component;

import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import { InputAdornment } from 'components/Form';
import MaxLengthTextareaField from 'components/Form/MaxLengthTextareaField';

import { VideoCamera } from 'components/icons';
import { TemplateEditorStateExport } from 'components/VideoTemplateEditor';
import useTemplateValidationCheck from 'components/VideoTemplateEditor/useTemplateValidationCheck';
import { pushModal } from 'redux/modules/modal/actions';
import { Dispatch } from 'redux/types';
import { AudioSourceType, FrameSize, Omit } from 'types';
import { AUDIOGRAM_CUTOFF_MILLIS } from 'utils/constants';
import AudiogramCtaOverlay from './AudiogramCtaOverlay';
import FullEpisodeCtaOverlay from './FullEpisodeCtaOverlay';
import {
  CustomizeStepCtaSubmitConfig,
  CustomizeStepSubmitType,
  RenderCustomizeCtaProps,
} from './types';
import { block } from './utils';

export interface CustomizeStepCtaProps
  extends Omit<RenderCustomizeCtaProps, 'className' | 'onClick'> {
  className?: string;
  defaultProjectName?: string;
  disableSubmitOptions?: boolean;
  durationMillis?: number;
  projectType?: 'audiogram' | 'fullEpisode';
  onClick?: (
    opts: CustomizeStepCtaSubmitConfig,
    state: TemplateEditorStateExport,
    frameSizeOverride?: FrameSize,
  ) => void;
  transcriptionEnabled: boolean;
  audioSourceType?: AudioSourceType;
  language?: string;
  transcriptWithNoClipping?: boolean;
  isTranscriptionEnabled?: boolean;
}

const MAX_LENGTH = 255;

const normalizeDefaultProjectName = (value: string = '') => {
  return value.trim().slice(0, MAX_LENGTH);
};

const CustomizeStepCta: React.FC<CustomizeStepCtaProps> = ({
  className,
  defaultProjectName = '',
  disabled,
  disableSubmitOptions,
  durationMillis,
  exportEditorState,
  projectType = 'audiogram',
  onClick,
  state,
  transcriptionEnabled,
  audioSourceType,
  language,
  transcriptWithNoClipping,
  isTranscriptionEnabled,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const isLongFormProject = durationMillis > AUDIOGRAM_CUTOFF_MILLIS;
  const [showOverlay, setShowOverlay] = useState(false);
  const [projectName, setProjectName] = useState(() =>
    normalizeDefaultProjectName(defaultProjectName),
  );

  const config = useMemo(
    () => ({
      projectName,
      transcriptionEnabled,
    }),
    [projectName, transcriptionEnabled],
  );

  const { handleCheckIsValidTemplate } = useTemplateValidationCheck();

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!handleCheckIsValidTemplate(state)) {
        return;
      }

      if (disableSubmitOptions) {
        onClick(
          {
            projectName,
            transcriptionEnabled,
          },
          exportEditorState(),
        );
      } else {
        setShowOverlay(true);
      }
    },
    [
      disableSubmitOptions,
      exportEditorState,
      handleCheckIsValidTemplate,
      onClick,
      projectName,
      state,
      transcriptionEnabled,
    ],
  );

  const handleCancelClick = useCallback(() => {
    setShowOverlay(false);
  }, []);

  const handleCtaClick = useCallback(
    async (type: CustomizeStepSubmitType) => {
      if (type === 'edit' || !transcriptionEnabled || isLongFormProject) {
        onClick(
          {
            type,
            projectName,
            transcriptionEnabled,
          },
          exportEditorState(),
        );
      } else {
        const transcribe = await dispatch(
          pushModal({ name: 'SkipEditorCaptions' }),
        );

        onClick(
          {
            type,
            projectName,
            transcriptionEnabled: transcribe,
          },
          exportEditorState(),
        );
      }
    },
    [
      dispatch,
      exportEditorState,
      isLongFormProject,
      onClick,
      projectName,
      transcriptionEnabled,
    ],
  );

  const handleFullEpisodeCtaClick = useCallback(
    (val?: FrameSize) => {
      onClick(config, exportEditorState(), val);
    },
    [config, exportEditorState, onClick],
  );

  const ctaOverlay =
    projectType === 'fullEpisode' ? (
      <FullEpisodeCtaOverlay
        onCancelClick={handleCancelClick}
        show={showOverlay}
        onSubmitClick={handleFullEpisodeCtaClick}
        {...{ audioSourceType, language }}
      />
    ) : (
      <AudiogramCtaOverlay
        onCancelClick={handleCancelClick}
        onSubmitClick={handleCtaClick}
        isComplex={state.isComplex}
        isTranscriptionEnabled={transcriptionEnabled}
        show={showOverlay}
        {...{
          disabled,
          isLongFormProject,
          audioSourceType,
          language,
          transcriptWithNoClipping,
          isTranscriptionEnabled,
        }}
      />
    );
  return (
    <div className={cn(block('cta'), className)}>
      <form className={block('form')} onSubmit={handleSubmit}>
        <MaxLengthTextareaField
          maxChars={MAX_LENGTH}
          onChange={setProjectName}
          placeholder="Title your project (optional)"
          density="comfortable"
          startAdornment={
            <InputAdornment>
              <VideoCamera width={16} />
            </InputAdornment>
          }
          value={projectName}
          type="input"
        />
        <Button
          className={block('submit-button')}
          disabled={disabled}
          theme="submit"
          type="submit"
        >
          create
        </Button>
      </form>
      {!disableSubmitOptions && ctaOverlay}
    </div>
  );
};

export default CustomizeStepCta;

import fileExtension from 'file-extension';
import md5 from 'js-md5';
import request from 'superagent';
import { isString } from 'underscore';

export function isFileLike(obj: unknown): obj is Blob {
  return obj instanceof File || obj instanceof Blob;
}

export function hasValidType(file: File, accept: string) {
  for (const t of accept.split(',')) {
    const type = t.trim();
    if (type.startsWith('.')) {
      if (file.name.toLowerCase().endsWith(type.toLowerCase())) {
        return true;
      }
    }
    if (new RegExp(type.replace(/\*/g, '.*'), 'ig').test(file.type)) {
      return true;
    }
  }

  return false;
}

export function getFileExtension(file: File) {
  if (file.name) {
    return fileExtension(file.name);
  }

  return '';
}

export function getFileNameWithtoutExtension(file: File | string) {
  if (isString(file)) {
    return '';
  }

  if (isString(file?.name)) {
    return file.name.substr(0, file.name.lastIndexOf('.')) || file.name;
  }

  return '';
}

export function downloadFile(url: string, name: string) {
  return request
    .get(url)
    .responseType('blob')
    .then(({ body, type }) => new File([body], name, { type }));
}

export function getFileSize(
  source: File | string,
  mode: RequestMode = 'no-cors',
) {
  if (!source) return Promise.resolve(0);

  if (source instanceof File) {
    return Promise.resolve(source.size);
  }

  // wrap fetch in bluebird promise
  const result = fetch(source, {
    method: 'HEAD',
    mode,
  }).then(res => Number(res.headers.get('content-length')));
  return Promise.resolve(result);
}

function readFileInChunks(
  file: Blob,
  onChunkRead: (chunk: ArrayBuffer) => void,
): Promise<void> {
  // Define chunk size (2MB) to process the file in smaller pieces.
  const chunkSize = 2 * 1024 * 1024;
  // Calculate the total number of chunks.
  const totalChunks = Math.ceil(file.size / chunkSize);

  return new Promise((resolve, reject) => {
    // Track the current chunk being processed.
    let currentChunk = 0;
    // Create a FileReader to read file chunks.
    const reader = new FileReader();

    function loadNextChunk() {
      // Calculate the starting byte of the current chunk.
      const start = currentChunk * chunkSize;
      // Calculate the ending byte (or end of file).
      const end = Math.min(start + chunkSize, file.size);

      // Read the sliced chunk as an ArrayBuffer.
      reader.readAsArrayBuffer(file.slice(start, end));
    }

    reader.onload = e => {
      if (e.target?.result) {
        onChunkRead(e.target.result as ArrayBuffer);

        // Move to the next chunk.
        currentChunk += 1;

        if (currentChunk < totalChunks) {
          // If there are more chunks, load the next chunk.
          loadNextChunk();
        } else {
          // If all chunks are processed, finalize.
          resolve();
        }
      }
    };

    reader.onerror = () => reject(new Error('Error reading file'));

    // Start processing the first chunk.
    loadNextChunk();
  });
}

export async function md5File(file: Blob): Promise<string> {
  // Create an instance of the MD5 hasher for incremental hashing.
  const md5Hasher = md5.create();

  // Wait for the file chunks to be read and processed.
  await readFileInChunks(file, chunk => {
    // Add the chunk data to the MD5 hasher.
    md5Hasher.update(chunk);
  });

  // Return the computed hash as a hexadecimal string.
  return md5Hasher.hex();
}

export function megabytesToBytes(
  valueInMegaBytes?: number,
): number | undefined {
  if (!valueInMegaBytes) return undefined;

  return valueInMegaBytes * 1000000;
}

export function isBlobUrl(url: string): boolean {
  return url.startsWith('blob:');
}

export default { isFileLike, hasValidType, getFileExtension };

import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import { SPAREMIN_SERVICES_CREATION_URL } from 'config';
import { attachFields } from 'utils/request';
import { createRequest } from '../utils';
import {
  ACTION_KEY,
  CreateCreationClipSuggestionArgs,
  CreateCreationClipSuggestionFeedbackArgs,
  CreateCreationClipSuggestionResult,
  CreateSocialPostCaptionsArgs,
  CreateSocialPostCaptionsResult,
  CreationArgs,
  CreationResult,
  EnableCreationClipSuggestionArgs,
  EnableCreationClipSuggestionResult,
  GetCreationArgs,
  GetCreationClipSuggestionArgs,
  GetCreationClipSuggestionResult,
  GetCreationClipSuggestionsArgs,
  GetCreationClipSuggestionsProgressResult,
  GetCreationClipSuggestionsResult,
  GetSocialPostCaptionsArgs,
  GetSocialPostCaptionsJobArgs,
  GetSocialPostCaptionsResult,
  ReceiveCreationSuggestionEventArgs,
  ReceiveCreationSuggestionEventResult,
  ReprocessCreationSuggestionArgs,
  ServiceMethod,
} from './types';

const BASE_PATH = '/api/v1/creation';
const CREATION_REQUEST_PATH = `${BASE_PATH}/creation-request`;

const creationSchema = new schema.Entity('creations');

const creationClipSuggestionsSchema = new schema.Entity(
  'creationClipSuggestions',
  {},
  {
    idAttribute: () => 'clipGroup',
  },
);

const videoSocialPostCaptionsSchema = new schema.Entity(
  'videoSocialPostCaptions',
  {},
  {
    idAttribute: 'jobId',
  },
);

const creationSuggestionsProgressSchema = new schema.Entity(
  'creationSuggestionsProgress',
  {},
  {
    idAttribute: () => 'progress',
    processStrategy: entity => ({
      progress: entity.progress,
      status: entity.status,
    }),
  },
);

async function create(
  request: SuperAgentStatic,
  [creationReq]: CreationArgs,
): Promise<CreationResult> {
  const req = request.post(CREATION_REQUEST_PATH);
  attachFields(req, creationReq);

  const { body } = await req;
  return normalize(body, creationSchema);
}

async function getCreation(
  request: SuperAgentStatic,
  [creationId]: GetCreationArgs,
): Promise<CreationResult> {
  const req = request.get(`${CREATION_REQUEST_PATH}/${creationId}`);
  const { body } = await req;
  return normalize(body, creationSchema);
}

async function enableCreationClipSuggestion(
  request: SuperAgentStatic,
  [creationRequestId, suggestionId]: EnableCreationClipSuggestionArgs,
): Promise<EnableCreationClipSuggestionResult> {
  const res = await request.post(
    `${CREATION_REQUEST_PATH}/${creationRequestId}/suggestion/${suggestionId}/enable`,
  );

  return res.body;
}

async function receiveCreationSuggestionEvent(
  request: SuperAgentStatic,
  {
    creationRequestId,
    suggestionId,
    ...params
  }: ReceiveCreationSuggestionEventArgs,
): Promise<ReceiveCreationSuggestionEventResult> {
  await request
    .post(
      `${CREATION_REQUEST_PATH}/${creationRequestId}/suggestion/${suggestionId}/event/receive`,
    )
    .send(params);

  return null;
}

async function createCreationClipSuggestionFeedback(
  request: SuperAgentStatic,
  [
    feedbackType,
    creationRequestId,
    suggestionId,
  ]: CreateCreationClipSuggestionFeedbackArgs,
) {
  await request
    .post(
      `${CREATION_REQUEST_PATH}/${creationRequestId}/suggestion/${suggestionId}/feedback`,
    )
    .send({
      feedbackType,
      creationRequestId,
      suggestionId,
    });
}

async function getCreationClipSuggestions(
  request: SuperAgentStatic,
  [creationRequestId]: GetCreationClipSuggestionsArgs,
): Promise<GetCreationClipSuggestionsResult> {
  const res = await request.get(
    `${CREATION_REQUEST_PATH}/${creationRequestId}/suggestion`,
  );

  return normalize(res.body, creationClipSuggestionsSchema);
}

const getCreationClipSuggestion = (
  request: SuperAgentStatic,
  args: GetCreationClipSuggestionArgs,
): Promise<GetCreationClipSuggestionResult> => {
  const [creationRequestId, suggestionId] = args;

  return request
    .get(
      `${CREATION_REQUEST_PATH}/${creationRequestId}/suggestion/${suggestionId}`,
    )
    .then(res => res.body);
};

const getCreationSuggestionsProgress = async (
  request: SuperAgentStatic,
  args: GetCreationClipSuggestionArgs,
): Promise<GetCreationClipSuggestionsProgressResult> => {
  const [creationRequestId] = args;

  const res = await request.get(
    `${CREATION_REQUEST_PATH}/${creationRequestId}/suggestion-progress`,
  );

  return normalize(res, [creationSuggestionsProgressSchema]);
};

async function createCreationClipSuggestion(
  request: SuperAgentStatic,
  [
    creationRequestId,
    replaceSuggestionId,
    newTrimStartMillis,
    newTrimEndMillis,
  ]: CreateCreationClipSuggestionArgs,
): Promise<CreateCreationClipSuggestionResult> {
  const res = await request
    .post(`${CREATION_REQUEST_PATH}/${creationRequestId}/suggestion`)
    .send({ replaceSuggestionId, newTrimStartMillis, newTrimEndMillis });

  return res.body;
}

async function reprocessCreationClipSuggestion(
  request: SuperAgentStatic,
  {
    creationRequestId,
    suggestionId,
    ...params
  }: ReprocessCreationSuggestionArgs,
) {
  await request
    .post(
      `${CREATION_REQUEST_PATH}/${creationRequestId}/suggestion/${suggestionId}/reprocess`,
    )
    .send(params);

  return undefined;
}

const createSocialPostCaptions = (
  request: SuperAgentStatic,
  args: CreateSocialPostCaptionsArgs,
): Promise<CreateSocialPostCaptionsResult> => {
  const [creationRequestId] = args;

  return request
    .post(
      `${CREATION_REQUEST_PATH}/${creationRequestId}/social-post-caption-job`,
    )
    .then(res => res.body);
};

async function getSocialPostCaptions(
  request: SuperAgentStatic,
  [creationRequestId]: GetSocialPostCaptionsArgs,
): Promise<GetSocialPostCaptionsResult> {
  const res = await request.get(
    `${CREATION_REQUEST_PATH}/${creationRequestId}/social-post-caption-job`,
  );

  return normalize(res.body, videoSocialPostCaptionsSchema);
}
const getSocialPostCaptionsJob = (
  request: SuperAgentStatic,
  args: GetSocialPostCaptionsJobArgs,
): Promise<GetSocialPostCaptionsResult> => {
  const [creationRequestId, jobId] = args;

  return request
    .get(
      `${CREATION_REQUEST_PATH}/${creationRequestId}/social-post-caption-job/${jobId}`,
    )
    .then(res => normalize(res.body, videoSocialPostCaptionsSchema));
};
export const handle = (method: any, args: any, token?: string): any => {
  const request = createRequest({
    token,
    baseUrl: SPAREMIN_SERVICES_CREATION_URL,
  });

  switch (method) {
    case ServiceMethod.CREATE:
      return create(request, args);

    case ServiceMethod.CREATION_GET:
      return getCreation(request, args);

    case ServiceMethod.GET_CREATION_CLIP_SUGGESTIONS:
      return getCreationClipSuggestions(request, args);

    case ServiceMethod.GET_CREATION_CLIP_SUGGESTION:
      return getCreationClipSuggestion(request, args);

    case ServiceMethod.GET_CREATION_CLIP_SUGGESTIONS_PROGRESS:
      return getCreationSuggestionsProgress(request, args);

    case ServiceMethod.ENABLE_CREATION_CLIP_SUGGESTION:
      return enableCreationClipSuggestion(request, args);

    case ServiceMethod.RECEIVE_CREATION_SUGGESTION_EVENT:
      return receiveCreationSuggestionEvent(request, args);

    case ServiceMethod.CREATE_CREATION_CLIP_SUGGESTION_FEEDBACK:
      return createCreationClipSuggestionFeedback(request, args);

    case ServiceMethod.CREATE_CREATION_CLIP_SUGGESTION:
      return createCreationClipSuggestion(request, args);

    case ServiceMethod.REPROCESS_CREATION_SUGGESTION:
      return reprocessCreationClipSuggestion(request, args);

    case ServiceMethod.CREATE_SOCIAL_POST_CAPTIONS:
      return createSocialPostCaptions(request, args);

    case ServiceMethod.GET_SOCIAL_POST_CAPTIONS:
      return getSocialPostCaptions(request, args);

    case ServiceMethod.GET_SOCIAL_POST_CAPTIONS_JOB:
      return getSocialPostCaptionsJob(request, args);

    default:
      throw new Error(`${ACTION_KEY} cannot handle method ${method}`);
  }
};

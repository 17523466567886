import * as React from 'react';
import AutomationCMS from 'blocks/AutomationCMS/containers/AutomationCMS';

export interface AutomatinCMSTabProps {}

const AutomatinCMSTab: React.FC<AutomatinCMSTabProps> = () => {
  return <AutomationCMS />;
};

export default AutomatinCMSTab;

import ids from 'short-id';
import {
  PersistentTextEditorState,
  TextOverlayV2,
} from 'blocks/TextOverlayModal/v2';
import { getDefaultActiveModal } from 'containers/EditTextModal';
import { pushModal } from 'redux/modules/modal';
import { subtierSelector } from 'redux/modules/pricing';
import { ThunkAction } from 'redux/types';
import { asArray, getValue } from 'utils/collections';
import { applyGlobalOverlayToOverlays } from 'utils/embed/text-overlay-ts';
import { isEnterpriseSubTier } from 'utils/pricing';

import * as types from '../action-types';
import { selectAddingToTrack, selectedOverlaySelector } from '../selectors';
import {
  createEmbedConfigurationFromState,
  saveConfiguration,
  setEmbedDuration,
} from './embed';
import { getOverlaysByTrackId, updateTextOverlays } from './text-overlay';
import { addToTrack } from './tracks';

/**
 * Action dispatcher for updating the global text style for a track using the v2
 * modal submission.
 *
 * @pre
 * Only meant to be used for v2 text overlays
 */
export const applyOverlayToTextTrack = (
  trackId: string,
  updatedOverlay: TextOverlayV2,
): ThunkAction<void> => dispatch => {
  const trackOverlays = dispatch(getOverlaysByTrackId(trackId));
  const updatedOverlays = applyGlobalOverlayToOverlays(
    trackOverlays,
    updatedOverlay,
  );

  dispatch(updateTextOverlays(overlays => overlays.merge(updatedOverlays)));
  return dispatch(createEmbedConfigurationFromState());
};

export const pushEditTextModal = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  // Enterprise users should have all the text boxes modals
  // defaulted to the the legacy editor by default.
  const textOverlay = selectedOverlaySelector(getState());
  const userSubtier = subtierSelector(getState());
  const isEnterpriseUser = isEnterpriseSubTier(userSubtier);
  const shouldDefaultToLegacyModal = isEnterpriseUser;

  dispatch(
    pushModal({
      name: 'EditTextModal',
      params: {
        startingModalType: getDefaultActiveModal(
          textOverlay,
          shouldDefaultToLegacyModal,
        ),
      },
    }),
  );
};

export const saveLastTextEditorV2State = (
  editorState: PersistentTextEditorState,
) => dispatch => {
  dispatch({
    type: types.EMBED_LAST_TEXT_EDITOR_V2_STATE_UPDATE,
    payload: {
      lastTextEditorV2State: editorState,
    },
  });
};

export const saveLastGlobalTextEditorV2State = (
  editorState: PersistentTextEditorState,
) => dispatch => {
  dispatch({
    type: types.EMBED_LAST_GLOBAL_TEXT_EDITOR_V2_STATE_UPDATE,
    payload: {
      lastTextEditorV2State: editorState,
    },
  });
};

/**
 * This action dispatcher is duplicated from updateTextOverlay at text-overlay. The reason
 * for duplcating it is that it is not necessary to override the text attribute of v2
 * overlays as it is already handled by the modal.
 *
 * @pre
 * Only meant to be used for v2 text overlays
 */
export const updateTextOverlayV2 = (
  textOverlay: TextOverlayV2,
): ThunkAction<void> => dispatch => {
  const id = getValue(textOverlay, 'id');
  dispatch({
    type: types.EMBED_TEXT_OVERLAY_UPDATE,
    payload: {
      id,
      textOverlay,
    },
  });

  dispatch(setEmbedDuration());
};

/**
 * This action dispatcher is duplicated from saveTextOverlay at text-overlay. The reason
 * for it to be suplicated is that it uses the v2 version of the updateTextOverlay function
 * and it also skips the saving the last used template as it is now a concern of the modal.
 *
 * @pre
 * Only meant to be used for v2 text overlays
 */
export const saveTextOverlayV2 = (
  textOverlay: TextOverlayV2,
): ThunkAction<void> => dispatch => {
  dispatch(updateTextOverlayV2(textOverlay));
  dispatch(saveConfiguration());
};

/**
 * This action dispatcher is duplicated from addToTextOverlays at text-overlay. The reason
 * for it to be duplicated is that there are some differences in the way in which the V2
 * overlays should be pushed to the editor's state:
 * 1. There is no need for extracting the text value from the html string. v2 modal already
 * handles this concern.
 * 2. Last used template is not saved to the state. This functionality was extracted for the
 * v2 modal and the concern of saving the last used state was mainly placed at the text modal
 * as an extension. The state is still saved by the same store slice, but the responsibility
 * of saving and retrieving it was delegated to the modal.
 *
 * @pre
 * Only meant to be used for v2 text overlays
 */
export const addToTextOverlaysV2 = (
  newOverlay: TextOverlayV2,
  trackId?: string,
): ThunkAction<void> => (dispatch, getState) => {
  const newOverlays = asArray(newOverlay).map(overlay =>
    overlay.withMutations(mutableOverlay =>
      mutableOverlay.set('id', ids.generate()),
    ),
  );

  dispatch(
    updateTextOverlays(overlays =>
      newOverlays.reduce(
        (acc, overlay) => acc.set(overlay.get('id'), overlay),
        overlays,
      ),
    ),
  );

  const addToTrackId = trackId || selectAddingToTrack(getState());

  if (addToTrackId) {
    newOverlays.forEach(overlay =>
      dispatch(addToTrack(addToTrackId, overlay.get('id'))),
    );
  }

  return newOverlays;
};

/**
 * This action dispatcher is duplicated from addTextOverlays at text-overlay. It is
 * escentially the same but typed and it used the addToTextOverlaysV2 method for
 * generating the overlays.
 *
 * @pre
 * Only meant to be used for v2 text overlays
 */
export const addTextOverlaysV2 = (
  overlay: TextOverlayV2,
  trackId?: string,
): ThunkAction<void> => dispatch => {
  dispatch({ type: types.EMBED_TEXT_OVERLAY_ADD_REQUEST });

  dispatch(addToTextOverlaysV2(overlay, trackId));

  return dispatch(createEmbedConfigurationFromState())
    .then(() => dispatch({ type: types.EMBED_TEXT_OVERLAY_ADD_SUCCESS }))
    .catch(error => {
      dispatch({ type: types.EMBED_TEXT_OVERLAY_ADD_FAILURE });
      throw error;
    });
};

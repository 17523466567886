import React from 'react';
import { CSSTransition } from 'react-transition-group';
import useEpisodeTranscriptionProIndicator from 'hooks/useEpisodeTranscriptionProIndicator';
import { episodeListBlock as block } from '../../utils';
import EpisodeNewVideoButton from './EpisodeNewVideoButton';
import EpisodeTranscriptionButton from './EpisodeTranscriptionButton';
import useEpisodeListEpisodeRowContext from './useEpisodeListEpisodeRowContext';

export interface EpisodeActionButtonsProps {
  onClick: () => void;
  canTranscribe: boolean;
  enabled: boolean;
}

const EpisodeActionButtons: React.FC<EpisodeActionButtonsProps> = ({
  canTranscribe,
  enabled,
  onClick,
}) => {
  const { episode } = useEpisodeListEpisodeRowContext();
  const { isFree } = useEpisodeTranscriptionProIndicator();

  const transcriptionStatus = episode?.transcriptInfo?.status;

  return (
    <CSSTransition
      in={enabled}
      classNames={block('episode-action-buttons')}
      timeout={{
        enter: 300,
        exit: 600,
      }}
      unmountOnExit
    >
      <div
        className={block('episode-action-buttons', {
          free: isFree,
          completed: !isFree && transcriptionStatus === 'completed',
          transcribe: canTranscribe,
        })}
      >
        <EpisodeTranscriptionButton />
        <EpisodeNewVideoButton
          onClick={e => {
            e.stopPropagation();
            onClick();
          }}
        />
      </div>
    </CSSTransition>
  );
};

export default EpisodeActionButtons;

import * as React from 'react';

import ChildViewCard from '../ChildViewCard';
import useMainMedia from '../useMainMedia';
import { imageViewBlock as block } from '../utils';

export interface DynamicImageCardProps {}

const DynamicImageCard: React.FC<DynamicImageCardProps> = () => {
  const { integration, lastIntegration } = useMainMedia();
  const targetIntegration = integration || lastIntegration;

  if (
    targetIntegration.creationType !== 'dynamic' ||
    targetIntegration.type === 'video'
  )
    return null;

  const { body, footer } = targetIntegration.childViewProps;

  return (
    <ChildViewCard
      className={block('dynamic-image-card')}
      icon={
        <div className={block('dynamic-image-card-icon')}>
          {targetIntegration.childViewProps.icon}
        </div>
      }
      layout="vertical"
    >
      <span className={block('dynamic-image-card-body')}>{body}</span>
      <span className={block('dynamic-image-card-footer')}>{footer}</span>
    </ChildViewCard>
  );
};

export default DynamicImageCard;

import { Button } from '@sparemin/blockhead';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { State } from 'xstate';
import { clamp } from 'utils/numbers';
import { useClipSelectNavigation } from '../../ClipSelectNavigationContext';
import {
  NavigationMachineContextType,
  NavigationMachineEvent,
} from '../../ClipSelectNavigationContext/navigation-machine';
import HeaderItem from '../HeaderItem';
import EditClipViewSelectorOption from './EditClipViewSelectorOption';
import { block } from './utils';

export interface EditClipViewSelectorProps {
  className?: string;
}

const OPTIONS = ['transcript', 'waveform'] as const;

type Option = typeof OPTIONS[number];

function stateValueToOption(
  state: State<NavigationMachineContextType, NavigationMachineEvent>,
): Option | undefined {
  if (state.matches('edit.transcript')) {
    return 'transcript';
  }

  // @ts-ignore
  if (state.matches('edit.waveform')) {
    return 'waveform';
  }

  return undefined;
}

function stateValueToIndex(
  state: State<NavigationMachineContextType, NavigationMachineEvent>,
): number {
  const opt = stateValueToOption(state);
  return !opt ? -1 : OPTIONS.indexOf(opt);
}

const EditClipViewSelector: React.FC<EditClipViewSelectorProps> = ({
  className,
}) => {
  const [state, dispatch] = useClipSelectNavigation();

  // the fsm state will change to an invalid state index right before this
  // component fades out.  keeping a local state value helps prevent the slider
  // from moving to a different option while the view is exiting
  const stateActiveIndex = stateValueToIndex(state);
  const [activeIndex, setActiveIndex] = useState(
    clamp(stateActiveIndex, 0, OPTIONS.length - 1),
  );

  if (
    stateActiveIndex >= 0 &&
    stateActiveIndex <= OPTIONS.length - 1 &&
    activeIndex !== stateActiveIndex
  ) {
    setActiveIndex(stateActiveIndex);
  }

  const handlePress = useCallback(() => {
    dispatch({ type: 'EDIT_VIEW_TOGGLE' });
  }, [dispatch]);

  return (
    <HeaderItem>
      <Button
        className={cn(block(), className)}
        color="tertiary"
        onPress={handlePress}
        variant="ghost"
        style={{ ['--selected-index' as any]: activeIndex }}
      >
        <div className={block('options')}>
          {OPTIONS.map((opt, i) => (
            <EditClipViewSelectorOption active={i === activeIndex} key={opt}>
              {opt}
            </EditClipViewSelectorOption>
          ))}
        </div>
      </Button>
    </HeaderItem>
  );
};

export default EditClipViewSelector;

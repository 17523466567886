import TextOverlayFooterExternalControls from './components/TextOverlayFooterExternalControls';
import { buildUnmountedHtmlString } from './components/TextOverlayTextPreview';
import { AnimationsTabDisplayConfig } from './extensions/AnimationsTab';
import {
  createCaptionsTextOverlay,
  mapTextOverlayToCaptionsOverride,
  validateCaptionsConfigIntegrity,
} from './style-captions-utils';
import { validateCompatibleLegacyConfig } from './style-compatibility-checker';
import { TEXT_OVERLAY_MODAL_VERSION } from './style-export-utils';
import {
  createDynamicBaseOverlay,
  updateOverlayToV2,
  validateOverlayV2Integrity,
} from './style-import-utils';
import {
  convertLegacyOverlay,
  isLegacyOverlayConvertAble,
} from './style-legacy-parser';
import TextOverlayModal, {
  TextOverlayModalProps,
} from './TextOverlayModalContainer';
import {
  CaptionsConfig,
  ITextOverlayV2,
  OverlayScaler,
  PersistentTextEditorState,
  TextBuilderStyles,
  TextEditorConfig,
  TextOverlayV2,
} from './types';

export {
  TEXT_OVERLAY_MODAL_VERSION,
  AnimationsTabDisplayConfig,
  CaptionsConfig,
  ITextOverlayV2,
  OverlayScaler,
  PersistentTextEditorState,
  TextBuilderStyles,
  TextEditorConfig,
  TextOverlayFooterExternalControls,
  TextOverlayModalProps,
  TextOverlayV2,
  buildUnmountedHtmlString,
  convertLegacyOverlay,
  createDynamicBaseOverlay,
  createCaptionsTextOverlay,
  isLegacyOverlayConvertAble,
  mapTextOverlayToCaptionsOverride,
  updateOverlayToV2,
  validateCaptionsConfigIntegrity,
  validateOverlayV2Integrity,
  validateCompatibleLegacyConfig,
};

export default TextOverlayModal;

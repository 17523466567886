import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploadMethod } from 'components/FileUploader/FileUploaderDropzone';
import { MediaImportOption } from 'components/FileUploader/types';
import FontAwesome from 'components/FontAwesome';
import Circular from 'components/icons/circular';
import NonIdealState from 'components/NonIdealState';
import VideoVerification from 'components/VideoVerification';
import { MediaImportedArgs } from 'containers/MediaUploadModal/types';
import useToggle from 'hooks/useToggle';
import { addAudioToTrack } from 'redux/modules/embed/actions/audio';
import { onIncorrectFileType } from 'redux/modules/mixpanel/actions';
import { replaceModal } from 'redux/modules/modal/actions';
import { videoUploadLimitHoursSelector } from 'redux/modules/pricing';
import { Dispatch } from 'redux/types';
import { ApplicationError } from 'utils/ApplicationError';
import { DropZoneType } from 'utils/constants';
import { isSupportedAudioFile, isSupportedVideoFile } from 'utils/formats';
import MediaUploader from './MediaUploader';

interface Props {
  disableAudioUploadSuggestion?: boolean;
  uploadMethod?: FileUploadMethod;
  onFileAccepted: (file: File) => void;
  onFileRejected: (error: ApplicationError, file?: File) => void;
  aspectRatio: number;
  maxFileSizeMb: number;
  maxDurationHour: number;
  onUrlRequested?: (url: string) => void;
  onMediaImported?: (mediaImportedArgs: MediaImportedArgs) => void;
}

function MediaUploadTab(props: Props) {
  const {
    disableAudioUploadSuggestion = false,
    uploadMethod,
    onFileAccepted,
    onFileRejected,
    aspectRatio,
    maxFileSizeMb,
    maxDurationHour,
    onUrlRequested,
    onMediaImported,
  } = props;
  const lastSelectedFile = useRef<File>(null);
  const dispatch = useDispatch<Dispatch>();
  const [overlayVisible, toggleOverlay] = useToggle(false);

  const uploadLimitHours = useSelector(videoUploadLimitHoursSelector);

  const handleFileRejected = (error: ApplicationError, file?: File) => {
    lastSelectedFile.current = file;
    if (!disableAudioUploadSuggestion && isSupportedAudioFile(file)) {
      dispatch(onIncorrectFileType('Audio'));
      toggleOverlay(true);
    } else {
      onFileRejected?.(error, file);
    }
  };
  const handleUploadVideoClick = () => {
    dispatch(addAudioToTrack());
    dispatch(
      replaceModal({
        name: 'AddAudioModal',
        params: {
          file: lastSelectedFile.current,
        },
      }),
    );
  };

  const handleClickMediaImportOption = useCallback(
    async (mediaImportOption: MediaImportOption): Promise<void> => {
      const mediaImportedArgs: MediaImportedArgs = await dispatch(
        replaceModal({
          name: 'MediaUpload',
          params: {
            mediaImportOption,
            dropZoneType: DropZoneType.EDITOR_MEDIA,
          },
        }),
      );

      dispatch(
        replaceModal({
          name: 'AddMediaModal',
        }),
      );

      onMediaImported(mediaImportedArgs);
    },
    [dispatch, onMediaImported],
  );

  return (
    <>
      <VideoVerification
        onFileAccepted={onFileAccepted}
        onFileRejected={handleFileRejected}
        shouldVerify={isSupportedVideoFile}
        maxDurationHours={uploadLimitHours}
      >
        {({ verify }) => (
          <MediaUploader
            {...{ aspectRatio, maxFileSizeMb, maxDurationHour }}
            className="add-media-modal__upload"
            onFileAccepted={verify}
            onFileRejected={handleFileRejected}
            onUrlAccepted={onUrlRequested}
            onClickMediaImportOption={handleClickMediaImportOption}
            uploadMethod={uploadMethod}
          />
        )}
      </VideoVerification>
      <NonIdealState.Overlay visible={overlayVisible}>
        <NonIdealState
          icon={
            <Circular variant="pink">
              <FontAwesome icon="microphone" size="sm" />
            </Circular>
          }
          title="Want to add an audio file?"
          description="You’re actually in the wrong place, but we can easily fix that with the tap of a button:"
        >
          <NonIdealState.Button theme="submit" onClick={handleUploadVideoClick}>
            Upload this audio file
          </NonIdealState.Button>
          <NonIdealState.LinkButton onClick={() => toggleOverlay(false)}>
            ← Return to uploader
          </NonIdealState.LinkButton>
        </NonIdealState>
      </NonIdealState.Overlay>
    </>
  );
}

export default MediaUploadTab;

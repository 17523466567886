import * as Sentry from '@sentry/browser';

import { ApplicationError } from 'utils/ApplicationError';

export const handleAudioAccessErr = (err: unknown): void => {
  Sentry.captureException(err);
  throw new ApplicationError(
    'Error accessing audio, please try again shortly',
    'ER042',
  );
};

import * as mediaUploadService from 'redux/middleware/api/media-upload-service/actions';
import { IVideoUpload } from 'redux/middleware/api/media-upload-service/types';
import * as entitySelectors from 'redux/modules/entities/selectors';
import { GetState, ThunkAction } from 'redux/types';
import { IImmutableMap } from 'types';
import {
  VIDEO_UPLOAD_POLL_INTERVAL_MILLIS,
  VIDEO_UPLOAD_POLL_MAX_ATTEMPTS,
} from 'utils/constants';
import reduxPoll from 'utils/redux-poll';

const UPLOAD_POLL_ID = 'app/common/video-upload-poll';

function getVideo(uploadId: number, getState: GetState) {
  return entitySelectors.videosSelector(getState()).get(uploadId.toString());
}

export const waitForVideoUpload = (
  uploadId: number,
): ThunkAction<Promise<IImmutableMap<IVideoUpload>>> => async (
  dispatch,
  getState,
) => {
  await reduxPoll(
    dispatch,
    () => dispatch(mediaUploadService.getVideo(uploadId)),
    {
      id: UPLOAD_POLL_ID,
      intervalMillis: VIDEO_UPLOAD_POLL_INTERVAL_MILLIS,
      maxAttempts: VIDEO_UPLOAD_POLL_MAX_ATTEMPTS,
      shouldContinue: err => {
        if (err) return false;

        const videoEntity = getVideo(uploadId, getState);
        const videoResolved = videoEntity.get('isResolved');
        const videoStatus = videoEntity.get('status');
        const audioResolved = videoEntity.getIn(['audioExtract', 'isResolved']);
        const audioStatus = videoEntity.getIn(['audioExtract', 'status']);
        const thumbnailResolved = videoEntity.getIn([
          'previewThumbnail',
          'thumbnails',
          0,
          'isResolved',
        ]);
        const thumbnailStatus = videoEntity.getIn([
          'previewThumbnail',
          'thumbnails',
          0,
          'status',
        ]);

        if (
          (videoResolved && videoStatus === 'failed') ||
          (audioResolved && audioStatus === 'failed') ||
          (thumbnailResolved && thumbnailStatus === 'failed')
        ) {
          throw new Error('Failed to upload video');
        }
        return !videoResolved || !audioResolved || !thumbnailResolved;
      },
    },
  );

  return getVideo(uploadId, getState);
};

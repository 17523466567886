import { Spacer, Video } from '@sparemin/blockhead';
import cn from 'classnames';
import React, { useCallback, useRef, useState } from 'react';

import VideoClipper, { VideoClipperInstance } from 'components/VideoClipper';
import VideoPlayer, { VideoScaling } from 'components/VideoPlayer';
import { min } from 'utils/numbers';
import { Region } from '../types';
import { clipSelectVideoClipper as block } from '../utils';
import AddToVideoButton from './AddToVideoButton';
import { useClipSelectAnimation } from './ClipSelectAnimationContext';
import { useClipSelect } from './ClipSelectContext';

interface Props {
  className?: string;
}

const ClipSelectVideoClipper: React.FC<Props> = ({ className }) => {
  const {
    defaultClipperRegion,
    onSelectClip,
    videoPlayerControls,
    clipsPageInfo: { aspectRatioName, mediaDurationMillis, videoUrl },
  } = useClipSelect();

  const playerRef = useRef<VideoPlayer>();

  const [region, setRegion] = useState(defaultClipperRegion);

  const [prevDefaultClipperRegion, setPrevDefaultClipperRegion] = useState<
    Region
  >();

  if (defaultClipperRegion !== prevDefaultClipperRegion) {
    setPrevDefaultClipperRegion(defaultClipperRegion);
    setRegion(defaultClipperRegion);
    playerRef.current?.seek(defaultClipperRegion.startMillis);
  }

  const [durationMillis, setDurationMillis] = useState(mediaDurationMillis);

  const { onClipSelected } = useClipSelectAnimation();

  const clipperRef = React.createRef<VideoClipperInstance>();

  const handleSelectClip = useCallback(async (): Promise<void> => {
    const { startMillis, endMillis } = region;

    if (startMillis !== undefined && endMillis !== undefined) {
      await onSelectClip({ region: { startMillis, endMillis } });

      onClipSelected();
    }
  }, [onClipSelected, onSelectClip, region]);

  const handlePlayerRef = useCallback((player: VideoPlayer) => {
    playerRef.current = player;
  }, []);

  return (
    <div className={cn(block(), className)}>
      <Spacer
        orientation="vertical"
        space="16px"
        align="center"
        justify="center"
        className={block('body')}
      >
        <VideoClipper
          {...videoPlayerControls}
          {...region}
          defaultPositionMillis={region.startMillis}
          aspectRatioName={aspectRatioName}
          durationMillis={durationMillis}
          playerRef={handlePlayerRef}
          playerClassName={cn(block('video-clipper-player'))}
          scaling={VideoScaling.FIT}
          maxDurationMillis={min(
            durationMillis,
            region.startMillis + mediaDurationMillis,
          )}
          wavesurferClassName={block('clipper-wavesurfer')}
          captionsControl={
            <AddToVideoButton
              disabled={region.endMillis - region.startMillis < 1000}
              className={block('submit-button')}
              theme="submit"
              icon={<Video style={{ width: 17, height: 12 }} />}
              onClick={handleSelectClip}
            >
              save clip
            </AddToVideoButton>
          }
          defaultClipperRegion={defaultClipperRegion}
          onSelectedMillisChange={(startMillis, endMillis) => {
            setRegion({ startMillis, endMillis });
          }}
          onDurationChange={setDurationMillis}
          src={videoUrl}
          ref={clipperRef}
        />
      </Spacer>
    </div>
  );
};

export default ClipSelectVideoClipper;

import { useMachine } from '@xstate/react';
import { assign, Machine } from 'xstate';

type NavigationMachineEventType =
  | 'ADJUST_CLICK'
  | 'EDITOR_CLOSE'
  | 'CUSTOM_CLICK'
  | 'DATA_LOAD_SUCCESS'
  | 'DATA_LOAD_FAILURE'
  | 'DISLIKE_CLIP'
  | 'EDIT_CLICK'
  | 'INTRO_EXIT'
  | 'INTRO_EXITED'
  | 'SUBMIT_SUCCESS'
  | 'EDIT_VIEW_TOGGLE';

interface Event<T extends NavigationMachineEventType> {
  payload?: any;
  type: T;
}

interface IntroExitedEvent extends Event<'INTRO_EXITED'> {
  payload: {
    suggestionCount: number;
  };
}

interface DislikeClipEvent extends Event<'DISLIKE_CLIP'> {
  payload: {
    suggestionCount: number;
  };
}

interface DataLoadSuccessEvent extends Event<'DATA_LOAD_SUCCESS'> {
  payload: {
    hasTranscript: boolean;
    suggestionCount: number;
  };
}

export type NavigationMachineEvent =
  | DislikeClipEvent
  | Event<'ADJUST_CLICK'>
  | Event<'EDITOR_CLOSE'>
  | Event<'EDIT_VIEW_TOGGLE'>
  | Event<'CUSTOM_CLICK'>
  | DataLoadSuccessEvent
  | Event<'DATA_LOAD_FAILURE'>
  | Event<'EDIT_CLICK'>
  | Event<'INTRO_EXIT'>
  | Event<'SUBMIT_SUCCESS'>
  | IntroExitedEvent;

export interface NavigationMachineContextType {
  clipAdjusted?: boolean;
  hasTranscript?: boolean;
}

interface NavigationMachineSchema {
  states: {
    loading: {};
    intro: {
      states: {
        exiting: {};
        idle: {};
      };
    };
    select: {};
    edit: {
      states: {
        transcript: {};
        unknown: {};
        waveform: {};
      };
    };
    export: {};
    error: {};
  };
}

const hasNoSuggestions = (
  _: NavigationMachineContextType,
  event: NavigationMachineEvent,
) => {
  if (event.type !== 'INTRO_EXITED' && event.type !== 'DISLIKE_CLIP') {
    return undefined;
  }

  return event.payload.suggestionCount === 0;
};

const hasTranscript = (ctx: NavigationMachineContextType) => {
  return ctx.hasTranscript;
};

const loadData = assign<NavigationMachineContextType, NavigationMachineEvent>({
  hasTranscript: (ctx, event) =>
    event.type !== 'DATA_LOAD_SUCCESS'
      ? ctx.hasTranscript
      : event.payload.hasTranscript,
});

const navigationMachine = Machine<
  NavigationMachineContextType,
  NavigationMachineSchema,
  NavigationMachineEvent
>(
  {
    id: 'nav',
    initial: 'loading',
    context: {
      clipAdjusted: undefined,
      hasTranscript: undefined,
    },
    states: {
      loading: {
        on: {
          DATA_LOAD_FAILURE: 'error',
          DATA_LOAD_SUCCESS: {
            target: 'intro',
            actions: 'loadData',
          },
        },
      },
      intro: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              INTRO_EXIT: 'exiting',
            },
          },
          exiting: {
            on: {
              INTRO_EXITED: [
                { target: '#nav.edit', cond: 'hasNoSuggestions' },
                { target: '#nav.select' },
              ],
            },
          },
        },
      },
      edit: {
        entry: [
          assign({
            clipAdjusted: true,
          }),
        ],
        initial: 'unknown',
        on: {
          EDITOR_CLOSE: 'select',
        },
        states: {
          transcript: {
            on: {
              EDIT_VIEW_TOGGLE: 'waveform',
            },
          },
          unknown: {
            on: {
              '': [
                { target: 'transcript', cond: 'hasTranscript' },
                { target: 'waveform' },
              ],
            },
          },
          waveform: {
            on: {
              EDIT_VIEW_TOGGLE: 'transcript',
              SUBMIT_SUCCESS: '#nav.export',
            },
          },
        },
      },
      select: {
        on: {
          ADJUST_CLICK: {
            target: 'edit',
          },
          CUSTOM_CLICK: {
            target: '#nav.edit',
          },
          DISLIKE_CLIP: {
            actions: assign({
              clipAdjusted: undefined,
            }),
            target: '#nav.edit',
            cond: 'hasNoSuggestions',
          },
          SUBMIT_SUCCESS: {
            target: 'export',
          },
        },
        entry: [
          assign({
            clipAdjusted: false,
          }),
        ],
      },
      error: {
        type: 'final',
      },
      export: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      loadData,
    },
    guards: {
      hasNoSuggestions,
      hasTranscript,
    },
  },
);

export function useNavigation() {
  return useMachine(navigationMachine);
}

export default navigationMachine;

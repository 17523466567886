import React from 'react';
import { ClosedCaptioning } from '..';
import CircularGradient from './CircularGradient';

export interface CircularGradientCaptionsProps {
  className?: string;
}

const CircularGradientCaptions: React.FC<CircularGradientCaptionsProps> = ({
  className,
}) => (
  <CircularGradient className={className}>
    <ClosedCaptioning style={{ height: 'auto', width: 35 }} />
  </CircularGradient>
);

export default CircularGradientCaptions;

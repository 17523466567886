import * as React from 'react';

import { useSocialSection } from 'blocks/DownloadPage/containers/DownloadPageSocialSection/SocialSectionContext';
import LoadingOverlay from 'components/LoadingOverlay';
import { ShareAICaptionsField } from 'components/ShareForm';
import ShareFormGroup from 'components/ShareForm/ShareFormGroup';
import { makeSocialPostLengthCounter } from 'utils/string';
import FacebookPageSelector, {
  FacebookPageSelectorProps,
  Option,
} from './FacebookPageSelector';
import { block } from './utils';

const MAX_CHARS = 600;

export interface FacebookPostComposerProps {
  isFetchingPages?: boolean;
  isPosting?: boolean;
  onPageChange?: FacebookPageSelectorProps['onChange'];
  onSwitchAccountClick?: () => void;
  onTextChange?: (text: string) => void;
  pages: FacebookPageSelectorProps['options'];
  selectedPage: Option;
  text: string;
}

const zeroSocialPostLengthCounter = makeSocialPostLengthCounter(0);

export const FacebookPostComposer: React.FC<FacebookPostComposerProps> = ({
  isPosting,
  onPageChange,
  onSwitchAccountClick,
  onTextChange,
  pages,
  selectedPage,
  text,
}) => {
  const {
    allowAiDisclaimer,
    onUnlockAICaptions,
    toggleModalVisibility,
  } = useSocialSection();

  return (
    <>
      {isPosting && <LoadingOverlay title="Posting Video" />}
      <ShareFormGroup>
        <FacebookPageSelector
          options={pages}
          onChange={onPageChange}
          onSwitchAccountSelect={onSwitchAccountClick}
          value={selectedPage}
        />
      </ShareFormGroup>

      <ShareAICaptionsField
        {...{ allowAiDisclaimer, onUnlockAICaptions }}
        inputProps={{
          controlClassName: block('textarea'),
          onChange: onTextChange,
          value: text,
          maxChars: MAX_CHARS,
          countRemaining: zeroSocialPostLengthCounter,
        }}
        onCancelUnlockAICaptions={() => toggleModalVisibility('facebook')}
      />
    </>
  );
};

FacebookPostComposer.defaultProps = {
  isFetchingPages: false,
  isPosting: false,
};

export default FacebookPostComposer;

import { fromJS } from 'immutable';

import { PersistentTextEditorState, TextOverlayV2 } from '../../types';

/**
 * Applies the last used editor state to a given overlay. The current
 * styles/states to override are:
 * - advancedTextConfigs
 * - advancedAnimation
 * - position
 * - size
 * - editor > textStyle
 */
export const applyEditorStateOverrides = (
  textOverlay: TextOverlayV2,
  editorState?: PersistentTextEditorState,
): TextOverlayV2 => {
  if (!editorState) {
    return textOverlay;
  }

  return textOverlay.withMutations(s =>
    s
      .set('advancedTextConfigs', fromJS(editorState.advancedTextConfigs))
      .set('advancedAnimation', fromJS(editorState.advancedAnimation))
      .set('position', fromJS(editorState.position))
      .set('size', fromJS(editorState.size))
      .setIn(['editor', 'textStyle'], fromJS(editorState.textStyle)),
  );
};

import { useCallback } from 'react';
import {
  useMessageDispatch,
  UseMessageDispatchProps,
} from './useEddyMessaging';

export type UseResetMessagingProps = Pick<UseMessageDispatchProps, 'iframe'>;

export function useResetMessaging({ iframe }: UseResetMessagingProps) {
  const dispatch = useMessageDispatch({ iframe });

  const reset = useCallback(() => {
    dispatch({ type: 'editorReset' });
  }, [dispatch]);

  return { reset };
}

import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'underscore';

import { ClosedCaptioning } from 'components/icons';
import Popover from 'components/Popover';
import ToggleIconButton from 'components/ToggleIconButton';
import Tooltip from 'components/Tooltip';
import { TranscriptionTimeRemaining } from 'containers/FreeTranscriptionBalance';
import useToggle from 'hooks/useToggle';
import useTranscriptionBalance from 'hooks/useTranscriptionBalance';
import { updateMyCaptionsPref } from 'redux/modules/user-pref/actions';
import { TranscriptionTimeBankUpgradeSource } from 'types/common';
import {
  AUDIOGRAM_CUTOFF_DURATION_STRING,
  AUDIOGRAM_CUTOFF_MILLIS,
} from 'utils/constants';
import TranscriptionFormContext, {
  TranscriptionFormValue,
} from './TranscriptionFormContext';
import TranscriptionLanguageSelect from './TranscriptionLanguageSelect';
import TranscriptionToggle from './TranscriptionToggle';
import { block } from './utils';

const { useCallback, Children } = React;

export interface TranscriptionFormProps {
  className?: string;
  disabled?: boolean;
  durationMillis?: number;
  onChange?: (value: TranscriptionFormValue) => void;
  onMouseOut?: (e: React.MouseEvent) => void;
  onMouseOver?: (e: React.MouseEvent) => void;
  value: TranscriptionFormValue;
  source?: TranscriptionTimeBankUpgradeSource;
}

type ITranscriptionForm = React.FC<TranscriptionFormProps> & {
  Toggle: typeof TranscriptionToggle;
  LanguageSelect: typeof TranscriptionLanguageSelect;
};

const TranscriptionForm: ITranscriptionForm = ({
  className,
  disabled = false,
  durationMillis,
  onChange = noop,
  onMouseOut,
  onMouseOver,
  children,
  value,
  source = 'wizard',
}) => {
  const [show, onToggle] = useToggle(false);
  const dispatch = useDispatch();

  const [
    captionsButtonElement,
    setCaptionsButtonElement,
  ] = useState<HTMLButtonElement | null>(null);

  const handleChange = useCallback(
    (values: TranscriptionFormValue) => {
      dispatch(
        updateMyCaptionsPref({
          transcribe: values.transcribe,
          language: values.language,
          forcePrefsLoading: values.transcribe,
        }),
      );

      onChange({
        transcribe: values.transcribe,
        language: values.language,
      });
    },
    [onChange, dispatch],
  );

  const handleTranscribeChange = useCallback(
    (transcribe: boolean) => {
      dispatch(
        updateMyCaptionsPref({
          transcribe,
          language: value.language,
          forcePrefsLoading: true,
        }),
      );
      onChange({ ...value, transcribe });
    },
    [onChange, dispatch, value],
  );

  const transcriptionEnabled = value.transcribe;

  const {
    newBalanceMillis,
    outOfTimeMessage,
    showBalance,
    onClick,
  } = useTranscriptionBalance({
    durationMillis,
    onToggleTranscription: handleTranscribeChange,
    transcriptionEnabled,
    source,
  });

  const isDisabled =
    disabled ||
    durationMillis > AUDIOGRAM_CUTOFF_MILLIS ||
    newBalanceMillis < 0; // TODO canTranscribe?

  useEffect(() => {
    if (transcriptionEnabled && durationMillis > AUDIOGRAM_CUTOFF_MILLIS) {
      handleTranscribeChange(false);
    }
  }, [durationMillis, onChange, transcriptionEnabled, handleTranscribeChange]);

  const content = (
    <TranscriptionFormContext.Provider
      value={{ onChange: handleChange, value, disabled: isDisabled }}
    >
      <div className={cn(block(), className)} data-testid="transcription-form">
        <form
          className={block('form', {
            // TODO: SPAR-12524 - transcriptionBalanceMillis condition hides feature
            // until 12524 is implemented
            // enabled: transcriptionBalanceMillis !== undefined && value.transcribe,
            enabled: showBalance,
            compact: Children.count(children) === 1,
          })}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
        >
          {children}
          {durationMillis > AUDIOGRAM_CUTOFF_MILLIS ||
            (newBalanceMillis < 0 && (
              <Tooltip
                content={
                  durationMillis > AUDIOGRAM_CUTOFF_MILLIS
                    ? `Transcription not available for clips over ${AUDIOGRAM_CUTOFF_DURATION_STRING}`
                    : outOfTimeMessage
                }
                className={block('tooltip')}
                id="transcription-form-tooltip"
                placement="top"
                preventHideOnHover={false}
              >
                <div className={block('tooltip-hover-target')} />
              </Tooltip>
            ))}
          {showBalance && (
            // wrapped in balance-container to add another transition.  FreeTranscriptionBalance
            // has its own transition
            <div className={block('balance-container')}>
              <TranscriptionTimeRemaining
                className={block('balance')}
                onClick={onClick}
                remainingMillis={newBalanceMillis}
              />
            </div>
          )}
        </form>
      </div>
    </TranscriptionFormContext.Provider>
  );

  return (
    <>
      <ToggleIconButton
        onButtonClick={() => onToggle()}
        checked={transcriptionEnabled}
        ref={setCaptionsButtonElement}
      >
        <ClosedCaptioning width={21} style={{ marginRight: 8 }} /> captions{' '}
        {transcriptionEnabled ? 'on' : 'off'}
      </ToggleIconButton>

      <Popover
        isOpen={show}
        isDismissable
        offset={10}
        onClose={() => onToggle(false)}
        shouldCloseOnBlur
        shouldCloseOnInteractOutside={el =>
          !captionsButtonElement.contains(el) ?? true
        }
        triggerElement={captionsButtonElement}
      >
        {content}
      </Popover>
    </>
  );
};

TranscriptionForm.Toggle = TranscriptionToggle;
TranscriptionForm.LanguageSelect = TranscriptionLanguageSelect;

export default TranscriptionForm;

import * as React from 'react';
import { useDispatch } from 'react-redux';

import FontAwesome from 'components/FontAwesome';
import { toggleWorkflow } from 'redux/modules/automation-cms/actions';
import { pushModal } from 'redux/modules/modal';
import AutomationCMSModalContext from '../containers/AutomationCMSRich/AutomationCMSModalContext';
import EpisodeListAutomationsRowStatus from '../containers/EpisodeListAutomationsRowStatus';
import { episodeListBlock as block } from '../utils';
import AutomationWorkflowGrid from './AutomationWorkflowGrid';
import EpisodeListAutomationsRowDescription from './EpisodeListAutomationsRowDescription';
import EpisodeListRow, { EpisodeListRowProps } from './EpisodeListRow';

const { useCallback, useContext } = React;

type PickedRowProps = Pick<EpisodeListRowProps, 'active'>;

export interface EpisodeListAutomationsRowProps extends PickedRowProps {
  workflows: number[];
}

const EpisodeListAutomationsRow: React.FC<EpisodeListAutomationsRowProps> = ({
  active,
  workflows,
}) => {
  const modalDispatch = useContext(AutomationCMSModalContext);
  const dispatch = useDispatch();

  const handleDeleteWorkflow = useCallback(
    (id: number) => {
      modalDispatch({
        payload: { workflowId: id },
        type: 'show-workflow-delete',
      });
    },
    [modalDispatch],
  );

  const handleToggleWorkflow = useCallback(
    (id: number, enabled: boolean, hasAuthError: boolean): void => {
      // This case handles the unpausing for those subscriptions with auth errors (YT permission revoked).
      // On those cases, the modal for authenticating to YT again is shown.
      if (enabled && hasAuthError) {
        dispatch(
          pushModal({
            name: 'YoutubePermissionsRevoked',
            params: { reAuthAutoCreateSubId: id },
          }),
        );
      } else if (!enabled) {
        modalDispatch({
          payload: { workflowId: id },
          type: 'show-workflow-disable',
        });
      } else {
        dispatch(toggleWorkflow(id, enabled));
      }
    },
    [dispatch, modalDispatch],
  );

  return (
    <EpisodeListRow
      active={active}
      className={block('automations-row', {
        active,
        zero: (workflows?.length ?? 0) === 0,
      })}
    >
      <EpisodeListRow.Container
        expanderClassName={block('automations-row-expander')}
        collapsible={!!workflows?.length}
      >
        <EpisodeListRow.Artwork>
          <div className={block('automations-artwork')}>
            <FontAwesome
              className={block('automations-icon')}
              icon="magic"
              style={{ width: 20, height: 'auto' }}
            />
          </div>
        </EpisodeListRow.Artwork>
        <EpisodeListAutomationsRowDescription workflows={workflows} />
        <EpisodeListRow.Status
          className={block('automations-status')}
          innerClassName={block('automations-status-inner')}
        >
          <EpisodeListAutomationsRowStatus workflowCount={workflows?.length} />
        </EpisodeListRow.Status>
      </EpisodeListRow.Container>
      <EpisodeListRow.Contents>
        <AutomationWorkflowGrid
          className={block('workflows')}
          onClickDeleteTemplate={handleDeleteWorkflow}
          onClickToggleTemplate={handleToggleWorkflow}
          workflows={workflows}
        />
      </EpisodeListRow.Contents>
    </EpisodeListRow>
  );
};

export default EpisodeListAutomationsRow;

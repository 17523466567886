import React from 'react';

import TextOverlayModal, {
  TextOverlayModalProps,
} from 'blocks/TextOverlayModal/v2';
import { AnimationsTabDisplayConfig } from 'blocks/TextOverlayModal/v2/extensions/AnimationsTab/types';
import FontAwesome from 'components/FontAwesome';
import { CAPTION_STYLE_FIT_TEXT } from 'utils/constants';

import { block } from './utils';

type PickedTextOverlayModalProps = Pick<
  TextOverlayModalProps,
  | 'background'
  | 'footerLeftActions'
  | 'isCompatibilityMode'
  | 'onSubmit'
  | 'onSwitchToLegacy'
  | 'textOverlay'
>;

export interface EditCaptionsModalProps extends PickedTextOverlayModalProps {
  aspectRatio: number;
  captionsOverlay?: unknown;
  animationsTabEnabled?: boolean;
  animationsTabDisplayConfig?: AnimationsTabDisplayConfig;
  onCancel: () => void;
  onExited?: () => void;
  onTextRangeChange: (range: [number, number]) => void;
  onToggleRechunkRequired: (required: boolean) => void;
  show: boolean;
  showRechunkAlert?: boolean;
}

const EditCaptionsModal: React.FunctionComponent<EditCaptionsModalProps> = props => {
  const {
    aspectRatio,
    background,
    animationsTabEnabled,
    animationsTabDisplayConfig,
    footerLeftActions,
    isCompatibilityMode,
    onCancel,
    onExited,
    onSubmit,
    onSwitchToLegacy,
    onTextRangeChange,
    onToggleRechunkRequired,
    show,
    showRechunkAlert = false,
    textOverlay,
  } = props;

  const DisabledSubmitMessage = (
    <div className={block('disabled-message')}>
      <FontAwesome
        className={block('warning-icon')}
        icon="exclamation-circle"
      />
      Please enlarge the text box or decrease the font size to continue
    </div>
  );

  const RechunkAlertMessage = (
    <div className={block('disabled-message')}>
      <FontAwesome
        className={block('warning-icon')}
        icon="exclamation-circle"
      />
      Saving may change your caption timings
    </div>
  );

  return (
    <TextOverlayModal
      aspectRatio={aspectRatio}
      background={background}
      defaultText={CAPTION_STYLE_FIT_TEXT}
      editable={false}
      extensions={{
        animationsTab: {
          animations: ['none', 'karaoke'],
          enabled: animationsTabEnabled,
          displayConfig: animationsTabDisplayConfig,
        },
        boxSizeFitChecker: {
          enabled: true,
          onTextRangeChange,
          onToggleRechunkRequired,
          rechunkAlert: {
            enabled: showRechunkAlert,
            message: RechunkAlertMessage,
          },
          submitDisabledOnNoFit: {
            enabled: true,
            message: DisabledSubmitMessage,
          },
        },
      }}
      footerLeftActions={footerLeftActions}
      isCompatibilityMode={isCompatibilityMode}
      onExited={onExited}
      onHide={onCancel}
      onSubmit={onSubmit}
      onSwitchToLegacy={onSwitchToLegacy}
      submitButtonText="Save changes"
      shouldDisableSubmitIfEmpty={false}
      show={show}
      showEmoji={false}
      textOverlay={textOverlay}
      title="Captions"
      isCaption
    />
  );
};

export default EditCaptionsModal;

import { CaptionsAnimationConfig } from '@sparemin/text-effects';
import { OnboardingName } from 'blocks/OnboardingAnimations/types';
import {
  AspectRatioName,
  DeepPartial,
  FrameQuality,
  FrameRate,
  FrameSize,
  ISoundwave,
  WatermarkOption,
} from 'types';
import { AdvancedTextConfigs } from 'utils/text-templates/types';
import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'HEADLINER_USER_SERVICE';
export const ACTION_KEY: ActionKey = 'HEADLINER_USER_SERVICE';

export enum ServiceMethod {
  GET_MY_FONTS = 'GET_MY_FONTS',
  GET_GOOGLE_FONTS = 'GET_GOOGLE_FONTS',
  POST_PERSONAL_FONTS = 'POST_PERSONAL_FONTS',
  PODCAST_SUPPORT = 'PODCAST_SUPPORT',
  UPDATE_FONT_NAME = 'UPDATE_FONT_NAME',
  DELETE_FONT = 'DELETE_FONT',
  GET_MY_DISPLAY_PREF = 'GET_MY_DISPLAY_PREF',
  PUT_MY_CAPTIONS_PREF = 'PUT_MY_CAPTIONS_PREF',
  GET_USER_DISPLAY_PREF = 'GET_USER_DISPLAY_PREF',
  GET_SAMPLE_AUDIOS = 'GET_SAMPLE_AUDIOS',
  GET_VIEW_CONFIRMATION = 'GET_VIEW_CONFIRMATION',
  GET_ANNOUNCMENTS = 'GET_ANNOUNCEMENTS',
  GET_ONBOARDING = 'GET_ONBOARDING',
  GET_ONBOARDING_VIEW_CONFIRMATION = 'GET_ONBOARDING_VIEW_CONFIRMATION',
  GET_PROJECT_TEMPLATES = 'GET_PROJECT_TEMPLATES',
  GET_TEXT_PRESETS = 'GET_TEXT_PRESETS',
  CREATE_PROJECT_TEMPLATE = 'CREATE_PROJECT_TEMPLATE',
  OVERWRITE_PROJECT_TEMPLATE = 'OVERWRITE_PROJECT_TEMPLATE',
  DELETE_PROJECT_TEMPLATE = 'DELETE_PROJECT_TEMPLATE',
  GET_PROJECT_TEMPLATE_COMPATIBILITY_FROM_CONFIGURATION_ID = 'GET_PROJECT_TEMPLATE_COMPATIBILITY_FROM_CONFIGURATION_ID',
  GET_USER_TEMPLATE_COMPATIBILITY = 'GET_USER_TEMPLATE_COMPATIBILITY',
  CREATE_QUESTIONNAIRE_RESPONSE = 'CREATE_QUESTIONNAIRE_RESPONSE',
  GET_QUESTIONNAIRE_RESPONSE = 'GET_QUESTIONNAIRE_RESPONSE',
  GET_QUESTIONNAIRE_STATUS = 'GET_QUESTIONNAIRE_STATUS',
  GET_MY_USER_PREF = 'GET_MY_USER_PREF',
  CREATE_WAVEFORM_PREF = 'CREATE_WAVEFORM_PREF',
  DELETE_WAVEFORM_PREF = 'DELETE_WAVEFORM_PREF',
  GET_EXPERIMENT_VARIANT = 'GET_EXPERIMENT_VARIANT',
  UPDATE_COLOR_PREF = 'UPDATE_COLOR_PREF',
  UPDATE_VIDEO_EXPORT_PREF = 'UPDATE_VIDEO_EXPORT_PREF',
  GET_PREF_VIDEOS_SIZES = 'GET_PREF_VIDEOS_SIZES',
  GET_EDDY_SUPPORTED_PROJECT_LANGUAGES = 'GET_EDDY_SUPPORTED_PROJECT_LANGUAGES',
  GET_NOTIFICATION_ALERT = 'GET_NOTIFICATION_ALERT',
  UPDATE_NOTIFICATION_ALERT = 'UPDATE_NOTIFICATION_ALERT',
  GET_AUTOMATION_SUPPORTED_LANGUAGES = 'GET_AUTOMATION_SUPPORTED_LANGUAGES',
  GET_CAPTION_SUPPORTED_LANGUAGES = 'GET_CAPTION_SUPPORTED_LANGUAGES',
}

export enum QuestionId {
  PODCAST = 'podcastYesNo',
  ROLE = 'tellUsWhatYouDo',
  REFERRER = 'referrer',
}

export type QuestionnaireStatus = 'incomplete' | 'completed';

export type UserId = number;

interface QuestionResponse {
  questionIdentifier: QuestionId;
  response: string;
}

export type GetViewConfirmationArgs = [
  number, // announcementId
];

export type GetOnboardingArgs = [
  string, // name of onboarding
];

export type GetOnboardingViewConfirmationArgs = [
  string, // name of onboarding
];

export type CreateQuestionnaireResponseArgs = QuestionResponse[];

export type GetQuestionnaireResponseArgs = undefined;

export type CreateProjectTemplateArgs = [
  string, // configurationId
  string, // displayName
  boolean, // useMockAudioForPreview
];

export type OverwriteProjectTemplateArgs = [
  string, // configurationId
  string, // templateId
  boolean, // useMockAudioForPreview
];

export type DeleteProjectTemplateArgs = [
  string, // templateId
];

export type GetProjectTemplateCompatibilityFromConfigurationIdArgs = [
  string, // configurationId
];

export type GetUserTemplateCompatibilityArgs = [
  string, // templateId
];

export type GetQuestionnaireStatusArgs = undefined;

export type GetUserDisplayPrefArgs = [
  number, // userId
];

export type CreateWaveformPrefArgs = [
  number, // dimensionWidth
  number, // dimensionHeight
  DeepPartial<ISoundwave>,
];

export type DeleteWaveformPrefArgs = [number];

export type GetExperimentVariantArgs = [string[]];

export type UpdateColorPrefArgs = [string[]];

export type PutCaptionsPrefArgs = [boolean, string];

export type PostPersonalFontsArgs = {
  fontFiles?: File[];
  googleFamily?: string;
};

export type SendPodcastSupportArgs = {
  searchTerm?: string;
  requestType?: 'podcastSearchNoResult';
};

export type UpdateFontNameArgs = {
  displayName: string;
  fontId: string;
};

export type DeleteFontArgs = {
  fontId: string;
};

export interface PersonalFont {
  displayName: string;
  familyName: string;
  familyNameWithVariations: string;
  variations: string[];
  url: string;
}

export interface GoogleFont {
  family: string;
  previewCssUrl: string;
}

export interface IAudioGenre {
  id: number;
  name?: string;
}

export interface ISampleAudio {
  id: number;
  audioUrl: string;
  title: string;
  durationMillis: number;
  genre: IAudioGenre;
}

export interface INormalizedPersonalFonts {
  result: string[];
  entities: {
    personalFonts: {
      [id: string]: PersonalFont;
    };
  };
}

export interface UserPrefVideoDimensions {
  height: number;
  width: number;
}

export interface UserPrefVideoSize
  extends Record<AspectRatioName, UserPrefVideoDimensions> {
  id: number;
  name: FrameSize;
}

export type UserPrefVideoSizes = Record<AspectRatioName, UserPrefVideoSize>;

export interface NormalizedVideoSizes {
  result: number[];
  entities: {
    videoSizes: {
      [id: number]: UserPrefVideoSize;
    };
  };
}

export interface BaseLanguageConfig {
  name: string;
  languageCode: string;
  originalPosition?: number;
}

export interface NormalizedEddySupportedProjectLanguage {
  result: number[];
  entities: {
    eddySupportedProjectLanguages: {
      [id: number]: BaseLanguageConfig;
    };
  };
}

export interface NormalizedAutomationSupportedLanguage {
  result: number[];
  entities: {
    automationSupportedLanguages: {
      [id: number]: BaseLanguageConfig;
    };
  };
}

export interface NormalizedCaptionSupportedLanguage {
  result: number[];
  entities: {
    captionSupportedLanguages: {
      [id: number]: BaseLanguageConfig;
    };
  };
}

interface GoogleFonts {
  result: string[];
  entities: {
    googleFonts: {
      [id: string]: GoogleFont;
    };
  };
}

export interface VideoExportPrefsArgs {
  frameRate?: number;
  fullEpisodeFrameRate?: number;
  frameQuality?: number;
  frameSize?: FrameSize;
  fullEpisodeFrameSize?: FrameSize;
}

interface INormalizedSampleAudios {
  result: string[];
  entities: {
    sampleAudios: {
      [id: string]: ISampleAudio;
    };
  };
}

interface IAnnouncmentParts {
  bannerIcon: string;
  bannerText: string;
  titleText: string;
  subtitleText: string;
  mainHtml: string;
}

export interface IAnnouncement {
  id: number;
  announcementParts: IAnnouncmentParts;
  validBetween: {
    startAtMillis: number;
    endAtMillis: number;
  };
  viewConfirmationUrl: string;
}

interface INormalizedAnnoucements {
  entities: {
    announcements: {
      [id: number]: IAnnouncement;
    };
  };
  result: number;
}

export interface IAdjustmentConfig<T extends string, U> {
  valueType: T;
  default?: U;
  hide?: boolean;
  readonly?: boolean;
  overrideOptions?: U[];
  hideOptions?: U[];
  showOptions?: U[];
}

type NumericAdjustmentConfig = IAdjustmentConfig<'integer', number>;
type StringAdjustmentConfig = IAdjustmentConfig<'string', string>;
type BooleanAdjustmentConfig = IAdjustmentConfig<'boolean', boolean>;
type WatermarkOptionsAdjustmentConfig = IAdjustmentConfig<
  'object',
  {
    [key in AspectRatioName]: WatermarkOption[];
  }
>;

export type AudioSelectorMethod = 'upload' | 'podcastSearch';

interface IAudioAdjustment {
  selectorMethods: IAdjustmentConfig<string, AudioSelectorMethod>;
}

interface IWaveformAdjustment {
  position?: StringAdjustmentConfig;
  type?: StringAdjustmentConfig;
  generation?: StringAdjustmentConfig;
}

export interface IMediaSearchAdjustment {
  provider?: {
    gif?: StringAdjustmentConfig;
    image?: StringAdjustmentConfig;
    video?: StringAdjustmentConfig;
  };
}

type AspectRatioAdjustment<T> = { [key in AspectRatioName]?: T };

export type LandscapeVideoExportDisplayPrefKey = '480p' | '720p' | '1080p';

export type PortraitVideoExportDisplayPrefKey =
  | 'Vertical Std'
  | 'Vertical HD'
  | 'Vertical HD2';

export type SquareVideoExportDisplayPrefKey =
  | 'Square Std'
  | 'Square HD'
  | 'Square HD2';

export type VideoExportDisplayPrefKey =
  | LandscapeVideoExportDisplayPrefKey
  | PortraitVideoExportDisplayPrefKey
  | SquareVideoExportDisplayPrefKey;

type IFullEpisodeDisplayPref<
  TExportPrefKey extends VideoExportDisplayPrefKey
> = IAdjustmentConfig<'string', TExportPrefKey>;

export interface IVideoExportAdjustment {
  frameRate?: NumericAdjustmentConfig;
  fullEpisodeSize?: {
    landscape?: IFullEpisodeDisplayPref<LandscapeVideoExportDisplayPrefKey>;
    portrait?: IFullEpisodeDisplayPref<PortraitVideoExportDisplayPrefKey>;
    square?: IFullEpisodeDisplayPref<SquareVideoExportDisplayPrefKey>;
  };
  introVideo?: AspectRatioAdjustment<StringAdjustmentConfig>;
  outroVideo?: AspectRatioAdjustment<StringAdjustmentConfig>;
  quality?: NumericAdjustmentConfig;
  size?: AspectRatioAdjustment<StringAdjustmentConfig>;
}

interface ITransitionAdjustment {
  effect: StringAdjustmentConfig;
}

interface ITextAdjustment {
  inTransition?: ITransitionAdjustment;
  outTransition?: ITransitionAdjustment;
  presetStyle?: StringAdjustmentConfig;
}

interface ITranscriptionAdjustment {
  enabled?: BooleanAdjustmentConfig;
}

interface INavigation {
  headerLogo?: { enabled?: BooleanAdjustmentConfig };
  bar?: { hidden?: BooleanAdjustmentConfig };
}

interface IVideoShareAdjustment {
  shareMethod: StringAdjustmentConfig;
}

export interface IDisplayAdjustment {
  audio?: IAudioAdjustment;
  mediaSearchEngine?: IMediaSearchAdjustment;
  navigation?: INavigation;
  text?: ITextAdjustment;
  transcription?: ITranscriptionAdjustment;
  videoExport?: IVideoExportAdjustment;
  watermark?: StringAdjustmentConfig;
  watermarkOptions?: WatermarkOptionsAdjustmentConfig;
  waveform?: IWaveformAdjustment;
  videoShare?: IVideoShareAdjustment;
}

export interface IDisplayPref {
  displayAdjustment: IDisplayAdjustment;
}

export interface ICaptionsPref {
  captionEnabled: boolean;
  captionLanguage: string;
}

export interface INormalizedDisplayPref {
  entities: {
    displayPref: { [key: string]: IDisplayPref };
  };
  result: UserId;
}

export interface IGetOnboarding {
  id: number;
  name: OnboardingName;
  isViewed: boolean;
  viewConfirmationUrl: string;
}

interface IPreviewVideo {
  videoUrl: string;
  mediaType: string;
}

export interface IProjectTemplate {
  id: string;
  configurationId: string;
  dimension: {
    width: number;
    height: number;
  };
  name: string;
  description: string;
  thumbnailImageUrl: string;
  exportPlatforms: string[];
  previewVideos: IPreviewVideo[];
}

interface INormalizedIProjectTemplates {
  entities: {
    projectTemplates: {
      [id: number]: IProjectTemplate;
    };
  };
  result: number;
}

export interface CompatibilityDetails {
  validVideoLayer: boolean;
  validAnimationSettings: boolean;
  validCaptionsSettings: boolean;
  validAssetStartAndEndTimes: boolean;
}

export interface FullEpisodeCompatibility {
  isCompatible: boolean;
  compatibilityDetails: CompatibilityDetails;
}

export interface UCSEditorCompatibility {
  isCompatible: boolean;
}

export interface TemplateCompatibility {
  response?: {
    fullEpisode: FullEpisodeCompatibility;
  };
}

export interface UserTemplateCompatibility {
  response?: {
    fullEpisode: FullEpisodeCompatibility;
    ucsEditor: UCSEditorCompatibility;
  };
}

export interface ProjectTemplateCompatibilityFromConfigurationIdResponse
  extends GetProjectTemplateCompatibilityFromConfigurationIdAction,
    TemplateCompatibility {}

export interface UserTemplateCompatibilityResponse
  extends GetUserTemplateCompatibilityAction,
    UserTemplateCompatibility {}

export interface WaveformPref {
  id: number;
  dimensionWidth: number;
  dimensionHeight: number;
  waveformConfig: DeepPartial<ISoundwave>;
}

export interface VideoExportAdjustments {
  frameRate: IAdjustmentConfig<'integer', FrameRate>;
  fullEpisodeFrameRate: IAdjustmentConfig<'integer', FrameRate>;
  frameQuality: IAdjustmentConfig<'integer', FrameQuality>;
  frameSize: IAdjustmentConfig<'string', FrameSize>;
  fullEpisodeFrameSize: IAdjustmentConfig<'string', FrameSize>;
}
interface NormalizedUserPref {
  entities: {
    userPrefs: {
      [userId: number]: {
        captionPrefs: {
          captionEnabled: boolean;
          captionLanguage: string;
        };
        colorPrefs: string[];
        waveformPrefs: number[];
        videoExportPrefs: VideoExportAdjustments;
        userId: number;
      };
    };
  };
  result: number;
}

export type ExperimentVariant = {
  experimentId: number;
  experimentName: string;
  variantId: number;
  variantName: string;
  variantWeight: number;
  variantVariables: {};
};

export type GetPersonalFontsResult = INormalizedPersonalFonts;

export type GetGoogleFontsResult = GoogleFonts;

export type PostPersonalFontsResult = void;

export type PodcastSupportResult = void;

export type UpdateFontNameResult = void;

export type DeleteFontResult = void;

export type GetDisplayPrefResult = IDisplayPref;

export type GetPrefVideoSizesResult = NormalizedVideoSizes;

export type GetEddySupportedProjectLanguagesResult = NormalizedEddySupportedProjectLanguage;

export type GetAutomationSupportedLanguagesResult = NormalizedAutomationSupportedLanguage;

export type GetCaptionSupportedLanguagesResult = NormalizedCaptionSupportedLanguage;

export type PutCaptionsPrefResult = ICaptionsPref;

export type UpdateVideoExportPrefResult = void;

export type GetSampleAudiosResult = INormalizedSampleAudios;

export type GetAnnouncementsResult = INormalizedAnnoucements;

export type GetViewConfirmationResult = null;

export type GetOnboardingResult = IGetOnboarding;

export type GetOnboardingViewConfirmationResult = null;

export type GetProjectTemplatesResult = INormalizedIProjectTemplates;

export type CreateProjectTemplateResult = void;

export type OverwriteProjectTemplateResult = void;

export type DeleteProjectTemplateResult = void;

export type GetProjectTemplatecompatibilityFromConfigurationIdResult = TemplateCompatibility;

export type GetUserTemplatecompatibilityResult = UserTemplateCompatibility;

export type CreateQuestionnaireResponseResult = void;

export type GetUserDisplayPrefResult = INormalizedDisplayPref;

export type GetMyUserPrefResult = NormalizedUserPref;

export type CreateWaveformPrefResult = { waveformPrefId: number };

export type DeleteWaveformPrefResult = { waveformPrefId: number };

export type GetExperimentVariantResult = {
  entities: {
    experiments: Record<string, ExperimentVariant>;
  };
  result: string;
};

export interface GetQuestionnaireStatusResponse {
  status: QuestionnaireStatus;
  questionsWithNoResponse: Array<{
    questionIdentifier: QuestionId;
    response: string;
  }>;
}

export interface GetQuestionnaireResponseResult {
  questionResponses: QuestionResponse[];
}

export type UpdateColorPrefResult = void;

export interface TextPreset {
  advancedTextConfigs: AdvancedTextConfigs;
  advancedAnimation: CaptionsAnimationConfig;
  previewImageUrl: string;
  textPresetId: string;
  cssStyles: Object;
}

export interface GetTextPresetsResult {
  entities: {
    textPresets: Record<string, TextPreset>;
  };
  result: string;
}

export interface GetNotificationAlertResult {
  entities: { hasNewSocialShareData: boolean };

  result: boolean;
}

export interface UpdateNotificationAlertArgs {
  hasNewSocialShareData?: boolean;
}

export type UpdateNotificationAlertResult = void;

export interface IHandle {
  (
    method: ServiceMethod.GET_MY_FONTS,
    args: undefined,
    token?: string,
  ): Promise<GetPersonalFontsResult>;
  (
    method: ServiceMethod.GET_GOOGLE_FONTS,
    args: undefined,
    token?: string,
  ): Promise<GetGoogleFontsResult>;
  (
    method: ServiceMethod.POST_PERSONAL_FONTS,
    args: PostPersonalFontsArgs,
    token?: string,
  ): Promise<PostPersonalFontsResult>;
  (
    method: ServiceMethod.PODCAST_SUPPORT,
    args: SendPodcastSupportArgs,
    token?: string,
  ): Promise<PodcastSupportResult>;
  (
    method: ServiceMethod.UPDATE_FONT_NAME,
    args: UpdateFontNameArgs,
    token?: string,
  ): Promise<UpdateFontNameResult>;
  (
    method: ServiceMethod.DELETE_FONT,
    args: DeleteFontArgs,
    token?: string,
  ): Promise<DeleteFontResult>;
  (
    method: ServiceMethod.UPDATE_VIDEO_EXPORT_PREF,
    args: VideoExportPrefsArgs,
    token?: string,
  ): Promise<UpdateColorPrefResult>;
  (
    method: ServiceMethod.GET_MY_DISPLAY_PREF,
    args: undefined,
    token?: string,
  ): Promise<GetDisplayPrefResult>;
  (
    method: ServiceMethod.GET_PREF_VIDEOS_SIZES,
    args: undefined,
    token?: string,
  ): Promise<GetPrefVideoSizesResult>;
  (
    method: ServiceMethod.GET_EDDY_SUPPORTED_PROJECT_LANGUAGES,
    args: undefined,
    token?: string,
  ): Promise<GetEddySupportedProjectLanguagesResult>;
  (
    method: ServiceMethod.GET_USER_DISPLAY_PREF,
    args: GetUserDisplayPrefArgs,
    token?: string,
  ): Promise<GetUserDisplayPrefResult>;
  (
    method: ServiceMethod.GET_SAMPLE_AUDIOS,
    args: undefined,
    token?: string,
  ): Promise<GetSampleAudiosResult>;

  (
    method: ServiceMethod.GET_ANNOUNCMENTS,
    args: undefined,
    token?: string,
  ): Promise<GetAnnouncementsResult>;

  (
    method: ServiceMethod.GET_VIEW_CONFIRMATION,
    args: GetViewConfirmationArgs,
    token?: string,
  ): Promise<GetViewConfirmationResult>;

  (
    method: ServiceMethod.GET_ONBOARDING,
    args: GetOnboardingArgs,
    token?: string,
  ): Promise<GetOnboardingResult>;

  (
    method: ServiceMethod.GET_ONBOARDING_VIEW_CONFIRMATION,
    args: GetOnboardingViewConfirmationArgs,
    token?: string,
  ): Promise<GetOnboardingViewConfirmationResult>;

  (
    method: ServiceMethod.GET_PROJECT_TEMPLATES,
    args: undefined,
    token?: string,
  ): Promise<GetProjectTemplatesResult>;

  (
    method: ServiceMethod.CREATE_PROJECT_TEMPLATE,
    args: CreateProjectTemplateArgs,
    token?: string,
  ): Promise<CreateProjectTemplateResult>;

  (
    method: ServiceMethod.DELETE_PROJECT_TEMPLATE,
    args: DeleteProjectTemplateArgs,
    token?: string,
  ): Promise<DeleteProjectTemplateResult>;

  (
    method: ServiceMethod.CREATE_QUESTIONNAIRE_RESPONSE,
    args: CreateQuestionnaireResponseArgs,
    token?: string,
  ): Promise<CreateQuestionnaireResponseResult>;

  (
    method: ServiceMethod.GET_QUESTIONNAIRE_RESPONSE,
    args: GetQuestionnaireResponseArgs,
    token?: string,
  ): Promise<GetQuestionnaireResponseResult>;

  (
    method: ServiceMethod.GET_QUESTIONNAIRE_STATUS,
    args: GetQuestionnaireStatusArgs,
    token?: string,
  ): Promise<GetQuestionnaireStatusResponse>;

  (
    method: ServiceMethod.GET_MY_USER_PREF,
    args: undefined,
    token?: string,
  ): Promise<GetMyUserPrefResult>;

  (
    method: ServiceMethod.CREATE_WAVEFORM_PREF,
    args: CreateWaveformPrefArgs,
    token?: string,
  ): Promise<CreateWaveformPrefResult>;

  (
    method: ServiceMethod.DELETE_WAVEFORM_PREF,
    args: DeleteWaveformPrefArgs,
    token?: string,
  ): Promise<DeleteWaveformPrefResult>;

  (
    method: ServiceMethod.GET_EXPERIMENT_VARIANT,
    args: GetExperimentVariantArgs,
    token?: string,
  ): Promise<GetExperimentVariantResult>;

  (
    method: ServiceMethod.UPDATE_COLOR_PREF,
    args: UpdateColorPrefArgs,
    token?: string,
  ): Promise<UpdateColorPrefResult>;
  (
    method: ServiceMethod.GET_NOTIFICATION_ALERT,
    args: undefined,
    token?: string,
  ): Promise<GetNotificationAlertResult>;
  (
    method: ServiceMethod.UPDATE_NOTIFICATION_ALERT,
    args: UpdateNotificationAlertArgs,
    token?: string,
  ): Promise<UpdateNotificationAlertResult>;
  (
    method: ServiceMethod.GET_AUTOMATION_SUPPORTED_LANGUAGES,
    args: undefined,
    token?: string,
  ): Promise<GetAutomationSupportedLanguagesResult>;
  (
    method: ServiceMethod.GET_CAPTION_SUPPORTED_LANGUAGES,
    args: undefined,
    token?: string,
  ): Promise<GetCaptionSupportedLanguagesResult>;
}

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;
export type GetMyFontsAction = ApiAction<ServiceMethod.GET_MY_FONTS, undefined>;

export type GetGoogleFontsAction = ApiAction<
  ServiceMethod.GET_GOOGLE_FONTS,
  undefined
>;

export type PostPersonalFontsAction = ApiAction<
  ServiceMethod.POST_PERSONAL_FONTS,
  PostPersonalFontsArgs
>;

export type PodcastSupportAction = ApiAction<
  ServiceMethod.PODCAST_SUPPORT,
  SendPodcastSupportArgs
>;

export type UpdateFontNameAction = ApiAction<
  ServiceMethod.UPDATE_FONT_NAME,
  UpdateFontNameArgs
>;

export type DeleteFontAction = ApiAction<
  ServiceMethod.DELETE_FONT,
  DeleteFontArgs
>;

export type GetMyDisplayPrefAction = ApiAction<
  ServiceMethod.GET_MY_DISPLAY_PREF,
  undefined
>;
export type GetMyDisplayPrefResult = GetDisplayPrefResult;

export type PutMyCaptionsPrefAction = ApiAction<
  ServiceMethod.PUT_MY_CAPTIONS_PREF,
  PutCaptionsPrefArgs
>;
export type PutMyCaptionsPrefResult = PutCaptionsPrefResult;

export type GetUserDisplayPrefAction = ApiAction<
  ServiceMethod.GET_USER_DISPLAY_PREF,
  GetUserDisplayPrefArgs
>;

export type GetSampleAudiosAction = ApiAction<
  ServiceMethod.GET_SAMPLE_AUDIOS,
  undefined
>;
export type GetAnnouncementsAction = ApiAction<
  ServiceMethod.GET_ANNOUNCMENTS,
  undefined
>;
export type GetViewConfirmationAction = ApiAction<
  ServiceMethod.GET_VIEW_CONFIRMATION,
  GetViewConfirmationArgs
>;

export type GetOnboardingAction = ApiAction<
  ServiceMethod.GET_ONBOARDING,
  GetOnboardingArgs
>;

export type GetOnboardingViewConfirmationAction = ApiAction<
  ServiceMethod.GET_ONBOARDING_VIEW_CONFIRMATION,
  GetOnboardingViewConfirmationArgs
>;

export type GetProjectTemplatesAction = ApiAction<
  ServiceMethod.GET_PROJECT_TEMPLATES,
  undefined
>;

export type CreateProjectTemplateAction = ApiAction<
  ServiceMethod.CREATE_PROJECT_TEMPLATE,
  CreateProjectTemplateArgs
>;

export type OverwriteProjectTemplateAction = ApiAction<
  ServiceMethod.OVERWRITE_PROJECT_TEMPLATE,
  OverwriteProjectTemplateArgs
>;

export type DeleteProjectTemplateAction = ApiAction<
  ServiceMethod.DELETE_PROJECT_TEMPLATE,
  DeleteProjectTemplateArgs
>;

export type GetProjectTemplateCompatibilityFromConfigurationIdAction = ApiAction<
  ServiceMethod.GET_PROJECT_TEMPLATE_COMPATIBILITY_FROM_CONFIGURATION_ID,
  GetProjectTemplateCompatibilityFromConfigurationIdArgs
>;

export type GetUserTemplateCompatibilityAction = ApiAction<
  ServiceMethod.GET_USER_TEMPLATE_COMPATIBILITY,
  GetUserTemplateCompatibilityArgs
>;

export type CreateQuestionnaireResponseAction = ApiAction<
  ServiceMethod.CREATE_QUESTIONNAIRE_RESPONSE,
  CreateQuestionnaireResponseArgs
>;

export type GetQuestionnaireResponseAction = ApiAction<
  ServiceMethod.GET_QUESTIONNAIRE_RESPONSE,
  GetQuestionnaireResponseArgs
>;

export type GetQuestionnaireStatusAction = ApiAction<
  ServiceMethod.GET_QUESTIONNAIRE_STATUS,
  GetQuestionnaireStatusArgs
>;

export type GetMyUserPrefAction = ApiAction<
  ServiceMethod.GET_MY_USER_PREF,
  undefined
>;

export type CreateWaveformPrefAction = ApiAction<
  ServiceMethod.CREATE_WAVEFORM_PREF,
  CreateWaveformPrefArgs
>;

export type DeleteWaveformPrefAction = ApiAction<
  ServiceMethod.DELETE_WAVEFORM_PREF,
  DeleteWaveformPrefArgs
>;

export type GetExperimentVariantAction = ApiAction<
  ServiceMethod.GET_EXPERIMENT_VARIANT,
  GetExperimentVariantArgs
>;

export type UpdateColorPrefAction = ApiAction<
  ServiceMethod.UPDATE_COLOR_PREF,
  UpdateColorPrefArgs
>;

export type UpdateVideoExportPrefAction = ApiAction<
  ServiceMethod.UPDATE_VIDEO_EXPORT_PREF,
  VideoExportPrefsArgs
>;

export type GetPrefVideoSizesAction = ApiAction<
  ServiceMethod.GET_PREF_VIDEOS_SIZES,
  undefined
>;

export type GetEddySupportedProjectLanguagesAction = ApiAction<
  ServiceMethod.GET_EDDY_SUPPORTED_PROJECT_LANGUAGES,
  undefined
>;

export type GetTextPresetsAction = ApiAction<
  ServiceMethod.GET_TEXT_PRESETS,
  GetTextPresetsResult
>;

export type GetNotificationAlertAction = ApiAction<
  ServiceMethod.GET_NOTIFICATION_ALERT,
  undefined
>;

export type UpdateNotificationAlertAction = ApiAction<
  ServiceMethod.UPDATE_NOTIFICATION_ALERT,
  UpdateNotificationAlertArgs
>;

export type GetAutomationSupportedLanguagesAction = ApiAction<
  ServiceMethod.GET_AUTOMATION_SUPPORTED_LANGUAGES,
  undefined
>;

export type GetCaptionSupportedLanguagesAction = ApiAction<
  ServiceMethod.GET_CAPTION_SUPPORTED_LANGUAGES,
  undefined
>;

export interface HeadlinerUserServiceDispatch {
  (action: GetMyFontsAction): Promise<IApiResponse<GetPersonalFontsResult>>;
  (action: GetGoogleFontsAction): Promise<IApiResponse<GetGoogleFontsResult>>;
  (action: GetMyDisplayPrefAction): Promise<IApiResponse<GetDisplayPrefResult>>;
  (action: PutMyCaptionsPrefAction): Promise<
    IApiResponse<PutCaptionsPrefResult>
  >;
  (action: GetUserDisplayPrefAction): Promise<
    IApiResponse<GetUserDisplayPrefResult>
  >;

  (action: GetSampleAudiosAction): Promise<IApiResponse<GetSampleAudiosResult>>;
  (action: GetAnnouncementsAction): Promise<
    IApiResponse<GetAnnouncementsResult>
  >;
  (action: GetViewConfirmationAction): Promise<
    IApiResponse<GetViewConfirmationResult>
  >;
  (action: GetOnboardingAction): Promise<IApiResponse<GetOnboardingResult>>;
  (action: GetOnboardingViewConfirmationAction): Promise<
    IApiResponse<GetOnboardingViewConfirmationResult>
  >;
  (action: GetProjectTemplatesAction): Promise<
    IApiResponse<GetProjectTemplatesResult>
  >;
  (action: CreateProjectTemplateAction): Promise<
    IApiResponse<CreateProjectTemplateResult>
  >;
  (action: OverwriteProjectTemplateAction): Promise<
    IApiResponse<OverwriteProjectTemplateResult>
  >;
  (action: DeleteProjectTemplateAction): Promise<
    IApiResponse<DeleteProjectTemplateResult>
  >;
  (action: GetProjectTemplatesAction): Promise<
    IApiResponse<GetProjectTemplatesResult>
  >;
  (action: CreateQuestionnaireResponseAction): Promise<
    IApiResponse<CreateQuestionnaireResponseResult>
  >;
  (action: GetQuestionnaireResponseAction): Promise<
    IApiResponse<GetQuestionnaireResponseResult>
  >;
  (action: GetQuestionnaireStatusAction): Promise<
    IApiResponse<GetQuestionnaireStatusResponse>
  >;
  (action: GetMyUserPrefAction): Promise<IApiResponse<GetMyUserPrefResult>>;
  (action: CreateWaveformPrefAction): Promise<
    IApiResponse<CreateWaveformPrefResult>
  >;
  (action: DeleteWaveformPrefAction): Promise<
    IApiResponse<DeleteWaveformPrefResult>
  >;
  (action: GetExperimentVariantAction): Promise<
    IApiResponse<GetExperimentVariantResult>
  >;
  (action: UpdateColorPrefAction): Promise<IApiResponse<UpdateColorPrefResult>>;
  (action: UpdateVideoExportPrefAction): Promise<
    IApiResponse<UpdateVideoExportPrefResult>
  >;
  (action: GetPrefVideoSizesAction): Promise<
    IApiResponse<GetPrefVideoSizesResult>
  >;
  (action: GetEddySupportedProjectLanguagesAction): Promise<
    IApiResponse<GetEddySupportedProjectLanguagesResult>
  >;
  (action: GetTextPresetsAction): Promise<IApiResponse<GetTextPresetsResult>>;
  (action: GetNotificationAlertAction): Promise<
    IApiResponse<GetNotificationAlertResult>
  >;
  (action: UpdateNotificationAlertAction): Promise<
    IApiResponse<UpdateNotificationAlertResult>
  >;
  (action: GetAutomationSupportedLanguagesAction): Promise<
    IApiResponse<GetAutomationSupportedLanguagesResult>
  >;
  (action: GetCaptionSupportedLanguagesAction): Promise<
    IApiResponse<GetCaptionSupportedLanguagesResult>
  >;
}

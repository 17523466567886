import * as React from 'react';
import { MediaIntegration } from '../types/integrations';
import DynamicImageChildContents from './DynamicImageChildContents';
import DynamicVideoChildContents from './DynamicVideoChildContents';

export interface DynamicAssetChildContentsProps {
  integration: MediaIntegration;
}

const DynamicAssetChildContents: React.FC<DynamicAssetChildContentsProps> = ({
  integration,
}) => {
  return integration?.type === 'video' ? (
    <DynamicVideoChildContents integration={integration} />
  ) : (
    <DynamicImageChildContents />
  );
};

export default DynamicAssetChildContents;

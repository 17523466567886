import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalFooterButton } from 'components/Modal';
import { Step } from 'components/SteppedModal';
import usePostModalDescription from 'hooks/usePostModalDescription';
import {
  isPollingSocialPostCaptionsSelector,
  videoIdSelector,
} from 'redux/modules/download';
import { onClickInstagramPostModalCTA } from 'redux/modules/mixpanel';
import {
  instagramProviderUserIdSelector,
  postInstagramVideo,
} from 'redux/modules/social';
import { Dispatch } from 'redux/types';
import ComposerStep from '../ComposerStep';
import { DEFAULT_CAPTION } from '../constants';
import { StepProps } from '../types';

export default function useComposerStep({ goToStep }: StepProps): Step {
  const providerUserId = useSelector(instagramProviderUserIdSelector);
  const isPollingSocialPostCaptions = useSelector(
    isPollingSocialPostCaptionsSelector,
  );

  const { description, setDescription } = usePostModalDescription({
    platform: 'instagram',
    defaultDescription: DEFAULT_CAPTION,
  });

  const dispatch = useDispatch<Dispatch>();

  const handlePostVideo = useCallback(() => {
    dispatch((_, getState) => {
      const videoId = videoIdSelector(getState());

      dispatch(onClickInstagramPostModalCTA('PostReel'));

      dispatch(
        postInstagramVideo(videoId, {
          commentEnabled: true,
          description,
          instagramUserId: providerUserId,
          instagramMediaType: 'reels',
          providerUserId,
          scopeContext: 'instagramBusinessManageV2',
        }),
      );

      goToStep('loader');
    });
  }, [description, dispatch, goToStep, providerUserId]);

  const handleGoBack = useCallback((): void => {
    goToStep('select-account');
  }, [goToStep]);

  return {
    component: (
      <ComposerStep
        goBack={handleGoBack}
        onDescriptionChange={setDescription}
        {...{
          description,
        }}
      />
    ),
    id: 'composer',
    fluidFooterButtons: true,
    renderFooterButtons: () => [
      <ModalFooterButton
        theme="submit"
        fluid
        onClick={handlePostVideo}
        disabled={isPollingSocialPostCaptions}
      >
        Post your video
      </ModalFooterButton>,
    ],
  };
}

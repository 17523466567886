import _ from 'underscore';
import { getKeywordsForRecordingVersion } from 'redux/modules/common/actions';
import {
  addChunk,
  createManualTranscript,
  deleteChunk,
  rechunkTranscript,
  shiftChunks,
  updatePhrase,
} from 'redux/modules/embed/actions/transcript';
import {
  recordingsSelector,
  transcriptsSelector,
} from 'redux/modules/entities/selectors';
import { asArray, getValue } from 'utils/collections';
import {
  convertCaptionToOverlay,
  formatCaptionsStyle,
  formatCaptionsStyleFromTemplate,
  getCaptionsStateStyle,
} from 'utils/embed/captions/captions';
import { clamp } from 'utils/numbers';
import * as mixpanelActions from '../../mixpanel';
import {
  EMBED_CAPTIONS_CONFIG_SET,
  EMBED_CAPTIONS_LOCK_SET,
  EMBED_CAPTIONS_MEDIA_SOURCE_SET,
  EMBED_CAPTIONS_OFFSET_SET_FAILURE,
  EMBED_CAPTIONS_OFFSET_SET_REQUEST,
  EMBED_CAPTIONS_OFFSET_SET_SUCCESS,
  EMBED_CAPTIONS_SOURCE_DELETE,
  EMBED_CAPTIONS_STYLE_SET_FAILURE,
  EMBED_CAPTIONS_STYLE_SET_REQUEST,
  EMBED_CAPTIONS_STYLE_SET_SUCCESS,
} from '../action-types';
import {
  aspectRatioNameSelector,
  captionsConfigSelector,
  captionsMediaSourceSelector,
  captionsOffsetMillisSelector,
  captionsStyleSelector,
  embedDurationMillisSelector,
  embedManualTranscriptSelector,
  embedTranscriptSelector,
  textTrackSelector,
  transcriptOffsetMillisSelector,
} from '../selectors';
import { saveConfiguration } from './embed';
import { addTextOverlays, addToTextOverlays } from './text-overlay';
import { createTrack } from './tracks';

export const setCaptionsConfig = config => dispatch =>
  dispatch({
    type: EMBED_CAPTIONS_CONFIG_SET,
    payload: { config },
  });

export const setCaptionsSource = (transcriptId, revisionId, enabled) => (
  dispatch,
  getState,
) => {
  const captions = captionsConfigSelector(getState());
  const updatedCaptions = captions.withMutations(c => {
    c.set('transcriptId', transcriptId);
    c.set(
      'enabled',
      _.isUndefined(enabled) ? captions.get('enabled') : enabled,
    );
    if (revisionId) {
      c.set('transcriptRevisionId', revisionId);
    }
  });
  dispatch(setCaptionsConfig(updatedCaptions));
};

// TODO only delta and merge in reducer?
const saveCaptions = enabled => (dispatch, getState) => {
  const captions = captionsConfigSelector(getState());
  dispatch(setCaptionsConfig(captions.set('enabled', enabled)));
  return dispatch(saveConfiguration());
};

/**
 * when `sync` is true, will save the embed configuration with the udpated revision id
 */
export const updateCaptionsRevision = (sync = true) => (dispatch, getState) => {
  const transcript = embedManualTranscriptSelector(getState());
  const captions = captionsConfigSelector(getState());

  if (
    transcript &&
    transcript.get('revisionId') !== captions.get('transcriptRevisionId')
  ) {
    dispatch(
      setCaptionsSource(transcript.get('id'), transcript.get('revisionId')),
    );

    if (sync) {
      dispatch(saveConfiguration());
    }
  }
};

const resolveTargetCaptionsStyle = ({ forceUpdate, style, templateName }) => (
  dispatch,
  getState,
) => {
  const aspectRatioName = aspectRatioNameSelector(getState());

  // careful not to set targetStyle to the template style if templateName is defined.
  // both templateName and style might be passed, indicating that the rules defined
  // in the style object are derivative of those defined in the template.  templateName
  // is just used to track that the style is associated with a template
  if (_.isObject(style)) {
    return formatCaptionsStyle(style, templateName).get('style');
  }

  if (templateName) {
    return formatCaptionsStyleFromTemplate(templateName, aspectRatioName).get(
      'style',
    );
  }

  if (forceUpdate) {
    const captions = captionsConfigSelector(getState());
    const currentStyle = captions.get('style');

    if (currentStyle) {
      return currentStyle;
    }

    return formatCaptionsStyleFromTemplate('default', aspectRatioName).get(
      'style',
    );
  }

  return undefined;
};

/**
 *
 * @param {Object|string} targetStyle
 */
export const applyCaptionsStyle = config => (dispatch, getState) => {
  const { enabled = true, shouldRechunk = true } = config;

  const targetStyle = dispatch(resolveTargetCaptionsStyle(config));

  if (!targetStyle) {
    return Promise.resolve();
  }

  dispatch({ type: EMBED_CAPTIONS_STYLE_SET_REQUEST });
  const captions = captionsConfigSelector(getState());
  const updatedCaptions = captions.withMutations(c => {
    c.mergeIn(['style'], targetStyle);
    c.set('enabled', enabled);
    return c;
  });

  dispatch(setCaptionsConfig(updatedCaptions));

  const rechunkPromise = shouldRechunk
    ? dispatch(
        rechunkTranscript(
          undefined,
          undefined,
          undefined,
          getCaptionsStateStyle(updatedCaptions),
        ),
      ).then(() => dispatch(updateCaptionsRevision()))
    : Promise.resolve(dispatch(saveConfiguration()));

  return rechunkPromise
    .then(() => dispatch({ type: EMBED_CAPTIONS_STYLE_SET_SUCCESS }))
    .catch(err => {
      dispatch({
        type: EMBED_CAPTIONS_STYLE_SET_FAILURE,
        error: err.message,
      });
      throw err;
    });
};

export const toggleCaptions = (isEnabled, logEvent = true) => (
  dispatch,
  getState,
) => {
  const captions = captionsConfigSelector(getState());

  if (captions.get('enabled') === isEnabled) {
    return Promise.resolve();
  }

  if (logEvent) {
    dispatch(mixpanelActions.onChangeCaptionState(isEnabled));
  }

  if (!isEnabled) {
    dispatch(saveCaptions(false));
    return Promise.resolve();
  }

  // isEnabled is true here. check if we have a style, if not apply a default style
  const currentStyle = captions.get('style');
  if (currentStyle && !currentStyle.isEmpty()) {
    dispatch(saveCaptions(true));
    return Promise.resolve();
  }

  // no style currently set on captions.  use default Headliner style.
  // isEnabled will be true here
  return dispatch(
    applyCaptionsStyle({ enabled: isEnabled, templateName: 'default' }),
  );
};

// TODO use singular - updateCaptionPhrase
export const updateCaptionsPhrase = (phraseId, text) => dispatch =>
  dispatch(updatePhrase(phraseId, { text })).then(() =>
    dispatch(updateCaptionsRevision()),
  );

const getPhrase = (phraseId, state) => {
  const transcript = embedTranscriptSelector(state);
  const phrase = transcript
    .get('transcript')
    .find(chunk => chunk.get('id') === phraseId);
  return phrase;
};

export const updateCaptionStartTime = (phraseId, startMillis) => (
  dispatch,
  getState,
) => {
  const phrase = getPhrase(phraseId, getState());
  const durationMillis = embedDurationMillisSelector(getState());
  const offsetMillis = captionsOffsetMillisSelector(getState());
  const prevStartMillis = phrase.get('startMillis');
  const prevEndMillis = phrase.get('endMillis');
  const length = prevEndMillis - prevStartMillis;
  const clampedStartMillis = clamp(startMillis, offsetMillis, durationMillis);

  let submittedStartMillis;
  let submittedEndMillis;

  // adjust endMillis
  if (clampedStartMillis >= prevEndMillis) {
    const clampedEndMillis = clamp(
      clampedStartMillis + length,
      offsetMillis,
      durationMillis,
    );

    // prevents start millis from becoming the same millis value than the end time
    const adjustedStartMillis =
      clampedEndMillis === durationMillis
        ? clampedEndMillis - 1
        : clampedStartMillis;

    submittedStartMillis = adjustedStartMillis;
    submittedEndMillis = clampedEndMillis;

    dispatch(
      updateCaptionsTime(phraseId, {
        startMillis: adjustedStartMillis,
        endMillis: clampedEndMillis,
      }),
    );
  } else {
    submittedStartMillis = clampedStartMillis;

    dispatch(
      updateCaptionsTime(phraseId, {
        startMillis: clampedStartMillis,
      }),
    );
  }

  return { startMillis: submittedStartMillis, endMillis: submittedEndMillis };
};

export const updateCaptionEndTime = (phraseId, endMillis) => (
  dispatch,
  getState,
) => {
  const phrase = getPhrase(phraseId, getState());
  const durationMillis = embedDurationMillisSelector(getState());
  const offsetMillis = captionsOffsetMillisSelector(getState());
  const prevStartMillis = phrase.get('startMillis');
  const prevEndMillis = phrase.get('endMillis');
  const length = prevEndMillis - prevStartMillis;
  const clampedEndMillis = clamp(endMillis, offsetMillis, durationMillis);

  let submittedStartMillis;
  let submittedEndMillis;

  // adjust startMillis
  if (clampedEndMillis <= prevStartMillis) {
    const clampedStartMillis = clamp(
      clampedEndMillis - length,
      offsetMillis,
      durationMillis,
    );

    // prevents end millis from becoming the same millis value than the start time
    const adjustedEndMillis =
      clampedEndMillis === clampedStartMillis
        ? clampedEndMillis + 1
        : clampedEndMillis;

    submittedStartMillis = clampedStartMillis;
    submittedEndMillis = adjustedEndMillis;

    dispatch(
      updateCaptionsTime(phraseId, {
        startMillis: clampedStartMillis,
        endMillis: adjustedEndMillis,
      }),
    );
  } else {
    submittedEndMillis = clampedEndMillis;
    dispatch(
      updateCaptionsTime(phraseId, {
        endMillis: clampedEndMillis,
      }),
    );
  }

  return { startMillis: submittedStartMillis, endMillis: submittedEndMillis };
};

// TODO use singular - updateCaptionTime
export const updateCaptionsTime = (
  phraseId,
  { startMillis, endMillis },
) => dispatch =>
  dispatch(updatePhrase(phraseId, { startMillis, endMillis })).then(() =>
    dispatch(updateCaptionsRevision()),
  );

export const updateCaptionsOffset = offsetMillis => (dispatch, getState) => {
  const currentOffsetMillis = captionsOffsetMillisSelector(getState());

  if (offsetMillis === currentOffsetMillis) return Promise.resolve();

  dispatch({
    type: EMBED_CAPTIONS_OFFSET_SET_REQUEST,
    payload: { offsetMillis },
  });

  const deltaOffsetMillis = offsetMillis - currentOffsetMillis;

  return dispatch(shiftChunks(deltaOffsetMillis))
    .then(() => {
      dispatch(updateCaptionsRevision());
      dispatch({
        type: EMBED_CAPTIONS_OFFSET_SET_SUCCESS,
        payload: { offsetMillis },
      });
    })
    .catch(() => dispatch({ type: EMBED_CAPTIONS_OFFSET_SET_FAILURE }));
};

export const deletePhrase = phraseId => dispatch =>
  dispatch(deleteChunk(phraseId)).then(() =>
    dispatch(updateCaptionsRevision()),
  );

export const addPhrase = (startMillis, endMillis, text) => dispatch =>
  dispatch(addChunk(startMillis, endMillis, text)).then(() =>
    dispatch(updateCaptionsRevision()),
  );

export const setCaptionsLock = lock => dispatch =>
  dispatch({
    type: EMBED_CAPTIONS_LOCK_SET,
    payload: { lock },
  });

export const convertPhrasesToOverlays = phraseIds => (__, getState) => {
  const transcript = embedTranscriptSelector(getState()).get('transcript');

  const phraseIdList = asArray(phraseIds);
  const phrases = phraseIdList.map(id =>
    transcript.find(chunk => chunk.get('id') === id),
  );

  const transcriptOffsetMillis = transcriptOffsetMillisSelector(getState());
  const captionsStyle = captionsStyleSelector(getState());
  return phrases.map(phrase =>
    convertCaptionToOverlay(phrase, captionsStyle, transcriptOffsetMillis),
  );
};

export const copyPhrasesToTimeline = phraseId => (dispatch, getState) => {
  const overlays = dispatch(convertPhrasesToOverlays(phraseId));
  const destinationTrackId = textTrackSelector(getState(), 1).get('id');
  return dispatch(addTextOverlays(overlays, destinationTrackId));
};

export const copyAllPhrasesToTimeline = () => (dispatch, getState) => {
  const transcript = embedTranscriptSelector(getState())
    .get('transcript')
    .toArray();
  const phraseIds = transcript.map(chunk => chunk.get('id'));
  const overlays = dispatch(convertPhrasesToOverlays(phraseIds));

  const action = dispatch(createTrack('text', { layerOrderType: 'free' }));
  dispatch(addToTextOverlays(overlays, action.payload.id));
  dispatch(saveCaptions(false));
};

export const setCaptionsMediaSource = (assetType, assetId) => dispatch =>
  dispatch({
    type: EMBED_CAPTIONS_MEDIA_SOURCE_SET,
    payload: { assetType, assetId },
  });

/**
 * if passed with mediaSourceId and mediaSourceType, will only delete captions source if the
 * media source matches.
 *
 * if passed with no args, will delete captions source regardless of media source
 */
export const deleteCaptionsSource = (mediaSourceId, mediaSourceType) => (
  dispatch,
  getState,
) => {
  const hasCaptions = !captionsConfigSelector(getState()).isEmpty();
  const mediaSource = captionsMediaSourceSelector(getState());
  const noArgs = !mediaSourceId && !mediaSourceType;
  const matchingMediaSource =
    mediaSource.get('mediaSourceId') === mediaSourceId &&
    mediaSource.get('mediaSourceType') === mediaSourceType;

  if ((noArgs || matchingMediaSource) && hasCaptions) {
    dispatch({ type: EMBED_CAPTIONS_SOURCE_DELETE });
  }
};

const processAutomatedTranscript = (
  mediaSourceId,
  mediaSourceType = 'audio',
) => async (dispatch, getState) => {
  const recordings = recordingsSelector(getState());
  const transcripts = transcriptsSelector(getState());

  const mediaId =
    mediaSourceType === 'audio'
      ? recordings.getIn([mediaSourceId.toString(), 'versionId'])
      : mediaSourceId;

  const transcript = transcripts.get(mediaId.toString());

  if (!transcript || transcript.get('transcript').size <= 0) {
    return transcript?.toJS();
  }

  const { response } = await dispatch(createManualTranscript(transcript));
  return getValue(response, ['entities', 'manualTranscripts', response.result]);
};

/**
 *
 * @param {number} mediaSourceId
 * @param {"audio"|"video"} [mediaSourceType=audio]
 * @param {boolean} [enabled]
 */
export const setupCaptions = (
  mediaSourceId,
  mediaSourceType,
  enabled,
) => async (dispatch, getState) => {
  const transcript = await dispatch(
    processAutomatedTranscript(mediaSourceId, mediaSourceType),
  );

  if (!transcript || transcript.transcript.length <= 0) {
    dispatch(toggleCaptions(false, false));
  }

  const id = getValue(transcript, 'id');
  const chunks = getValue(transcript, 'transcript');
  const revisionId = getValue(transcript, 'revisionId');
  const captionsEnabled = enabled !== undefined ? enabled : chunks.length > 0;

  dispatch(setCaptionsSource(id, revisionId, captionsEnabled));

  if (mediaSourceType === 'audio') {
    dispatch(getKeywordsForRecordingVersion(mediaSourceId, false));
  }

  return dispatch(applyCaptionsStyle({ forceUpdate: true }));
};

export const updateManualTranscriptFromUpload = response => dispatch => {
  const {
    response: { entities, result: transcriptId },
  } = response;
  const { revisionId } = entities.manualTranscripts[transcriptId];

  dispatch(setCaptionsSource(transcriptId, revisionId, true));
  dispatch(applyCaptionsStyle({ forceUpdate: true }));
};

export default {
  setCaptionsConfig,
  setCaptionsLock,
  toggleCaptions,
  updateCaptionsTime,
};

import { useCallback, useMemo, useRef } from 'react';
import useMessageHandler from './useMessageHandler';

export type UseMessageDispatchProps = {
  iframe: HTMLIFrameElement | null;
};

const messageQueue: unknown[] = [];

export default function useMessageDispatch({
  iframe,
}: UseMessageDispatchProps) {
  const isReady = useRef(false);

  const iframeSrc = iframe?.src;
  const iframeWindow = iframe?.contentWindow;

  const origin = useMemo(() => {
    return iframeSrc && new URL(iframeSrc).origin;
  }, [iframeSrc]);

  const dispatch = useCallback(
    (message: unknown) => {
      if (!isReady.current) {
        messageQueue.push(message);
      } else {
        iframeWindow.postMessage(message, origin);
      }
    },
    [iframeWindow, origin],
  );

  const drainMessageQueue = useCallback(() => {
    while (messageQueue.length > 0) {
      const message = messageQueue.shift();
      dispatch(message);
    }
  }, [dispatch]);

  useMessageHandler(
    useCallback(
      (event: MessageEvent) => {
        if (event.data.type === 'applicationReady') {
          isReady.current = true;
          drainMessageQueue();
        }
      },
      [drainMessageQueue],
    ),
  );

  return dispatch;
}

import * as React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import DOCLimitationsAlertModalContents from './DOCLimitationsAlertModalContents';
import { block } from './utils';

export interface DOCLimitationsAlertModalProps {}

const DOCLimitationsAlertModal: React.FC<DOCLimitationsAlertModalProps> = () => {
  return (
    <ConnectedModal
      name="DOCLimitationsAlert"
      title="upload doc file"
      className={block()}
    >
      <DOCLimitationsAlertModalContents />
    </ConnectedModal>
  );
};

export default DOCLimitationsAlertModal;

import {
  Apps,
  Description,
  Spacer,
  useDescriptionContext,
} from '@sparemin/blockhead';
import { createPath } from 'history';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import FontAwesome from 'components/FontAwesome';
import {
  makeProjectSelector,
  makeProjectWidgetUuidSelector,
} from 'redux/modules/entities';
import { getDownloadPath } from 'utils/routes';
import { ProjectTileControl } from '../types';
import { block, makeProjectClipsPathSelector } from '../utils';
import ProjectRowActionButton from './ProjectRowActionButton';
import { useProjectRow } from './ProjectRowContext';

export interface ProjectRowActionsProps {
  onControlClick?: (type: ProjectTileControl, projectId: string) => void;
}

const ProjectRowActions: React.FC<ProjectRowActionsProps> = ({
  onControlClick,
}) => {
  const { isDisabled } = useDescriptionContext();
  const { projectId } = useProjectRow();

  const { projectType } =
    useSelector(useMemo(() => makeProjectSelector(projectId), [projectId])) ??
    {};
  const clipsPath = useSelector(
    useMemo(() => makeProjectClipsPathSelector(projectId), [projectId]),
  );
  const widgetId = useSelector(
    useMemo(() => makeProjectWidgetUuidSelector(projectId), [projectId]),
  );

  return (
    <Description.Header.Actions>
      <Spacer
        orientation="horizontal"
        space="32px"
        align="center"
        justify="center"
        className={block('actions')}
      >
        {projectType !== 'plainProject' && clipsPath ? (
          <ProjectRowActionButton
            icon={<Apps className={block('clips-icon')} />}
            href={createPath(clipsPath)}
          >
            See your clips
          </ProjectRowActionButton>
        ) : (
          <>
            <ProjectRowActionButton
              icon={<FontAwesome icon="trash-alt" />}
              onPress={() => onControlClick('delete', projectId)}
            />

            <ProjectRowActionButton
              icon={<FontAwesome regular icon="copy" />}
              disabled={isDisabled}
              onPress={() => onControlClick('copy', projectId)}
            />

            <ProjectRowActionButton
              icon={<FontAwesome icon="share" />}
              disabled={!widgetId}
              href={widgetId ? getDownloadPath(widgetId) : undefined}
              onPress={() => onControlClick('download', projectId)}
            >
              share
            </ProjectRowActionButton>
          </>
        )}
      </Spacer>
    </Description.Header.Actions>
  );
};

export default ProjectRowActions;

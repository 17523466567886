import { CaptionsRenderer } from '@sparemin/text-effects';
import React, { useCallback } from 'react';
import {
  createCaptionCueObject,
  getDefaultCaptionWordStyling,
} from 'components/VideoTemplateEditor/utils';
import usePrevious from 'hooks/usePrevious';
import { updateRgbaStringAlpha } from 'utils/color';
import { useTextOverlay } from '../state/TextOverlayProvider';

const useTextOverlayCaptionsAnimations = () => {
  const {
    canvasSize,
    draftEditorData,
    editorHtmlRef,
    textValue,
  } = useTextOverlay();

  const captionsRendererRef = React.useRef<CaptionsRenderer | undefined>();

  const advancedAnimation = React.useMemo(
    () => draftEditorData.get('advancedAnimation')?.toJS(),
    [draftEditorData],
  );

  const textStyles = React.useMemo(
    () => draftEditorData.getIn(['editor', 'textStyle'])?.toJS(),
    [draftEditorData],
  );

  const prevTextValue = usePrevious(textValue);
  const prevAdvancedAnimation = usePrevious(advancedAnimation);
  const prevTextStyles = usePrevious(textStyles);
  const prevCanvasSize = usePrevious(canvasSize);

  const updateCaptionsAnimationsConfig = useCallback(() => {
    const { animationName, properties } = advancedAnimation ?? {};

    requestAnimationFrame(() => {
      captionsRendererRef?.current?.updateConfig({
        cues: advancedAnimation?.enabled
          ? [createCaptionCueObject(textValue)]
          : [],
        textStyles,
        animationConfig: {
          ...advancedAnimation,
          properties: {
            ...properties,
            wordActiveColor:
              animationName !== 'activeWordColor'
                ? textStyles?.color
                : properties?.wordEmphasisColor,
            wordFutureColor: updateRgbaStringAlpha(textStyles?.color, 0.5),
            wordPastColor: textStyles?.color,
          },
        },
        ...getDefaultCaptionWordStyling(canvasSize?.width),
      });
    });
  }, [advancedAnimation, canvasSize, textStyles, textValue]);

  React.useEffect(() => {
    const captionsContentElement = editorHtmlRef?.current?.querySelector(
      'span',
    );

    if (
      !captionsContentElement ||
      !advancedAnimation?.enabled ||
      captionsRendererRef?.current
    ) {
      return;
    }

    const { properties } = advancedAnimation;

    captionsRendererRef.current = new CaptionsRenderer(captionsContentElement, {
      cues: [createCaptionCueObject(textValue)],
      textStyles,
      animationConfig: {
        ...advancedAnimation,
        properties: {
          ...properties,
          wordActiveColor: properties?.wordEmphasisColor,
          wordFutureColor: updateRgbaStringAlpha(textStyles?.color, 0.5),
          wordPastColor: textStyles?.color,
        },
      },
      ...getDefaultCaptionWordStyling(canvasSize?.width),
    });

    requestAnimationFrame(() => {
      captionsRendererRef?.current?.getAnimator();
    });
  }, [advancedAnimation, canvasSize, editorHtmlRef, textStyles, textValue]);

  React.useEffect(() => {
    if (
      prevTextValue !== textValue ||
      prevAdvancedAnimation !== advancedAnimation ||
      prevTextStyles !== textStyles ||
      prevCanvasSize !== canvasSize
    ) {
      updateCaptionsAnimationsConfig();
    }
  }, [
    advancedAnimation,
    canvasSize,
    prevAdvancedAnimation,
    prevCanvasSize,
    prevTextStyles,
    prevTextValue,
    textStyles,
    textValue,
    updateCaptionsAnimationsConfig,
  ]);

  return {
    resizeDeltaObserver: updateCaptionsAnimationsConfig,
  };
};

export default useTextOverlayCaptionsAnimations;

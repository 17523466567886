import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateManualTranscriptFromUpload } from 'redux/modules/embed/actions';
import {
  importFreeFormTranscript,
  importManualTranscript,
} from 'redux/modules/embed/actions/transcript';
import { onFileUpload } from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal';
import * as notificationActions from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { ApplicationError } from 'utils/ApplicationError';
import { DropZoneType } from 'utils/constants';
import { getFileExtension } from 'utils/file';

export interface UseUploadeCaptionsModalProps {
  onUploadNewFile: () => void;
  onHide: () => void;
}

export interface UseUploadeCaptionsModal {
  onFileAccepted: (file: File) => void;
  onFileError: (error: ApplicationError, file: File) => void;
}

export default function useUploadCaptionsModal({
  onUploadNewFile,
  onHide,
}: UseUploadeCaptionsModalProps) {
  const dispatch = useDispatch<Dispatch>();
  const uploadCaptions = useCallback(
    (file: File, isFreeForm: boolean): void => {
      dispatch(
        onFileUpload({
          dropZoneType: DropZoneType.EDITOR_CAPTION,
          source: 'localFile',
          file,
        }),
      );

      const action = isFreeForm
        ? importFreeFormTranscript
        : importManualTranscript;

      return dispatch(action(file))
        .then(res => dispatch(updateManualTranscriptFromUpload(res)))
        .catch(() =>
          dispatch(
            notificationActions.showError(
              'Something went wrong, please try again later.',
              5,
            ),
          ),
        );
    },
    [dispatch],
  );

  const handleFileAccepted = useCallback(
    async (file: File): Promise<void> => {
      onHide();

      if (getFileExtension(file) === 'docx') {
        const shouldProceed = await dispatch(
          pushModal({ name: 'DOCLimitationsAlert' }),
        );

        return shouldProceed ? uploadCaptions(file, true) : onUploadNewFile();
      }

      return uploadCaptions(file, false);
    },
    [dispatch, onHide, onUploadNewFile, uploadCaptions],
  );

  const handleFileError = useCallback(
    (error: ApplicationError, file: File): void => {
      dispatch(
        onFileUpload({
          dropZoneType: DropZoneType.EDITOR_CAPTION,
          source: 'localFile',
          file,
          error: error.message,
        }),
      );
      dispatch(
        notificationActions.showError({
          message: error.message,
          code: error.code,
          dismissAfterSec: 5,
        }),
      );
    },
    [dispatch],
  );

  return {
    onFileAccepted: handleFileAccepted,
    onFileError: handleFileError,
  };
}

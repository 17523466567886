import fileExtension from 'file-extension';
import React, { useState } from 'react';
import { CropperModalBody } from 'components/CropperModal';
import { ModalFooterButton } from 'components/Modal';
import SteppedModal from 'components/SteppedModal';

import { Size } from 'types';
import EditVideoModalContents from './EditVideoModalContents';
import useCropVideo from './useCropVideo';
import useEditVideoModal from './useEditVideoModal';

export interface EditVideoModalProps {}

const EditVideoModal: React.FC<EditVideoModalProps> = () => {
  const [dimensions, setDimensions] = useState<Size<number> | undefined>();

  const {
    modalRef,
    show,
    video,
    formRef,
    metadataLoaded,
    onLoadedMetadata,
    onGoToEditPropertiesStep,
    onGoToAdjustCropStep,
    onSave,
    onDelete,
    onReplace,
    onHide,
    onExited,
  } = useEditVideoModal();

  const thumbnailUrl = video?.previewThumbnailUrl;

  const {
    cropperRef,
    cropMetadata,
    videoFileSize,
    scaledCropDimensions,
    onReady,
    onCrop,
  } = useCropVideo({
    show,
    video,
    onCrop: onGoToEditPropertiesStep,
  });

  return (
    <SteppedModal
      title="Video Properties"
      defaultStep="edit-properties"
      baseClassName="edit-video-modal"
      ref={modalRef}
      steps={[
        {
          id: 'edit-properties',
          component: (
            <EditVideoModalContents
              {...{
                formRef,
                video,
                videoFileSize,
                metadataLoaded,
                cropMetadata,
                onLoadedMetadata,
              }}
              onDimensionsChange={setDimensions}
              boundingBox={scaledCropDimensions}
              onAdjustCrop={onGoToAdjustCropStep}
            />
          ),
          renderFooterButtons: [
            <ModalFooterButton onClick={onHide}>cancel</ModalFooterButton>,
            <ModalFooterButton onClick={onReplace}>replace</ModalFooterButton>,
            <ModalFooterButton onClick={onDelete} theme="delete">
              delete
            </ModalFooterButton>,
            <ModalFooterButton
              theme="submit"
              disabled={!metadataLoaded}
              onClick={() =>
                onSave(scaledCropDimensions, videoFileSize, dimensions)
              }
            >
              update Video
            </ModalFooterButton>,
          ],
        },
        {
          id: 'adjust-crop',
          submitButtonLabel: 'Crop',
          component: (
            <CropperModalBody
              ref={cropperRef}
              defaultMetadata={cropMetadata}
              fileType={thumbnailUrl && `image/${fileExtension(thumbnailUrl)}`}
              imgSrc={thumbnailUrl}
              onReady={onReady}
              defaultConstrainImage
              allowConstrainControl={false}
            />
          ),
          renderFooterButtons: [
            <ModalFooterButton onClick={onGoToEditPropertiesStep}>
              back
            </ModalFooterButton>,
            <ModalFooterButton onClick={onHide}>cancel</ModalFooterButton>,
            <ModalFooterButton theme="submit" onClick={onCrop}>
              crop
            </ModalFooterButton>,
          ],
        },
      ]}
      {...{
        show,
        onHide,
        onExited,
      }}
    />
  );
};

export default EditVideoModal;

import lottie, {
  AnimationConfig,
  AnimationConfigWithData,
  AnimationConfigWithPath,
  AnimationItem,
  AnimationSegment,
} from 'lottie-web';
import * as React from 'react';

export interface LottieOptions {
  animationData?: any;
  loop?: boolean;
  autoplay?: boolean;
  rendererSettings?: AnimationConfig['rendererSettings'];
  renderer?: 'svg';
  path?: string;
  assetsPath?: string;
}

export interface LottieEventListener {
  eventName: string;
  callback: () => void;
}

export interface LottieProps {
  options: LottieOptions;
  height?: string | number;
  width?: string | number;
  isStopped?: boolean;
  isPaused?: boolean;
  speed?: number;
  segments?: AnimationSegment | AnimationSegment[];
  forceSegments?: boolean;
  direction?: 1 | -1;
  ariaRole?: string;
  ariaLabel?: string;
  title?: string;
  tabIndex?: number;

  // Not documented
  style?: React.CSSProperties;
  eventListeners?: LottieEventListener[];
}

class Lottie extends React.Component<LottieProps> {
  static defaultProps = {
    eventListeners: [],
    isStopped: false,
    isPaused: false,
    speed: 1,
    ariaRole: 'button',
    ariaLabel: 'animation',
    title: '',
    tabIndex: 0,
  };

  anim: AnimationItem & {
    loop?: boolean | number;
    isPaused?: boolean;
  };

  el = React.createRef<HTMLDivElement>();

  options: AnimationConfigWithPath & AnimationConfigWithData;

  componentDidMount() {
    const { options, eventListeners, isStopped, segments } = this.props;

    const {
      loop,
      autoplay,
      animationData,
      path,
      renderer = 'svg',
      rendererSettings,
      assetsPath,
    } = options;

    this.options = {
      container: this.el.current,
      path,
      renderer,
      loop: loop !== false,
      autoplay: autoplay !== false,
      animationData,
      rendererSettings,
      assetsPath,
    };

    this.options = { ...this.options, ...options };

    this.anim = lottie.loadAnimation(this.options);
    this.setSpeed();
    this.setDirection();
    this.registerEvents(eventListeners);

    if (isStopped) {
      this.stop();
    } else if (segments) {
      this.playSegments(true);
    } else {
      this.play();
    }
  }

  UNSAFE_componentWillUpdate(nextProps: LottieProps) {
    const { eventListeners } = this.props;
    /* Recreate the animation handle if the data is changed */
    if (this.options.animationData !== nextProps.options.animationData) {
      this.deRegisterEvents(eventListeners);
      this.destroy();
      this.options = { ...this.options, ...nextProps.options };
      this.anim = lottie.loadAnimation(this.options);
      this.registerEvents(nextProps.eventListeners);
    }
  }

  componentDidUpdate(prevProps) {
    const { options, segments, forceSegments, isStopped } = this.props;
    if (options.loop !== undefined) {
      this.anim.loop = options.loop;
    }

    if (isStopped) {
      this.stop();
    } else if (segments) {
      if (JSON.stringify(segments) === JSON.stringify(prevProps.segments)) {
        return;
      }
      this.playSegments(forceSegments);
    } else {
      this.play();
    }

    this.pause();
    this.setSpeed();
    this.setDirection();
  }

  componentWillUnmount() {
    const { eventListeners } = this.props;
    this.deRegisterEvents(eventListeners);
    this.destroy();
    this.options.animationData = null;
    this.anim = null;
  }

  setSpeed() {
    const { speed } = this.props;
    this.anim.setSpeed(speed);
  }

  setDirection() {
    const { direction } = this.props;
    this.anim.setDirection(direction);
  }

  play() {
    this.anim.play();
  }

  playSegments(shouldForce) {
    const { segments } = this.props;
    this.anim.playSegments(segments, shouldForce);
  }

  stop() {
    this.anim.stop();
  }

  pause() {
    const { isPaused } = this.props;
    if (isPaused && !this.anim.isPaused) {
      this.anim.pause();
    } else if (!isPaused && this.anim.isPaused) {
      this.anim.pause();
    }
  }

  destroy() {
    this.anim.destroy();
  }

  registerEvents(eventListeners) {
    eventListeners.forEach(eventListener => {
      this.anim.addEventListener(
        eventListener.eventName,
        eventListener.callback,
      );
    });
  }

  deRegisterEvents(eventListeners) {
    eventListeners.forEach(eventListener => {
      this.anim.removeEventListener(
        eventListener.eventName,
        eventListener.callback,
      );
    });
  }

  render() {
    const {
      width,
      height,
      ariaRole,
      ariaLabel,
      title,
      tabIndex,
      style,
    } = this.props;

    const getSize = initial => {
      let size;

      if (typeof initial === 'number') {
        size = `${initial}px`;
      } else {
        size = initial || '100%';
      }

      return size;
    };

    const lottieStyles = {
      width: getSize(width),
      height: getSize(height),
      overflow: 'hidden',
      margin: '0 auto',
      outline: 'none',
      ...style,
    };

    return (
      // Bug with eslint rules https://github.com/airbnb/javascript/issues/1374
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        ref={this.el}
        style={lottieStyles}
        title={title}
        role={ariaRole}
        aria-label={ariaLabel}
        tabIndex={tabIndex}
      />
    );
  }
}

export default Lottie;

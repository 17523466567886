import { createPath } from 'history';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ClipSelectPage, {
  ClipSuggestion,
  OnOpenInAdvancedEditor,
  OnReprocessClip,
  OnSubmit,
} from 'blocks/ClipSelect';

import {
  enableClipSuggestion,
  getGroupClipSuggestions,
} from 'redux/middleware/api/podcast-service/actions';
import {
  createGroupClipSuggestion,
  dislikeGroupSuggestedClip,
  reprocessGroupClipSuggestion,
} from 'redux/modules/clip-select';
import { groupRequestIdSelector } from 'redux/modules/entities';
import { showError } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { ClipSuggestionDislikeReason } from 'types';
import { getDownloadPath, getProjectPath } from 'utils/routes';
import useAutomationSuggestedClipInfo from './useAutomationSuggestedClipInfo';

export interface AutomationSuggestedClipsPageProps {}

type HandleSelectClip = React.ComponentProps<
  typeof ClipSelectPage
>['onSelectClip'];
type HandleDislikeClip = React.ComponentProps<
  typeof ClipSelectPage
>['onDislikeClip'];

const AutomationSuggestedClipsPage: React.FC<AutomationSuggestedClipsPageProps> = () => {
  const groupRequestId = useSelector(groupRequestIdSelector);

  const dispatch = useDispatch<Dispatch>();

  const {
    eddyProjectId,
    episodeId,
    subscriptionItemId,
    suggestedClips,
    dislikedSuggestionIds,
    clipsPageInfo,
    source,
  } = useAutomationSuggestedClipInfo();

  const handleSubmit: OnSubmit = async clipSuggestion => {
    try {
      await dispatch(enableClipSuggestion(clipSuggestion.id));

      window.open(getDownloadPath(clipSuggestion.widgetId), '_blank');
    } catch {
      dispatch(showError('Error sharing clip', 5));
    }
  };

  const handleDislikeClip: HandleDislikeClip = useCallback(
    async (
      reason: ClipSuggestionDislikeReason,
      clipSuggestion: ClipSuggestion,
    ): Promise<void> => {
      await dispatch(
        dislikeGroupSuggestedClip(reason, clipSuggestion.id, clipSuggestion.id),
      );
    },
    [dispatch],
  );

  const handleSelectClip: HandleSelectClip = useCallback(
    async ({ region, activeSuggestionId }): Promise<number | void> => {
      const suggestionId = await dispatch(
        createGroupClipSuggestion(
          Number(episodeId),
          subscriptionItemId,
          groupRequestId,
          activeSuggestionId,
          region.startMillis,
          region.endMillis,
        ),
      );

      return suggestionId;
    },
    [dispatch, episodeId, groupRequestId, subscriptionItemId],
  );

  const handleReprocessClip: OnReprocessClip = useCallback(
    async suggestionId => {
      const clip = suggestedClips.find(c => c.id === suggestionId);

      if (!clip) {
        return null;
      }

      await dispatch(
        reprocessGroupClipSuggestion(
          suggestionId,
          subscriptionItemId,
          groupRequestId,
          clip.startMillis,
          clip.endMillis,
        ),
      );

      return null;
    },
    [dispatch, groupRequestId, subscriptionItemId, suggestedClips],
  );

  const handleOpenInAdvancedEditor: OnOpenInAdvancedEditor = useCallback(
    async ({ id, projectUuid }): Promise<void> => {
      try {
        await dispatch(enableClipSuggestion(id));
        window.open(createPath(getProjectPath(projectUuid)), '_blank');
      } catch {
        dispatch(showError('Error while opening the clip', 5));
      }
    },
    [dispatch],
  );

  const handleUpdateSuggestions = useCallback(() => {
    dispatch(
      getGroupClipSuggestions(
        Number(episodeId),
        subscriptionItemId,
        groupRequestId,
      ),
    );
  }, [dispatch, episodeId, groupRequestId, subscriptionItemId]);

  return (
    <ClipSelectPage
      {...{
        eddyProjectId,
        suggestedClips,
        dislikedSuggestionIds,
        clipsPageInfo,
        source,
      }}
      onDislikeClip={handleDislikeClip}
      onReprocessClip={handleReprocessClip}
      onOpenInAdvancedEditor={handleOpenInAdvancedEditor}
      onSelectClip={handleSelectClip}
      onSubmit={handleSubmit}
      onUpdateSuggestions={handleUpdateSuggestions}
    />
  );
};

export default AutomationSuggestedClipsPage;

import { Preset } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';

import FadingScrollBars from 'components/FadingScrollBars';

import { ImmutableTextPreset } from 'redux/modules/text-presets';
import TextPresetButton from './TextPresetButton';

import useTextPresets from './useTextPresets';
import { block } from './utils';

export interface TextPresetSelectorProps {
  mode?: 'horizontal' | 'vertical';
  onPickPreset: (presetStyles: ImmutableTextPreset) => void;
  selectedPreset?: ImmutableTextPreset;
}

const TextPresetSelector: React.FunctionComponent<TextPresetSelectorProps> = props => {
  const { mode = 'vertical', onPickPreset, selectedPreset } = props;

  const { presetIds } = useTextPresets();

  return (
    <div className={block('container')}>
      <div className={block('header')}>
        <Preset className={block('header-icon')} />
        <h3 className={block('title')}>Presets</h3>
      </div>
      <FadingScrollBars
        className={cn(block('fading-scroller', { [mode]: true }))}
        horizontal={mode === 'horizontal'}
        hideTracksWhenNotNeeded
        sideFaderClassName={cn(block('fader', { [mode]: true }))}
      >
        <div className={block('body', { [mode]: true })}>
          {presetIds.map(presetId => (
            <TextPresetButton
              key={presetId}
              isSelected={selectedPreset?.get('textPresetId') === presetId}
              mode={mode}
              onClick={onPickPreset}
              presetId={presetId}
            />
          ))}
        </div>
      </FadingScrollBars>
    </div>
  );
};

export default TextPresetSelector;

import React from 'react';
import { block } from './utils';

export interface EditClipViewSelectorOptionProps {
  children?: React.ReactNode;
  active?: boolean;
}

const EditClipViewSelectorOption: React.FC<EditClipViewSelectorOptionProps> = ({
  active,
  children,
}) => <span className={block('option', { active })}>{children}</span>;

export default EditClipViewSelectorOption;

import * as React from 'react';

import { Wave } from 'components/icons';
import { useEditorState } from '../context/VideoEditorStateContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { OPTION_TILE_FEATURE_PATHS } from '../state/constants';
import useFeature from '../useFeature';
import AccessControlOptionTile, {
  AccessControlOptionTileProps,
} from './AccessControlOptionTile';

type PickedTileProps<P = any> = Pick<
  AccessControlOptionTileProps<P>,
  'onClick' | 'params'
>;

export interface WaveformTileProps<P = any> extends PickedTileProps<P> {
  className?: string;
}

const WaveformTile = React.forwardRef(
  <P extends {} = any>(
    { className, onClick, params }: WaveformTileProps<P>,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const { isComplex } = useTemplateState();
    const { features, disabled, isLoading } = useEditorState();
    const featureState = useFeature(['waveform']);

    const isDisabled = disabled || isLoading;

    const getTileType = () => {
      if (isComplex) {
        return 'not-available';
      }

      if (featureState === 'locked') {
        return 'locked';
      }

      return 'default';
    };

    if (features.waveform === 'hidden') {
      return null;
    }

    return (
      <AccessControlOptionTile
        {...{ className, onClick, params }}
        disabled={isDisabled}
        featurePath={OPTION_TILE_FEATURE_PATHS.waveform}
        icon={<Wave height={40} />}
        id="template-waveform-tile"
        label="waveform"
        type={getTileType()}
        ref={ref}
      />
    );
  },
);

export default WaveformTile;

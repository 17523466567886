import React from 'react';
import { useTextOverlay } from '../../state/TextOverlayProvider';
import { TextBuilderStyles } from '../../types';

import { block } from '../../utils';
import { EMPTY_LINE_TYPE, FULL_LINE_TYPE, getTextLines } from './utils';

interface TextOverlayStaticTextProps {
  textBuilderStyles: TextBuilderStyles;
  textValue: string;
  isCaption?: boolean;
}

const TextOverlayStaticText: React.FunctionComponent<TextOverlayStaticTextProps> = props => {
  const { textBuilderStyles, textValue, isCaption } = props;

  const { editorHtmlRef, draftEditorData } = useTextOverlay();

  const lines = React.useMemo(() => getTextLines(textValue), [textValue]);

  const htmlContent = React.useMemo(
    () =>
      lines.map((line, index) => {
        let content = null;
        switch (line.type) {
          case EMPTY_LINE_TYPE:
            content = (
              <span style={textBuilderStyles.emptyLineStyle}>{line.text}</span>
            );
            break;
          case FULL_LINE_TYPE:
            content = (
              <span style={textBuilderStyles.lineStyle}>
                {draftEditorData?.getIn(['advancedAnimation', 'enabled']) &&
                isCaption
                  ? null
                  : line.text}
              </span>
            );
            break;
          default:
            break;
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <p key={String(index)} style={textBuilderStyles.paragraphStyle}>
            {content}
          </p>
        );
      }),
    [
      draftEditorData,
      isCaption,
      lines,
      textBuilderStyles.emptyLineStyle,
      textBuilderStyles.lineStyle,
      textBuilderStyles.paragraphStyle,
    ],
  );

  return (
    <div ref={editorHtmlRef} className={block('static-text-overlay')}>
      {htmlContent}
    </div>
  );
};

export default TextOverlayStaticText;

import React from 'react';

import ConnectedModal from 'containers/ConnectedModal';
import FullEpisodeCaptionsUpsellModalContents from './FullEpisodeCaptionsUpsellModalContents';
import { block } from './utils';

export interface CaptionsUpsellModalProps {}

const FullEpisodeCaptionsUpsellModal: React.FC<CaptionsUpsellModalProps> = () => (
  <ConnectedModal
    name="FullEpisodeCaptionsUpsell"
    title="full-length captions"
    className={block()}
  >
    <FullEpisodeCaptionsUpsellModalContents />
  </ConnectedModal>
);

export default FullEpisodeCaptionsUpsellModal;

import cn from 'classnames';
import * as React from 'react';

import { IconicSelectorStepProps } from '../../types';
import { iconicSelectorBlock as block } from '../../utils';
import CardWizardStep from '../CardWizard/CardWizardStep';
import IconicOption from './IconicOption';

function IconicSelectorStep<V = any>({
  className,
  footer,
  onClick,
  options,
  title,
  subtitle,
}: IconicSelectorStepProps<V>) {
  return (
    <CardWizardStep className={cn(block(), className)}>
      <CardWizardStep.H1>{title}</CardWizardStep.H1>
      <CardWizardStep.H2>{subtitle}</CardWizardStep.H2>
      <div className={block('inner')}>
        <div className={block('options')}>
          {options.map(
            (
              { className: optionClassName, color, href, icon, label, value },
              index,
            ) => (
              <IconicOption
                {...{
                  color,
                  href,
                  icon,
                  index,
                  label,
                  onClick,
                  value,
                }}
                className={optionClassName}
                key={label}
              />
            ),
          )}
        </div>

        {footer && <div className={block('footer')}>{footer}</div>}
      </div>
    </CardWizardStep>
  );
}

export default IconicSelectorStep;

import { AlertCircle, Banner } from '@sparemin/blockhead';
import React from 'react';

import { ClipSelectionMode } from './types';
import { block } from './utils';

interface ClipVideoFullEpisodeBannerProps {
  clipSelectionMode: ClipSelectionMode;
}

const ClipVideoFullEpisodeBanner: React.FunctionComponent<ClipVideoFullEpisodeBannerProps> = props => {
  const { clipSelectionMode } = props;

  if (clipSelectionMode === 'clip') {
    return null;
  }

  return (
    <Banner
      className={block('callout')}
      icon={
        <AlertCircle className={block('callout-icon')} height={40} width={40} />
      }
    >
      Editing full episode captions isn’t supported yet, but will be soon!
    </Banner>
  );
};

export default ClipVideoFullEpisodeBanner;

import * as React from 'react';

import { SelectField, SelectFieldProps } from 'components/Form';
import useCaptionSupportedLanguages from 'hooks/useCaptionSupportedLanguages';

interface Props extends SelectFieldProps {
  label?: string;
}

const LanguageSelector: React.SFC<Props> = ({ label, ...props }) => {
  const supportedLanguages = useCaptionSupportedLanguages();

  return (
    <SelectField label={label} {...props}>
      {supportedLanguages.map(({ languageCode, name }) => (
        <option value={languageCode} key={languageCode}>
          {name}
        </option>
      ))}
    </SelectField>
  );
};

LanguageSelector.defaultProps = {
  label: 'Transcription Language',
  value: 'en-US',
};

export default LanguageSelector;

export { Props as LanguageSelectorProps };

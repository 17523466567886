import { OnSuggestedClipsLoaded } from './types';
import useMessageHandler from './useEddyMessaging/useMessageHandler';

export type UseSuggestedClipsLoadedMessageData = {
  type: 'suggestedClipsLoaded';
};

export type UseSuggestedClipsLoadedMessagingProps = {
  onSuggestedClipsLoaded?: OnSuggestedClipsLoaded;
};

export default function useSuggestedClipsLoadedMessaging({
  onSuggestedClipsLoaded,
}: UseSuggestedClipsLoadedMessagingProps) {
  useMessageHandler<UseSuggestedClipsLoadedMessageData>(e => {
    if (e.data.type === 'suggestedClipsLoaded') {
      onSuggestedClipsLoaded?.();
    }
  });
}

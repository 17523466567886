import React from 'react';

import { TextOverlay } from 'blocks/TextOverlayModal';
import {
  buildUnmountedHtmlString,
  OverlayScaler,
  TextOverlayV2,
  validateOverlayV2Integrity,
} from 'blocks/TextOverlayModal/v2';
import { DeepImmutableMap, IViewport } from 'types';

/**
 * Adjusts the font size px at the text style. For it, uses the current
 * scaler in order get a px value based on the font size vw calculation
 * with the new viewport width.
 */
export const recalculateFontSize = (
  textStyle: DeepImmutableMap<React.CSSProperties>,
  scaler: DeepImmutableMap<OverlayScaler>,
  targetViewport: IViewport,
): DeepImmutableMap<React.CSSProperties> => {
  const recalculatedFontSize = Math.round(
    (scaler.get('fontSizeVw') / 100) * targetViewport.width,
  );

  return textStyle.set('fontSize', recalculatedFontSize);
};

/**
 * Performs the necessary calculations for updating a v2 text overlay
 * when copying to a different aspect ratio.
 */
export const rescaleOverlayToViewport = (
  overlayItem: TextOverlay | TextOverlayV2,
  targetViewport: IViewport,
): TextOverlay => {
  const isV2 = validateOverlayV2Integrity(overlayItem);

  // if the overlay is not valid v2 overlay, no transformation should be done.
  if (!isV2) {
    return overlayItem;
  }

  // v2 integrity already ensures that both textStyle and scaler objects exist.
  const textStyle = overlayItem.getIn(['editor', 'textStyle']);
  const scaler = overlayItem.getIn(['editor', 'scaler']);

  return overlayItem.withMutations(s => {
    s.setIn(
      ['editor', 'textStyle'],
      recalculateFontSize(textStyle, scaler, targetViewport),
    );
  });
};

/**
 * Applies the global style at the updated overlay to all the overlays contained
 * at an overlays collection.
 * To achieve this the text is obtained from each overlay in the colection and html
 * string is built with for each of the individual overlays text but using the
 * styles from the global updated one.
 * Finally the current overlay is replaced with a composed overlay that is obtained
 * by using the updated overlay as based and replacing textHtml the generated one.
 * Additionally, text, time and id are kept from the original overlay.
 */
export const applyGlobalOverlayToOverlays = (
  overlaysById: TextOverlayV2[],
  updatedOverlay: TextOverlayV2,
): TextOverlayV2[] => {
  return overlaysById.map(overlay => {
    const currOverlayText = overlay.get('text');
    const updatedStyles = updatedOverlay.getIn(['editor', 'textStyle'])?.toJS();
    const textHtml = buildUnmountedHtmlString(updatedStyles, currOverlayText);

    return updatedOverlay.withMutations(s =>
      s
        .set('textHtml', textHtml)
        .set('text', currOverlayText)
        .set('time', overlay.get('time'))
        .set('id', overlay.getIn(['id'])),
    );
  });
};

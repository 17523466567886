import * as React from 'react';
import { Editor } from 'tinymce';
import { isUndefined, mapObject } from 'underscore';
import { TINYMCE_CONTENT_CSS } from 'config';
import { TINYMCE_CONTENT_CSS_SELECTOR } from 'utils/constants';

import { domTreeToHtmlString, htmlStringToDomTree } from 'utils/dom';
import { createChainedFunction } from 'utils/functions';
import { setStyle as applyStyles, pxify, toCssString } from 'utils/ui';
import { Fonts, IStyles, ITextShadow } from './types';

/*
 * because tinymce does this and without it, our families will never match the ones it gives back
 * https://github.com/tinymce/tinymce/blob/0e5a959464065c8fdda3899472fded032125a0c0/src/core/main/ts/fmt/FontInfo.ts#L35
 */
export function normalizeFontFamily(family: string) {
  if (!family) return family;

  return family.replace(/['"\\]/g, '').replace(/,\s+/g, ',');
}

export function getHeadlinerCss() {
  switch (process.env.NODE_ENV) {
    /*
     * when NODE_ENV===local, styles are injected as a blob on a link tag using webpack's
     * style-loader. there's no css file to reference.  find the css link tag and take the href to
     * pass to tinymce iframe
     */
    case 'development': {
      const selector = TINYMCE_CONTENT_CSS_SELECTOR;
      const tag: HTMLLinkElement = document.querySelector(selector);
      return tag.href;
    }

    // all other NODE_ENV's, we extract style to a separate file
    default:
      return TINYMCE_CONTENT_CSS;
  }
}

export function fireEditorChange(editor: Editor) {
  editor.nodeChanged();
}

export function setStyle(editor: Editor, command: string, value: any) {
  // skip_focus prevents tinymce stealing focus from other inputs, e.g. color
  // inputs which call onChange after entering at least 3 characters
  editor.execCommand(command, false, value, { skip_focus: true });
}

function getIframe(editor: Editor) {
  return editor.getContentAreaContainer().firstElementChild;
}

export function insertContent(editor: Editor, content: string) {
  editor.insertContent(content);
}

export function appendContent(editor: Editor, content: string) {
  editor.focus(false);
  editor.selection.select(editor.getBody(), true);
  editor.selection.collapse();
  insertContent(editor, content);

  // blurs the iframe - seems this is the recommended way of triggering a blur on the editor
  (getIframe(editor) as any).blur();
}

function getFontNameFromFamily(family: string, fonts: Fonts) {
  if (!family) return undefined;
  const font = fonts.find(f => normalizeFontFamily(f.family) === family);
  return font && font.name;
}

export function getStyles(editor: Editor, fonts: Fonts): IStyles {
  const justify = (() => {
    if (editor.queryCommandState('justifycenter')) {
      return 'center';
    }

    if (editor.queryCommandState('justifyright')) {
      return 'right';
    }

    return 'left';
  })();

  return {
    justify,
    bold: editor.queryCommandState('bold'),
    fontName: getFontNameFromFamily(
      editor.queryCommandValue('fontname') as string,
      fonts,
    ),
    fontSize: parseFloat(editor.queryCommandValue('fontsize') as string),
    foreColor: editor.queryCommandValue('forecolor') as string,
    /*
     * NB: for some reason, tinymce doesn't return the correct value for text background when using
     * the 'hilite' command (even though that seems to set the correct value).  only backcolor seems
     * to return the correct value
     */
    hiliteColor: editor.queryCommandValue('backcolor') as string,
    italic: editor.queryCommandState('italic'),
    textShadow: editor.queryCommandValue('textshadow') || ({} as ITextShadow),
    underline: editor.queryCommandState('underline'),
  };
}

export function destroyEditor(editor: Editor) {
  editor.remove();
}

export function formatCommandValue(command: string, value: any): any {
  switch (command) {
    case 'fontsize':
      return `${value}px`;

    default:
      return value;
  }
}

export function styleToContentStyle(style: React.CSSProperties) {
  return `body ${toCssString(style)}`;
}

export function setContainerStyle(editor: Editor, style: React.CSSProperties) {
  // WARNING: due to autoresize tinymce plugin, don't set padding on body
  const bodyStyle = pxify({ ...style });

  setTextStyle(editor, bodyStyle);
}

/**
 * NOTE: editor.execCommand('SelectAll', false) is probably the most straightforward way to do this,
 * but it results in an incorrect text shadow state in the toolbar.  this might be an issue with our
 * custom text-shadow plugin, but unsure of how to apply the fix there.
 *
 * the outermost p tag has a text-shadow.  if a text-shadow is applied to all of the text, the text
 * will be wrapped in a span and the text-shadow will be added.  exec'ing the SelectAll command
 * will select the p tag resulting in the toolbar showing the outermost text-shadow rather than the
 * innermost one.
 *
 * the implementation below doesn't suffer from this same issue and seems to select the correct
 * node
 */
export function selectAll(editor: Editor) {
  editor.selection.select(editor.getBody(), true);
}

export function setTextStyle(editor: Editor, style: React.CSSProperties) {
  const styles = mapObject(style, val => `${val}`);

  /*
   * for all the styles we use, tinymce applies only text-align to the `p` tag.  remove it from the
   * rest of the styles and apply it separately to the p tags below
   */
  const { textAlign, ...inlineStyles } = styles;
  editor.formatter.register('text_format', {
    inline: 'span',
    styles: inlineStyles,
  });
  selectAll(editor);
  editor.formatter.apply('text_format');
  clearSelection(editor);

  if (textAlign) {
    /*
     * NB: see https://www.tinymce.com/docs/api/tinymce.dom/tinymce.dom.domutils/#setstyle
     * tinymce docs (and typedefs) state that the first argument to setStyle should be a string,
     * yet when passed the string 'p', setStyle throws errors.  furthermore the example in the
     * tinymce docs is exactly what we want here - set the style on all p tags - and it does so
     * by using editor.dom.select('p').
     *
     * casting to any to suppress errors
     */
    const elements: any = editor.dom.select('p');
    editor.dom.setStyle(elements, 'textAlign', textAlign);
  }
}

export function setIframeStyle(editor: Editor, style: React.CSSProperties) {
  applyStyles(getIframe(editor), style);
}

export function getTextContent(editor: Editor) {
  return editor.getContent({ format: 'text' });
}

/**
 * tinymce adds a <br /> when the user presses enter so that the cursor has somewhere to live.
 * when the content is export to HTML on Firefox, these br's are in the content string, whereas
 * on Chrome and Safari they are not.
 *
 * Sanitizing just involves removing any <br /> tags to solve the Firefox issue.
 *
 * Note that there should not be any br tags which we're not removing, since line breaks are created
 * by tinymce wrapping the individual lines in a <p> tag
 */
function sanitizeHtml(html: string) {
  if (!html) return undefined;

  // wrap in case html string is justa list of p tags with no root
  const root = htmlStringToDomTree(`<div>${html}</div>`);
  const brs = root.getElementsByTagName('br');

  for (const br of brs) {
    br.remove();
  }

  return domTreeToHtmlString(root);
}

/**
 * returns the editor html content without the placeholer string
 */
export function getEditorContent(editor: Editor, placeholder?: string) {
  if (!isUndefined(placeholder) && isDefaultContent(editor, placeholder)) {
    setEditorContent(editor, editor.getContent().replace(placeholder, ''));
  }
  return sanitizeHtml(editor.getContent());
}

export function isDefaultContent(editor: Editor, placeholder: string) {
  return getTextContent(editor) === placeholder;
}

export function setEditorContent(editor: Editor, content: string) {
  editor.setContent(content);
}

/*
 * NOTE: when the text area is empty, getEditorText() will return a line break.  when the
 * text box contains just one line break, getEditorText() will return 2 line breaks.
 * it looks like getEditorText() aways returns n+1 line breaks where n is the number of line
 * breaks the user inserted
 */
export function editorHasTextContent(editor: Editor) {
  const text = getTextContent(editor);
  return !isUndefined(text) && text.trim() !== '';
}

export function setDefaultContent(editor: Editor, placeholder: string) {
  /*
   * this check is important.  assumptions are made below that only hold if the editor has no text
   * content.  in that case, we know the following is true:
   *  - there is a <p /> tag.  tinymce enforces this
   *  - there _might_ be a span we've inserted for "inner" styling
   *  - there is a <br /> tag.  tinymce inserts these into editors with no content so that the user
   *    can place the caret inside of a tag (otherwise there would be no way to do this)
   */
  if (!editorHasTextContent(editor)) {
    /*
     * default content will be injected into first span or first p tag, whichever we find.
     * prioritize span tag since those styles will override p tag styles
     */
    const elements = editor.dom.select('span,p');
    const element: HTMLElement = elements.pop() as HTMLElement;
    element.innerHTML = placeholder;

    /*
     * delete all br tags - they're just placehodlers inserted by tinymce when there's no content,
     * but now we have content
     */
    editor.dom.select('br').forEach((br: HTMLElement) => br.remove());
  }
}

/*
 * NOTE: note sure why, but the code between editor.off and editor.on brings the editor in
 * focus, causing the toolbar to get activated.  workaround by temporarily removing the focus
 * handler and restoring it after the operation is complete
 */
export function clearStyles(editor: Editor, focusHandler?: any) {
  const hasFocusHandler = !isUndefined(focusHandler);

  if (hasFocusHandler) {
    editor.off('Focus', focusHandler);
  }

  selectAll(editor);
  editor.execCommand('RemoveFormat', false);
  clearSelection(editor);

  if (hasFocusHandler) {
    editor.on('Focus', focusHandler);
  }
}

export function focusEditor(editor: Editor) {
  editor.focus(false);
}

export function clearSelection(editor: Editor) {
  editor.selection.collapse(true);
}

export const moveCursorToStart = createChainedFunction(
  selectAll,
  clearSelection,
);

export function pastePostprocess(
  editor: Editor,
  { node },
  style: React.CSSProperties,
) {
  const text = node.innerText;

  // remove all children to simplify the html structure
  while (node.firstChild) {
    node.removeChild(node.firstChild);
  }

  const editorText = getTextContent(editor);
  const selectedText = editor.selection.getContent({ format: 'text' });

  const element = (() => {
    // user is replacing entire contents or editor is currently empty
    if (selectedText === editorText || !editorHasTextContent(editor)) {
      const el = document.createElement('p');
      editor.dom.setStyles(el, pxify({ ...style }));
      return el;
    }
    return document.createElement('span');
  })();

  element.innerText = text;
  node.appendChild(element);
}

import React from 'react';

import {
  createCaptionsTextOverlay,
  mapTextOverlayToCaptionsOverride,
} from 'blocks/TextOverlayModal/v2/style-captions-utils';
import {
  CaptionsConfig,
  TextOverlayV2,
} from 'blocks/TextOverlayModal/v2/types';

import EditCaptionsModal, { EditCaptionsModalProps } from './EditCaptionsModal';
import { getMaxChars } from './utils';

type PickedEditCaptionsModalProps = Pick<
  EditCaptionsModalProps,
  | 'aspectRatio'
  | 'background'
  | 'animationsTabEnabled'
  | 'animationsTabDisplayConfig'
  | 'footerLeftActions'
  | 'isCompatibilityMode'
  | 'onCancel'
  | 'onExited'
  | 'onSwitchToLegacy'
  | 'show'
  | 'showRechunkAlert'
>;

export interface EditCaptionsModalContainerProps
  extends PickedEditCaptionsModalProps {
  captionsOverride?: CaptionsConfig;
  onSubmit: (
    captionsOverride: CaptionsConfig,
    maxChars: number,
    hasBeenResized: boolean,
  ) => void;
}

const EditCaptionsModalContainer: React.FunctionComponent<EditCaptionsModalContainerProps> = props => {
  const {
    aspectRatio,
    background,
    captionsOverride,
    animationsTabEnabled,
    animationsTabDisplayConfig,
    footerLeftActions,
    isCompatibilityMode,
    onCancel,
    onExited,
    onSubmit,
    onSwitchToLegacy,
    show,
    showRechunkAlert,
  } = props;

  const [textRange, setTextRange] = React.useState<[number, number]>([0, 0]);
  const [rechunkRequired, setRechunkRequired] = React.useState(false);

  const textOverlay = createCaptionsTextOverlay(aspectRatio, captionsOverride);

  const handleSubmit = React.useCallback(
    (overlay: TextOverlayV2) => {
      const updatedCaptionsOverride = mapTextOverlayToCaptionsOverride(overlay);
      const maxChars = getMaxChars(textRange);
      onSubmit(updatedCaptionsOverride, maxChars, rechunkRequired);
    },
    [onSubmit, rechunkRequired, textRange],
  );

  return (
    <EditCaptionsModal
      aspectRatio={aspectRatio}
      background={background}
      animationsTabEnabled={animationsTabEnabled}
      animationsTabDisplayConfig={animationsTabDisplayConfig}
      footerLeftActions={footerLeftActions}
      isCompatibilityMode={isCompatibilityMode}
      onCancel={onCancel}
      onExited={onExited}
      onSubmit={handleSubmit}
      onSwitchToLegacy={onSwitchToLegacy}
      onTextRangeChange={setTextRange}
      onToggleRechunkRequired={setRechunkRequired}
      show={show}
      showRechunkAlert={showRechunkAlert}
      textOverlay={textOverlay}
    />
  );
};

export default EditCaptionsModalContainer;

import fileExtension from 'file-extension';
import React, { useCallback } from 'react';

import { Crop } from 'components/icons';

import OptionTile from '../assets/OptionTile';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useModalManager } from '../ModalManager';
import useMainMedia from '../useMainMedia';

export interface CropTileProps {
  className?: string;
}

const CropTile: React.FC<CropTileProps> = props => {
  const { className } = props;

  const { showModal } = useModalManager();
  const mainMedia = useMainMedia();
  const dispatch = useTemplateDispatch();

  const disabled = mainMedia.type !== 'image' && mainMedia.type !== 'videoClip';

  const handleCrop = useCallback(() => {
    const { type, asset } = mainMedia;

    if (type === 'image') {
      const { id, originalSrc, sourceImage, imageSrc, metadata } = asset;

      showModal('crop', {
        mediaType: 'image',
        defaultMetadata: metadata,
        fileType:
          typeof originalSrc === 'string' ? undefined : originalSrc?.type,
        imgSrc: originalSrc || sourceImage.url || imageSrc,
        onSubmit: (src, newMetadata) => {
          dispatch({
            payload: { id, src, metadata: newMetadata },
            type: 'IMAGE_CROP',
          });
        },
      });
    }

    if (type === 'videoClip') {
      const { id, original, metadata } = asset;
      const thumbnailUrl = original?.previewThumbnail?.url;

      showModal('crop', {
        mediaType: 'videoClip',
        defaultMetadata: {
          ...metadata,
          constrained: true,
        },
        fileType: thumbnailUrl && `image/${fileExtension(thumbnailUrl)}`,
        imgSrc: thumbnailUrl,
        allowConstrainControl: false,
        onSubmit: (_, newMetadata) => {
          dispatch({
            payload: { id, metadata: newMetadata },
            type: 'VIDEOCLIP_CROP',
          });
        },
      });
    }
  }, [dispatch, mainMedia, showModal]);

  return (
    <OptionTile
      label="crop"
      className={className}
      disabled={disabled}
      icon={<Crop style={{ height: 40, width: 'auto' }} />}
      onClick={handleCrop}
    />
  );
};

export default CropTile;

import React from 'react';

import { unscaleCanvasSize } from '../style-transform-utils';
import { EditorDataV2, Position, Rect, Size } from '../types';

const BASE_RESIZE_DELTA: Size = { height: 0, width: 0 };

interface UseTextOverlayResizeDeltaConfig {
  canvasSize: Size;
  draftEditorData: EditorDataV2;
  resizeObservers: ((resizeDelta: Size) => void)[];
}

interface UseTextOverlayResizeDelta {
  onTextBoxResize: (_: Position, delta: Size) => void;
  onTextBoxResizeEnd: (
    onResizeEnd: (rect: Rect) => void,
  ) => (rect: Rect) => void;
  onTextBoxResizeStart: (onResizeStart: () => void) => () => void;
}

const useTextOverlayResizeDelta = (
  config: UseTextOverlayResizeDeltaConfig,
): UseTextOverlayResizeDelta => {
  const { canvasSize, draftEditorData, resizeObservers } = config;

  // As resize actions are emitted repeadly and fast, the ref is necessary for
  // making immediate checks and avoiding race conditions when the resize
  // actions start and end.
  const isResizingRef = React.useRef(false);

  // Calculating the resize delta allows a "live" feedback of the text fit calculation
  // while dragging. If the extension is disabled, the calculation is not done
  // at all.
  const handleTextBoxResize = React.useCallback(
    (_: Position, delta: Size): void => {
      if (isResizingRef.current && draftEditorData) {
        const resizeDelta = unscaleCanvasSize(
          delta,
          draftEditorData,
          canvasSize,
        );
        resizeObservers.forEach(observer => observer(resizeDelta));
      }
    },
    [canvasSize, draftEditorData, resizeObservers],
  );

  // Composes the resize end function for being able to restore the resize
  // delta to the initial value and clearing the isResizing flag
  const handleTextBoxResizeEnd = React.useCallback(
    (onResizeEnd: (rect: Rect) => void) => (rect: Rect): void => {
      onResizeEnd(rect);
      resizeObservers.forEach(observer => observer(BASE_RESIZE_DELTA));
      isResizingRef.current = false;
    },
    [resizeObservers],
  );

  // Composes the resize start function for being able to enable the isResizing
  // flag when resizing starts.
  const handleTextBoxResizeStart = React.useCallback(
    (onResizeStart: () => void) => (): void => {
      onResizeStart();
      isResizingRef.current = true;
    },
    [],
  );

  return {
    onTextBoxResize: handleTextBoxResize,
    onTextBoxResizeEnd: handleTextBoxResizeEnd,
    onTextBoxResizeStart: handleTextBoxResizeStart,
  };
};

export default useTextOverlayResizeDelta;

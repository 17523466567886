import { createSelector } from 'reselect';
import { AUTOMATION_BLACKLIST } from 'containers/ProIndicator';
import { SubTier } from 'redux/middleware/api/plan-service';
import { State } from 'redux/types';
import { RequestStatus } from 'types';
import { subtierSelector } from '../pricing/selectors';
import { ClipSelectState } from './types';

const stateSelector = (state: State): ClipSelectState =>
  state.get('clipSelect');

export const isFreeSelector = createSelector(
  subtierSelector,
  (subtier: SubTier) => AUTOMATION_BLACKLIST.includes(subtier),
);

export const dislikedGroupSuggestionIdsSelector = createSelector(
  stateSelector,
  state => state.dislikedGroupSuggestionIds.toArray(),
);

export const groupClipSuggestionsStatusSelector = createSelector(
  stateSelector,
  state => state.get('groupClipSuggestionsStatus'),
);

export const groupClipSuggestionsIsBlockedSelector = createSelector(
  stateSelector,
  state => state.get('isBlockedError'),
);

export const groupClipSuggestionsIsLoadingSelector = createSelector(
  groupClipSuggestionsStatusSelector,
  status => status === RequestStatus.REQUEST,
);

export const creationClipSuggestionsStatusSelector = createSelector(
  stateSelector,
  state => state.get('creationClipSuggestionsStatus'),
);
export const creationClipSuggestionsIsBlockedSelector = createSelector(
  stateSelector,
  state => state.get('isBlockedError'),
);

export const creationClipSuggestionsIsLoadingSelector = createSelector(
  creationClipSuggestionsStatusSelector,
  status => status === RequestStatus.REQUEST,
);

export const dislikedCreationSuggestionIdsSelector = createSelector(
  stateSelector,
  state => state.dislikedCreationSuggestionIds.toArray(),
);

export const subscriptionItemIdSelector = createSelector(stateSelector, state =>
  state.get('subscriptionItemId'),
);

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { reset } from 'redux/modules/clip-select/actions';
import { Dispatch } from 'redux/types';
import { ClipSelectPageSource, ClipSuggestionDislikeReason } from 'types';
import { ClipSelectMediaType, ClipsPageInfo, ClipSuggestion } from '../types';
import { pageBlock as block } from '../utils';
import { ClipSelectAnimationProvider } from './ClipSelectAnimationContext';
import { ClipSelectProvider } from './ClipSelectContext';
import { ClipSelectProviderProps } from './ClipSelectContext/types';
import { ClipSelectNavigationProvider } from './ClipSelectNavigationContext';
import { useNavigation } from './ClipSelectNavigationContext/navigation-machine';
import ClipSelectPageContents from './ClipSelectPageContents';
import { ClipSelectTrackingProvider } from './ClipSelectTrackingContext';

type ProviderProps = Partial<
  Pick<
    ClipSelectProviderProps,
    'onOpenInAdvancedEditor' | 'onSubmit' | 'onReprocessClip' | 'onSelectClip'
  >
>;

export interface ClipSelectPageProps extends ProviderProps {
  suggestedClips?: ClipSuggestion[];
  dislikedSuggestionIds?: number[];
  clipsPageInfo: ClipsPageInfo;
  eddyProjectId?: string;
  source: ClipSelectPageSource;
  mediaType?: ClipSelectMediaType;
  onDislikeClip?: (
    reason: ClipSuggestionDislikeReason,
    clipSuggestion: ClipSuggestion,
  ) => void;
  onUpdateSuggestions?: () => void;
}

const ClipSelectPage: React.FC<ClipSelectPageProps> = ({
  suggestedClips,
  dislikedSuggestionIds,
  eddyProjectId,
  clipsPageInfo,
  source,
  mediaType = 'audio',
  onReprocessClip,
  onOpenInAdvancedEditor,
  onSubmit,
  onDislikeClip,
  onSelectClip,
  onUpdateSuggestions,
}) => {
  const machine = useNavigation();
  const dispatch = useDispatch<Dispatch>();

  const [state] = machine;
  const isLoading = state.matches('loading');
  const isIntro = state.matches('intro');

  useEffect(
    () => () => {
      dispatch(reset);
    },
    [dispatch],
  );

  return (
    <div
      className={block({
        exporting: state.matches('export'),
        scrollable: !isIntro && !isLoading,
      })}
    >
      <ClipSelectNavigationProvider value={machine}>
        <ClipSelectTrackingProvider {...{ suggestedClips, source, mediaType }}>
          <ClipSelectProvider
            {...{
              eddyProjectId,
              suggestedClips,
              dislikedSuggestionIds,
              clipsPageInfo,
              mediaType,
              onReprocessClip,
              onOpenInAdvancedEditor,
              onSubmit,
              onDislikeClip,
              onSelectClip,
              onUpdateSuggestions,
            }}
          >
            <ClipSelectAnimationProvider>
              <ClipSelectPageContents />
            </ClipSelectAnimationProvider>
          </ClipSelectProvider>
        </ClipSelectTrackingProvider>
      </ClipSelectNavigationProvider>
    </div>
  );
};

export default ClipSelectPage;

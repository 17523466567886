import { State } from 'redux/types';
import { experimentsSelector } from '../entities/selectors';

export const experimentVariantSelector = (experimentName: string) => (
  state: State,
): string => {
  const experiments = experimentsSelector(state);
  return experiments?.get(experimentName)?.get('variantName');
};

export const experimentVariantVariablesSelector = (experimentName: string) => (
  state: State,
): undefined => {
  const experiments = experimentsSelector(state);
  return experiments?.getIn([experimentName, 'variantVariables'])?.toJS();
};

import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AudioDurationUpsellBanner from 'components/AudioDurationUpsellBanner';
import { replaceModal } from 'redux/modules/modal';
import { isBasicOrFreeSelector } from 'redux/modules/pricing';

import { block } from './utils';

interface AddAudioModalDurationUpsellProps {
  containerClassName?: string;
}

const AddAudioModalDurationUpsell: React.FunctionComponent<AddAudioModalDurationUpsellProps> = props => {
  const { containerClassName } = props;

  const dispatch = useDispatch();
  const isFreeTier = useSelector(isBasicOrFreeSelector);

  const shouldShowDurationIncreaseBanner = isFreeTier;

  const handleDurationUpsellBannerClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      dispatch(
        replaceModal({
          name: 'DurationLimitModal',
          params: { modalSource: 'IncreaseLengthLimitEditor' },
        }),
      );
    },
    [dispatch],
  );

  if (!shouldShowDurationIncreaseBanner) {
    return null;
  }

  return (
    <div
      className={classNames(
        block('audio-duration-upsell-container'),
        containerClassName,
      )}
    >
      <AudioDurationUpsellBanner
        className={block('audio-duration-upsell-banner')}
        onUpgradeClick={handleDurationUpsellBannerClick}
        show={shouldShowDurationIncreaseBanner}
        source="editor"
      />
    </div>
  );
};

export default AddAudioModalDurationUpsell;

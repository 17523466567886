import React, { useCallback } from 'react';
import { identity } from 'underscore';
import { TextOverlay } from 'blocks/TextOverlayModal';
import { TextOverlayV2 } from 'blocks/TextOverlayModal/v2';
import {
  ModalSwitcher,
  useTextOverlayStatelessSwitch,
} from 'blocks/TextOverlaySwitchModal';
import { Font } from 'components/icons';

import { addTextOverlay, getTextModalOverlay } from '../state';
import { TextIntegrationId } from '../types';
import {
  AddStaticIntegrationText,
  IntegrationProps,
  OnAddIntegrationText,
  onEditIntegrationText,
  StaticTextIntegration,
} from '../types/integrations';
import { getTextOverlayEditorConfig } from '../utils';

interface UseStaticTextIntegrationConfig extends IntegrationProps {
  placeholder?: string;
}

const INTEGRATION_ID = TextIntegrationId.STATIC as const;
const INTEGRATION_DATA = { id: INTEGRATION_ID };

export default function useStaticTextIntegration({
  placeholder,
  priority,
}: UseStaticTextIntegrationConfig): StaticTextIntegration {
  const { onToggleLegacyMode } = useTextOverlayStatelessSwitch();

  const handleAddText: OnAddIntegrationText = useCallback(
    (_, modalManager, addText) => {
      modalManager.showModal('add-text', {
        defaultText: placeholder,
        isLegacy: false,
        onSubmit: overlay => addText(overlay, INTEGRATION_DATA),
        title: 'Add text',
      });
    },
    [placeholder],
  );

  const handleEditText: onEditIntegrationText = useCallback(
    (state, modalManager, id, props) => {
      const textOverlay = getTextModalOverlay(state, id);
      const { isLegacy, isV2CompatibilityMode } = getTextOverlayEditorConfig(
        textOverlay,
      );

      const handleOpenLegacyModal = (
        modalSwitcher: ReturnType<ModalSwitcher>,
        transitionOverlay: TextOverlay,
      ) => {
        modalManager.showModal('edit-text', {
          assetId: id,
          isLegacy: true,
          onSwitchModal: modalSwitcher,
          textOverlay: transitionOverlay,
          title: 'Text',
          ...props,
        });
      };

      const handleOpenCurrentModal = (
        modalSwitcher: ReturnType<ModalSwitcher>,
        transitionOverlay: TextOverlayV2,
      ) => {
        modalManager.showModal('edit-text', {
          assetId: id,
          isLegacy: false,
          isV2CompatibilityMode: true,
          onSwitchModal: modalSwitcher,
          textOverlay: transitionOverlay,
          title: 'Text',
          ...props,
        });
      };

      modalManager.showModal('edit-text', {
        assetId: id,
        isLegacy,
        isV2CompatibilityMode,
        onSwitchModal: onToggleLegacyMode(
          handleOpenCurrentModal,
          handleOpenLegacyModal,
        ),
        textOverlay,
        title: 'Text',
        ...props,
      });
    },
    [onToggleLegacyMode],
  );

  const addText: AddStaticIntegrationText = useCallback(
    (state, overlay) =>
      addTextOverlay(state, overlay, { integrationData: INTEGRATION_DATA }),
    [],
  );

  return {
    addText,
    priority,
    creationType: 'static',
    id: TextIntegrationId.STATIC,
    onAddText: handleAddText,
    onEditText: handleEditText,
    postProcessor: identity,
    textTypeCardProps: {
      icon: <Font style={{ height: 33 }} />,
      info: (
        <>
          <strong>Manually</strong> add custom text
          <br />
          to include it in every video
        </>
      ),
    },
    type: 'text',
  };
}

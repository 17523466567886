import {
  CaptionsAnimationConfig,
  CaptionsAnimationName,
} from '@sparemin/text-effects';
import { fromJS } from 'immutable';
import { useCallback } from 'react';
import { hexToRgba } from 'utils/color';
import { SM_BLUE } from 'utils/constants';
import { useTextOverlay } from '../../state/TextOverlayProvider';

export interface UseTextOverlayAdvancedAnimation {
  selectedAdvancedAnimation: CaptionsAnimationConfig | undefined;
  onTurnKaraokeAnimationOn: () => void;
  onActiveAnimationChange: (animationName: CaptionsAnimationName) => void;
  onWordEmphasisColorChange: (color: string) => void;
  onTurnAnimationsOff: () => void;
}

const DEFAULT_ACTIVE_KARAOKE_COLOR = hexToRgba(SM_BLUE);
const DEFAULT_ANIMATION_NAME: CaptionsAnimationName = 'activeWordColor';

const useTextOverlayAdvancedAnimation = (): UseTextOverlayAdvancedAnimation => {
  const { draftEditorData, onUpdateOverlay } = useTextOverlay();

  const selectedAdvancedAnimation = draftEditorData
    .get('advancedAnimation')
    ?.toJS();

  const handleTurnKaraokeAnimationOn = useCallback((): void => {
    onUpdateOverlay(advancedAnimation => {
      return advancedAnimation.set(
        'advancedAnimation',
        fromJS({
          enabled: true,
          animationName: DEFAULT_ANIMATION_NAME,
          properties: {
            wordEmphasisColor: DEFAULT_ACTIVE_KARAOKE_COLOR,
          },
        }),
      );
    });
  }, [onUpdateOverlay]);

  const handleActiveAnimationChange = useCallback(
    (animationName: CaptionsAnimationName): void => {
      onUpdateOverlay(advancedAnimation => {
        return advancedAnimation.set(
          'advancedAnimation',
          fromJS({
            ...selectedAdvancedAnimation,
            animationName,
          }),
        );
      });
    },
    [onUpdateOverlay, selectedAdvancedAnimation],
  );

  const handleWordEmphasisColorChange = useCallback(
    (color: string): void => {
      onUpdateOverlay(advancedAnimation => {
        return advancedAnimation.set(
          'advancedAnimation',
          fromJS({
            ...selectedAdvancedAnimation,
            properties: {
              ...selectedAdvancedAnimation?.properties,
              wordEmphasisColor: color,
            },
          }),
        );
      });
    },
    [onUpdateOverlay, selectedAdvancedAnimation],
  );

  const handleTurnAnimationsOff = useCallback((): void => {
    onUpdateOverlay(advancedAnimation => {
      return advancedAnimation.set(
        'advancedAnimation',
        fromJS({
          enabled: false,
        }),
      );
    });
  }, [onUpdateOverlay]);

  return {
    selectedAdvancedAnimation,
    onTurnKaraokeAnimationOn: handleTurnKaraokeAnimationOn,
    onActiveAnimationChange: handleActiveAnimationChange,
    onWordEmphasisColorChange: handleWordEmphasisColorChange,
    onTurnAnimationsOff: handleTurnAnimationsOff,
  };
};

export default useTextOverlayAdvancedAnimation;

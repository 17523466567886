import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingName } from 'blocks/OnboardingAnimations/types';
import { TYPEFORM_NPS_FORM_ID } from 'config';
import { getOnboardingViewConfirmation } from 'redux/middleware/api/headliner-user-service';
import { loggedInSelector } from 'redux/modules/auth';
import { dpaPerformedSelector } from 'redux/modules/download';
import { isNpsSurveyViewedSelector } from 'redux/modules/onboarding/selectors';

import { SurveyPopover } from './Typeform';
import { SurveyPopoverProps } from './Typeform/SurveyPopover';

export interface NpsSurveyProps extends Omit<SurveyPopoverProps, 'id'> {
  autoClose?: number;
}

const NpsSurvey: React.FC<NpsSurveyProps> = props => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(loggedInSelector);
  const isNpsSurveyViewed = useSelector(isNpsSurveyViewedSelector);
  const isDpaPerformed = useSelector(dpaPerformedSelector);
  const show = isLoggedIn && !isNpsSurveyViewed && isDpaPerformed;

  useEffect(() => {
    if (show) {
      dispatch(getOnboardingViewConfirmation(OnboardingName.NPS_SURVEY));
    }
  }, [dispatch, show]);

  return (
    show && (
      <SurveyPopover
        id={TYPEFORM_NPS_FORM_ID}
        open="load"
        width={750}
        {...props}
      />
    )
  );
};

export default NpsSurvey;

import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { isString, isUndefined } from 'underscore';

import { AudioClipperComponent } from 'components/AudioClipper';
import { IRegion } from 'components/AudioClipper/types';
import Modal from 'components/Modal';
import SteppedModal, {
  FooterButtonsDisabled,
  FooterButtonType,
} from 'components/SteppedModal';
import SelectAudioTabs from 'containers/AddAudioModal/SelectAudioTabs';
import AsyncAudioClipper from 'containers/AsyncAudioClipper';
import { useAddAssetModal } from 'containers/ConnectedModal/useAddAssetModal';
import TranscriptionForm from 'containers/TranscriptionForm';
import useVideoEditorPlaybackTimeContext from 'containers/VideoEditor/useVideoEditorPlaybackTimeContext';
import useMediaPlayback from 'hooks/useMediaPlayback';
import useOnMount from 'hooks/useOnMount';
import usePrevious from 'hooks/usePrevious';
import usePromise from 'hooks/usePromise';
import { waitingForStandardizationSelector } from 'redux/modules/async-audio-clipper/selectors';
import {
  defaultTranscriptionEnabledSelector,
  defaultWaveformSelector,
  defaultWaveGenerationReadonlySelector,
  defaultWaveGenerationsSelector,
} from 'redux/modules/display-pref/selectors';
import {
  addToAudioTrack,
  clearEntireAudio,
  uploadEntireAudio,
} from 'redux/modules/embed/actions/audio';
import {
  addingAudioSelector,
  entireAudioInstanceIdSelector,
  selectAddingToTrack,
  tracksByIdSelector,
  tracksSelector,
  uploadProgressSelector,
} from 'redux/modules/embed/selectors';
import { podcastDetailEpisodeSelector } from 'redux/modules/entities/selectors';
import { onFileUpload } from 'redux/modules/mixpanel';
import { updateModal } from 'redux/modules/modal/actions';
import { showError } from 'redux/modules/notification/actions';
import { loadMyPricingData } from 'redux/modules/pricing/actions';
import { UploadType as UploadTypeEnum } from 'redux/modules/recording-upload/constants';
import {
  recordingUploadStatusMessageSelector,
  recordingUploadTypeSelector,
} from 'redux/modules/recording-upload/selectors';
import { clearSelectedAudio } from 'redux/modules/sample-audio/actions';
import { selectedAudioSelector } from 'redux/modules/sample-audio/selectors';
import { captionsPrefsSelector } from 'redux/modules/user-pref/selectors';
import { Dispatch, State } from 'redux/types';
import { AddAudioMeta, FileSource } from 'types';
import { ApplicationError } from 'utils/ApplicationError';
import { getDuration } from 'utils/audio';
import {
  DEFAULT_AUDIO_CLIP_DURATION_MILLIS,
  DropZoneType,
} from 'utils/constants';
import { getAudioTrackType } from 'utils/embed/tracks';
import AudioOptionsForm from './AudioOptionsForm';
import { AddAudioModalProps } from './types';
import useActiveTabKey from './useActiveTabKey';
import { block, maxFileSizeMb } from './utils';

const UploadType = UploadTypeEnum as any;

const uploadInProgressSelector = createSelector(
  addingAudioSelector,
  inProgress => inProgress === true,
);

const uploadStatusMessageSelector = createSelector(
  [recordingUploadTypeSelector, recordingUploadStatusMessageSelector],
  (type, message): string =>
    type !== UploadType.TRACK ? undefined : message.message,
);

const defaultWaveGenerationSelector = createSelector(
  defaultWaveformSelector,
  waveform => waveform.get('waveGeneration'),
);

const defaultSoundwaveSelector = createSelector(
  defaultWaveformSelector,
  waveform => waveform.toJS(),
);

const audioTrackTypeSelector = createSelector(
  selectAddingToTrack,
  tracksByIdSelector,
  tracksSelector,
  (trackId, tracksById, trackIds) => {
    if (!trackId) return undefined;
    return getAudioTrackType(trackId, trackIds, tracksById);
  },
);

const getDefaultRegion = (
  durationMillis: number,
  isPodcastSearch: boolean,
): IRegion => {
  const endMillis =
    isPodcastSearch || durationMillis >= 60000 * 10
      ? DEFAULT_AUDIO_CLIP_DURATION_MILLIS
      : durationMillis;

  return {
    startMillis: 0,
    endMillis: Math.round(Math.min(durationMillis, endMillis)),
  };
};

const AddAudioModal: React.FC<AddAudioModalProps> = ({
  className,
  onMount,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { captionEnabled, captionLanguage } = useSelector(
    captionsPrefsSelector,
  );
  const audioTrackType = useSelector(audioTrackTypeSelector);
  const defaultSoundwave = useSelector(defaultSoundwaveSelector);
  const defaultTranscriptionEnabled = useSelector<State, boolean>(state => {
    if (audioTrackType === 'background') {
      return false;
    }
    return defaultTranscriptionEnabledSelector(state) ?? false;
  });
  const defaultWaveGeneration = useSelector<State, string>(state => {
    if (audioTrackType === 'background') {
      return 'amplitudeBased';
    }
    return defaultWaveGenerationSelector(state) ?? 'amplitudeBased';
  });
  const entireAudioInstanceId = useSelector(entireAudioInstanceIdSelector);
  const lockedWaveGeneration = useSelector(
    defaultWaveGenerationReadonlySelector,
  );
  const selectedSampleAudio = useSelector(selectedAudioSelector);
  const uploadInProgress = useSelector(uploadInProgressSelector);
  const uploadStatusMessage = useSelector(uploadStatusMessageSelector);
  const waveGenerationOptions = useSelector(defaultWaveGenerationsSelector);
  const uploadProgress = useSelector(uploadProgressSelector);

  const { show, onHide: onAddAssetHide, onExited } = useAddAssetModal(
    'AddAudioModal',
  );

  const { promise: uploadPromise, setPromise: setUploadPromise } = usePromise<
    void
  >();

  const { pause, play, playing } = useMediaPlayback();

  const videoEditorPlaybackTimeContext = useVideoEditorPlaybackTimeContext();
  const defaultStartMillis = videoEditorPlaybackTimeContext.positionSec * 1000;

  const prevShow = usePrevious(show);
  const prevSelectedSampleAudio = usePrevious(selectedSampleAudio);

  const clipperRef = useRef<AudioClipperComponent>();
  const steppedModalRef = useRef<SteppedModal>();

  const [
    activeTabKey,
    setActiveTabKey,
    defaultActiveTabKey,
  ] = useActiveTabKey();
  const [audioSrc, setAudioSrc] = useState<string | File>(undefined);
  const [clipEndMillis, setClipEndMillis] = useState<number>(undefined);
  const [clipStartMillis, setClipStartMillis] = useState<number>(undefined);
  const [fileSource, setFileSource] = useState<FileSource>(undefined);
  const [language, setLanguage] = useState<string>('en-US');
  const [startMillis, setStartMillis] = useState<number>(defaultStartMillis);
  const [transcriptionEnabled, setTranscriptionEnabled] = useState<boolean>(
    audioTrackType === 'background' ? false : defaultTranscriptionEnabled,
  );
  const [waveGeneration, setWaveGeneration] = useState<string>(
    audioTrackType === 'background' ? 'amplitudeBased' : defaultWaveGeneration,
  );
  const [episodeId, setEpisodeId] = useState<string>(undefined);
  const episode = useSelector(
    useMemo(() => podcastDetailEpisodeSelector(episodeId), [episodeId]),
  );
  const waitingForStandardization = useSelector(
    waitingForStandardizationSelector,
  );

  const handleRegionUpdate = useCallback(
    ({ startMillis: clipStartInMillis, endMillis: clipEndInMillis }) => {
      setClipStartMillis(clipStartInMillis);
      setClipEndMillis(clipEndInMillis);
    },
    [setClipStartMillis, setClipEndMillis],
  );

  useEffect(() => {
    if (show) {
      dispatch(loadMyPricingData());
    }
  }, [dispatch, show]);

  useEffect(() => {
    let cancelled = false;
    if (audioSrc) {
      getDuration(audioSrc, (duration, err) => {
        if (!err && !cancelled) {
          const defaultRegion = getDefaultRegion(duration * 1000, !!episodeId);
          handleRegionUpdate(defaultRegion);
        }
      });
    }
    return () => {
      cancelled = true;
    };
  }, [audioSrc, episodeId, handleRegionUpdate]);

  const withSourceFile = useCallback(
    <T extends any>(fn: (source: File) => T): T => {
      if (audioSrc && !isString(audioSrc)) {
        return fn(audioSrc);
      }
      return undefined;
    },
    [audioSrc],
  );

  const resetDefaultFile = useCallback(() => {
    dispatch(
      updateModal({
        name: 'AddAudioModal',
        params: { file: null },
      }),
    );
  }, [dispatch]);

  const createDefaultState = useCallback(
    ({
      startMillis: startInMillis,
      activeTab = defaultActiveTabKey,
      isTranscriptionEnabled = defaultTranscriptionEnabled,
      waveGen = defaultWaveGeneration,
    }) => {
      setActiveTabKey(activeTab);
      setStartMillis(startInMillis);
      setTranscriptionEnabled(isTranscriptionEnabled);
      setWaveGeneration(waveGen);
      setAudioSrc(undefined);
      setClipEndMillis(undefined);
      setClipStartMillis(undefined);
      setFileSource(undefined);
      setLanguage('en-US');

      if (
        (captionEnabled || captionLanguage) &&
        audioTrackType !== 'background'
      ) {
        setTranscriptionEnabled(captionEnabled);
        setLanguage(captionLanguage);
      }

      pause();
    },
    [
      defaultActiveTabKey,
      defaultTranscriptionEnabled,
      defaultWaveGeneration,
      setActiveTabKey,
      captionEnabled,
      captionLanguage,
      audioTrackType,
      pause,
    ],
  );

  const cancelAudioUpload = useCallback(() => {
    if (uploadPromise) {
      (uploadPromise as any).cancel?.();
      setUploadPromise(undefined);
    }
  }, [setUploadPromise, uploadPromise]);

  const reset = useCallback(
    (nextStartMillis?: number) => {
      cancelAudioUpload();

      createDefaultState({
        activeTab: activeTabKey,
        startMillis: nextStartMillis || defaultStartMillis,
      });

      dispatch(clearSelectedAudio());
    },
    [
      activeTabKey,
      cancelAudioUpload,
      createDefaultState,
      defaultStartMillis,
      dispatch,
    ],
  );

  const handleDropAccepted = useCallback(
    (src: File | string, source: FileSource, metadata?: AddAudioMeta) => {
      setAudioSrc(src);
      setFileSource(source);
      setEpisodeId(metadata?.episodeId);

      if (src instanceof File) {
        dispatch(
          onFileUpload({
            dropZoneType: DropZoneType.EDITOR_AUDIO,
            source: 'localFile',
            file: src,
          }),
        );
      }

      dispatch(clearEntireAudio());
      setUploadPromise(dispatch(uploadEntireAudio(src, metadata)));
      resetDefaultFile();

      steppedModalRef.current.stepHistory.push('form');
    },
    [dispatch, resetDefaultFile, setUploadPromise],
  );

  const handleUploadError = useCallback(
    (error: ApplicationError, file: File, isPodcast?: boolean) => {
      resetDefaultFile();
      if (isPodcast) {
        dispatch(
          showError(
            'Please try a different episode instead',
            5,
            undefined,
            `Sorry, that episode was over ${maxFileSizeMb}MB`,
          ),
        );
      } else {
        dispatch(
          onFileUpload({
            dropZoneType: DropZoneType.EDITOR_AUDIO,
            source: 'localFile',
            file,
            error: error.message,
          }),
        );
        dispatch(
          showError({
            message: error.message,
            code: error.code,
            dismissAfterSec: 5,
          }),
        );
      }
    },
    [dispatch, resetDefaultFile],
  );

  useOnMount(onMount);

  useEffect(() => {
    // if modal is being opened, but not uploading, reset state
    if (show && !prevShow && !uploadInProgress) {
      steppedModalRef.current?.stepHistory.replace('select');

      reset(startMillis);
    }

    if (
      !prevSelectedSampleAudio?.get('file') &&
      selectedSampleAudio?.get('file')
    ) {
      handleDropAccepted(selectedSampleAudio.get('file'), FileSource.LIBRARY);
    }
  }, [
    handleDropAccepted,
    prevSelectedSampleAudio,
    prevShow,
    reset,
    show,
    startMillis,
    selectedSampleAudio,
    uploadInProgress,
  ]);

  const handleHide = useCallback(() => {
    onAddAssetHide();
    if (!uploadInProgress) {
      reset();
    }
  }, [onAddAssetHide, reset, uploadInProgress]);

  const handleExited = () => {
    steppedModalRef.current.stepHistory.replace('select');
    onExited();
  };

  const handleAddAudioClick = useCallback(async () => {
    const clip = {
      endMillis: clipEndMillis,
      originalDurationMillis: clipperRef.current.clip.originalDurationMillis,
      startMillis: clipStartMillis,
    };
    pause();
    await dispatch(
      addToAudioTrack(
        clip,
        startMillis,
        language,
        transcriptionEnabled,
        waveGeneration,
      ),
    );
    handleHide();
  }, [
    clipEndMillis,
    clipStartMillis,
    dispatch,
    handleHide,
    language,
    pause,
    startMillis,
    transcriptionEnabled,
    waveGeneration,
  ]);

  const handleStepChange = (__, fromStep: string) => {
    if (fromStep === 'form') {
      reset();
    }
  };

  const renderClipper = () => {
    if (!audioSrc) return null;

    const showTranscriptionSettings =
      audioTrackType !== 'background' &&
      (fileSource === FileSource.UPLOAD || fileSource === FileSource.PODCAST);

    return (
      <AsyncAudioClipper
        className={block('clipper')}
        disabled={uploadInProgress}
        entireAudioInstanceId={entireAudioInstanceId}
        onPlay={play}
        onPause={pause}
        onStop={pause}
        onRegionUpdate={handleRegionUpdate}
        region={{ startMillis: clipStartMillis, endMillis: clipEndMillis }}
        playing={playing}
        ref={clipperRef}
        src={audioSrc}
        estimatedDurationSec={episode?.durationSec}
        proxySrc={episode?.proxyAudioUrl}
        wavesurferClassName={block('add-audio-waveform')}
        uploadProgress={uploadProgress}
        captionsControl={
          showTranscriptionSettings && (
            <TranscriptionForm
              disabled={uploadInProgress}
              durationMillis={clipEndMillis - clipStartMillis}
              onChange={value => {
                setTranscriptionEnabled(value.transcribe);
                setLanguage(value.language);
              }}
              value={{ language, transcribe: transcriptionEnabled }}
            >
              <TranscriptionForm.Toggle />
              <TranscriptionForm.LanguageSelect />
            </TranscriptionForm>
          )
        }
      />
    );
  };

  const footerButtonsDisabled: FooterButtonsDisabled = action => {
    const disabled =
      selectedSampleAudio?.isFetching ||
      (audioSrc &&
        (isUndefined(clipStartMillis) || isUndefined(clipEndMillis)));

    if (action === FooterButtonType.CANCEL) {
      return disabled;
    }

    return (
      uploadInProgress ||
      disabled ||
      isUndefined(entireAudioInstanceId) ||
      waitingForStandardization
    );
  };

  const modalClassName = classNames(block({ added: !!audioSrc }), className);

  return (
    <SteppedModal
      defaultStep="select"
      baseClassName={block()}
      show={show}
      onHide={handleHide}
      onExited={handleExited}
      onStepChange={handleStepChange}
      footerButtonsDisabled={footerButtonsDisabled}
      ref={steppedModalRef}
      steps={[
        {
          component: (
            <SelectAudioTabs
              onUploadError={handleUploadError}
              statusMessage={uploadStatusMessage}
              onDropAccepted={handleDropAccepted}
              activeTabKey={activeTabKey}
              onTabSelect={setActiveTabKey}
            />
          ),
          id: 'select',
          modalClassName,
          renderFooterButtons: ({ cancel }) => [
            <Modal.FooterButton {...cancel} key="cancel">
              Cancel
            </Modal.FooterButton>,
          ],
        },
        {
          component: (
            <AudioOptionsForm
              audioTrackType={audioTrackType}
              className={block('options')}
              startMillis={startMillis}
              onStartTimeChange={setStartMillis}
              renderClipper={renderClipper}
              uploadInProgress={uploadInProgress}
              fileName={
                episodeId ? episode?.title : withSourceFile(f => f.name)
              }
              waveGeneration={waveGeneration}
              onWaveGenerationChange={setWaveGeneration}
              defaultSoundwave={defaultSoundwave}
              lockedWaveGeneration={lockedWaveGeneration}
              waveGenerationOptions={waveGenerationOptions}
            />
          ),
          id: 'form',
          modalClassName,
          onSubmit: handleAddAudioClick,
          submitButtonLabel: 'Add to video',
        },
      ]}
      title="Add audio"
    />
  );
};

export default AddAudioModal;

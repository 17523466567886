import { Music } from '@sparemin/blockhead';
import React from 'react';

import { AnimationModule } from '../types';
import useTextOverlayAdvancedAnimation from '../useTextOverlayAdvancedAnimation';
import { checkIsKaraokeAnimation } from '../utils';
import AnimationModuleButton from './AnimationModuleButton';
import KaraokeAnimationControls from './KaraokeAnimationControls';

export interface KaraokeAnimationModuleProps {}

const KaraokeAnimation: React.FC<KaraokeAnimationModuleProps> = () => {
  const {
    selectedAdvancedAnimation,
    onTurnKaraokeAnimationOn,
  } = useTextOverlayAdvancedAnimation();

  return (
    <AnimationModuleButton
      label="Karaoke"
      icon={<Music width={50} height={50} />}
      isSelected={
        selectedAdvancedAnimation?.enabled &&
        checkIsKaraokeAnimation(selectedAdvancedAnimation)
      }
      onPress={onTurnKaraokeAnimationOn}
    />
  );
};

const karaokeAnimationModule: AnimationModule = {
  key: 'karaoke',
  name: 'Karaoke',
  control: KaraokeAnimation,
  contents: <KaraokeAnimationControls />,
};

export default karaokeAnimationModule;

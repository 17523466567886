import { Container, Text, ThemeProvider } from '@sparemin/blockhead';
import React from 'react';
import { block } from '../utils';
import EddyProjectCreationContents from './EddyProjectCreationContents';
import EddyProjectCreationUnsupportedBanner from './EddyProjectCreationUnsupportedBanner';

export interface EddyProjectCreationSelectorProps {
  isLanguageSupported: boolean;
  shouldGenerateAssets: boolean;
  onShouldGenerateAssetsChange: (shouldGenerateAssets: boolean) => void;
}

const EddyProjectCreationSelector: React.FC<EddyProjectCreationSelectorProps> = ({
  isLanguageSupported,
  shouldGenerateAssets = true,
  onShouldGenerateAssetsChange,
}) => {
  return (
    <ThemeProvider theme="dark">
      {isLanguageSupported ? (
        <Container className={block('creation-selector')}>
          <EddyProjectCreationContents
            {...{
              shouldGenerateAssets,
              onShouldGenerateAssetsChange,
            }}
            title="Add FREE promo assets!"
            description={
              <Text
                variant="p"
                align="center"
                className={block('creation-description')}
              >
                At no additional charge, we can create a <b>transcript</b>,{' '}
                <b>episode art</b>, <b>keywords</b>, <b>show notes</b>,{' '}
                <b>social post captions</b>, <b>quotes</b> & much more!
              </Text>
            }
            imageSrc="https://static.sparemin.com/static/eddy/promopack-popper-header-image-202312121904.jpg"
          />
        </Container>
      ) : (
        <EddyProjectCreationUnsupportedBanner />
      )}
    </ThemeProvider>
  );
};

export default EddyProjectCreationSelector;

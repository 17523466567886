import { connect } from 'react-redux';

import { onFileUpload, onWatchInfoVideo } from 'redux/modules/mixpanel';
import { fullEpisodeDurationLimitHoursSelector } from 'redux/modules/pricing';
import { Dispatch, State } from 'redux/types';
import { getAudioMaxDurationSeconds } from 'utils/audiopicker';
import { DropZoneType } from 'utils/constants';
import { createChainedFunction } from 'utils/functions';

import EpisodeAddAudioStep, {
  EpisodeAddAudioStepProps,
} from './EpisodeAddAudioStep';

type StateProps = Pick<
  EpisodeAddAudioStepProps,
  'maxFileDurationSeconds' | 'header'
>;
type DispatchProps = Pick<
  EpisodeAddAudioStepProps,
  'onAudioAdded' | 'onSampleVideoPlay'
>;

type OwnProps = Pick<EpisodeAddAudioStepProps, 'onAudioAdded'>;

const mapStateToProps = (state: State, props: OwnProps): StateProps => {
  return {
    ...props,
    maxFileDurationSeconds: getAudioMaxDurationSeconds(
      fullEpisodeDurationLimitHoursSelector(state),
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onAudioAdded: createChainedFunction(props.onAudioAdded, src => {
    if (src instanceof File) {
      dispatch(
        onFileUpload({
          dropZoneType: DropZoneType.EPISODE,
          source: 'localFile',
          file: src,
        }),
      );
    }
  }),
  onSampleVideoPlay: () => dispatch(onWatchInfoVideo('episode')),
});

const component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EpisodeAddAudioStep);
export type EpisodeAddAudioProps = React.ComponentPropsWithoutRef<
  typeof component
>;
export default component;

import { sendDownloadPageActionEvent } from 'redux/modules/download';
import {
  onClickCloudSave,
  onClickCopyVideoUrl,
  onClickDownloadVideo,
  onClickPostFacebookVideo,
  onClickPostLinkedinVideo,
  onClickPostThreadsVideo,
  onClickPostTikTokVideo,
  onClickPostYoutubeVideo,
  onClickTweetVideo,
} from 'redux/modules/mixpanel';
import { DownloadPageEventHandlers } from './types';

export const MIXPANEL_EVENTS_BY_ACTION: DownloadPageEventHandlers = {
  cloudUpload: onClickCloudSave,
  videoLinkCopy: onClickCopyVideoUrl,
  downloadVideo: onClickDownloadVideo,
  facebookShare: onClickPostFacebookVideo,
  instagramShare: sendDownloadPageActionEvent,
  linkedinShare: onClickPostLinkedinVideo,
  threadsShare: onClickPostThreadsVideo,
  tiktokShare: onClickPostTikTokVideo,
  twitterShare: onClickTweetVideo,
  youtubeShare: onClickPostYoutubeVideo,
};

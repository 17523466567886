import cn from 'classnames';
import * as React from 'react';

import { ClosedCaptioning } from 'components/icons';
import { AutogramVideoType } from 'types';
import OptionChildView, { OptionChildViewProps } from '../OptionChildView';
import { captionsViewBlock as block } from '../utils';
import CaptionsChildViewContents from './CaptionsChildViewContents';
import useCaptionsChildViewContentsProps from './useCaptionsChildViewContentsProps';

type PickedChildViewProps = Pick<
  OptionChildViewProps,
  'active' | 'onClose' | 'className'
>;

export interface CaptionsChildViewProps extends PickedChildViewProps {
  videoType?: AutogramVideoType;
}

const CaptionsChildView: React.FC<CaptionsChildViewProps> = ({
  active,
  videoType,
  className,
  onClose,
}) => {
  const captionsChildViewContentsProps = useCaptionsChildViewContentsProps({
    videoType,
  });

  return (
    <OptionChildView
      title="captions"
      icon={<ClosedCaptioning style={{ width: 24, height: 'auto' }} />}
      active={active}
      className={cn(className, block())}
      bodyClassName={block('body')}
      onClose={onClose}
    >
      <CaptionsChildViewContents {...captionsChildViewContentsProps} />
    </OptionChildView>
  );
};

export default CaptionsChildView;

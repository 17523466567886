import * as React from 'react';

import CropperModal from 'components/CropperModal';
import { useFontFamilyProIndicator } from 'components/TextToolbar';
import EditCaptionsModal from 'containers/EditCaptionsModal';
import { getValue } from 'utils/collections';
import { createChainedFunction } from 'utils/functions';
import { useEditorDispatch } from '../context/VideoEditorDispatchContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import AddImageTypeSelectionModal from './AddImageTypeSelectionModal';
import AddMediaModal from './AddMediaModal';
import AddTextTypeSelectionModal from './AddTextTypeSelectionModal/AddTextTypeSelectionModal';
import AddWatermarkModal from './AddWatermarkModal';
import DeleteAssetModal from './DeleteAssetModal';
import DeleteDynamicImageModal from './DeleteDynamicImageModal';
import EditEffectTextModal from './EditEffectTextModal';
import EditorModalStaticBackground from './EditorModalStaticBackground';
import { ModalManagerProvider } from './ModalManagerContext';
import TextAssetModal, { TextAssetModalType } from './TextAssetModal';
import { ModalProps, ModalType, ShowModal } from './types';
import VideoTemplateAddMediaModal from './VideoTemplateAddMediaModal';

const { useCallback, useState } = React;

export interface ModalManagerProps {
  children?: React.ReactNode;
}

interface ModalState {
  active: boolean;
  type: ModalType;
  props: ModalProps;
}

function getTextModalType(modalType: ModalType): TextAssetModalType {
  if (modalType === 'add-text') {
    return 'add';
  }

  if (modalType === 'edit-text') {
    return 'edit';
  }

  return undefined;
}

const ModalManager: React.FC<ModalManagerProps> = ({ children }) => {
  const [modals, setModals] = useState<ModalState[]>([]);
  const { aspectRatio } = useTemplateState();
  const dispatch = useEditorDispatch();

  const modal = modals[0];
  const modalProps = modal?.props;
  const modalType = modal?.type;
  const show = modal?.active;

  const handleModalExited = useCallback(() => {
    setModals(([, ...rest]) => rest);
  }, []);

  const onExited = createChainedFunction(
    getValue(modalProps, 'onExited'),
    handleModalExited,
  );

  const onCancel = getValue(modalProps, 'onCancel');

  const closeModal = useCallback(() => {
    dispatch({ type: 'OVERLAY_CLOSE' });
    setModals(([first, ...rest]) => [{ ...first, active: false }, ...rest]);
  }, [dispatch]);

  const handleCancel = useCallback(() => {
    closeModal();
    onCancel?.();
  }, [closeModal, onCancel]);

  const showModal: ShowModal = useCallback(
    (type, props) => {
      if (modalType === undefined) {
        dispatch({ type: 'OVERLAY_OPEN' });
      }
      setModals(ms => [
        ...ms,
        {
          active: true,
          type,
          props,
        },
      ]);
    },
    [dispatch, modalType],
  );

  useFontFamilyProIndicator('TextEditorModalManager', closeModal);

  const getProp = (...path: string[]) => getValue(modalProps, path);
  const textModalType = getTextModalType(modalType);

  return (
    <>
      <DeleteAssetModal
        assetName={getProp('assetName')}
        onCancel={handleCancel}
        onDelete={createChainedFunction(getProp('onDelete'), closeModal)}
        onExited={onExited}
        show={modalType === 'delete' && show}
        title={getProp('title')}
      />
      <CropperModal
        title={getProp('mediaType') === 'image' ? 'Crop Image' : 'Crop Video'}
        aspectRatio={aspectRatio}
        defaultConstrainImage={getProp('defaultMetadata', 'constrained')}
        defaultMetadata={getProp('defaultMetadata')}
        allowConstrainControl={getProp('allowConstrainControl')}
        fileType={getProp('fileType')}
        imgSrc={getProp('imgSrc')}
        onCancel={handleCancel}
        onExited={onExited}
        onSubmit={createChainedFunction(getProp('onSubmit'), closeModal)}
        show={modalType === 'crop' && show}
      />
      <TextAssetModal
        assetId={getProp('assetId')}
        banner={getProp('banner')}
        editable={getProp('editable')}
        isLegacy={getProp('isLegacy')}
        isV2CompatibilityMode={getProp('isV2CompatibilityMode')}
        onCancel={handleCancel}
        onDelete={createChainedFunction(getProp('onDelete'), closeModal)}
        onExited={onExited}
        onSwitchModal={getProp('onSwitchModal')}
        onSubmit={createChainedFunction(getProp('onSubmit'), closeModal)}
        show={(modalType === 'add-text' || modalType === 'edit-text') && show}
        showEmoji={getProp('showEmoji')}
        subTitle={getProp('subTitle')}
        textOverlay={getProp('textOverlay')}
        title={getProp('title')}
        type={textModalType}
      />
      <EditEffectTextModal
        id={getProp('id')}
        onExited={onExited}
        onHide={handleCancel}
        onSubmit={createChainedFunction(getProp('onSubmit'), closeModal)}
        show={modalType === 'edit-effect-text' && show}
      />
      <AddMediaModal
        onCancel={handleCancel}
        onClick={getProp('onClick')}
        onExited={onExited}
        show={modalType === 'add-image' && show}
      />
      <AddWatermarkModal
        onCancel={handleCancel}
        onFileAccepted={getProp('onFileAccepted')}
        onFileRejected={getProp('onFileRejected')}
        onExited={onExited}
        show={modalType === 'add-watermark' && show}
      />
      <DeleteDynamicImageModal
        details={getProp('details')}
        icon={getProp('icon')}
        onCancel={handleCancel}
        onExited={onExited}
        onSubmit={createChainedFunction(getProp('onSubmit'), closeModal)}
        show={modalType === 'delete-dynamic-image' && show}
        title={getProp('title')}
      />
      <AddImageTypeSelectionModal
        onCancel={handleCancel}
        onExited={onExited}
        onSubmit={createChainedFunction(getProp('onSubmit'), closeModal)}
        show={modalType === 'add-image-type' && show}
      />
      <AddTextTypeSelectionModal
        onCancel={handleCancel}
        onExited={onExited}
        onSubmit={getProp('onSubmit')}
        show={modalType === 'add-text-type' && show}
      />
      <VideoTemplateAddMediaModal
        aspectRatio={aspectRatio}
        onAnimateGatedClick={closeModal}
        onCancel={handleCancel}
        onExited={onExited}
        onSubmit={getProp('onSubmit')}
        show={modalType === 'add-media-modal' && show}
      />
      <EditCaptionsModal
        aspectRatio={aspectRatio}
        background={
          <EditorModalStaticBackground
            assetId={getProp('assetId')}
            isCaptionsEditor
          />
        }
        captionsOverride={getProp('captionsOverride')}
        animationsTabEnabled={getValue(modalProps, 'animationsTabEnabled')}
        animationsTabDisplayConfig={getValue(
          modalProps,
          'animationsTabDisplayConfig',
        )}
        onCancel={handleCancel}
        onExited={onExited}
        onSubmit={getProp('onSubmit')}
        show={modalType === 'edit-captions' && show}
      />
      <ModalManagerProvider hideModal={handleCancel} showModal={showModal}>
        {children}
      </ModalManagerProvider>
    </>
  );
};

export default ModalManager;

import cn from 'classnames';
import React, { useCallback, useRef } from 'react';
import bem from 'utils/bem';

const block = bem('icon-button');

type IconButtonProps = React.AllHTMLAttributes<HTMLButtonElement> & {
  disabled?: boolean;
  // FIXME: a little confusing that this has both onClick and onButtonClick (see index.ts)
  onButtonClick?: (
    params: any,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  onLongButtonClick?: (
    params: any,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  params?: any;
  className?: string;
  theme?: 'default' | 'primary';
};

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      children,
      className,
      onButtonClick,
      onLongButtonClick,
      params,
      theme,
      ...rest
    },
    ref,
  ) => {
    const longPressDelayRef = useRef<number | undefined>(undefined);
    const longPressTimerRef = useRef<number | undefined>(undefined);

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        onButtonClick(params, event);
      },
      [onButtonClick, params],
    );

    const handleLongButtonClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        onLongButtonClick(params, event);
      },
      [onLongButtonClick, params],
    );

    const handleMouseDown = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onLongButtonClick) {
          longPressDelayRef.current = window.setTimeout(() => {
            longPressTimerRef.current = window.setInterval(() => {
              handleLongButtonClick(event);
            }, 5);
          }, 200);
        }
      },
      [handleLongButtonClick, onLongButtonClick],
    );

    const handleMouseUp = useCallback(() => {
      window.clearInterval(longPressTimerRef.current);
      window.clearTimeout(longPressDelayRef.current);
    }, []);

    return (
      <button
        {...rest}
        className={cn(block({ [theme]: !!theme }), className)}
        onClick={handleClick}
        type="button"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        ref={ref}
      >
        {children}
      </button>
    );
  },
);

export default IconButton;
export { IconButtonProps };

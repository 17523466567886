import classNames from 'classnames';
import * as React from 'react';

// icons
import {
  SocialSolidCircleFacebook,
  SocialSolidCircleInstagram,
  SocialSolidCircleTikTok,
  SocialSolidCircleX,
} from 'components/icons';

// utils
import { block } from './utils';

export interface IProps {
  isEnterpriseUser: boolean;
  onClickMarketingLink: () => void;
}

const SocialIconsBottomBar: React.FC<IProps> = ({
  isEnterpriseUser,
  onClickMarketingLink,
}) => (
  <div className={block()}>
    <div className={classNames('container-fluid', block('container'))}>
      <div className={block('icons')}>
        <a
          href="https://twitter.com/headlinerapp_"
          rel="noopener noreferrer"
          target="_blank"
          className={block('icon')}
        >
          <SocialSolidCircleX />
        </a>

        <a
          href="https://www.instagram.com/headliner.app/"
          rel="noopener noreferrer"
          target="_blank"
          className={block('icon')}
        >
          <SocialSolidCircleInstagram />
        </a>
        <a
          href="https://www.facebook.com/headlinerapp/"
          rel="noopener noreferrer"
          target="_blank"
          className={block('icon')}
        >
          <SocialSolidCircleFacebook />
        </a>
        <a
          href="https://www.tiktok.com/@headlinerapp"
          rel="noopener noreferrer"
          target="_blank"
          className={block('icon')}
        >
          <SocialSolidCircleTikTok />
        </a>
      </div>

      {!isEnterpriseUser && (
        <div className={block('marketing')}>
          Get Pro for Free:{' '}
          <a href="/settings/referrals" onClick={onClickMarketingLink}>
            Refer Friends Now
          </a>
        </div>
      )}

      <div className={block('app-icons')}>
        <a
          href="https://play.google.com/store/apps/details?id=app.headliner.headliner"
          rel="noopener noreferrer"
          target="_blank"
          className={block('icon-app')}
        >
          <img
            src="https://static.sparemin.com/static/webapp-assets/google-play-201912101448.png"
            className={block('icon-app-image')}
          />
        </a>
        <a
          href="https://apps.apple.com/us/app/headliner-video/id1485577013"
          rel="noopener noreferrer"
          target="_blank"
          className={block('icon-app')}
        >
          <img
            src="https://static.sparemin.com/static/webapp-assets/apple-app-store-201912101449.png"
            className={block('icon-app-image')}
          />
        </a>
      </div>
    </div>
  </div>
);

export default SocialIconsBottomBar;

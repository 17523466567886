import cn from 'classnames';
import React, { useContext } from 'react';
import { block } from './utils';
import { WaveSurferContainerContext } from './WaveSurferContext';

interface Props {
  height?: number;
  className?: string;
  children?: React.ReactNode;
}

const WaveSurferContainer = (props: Props) => {
  const { className, children } = props;
  const setContainerEl = useContext(WaveSurferContainerContext);

  return (
    <div className={cn(block(), className)}>
      <div className={block('container')} ref={setContainerEl}>
        {children}
      </div>
    </div>
  );
};

export default WaveSurferContainer;

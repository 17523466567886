import qs, { ParsedUrl } from 'query-string';
import { SPAREMIN_SERVICES_EMBED_URL } from 'config';
import { CreateEmbedPreviewUrlProps } from './types';

export function createEmbedPreviewUrl({
  headlinerWatermarkEnabled = false,
  recordingId,
  token,
  widgetId,
}: CreateEmbedPreviewUrlProps) {
  const query: ParsedUrl['query'] = {
    _controlVisibility: 'hide',
    access_token: token,
    headlinerWatermarkEnabled: String(headlinerWatermarkEnabled),
    hideAvatars: 'true',
    includeFS: 'true',
    widgetId,
  };

  if (recordingId !== undefined) {
    query.r = String(recordingId);
  }

  return qs.stringifyUrl({
    query,
    url: `${SPAREMIN_SERVICES_EMBED_URL}/embed`,
  });
}

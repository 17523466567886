import * as captions from './captions';

export * from './captions';
export {
  BASE_CAPTION_OVERRIDES_MAP,
  DEFAULT_ADVANCED_TEXT_CONFIGS,
} from './constants';
export { isCaptionsAnimationsTabDisabled } from './utils';
export { Style } from './types';

export default { captions };

import React from 'react';
import { Blur } from 'components/icons';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import useMainMedia from '../useMainMedia';
import ControlToggle from './ControlToggle';

export interface VideoPodcastSettingsBlurToggleProps {}

const VideoPodcastSettingsBlurToggle: React.FC<VideoPodcastSettingsBlurToggleProps> = () => {
  const { asset } = useMainMedia();

  const dispatch = useTemplateDispatch();

  const handleBlurChange = React.useCallback(
    (enableBlur: boolean): void => {
      dispatch({
        type: 'VIDEO_BLUR_CHANGE',
        payload: {
          id: asset?.id,
          enableBlur,
        },
      });
    },
    [asset, dispatch],
  );

  return (
    <ControlToggle
      icon={<Blur />}
      isActive={asset?.enableBlur}
      label="Background blur"
      onChange={handleBlurChange}
    />
  );
};

export default VideoPodcastSettingsBlurToggle;

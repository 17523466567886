import React, { useEffect, useState } from 'react';

import { Picture } from 'components/icons';
import useObjectUrl from 'hooks/useObjectUrl';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { useNavigation } from '../context/NavigationContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import OptionChildView, { OptionChildViewProps } from '../OptionChildView';
import { MediaIntegrationId, Slide, VideoClip } from '../types';
import useMainMedia from '../useMainMedia';
import { imageViewBlock as block } from '../utils';
import VideoPodcastSettingsBlurToggle from '../VideoPodcastSettings/VideoPodcastSettingsBlurToggle';
import DynamicAssetChildContents from './DynamicAssetChildContents';
import ImageBlurInput from './ImageBlurInput';
import StaticImageChildContents from './StaticImageChildContents';

export type ImageChildViewProps = Pick<
  OptionChildViewProps,
  'active' | 'onClose'
>;

const TITLE_ALLOWED_INTEGRATION_IDS = [
  MediaIntegrationId.GIF,
  MediaIntegrationId.UPLOAD,
  MediaIntegrationId.VIDEOCLIP,
];

function getTitle(slide: Slide | VideoClip): string {
  const { integrationData, fileName } = slide ?? {};
  const fallback = 'Media';
  if (TITLE_ALLOWED_INTEGRATION_IDS.includes(integrationData?.id)) {
    return fileName ?? fallback;
  }

  return fallback;
}

const ImageChildView: React.FC<ImageChildViewProps> = ({ active, onClose }) => {
  const [, send] = useNavigation();
  const mainMedia = useMainMedia();
  const [isDynamic, setIsDynamic] = useState<boolean | undefined>(undefined);
  const { aspectRatio } = useTemplateState();

  // initializes thubnail src, integration and title
  const { integration } = mainMedia;
  let thumbnailSrc;
  let title;

  // for images thubnail is obtained from asset imageSrc
  if (mainMedia.type === 'image') {
    const { asset } = mainMedia;
    thumbnailSrc = asset.imageSrc;
    title = getTitle(asset);
    // for video clips thumbnail is obtained from the upload preview thumbnail
  } else if (mainMedia.type === 'videoClip') {
    const { asset } = mainMedia;
    thumbnailSrc = asset.original.previewThumbnail?.url;
    title =
      integration.creationType === 'dynamic' && integration.type === 'video'
        ? integration.childViewProps.title
        : getTitle(asset);
  }

  const imageUrl = useObjectUrl(thumbnailSrc);

  useEffect(() => {
    if (!thumbnailSrc && active) {
      send({ type: 'CHILD_VIEW_CLOSE' });
    }
  }, [active, thumbnailSrc, send]);

  // prevent isDynamic from changing when an image is deleted so that the contents
  // of the child view doesn't change from dynamic to static when a dynamic
  // image is deleted and the closing animation is active
  useEffect(() => {
    if (imageUrl) {
      setIsDynamic(integration?.creationType === 'dynamic');
    }
  }, [imageUrl, integration, isDynamic]);

  return (
    <OptionChildView
      {...{ active }}
      onClose={onClose}
      className={block()}
      icon={<Picture style={{ height: 22 }} />}
      title={title}
    >
      <div
        className={block('background-image')}
        style={{
          background: [
            'linear-gradient(to bottom, rgba(50, 55, 50, 0.75), #323746)',
            imageUrl && `url(${imageUrl}) center center / cover no-repeat`,
          ]
            .filter(Boolean)
            .join(','),
        }}
      />
      <div className={block('contents')}>
        {isDynamic ? (
          <DynamicAssetChildContents integration={integration} />
        ) : (
          <StaticImageChildContents />
        )}

        {mainMedia.type === 'videoClip' &&
          getAspectRatioName(aspectRatio) === 'landscape' && (
            <VideoPodcastSettingsBlurToggle />
          )}

        {mainMedia.type !== 'videoClip' && <ImageBlurInput />}
      </div>
    </OptionChildView>
  );
};

export default ImageChildView;

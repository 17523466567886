import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onClickFullEpisodeBackCatalogGatedCard } from 'redux/modules/mixpanel';
import { pushModal } from 'redux/modules/modal';
import { isBasicOrFreeOrEducationNonprofitSelector } from 'redux/modules/pricing';
import EpisodesCardOptions from './EpisodesCardOptions';
import { EpisodeOption } from './types';

interface EpisodesStepProps {
  isFullEpisode: boolean;
  onClick: (selected: EpisodeOption) => void;
}

const EpisodesStep: React.FC<EpisodesStepProps> = ({
  isFullEpisode,
  onClick,
}) => {
  const dispatch = useDispatch();
  const isFreeTier = useSelector(isBasicOrFreeOrEducationNonprofitSelector);

  const handleUpgradeToUnlockClick = useCallback((): void => {
    dispatch(onClickFullEpisodeBackCatalogGatedCard());
    dispatch(
      pushModal({
        name: 'BackCatalogUpsell',
      }),
    );
  }, [dispatch]);

  const handleTargetedSeasonsClick = useCallback((): void => {
    if (!isFullEpisode) {
      return;
    }

    if (isFreeTier) {
      handleUpgradeToUnlockClick();

      return;
    }

    onClick('targetedSeason');
  }, [handleUpgradeToUnlockClick, isFreeTier, isFullEpisode, onClick]);

  const handleOnPublishClick = useCallback((): void => {
    onClick('onPublish');
  }, [onClick]);

  return (
    <EpisodesCardOptions
      isFreeTier={isFreeTier}
      isDisabled={!isFullEpisode}
      onTargetedSeasonsClick={handleTargetedSeasonsClick}
      onPublishClick={handleOnPublishClick}
    />
  );
};

export default EpisodesStep;

import {
  Container,
  Heading,
  Link,
  Spacer,
  ThemeProvider,
  WarningIcon,
} from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useClipSelect } from '../../ClipSelectContext';
import { block } from './utils';

export type ClipOutdatedWarningProps = {
  clipId: number;
};

const ClipOutdatedWarning: React.FC<ClipOutdatedWarningProps> = ({
  clipId,
}) => {
  const { onReprocessClip } = useClipSelect();

  const handleReprocess = useCallback(() => {
    onReprocessClip(clipId);
  }, [clipId, onReprocessClip]);

  return (
    <ThemeProvider theme="dark">
      <Container
        align="start"
        as={Spacer}
        borderWeight="2px"
        className={block('warning-banner')}
        cornerRadius="10px"
        gutter={0}
        orientation="horizontal"
      >
        <div className={block('warning-banner-badge')}>
          <WarningIcon height="16px" width="16px" />
        </div>
        <Heading level={4} className={block('warning-banner-content')}>
          These captions are out of date:
          <Link
            as="button"
            elementType="button"
            onPress={handleReprocess}
            style={{ marginLeft: 2 }}
            variant="inherit"
          >
            update clip
          </Link>
        </Heading>
      </Container>
    </ThemeProvider>
  );
};

export default ClipOutdatedWarning;

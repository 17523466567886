import { Button } from '@sparemin/blockhead';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { headerBlock as block } from '../../utils';
import { useClipSelectNavigation } from '../ClipSelectNavigationContext';
import HeaderItem from './HeaderItem';

export interface BackToSuggestionsButtonProps {
  className?: string;
}

const BackToSuggestionsButton: React.FC<BackToSuggestionsButtonProps> = ({
  className,
}) => {
  const [, dispatch] = useClipSelectNavigation();

  const handlePress = useCallback(() => {
    dispatch({ type: 'EDITOR_CLOSE' });
  }, [dispatch]);

  return (
    <HeaderItem>
      <Button
        className={cn(block('back-button'), className)}
        color="primary"
        onPress={handlePress}
        variant="ghost"
      >
        ← back to clips
      </Button>
    </HeaderItem>
  );
};

export default BackToSuggestionsButton;

import { DESTINATION_PRESETS } from './constants';
import DestinationPlatforms from './DestinationPlatforms';
import DestinationPlatformsContents from './DestinationPlatformsContents';
import DestinationPlatformsHeader from './DestinationPlatformsHeader';
import DestinationPlatformsVideoTypes from './DestinationPlatformsVideoTypes';
import destinationConfigMap from './destinations';
import {
  checkIfDestinationPresetExists,
  getPresetMaxDurationKey,
} from './utils';

export default DestinationPlatforms;
export {
  DestinationPlatformsVideoTypes,
  checkIfDestinationPresetExists,
  DESTINATION_PRESETS,
  getPresetMaxDurationKey,
  destinationConfigMap,
  DestinationPlatformsHeader,
  DestinationPlatformsContents,
};
export * from './types';

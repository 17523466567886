import { each } from 'underscore';
import { Type } from './action-types';
import {
  audiogramData,
  autogramData,
  fullEpisodeData,
  stateFactory,
  videoTranscriptionData,
} from './factories';
import {
  WizardDataKey,
  WizardLastUsedStyleAction,
  WizardLastUsedStyleState,
} from './types';

const defaultState = stateFactory();

export default function(
  state: WizardLastUsedStyleState = defaultState,
  action: WizardLastUsedStyleAction,
) {
  switch (action.type) {
    case Type.WIZARD_LAST_USED_STYLE_SET: {
      const { wizard, style } = action.payload;
      return state.update(wizard, current =>
        current.withMutations(c => {
          each(style, (val: any, key: WizardDataKey) => {
            c.set(key, val);
          });
          return c;
        }),
      );
    }

    case Type.WIZARD_LAST_USED_STYLE_AUDIOGRAM_RESET:
      return state.set('audiogram', audiogramData());

    case Type.WIZARD_LAST_USED_STYLE_FULL_EPISODE_RESET:
      return state.set('fullEpisode', fullEpisodeData());

    case Type.WIZARD_LAST_USED_STYLE_AUTOGRAM_RESET:
      return state.set('audiogram', autogramData());

    case Type.WIZARD_LAST_USED_STYLE_VIDEO_TRANSCRIPTION_RESET:
      return state.set('videoTranscription', videoTranscriptionData());

    default:
      return state;
  }
}

import React from 'react';
import GlobalStyleModal from 'blocks/GlobalStyleModal';

import TextTrackStyleModal from './TextTrackStyleModal';
import useTextTrackStyleModalSwitcher from './useGlobalTextModalSwitcher';

const TextTrackStyleModalContainer: React.FunctionComponent = () => {
  const {
    activeModal,
    onExited,
    onHide,
    onToggleLegacyMode,
    transitionOverlay,
  } = useTextTrackStyleModalSwitcher();

  return (
    <>
      <GlobalStyleModal
        onExited={onExited}
        onHide={onHide}
        onSwitchLegacyMode={onToggleLegacyMode}
        show={activeModal === 'legacy'}
      />
      <TextTrackStyleModal
        initialOverlay={transitionOverlay}
        onExited={onExited}
        onHide={onHide}
        onSwitchToLegacy={onToggleLegacyMode}
        show={activeModal === 'current'}
      />
    </>
  );
};

export default TextTrackStyleModalContainer;

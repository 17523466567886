import { array, string } from './utils';

export const APP_VERSION: string = process.env.REACT_APP_VERSION;

export const APPLE_AUTH_REDIRECT_URI: string =
  process.env.REACT_APP_APPLE_AUTH_REDIRECT_URI;

export const BRANCH_APP_KEY = string(process.env.REACT_APP_BRANCH_APP_KEY);

export const BRANCH_DOWNLOAD_APP_URL = string(
  process.env.REACT_APP_BRANCH_DOWNLOAD_APP_URL,
);

export const CANVA_API_KEY = string(process.env.REACT_APP_CANVA_API_KEY);

export const DISCO_IFRAME_URL = string(process.env.REACT_APP_DISCO_IFRAME_URL);

export const DISCO_SDK_URL = string(process.env.REACT_APP_DISCO_SDK_URL);

export const DISCO_WIDGET_ID = string(process.env.REACT_APP_DISCO_WIDGET_ID);

export const DROPBOX_APP_KEY = string(process.env.REACT_APP_DROPBOX_APP_KEY);

export const EDDY_URL = string(process.env.REACT_APP_EDDY_URL);

export const FACEBOOK_CONSUMER_KEY = string(
  process.env.REACT_APP_FACEBOOK_CONSUMER_KEY,
);

export const GOOGLE_ANALYTICS_ID = string(
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
);

export const MIXPANEL_API_HOST = string(
  process.env.REACT_APP_MIXPANEL_API_HOST,
);

export const MIXPANEL_TOKEN = string(process.env.REACT_APP_MIXPANEL_TOKEN);

export const RUNTIME_ENV: string = process.env.REACT_APP_RUNTIME_ENV;

export const SENTRY_DSN: string = process.env.REACT_APP_SENTRY_DSN;

export const SENTRY_SAMPLE_RATE: number = Number(
  process.env.REACT_APP_SENTRY_SAMPLE_RATE,
);

export const SPAREMIN_SERVICES_AUDIO_PROXY_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_AUDIO_PROXY_URL,
);

export const SPAREMIN_SERVICES_AUDIOGRAMDATA_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_AUDIOGRAMDATA_URL,
);

export const SPAREMIN_SERVICES_AUTHENTICATION_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_AUTHENTICATION_URL,
);

export const SPAREMIN_SERVICES_CREATION_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_CREATION_URL,
);

export const SPAREMIN_SERVICES_EDDY_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_EDDY_URL,
);

export const SPAREMIN_SERVICES_EMBED_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_EMBED_URL,
);

export const SPAREMIN_SERVICES_EMBEDCONFIGURATION_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_EMBEDCONFIGURATION_URL,
);

export const SPAREMIN_SERVICES_EMBEDVIDEO_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_EMBEDVIDEO_URL,
);

export const SPAREMIN_SERVICES_EVENT_PING_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_EVENT_PING_URL,
);

export const SPAREMIN_SERVICES_HEADLINERUSER_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_HEADLINERUSER_URL,
);

export const SPAREMIN_SERVICES_IMAGE_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_IMAGE_URL,
);

export const SPAREMIN_SERVICES_IMAGESEARCH_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_IMAGESEARCH_URL,
);

export const SPAREMIN_SERVICES_KEYWORDEXTRACTOR_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_KEYWORDEXTRACTOR_URL,
);

export const SPAREMIN_SERVICES_MEDIAUPLOAD_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_MEDIAUPLOAD_URL,
);

export const SPAREMIN_SERVICES_PLAN_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_PLAN_URL,
);

export const SPAREMIN_SERVICES_PODCAST_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_PODCAST_URL,
);

export const SPAREMIN_SERVICES_RECORDING_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_RECORDING_URL,
);

export const SPAREMIN_SERVICES_RECORDINGUPLOAD_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_RECORDINGUPLOAD_URL,
);

export const SPAREMIN_SERVICES_THIRDPARTYAUTHENTICATION_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_THIRDPARTYAUTHENTICATION_URL,
);

export const SPAREMIN_SERVICES_TRANSCRIPT_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_TRANSCRIPT_URL,
);

export const SPAREMIN_SERVICES_URL_GENERATOR = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_URL_GENERATOR,
);

export const SPAREMIN_SERVICES_USER_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_USER_URL,
);

export const SPAREMIN_SERVICES_VIDEOPROJECTMANAGEMENT_URL = string(
  process.env.REACT_APP_SPAREMIN_SERVICES_VIDEOPROJECTMANAGEMENT_URL,
);

export const STRIPE_PUBLISHABLE_KEY = string(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
);

export const TINYMCE_CONTENT_CSS = string(
  process.env.REACT_APP_TINYMCE_CONTENT_CSS,
);

export const TINYMCE_SKIN_URL = string(process.env.REACT_APP_TINYMCE_SKIN_URL);

export const TYPEFORM_NPS_FORM_ID = string(
  process.env.REACT_APP_TYPEFORM_NPS_FORM_ID,
);

export const YOUTUBE_EMAIL_PATTERNS = array(
  process.env.REACT_APP_YOUTUBE_EMAIL_PATTERNS,
  string,
);

export const YOUTUBE_ENABLED_FOR_INTEGRATORS = array(
  process.env.REACT_APP_YOUTUBE_ENABLED_FOR_INTEGRATORS,
  string,
);

export const YOUTUBE_USER_WHITELIST = array(
  process.env.REACT_APP_YOUTUBE_USER_WHITELIST,
  Number,
);

import * as React from 'react';
import { useDispatch } from 'react-redux';
import MaxDurationShareButton, {
  MaxDurationShareButtonProps,
} from 'blocks/DownloadPage/components/MaxDurationShareButton';
import { InstagramButton, InstagramButtonProps } from 'components/SocialButton';
import InstagramPostVideoModal from 'containers/InstagramPostVideoModal';
import useInstagramAuthenticator from 'hooks/useInstagramAuthenticator';
import { showError } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { FatalError } from 'utils/FatalError';
import { createChainedFunction } from 'utils/functions';
import { useSocialSection } from '../DownloadPageSocialSection/SocialSectionContext';
import useOnDownloadPageAction from '../useOnDownloadPageAction';
import { MAX_VIDEO_DURATION_SEC } from './utils';

type PickedButtonProps = Pick<InstagramButtonProps, 'disabled'>;
type PickedMaxDurationButtonProps = Pick<
  MaxDurationShareButtonProps,
  'videoDurationSec'
>;

export interface InstagramPostButtonProps
  extends PickedButtonProps,
    PickedMaxDurationButtonProps {
  show?: boolean;
  onAuthenticatedClick?: () => void;
}

const { useCallback, useState } = React;

const InstagramPostButton: React.FC<InstagramPostButtonProps> = ({
  disabled,
  videoDurationSec,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { activeModal, toggleModalVisibility } = useSocialSection();

  const { onDownloadPageAction } = useOnDownloadPageAction();

  const [shouldReauthenticate, setShouldReauthenticate] = useState<boolean>(
    false,
  );

  const handleModalHide = useCallback(() => {
    toggleModalVisibility('instagram', false);
  }, [toggleModalVisibility]);

  const handleError = useCallback(
    (err: Error) => {
      if (err instanceof FatalError) {
        dispatch(showError('Error authenticating with Instagram'));
        setShouldReauthenticate(true);
        toggleModalVisibility('instagram', false);
      } else {
        dispatch(showError(err.message));
        toggleModalVisibility('instagram', true);
      }
    },
    [dispatch, toggleModalVisibility],
  );

  const handleClick = useCallback(() => {
    toggleModalVisibility('instagram', true);
  }, [toggleModalVisibility]);

  const { authenticating, withAuthentication } = useInstagramAuthenticator({
    onError: handleError,
    force: shouldReauthenticate,
  });

  return (
    <>
      <MaxDurationShareButton
        maxVideoDurationSec={MAX_VIDEO_DURATION_SEC}
        platform="Instagram"
        videoDurationSec={videoDurationSec}
      >
        <InstagramButton
          disabled={disabled || authenticating}
          onClick={createChainedFunction(
            () => onDownloadPageAction('instagramShare'),
            withAuthentication(handleClick),
          )}
          size="small"
        />
      </MaxDurationShareButton>

      <InstagramPostVideoModal
        show={activeModal === 'instagram'}
        videoDurationSec={videoDurationSec}
        onHide={handleModalHide}
      />
    </>
  );
};

export default InstagramPostButton;

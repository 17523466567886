import cn from 'classnames';
import * as React from 'react';
import { isFinite, isUndefined, noop } from 'underscore';
import { BigPlayButton, ControlBar, Player } from 'video-react';

import BlurredVideoBackground from 'components/BlurredVideoBackground';
import bem from 'utils/bem';
import { getValue } from 'utils/collections';
import { VideoScaling } from 'utils/embed/video';
import { ReadyState } from './utils';

export type VideoPreload = 'none' | 'metadata' | 'auto';
export type VideoControlsDisplay = 'autohide' | 'hide' | 'show';

interface IProps {
  /**
   * as defined by video-react, should be either 'auto' or a `width:height` string, e.g. "16:9"
   */
  aspectRatio?: string;
  /**
   * when true, will display a big play button in the middle of the paused video
   */
  bigPlayButton?: boolean;
  /**
   * when `true` replaces letterbox / pillarbox background with a blurred version of the video. this
   * option only affects rendering when `sizing` is set to `FIT`.  when `sizing` is set to `FILL`,
   * the background is completely covered by the video, so setting this prop to true does nothing
   */
  blurBackground?: boolean;
  /**
   * children are rendered within the video container.
   *
   * video-react conditionally adds some props to children. if an intrinsic element is used
   * (e.g. an html element like div, span, etc.), react will complain about the unsupported props
   * being passed to these elements.  this is just a warning and won't affect anything at runtime.
   * to get rid of the warning, wrap the child component in either a class component or sfc
   */
  children?: React.ReactNode;
  className?: string;
  /**
   * - "autohide" will not show controls before playback begings and will hide the control bar
   *  automatically when the player is inactive.
   * - "hide" will always hide the control bar, regardless of whether playback has started or
   * player is active/inactive
   * - "show" will always show the control bar, regardless of whether playback has started or
   * the player is active/inactive
   *
   * by default, controls will always be shown
   */
  controls?: VideoControlsDisplay;
  defaultPlaying?: boolean;
  onDurationChange?: (durationMillis: number) => void;
  onLoadedMetadata?: () => void;
  /**
   * callback fired anytime the video is played
   */
  onPlay?: () => void;
  onPause?: () => void;
  onTimeUpdate?: (millis: number) => void;
  playing?: boolean;
  /**
   * url of image to show before video is played
   */
  poster?: string;
  muted?: boolean;
  preload?: VideoPreload;
  /**
   * fit - expands the image from the center point until it hits two edges of the screen
   * fill - expands the image from the center point to fill the entire screen
   *
   * see https://support.renewedvision.com/article/174-media-scaling-options
   */
  scaling?: VideoScaling;
  src: string;
  volume?: number;
}

interface IState {
  playing: boolean;
  readyState: ReadyState;
}

export default class VideoPlayer extends React.Component<IProps, IState> {
  private player: any;

  public state = {
    playing: this.managesPlaying()
      ? this.props.defaultPlaying
      : this.props.playing,
    readyState: undefined,
  };

  public static defaultProps: Partial<IProps> = {
    bigPlayButton: true,
    blurBackground: false,
    controls: 'autohide',
    defaultPlaying: false,
    onDurationChange: noop,
    onLoadedMetadata: noop,
    onPause: noop,
    onPlay: noop,
    onTimeUpdate: noop,
    preload: 'auto',
  };

  public componentDidMount() {
    this.player.subscribeToStateChange(this.handlePlayerStateChange);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const { playing } = this.props;
    const { onPause, onPlay, playing: nextPlaying } = nextProps;

    if (!playing && nextPlaying) {
      this.withPlayer(player => {
        player.play();
        onPlay();
      });
    }

    if (playing && !nextPlaying) {
      this.withPlayer(player => {
        player.pause();
        onPause();
      });
    }
  }

  private handlePlayerStateChange = (state, prevState) => {
    const {
      onDurationChange,
      onLoadedMetadata,
      onPause,
      onPlay,
      onTimeUpdate,
      playing,
    } = this.props;

    if (state.currentTime !== prevState.currentTime) {
      onTimeUpdate(state.currentTime * 1000);
    }

    /**
     * player is transitioning from playing to paused.  only fire callback if playing prop is true,
     * otherwise video is already paused from the caller's pov and there's no need to call onPuase
     */
    if (state.paused && !prevState.paused && playing) {
      onPause();
    }

    /**
     * player is transitioning from paused to playing.  oly fire callback if playing prop is faluse,
     * otherwise video is already palying from caller's pov and there's no need to call onPlay
     */
    if (!state.paused && prevState.paused && !playing) {
      onPlay();
    }

    if (state.paused !== prevState.paused) {
      this.setPlaying(!state.paused);
    }

    if (state.readyState !== prevState.readyState) {
      this.setState({ readyState: state.readyState });

      if (state.readyState >= ReadyState.HAVE_METADATA) {
        onLoadedMetadata();
      }
    }

    /*
     * isFinite because sometimes video-react passes back NaN for duration
     */
    if (state.duration !== prevState.duration && isFinite(state.duration)) {
      onDurationChange(Math.trunc(state.duration * 1000));
    }
  };

  private setPlayer = (player: any) => {
    this.player = player;
  };

  private managesPlaying() {
    const { playing } = this.props;
    return isUndefined(playing);
  }

  private isPlaying() {
    const { playing: playingProp } = this.props;
    const { playing: playingState } = this.state;

    return this.managesPlaying() ? playingState : playingProp;
  }

  private withPlayer<T>(fn: (player: any) => T) {
    if (!this.player) return undefined;
    return fn(this.player);
  }

  private setPlaying(playing: boolean) {
    if (!this.managesPlaying()) return;
    this.setState({ playing });
  }

  public play() {
    this.withPlayer(player => player.play());
  }

  public pause() {
    /*
     * we only want to call pause() if the video is playing to avoid errors about "the play()
     * request was interrupted by a call to pause()"
     *
     * video-react lies to us.  as soon as you issue `play()`, `player.paused` will return false -
     * even if the video is still loading and playback hasn't begun.
     *
     * to workaround this and only call pause when necessary, check that `player.paused` is false
     * and check that `player.readyState === 4`, which means that the player has enough information
     * to play the video (i.e. it's loaded).  this definitely doesn't solve the problem - readyState
     * can be 4 even though the video hasn't started playing yet - but it should mitigate the issue.
     *
     * if videos get stuck loading and the player is unmounted, video-react is going to issue
     * a pause which will throw the error - but that's not our fault...
     */
    this.withPlayer(player => {
      const { paused, readyState } = player.getState().player;
      if (!paused && readyState === 4) {
        player.pause();
      }
    });
  }

  public seek(millis: number) {
    this.withPlayer(player => player.seek(millis / 1000));
  }

  public set volume(volume: number) {
    this.withPlayer(player => (player.volume = volume));
  }

  public render() {
    const {
      aspectRatio,
      bigPlayButton,
      blurBackground,
      children,
      className,
      controls,
      poster,
      muted = false,
      scaling,
      src,
    } = this.props;
    const { readyState } = this.state;

    const video: HTMLVideoElement = getValue(this.player, ['video', 'video']);
    const b = bem('video-player');

    return (
      <Player
        aspectRatio={aspectRatio}
        className={cn(b({ fill: scaling === VideoScaling.FILL }), className)}
        src={src}
        poster={poster}
        muted={muted}
        ref={this.setPlayer}
      >
        {// in order to render the blurred background, the option must be set...
        blurBackground &&
          // ...we need the video to draw on the canvas...
          video &&
          // ...we need the metadata so we can draw the first frame before playing...
          readyState >= ReadyState.HAVE_CURRENT_DATA &&
          // ...scaling must be FIT because FILL would completely hide the background
          scaling === VideoScaling.FIT && (
            <BlurredVideoBackground
              playing={this.isPlaying()}
              video={video}
              videoClientHeight={video.clientHeight}
              videoClientWidth={video.clientWidth}
            />
          )}
        <BigPlayButton
          className={b('big-play-button', { hidden: !bigPlayButton })}
          position="center"
        />
        <ControlBar
          className={b('controls', {
            hidden: controls === 'hide',
            visible: controls === 'show',
          })}
          autoHide={controls === 'autohide'}
        />
        {children}
      </Player>
    );
  }
}

export { VideoPlayer, IProps as VideoPlayerProps, VideoScaling };

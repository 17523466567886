import * as React from 'react';
import { useDispatch } from 'react-redux';
import { isUndefined, noop } from 'underscore';
import FontAwesome from 'components/FontAwesome';
import { Watermark } from 'components/icons';
import useModalMonitor from 'containers/ConnectedModal/useModalMonitor';
import { AddLogoModal, EditLogoModal } from 'containers/LogoModal';
import ProIndicator, { ProIndicatorOverlay } from 'containers/ProIndicator';
import { pushModal } from 'redux/modules/modal';
import { ModalName } from 'redux/modules/modal/types';
import { ProjectTypeByAssets } from 'utils/constants';
import StyleTileButton from '../../components/StyleTileButton';
import { block } from '../../utils';
import LockedWatermarkEditModal from '../LockedWatermarkEditModal';
import LockedWatermarkSelectModal from '../LockedWatermarkSelectModal';
import WatermarkTooltip from './WatermarkTooltip';

export interface WatermarkTileProps {
  className?: string;
  defaultWatermark?: string;
  logoImage?: string;
  previewBackgroundImage?: string;
  projectType: ProjectTypeByAssets;
  logoStatus: 'adding' | 'deleting';
  onModalHide?: () => void;
  onModalShow?: () => void;
  imageAtPosition: string;
  positionSec: number;
  hasOverrides: boolean;
}

const MODAL_NAMES: ModalName[] = ['EditWatermark', 'AddWatermark'];

const WatermarkTile: React.SFC<WatermarkTileProps> = props => {
  const {
    defaultWatermark,
    logoImage,
    logoStatus,
    onModalShow = noop,
    onModalHide = noop,
    projectType,
    hasOverrides,
  } = props;
  const dispatch = useDispatch();
  useModalMonitor({
    names: MODAL_NAMES,
    onHide: onModalHide,
    onShow: onModalShow,
  });
  const handleLogoButtonClick = () => {
    if (hasLogo()) {
      dispatch(
        pushModal({
          name: 'EditWatermark',
        }),
      );
    } else {
      dispatch(
        pushModal({
          name: 'AddWatermark',
        }),
      );
    }
  };

  const handleUpgradeClick = (event: React.MouseEvent) => {
    event.preventDefault();
    dispatch(
      pushModal({
        name: 'WatermarkUpsell',
      }),
    );
  };

  /**
   * whether or not a logo exists - i.e. determines if we show the "add" modal or "delete" modal.
   *
   * note that if there is no logo url but the logo status is "deleting", we still consider this
   * as having a logo (meaning this method returns true). this prevents the "delete" button from
   * turning back into an "upload" button prematurely
   */
  const hasLogo = () => {
    return (
      !isUndefined(logoImage) ||
      (isUndefined(logoImage) && logoStatus === 'deleting')
    );
  };

  const showWatermarkButtons = () => {
    return hasLogo() || projectType !== ProjectTypeByAssets.PROJECT_NO_ASSETS;
  };

  const disableButtons =
    projectType === ProjectTypeByAssets.PROJECT_NO_ASSETS ||
    defaultWatermark !== undefined;

  return (
    <>
      <ProIndicator className={block('badge')}>
        {({ isFree, badge }) => (
          <WatermarkTooltip
            defaultWatermark={defaultWatermark}
            disableButtons={disableButtons && !isFree}
            projectType={projectType}
          >
            <StyleTileButton
              className={block('tile', {
                disabled: disableButtons || isFree,
                inside: true,
              })}
              icon={
                <Watermark
                  className={block('watermark-icon', {
                    faded: isFree || disableButtons,
                  })}
                  color={isFree || disableButtons ? '#555c66' : '#6e6ede'}
                />
              }
              label={hasLogo() ? 'Edit Watermark' : 'Add Watermark'}
              onClick={handleLogoButtonClick}
              badge={
                defaultWatermark && (
                  <FontAwesome
                    className={block('tile-button-lock')}
                    icon="lock"
                  />
                )
              }
            />
            {!defaultWatermark && badge}
            {!defaultWatermark && isFree && (
              <ProIndicatorOverlay
                from="Add Watermark"
                onClick={handleUpgradeClick}
              />
            )}
          </WatermarkTooltip>
        )}
      </ProIndicator>
      {showWatermarkButtons() &&
        (hasOverrides ? (
          <>
            <LockedWatermarkSelectModal />
            <LockedWatermarkEditModal />
          </>
        ) : (
          <>
            <AddLogoModal />
            <EditLogoModal showDeleteButton logoImageSrc={logoImage} />
          </>
        ))}
    </>
  );
};

export default WatermarkTile;

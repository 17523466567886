import { CaptionsAnimationConfig } from '@sparemin/text-effects';
import { indexBy } from 'underscore';
import { KARAOKE_ANIMATION_NAMES } from './constants';
import { AnimationKey, AnimationModule } from './types';

export const checkIsKaraokeAnimation = (
  selectedAdvancedAnimation: CaptionsAnimationConfig,
): boolean => {
  return KARAOKE_ANIMATION_NAMES.includes(
    selectedAdvancedAnimation?.animationName,
  );
};

export const getAnimationChildView = (
  selectedAdvancedAnimation: CaptionsAnimationConfig,
  animationModules: AnimationModule[],
): React.ReactNode => {
  if (!selectedAdvancedAnimation?.enabled) {
    return null;
  }

  const animationModulesAsObject = indexBy(animationModules, 'key') as Record<
    AnimationKey,
    AnimationModule
  >;

  if (checkIsKaraokeAnimation(selectedAdvancedAnimation)) {
    return animationModulesAsObject?.karaoke?.contents;
  }

  return null;
};

import { Video } from '@sparemin/blockhead';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IRegion, RegionUpdateAction } from 'components/AudioClipper';
import AsyncAudioClipper from 'containers/AsyncAudioClipper';
import {
  entireAudioInstancesSelector,
  entireAudiosSelector,
} from 'redux/modules/entities/selectors';
import { pageBlock as block } from '../utils';
import AddToVideoButton from './AddToVideoButton';
import { useClipSelectAnimation } from './ClipSelectAnimationContext';
import { useClipSelect } from './ClipSelectContext';

interface Props {
  className?: string;
}

const ClipSelectAudioClipper: React.FC<Props> = ({ className }) => {
  const {
    defaultClipperRegion,
    audioClipperRef,
    onSelectClip,
    clipsPageInfo: { entireAudioInstanceId },
  } = useClipSelect();

  const { onClipSelected } = useClipSelectAnimation();

  const [
    prevDefaultHighlightedRegion,
    setPrevDefaultHighlightedRegion,
  ] = useState(defaultClipperRegion);

  const [region, setRegion] = useState<IRegion>(defaultClipperRegion);

  if (defaultClipperRegion !== prevDefaultHighlightedRegion) {
    setPrevDefaultHighlightedRegion(defaultClipperRegion);
    setRegion(defaultClipperRegion);
  }

  const entireAudio = useSelector(state => {
    const entireAudioInstance = entireAudioInstancesSelector(state)?.get(
      String(entireAudioInstanceId),
    );
    return entireAudiosSelector(state)?.get(
      String(entireAudioInstance?.get('entireAudio')),
    );
  });

  const handleRegionUpdate = useCallback(
    (newRegion: IRegion, action?: RegionUpdateAction) => {
      if (action !== 'init') {
        setRegion(newRegion);
      }
    },
    [],
  );

  useLayoutEffect(() => {
    if (region) {
      audioClipperRef.current?.seek(region.startMillis);
    }
  }, [audioClipperRef, region]);

  const handleSelectClip = useCallback(async (): Promise<void> => {
    const { startMillis, endMillis } = region;

    if (startMillis !== undefined && endMillis !== undefined) {
      await onSelectClip({ region: { startMillis, endMillis } });

      onClipSelected();
    }
  }, [onClipSelected, onSelectClip, region]);

  return (
    <AsyncAudioClipper
      {...{ className, region, entireAudioInstanceId }}
      src={entireAudio?.get('url')}
      ref={audioClipperRef}
      onRegionUpdate={handleRegionUpdate}
      wavesurferClassName={block('audio-clipper-waversurfer')}
      controlsClassName={block('audio-clipper-forms-control')}
      formClassName={block('audio-clipper-forms-control')}
      captionsControl={
        <AddToVideoButton
          disabled={region.endMillis - region.startMillis < 1000}
          className={block('clip-select-submit-button')}
          theme="submit"
          icon={<Video style={{ width: 17, height: 12 }} />}
          onClick={handleSelectClip}
        >
          save clip
        </AddToVideoButton>
      }
    />
  );
};

export default ClipSelectAudioClipper;

import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import { SPAREMIN_SERVICES_VIDEOPROJECTMANAGEMENT_URL } from 'config';
import { createRequest } from '../utils';

import * as types from './types';

const BASE_PATH_V1 = '/api/v1/video-project-mgmt';
const BASE_PATH_V2 = '/api/v2/video-project-mgmt';
const PROJECT_PATH_V1 = `${BASE_PATH_V1}/project`;
const PROJECT_PATH_V2 = `${BASE_PATH_V2}/project`;

const projectSchema = new schema.Entity(
  'projects',
  {},
  {
    idAttribute: project => {
      const { detail, projectType } = project ?? {};

      if (projectType === 'creationSuggestionGroup') {
        return detail?.creationRequestId;
      }

      if (projectType === 'automationSuggestionGroup') {
        return detail?.groupRequestId;
      }

      return detail?.projectUuid ?? project?.projectUuid;
    },
    processStrategy: project => {
      const { detail, projectType, reprAt } = project || {};

      return {
        detail: detail ?? project,
        // endpoints that only send back plainProjects don't include the projectType, so fallback to
        // plainProject
        projectType: projectType ?? 'plainProject',
        reprAt,
      };
    },
  },
);

const revisionHistorySchema = new schema.Entity(
  'revisionHistory',
  {},
  {
    idAttribute: revisionHistory => revisionHistory.embedConfigurationUuid,
  },
);

const createProject = (
  args: types.CreateProjectArgs,
  request: SuperAgentStatic,
): Promise<types.CreateProjectResult> => {
  const [
    embedConfigurationId,
    projectName,
    aspectRatio,
    createMethod,
    thumbnailUrl,
    copiedFromProjectId,
    traceId,
    customTraceId,
    createdFromTemplateId,
    createdFromTemplateType,
  ] = args;

  return request
    .post(`${PROJECT_PATH_V1}/`)
    .send({
      aspectRatio,
      copiedFromProjectId,
      createdFromTemplateId,
      createdFromTemplateType,
      createMethod,
      customTraceId,
      embedConfigurationId,
      projectName,
      thumbnailUrl,
      traceId,
    })
    .then(res => normalize(res.body, projectSchema));
};

const getProjectById = (
  args: types.GetProjectByIdArgs,
  request: SuperAgentStatic,
): Promise<types.GetProjectByIdResult> => {
  const [id] = args;

  return request
    .get(`${PROJECT_PATH_V1}/${id}`)
    .then(res => normalize(res.body, projectSchema));
};

const getAllProjects = (
  args: types.GetAllProjectsArgs,
  request: SuperAgentStatic,
): Promise<types.GetAllProjectsResult> => {
  const [ownerId, createMethod, pageSize, nextToken] = args;

  return (
    request
      // Backend only accepts this request with the trailing slash (`/`).
      .get(`${PROJECT_PATH_V2}/`)
      .query({
        ownerId,
        createMethod,
        pageSize,
        nextToken,
      })
      .then(res => {
        const { content, nextToken: nextPageToken, totalResults } = res.body;

        return {
          ...normalize(content, [projectSchema]),
          nextToken: nextPageToken,
          totalResults,
        };
      })
  );
};

const getProjectsByUrl = async (
  [url]: types.GetProjectsByUrlArgs,
  request: SuperAgentStatic,
): Promise<types.GetProjectsByUrlResult> => {
  const {
    body: { content, page },
  } = await request.get(url);
  return {
    ...normalize(content, [projectSchema]),
    page,
  };
};

const updateProject = (
  args: types.UpdateProjectArgs,
  request: SuperAgentStatic,
): Promise<types.UpdateProjectResult> => {
  const [
    id,
    embedConfigurationId,
    projectName,
    aspectRatio,
    thumbnailUrl,
  ] = args;

  return request
    .put(`${PROJECT_PATH_V1}/${id}`)
    .send({ embedConfigurationId, projectName, thumbnailUrl, aspectRatio })
    .then(res => res.body);
};

const deleteProject = (
  args: types.DeleteProjectArgs,
  request: SuperAgentStatic,
): Promise<types.DeleteProjectResult> => {
  const [id] = args;

  return request.delete(`${PROJECT_PATH_V1}/${id}`).then(res => res.body);
};

const getRevisionHistoryByProjectId = (
  args: types.GetRevisionHistoryByProjectIdArgs,
  request: SuperAgentStatic,
): Promise<types.GetRevisionHistoryByProjectIdResult> => {
  const [id, page, size] = args;

  return request
    .get(`${PROJECT_PATH_V1}/${id}/history?page=${page}&size=${size}`)
    .then(res => ({
      ...normalize(res.body.content, [revisionHistorySchema]),
      page: res.body.page,
    }));
};

export const handle: types.IHandle = (
  method: types.ServiceMethod,
  args: any,
  token?: string,
): Promise<types.ServiceResults> => {
  const request = createRequest({
    token,
    baseUrl: SPAREMIN_SERVICES_VIDEOPROJECTMANAGEMENT_URL,
  });

  switch (method) {
    case types.ServiceMethod.CREATE_PROJECT:
      return createProject(args, request);

    case types.ServiceMethod.GET_ALL_PROJECTS:
      return getAllProjects(args, request);

    case types.ServiceMethod.GET_PROJECT_BY_ID:
      return getProjectById(args, request);

    case types.ServiceMethod.UPDATE_PROJECT:
      return updateProject(args, request);

    case types.ServiceMethod.DELETE_PROJECT:
      return deleteProject(args, request);

    case types.ServiceMethod.GET_REVISION_HISTORY_BY_PROJECT_ID:
      return getRevisionHistoryByProjectId(args, request);

    case types.ServiceMethod.GET_PROJECTS_BY_URL:
      return getProjectsByUrl(args, request);

    default:
      throw new Error(`${types.ACTION_KEY} cannot handle method ${method}`);
  }
};

import { ProjectCreationMethod } from 'types';

export const getProjectCreationMethod = (
  isClipSuggestion: boolean,
  isFullEpisodeExport: boolean,
): ProjectCreationMethod => {
  // For clip suggestions there is no distinction between full episode and clip yet.
  if (isClipSuggestion) {
    return 'videoTranscriptClipSuggestion';
  }

  // For single clips it is necessary to check between full episode and regular transcripts.
  if (isFullEpisodeExport) {
    return 'videoTranscriptEpisode';
  }

  return 'videoTranscript';
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProjectHistory from 'blocks/ProjectHistory';
import BemCssTransition from 'components/BemCssTransition';
import { getProjectHistory } from 'redux/modules/project/actions';
import { isLoadingHistorySelector } from 'redux/modules/project/selectors';
import { cmsBlock as block } from '../utils';

interface ProjectFeedDataProps {
  active: boolean;
}

const ProjectFeedData: React.FC<ProjectFeedDataProps> = ({ active }) => {
  const isLoading = useSelector(isLoadingHistorySelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (active) {
      dispatch(getProjectHistory(false));
    }
  }, [active, dispatch]);

  return (
    <BemCssTransition
      unmountOnExit
      in={active}
      timeout={300}
      className={block('feed-data')}
      delay={{
        enter: 150,
      }}
    >
      <div>
        <div className={block('heading')}>
          <span className={block('title')}>Your projects</span>
        </div>

        <ProjectHistory active={active} isLoadingHistory={isLoading} />
      </div>
    </BemCssTransition>
  );
};

export default ProjectFeedData;

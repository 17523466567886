import { Reply } from '@sparemin/blockhead';
import React from 'react';
import { selectorBlock as block } from '../../utils';
import AddToVideoButton from '../AddToVideoButton';
import { useClipSelect } from '../ClipSelectContext';
import DislikeClipButton from './DislikeClipButton';
import EditClipButton from './EditClipButton';

export interface ClipSelectorControlsProps {
  clipId: number;
  playable?: boolean;
  isDisabled?: boolean;
}

const ClipSelectorControls: React.FC<ClipSelectorControlsProps> = ({
  clipId,
  playable,
  isDisabled,
}) => {
  const {
    activeSuggestionId,
    isClipSuggestionLoading,
    onSubmit,
  } = useClipSelect();

  const disabled = isClipSuggestionLoading || !playable || isDisabled;

  return (
    <div className={block('controls')}>
      <DislikeClipButton
        disabled={disabled}
        suggestionId={activeSuggestionId}
      />
      <AddToVideoButton
        disabled={disabled}
        id="confirm"
        theme="rainbow"
        tooltip={
          playable
            ? disabled
              ? 'This clip will be ready soon'
              : 'Use this clip without making changes'
            : ''
        }
        icon={<Reply style={{ width: 13, height: 12 }} />}
        onClick={() => onSubmit(clipId)}
      >
        share
      </AddToVideoButton>
      <EditClipButton isDisabled={disabled} />
    </div>
  );
};

export default ClipSelectorControls;

import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';

import Modal from 'components/Modal';
import { aspectRatioSelector } from 'redux/modules/embed/selectors';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { block } from '../../utils/block';
import GlobalStyleModalContents from '../GlobalStyleModalContents';

export interface IProps {
  className?: string;
  onExited: () => void;
  onHide: () => void;
  onSwitchLegacyMode: () => void;
  show: boolean;
}

const GlobalStyleModal: React.FunctionComponent<IProps> = props => {
  const { className, onExited, onHide, onSwitchLegacyMode, show } = props;

  const aspectRatio = useSelector(aspectRatioSelector);
  const ratioName = getAspectRatioName(aspectRatio);
  const ratioClass = ratioName && `${block()}--${ratioName}`;

  const modalClassName = classNames(block(), ratioClass, className);

  return (
    <Modal
      className={modalClassName}
      onExited={onExited}
      onHide={onHide}
      backdrop="static"
      title="Track Properties"
      show={show}
    >
      <GlobalStyleModalContents
        onHide={onHide}
        onSwitchLegacyMode={onSwitchLegacyMode}
      />
    </Modal>
  );
};

export { GlobalStyleModal as default, IProps as GlobalStyleModalProps };

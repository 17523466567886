import React from 'react';
import { H1, H2 } from 'components/Heading';
import { block } from './utils';

export interface DestinationPlatformsHeaderProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
}

const DestinationPlatformsHeader: React.FC<DestinationPlatformsHeaderProps> = ({
  title = 'Choose a destination',
  description = 'Where would you like to promote your podcast?',
}) => (
  <div className={block('headers')}>
    <H1>{title}</H1>

    <H2>{description}</H2>
  </div>
);
export default DestinationPlatformsHeader;

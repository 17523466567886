import bem from 'utils/bem';
import { TextOverlay } from './LegacyEditTextModal';

export const block = bem('legacy-text-modal');

// Remove unwanted styles from the text overlay. Specifically,
// this handles styles originating from the editor V2. Without
// sanitization, these styles will never be removed from the
// config, as the update action merges new values with existing
// ones. This ensures that styles are saved correctly based on
// the legacy configuration.
export function sanitizeLegacyTextOverlay(textOverlay: TextOverlay) {
  return textOverlay
    .setIn(['editor', 'textStyle', 'textOutline'], undefined)
    .setIn(['editor', 'textStyle', 'textTransform'], undefined)
    .setIn(['editor', 'textStyle', 'webkitTextStroke'], undefined);
}

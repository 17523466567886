import React from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'components/icons';
import Circular from 'components/icons/circular';
import Modal from 'components/Modal';
import NonIdealState from 'components/NonIdealState';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import { block } from './utils';

function DOCLimitationsAlertModalContent() {
  const { onHide } = useConnectedModal('DOCLimitationsAlert');

  return (
    <>
      <NonIdealState
        title="Best practices for DOC files"
        description={
          <>
            To align captions correctly, ensure that any text not present in the
            selected audio is removed. Episodes with Dynamic Ad Insertion must
            include transcripts for all featured ads to ensure accuracy.
          </>
        }
        icon={
          <Circular variant="danger">
            <Alert />
          </Circular>
        }
        className={block('contents')}
      />
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="submit-alt" onClick={() => onHide(false)}>
            upload another file
          </Modal.FooterButton>

          <Modal.FooterButton
            theme="submit"
            componentClass={Link}
            onClick={() => onHide(true)}
          >
            continue upload
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
}

export default DOCLimitationsAlertModalContent;

import React from 'react';

import AssetTile from 'components/AssetTile';
import useTemplateThumbnail from 'hooks/useTemplateThumbnail';
import { TemplateItem } from '../../types';

interface IProps {
  className?: string;
  onClickDeleteButton: (
    params: any,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  onClickTemplate: () => void;
  template: TemplateItem;
}

const UserGenerated = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const { className, onClickDeleteButton, onClickTemplate, template } = props;
  const { imageUrl, progressOverlay } = useTemplateThumbnail({ template });

  return (
    <AssetTile
      background={!imageUrl ? undefined : { src: imageUrl, type: 'image' }}
      className={className}
      onClick={onClickTemplate}
      onDeleteClick={onClickDeleteButton}
      ref={ref}
      displayName={template.displayName}
    >
      {progressOverlay}
    </AssetTile>
  );
});

export default UserGenerated;
export { IProps as UserGeneratedProps };

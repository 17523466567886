import { fromJS } from 'immutable';
import React from 'react';

import { isEqual } from 'underscore';
import { TextHighlightV2, TextShadowV2 } from 'components/TextToolbar';

import { TextOutline } from 'components/TextToolbar/OutlineInput';
import { useTextOverlay } from '../state/TextOverlayProvider';

interface UseTextOverlayEffects {
  onChangeHighlightStyle: (highlightStyle: TextHighlightV2) => void;
  onChangeShadowStyle: (shadowStyle: TextShadowV2) => void;
  onChangeOutlineStyle: (outlineStyle: TextOutline) => void;
  selectedTextHighlightStyle?: TextHighlightV2;
  selectedShadowStyle: TextShadowV2;
  selectedOutlineStyle: TextOutline;
}

const useTextOverlayEffects = (): UseTextOverlayEffects => {
  const {
    draftEditorData,
    onUpdateAdvancedTextConfig,
    onUpdateStyle,
  } = useTextOverlay();

  const selectedTextHighlightStyle = draftEditorData
    .getIn(['advancedTextConfigs', 'gooeyText'])
    ?.toJS();
  const selectedShadowStyle = draftEditorData
    .getIn(['editor', 'textStyle', 'textShadow'])
    ?.toJS();
  const selectedOutlineStyle = draftEditorData
    .getIn(['editor', 'textStyle', 'textOutline'])
    ?.toJS();

  const handleChangeShadowStyle = React.useCallback(
    (shadowStyle: TextShadowV2): void => {
      onUpdateStyle(editorStyle => {
        // Overriding the value even if it has not change causes an unnecesary
        // performance slowdown, by changing it only when there is a real reason
        // for doing it improves performance considerably.
        if (!isEqual(editorStyle.getIn(['textShadow'])?.toJS(), shadowStyle)) {
          return editorStyle.set('textShadow', fromJS(shadowStyle));
        }
        return editorStyle;
      });
    },
    [onUpdateStyle],
  );

  const handleChangeOutlineStyle = React.useCallback(
    (textOutline: TextOutline): void => {
      onUpdateStyle(editorStyle => {
        // Overriding the value even if it has not change causes an unnecesary
        // performance slowdown, by changing it only when there is a real reason
        // for doing it improves performance considerably.
        if (!isEqual(editorStyle.getIn(['textOutline'])?.toJS(), textOutline)) {
          return editorStyle.set('textOutline', fromJS(textOutline));
        }
        return editorStyle;
      });
    },
    [onUpdateStyle],
  );

  const handleChangeHighlightStyle = React.useCallback(
    (value: TextHighlightV2): void => {
      onUpdateAdvancedTextConfig(advancedTextConfigs => {
        // Overriding the value even if it has not change causes an unnecesary
        // performance slowdown, by changing it only when there is a real reason
        // for doing it improves performance considerably.
        if (!isEqual(advancedTextConfigs.get('gooeyText')?.toJS(), value)) {
          return advancedTextConfigs.set('gooeyText', fromJS(value));
        }
        return advancedTextConfigs;
      });
    },
    [onUpdateAdvancedTextConfig],
  );

  return {
    onChangeHighlightStyle: handleChangeHighlightStyle,
    onChangeShadowStyle: handleChangeShadowStyle,
    onChangeOutlineStyle: handleChangeOutlineStyle,
    selectedTextHighlightStyle,
    selectedShadowStyle,
    selectedOutlineStyle,
  };
};

export default useTextOverlayEffects;

import { Card, Spacer, Switch, Text } from '@sparemin/blockhead';
import React from 'react';
import { Blur } from 'components/icons';
import { block } from './utils';

export interface BlurToggleProps {
  label?: string;
  isActive: boolean;
  onChange: (isActive: boolean) => void;
}

const BlurToggle: React.FC<BlurToggleProps> = ({
  label = 'Background blur',
  isActive,
  onChange,
}) => {
  return (
    <Card className={block()} header={<></>} footer={<></>}>
      <Spacer
        orientation="horizontal"
        space="10px"
        align="center"
        justify="center"
      >
        <Blur />

        <Text
          id="blur-toggle-label"
          as="label"
          variant="label"
          className={block('label')}
          htmlFor="blur-toggle-switch"
        >
          {label}
        </Text>
      </Spacer>

      <Switch
        id="blur-toggle-switch"
        as="div"
        aria-labelledby="blur-toggle-label"
        isSelected={isActive}
        className={block('toggle')}
        onChange={onChange}
      />
    </Card>
  );
};

export default BlurToggle;

import { connect } from 'react-redux';

import { onFileUpload, onWatchInfoVideo } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import { DropZoneType } from 'utils/constants';
import { createChainedFunction } from 'utils/functions';
import AutomatedAddAudioStep, {
  AutomatedAddAudioStepProps,
} from './AutomatedAddAudioStep';

type DispatchProps = Pick<
  AutomatedAddAudioStepProps,
  'onAudioAdded' | 'onSampleVideoPlay'
>;

type OwnProps = Pick<AutomatedAddAudioStepProps, 'onAudioAdded'>;

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onAudioAdded: createChainedFunction(props.onAudioAdded, src => {
    if (src instanceof File) {
      dispatch(
        onFileUpload({
          dropZoneType: DropZoneType.AUTOMATED,
          source: 'localFile',
          file: src,
        }),
      );
    }
  }),
  onSampleVideoPlay: () => dispatch(onWatchInfoVideo('automated')),
});

const component = connect(null, mapDispatchToProps)(AutomatedAddAudioStep);
export type AutomatedAddAudioProps = React.ComponentPropsWithoutRef<
  typeof component
>;
export default component;

import { memoize } from 'underscore';
import { ReadyState } from 'components/VideoPlayer/utils';
import { IDimensions } from 'types';
import { ApplicationError } from './ApplicationError';
import { UPLOAD_VIDEO_MAX_DURATION_SECONDS } from './constants';
import { isSafari } from './device';
import { secToHours } from './time';

function getSource(src: File | string) {
  return src instanceof File ? URL.createObjectURL(src) : src;
}

type CreateVideoElementOpts = {
  crossOrigin?: HTMLMediaElement['crossOrigin'];
  preload?: HTMLMediaElement['preload'];
};

export function createVideoElement(
  src: File | string,
  opts?: CreateVideoElementOpts,
) {
  if (src === undefined) {
    return undefined;
  }

  const { crossOrigin, preload } = opts ?? {};

  const video = document.createElement('video');
  video.volume = 0;

  if (crossOrigin !== undefined) {
    video.crossOrigin = opts.crossOrigin;
  }

  if (preload !== undefined) {
    video.preload = preload;
  }

  return new Promise<HTMLVideoElement>((resolve, reject) => {
    const removeListeners = () => {
      video.removeEventListener('durationchange', handleDurationChange);
      video.removeEventListener('error', handleError);
    };

    const handleDurationChange = () => {
      resolve(video);
      removeListeners();
    };

    const handleError = () => {
      reject(video.error);
      removeListeners();
    };

    const loadSource = () => {
      video.src = getSource(src);
      video.load();
    };

    video.addEventListener('error', handleError);

    // HACK
    // https://stackoverflow.com/questions/34255345/how-to-get-audio-file-duration-in-safari
    // https://sparemin.atlassian.net/browse/SPAR-8641
    if (isSafari) {
      loadSource();
      video
        .play()
        .then(() => {
          video.pause();
          resolve(video);
        })
        .catch(err => reject(err));
    } else {
      video.addEventListener('durationchange', handleDurationChange);
      loadSource();
    }
  });
}

/**
 * Retrieves the duration of a video in seconds.
 */
export function getDuration(
  src: File | string,
  cb?: (duration: number, err?: Error) => void,
) {
  const result = createVideoElement(src).then(video => video.duration);

  // if the caller passed a cb, call it and return nothing, otherwise return the promise
  if (!cb || typeof cb !== 'function') {
    return result;
  }

  result.then(duration => cb(duration)).catch(err => cb(undefined, err));
  return undefined;
}

export async function verifyVideo(
  file: File,
  maxDurationSeconds = UPLOAD_VIDEO_MAX_DURATION_SECONDS,
) {
  const video = await createVideoElement(file);

  const maxDurationHour = secToHours(maxDurationSeconds);

  if (video.duration > maxDurationSeconds) {
    throw new ApplicationError(
      `Uploaded videos have to be a maximum of ${maxDurationHour} ${
        maxDurationHour === 1 ? 'hour' : 'hours'
      } in length`,
      'IN005',
    );
  }

  /*
   * HACK: not all browsers support all video encodings.  if a video with an unsupported encoding is
   * loaded into the browser, the browser typically just plays the audio without showing any video.
   * codec information isn't exposed in the browser, so assume that if the video is done loading
   * and both videoHeight and videoWidth are 0 the encoding is unsupported
   */
  if (video.videoHeight === 0 || video.videoWidth === 0) {
    throw new ApplicationError(
      "Your browser doesn't support the video's encoding.  Try uploading this video in another browser.",
      'ER008',
    );
  }

  return true;
}

type VideoType = 'ogg' | 'h264' | 'webm' | 'vp9' | 'hls' | string;

/**
 * https://davidwalsh.name/detect-supported-video-formats-javascript
 * https://github.com/Modernizr/Modernizr/blob/5eea7e2a213edc9e83a47b6414d0250468d83471/feature-detects/video.js
 */
export const supportsVideoType = memoize((type: VideoType) => {
  let video: HTMLVideoElement;

  // Allow user to create shortcuts, i.e. just "webm"
  const formats: Record<VideoType, string> = {
    ogg: 'video/ogg; codecs="theora"',
    h264: 'video/mp4; codecs="avc1.42E01E"',
    webm: 'video/webm; codecs="vp8, vorbis"',
    vp9: 'video/webm; codecs="vp9"',
    hls: 'application/x-mpegURL; codecs="avc1.42E01E"',
  };

  if (!video) {
    video = document.createElement('video');
  }

  try {
    return !!video.canPlayType(formats[type] || type).replace(/^no$/, '');
  } catch {
    return false;
  }
});

export function supportsWebM() {
  return supportsVideoType('webm') && supportsVideoType('vp9');
}

export function getVideoSize(url: string): Promise<IDimensions> {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');

    video.src = url;

    const handleMetadataLoaded = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
      });
    };

    video.addEventListener('loadedmetadata', handleMetadataLoaded);

    video.addEventListener('error', () => {
      reject(video.error);
      video.removeEventListener('loadedmetadata', handleMetadataLoaded);
    });
  });
}

async function waitForMetadata(video: HTMLVideoElement) {
  return new Promise<void>((resolve, reject) => {
    if (video.readyState >= ReadyState.HAVE_METADATA) {
      resolve();
    } else {
      const removeHandlers = () => {
        video.removeEventListener('loadedmetadata', handleMeta);
        video.removeEventListener('error', handleError);
      };
      const handleError = () => {
        removeHandlers();
        reject(video.error);
      };
      const handleMeta = () => {
        removeHandlers();
        resolve();
      };

      video.addEventListener('error', handleError);
      video.addEventListener('loadedmetadata', handleMeta);
    }
  });
}

async function seekToTime(video: HTMLVideoElement, time: number) {
  return new Promise<void>((resolve, reject) => {
    const removeHandlers = () => {
      video.removeEventListener('seeked', handleSeeked);
      video.removeEventListener('error', handleError);
    };
    const handleSeeked = () => {
      removeHandlers();
      resolve();
    };
    const handleError = () => {
      removeHandlers();
      reject(video.error);
    };

    video.addEventListener('error', handleError);
    video.addEventListener('seeked', handleSeeked);

    video.currentTime = time;
  });
}

function exportCurrentVideoFrame(video: HTMLVideoElement, scale: number = 1) {
  // Extract dimensions for the thumbnail.
  const originalWidth = video.videoWidth;
  const originalHeight = video.videoHeight;

  // Create a canvas for rendering the thumbnail.
  const canvas = document.createElement('canvas');

  canvas.width = Math.round(originalWidth * scale);
  canvas.height = Math.round(originalHeight * scale);

  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Failed to get 2D context for canvas.');
  }

  // Draw the video frame onto the canvas.
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

  // Convert canvas to a PNG data URL.
  return canvas.toDataURL('image/png');
}

type CreateVideoThumbnailOpts = {
  scale?: number;
  time?: number;
};

/**
 * Generates a thumbnail from the first frame of a video file or URL.
 *
 * if input is a video, it might need crossOrigin set to 'anonymous'
 */
export async function getVideoThumbnail(
  input: HTMLVideoElement | File | string,
  { time = 0, scale = 1 }: CreateVideoThumbnailOpts = {},
) {
  const video =
    input instanceof HTMLVideoElement
      ? input
      : await createVideoElement(input, {
          crossOrigin: 'anonymous',
          preload: 'metadata',
        });

  await waitForMetadata(video);
  await seekToTime(video, time);
  return exportCurrentVideoFrame(video, scale);
}

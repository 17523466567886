import { useToggle } from '@sparemin/blockhead';
import React, { useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import PodcastHeading from 'blocks/AutomationCMS/components/PodcastHeading';
import BemCssTransition from 'components/BemCssTransition';
import { InfiniteScrollLoader } from 'components/InfiniteScroll';
import { getMyPodcastFeed } from 'redux/modules/automation-cms';
import AutomationCMSEpisodeList from '../../components/AutomationCMSEpisodeList';
import { cmsBlock as block } from '../../utils';
import PodcastFeedDataContext from './PodcastFeedDataContext';
import usePodcastFeedEpisodes from './usePodcastFeedEpisodes';

export interface PodcastFeedDataProps {
  active: boolean;
  podcastFeedId: string;
}

const PodcastFeedData: React.FC<PodcastFeedDataProps> = ({
  active,
  podcastFeedId,
}) => {
  const {
    value: entered,
    toggleOn: setEntered,
    toggleOff: setExited,
  } = useToggle(false);

  const {
    episodes,
    workflows,
    isFetching,
    hasMore,
    canRefreshFeed,
    onLoadMoreEpisodes,
  } = usePodcastFeedEpisodes(podcastFeedId);

  const dispatch = useDispatch();

  const contextValue = useMemo(
    () => ({
      podcastId: podcastFeedId,
    }),
    [podcastFeedId],
  );

  const isFetchingInitialData = isFetching && !episodes.length;

  useEffect(() => {
    if (active) {
      dispatch(getMyPodcastFeed(podcastFeedId));
    }
  }, [active, dispatch, podcastFeedId]);

  return (
    <PodcastFeedDataContext.Provider value={contextValue}>
      <BemCssTransition
        unmountOnExit
        className={block('feed-data')}
        delay={{
          enter: 150,
        }}
        in={active}
        timeout={300}
        onEntered={setEntered}
        onExit={setExited}
      >
        <div>
          <PodcastHeading
            podcastId={podcastFeedId}
            showRefreshButton={canRefreshFeed}
          />

          {isFetchingInitialData && (
            <InfiniteScrollLoader
              loadingText="Loading episodes"
              spinnerClassName={block('data-spinner')}
            />
          )}

          {!isFetchingInitialData && episodes && workflows && (
            <AutomationCMSEpisodeList
              hasMore={entered && active && hasMore}
              {...{ episodes, workflows, onLoadMoreEpisodes }}
            />
          )}
        </div>
      </BemCssTransition>
    </PodcastFeedDataContext.Provider>
  );
};

export default PodcastFeedData;

import * as Immutable from 'immutable';
import * as ids from 'short-id';
import _, { mapObject } from 'underscore';

import { getValue } from 'utils/collections';
import { domTreeToHtmlString, htmlStringToTreeWalker } from 'utils/dom';
import measurement, { Measurement, Pixels } from 'utils/measurement';
import { applyStyleToHtml, splitStyle } from 'utils/rte';
import {
  createTextShadowString,
  parseTextShadowString,
  RENDER_OFFSCREEN_RULES,
  setStyle,
} from 'utils/ui';
import { percentageOf, scale } from '../numbers';

function getDurationInMillis(duration) {
  return duration * 1000;
}

export function getDurationInSec(duration) {
  return duration / 1000;
}

function getTransition(overlay, direction, target) {
  let effect;
  let options = {};
  let syncMode;
  let delay;
  const uiTransition = overlay.getIn(['transitions', direction, 'value']);
  const duration = getDurationInMillis(
    overlay.getIn(['transitions', direction, 'duration']),
  );

  switch (uiTransition) {
    case 'slide':
      effect = 'slide';
      break;
    case 'zoomIn':
      effect = 'zoom';
      options = { direction: 'in' };
      break;
    case 'zoomOut':
      effect = 'zoom';
      options = { direction: 'out' };
      break;
    case 'fadeInLeft':
      effect = 'fade';
      options = { to: 'right' };
      break;
    case 'wipeInRight':
      effect = 'wipe';
      options = { to: 'right' };
      break;
    case 'fadeOutRight':
      effect = 'fade';
      options = { to: 'right' };
      break;
    case 'wipeOutRight':
      effect = 'wipe';
      options = { to: 'right' };
      break;
    case 'perWordCut':
      effect = 'cut';
      options = { to: 'right' };
      syncMode = target === 'text' ? 'word' : undefined;
      break;
    case 'perWordSlideUp':
      if (target === 'text') {
        effect = 'cut';
        delay = 25;
        syncMode = 'word';
      } else {
        effect = 'fade';
        options = { to: 'top' };
      }
      break;
    case 'inFrameSlide':
      if (target === 'text') {
        syncMode = 'paragraph';
        delay = 400;
        effect = 'slide';
      } else {
        effect = 'slide';
      }
      break;
    case 'inFrameSlideUp':
      if (target === 'text') {
        syncMode = 'paragraph';
        delay = 400;
        effect = 'slide';
        options = { to: 'top' };
      } else {
        effect = 'slide';
      }
      break;
    case 'outFrameSlide':
      if (target === 'text') {
        effect = 'slide';
        options = { to: 'right' };
        syncMode = 'paragraph';
      } else {
        delay = duration / 2;
        effect = 'slide';
        options = { to: 'right' };
      }
      break;
    case 'outFrameSlideUp':
      if (target === 'text') {
        effect = 'slide';
        options = { to: 'top' };
        syncMode = 'paragraph';
      } else {
        delay = duration / 2;
        effect = 'slide';
        options = { to: 'top' };
      }
      break;
    case 'cut':
      effect = 'cut';
      break;
    case 'fadeIn':
    case 'fadeOut':
      effect = 'dissolve';
      break;
    default:
      effect = 'cut';
  }

  return { effect, options, syncMode, delay, duration };
}

// This function looks at the overlay to see if there are any property fields that need to be
// added to the config object
function getConfigProperties(overlay) {
  const containerInTransition = overlay.getIn(['transitions', 'in', 'value']);
  if (
    containerInTransition === 'inFrameSlide' ||
    containerInTransition === 'inFrameSlideUp'
  ) {
    return {
      overflow: {
        target: 'paragraph',
        value: 'hidden',
      },
    };
  }

  return {};
}

function getUiTransition(transition, inTransition, textTransition) {
  switch (transition.effect) {
    case 'zoom':
      return transition.options && transition.options.direction === 'in'
        ? 'zoomIn'
        : 'zoomOut';
    case 'fade':
      if (transition.options && transition.options.to === 'top')
        return 'perWordSlideUp';
      return inTransition ? 'fadeInLeft' : 'fadeOutRight';
    case 'wipe':
      return inTransition ? 'wipeInRight' : 'wipeOutRight';
    case 'slide':
      if (
        textTransition.effect === 'slide' &&
        textTransition.syncMode === 'paragraph' &&
        textTransition.target === 'text'
      ) {
        if (textTransition.options && textTransition.options.to === 'top') {
          return inTransition ? 'inFrameSlideUp' : 'outFrameSlideUp';
        }
        return inTransition ? 'inFrameSlide' : 'outFrameSlide';
      }
      return 'slide';
    case 'fadeInLeft':
    case 'fadeOutRight':
    case 'wipeInRight':
    case 'wipeOutRight':
      return transition.effect;
    case 'cut':
      return transition.options && transition.options.to === 'right'
        ? 'perWordCut'
        : 'cut';
    case 'dissolve':
      return inTransition ? 'fadeIn' : 'fadeOut';
    default:
      return 'cut';
  }
}

export function scaleInlineStyles(htmlString, styleKeys, scaler) {
  if (!htmlString) return undefined;

  const keys = Array.isArray(styleKeys) ? styleKeys : [styleKeys];

  const walker = htmlStringToTreeWalker(htmlString);
  const root = walker.currentNode;
  while (walker.nextNode()) {
    const node = walker.currentNode;
    keys.forEach(key => {
      const value = node.style[key];
      if (value) {
        node.style[key] = scaler(parseFloat(value), key);
      }
    });
  }

  const result = domTreeToHtmlString(root);

  /*
   * NOTE: domTreeToHtmlString will replace single and double quotes with &quot;.  so, for example
   * the tag
   *    <div style="font-family: 'Lobster Two', cursive;" />
   * will become
   *   <div style="font-family: &quot;Lobster Two&quot;, cursive;" />
   *
   * It seems like the server doesn't handle &quot;, so replace all instances of &quot; in the
   * style property with single quotes
   */
  return result.replace(/style="[^"]+"/g, match =>
    match.replace(/&quot;/g, ''),
  );
}

function parsePaddings(styles) {
  const {
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  } = styles;
  if (padding) {
    return {
      paddingTop: parseFloat(padding),
      paddingRight: parseFloat(padding),
      paddingBottom: parseFloat(padding),
      paddingLeft: parseFloat(padding),
    };
  }

  return {
    paddingTop: parseFloat(paddingTop),
    paddingRight: parseFloat(paddingRight),
    paddingBottom: parseFloat(paddingBottom),
    paddingLeft: parseFloat(paddingLeft),
  };
}

// This maps the container decorator that we keep internally into a format that can be saved
// The internal state contains a display name that is required to show the option for the
// decorator to the user, but that display name is not technically part of the config, so we
// need to split it out into a separate section for the editor data.  This function returns
// an object of form { editorData, styleData }, where editorData goes into the editorData key
// and styleData goes under the container decorator key
function getContainerDecoratorForConfig(containerDecorator) {
  if (!containerDecorator) return {};
  const name = containerDecorator.get('name');
  if (!containerDecorator.get('styles')) return { styleData: { name } };

  const processedStyleData = containerDecorator.get('styles').reduce(
    (stylesAcc, style) => {
      const convertedStyle = style.reduce(
        (acc, styleProp) => {
          acc.configStyle[styleProp.get('propName')] = styleProp.get(
            'propValue',
          );
          acc.editorMetadata[styleProp.get('propName')] = styleProp.get(
            'displayName',
          );
          return acc;
        },
        { configStyle: {}, editorMetadata: {} },
      );

      stylesAcc.configStyles.push(convertedStyle.configStyle);
      stylesAcc.editorStylesMetadata.push(convertedStyle.editorMetadata);
      return stylesAcc;
    },
    { configStyles: [], editorStylesMetadata: [] },
  );

  return {
    editorData: processedStyleData.editorStylesMetadata,
    styleData: {
      name,
      styles: processedStyleData.configStyles,
    },
  };
}

// This function reverses the process done in getContainerDecoratorForConfig.  It assumes that
// there is editor metadata and a style
function getContainerDecoratorFromConfig(overlay) {
  const { containerDecorator } = overlay;
  const metadata =
    overlay && overlay.editor && overlay.editor.containerDecoratorMetadata;

  if (!containerDecorator) return undefined;
  if (
    !containerDecorator.styles ||
    containerDecorator.styles.length <= 0 ||
    !metadata
  ) {
    return { name: containerDecorator.name };
  }

  const styles = containerDecorator.styles.reduce((acc, style, index) => {
    acc.push(
      Object.keys(style).reduce((propList, propName) => {
        const displayName = metadata[index] && metadata[index][propName];
        propList.push({
          displayName,
          propName,
          propValue: style[propName],
        });
        return propList;
      }, []),
    );
    return acc;
  }, []);

  return {
    styles,
    name: containerDecorator.name,
  };
}

function formatOverlayForConfig(overlay, trackIndex) {
  const vw = overlay.getIn(['viewport', 'width']);
  const vh = overlay.getIn(['viewport', 'height']);
  const containerDecoratorData = getContainerDecoratorForConfig(
    overlay.get('containerDecorator'),
  );
  const templateId = overlay.get('templateId');

  return {
    advancedTextConfigs: overlay.get('advancedTextConfigs')?.toJS(),
    advancedAnimation: overlay.get('advancedAnimation')?.toJS(),
    text: overlay.get('text'),
    textHtml: scaleInlineStyles(
      overlay.get('textHtml'),
      'fontSize',
      val => `${percentageOf(val, vw)}vw`,
    ),
    startMilli: overlay.getIn(['time', 'startMillis']),
    endMilli: overlay.getIn(['time', 'endMillis']),
    layerId: trackIndex,
    position: {
      type: 'absolute',
      properties: {
        top: `${percentageOf(overlay.getIn(['position', 'top']), vh)}vh`,
        left: `${percentageOf(overlay.getIn(['position', 'left']), vw)}vw`,
      },
    },
    textStyle: {
      background: overlay.getIn(['style', 'textHighlight']),
      textDecoration: overlay.getIn(['style', 'textDecoration']),
      ...overlay.get('textStyle')?.toJS(),
    },
    containerStyle: {
      background: overlay.getIn(['style', 'background']),
      color: overlay.getIn(['style', 'color']),
      fontFamily: overlay.getIn(['style', 'fontFamily']),
      fontSize: `${percentageOf(overlay.getIn(['style', 'fontSize']), vw)}vw`,
      fontStyle: overlay.getIn(['style', 'fontStyle']),
      fontWeight: overlay.getIn(['style', 'fontWeight']),
      height: `${percentageOf(overlay.getIn(['size', 'height']), vh)}vh`,
      lineHeight: overlay.getIn(['style', 'lineHeight']),
      paddingTop: `${overlay.getIn(['style', 'paddingTop'])}vw`,
      paddingRight: `${overlay.getIn(['style', 'paddingRight'])}vw`,
      paddingBottom: `${overlay.getIn(['style', 'paddingBottom'])}vw`,
      paddingLeft: `${overlay.getIn(['style', 'paddingLeft'])}vw`,
      textAlign: overlay.getIn(['style', 'textAlign']),
      textShadow: createTextShadowString(
        overlay.getIn(['style', 'textShadow']),
      ),
      width: `${percentageOf(overlay.getIn(['size', 'width']), vw)}vw`,
    },
    containerDecorator: containerDecoratorData.styleData,
    properties: { ...getConfigProperties(overlay) },
    transitions: {
      in: [
        {
          target: 'container',
          sync: true,
          delay: 0,
          ...getTransition(overlay, 'in', 'container'),
        },
        {
          target: 'text',
          sync: true,
          delay: 0,
          ...getTransition(overlay, 'in', 'text'),
        },
      ],
      out: [
        {
          target: 'text',
          sync: true,
          delay: 0,
          ...getTransition(overlay, 'out', 'text'),
        },
        {
          target: 'container',
          sync: true,
          delay: 0,
          ...getTransition(overlay, 'out', 'container'),
        },
      ],
    },
    editor: {
      containerDecoratorMetadata: containerDecoratorData.editorData,
      scaler: overlay.getIn(['editor', 'scaler']),
      styleContext: {
        fontSize: overlay.getIn(['style', 'fontSize']),
        viewport: {
          height: vh,
          width: vw,
        },
      },
      templateId,
      textStyle: overlay.getIn(['editor', 'textStyle']),
    },
    textBuilderStyles: overlay.get('textBuilderStyles'),
    version: overlay.get('version'),
  };
}

function formatOverlayFromConfig(overlay) {
  // TODO what to do for older configs when ctx doesn't exist
  const ctx = overlay && overlay.editor && overlay.editor.styleContext;
  const viewport = ctx && ctx.viewport;
  const vw = (viewport && viewport.width) || 1000;
  const vh = (viewport && viewport.height) || (vw * 9) / 16;

  const { height, width, padding, ...style } = overlay.containerStyle;
  const textShadow = parseTextShadowString(overlay.containerStyle.textShadow);
  const transitions = {
    containerIn: overlay.transitions.in.find(t => t.target === 'container'),
    textIn: overlay.transitions.in.find(t => t.target === 'text'),
    containerOut: overlay.transitions.out.find(t => t.target === 'container'),
    textOut: overlay.transitions.out.find(t => t.target === 'text'),
  };

  const containerDecorator = getContainerDecoratorFromConfig(overlay);
  return Immutable.fromJS({
    advancedTextConfigs: getValue(overlay, ['advancedTextConfigs']),
    advancedAnimation: getValue(overlay, ['advancedAnimation']),
    containerDecorator,
    id: ids.generate(),
    editor: getValue(overlay, ['editor']),
    position: {
      left: (parseFloat(overlay.position.properties.left) / 100) * vw,
      top: (parseFloat(overlay.position.properties.top) / 100) * vh,
    },
    size: {
      height: (parseFloat(overlay.containerStyle.height) / 100) * vh,
      width: (parseFloat(overlay.containerStyle.width) / 100) * vw,
    },
    style: {
      ...style,
      fontSize:
        (ctx && ctx.fontSize) ||
        (parseFloat(overlay.containerStyle.fontSize) / 100) * vw,
      ...parsePaddings(overlay.containerStyle),
      textShadow: {
        x: textShadow.x,
        y: textShadow.y,
        blur: textShadow.blur,
        color: textShadow.color,
      },
      textHighlight: overlay.textStyle.background || 'transparent',
      textDecoration: overlay.textStyle.textDecoration || 'none',
    },
    templateId: getValue(overlay, ['editor', 'templateId']),
    textBuilderStyles: getValue(overlay, ['textBuilderStyles']),
    text: overlay.text,
    textHtml: scaleInlineStyles(
      overlay.textHtml,
      'fontSize',
      val => `${(val / 100) * vw}px`,
    ),
    time: {
      startMillis: overlay.startMilli,
      endMillis: overlay.endMilli,
    },
    transitions: {
      in: {
        duration: getDurationInSec(transitions.textIn.duration),
        value: getUiTransition(
          transitions.containerIn,
          true,
          transitions.textIn,
        ),
      },
      out: {
        duration: getDurationInSec(transitions.textOut.duration),
        value: getUiTransition(
          transitions.containerOut,
          false,
          transitions.textOut,
        ),
      },
    },
    version: getValue(overlay, ['version']),
    viewport: {
      height: vh,
      width: vw,
    },
  });
}

function scaleTemplate(template, overlayViewport) {
  const templateHeight = getValue(template, ['viewport', 'height']);
  const templateWidth = getValue(template, ['viewport', 'width']);
  const overlayHeight = overlayViewport.get('height');
  const overlayWidth = overlayViewport.get('width');

  const scaleByHeight = _.partial(scale, _, templateHeight, overlayHeight);
  const scaleByWidth = _.partial(scale, _, templateWidth, overlayWidth);

  const scaleStyle = style =>
    !style || !style.fontSize
      ? style
      : {
          ...style,
          fontSize: scaleByWidth(style.fontSize),
        };

  return {
    ...template,
    containerStyle: scaleStyle(template.containerStyle),
    position: {
      left: scaleByWidth(template.position.left),
      top: scaleByWidth(template.position.top),
    },
    size: {
      height: scaleByHeight(template.size.height),
      width: scaleByWidth(template.size.width),
    },
    textStyle: scaleStyle(template.textStyle),
  };
}

/**
 * Applies a template to a collection of text overlay objects.
 *
 * The template should be in the same format as utils/text-templates.  This function applies the
 */
export function applyTemplateToOverlays(overlaysById, template) {
  return overlaysById.map(overlay => {
    const scaledTemplate = scaleTemplate(template, overlay.get('viewport'));
    const { containerDecorator, templateId } = template;
    const { containerStyle, textStyle } = scaledTemplate;
    const { outerStyle, innerStyle } = splitStyle(containerStyle);
    const { textHighlight } = textStyle;
    const inlineStyle = {
      ...innerStyle,
      ...textStyle,
      ...(textHighlight ? { background: textHighlight } : {}),
    };

    return overlay.withMutations(o => {
      o.set('textHtml', applyStyleToHtml(o.get('textHtml'), inlineStyle));
      o.set('style', Immutable.Map(outerStyle));
      o.set('size', Immutable.Map(scaledTemplate.size));
      o.set('position', Immutable.Map(scaledTemplate.position));
      o.set('transitions', Immutable.fromJS(template.transitions));
      o.set('containerDecorator', Immutable.fromJS(containerDecorator));
      o.set('templateId', templateId);
      o.delete('advancedTextConfigs');
      o.deleteIn(['editor', 'textStyle']);
      o.delete('version');
      return o;
    });
  });
}

const DRAGGING_DISABLED_TEMPLATE_IDS = new Set([
  'copyright1',
  'copyrightTextAmharic',
  'gradient1',
  'gradientSubtitleAmharic',
  'leftInsert1',
  'leftInsertAmharic',
  'rightInsert1',
  'rightInsertAmharic',
  'srseName',
  'srseOccupation',
  'srseP4',
]);

export function getDraggingEnabledByTemplateId(templateId) {
  return !DRAGGING_DISABLED_TEMPLATE_IDS.has(templateId);
}

const ALL_RESIZE_EDGES_DISABLED = {
  bottom: false,
  bottomLeft: false,
  bottomRight: false,
  left: false,
  right: false,
  top: false,
  topLeft: false,
  topRight: false,
};

export function getResizableEdgesByTemplateId(templateId) {
  switch (templateId) {
    case 'copyright1':
    case 'copyrightTextAmharic':
    case 'gradient1':
    case 'gradientSubtitleAmharic':
      return ALL_RESIZE_EDGES_DISABLED;

    case 'leftInsert1':
    case 'leftInsertAmharic':
    case 'srseName':
    case 'srseOccupation':
    case 'srseP4':
      return {
        ...ALL_RESIZE_EDGES_DISABLED,
        right: true,
      };

    case 'rightInsert1':
    case 'rightInsertAmharic':
      return {
        ...ALL_RESIZE_EDGES_DISABLED,
        left: true,
      };

    default:
      return undefined;
  }
}

/**
 * @param {Node} node text node containing the text to be measured
 */
function getFontSizeNode(textNode) {
  let fontSizeNode = textNode.parentNode;
  while (
    fontSizeNode &&
    [null, undefined, ''].includes(fontSizeNode.style.fontSize)
  ) {
    fontSizeNode = fontSizeNode.parentNode;
  }
  return fontSizeNode;
}

/**
 * Places text into container using given font size.  If there's no overflow,
 * the same font size is returned.  If there is overflow, decrease font size
 * until it fits in the box with no overflow.
 *
 * Font is decreased using binary search.  As the binary search algorithm runs,
 * the difference between low and high becomes smaller and smaller, converging
 * on the correct font size.  The correct font size is found when the difference
 * between `low` and `high` is <= `epsilon`.
 *
 * @param {Node} textNode node containing the contents to be replaced
 * @param {Object} dimensions the dimensions into which the text needs to fit
 * @param {Measurement} dimensions.height the height of the container
 * @param {Measurement} dimensions.width the width of the container
 * @param {Object} containerStyle styles that should be aplied to the container.
 *  Measurement values are expected to be in `Measurement` units
 * @param {number} [frameAspectRatio=1] aspect ratio of the frame, used to convert
 *    Measurements to pixels when using relative values like vw/vh
 * @param {number} [epsilon=0.5] the margin which determines when the correct value is found
 * @param {Node} [parent=document.body] an element to which this function attaches a dom tree in order
 *  to run its calculations
 */
export function getTextFitFontSize(
  textNode,
  dimensions,
  containerStyle,
  frameAspectRatio = 1,
  epsilon = 0.05,
  parent = document.body,
) {
  const frameSize = {
    height: new Pixels(1000 / frameAspectRatio),
    width: new Pixels(1000),
  };

  const toPx = val =>
    val instanceof Measurement
      ? val.toUnit('px', frameSize)
      : measurement(val).toUnit('px', frameSize);

  const normalizedContainerStyle = mapObject(containerStyle, val =>
    val instanceof Measurement ? toPx(val).toString() : val,
  );

  const fontSizeNode = getFontSizeNode(textNode);
  const root = fontSizeNode.getRootNode();
  const initialFontSize = measurement(fontSizeNode.style.fontSize);

  const frame = document.createElement('div');
  setStyle(frame, {
    height: frameSize.height.toString(),
    width: frameSize.width.toString(),
    ...RENDER_OFFSCREEN_RULES,
  });

  const container = document.createElement('div');
  setStyle(container, {
    height: toPx(dimensions.height).toString(),
    width: toPx(dimensions.width).toString(),
    ...normalizedContainerStyle,
  });

  container.appendChild(root);
  frame.appendChild(container);
  parent.appendChild(frame);

  const isOverflowing = () => {
    return (
      container.scrollHeight > container.clientHeight ||
      container.scrollWidth > container.clientWidth
    );
  };

  const decreaseFontSize = (lo, hi) => {
    if (hi.minus(lo).value <= epsilon) {
      const finalSize = lo.toUnit(initialFontSize.unit, frameSize);
      fontSizeNode.style.fontSize = finalSize.toString();
      return lo;
    }

    const mid = lo.plus(hi).divideBy(2);
    fontSizeNode.style.fontSize = mid.toString();

    if (isOverflowing()) {
      return decreaseFontSize(lo, mid);
    }

    return decreaseFontSize(mid, hi);
  };

  try {
    if (!isOverflowing()) {
      fontSizeNode.style.fontSize = initialFontSize.toString();
      return initialFontSize;
    }

    return decreaseFontSize(toPx('0px'), toPx(initialFontSize));
  } finally {
    frame.remove();
  }
}

export function fitOverlayText(
  textOverlay,
  epsilon = 0.05,
  parent = document.body,
) {
  const walker = htmlStringToTreeWalker(
    textOverlay.get('textHtml'),
    NodeFilter.SHOW_TEXT,
  );

  const root = walker.currentNode;
  const textNode = walker.nextNode();

  getTextFitFontSize(
    textNode,
    {
      height: textOverlay.getIn(['size', 'height']),
      width: textOverlay.getIn(['size', 'width']),
    },
    textOverlay.getIn(['viewport', 'height']) /
      textOverlay.getIn(['viewport', 'width']),
  );

  return textOverlay.set('textHtml', domTreeToHtmlString(root));
}

export function getFontSize(htmlString) {
  const walker = htmlStringToTreeWalker(htmlString, NodeFilter.SHOW_TEXT);
  const textNode = walker.nextNode();
  const fontSizeNode = getFontSizeNode(textNode);

  try {
    return measurement(fontSizeNode.style.fontSize);
  } catch (error) {
    return measurement(0, 'vh');
  }
}

export { formatOverlayForConfig, formatOverlayFromConfig };

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEqual } from 'underscore';
import { CropInfo } from 'blocks/VideoCropper/types';
import {
  calculateCropInfo,
  getScaledVideoDimensions,
} from 'blocks/VideoCropper/utils';
import { CropperSides } from 'components/Cropper/types';
import usePrevious from 'hooks/usePrevious';
import { Size } from 'types';
import { Pixels, ViewportHeight, ViewportWidth } from 'utils/measurement';
import { fitElement, getScaleFactors } from 'utils/placement';
import { getVideoSize } from 'utils/video';
import {
  scaleCropDimensions,
  transformVideoDimensions,
} from 'utils/video-crop';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { PLACEMENT_CHANGE_TYPE_MAP } from '../layers/utils';
import { VideoClip } from '../types';
import useVideo from '../useVideo';

export interface UseDraggableVideoAssetCropConfig {
  videoId: string;
}

export interface UseDraggableVideoAssetCropResult {
  videoDimensions: Size<number> | undefined;
  boundingBox: CropperSides;
  assetContainerBoundingBox: Size<ViewportHeight, ViewportWidth>;
  onResize: (dimensions: Size<number>) => void;
}

export default function useDraggableVideoAssetCrop({
  videoId,
}: UseDraggableVideoAssetCropConfig): UseDraggableVideoAssetCropResult {
  const [videoFileSize, setVideoFileSize] = useState<
    Size<number> | undefined
  >();
  // Adjusted dimensions are computed after scaling, accounting for cropping adjustments.
  const [adjustedDimensions, setAdjustedDimensions] = useState<
    Size<number> | undefined
  >();
  // Scaled dimensions represent how the video is resized to fit within the canvas.
  const [scaledDimensions, setScaledDimensions] = useState<
    Size<number> | undefined
  >();
  // Stores the original details of the video clip before any transformations.
  const [originalVideoClipDetails, setOriginalVideoClipDetails] = useState<
    VideoClip | undefined
  >();

  const isFetchingFileSizeRef = useRef(false);

  const { canvas } = useTemplateState();
  const dispatch = useTemplateDispatch();
  const videoClip = useVideo(videoId);
  const prevMetadata = usePrevious(videoClip?.metadata);
  const prevFitType = usePrevious(videoClip?.fitType);
  const prevVideoUrl = usePrevious(videoClip?.videoUrl);

  // Calculates the original crop info using the video file size and canvas dimensions.
  const originalCropInfo = useMemo(
    (): CropInfo =>
      videoFileSize &&
      calculateCropInfo(
        videoFileSize,
        canvas?.width,
        canvas?.height,
        1,
        0,
        0,
        1,
      ),
    [canvas, videoFileSize],
  );

  // Computes the bounding box for the asset container in viewport units.
  const assetContainerBoundingBox = useMemo(():
    | Size<ViewportHeight, ViewportWidth>
    | undefined => {
    if (!scaledDimensions || !canvas) {
      return undefined;
    }

    const newDimensions = transformVideoDimensions(scaledDimensions, canvas);

    return {
      width: new ViewportWidth(newDimensions?.width),
      height: new ViewportHeight(newDimensions?.height),
    };
  }, [canvas, scaledDimensions]);

  // Determines the current video dimensions based on cropping and scaling.
  const videoDimensions = useMemo((): Size<number> | undefined => {
    const { crop, imageData } = videoClip?.metadata ?? {};
    const { naturalWidth, naturalHeight } = imageData ?? {};

    if (!crop || !videoFileSize || !imageData) {
      return undefined;
    }

    return (
      scaledDimensions ??
      getScaledVideoDimensions(
        videoFileSize,
        canvas,
        videoClip?.metadata,
        scaleCropDimensions(crop, videoFileSize, {
          width: naturalWidth,
          height: naturalHeight,
        }),
      )
    );
  }, [canvas, scaledDimensions, videoClip, videoFileSize]);

  // Determines the bounding box for the crop region.
  const boundingBox = useMemo((): CropperSides | undefined => {
    const { crop, imageData } = videoClip?.metadata ?? {};
    const { naturalWidth, naturalHeight } = imageData ?? {};

    if (!crop || !videoFileSize || !imageData) {
      return undefined;
    }

    return scaleCropDimensions(crop, videoFileSize, {
      width: naturalWidth,
      height: naturalHeight,
    });
  }, [videoClip, videoFileSize]);

  // Fetches video file size and updates state.
  const handleVideoFileSize = useCallback(async (): Promise<void> => {
    if (isFetchingFileSizeRef.current) {
      return undefined;
    }

    isFetchingFileSizeRef.current = true;

    try {
      const fileSize = await getVideoSize(videoClip?.videoUrl);

      setVideoFileSize(fileSize);
    } catch {
      throw new Error('Error fetching video file size');
    } finally {
      isFetchingFileSizeRef.current = false;
    }

    return undefined;
  }, [videoClip]);

  // Handles resizing and updates both scaled and adjusted dimensions.
  const handleResize = useCallback(
    (resizedDimensions: Size<number>): void => {
      setScaledDimensions(resizedDimensions);

      const newDimensions = fitElement(
        originalCropInfo?.dimension,
        resizedDimensions,
        'fill',
      );

      setAdjustedDimensions({
        width: newDimensions?.width?.value,
        height: newDimensions?.height?.value,
      });
    },
    [originalCropInfo],
  );

  // Adjusts the scaled dimensions when the cropping cofiguration changes.
  useEffect(() => {
    if (videoClip?.metadata && !isEqual(prevMetadata, videoClip?.metadata)) {
      const { metadata, placement } = videoClip;
      const { crop, imageData } = metadata;
      const { naturalWidth, naturalHeight } = imageData;
      const { left, top } = placement;

      const scaledCrop = scaleCropDimensions(
        crop,
        adjustedDimensions ?? originalCropInfo?.dimension,
        {
          width: naturalWidth,
          height: naturalHeight,
        },
      );

      const newWidth = Math.abs(scaledCrop?.left - scaledCrop?.right);
      const newHeight = Math.abs(scaledCrop?.top - scaledCrop?.bottom);

      if (!prevMetadata) {
        dispatch({
          type: PLACEMENT_CHANGE_TYPE_MAP?.videoClip,
          payload: {
            id: videoId,
            placement: {
              left: left?.toUnit('px', canvas),
              top: top?.toUnit('px', canvas),
              width: new Pixels(newWidth),
              height: new Pixels(newHeight),
            },
          },
        });
      }

      setScaledDimensions({
        width: newWidth,
        height: newHeight,
      });
    }
  }, [
    canvas,
    dispatch,
    adjustedDimensions,
    originalCropInfo,
    prevMetadata,
    videoClip,
    videoId,
  ]);

  useEffect(() => {
    if (!videoFileSize && !isFetchingFileSizeRef.current) {
      handleVideoFileSize();
    }
  }, [handleVideoFileSize, videoFileSize]);

  // Resets the state values with the new video info when the video is replaced.
  if (prevVideoUrl !== videoClip?.videoUrl) {
    const { width, height } = videoClip?.placement ?? {};

    if (!videoClip?.placement || !canvas) {
      return undefined;
    }

    const newDimensions = transformVideoDimensions(
      {
        width: width?.value,
        height: height?.value,
      },
      {
        width: 100,
        height: 100,
      },
      {
        width: canvas?.width,
        height: canvas?.height,
      },
    );

    handleVideoFileSize();

    setScaledDimensions(newDimensions);
    setAdjustedDimensions(newDimensions);
    setOriginalVideoClipDetails(videoClip);
  }

  // Adjusts the scaled dimensions when the fitType changes (double click on video asset).
  if (
    videoClip?.metadata?.crop &&
    prevFitType !== videoClip?.fitType &&
    videoClip?.fitType
  ) {
    const { scaleX, scaleY } = getScaleFactors(scaledDimensions, canvas);

    const newWidth =
      scaleY > scaleX ? scaledDimensions?.width * scaleY : canvas?.width;
    const newHeight =
      scaleY > scaleX ? canvas?.height : scaledDimensions?.height * scaleX;

    setScaledDimensions({
      width: newWidth,
      height: newHeight,
    });
  }

  if (!originalVideoClipDetails && videoClip) {
    setOriginalVideoClipDetails(videoClip);
  }

  return {
    videoDimensions,
    boundingBox,
    assetContainerBoundingBox,
    onResize: handleResize,
  };
}

import { useCallback, useMemo, useState } from 'react';
import { ClipSuggestion } from 'blocks/ClipSelect/types';

export type UseSuggestionsProps = {
  suggestedClips: ClipSuggestion[];
};

/**
 * Hook to manage suggestion state
 */
export default function useSuggestions({
  suggestedClips,
}: UseSuggestionsProps) {
  const [removedSuggestionIds, setRemovedSuggestionIds] = useState<number[]>(
    [],
  );

  const suggestionIds = useMemo(() => suggestedClips.map(clip => clip.id), [
    suggestedClips,
  ]);

  const visibleSuggestionIds = useMemo(
    () => suggestionIds?.filter(id => !removedSuggestionIds.includes(id)),
    [removedSuggestionIds, suggestionIds],
  );

  const hasClipSuggestions = !!suggestionIds?.length;

  const findSuggestion = useCallback(
    (id: number) => suggestedClips.find(c => c.id === id),
    [suggestedClips],
  );

  const removeSuggestion = useCallback((id: number) => {
    setRemovedSuggestionIds(ids => ids.concat(id));
  }, []);

  return {
    findSuggestion,
    hasClipSuggestions,
    removeSuggestion,
    suggestedClips,
    suggestionIds,
    visibleSuggestionIds,
  };
}

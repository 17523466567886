import * as React from 'react';
import { FormGroup } from 'react-bootstrap';

import CopyToClipboard from 'components/CopyToClipboard';
import { block } from '../../utils';
import useOnDownloadPageAction from '../useOnDownloadPageAction';

export interface CopyVideoLinkFormProps {
  videoUrl: string;
}

const CopyVideoLinkForm: React.FC<CopyVideoLinkFormProps> = ({ videoUrl }) => {
  const { onDownloadPageAction } = useOnDownloadPageAction();

  return (
    <FormGroup className={block('copy-form')}>
      <p>Copy this URL to view or share:</p>
      <CopyToClipboard
        buttonLabel="copy"
        id="download-url"
        onCopyClick={() => onDownloadPageAction('videoLinkCopy')}
        value={videoUrl}
      />
    </FormGroup>
  );
};

export default CopyVideoLinkForm;

import React from 'react';

import {
  CapitalizedTextIcon,
  LowercaseTextIcon,
  UppercaseTextIcon,
} from 'components/icons';
import { ToggleConfigByCase } from './types';

export const CASE_TOOLTIP_BASE = 'Displays captions as';

export const TOGGLE_CONFIG_BY_CASE: ToggleConfigByCase = {
  none: {
    tooltipMessage: `${CASE_TOOLTIP_BASE} typed`,
    icon: <CapitalizedTextIcon />,
  },
  uppercase: {
    tooltipMessage: `${CASE_TOOLTIP_BASE} uppercase`,
    icon: <UppercaseTextIcon />,
  },
  lowercase: {
    tooltipMessage: `${CASE_TOOLTIP_BASE} lowercase`,
    icon: <LowercaseTextIcon />,
  },
};

import * as React from 'react';

import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { Download } from 'components/icons';
import { videoUrlSelector } from 'redux/modules/download';
import bem from 'utils/bem';
import { downloadLinkFromS3 } from 'utils/download';
import ShareButton from '../../components/ShareButton';
import useOnDownloadPageAction from '../useOnDownloadPageAction';

export interface DownloadButtonProps {
  fileName?: string;
  single: boolean;
}

const block = bem('download-button');

const DownloadButton: React.FC<DownloadButtonProps> = ({
  fileName,
  single,
}) => {
  const url = useSelector(videoUrlSelector);

  const { onDownloadPageAction } = useOnDownloadPageAction();

  const handleDownloadClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();

      downloadLinkFromS3(url, fileName);

      onDownloadPageAction('downloadVideo');
    },
    [fileName, onDownloadPageAction, url],
  );

  if (single) {
    return (
      <Button
        fluid
        shape="chip"
        theme="submit"
        href={url}
        onClick={handleDownloadClick}
      >
        <Download width={16} height="100%" />
        <span>Download video</span>
      </Button>
    );
  }

  return (
    <ShareButton
      className={block()}
      download={fileName}
      href={url}
      label="Download"
      onClick={() => onDownloadPageAction('downloadVideo')}
    >
      <Download width={35} />
    </ShareButton>
  );
};

export default DownloadButton;

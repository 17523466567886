export enum Type {
  LOAD_DATA_REQUEST = 'app/download/DATA_LOAD_REQUEST',
  LOAD_DATA_SUCCESS = 'app/download/DATA_LOAD_SUCCESS',
  LOAD_DATA_FAILURE = 'app/download/DATA_LOAD_FAILURE',

  DOWNLOAD_CLEAR = 'app/download/CLEAR',

  DOWNLOAD_PAGE_ACTION = 'app/download/DOWNLOAD_PAGE_ACTION',

  SOCIAL_POST_CAPTIONS_CREATE_REQUEST = 'app/download/SOCIAL_POST_CAPTIONS_CREATE_REQUEST',
  SOCIAL_POST_CAPTIONS_CREATE_SUCCESS = 'app/download/SOCIAL_POST_CAPTIONS_CREATE_SUCCESS',
  SOCIAL_POST_CAPTIONS_CREATE_FAILURE = 'app/download/SOCIAL_POST_CAPTIONS_CREATE_FAILURE',

  SOCIAL_POST_CAPTIONS_POLLING_REQUEST = 'app/download/SOCIAL_POST_CAPTIONS_POLLING_REQUEST',
  SOCIAL_POST_CAPTIONS_POLLING_SUCCESS = 'app/download/SOCIAL_POST_CAPTIONS_POLLING_SUCCESS',
  SOCIAL_POST_CAPTIONS_POLLING_FAILURE = 'app/download/SOCIAL_POST_CAPTIONS_POLLING_FAILURE',
  SOCIAL_POST_CAPTIONS_POLLING_END = 'app/download/SOCIAL_POST_CAPTIONS_POLLING_END',

  VIDEO_SOCIAL_POST_CAPTIONS_CREATE_REQUEST = 'app/download/VIDEO_SOCIAL_POST_CAPTIONS_CREATE_REQUEST',
  VIDEO_SOCIAL_POST_CAPTIONS_CREATE_SUCCESS = 'app/download/VIDEO_SOCIAL_POST_CAPTIONS_CREATE_SUCCESS',
  VIDEO_SOCIAL_POST_CAPTIONS_CREATE_FAILURE = 'app/download/VIDEO_SOCIAL_POST_CAPTIONS_CREATE_FAILURE',

  VIDEO_SOCIAL_POST_CAPTIONS_GET_SUCCESS = 'app/download/VIDEO_SOCIAL_POST_CAPTIONS_GET_SUCCESS',
  VIDEO_SOCIAL_POST_CAPTIONS_POLLING_REQUEST = 'app/download/VIDEO_SOCIAL_POST_CAPTIONS_POLLING_REQUEST',
  VIDEO_SOCIAL_POST_CAPTIONS_POLLING_SUCCESS = 'app/download/VIDEO_SOCIAL_POST_CAPTIONS_POLLING_SUCCESS',
  VIDEO_SOCIAL_POST_CAPTIONS_POLLING_FAILURE = 'app/download/VIDEO_SOCIAL_POST_CAPTIONS_POLLING_FAILURE',
  VIDEO_SOCIAL_POST_CAPTIONS_POLLING_END = 'app/download/VIDEO_SOCIAL_POST_CAPTIONS_POLLING_END',

  EMBED_VIDEO_SOCIAL_POST_CAPTIONS_CREATE_REQUEST = 'app/download/EMBED_VIDEO_SOCIAL_POST_CAPTIONS_CREATE_REQUEST',
  EMBED_VIDEO_SOCIAL_POST_CAPTIONS_CREATE_SUCCESS = 'app/download/EMBED_VIDEO_SOCIAL_POST_CAPTIONS_CREATE_SUCCESS',
  EMBED_VIDEO_SOCIAL_POST_CAPTIONS_CREATE_FAILURE = 'app/download/EMBED_VIDEO_SOCIAL_POST_CAPTIONS_CREATE_FAILURE',

  EMBED_VIDEO_SOCIAL_POST_CAPTIONS_GET_SUCCESS = 'app/download/EMBED_VIDEO_SOCIAL_POST_CAPTIONS_GET_SUCCESS',
  EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_REQUEST = 'app/download/EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_REQUEST',
  EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_SUCCESS = 'app/download/EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_SUCCESS',
  EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_FAILURE = 'app/download/EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_FAILURE',
  EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_END = 'app/download/EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_END',
}

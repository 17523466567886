import { Color } from '@sparemin/blockhead';
import React from 'react';

import HistoricalColorPicker from 'containers/HistoricalColorPicker';

import { textControlsBlock as block } from '../utils';
import TextControl from './TextControl';

interface TextColorPickerProps {
  onChange: (color: string) => void;
  value: string;
}

const TextColorPicker: React.FunctionComponent<TextColorPickerProps> = props => {
  const { onChange, value } = props;

  return (
    <TextControl icon={<Color />} label="Color" layout="horizontal">
      <HistoricalColorPicker
        color={value}
        onChangeComplete={onChange}
        popoverClassName={block('color-picker-popover')}
        popoverStyle={{
          top: undefined,
          right: 90,
        }}
        swatchClassName={block('color-picker')}
      />
    </TextControl>
  );
};

export default TextColorPicker;

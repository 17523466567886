import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import MediaCard from 'components/MediaCard';
import RecommendationStamp from 'components/RecommendationStamp';
import { block } from './utils';

interface SourceCardOptionsProps {
  isFreeTier: boolean;
  onBackCatalogClick: () => void;
  onManualCreationClick: () => void;
}

const SourceCardOptions: React.FC<SourceCardOptionsProps> = ({
  isFreeTier,
  onBackCatalogClick,
  onManualCreationClick,
}) => {
  return (
    <Spacer
      orientation="horizontal"
      align="center"
      justify="space-between"
      className={block()}
    >
      <MediaCard isLocked={isFreeTier} onClick={onBackCatalogClick}>
        <MediaCard.MediaCardImage imgSrc="https://static.sparemin.com/static/webapp-assets/backgrounds/back-catalog-card-image-202303011921.png" />

        <MediaCard.MediaCardContent
          stamp={
            <RecommendationStamp
              description="Save time through automation"
              theme="youtube"
            />
          }
          title="A podcast’s entire back catalog"
          description="Connect a podcast to a video template and we’ll turn every episode into full-length audiograms automatically. We can even auto-post them for you."
          buttonContent={
            isFreeTier ? 'upgrade to unlock →' : 'set up automation →'
          }
        />
      </MediaCard>

      <MediaCard onClick={onManualCreationClick}>
        <MediaCard.MediaCardImage imgSrc="https://static.sparemin.com/static/webapp-assets/backgrounds/manual-creation-card-image-202303011921.png" />

        <MediaCard.MediaCardContent
          title="A specific episode or audio file"
          description="Select a podcast episode or upload an audio file to turn it into a full-length audiogram. This option is great for a single audiogram, but less efficient for promoting an entire podcast."
          buttonContent="select your audio →"
        />
      </MediaCard>
    </Spacer>
  );
};

export default SourceCardOptions;

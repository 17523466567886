import { usePreviousRef } from '@sparemin/blockhead';
import { useEffect } from 'react';
import { EddyClipSuggestion } from './types';
import {
  useMessageDispatch,
  UseMessageDispatchProps,
} from './useEddyMessaging';

export type UseLoadSuggestedClipsProps = Pick<
  UseMessageDispatchProps,
  'iframe'
> & {
  suggestedClips: EddyClipSuggestion[] | undefined;
};

export default function useLoadSuggestedClips({
  iframe,
  suggestedClips,
}: UseLoadSuggestedClipsProps) {
  const lastLoadedSuggestions = usePreviousRef<EddyClipSuggestion[]>(undefined);
  const dispatch = useMessageDispatch({ iframe });

  useEffect(() => {
    if (suggestedClips !== lastLoadedSuggestions.current) {
      lastLoadedSuggestions.current = suggestedClips;
      dispatch({
        type: 'customSuggestedClipsLoad',
        suggestions: suggestedClips,
      });
    }
  }, [dispatch, lastLoadedSuggestions, suggestedClips]);
}

import React from 'react';

import { ImmutableTextPreset } from 'redux/modules/text-presets';

import { useTextOverlay } from '../state/TextOverlayProvider';
import { transformPresetToStateStyles } from '../style-transform-utils';

interface UseTextOverlayPresets {
  onSelectPreset: (nextPreset: ImmutableTextPreset) => void;
  selectedPreset: ImmutableTextPreset;
}

const useTextOverlayPresets = (): UseTextOverlayPresets => {
  const {
    draftEditorData,
    onSelectPreset,
    onUpdateOverlay,
    selectedPreset,
  } = useTextOverlay();

  const handleSelectPreset = React.useCallback(
    (nextPreset: ImmutableTextPreset): void => {
      onSelectPreset(nextPreset);
      onUpdateOverlay(
        () => transformPresetToStateStyles(nextPreset, draftEditorData),
        true,
      );
    },
    [draftEditorData, onSelectPreset, onUpdateOverlay],
  );

  return {
    onSelectPreset: handleSelectPreset,
    selectedPreset,
  };
};

export default useTextOverlayPresets;

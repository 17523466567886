import * as React from 'react';

import { block } from '../utils';

export interface ProjectsEmptyListProps {}

const ProjectsEmptyList: React.FC<ProjectsEmptyListProps> = () => (
  <div className={block('empty')}>
    <div className={block('empty-message')}>No projects yet</div>

    <span>Projects you start will automatically appear here</span>
  </div>
);

export default ProjectsEmptyList;

import { RecordOf } from 'immutable';
import { useSelector } from 'react-redux';

import {
  audiogramLastUsedStyleSelector,
  autogramLastUsedStyleSelector,
  fullEpisodeLastUsedStyleSelector,
  stateSelector,
  videoTranscriptionLastUsedStyleSelector,
} from 'redux/modules/wizard-last-used-style';
import {
  WizardLastUsedStyleData,
  WizardName,
} from 'redux/modules/wizard-last-used-style/types';

export default function useLastUsedStyleSelector(wizardName: WizardName) {
  const lastUsedStyleSelector = useSelector(stateSelector);
  const audiogramLastUsedStyle = useSelector(audiogramLastUsedStyleSelector);
  const fullEpisodeLastUsedStyle = useSelector(
    fullEpisodeLastUsedStyleSelector,
  );
  const autogramLastUsedStyle = useSelector(autogramLastUsedStyleSelector);
  const videoTranscriptionLastUsedStyle = useSelector(
    videoTranscriptionLastUsedStyleSelector,
  );

  return {
    audiogramLastUsedStyle,
    autogramLastUsedStyle,
    fullEpisodeLastUsedStyle,
    videoTranscriptionLastUsedStyle,
    lastUsedStyle: lastUsedStyleSelector.get(wizardName) as RecordOf<
      WizardLastUsedStyleData
    >,
  };
}

import * as React from 'react';

import DialogContent, {
  Props as DialogContentProps,
} from 'components/DialogContent';
import CircularGradientVideoCameraAlt from 'components/icons/circular-gradient/CircularGradientVideoCameraAlt';
import { DEFAULT_UPGRADE_UPLOAD_LIMIT } from 'utils/constants';
import { Variant } from './types';

type PickedDialogProps = Pick<DialogContentProps, 'actions' | 'title'>;

export interface FileSizeUpsellDialogProps extends PickedDialogProps {
  className?: string;
  variant: Variant;
}

const TITLES: Record<Variant, string> = {
  default: `Increase your upload limit to ${DEFAULT_UPGRADE_UPLOAD_LIMIT}`,
  file: 'This file is too big for our free tier',
};

const FileSizeUpsellDialog: React.FC<FileSizeUpsellDialogProps> = ({
  actions,
  className,
  variant,
}) => (
  <DialogContent
    {...{ actions, className }}
    icon={<CircularGradientVideoCameraAlt />}
    title={TITLES[variant]}
    details={`Our Forever Free plan only supports up to 500MB file uploads, but you can upgrade to increase your upload limit to ${DEFAULT_UPGRADE_UPLOAD_LIMIT}`}
  />
);

export default FileSizeUpsellDialog;

import { Button } from '@sparemin/blockhead';
import cn from 'classnames';
import * as React from 'react';
import { RouteChildrenProps } from 'react-router';
import { isUndefined, noop } from 'underscore';
import {
  DESTINATION_PRESETS,
  DestinationPlatform,
  VideoTypeConfig,
} from 'blocks/DestinationPlatforms';
import { RejectedReason } from 'components/FileUploader';
import { renderTemplateDimensionsInfoByAspectRatio } from 'components/FileUploader/utils';
import LinkButton from 'components/LinkButton';
import Tooltip from 'components/Tooltip';
import { UseHook } from 'components/UseHook';
import { TemplateEditorStateExport } from 'components/VideoTemplateEditor';
import Wizard, {
  IndexedStep,
  Navigator,
  Step,
  WizardProps,
} from 'components/Wizard';
import { MediaImportedArgs } from 'containers/MediaUploadModal/types';
import useTranscriptionBalance from 'hooks/useTranscriptionBalance';
import { Tier } from 'redux/middleware/api/plan-service';
import {
  MixpanelWizardStep,
  OnWizardNextProps,
} from 'redux/modules/mixpanel/types';
import { VideoUploadArgs } from 'redux/modules/video-upload';
import { AspectRatioName } from 'types';
import { ApplicationError } from 'utils/ApplicationError';
import { getAspectRatioValue } from 'utils/aspect-ratio';
import bem from 'utils/bem';

import {
  DEFAULT_PROJECT_NAME,
  SAMPLE_VIDEO_URL,
  VIDEO_CLIP_MAX_DURATION_SECONDS,
} from 'utils/constants';
import { round } from 'utils/numbers';
import { capitalize } from 'utils/string';
import { millisToSec } from 'utils/time';
import { addParamsToUrl } from 'utils/url';
import AspectRatioStep from '../AspectRatioStep';
import ClipVideoStep, { ClipSelectionMode } from '../ClipVideoStep';
import { TranscriptionFormValue } from '../types';
import UploadVideoStep from '../UploadVideoStep';
import { CLIP_STEP_CONTINUE_BTN_TEXT } from './constants';
import useVideoWizardBlocker from './useVideoWizardBlocker';
import VideoWizardCustomizeStep from './VideoWizardCustomizeStep';
import VideoWizardDestinationStep from './VideoWizardDestinationStep';
import {
  VideoWizardMultipleClipsExportStep,
  VideoWizardSingleClipExportStep,
} from './VideoWizardExportStep';
import EpisodeWizardSourceStep from './VideoWizardObjectiveStep';
import { VideoWizardObjective } from './VideoWizardObjectiveStep/types';
import VideoWizardProgressStep from './VideoWizardProgressStep';

type PickedWizardProps = Pick<WizardProps, 'onFileUpload'>;

interface IProps
  extends PickedWizardProps,
    Partial<RouteChildrenProps<{}, { file: any }>> {
  tier: Tier;
  onCancel?: (nav: Navigator) => void;
  onClickSampleFile?: () => void;
  onCustomizeStepError?: () => void;
  onError?: (
    error: ApplicationError,
    file?: File,
    reason?: RejectedReason,
  ) => void;
  onMount?: () => void;
  onSubmit?: (
    args: VideoUploadArgs,
    projectName: string,
    mediaImportedArgs?: MediaImportedArgs,
  ) => void;
  onStepChange?: (stepId: string, props?: OnWizardNextProps) => void;
  onCompleted?: () => void;
}

interface IState {
  aspectRatioName: AspectRatioName;
  step: string;
  clipEndMillis: number;
  clipSelectionMode: ClipSelectionMode;
  clipStartMillis: number;
  videoSrc: string;
  isSampleVideo: boolean;
  language: string;
  transcribe: boolean;
  mediaImportedArgs: MediaImportedArgs;
  objective: VideoWizardObjective;
  projectName: string;
  transcription: TranscriptionFormValue;
  clipSuggestionLengthSecs: number;
  platform: DestinationPlatform;
  videoType?: VideoTypeConfig;
  isAutoFrame?: boolean;
}

enum Steps {
  ASPECT_RATIO = 0,
  UPLOAD_VIDEO = 1,
  CLIP_VIDEO = 2,
}

const clipMaxDuration = VIDEO_CLIP_MAX_DURATION_SECONDS * 1000;

const block = bem('video-wizard');

export default class VideoWizard extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    onCancel: noop,
    onClickSampleFile: noop,
    onError: noop,
    onFileUpload: noop,
    onMount: noop,
    onStepChange: noop,
    onSubmit: noop,
  };

  private wizard: Wizard;
  private videoDurationMillis: number;

  // will only be set if user uploads file, not if user clicks the sample video link
  private videoFile: File;

  public state: IState = {
    step: undefined,
    aspectRatioName: 'square',
    clipEndMillis: undefined,
    clipSelectionMode: 'clip',
    clipStartMillis: 0,
    isSampleVideo: false,
    language: 'en-US',
    transcribe: true,
    videoSrc: undefined,
    mediaImportedArgs: undefined,
    objective: undefined,
    projectName: DEFAULT_PROJECT_NAME,
    transcription: {},
    clipSuggestionLengthSecs: undefined,
    platform: undefined,
    videoType: undefined,
    isAutoFrame: undefined,
  };

  public componentDidMount() {
    const { onMount } = this.props;

    onMount();
  }

  private clearDefaultFile = () => {
    const { location, history } = this.props;
    if (location.state?.file) {
      const { state, ...locationWithoutState } = location;
      history.replace(locationWithoutState);
    }
  };

  private handleStepChange = (to: IndexedStep, from?: IndexedStep) => {
    const { objective } = this.state;
    const { onStepChange } = this.props;

    /*
     * user already selected a video but navigated back to a step where a video shouldn't be
     * selected.
     */
    if (
      from &&
      from.index >= Steps.UPLOAD_VIDEO &&
      to.index <= Steps.UPLOAD_VIDEO
    ) {
      this.videoFile = undefined;
      this.setVideoSrc();
      this.setState(prev => ({
        ...prev,
        clipEndMillis: undefined,
      }));
    }

    this.setState(prev => ({ ...prev, step: to.stepId }));

    const isAspectRatioStep =
      from?.stepId === 'objective' && objective === 'SingleClip';

    onStepChange(from?.stepId, {
      type: 'videoTranscription',
      step: isAspectRatioStep
        ? 'Choose Aspect Ratio'
        : (from?.stepId as MixpanelWizardStep),
      ...(from?.stepId === 'objective' &&
        !isAspectRatioStep && { objectiveSelection: objective }),
    });
  };

  private handleAspectRatioSelect = (__, name: AspectRatioName) =>
    this.setState({
      aspectRatioName: name,
    });

  private handleObjectiveSelect = (newObjective: VideoWizardObjective) => {
    const { onStepChange } = this.props;

    this.setState({
      objective: newObjective,
    });

    if (newObjective === 'MultipleClips') {
      this.wizard.next();
    }

    if (newObjective === 'SingleClip') {
      onStepChange('objective', {
        type: 'videoTranscription',
        step: 'objective',
        objectiveSelection: 'SingleClip',
      });
    }
  };

  private handleReturnToAllOptions = () => {
    this.setState({
      objective: undefined,
    });
  };

  private handleFileUploadAccepted = (file: File) => {
    const { onFileUpload } = this.props;

    this.clearDefaultFile();

    this.videoFile = file;
    this.setVideoSrc(URL.createObjectURL(file));

    this.setState({
      projectName: file.name,
    });

    onFileUpload(file);
  };

  private handleFileUploadRejected = (
    error: ApplicationError,
    file?: File,
    reason?: RejectedReason,
  ) => {
    const { onError } = this.props;

    this.clearDefaultFile();

    onError?.(error, file, reason);
  };

  private handleSampleVideoClick = () => {
    const { onClickSampleFile } = this.props;
    onClickSampleFile();
    // There is a rare issue that can happen if the user opens this video
    // out side of the app. If that happens it is possible that the sample
    // video CORS headers are not going to be set and that will later produce
    // the export to get stuck. Adding a unique param will prevent this
    // issue from happening for the sample video.
    this.setVideoSrc(
      addParamsToUrl(SAMPLE_VIDEO_URL, { nonce: Date.now() }),
      true,
    );

    this.wizard.next();
  };

  private handleMediaAccepted = (
    mediaImportedArgs: MediaImportedArgs,
  ): void => {
    if (!mediaImportedArgs) {
      return;
    }

    this.setVideoSrc(mediaImportedArgs.previewUrl, false);
    this.setState({
      mediaImportedArgs,
      projectName: mediaImportedArgs.title,
    });
  };

  private handleVideoExportStart = () => {
    this.wizard.next();
  };

  private handleLanguageSelect = (language: string) =>
    this.setState({ language });

  private reInitClipSelectionMillis = (
    currClipSelectionMode: ClipSelectionMode,
  ): void => {
    // each time the selection mode is change the selection should be adjusted
    const nextClipEndMillis =
      currClipSelectionMode === 'full-episode'
        ? this.videoDurationMillis
        : Math.min(this.videoDurationMillis, clipMaxDuration);

    this.setState({
      clipStartMillis: 0,
      clipEndMillis: nextClipEndMillis,
    });
  };

  private handleVideoDurationChange = (millis: number) => {
    const { clipSelectionMode } = this.state;

    this.videoDurationMillis = millis;
    this.reInitClipSelectionMillis(clipSelectionMode);
  };

  private handleClipMillisChange = (startMillis: number, endMillis: number) =>
    this.setState({
      clipEndMillis: round(endMillis),
      clipStartMillis: round(startMillis),
    });

  private handleVideoSrcRevoke = () => {
    const { videoSrc, mediaImportedArgs } = this.state;

    // Might not (but likely will) have an object url here but no harm in calling it either way.
    URL.revokeObjectURL(videoSrc);

    if (mediaImportedArgs) {
      URL.revokeObjectURL(mediaImportedArgs.previewUrl);
    }
  };

  private handleDestinationChosen = (
    platform: DestinationPlatform,
    videoType?: VideoTypeConfig,
  ) => {
    const platformInfo =
      platform in DESTINATION_PRESETS
        ? DESTINATION_PRESETS?.[platform]?.[videoType.id]
        : DESTINATION_PRESETS.more?.[platform];

    this.setState({
      platform,
      videoType,
      aspectRatioName: platformInfo?.aspectRatio,
      clipSuggestionLengthSecs: millisToSec(platformInfo?.durationMs),
    });

    this.wizard.next();
  };

  private handleSubmitSingleClip = (exportState: TemplateEditorStateExport) => {
    const {
      aspectRatioName,
      clipEndMillis,
      clipSelectionMode,
      clipStartMillis,
      language,
      transcribe,
      videoSrc,
      projectName,
      isAutoFrame,
      mediaImportedArgs,
      platform,
      videoType,
    } = this.state;

    this.handleVideoSrcRevoke();

    const { onSubmit } = this.props;
    const isFullEpisode = clipSelectionMode === 'full-episode';
    const videoPodcastType = isFullEpisode ? 'FullEpisode' : 'SingleClip';

    onSubmit(
      {
        aspectRatioName,
        src: this.videoFile || videoSrc,
        durationMillis: this.videoDurationMillis,
        initiateExport: isFullEpisode,
        isFullEpisodeExport: isFullEpisode,
        trimStartMillis: clipStartMillis,
        trimEndMillis: clipEndMillis,
        language,
        transcribe,
        platform,
        videoType,
        isAutoFrame,
        isClipSuggestion: false,
        exportState,
        videoPodcastType,
      },
      projectName,
      mediaImportedArgs,
    );
  };

  private handleSubmitMultipleClips = (
    exportState: TemplateEditorStateExport,
  ) => {
    const {
      clipEndMillis,
      clipStartMillis,
      language,
      transcribe,
      videoSrc,
      projectName,
      platform,
      videoType,
      aspectRatioName,
      clipSuggestionLengthSecs,
      isAutoFrame,
      mediaImportedArgs,
    } = this.state;

    this.handleVideoSrcRevoke();

    const { onSubmit } = this.props;

    onSubmit(
      {
        aspectRatioName,
        src: this.videoFile || videoSrc,
        durationMillis: this.videoDurationMillis,
        trimStartMillis: clipStartMillis,
        trimEndMillis: clipEndMillis,
        language,
        transcribe,
        platform,
        videoType,
        clipSuggestionLengthSecs,
        isAutoFrame,
        isClipSuggestion: true,
        exportState,
        videoPodcastType: 'MultipleClips',
      },
      projectName,
      mediaImportedArgs,
    );
  };

  private handleSubmit = exportState => {
    const { onStepChange } = this.props;
    const { objective } = this.state;

    if (objective === 'SingleClip') {
      this.handleSubmitSingleClip(exportState);
    } else {
      this.handleSubmitMultipleClips(exportState);
    }

    this.wizard.next();

    onStepChange('template');
  };

  private setVideoSrc(src?: string, isSample: boolean = false) {
    this.setState({
      isSampleVideo: !src ? undefined : isSample,
      videoSrc: !src ? undefined : src,
    });
  }

  private handleTranscriptionChange = (transcription: TranscriptionFormValue) =>
    this.setState({
      transcribe: transcription.transcribe,
      language: transcription.language,
    });

  private handleIsAutoFrameChange = (isAutoFrame: boolean) =>
    this.setState({
      isAutoFrame,
    });

  private setClipSelectionMode = (
    nextClipSelectionMode: ClipSelectionMode,
  ): void => {
    this.setState({ clipSelectionMode: nextClipSelectionMode });
    this.reInitClipSelectionMillis(nextClipSelectionMode);
  };

  private getExportStepContent = (): React.ReactElement => {
    const { onError } = this.props;
    const { clipEndMillis, clipStartMillis, objective } = this.state;

    if (objective === 'MultipleClips') {
      return <VideoWizardMultipleClipsExportStep onError={onError} />;
    }

    return (
      <VideoWizardSingleClipExportStep
        durationMillis={clipEndMillis - clipStartMillis}
        onError={onError}
      />
    );
  };

  private getSteps({
    canTranscribe,
    newBalanceMillis,
    outOfTimeMessage,
    showBalance,
  }: ReturnType<typeof useTranscriptionBalance>): Step[] {
    const {
      onCompleted,
      onError,
      onCustomizeStepError,
      tier,
      location,
    } = this.props;

    const {
      aspectRatioName,
      clipEndMillis,
      clipSelectionMode,
      clipStartMillis,
      isSampleVideo,
      language,
      transcribe,
      videoSrc,
      objective,
      clipSuggestionLengthSecs,
      mediaImportedArgs,
    } = this.state;

    const isSingleClip = objective === 'SingleClip';
    const isUndefinedOrMultipleClipsObjective = !objective || !isSingleClip;

    return [
      {
        completedName: isUndefinedOrMultipleClipsObjective
          ? 'Objective'
          : capitalize(aspectRatioName),
        component: isUndefinedOrMultipleClipsObjective ? (
          <EpisodeWizardSourceStep
            onSelectObjective={this.handleObjectiveSelect}
          />
        ) : (
          <AspectRatioStep
            onSelect={this.handleAspectRatioSelect}
            value={aspectRatioName}
          />
        ),
        description: isSingleClip && (
          <LinkButton
            uppercase
            theme="cta"
            size="md"
            className={block('back-link')}
            onClick={this.handleReturnToAllOptions}
          >
            ← return to all options
          </LinkButton>
        ),
        name: 'Objective',
        stepId: 'objective',
        title: isSingleClip && 'Select an aspect ratio',
        ...(isUndefinedOrMultipleClipsObjective && {
          renderCancelButton: () => null,
          renderNextButton: () => null,
        }),
      },
      {
        completedName: 'Video Selected',
        component: (
          <UploadVideoStep
            templateDimensionsInfo={
              objective === 'MultipleClips'
                ? null
                : renderTemplateDimensionsInfoByAspectRatio(
                    getAspectRatioValue(aspectRatioName),
                  )
            }
            uploadMethod="mediaImporter"
            language={language}
            onError={this.handleFileUploadRejected}
            onFileAccepted={this.handleFileUploadAccepted}
            onLanguageSelect={this.handleLanguageSelect}
            onMediaAccepted={this.handleMediaAccepted}
            /*
             * clicking the sample video should automatically advance to the next step, so the
             * isSampleVideo check is here to prevent the uploader from flashing the "selected
             * video" state when transitioning to the next step
             */
            videoSelected={!isSampleVideo && !isUndefined(videoSrc)}
            footer={
              <LinkButton onClick={this.handleSampleVideoClick}>
                No video file? Try our sample.
              </LinkButton>
            }
            className={block('uploader')}
            tier={tier}
            defaultFile={location.state?.file}
          />
        ),
        name: 'Upload Video',
        renderNextButton: props => (
          <Wizard.Button
            {...props}
            disabled={isUndefined(videoSrc)}
            theme="next"
          >
            Next
          </Wizard.Button>
        ),
        stepId: 'uploadVideo',
      },
      {
        completedName: 'Video Clipped',
        // both the single and multiple clip flow use the loading state rendered by the VideoWizardDestinationStep
        component: isUndefinedOrMultipleClipsObjective ? (
          <VideoWizardDestinationStep
            onDestinationChosen={this.handleDestinationChosen}
          />
        ) : (
          <ClipVideoStep
            aspectRatioName={aspectRatioName}
            clipEndMillis={clipEndMillis}
            clipSelectionMode={clipSelectionMode}
            clipStartMillis={clipStartMillis}
            onChangeClipSelectionMode={this.setClipSelectionMode}
            onDurationChange={this.handleVideoDurationChange}
            onSelectedMillisChange={this.handleClipMillisChange}
            src={videoSrc}
            showTranscriptionBalance={showBalance}
            transcriptionBalanceMillis={newBalanceMillis}
            captionsControl={
              isUndefinedOrMultipleClipsObjective ? null : (
                <Tooltip
                  content={outOfTimeMessage}
                  show={!canTranscribe ? undefined : false}
                  placement="top"
                  id="video-clip-tooltip"
                >
                  <Button
                    className={block('create-button')}
                    disabled={!canTranscribe}
                    fluid
                    onPress={() => this.wizard.next()}
                    variant="solid"
                  >
                    {CLIP_STEP_CONTINUE_BTN_TEXT[clipSelectionMode]}
                  </Button>
                </Tooltip>
              )
            }
          />
        ),
        name: isUndefinedOrMultipleClipsObjective
          ? 'Clip Selection'
          : 'Clip Video',
        renderNextButton: ({ className, ...props }) => {
          if (
            isUndefinedOrMultipleClipsObjective ||
            clipSelectionMode === 'clip'
          ) {
            return null;
          }

          return (
            <Tooltip
              content={outOfTimeMessage}
              show={!canTranscribe ? undefined : false}
              placement="top"
              id="video-clip-tooltip"
            >
              <div
                className={cn(
                  block('create-button-wrapper', {
                    disabled: !canTranscribe,
                  }),
                  className,
                )}
              >
                <Button
                  {...props}
                  disabled={!canTranscribe}
                  fluid
                  onPress={() => this.wizard.next()}
                  variant="solid"
                >
                  {CLIP_STEP_CONTINUE_BTN_TEXT[clipSelectionMode]}
                </Button>
              </div>
            </Tooltip>
          );
        },
        ...(isUndefinedOrMultipleClipsObjective && {
          renderCancelButton: () => null,
        }),
        stepId: isSingleClip ? 'clipVideo' : 'destination',
      },
      {
        stepId: 'customize',
        name: 'Template',
        component: (
          <VideoWizardCustomizeStep
            ctaLabel={isSingleClip ? 'create project' : 'get your clips'}
            defaults={{
              aspectRatio: getAspectRatioValue(aspectRatioName),
            }}
            transcription={{ transcribe, language }}
            audioClipDurationSec={
              clipEndMillis
                ? millisToSec(clipEndMillis - clipStartMillis)
                : clipSuggestionLengthSecs
            }
            features={{
              templates: 'hidden',
              waveform: 'hidden',
              captions: true,
            }}
            placeholderVideo={{
              src: videoSrc,
              fileName: mediaImportedArgs?.title,
              trimStartMillis: clipStartMillis,
              trimEndMillis: clipEndMillis,
              framingMethod:
                isSingleClip || aspectRatioName === 'landscape'
                  ? 'fixed'
                  : 'autoframe',
            }}
            onError={onCustomizeStepError}
            onIsAutoFrameChange={this.handleIsAutoFrameChange}
            onTranscriptionChange={this.handleTranscriptionChange}
            onSubmit={this.handleSubmit}
          />
        ),
        renderCancelButton: () => null,
        renderNextButton: () => null,
      },
      {
        component: (
          <VideoWizardProgressStep
            clipSelectionMode={clipSelectionMode}
            isSingleClip={isSingleClip}
            onCompleted={onCompleted}
            onError={onError}
            onVideoExportStart={this.handleVideoExportStart}
          />
        ),
        name: 'loading',
        stepId: 'submitting',
        renderCancelButton: () => null,
        renderNextButton: () => null,
        showInNav: false,
      },
      {
        component: this.getExportStepContent(),
        name: 'finish',
        stepId: 'export',
        renderCancelButton: () => null,
        renderNextButton: () => null,
        showInNav: false,
      },
    ];
  }

  public render() {
    const { onCancel } = this.props;
    const {
      step,
      clipEndMillis,
      clipStartMillis,
      clipSelectionMode,
    } = this.state;

    return (
      <UseHook hook={useVideoWizardBlocker} args={[step]}>
        {onStepClick => (
          <UseHook
            hook={useTranscriptionBalance}
            args={[
              {
                durationMillis: clipEndMillis - clipStartMillis,
                transcriptionEnabled: true,
              },
            ]}
          >
            {transcriptionBalance => (
              <Wizard
                canBacktrack={step !== 'export' && step !== 'submitting'}
                className={block()}
                bodyClassName={block('body', {
                  [step]: true,
                  [clipSelectionMode]: true,
                })}
                buttonClassName={block('buttons', {
                  'with-balance': transcriptionBalance?.showBalance,
                  'free-width': step === 'clipVideo',
                  [clipSelectionMode]: true,
                })}
                onCancelClick={onCancel}
                onStepChange={this.handleStepChange}
                onStepClick={onStepClick}
                steps={this.getSteps(transcriptionBalance)}
                ref={el => {
                  this.wizard = el;
                }}
              />
            )}
          </UseHook>
        )}
      </UseHook>
    );
  }
}

export { IProps as VideoWizardProps };

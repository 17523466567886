import { TextOverlay } from 'blocks/TextOverlayModal';
import {
  isLegacyOverlayConvertAble,
  TextOverlayV2,
  updateOverlayToV2,
  validateOverlayV2Integrity,
} from 'blocks/TextOverlayModal/v2';
import bem from 'utils/bem';
import {
  removeCSSPropsFromInlineStyle,
  textContentFromHtmlString,
} from 'utils/dom';
import { LEGACY_TEXT_OVERLAY_STYLE_BLACKLIST } from './constants';

export const block = bem('text-overlay-switch');

export const transformOverlayToLegacy = (
  overlay?: TextOverlayV2,
): TextOverlay | undefined => {
  if (!overlay) {
    return overlay;
  }

  // in order to avoid carrying unconsitencies when switching, version is removed
  // as well as the editor text styles and the advanced text configs.
  return overlay.withMutations(s =>
    s
      .delete('advancedTextConfigs')
      .delete('advancedAnimation')
      .deleteIn(['editor', 'textStyle'])
      .deleteIn(['editor', 'scaler'])
      .delete('version')
      .delete('textBuilderStyles')
      .set(
        'textHtml',
        removeCSSPropsFromInlineStyle(
          overlay.get('textHtml'),
          LEGACY_TEXT_OVERLAY_STYLE_BLACKLIST,
        ),
      ),
  );
};

export const transformOverlayToV2 = (
  overlay?: TextOverlay,
): TextOverlayV2 | undefined => {
  if (!overlay) {
    return overlay;
  }

  const isV2 = validateOverlayV2Integrity(overlay);

  if (isV2) {
    return overlay;
  }

  const canBeConverted = isLegacyOverlayConvertAble(overlay);
  const textValue = textContentFromHtmlString(overlay.get('textHtml'));
  const overlayWithUpdatedText = overlay.set('text', textValue);

  if (canBeConverted) {
    return updateOverlayToV2(overlayWithUpdatedText);
  }

  try {
    return updateOverlayToV2(overlayWithUpdatedText);
  } catch {
    return undefined;
  }
};

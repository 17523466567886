import { ThemeProvider } from '@sparemin/blockhead';
import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'underscore';
import ClipSelectEditViewContainer from 'blocks/ClipSelect/components/ClipSelectEditViewContainer';
import EddyEmbed, { EddyElement } from 'blocks/EddyEmbed';
import BemCssTransition from 'components/BemCssTransition';
import bem from 'utils/bem';
import { useClipSelectAnimation } from '../ClipSelectAnimationContext';
import { useClipSelect } from '../ClipSelectContext';
import { HighlightedRegionState } from '../ClipSelectContext/useDefaultHighlightedRegion';
import { useClipSelectNavigation } from '../ClipSelectNavigationContext';

export interface ClipSelectTranscriptEditorProps {
  className?: string;
}

const block = bem('clip-select-transcript-editor');

function isEmptyRegion(
  region: HighlightedRegionState['transcript'],
): region is Record<string, never> {
  return isEmpty(region);
}

const ClipSelectTranscriptEditor: React.FC<ClipSelectTranscriptEditorProps> = ({
  className,
}) => {
  const {
    defaultTranscriptRegion,
    eddyProjectId,
    onSelectClip,
    eddySuggestedClips,
    onTranscriptUpdate,
  } = useClipSelect();

  const [, send] = useClipSelectNavigation();

  const animations = useClipSelectAnimation();

  const [eddy, setEddy] = useState<EddyElement>(null);

  const isEddySuggestion = useCallback(
    (suggestionId: number) =>
      eddySuggestedClips.findIndex(c => c.id === suggestionId) >= 0,
    [eddySuggestedClips],
  );

  // this is a simplification of navState - the effect below needs more data than
  // just whether or not the transcript editor is active, so it inspects navState
  // directly
  const isActive = animations.transcript.in;

  const focusClip = useCallback(() => {
    if (!eddy) {
      return;
    }

    if (isEmptyRegion(defaultTranscriptRegion)) {
      eddy.reset();
    } else if (
      defaultTranscriptRegion.suggestionId &&
      isEddySuggestion(defaultTranscriptRegion.suggestionId)
    ) {
      eddy.focusClip(defaultTranscriptRegion.suggestionId);
    } else {
      eddy.focusClip({
        startMillis: defaultTranscriptRegion.startMillis,
        endMillis: defaultTranscriptRegion.endMillis,
      });
    }
  }, [defaultTranscriptRegion, eddy, isEddySuggestion]);

  const handleClipSelect = useCallback(
    async clip => {
      send({ type: 'EDITOR_CLOSE' });

      await onSelectClip({ region: clip });

      animations.onClipSelected();
    },
    [animations, onSelectClip, send],
  );

  // workaround for Eddy issue.  suggested clips are rendered in Eddy by wrapping
  // some of the transcript words in elements to help with highlighting when
  // the user is browsing clips.  If something is highlighted when the page
  // receives new suggestions, the transcript will re-render and if the selection
  // was part of the re-render operation, the elements will get de-selected.
  //
  // as a workaround, when suggested clips load, refocus anything that was selected
  const handleClipsLoaded = useCallback(() => {
    focusClip();
  }, [focusClip]);

  useEffect(() => {
    focusClip();
  }, [focusClip]);

  useEffect(() => {
    if (!isActive && eddy) {
      // this will run more often than necessary but should be harmless to pause
      // an already-paused player
      eddy.pause();
    }
  }, [eddy, isActive]);

  return (
    <ThemeProvider theme="dark">
      <BemCssTransition
        className={cn(block(), className)}
        {...animations.transcript}
      >
        <ClipSelectEditViewContainer>
          {eddyProjectId && (
            <EddyEmbed
              className={block('transcript')}
              eddyProjectId={eddyProjectId}
              height="100%"
              onClipSelect={handleClipSelect}
              onTranscriptUpdate={onTranscriptUpdate}
              onSuggestedClipsLoaded={handleClipsLoaded}
              ref={setEddy}
              suggestedClips={eddySuggestedClips}
              width="100%"
            />
          )}
        </ClipSelectEditViewContainer>
      </BemCssTransition>
    </ThemeProvider>
  );
};

export default ClipSelectTranscriptEditor;

import * as React from 'react';

import { IconProps } from 'components/icons/types';

const UppercaseTextIcon: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="30"
    height="18"
    viewBox="0 0 30 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.481 17.75a.446.446 0 0 1-.338-.155A.52.52 0 0 1 0 17.23c0-.077.012-.155.037-.235L5.604.882C5.66.709 5.752.56 5.88.436c.128-.124.31-.186.545-.186H8.93c.235 0 .417.062.545.186.129.124.22.273.276.446l5.568 16.113c.024.08.036.158.036.235a.52.52 0 0 1-.143.365.446.446 0 0 1-.338.155h-2.012c-.21 0-.365-.056-.464-.168a1.095 1.095 0 0 1-.204-.309l-1.077-3.045H4.238l-1.076 3.045a.85.85 0 0 1-.193.309c-.1.112-.259.168-.476.168H.481zm4.566-6.393h5.262L7.673 3.62l-2.626 7.737zM23.062 18c-1.419 0-2.628-.267-3.627-.802a5.548 5.548 0 0 1-2.311-2.312c-.542-1.007-.84-2.211-.892-3.613a111.793 111.793 0 0 1 0-4.648c.052-1.384.351-2.569.897-3.554A5.572 5.572 0 0 1 19.46.797C20.47.266 21.67 0 23.062 0c1.127 0 2.111.16 2.954.482.843.321 1.547.738 2.113 1.25.565.511.992 1.058 1.28 1.639.287.58.438 1.127.454 1.64a.47.47 0 0 1-.13.373.487.487 0 0 1-.365.147H26.99c-.151 0-.265-.032-.343-.097a.917.917 0 0 1-.218-.311 3.717 3.717 0 0 0-.568-1.03c-.262-.347-.617-.637-1.066-.871-.45-.234-1.027-.35-1.733-.35-1.046 0-1.882.302-2.51.907-.626.605-.964 1.595-1.013 2.971a69.746 69.746 0 0 0 0 4.398c.049 1.413.393 2.43 1.033 3.05.64.62 1.482.93 2.528.93.697 0 1.32-.134 1.868-.404.55-.27.982-.69 1.298-1.258.316-.568.474-1.294.474-2.175v-.768h-2.83a.509.509 0 0 1-.398-.177.636.636 0 0 1-.158-.438V8.573a.63.63 0 0 1 .158-.446.519.519 0 0 1 .398-.17h5.52c.17 0 .307.057.412.17a.63.63 0 0 1 .158.446v2.626c0 1.401-.282 2.61-.846 3.627a5.78 5.78 0 0 1-2.404 2.35c-1.039.55-2.268.824-3.688.824z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default UppercaseTextIcon;

import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router';

import { TransitionGroup } from 'react-transition-group';
import BemCSSTransition from 'components/BemCssTransition';
import Page from 'components/Page';
import withRedirectOnMobile from 'containers/Redirect/withRedirectOnMobile';
import { creationClipSuggestionsIsBlockedSelector } from 'redux/modules/clip-select';
import { appHomeSelector } from 'redux/modules/router';
import bem from 'utils/bem';
import VideoTranscriptionSuggestedClipsPage from './VideoTranscriptionSuggestedClipsPage';
import VideoTranscriptionSuggestedClipsPageContainer from './VideoTranscriptionSuggestedClipsPageContainer';

const block = bem('download-routes');

const desktopOnly = withRedirectOnMobile(location =>
  location.pathname.substr(0, location.pathname.lastIndexOf('/') + 1),
);

const ProtectedVideoTranscriptionSuggestedClipsPage = desktopOnly(
  VideoTranscriptionSuggestedClipsPage,
);

const VideoTranscriptionSuggestedClipsRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  const isBlocked = useSelector(creationClipSuggestionsIsBlockedSelector);

  if (isBlocked) {
    return <Redirect to={appHomeSelector()} />;
  }

  return (
    <VideoTranscriptionSuggestedClipsPageContainer>
      <Page>
        <TransitionGroup>
          <BemCSSTransition
            key={location.pathname}
            timeout={{
              exit: 600,
              enter: 1200,
            }}
            unmountOnExit
            transitionClassName={block('page-animation')}
          >
            <Switch location={location}>
              <Route
                exact
                path={path}
                component={ProtectedVideoTranscriptionSuggestedClipsPage}
              />
            </Switch>
          </BemCSSTransition>
        </TransitionGroup>
      </Page>
    </VideoTranscriptionSuggestedClipsPageContainer>
  );
};

export default VideoTranscriptionSuggestedClipsRoutes;

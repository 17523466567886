import { getLegacyThemeColor, Spacer, useTheme } from '@sparemin/blockhead';
import React from 'react';
import { Clock } from 'components/icons';
import MediaCard from 'components/MediaCard';
import RecommendationStamp from 'components/RecommendationStamp';
import { VideoWizardObjective } from './types';
import { block } from './utils';

interface ObjectiveCardOptionsProps {
  onSelectObjective: (selectedObjectiveStepType: VideoWizardObjective) => void;
}

const ObjectiveCardOptions: React.FC<ObjectiveCardOptionsProps> = ({
  onSelectObjective,
}) => {
  const theme = useTheme();

  return (
    <Spacer
      orientation="horizontal"
      align="center"
      justify="space-between"
      className={block('objective-card-options')}
    >
      <MediaCard onClick={() => onSelectObjective('MultipleClips')}>
        <MediaCard.MediaCardVideo
          src={[
            'https://static.sparemin.com/static/webapp-assets/video-transcript-wizard/video-podcast-suggested-clips-202409201614.mp4',
            'https://static.sparemin.com/static/webapp-assets/video-transcript-wizard/video-podcast-suggested-clips-202409201614.webm',
          ]}
        />

        <MediaCard.MediaCardContent
          stamp={
            <RecommendationStamp
              icon={
                <Clock
                  height="12px"
                  color={getLegacyThemeColor('primary')({ theme })}
                />
              }
              description="Recommended for saving time"
            />
          }
          title="Get multiple captioned clips"
          description="Select an entire video podcast episode and we’ll turn it into multiple shareable clips with captions in your preferred style."
          buttonContent="Select an episode →"
        />
      </MediaCard>

      <MediaCard onClick={() => onSelectObjective('SingleClip')}>
        <MediaCard.MediaCardVideo
          src={[
            'https://static.sparemin.com/static/webapp-assets/video-transcript-wizard/video-podcast-manual-clips-202409201614.mp4',
            'https://static.sparemin.com/static/webapp-assets/video-transcript-wizard/video-podcast-manual-clips-202409201614.webm',
          ]}
        />

        <MediaCard.MediaCardContent
          title="Add captions to a single video"
          description="Easily add captions in your preferred style to any video podcast. Apply them to the entire episode or select a specific clip."
          buttonContent="Select an episode →"
        />
      </MediaCard>
    </Spacer>
  );
};

export default ObjectiveCardOptions;

export const TEXT_CASES = ['none', 'uppercase', 'lowercase'] as const;

export type TextCase = typeof TEXT_CASES[number];

export interface ToggleConfig {
  tooltipMessage: string;
  icon: React.ReactNode;
}

export type ToggleConfigByCase = Record<TextCase, ToggleConfig>;

import cn from 'classnames';
import * as React from 'react';

import { Omit } from 'types';
import { cardWizardBlock as block } from '../../../utils';
import CardWizardHeading, { CardWizardHeadingProps } from './CardWizardHeading';

export interface CardWizardStepProps {
  children?: React.ReactNode;
  className?: string;
}

interface CardWizardStepComponent extends React.FC<CardWizardStepProps> {
  H1: React.FC<Omit<CardWizardHeadingProps, 'level'>>;
  H2: React.FC<Omit<CardWizardHeadingProps, 'level'>>;
}

const CardWizardStep: CardWizardStepComponent = ({ children, className }) => (
  <div className={cn(block('step'), className)}>{children}</div>
);

CardWizardStep.H1 = props => <CardWizardHeading level={1} {...props} />;
CardWizardStep.H2 = props => <CardWizardHeading level={2} {...props} />;

export default CardWizardStep;

import classNames from 'classnames';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import { Col } from 'react-bootstrap';
import _ from 'underscore';

import VideoCropper, {
  PlaybackControlBar,
  VideoPosition,
  VideoZoomSlider,
} from 'blocks/VideoCropper';
import VideoClipper from 'components/VideoClipper';
import VideoOptions, {
  OnAudioOptionChange,
  OnVideoOptionChange,
} from 'components/VideoOptions';
import VideoPlayer from 'components/VideoPlayer';
import { VideoCropperMetadata } from 'containers/EditVideoModal/types';
import { positionFactory } from 'redux/modules/embed/actions/video';
import { StaticCrop } from 'redux/modules/embed/types';
import { Size } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { VIDEO_CLIP_MAX_DURATION_SECONDS } from 'utils/constants';
import { VideoScaling } from 'utils/embed/video';
import { min, round } from 'utils/numbers';

interface IValues {
  audioFadeInDurationMillis: number;
  audioFadeOutDurationMillis: number;
  audioLevel: number;
  blurredBackground: boolean;
  clipStartMillis: number;
  clipEndMillis: number;
  mainAudioLevel: number;
  mediaToReplaceId?: string;
  startMillis: number;
  endMillis: number;
  position: VideoPosition;
  scaling: VideoScaling;
  transitionIn: string;
  transitionOut: string;
  src: string;
  width: number;
  height: number;
  zoom?: number;
  transcriptionEnabled?: boolean;
  transcriptionLanguage?: string;
}

interface IProps {
  aspectRatio: number;
  className?: string;
  durationMillis: number;
  onLoadedMetadata?: () => void;
  onPause?: () => void;
  onPlay?: () => void;
  onUpgradeClick?: () => void;
  onAdjustCrop?: () => void;
  onDimensionsChange?: (dimensions: Size<number>) => void;
  playing?: boolean;
  poster?: string;
  previewColClassName?: string;
  showTranscriptionSection?: boolean;
  showTransformOptions?: boolean;
  videoSrc: string;
  boundingBox?: StaticCrop;
  showClipper?: boolean;
  defaultData?: RecordOf<IDataState>;
  videoFileSize?: Size<number>;
  cropMetadata?: VideoCropperMetadata;
  id?: string;
}

interface IDataState {
  audioFadeInDurationMillis: number;
  audioFadeOutDurationMillis: number;
  audioLevel: number;
  blurredBackground: boolean;
  lastPlayPosMillis: number;
  mainAudioLevel: number;
  mediaToReplaceId?: string;
  startMillis: number;
  transitionIn: string;
  transitionOut: string;
  clipStartMillis: number;
  clipEndMillis: number;
  playPositionMillis: number;
  playing: boolean;
  position: VideoPosition;
  scaling: VideoScaling;
  width: number;
  height: number;
  zoom: number;
  transcriptionEnabled: boolean;
  transcriptionLanguage: string;
}

interface IUiState {
  fillZoom?: number;
}

interface IState {
  data: RecordOf<IDataState>;
  ui: RecordOf<IUiState>;
}

export const dataFactory = Record<IDataState>({
  audioFadeInDurationMillis: undefined,
  audioFadeOutDurationMillis: undefined,
  audioLevel: undefined,
  blurredBackground: undefined,
  clipEndMillis: undefined,
  clipStartMillis: undefined,
  height: undefined,
  lastPlayPosMillis: undefined,
  mainAudioLevel: undefined,
  mediaToReplaceId: undefined,
  playing: false,
  playPositionMillis: undefined,
  position: undefined,
  scaling: undefined,
  startMillis: undefined,
  transitionIn: undefined,
  transitionOut: undefined,
  width: undefined,
  zoom: undefined,
  transcriptionEnabled: undefined,
  transcriptionLanguage: undefined,
});

const uiFactory = Record<IUiState>({
  fillZoom: undefined,
});

const clipMaxDuration = VIDEO_CLIP_MAX_DURATION_SECONDS * 1000;

export default class VideoFormWithPreview extends React.Component<
  IProps,
  IState
> {
  public static defaultProps: Partial<IProps> = {
    defaultData: dataFactory(),
    onLoadedMetadata: _.noop,
    onPause: _.noop,
    onPlay: _.noop,
    showTranscriptionSection: true,
    showClipper: false,
  };

  private player: VideoPlayer;

  constructor(props: IProps) {
    super(props);

    const { durationMillis, defaultData } = props;

    this.state = {
      data: dataFactory({
        blurredBackground: false,
        clipEndMillis: durationMillis,
        clipStartMillis: 0,
        lastPlayPosMillis: 0,
        playing: false,
        playPositionMillis: 0,
        transcriptionEnabled: false,
        transcriptionLanguage: 'en-US',
      }).mergeDeepWith(
        (oldVal, newVal) => (_.isUndefined(newVal) ? oldVal : newVal),
        defaultData,
      ),
      ui: uiFactory(),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const {
      playing: nextPlaying,
      durationMillis: nextDurationMillis,
    } = nextProps;
    const { playing, durationMillis } = this.props;

    if (!_.isUndefined(nextPlaying) && nextPlaying !== playing && this.player) {
      if (nextPlaying) {
        this.player.play();
      } else {
        this.player.pause();
      }
    }

    if (nextDurationMillis !== durationMillis) {
      this.setState(({ data }) => ({
        data: data.set(
          'clipEndMillis',
          min(nextDurationMillis, clipMaxDuration, data.clipEndMillis),
        ),
      }));
    }
  }

  private handlePositionChange = ({ isDragged, top, left, width, height }) =>
    this.setState(({ data }) => ({
      data: data.withMutations(d => {
        d.set('position', positionFactory({ top, left }))
          .set('width', width)
          .set('height', height);

        if (isDragged) d.delete('scaling');

        return d;
      }),
    }));

  private handleClipMillisChange = (startMillis: number, endMillis: number) => {
    this.setState(({ data }) => ({
      data: data.withMutations(u =>
        u
          .set('clipStartMillis', round(startMillis))
          .set(
            'clipEndMillis',
            min(round(endMillis), round(startMillis) + clipMaxDuration),
          ),
      ),
    }));
  };

  private handleVideoOptionChange: OnVideoOptionChange = (val, field) => {
    switch (field) {
      case 'blurredBackground':
        this.setState(({ data }) => ({
          data: data.set('blurredBackground', val),
        }));
        break;

      case 'resize':
        this.setState(({ data, ui }) => ({
          data: data.withMutations(d =>
            d
              .set('scaling', val)
              .set('zoom', val === VideoScaling.FIT ? 1 : ui.fillZoom),
          ),
        }));
        break;

      case 'transitionIn':
        this.setState(({ data }) => ({ data: data.set('transitionIn', val) }));
        break;

      case 'transitionOut':
        this.setState(({ data }) => ({ data: data.set('transitionOut', val) }));
        break;

      case 'startMillis':
        this.setState(({ data }) => ({ data: data.set('startMillis', val) }));
        break;

      case 'endMillis':
        this.setState(({ data }) => {
          const durationMillis = round(
            data.clipEndMillis - data.clipStartMillis,
          );
          return { data: data.set('startMillis', val - durationMillis) };
        });
        break;

      default:
    }
  };

  private handleAudioOptionChange: OnAudioOptionChange = (val, field) => {
    switch (field) {
      case 'videoAudioLevel':
        this.setState(({ data }) => ({ data: data.set('audioLevel', val) }));
        break;

      case 'mainAudioLevel':
        this.setState(({ data }) => ({
          data: data.set('mainAudioLevel', val),
        }));
        break;

      case 'fadeInDurationMillis':
        this.setState(({ data }) => ({
          data: data.set('audioFadeInDurationMillis', val),
        }));
        break;

      case 'fadeOutDurationMillis':
        this.setState(({ data }) => ({
          data: data.set('audioFadeOutDurationMillis', val),
        }));
        break;

      default:
    }
  };
  private handleFillZoomCalculated = (fillZoom: number) =>
    this.setState(({ ui }) => ({
      ui: ui.set('fillZoom', fillZoom),
    }));

  private handleZoomChange = ({ zoom }) =>
    this.setState(({ data }) => ({
      data: data.set('zoom', zoom).delete('scaling'),
    }));

  private handleToggleTranscriptionEnabled = (enabled: boolean) =>
    this.setState(({ data }) => ({
      data: data.set('transcriptionEnabled', enabled),
    }));

  private handleChangeTranscriptionLanguage = (language: string) =>
    this.setState(({ data }) => ({
      data: data.set('transcriptionLanguage', language),
    }));

  private renderVideoCropper = props => {
    const {
      aspectRatio,
      onLoadedMetadata,
      videoSrc,
      boundingBox,
      id,
      videoFileSize,
      cropMetadata,
      onDimensionsChange,
    } = this.props;
    const { data } = this.state;

    return (
      <VideoCropper
        {...props}
        aspectRatio={aspectRatio}
        videoUrl={videoSrc}
        zoom={data.zoom}
        id={id}
        blurBackground={data.blurredBackground}
        onPositionChange={this.handlePositionChange}
        defaultPosition={data.position}
        onCalculateFillZoom={this.handleFillZoomCalculated}
        onLoadedMetadata={onLoadedMetadata}
        resetPosition={!!data.scaling}
        boundingBox={boundingBox}
        videoFileSize={videoFileSize}
        cropMetadata={cropMetadata}
        onDimensionsChange={onDimensionsChange}
      />
    );
  };

  public get values(): IValues {
    const { videoSrc } = this.props;
    const { data } = this.state;
    const clipDurationMillis = data.clipEndMillis - data.clipStartMillis;
    return {
      audioFadeInDurationMillis: data.audioFadeInDurationMillis,
      audioFadeOutDurationMillis: data.audioFadeOutDurationMillis,
      audioLevel: data.audioLevel,
      blurredBackground: data.blurredBackground,
      clipEndMillis: data.clipEndMillis,
      clipStartMillis: data.clipStartMillis,
      endMillis: data.startMillis + clipDurationMillis,
      height: data.height,
      mainAudioLevel: data.mainAudioLevel,
      mediaToReplaceId: data.mediaToReplaceId,
      position: data.position,
      scaling: data.scaling,
      src: videoSrc,
      startMillis: data.startMillis,
      transitionIn: data.transitionIn,
      transitionOut: data.transitionOut,
      width: data.width,
      zoom: data.zoom,
      transcriptionEnabled: data.transcriptionEnabled,
      transcriptionLanguage: data.transcriptionLanguage,
    };
  }

  private setPlayer = (el: VideoPlayer) => {
    this.player = el;
  };

  public render() {
    const {
      aspectRatio,
      className,
      durationMillis,
      onLoadedMetadata,
      onPause,
      onPlay,
      onUpgradeClick,
      playing,
      poster,
      previewColClassName,
      showClipper,
      showTranscriptionSection,
      showTransformOptions,
      videoSrc,
      onAdjustCrop,
    } = this.props;
    const { data } = this.state;

    const ratioName = getAspectRatioName(aspectRatio);
    const clipDurationMillis = data.clipEndMillis - data.clipStartMillis;

    return (
      <div
        className={classNames(
          'video-form-preview',
          'video-form-preview--default',
          `video-form-preview--${ratioName}`,
          className,
        )}
      >
        <Col
          xs={8}
          className={classNames(
            previewColClassName,
            'video-form-preview__video-col',
          )}
        >
          {showClipper ? (
            <VideoClipper
              aspectRatioName={ratioName}
              endMillis={data.clipEndMillis}
              durationMillis={durationMillis}
              maxDurationMillis={min(
                durationMillis,
                data.clipStartMillis + clipMaxDuration,
              )}
              onSelectedMillisChange={this.handleClipMillisChange}
              onLoadedMetadata={onLoadedMetadata}
              onPause={onPause}
              onPlay={onPlay}
              poster={poster}
              playerClassName={`video-form-preview__player--${ratioName}`}
              playerRef={this.setPlayer}
              playing={playing}
              scaling={data.scaling}
              src={videoSrc}
              startMillis={data.clipStartMillis}
              showMinimap={false}
              showTimelineZoomSlider={false}
              wavesurferClassName="video-form-preview__wavesurfer"
              controlsClassName="video-form-preview__forms-control"
              formClassName="video-form-preview__forms-control"
            />
          ) : (
            <div className="video-form-preview__player-with-zoom">
              <div
                className={`video-form-preview__player video-form-preview__player--${ratioName}`}
              >
                <PlaybackControlBar renderPlayer={this.renderVideoCropper} />
              </div>
              <VideoZoomSlider
                onZoomChange={this.handleZoomChange}
                zoom={data.zoom}
              />
            </div>
          )}
        </Col>
        <Col xs={4} className="video-form-preview__form-col">
          <VideoOptions>
            {showTranscriptionSection && (
              <VideoOptions.TranscriptionSection
                clipDurationMillis={clipDurationMillis}
                language={data.transcriptionLanguage}
                onChangeLanguage={this.handleChangeTranscriptionLanguage}
                onToggleTranscription={this.handleToggleTranscriptionEnabled}
                onUpgradeClick={onUpgradeClick}
                transcriptionEnabled={data.transcriptionEnabled}
              />
            )}
            {showClipper && (
              <VideoOptions.ClippingSection
                endMillis={data.clipEndMillis}
                maxMillis={min(
                  durationMillis,
                  data.clipStartMillis + clipMaxDuration,
                )}
                startMillis={data.clipStartMillis}
                onBlur={this.handleClipMillisChange}
              />
            )}
            <VideoOptions.VideoSection
              blurredBackground={data.blurredBackground}
              endMillis={data.startMillis + clipDurationMillis}
              endMillisBounds={{ lower: clipDurationMillis, upper: Infinity }}
              onChange={this.handleVideoOptionChange}
              resize={data.scaling}
              showTransformOptions={showTransformOptions}
              startMillis={data.startMillis}
              startMillisBounds={{ lower: 0, upper: Infinity }}
              transitionIn={data.transitionIn}
              transitionOut={data.transitionOut}
              onAdjustCrop={onAdjustCrop}
            />
            <VideoOptions.AudioSection
              fadeInDurationMillis={data.audioFadeInDurationMillis}
              fadeOutDurationMillis={data.audioFadeOutDurationMillis}
              mainAudioLevel={data.mainAudioLevel}
              onChange={this.handleAudioOptionChange}
              videoAudioLevel={data.audioLevel}
            />
          </VideoOptions>
        </Col>
      </div>
    );
  }
}

export { IProps as VideoFormWithPreviewProps, IValues as Values, VideoScaling };

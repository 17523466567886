import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { isEmpty } from 'underscore';
import { CaptionsConfig } from 'blocks/TextOverlayModal/v2';
import { AutogramVideoType } from 'types';
import { CAPTIONS_ANIMATIONS_MAX_DURATION_MINUTES } from 'utils/constants';
import {
  getBaseCaptionsOverride,
  isCaptionsAnimationsTabDisabled,
} from 'utils/embed/captions/utils';
import { parseSearch } from 'utils/routes';
import { useEditorState } from '../context/VideoEditorStateContext';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { useModalManager } from '../ModalManager';
import { getCaptionsFromConfig } from '../state/captions';
import { CaptionsChildViewContentsProps } from './CaptionsChildViewContents';
import {
  DEFAULT_CHILD_VIEW_CONTENTS,
  DEFAULT_CHILD_VIEW_LABELS,
  FULL_EPISODE_CHILD_VIEW_CONTENTS,
  INCLUDE_CAPTIONS_CHILD_VIEW_CONTENTS,
} from './constants';

export interface UseCaptionsChildViewContentsProps {
  videoType?: AutogramVideoType;
}

export const ANIMATIONS_NOT_SUPPORTED_MSG = `Not supported for content longer than ${CAPTIONS_ANIMATIONS_MAX_DURATION_MINUTES} minutes`;

export default function useCaptionsChildViewContentsProps({
  videoType,
}: UseCaptionsChildViewContentsProps): CaptionsChildViewContentsProps {
  const { audioClipDurationSec } = useEditorState();
  const { aspectRatio, captions, transcription } = useTemplateState();
  const dispatch = useTemplateDispatch();
  const modalManager = useModalManager();
  const location = useLocation();

  const query = parseSearch(location.search);
  const wizard = query.type;

  const handleTranscriptionChange = useCallback((): void => {
    const baseCaptionsOverride = !isEmpty(captions)
      ? captions
      : getCaptionsFromConfig(getBaseCaptionsOverride(aspectRatio));

    dispatch({
      type: 'TRANSCRIPTION_CHANGE',
      payload: {
        transcription: {
          ...transcription,
          transcribe: !transcription?.transcribe,
        },
      },
    });

    dispatch({
      type: 'CAPTIONS_CONFIG_UPDATE',
      payload: {
        captions: !transcription?.transcribe
          ? baseCaptionsOverride
          : ({} as CaptionsConfig),
      },
    });
  }, [aspectRatio, captions, dispatch, transcription]);

  const handleEditCaptionsStyle = useCallback((): void => {
    const showTabDisabled = isCaptionsAnimationsTabDisabled(
      audioClipDurationSec,
      videoType,
    );

    modalManager.showModal('edit-captions', {
      animationsTabEnabled: true,
      animationsTabDisplayConfig: {
        showTabDisabled,
        tabTooltip: showTabDisabled && ANIMATIONS_NOT_SUPPORTED_MSG,
      },
      captionsOverride: captions,
      onSubmit: (captionsConfig: CaptionsConfig) => {
        dispatch({
          type: 'CAPTIONS_CONFIG_UPDATE',
          payload: {
            captions: captionsConfig,
          },
        });
      },
    });
  }, [audioClipDurationSec, captions, dispatch, modalManager, videoType]);

  return useMemo((): CaptionsChildViewContentsProps => {
    let propsByWizardType: Partial<CaptionsChildViewContentsProps> = {};

    switch (wizard) {
      case 'autovideo': {
        propsByWizardType = INCLUDE_CAPTIONS_CHILD_VIEW_CONTENTS;

        break;
      }

      case 'audiogram': {
        propsByWizardType = INCLUDE_CAPTIONS_CHILD_VIEW_CONTENTS;

        break;
      }

      case 'video-transcription': {
        propsByWizardType = DEFAULT_CHILD_VIEW_CONTENTS;

        break;
      }

      case 'episode': {
        propsByWizardType = FULL_EPISODE_CHILD_VIEW_CONTENTS;

        break;
      }

      default: {
        propsByWizardType = {
          ...DEFAULT_CHILD_VIEW_LABELS,
        };

        break;
      }
    }

    return {
      ...propsByWizardType,
      onTranscriptionChange: handleTranscriptionChange,
      onEditCaptionsStyle: handleEditCaptionsStyle,
    } as CaptionsChildViewContentsProps;
  }, [handleEditCaptionsStyle, handleTranscriptionChange, wizard]);
}

import { Heading, Spacer } from '@sparemin/blockhead';
import React from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'components/ConfirmationModal';
import { HourglassSand } from 'components/icons';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import Modal from 'components/Modal';
import useContextualUpgrade from 'hooks/useContextualUpgrade';
import { onUnlockThisFeature } from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { createChainedFunction } from 'utils/functions';
import { block } from './utils';

export interface BackCatalogUpsellModalContentsProps {}

const BackCatalogUpsellModalContents: React.FC<BackCatalogUpsellModalContentsProps> = () => {
  const dispatch = useDispatch<Dispatch>();

  const { onUpgrade } = useContextualUpgrade({
    openModal: replaceModal,
  });

  const trackClick = () => {
    dispatch(onUnlockThisFeature('AutomationStartFromTheFirstEpisode'));
  };

  return (
    <>
      <ConfirmationModal.Body>
        <ConfirmationModal.Icon>
          <CircularGradient>
            <HourglassSand style={{ height: 40, width: 'auto' }} />
          </CircularGradient>
        </ConfirmationModal.Icon>
        <ConfirmationModal.Title>
          Promote your back catalog
        </ConfirmationModal.Title>
        <Spacer
          orientation="vertical"
          align="center"
          justify="center"
          space="24px"
        >
          <Heading level={2} className={block('description')}>
            Starting from any existing episodes of your podcast, we create
            videos for three episodes a day until we catch up to your latest
            episode. Then we continue creating when new episodes are released.
          </Heading>
        </Spacer>
      </ConfirmationModal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton
            theme="rainbow"
            onClick={createChainedFunction(onUpgrade, trackClick)}
          >
            Unlock this feature
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default BackCatalogUpsellModalContents;

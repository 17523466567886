import { SegmentedControl } from '@sparemin/blockhead';
import React from 'react';
import { useDispatch } from 'react-redux';

import ProIndicator from 'containers/ProIndicator';
import { SubTier } from 'redux/middleware/api/plan-service';
import { pushModal } from 'redux/modules/modal';

import { ClipSelectionMode } from './types';
import { block } from './utils';

interface ClipVideoTypeSelectorProps {
  isDisabled?: boolean;
  onChange: (value: ClipSelectionMode) => void;
  value: ClipSelectionMode;
}

const NOT_ALLOWED_SUBTIERS = [
  SubTier.FREE,
  SubTier.EDUCATION_NONPROFIT,
  SubTier.BASIC_20200922_PAID,
  SubTier.BASIC_20211112_PAID,
  SubTier.BASIC_EDUCATION_CHARITY,
];

const ClipVideoTypeSelector: React.FunctionComponent<ClipVideoTypeSelectorProps> = props => {
  const { isDisabled, onChange, value } = props;
  const dispatch = useDispatch();

  const handleUpgrade = React.useCallback((): void => {
    dispatch(pushModal({ name: 'FullEpisodeCaptionsUpsell' }));
  }, [dispatch]);

  const handleChange = React.useCallback(
    (isFree: boolean) => (nextValue: ClipSelectionMode) => {
      if (isFree && nextValue === 'full-episode') {
        handleUpgrade();
      } else {
        onChange(nextValue);
      }
    },
    [handleUpgrade, onChange],
  );

  return (
    <div className={block('segment-picker-wrapper')}>
      <ProIndicator blacklist={NOT_ALLOWED_SUBTIERS}>
        {({ isFree, badge }) => {
          const FullEpisodeLabel = isFree ? (
            <>
              Use full episode{' '}
              <span className={block('pro-badge')}>{badge}</span>
            </>
          ) : (
            'Use full episode'
          );

          return (
            <SegmentedControl
              className={block('segment-picker')}
              isDisabled={isDisabled}
              optionClassName={block('segment-picker-option')}
              onChange={handleChange(isFree)}
              options={[
                {
                  label: 'Select a clip',
                  value: 'clip',
                },
                {
                  label: FullEpisodeLabel,
                  value: 'full-episode',
                },
              ]}
              value={value}
            />
          );
        }}
      </ProIndicator>
    </div>
  );
};

export default ClipVideoTypeSelector;

import { isPlainObject } from 'is-plain-object';
import * as ids from 'short-id';
import _, { isEmpty } from 'underscore';

import { validateCaptionsConfigIntegrity } from 'blocks/TextOverlayModal/v2';
import {
  PodcastTemplateConfig,
  PodcastWorkflowTemplate,
} from 'redux/middleware/api/podcast-service/types';
import {
  Dimensions,
  ISlideshowItem,
  KeyAssetType,
  KeyImageType,
  KeyTextType,
} from 'types';
import merge from 'utils/deepmerge';
import { parseCSSFilter } from 'utils/dom';
import measurement, { ViewportHeight, ViewportWidth } from 'utils/measurement';
import { parseTextShadowString } from 'utils/ui';
import {
  CaptionsState,
  IntroOutroState,
  Layer,
  MediaIntegrationId,
  ProgressState,
  Slide,
  SlideEffectText,
  SlideEffectTextState,
  SlideshowState,
  SoundwaveState,
  TextOverlayState,
  TimerState,
  VideoClip,
  VideoClipState,
  WatermarkState,
} from '../types';
import { viewportToPct } from '../utils';
import { getCaptionsFromConfig } from './captions';
import { DEFAULT_BG_COLOR } from './constants';

const { omit } = _;

type WithKeyAssets<
  T,
  U extends string = KeyAssetType,
  TData extends string | string[] = string
> = Omit<T, 'keyAssets'> & {
  keyAssets: {
    [k in U]?: TData;
  };
};

type WithKeyTextAssets<T> = WithKeyAssets<T, KeyTextType, string>;

type WithKeyImageAssets<T> = WithKeyAssets<T, KeyImageType, string[]>;

type WithTypedKeyAsset<T> = WithKeyTextAssets<T> & WithKeyImageAssets<T>;

export function getAspectRatio(template: PodcastWorkflowTemplate) {
  return template?.dimension?.width / template?.dimension?.height;
}

export function getBackgroundColor(config: PodcastTemplateConfig) {
  return config?.mainMediaContainer?.style?.backgroundColor ?? DEFAULT_BG_COLOR;
}

function getSlideEffectText(slide: ISlideshowItem, slideId: string) {
  if (slide.imageEffect.effectType !== 'lottie') return undefined;
  return (slide.imageEffect.options.embeddedTexts ?? []).reduce<{
    [k: string]: SlideEffectText;
  }>((acc, text) => {
    const id = ids.generate();
    acc[id] = {
      ...text,
      id,
      slideId,
      textType: text.textType,
    };
    return acc;
  }, {});
}

export const calculateSlideBlurRadius = (
  slide: ISlideshowItem,
): ViewportWidth | undefined => {
  const cssFilter = parseCSSFilter(slide?.style?.filter);

  return cssFilter?.blur?.radius && cssFilter.blur.radius.includes('vw')
    ? new ViewportWidth(parseInt(cssFilter.blur.radius.replace('vw', ''), 10))
    : undefined;
};

/**
 *
 * @param layers an array of layer ids where the index of the layer in the array
 * corresponds to the layer's order.  a layer corresponds to a track in the
 * editor. slideshow and videoClips share the same track so the layers array passed
 * in here can be the output of the getVideoClips function
 */
export function getSlideshow(
  config: PodcastTemplateConfig,
  layers: Layer[],
): WithTypedKeyAsset<SlideshowState> & { text: SlideEffectTextState } {
  const slideshow = config?.slideshowInfo ?? [];

  return slideshow.reduce(
    (acc, slide, index) => {
      const id = ids.generate();

      const layer = layers[slide.layerId];

      if (layer?.type !== 'media') {
        throw new Error(
          `Expected slideshow asset to reference layer of type "media" but got ${layer?.type}`,
        );
      }

      const slideState: Slide = {
        // merging with an empty object is used to deep clone the image object since
        // parts of it are used in state.  this prevents accidentally changing
        // the source object
        ...merge(
          omit(slide, 'imageUrl', 'position'),
          {},
          { isMergeableObject: isPlainObject },
        ),
        id,
        blurRadius: calculateSlideBlurRadius(slide),
        imageSrc: slide.imageUrl,
        layerId: layer.id,
        originalSlideshowIndex: index,
        placement: {
          height: measurement(slide.style?.height ?? '100vh'),
          left: measurement(slide.position?.left ?? '0vw'),
          top: measurement(slide.position?.top ?? '0vh'),
          width: measurement(slide.style?.width ?? '100vw'),
        },
      };

      if (slide.imageType === 'mainImage') {
        acc.keyAssets.mainImage = [...acc.keyAssets.mainImage, id];
      }

      const texts = getSlideEffectText(slide, id);
      if (texts) {
        const textIds = Object.keys(texts);
        acc.text = { ...acc.text, ...texts };
        slideState.imageEffect.options.embeddedTexts = textIds;

        // iterate through the slide texts to see if any are key text assets
        textIds.forEach(textId => {
          const text = texts[textId];
          if (text.textType) {
            acc.keyAssets[text.textType] = textId;
          }
        });
      }

      acc.order.push(id);
      acc.data[id] = slideState;

      return acc;
    },
    {
      keyAssets: { mainImage: [] } as WithTypedKeyAsset<any>['keyAssets'],
      newImages: [],
      order: [],
      data: {},
      text: {},
    },
  );
}

/**
 *
 * @param layers an array of layer ids where the index of the layer in the array
 * corresponds to the layer's order.  a layer corresponds to a track in the
 * editor. slideshow and videoClips share the same track so the layers array passed
 * in here can be the output of the getSlideshow function
 */
export function getVideoClips(
  config: PodcastTemplateConfig,
  layers: Layer[],
): VideoClipState {
  const videoClips = config?.videoClips ?? [];

  return videoClips.reduce(
    (acc, clip) => {
      const layer = layers[clip.layerId];

      if (layer?.type !== 'media') {
        throw new Error(
          `Expected video asset to reference layer of type "media" but got ${layer?.type}`,
        );
      }

      const id = ids.generate();
      const clipState: VideoClip = {
        id,
        layerId: layer.id,
        original: clip,
        position: {
          left: viewportToPct(clip.position?.left),
          top: viewportToPct(clip.position?.top),
        },
        previewThumbnail: clip.previewThumbnail,
        style: {
          height: viewportToPct(clip.style?.height),
          width: viewportToPct(clip.style?.width),
        },
        videoUrl: clip.videoUrl,
        integrationData: { id: MediaIntegrationId.VIDEOCLIP },
      };
      acc.order.push(id);
      acc.data[id] = clipState;
      return acc;
    },
    { order: [], data: {} },
  );
}

export const getIntroOutro = (
  config: PodcastTemplateConfig,
): IntroOutroState => {
  const { edgeVideos } = config;
  const { intro, outro } = edgeVideos ?? {};
  return {
    intro: intro ? { id: intro.videoId, loaded: false } : undefined,
    outro: outro ? { id: outro.videoId, loaded: false } : undefined,
  };
};

export const getCaptions = (
  config: PodcastTemplateConfig,
): CaptionsState | undefined => {
  if (!config.captions || !validateCaptionsConfigIntegrity(config.captions)) {
    return undefined;
  }

  return getCaptionsFromConfig(config.captions);
};

export const getTimer = (config: PodcastTemplateConfig): TimerState => {
  const { timer } = config;

  if (!timer?.length) {
    return undefined;
  }

  const { enabled, containerStyle, position } = timer[0];

  return {
    color: containerStyle.color,
    enabled,
    fontSize: measurement(containerStyle.fontSize),
    position: {
      top: measurement(position.properties.top),
      left: measurement(position.properties.left),
    },
    timerSize: {
      height: measurement(containerStyle.height),
      width: measurement(containerStyle.width),
    },
  };
};

export function getSoundwave(
  config: PodcastTemplateConfig,
  layers: Layer[],
): SoundwaveState {
  const { soundwave } = config;

  if (!soundwave || isEmpty(soundwave)) {
    return undefined;
  }

  const layer = layers[soundwave.layerId];

  if (layer?.type !== 'waveform') {
    throw new Error(
      `Expected waveform asset to reference layer of type "waveform" but got ${layer?.type}`,
    );
  }

  const soundwaveState = {
    color: soundwave.style?.color,
    fidelity: 'lo-fi' as const,
    // some templaets (e.g. minimalSquare) have things like "height: 0" in the
    // config.  force the unitless value to vh if it's a raw number
    height: measurement(soundwave.style?.height, 'vh'),
    layerId: layer.id,
    left: measurement(soundwave.position?.properties?.left, 'vw'),
    original: undefined,
    top: measurement(soundwave.position?.properties?.top),
    type: soundwave?.style?.pattern,
    width: measurement(soundwave.style?.width),
  };

  return soundwaveState;
}

function parsePaddings(styles) {
  const {
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  } = styles;
  if (padding) {
    const value = measurement(padding);
    return {
      paddingTop: value,
      paddingRight: value,
      paddingBottom: value,
      paddingLeft: value,
    };
  }

  return {
    paddingTop: measurement(paddingTop),
    paddingRight: measurement(paddingRight),
    paddingBottom: measurement(paddingBottom),
    paddingLeft: measurement(paddingLeft),
  };
}

export function getTextOverlays(
  config: PodcastTemplateConfig,
  layers: Layer[],
): WithKeyTextAssets<TextOverlayState> {
  const { textOverlayInfo = [] } = config;

  return textOverlayInfo.reduce<WithKeyTextAssets<TextOverlayState>>(
    (acc, overlay) => {
      const id = ids.generate();
      const {
        containerStyle: {
          fontSize,
          height,
          width,
          padding,
          textShadow,
          ...style
        },
      } = overlay;

      const layer = layers[overlay.layerId];

      if (layer?.type !== 'text') {
        throw new Error(
          `Expected text asset to reference "text" layer but got ${layer?.type}`,
        );
      }

      acc.order.push(id);
      acc.data[id] = {
        id,
        advancedTextConfigs: overlay.advancedTextConfigs,
        editor: overlay.editor,
        layerId: layer.id,
        position: {
          left: measurement(overlay.position.properties.left),
          top: measurement(overlay.position.properties.top),
        },
        size: {
          height: measurement(height),
          width: measurement(width),
        },
        style: {
          ...style,
          ...parsePaddings(style),
          fontSize: measurement(fontSize),
          textShadow: parseTextShadowString(textShadow),
        },
        text: overlay.text,
        textBuilderStyles: overlay.textBuilderStyles,
        textHtml: overlay.textHtml,
        version: overlay.version,
      };
      if (overlay.textType) {
        acc.keyAssets[overlay.textType] = id;
      }
      return acc;
    },
    { order: [], data: {}, keyAssets: {} },
  );
}

export function getWatermark(
  config: PodcastTemplateConfig,
): [WatermarkState, boolean] {
  const firstWatermark = config?.watermark?.[0];
  if (!firstWatermark) return [undefined, undefined];

  return [
    {
      position: {
        left: measurement(firstWatermark.position?.properties?.left),
        top: measurement(firstWatermark.position?.properties?.top),
      },
      size: {
        height: measurement(firstWatermark.style?.height),
        width: measurement(firstWatermark.style?.width),
      },
      integrationData: undefined,
      metadata: undefined,
      originalUrl: firstWatermark.url,
      url: firstWatermark.url,
    },
    firstWatermark.imageType === 'mainImage',
  ];
}

/*
 * progress config will have height and width as well as top/bottom and left/right
 */
function getProgressDimensions(
  progress: PodcastTemplateConfig['progress'],
): Dimensions<ViewportHeight, ViewportWidth> {
  const vhKeys = ['top', 'bottom', 'height'];

  const provided = _.mapObject(
    {
      ...progress.position?.properties,
      ..._.pick(progress.style, 'height', 'width'),
    },
    (val, key) => measurement(val, vhKeys.includes(key) ? 'vh' : 'vw'),
  );

  const dimensions: any = {};

  if (provided.top) {
    dimensions.top = provided.top;
  } else if (provided.bottom && provided.height) {
    dimensions.top = provided.bottom.minus(provided.height);
  } else {
    return undefined;
  }

  if (provided.left) {
    dimensions.left = provided.left;
  } else if (provided.right && provided.width) {
    dimensions.left = provided.right.minus(provided.width);
  } else {
    return undefined;
  }

  if (provided.height) {
    dimensions.height = provided.height;
  } else if (provided.bottom && provided.top) {
    dimensions.height = provided.bottom.minus(provided.top);
  } else {
    return undefined;
  }

  if (provided.width) {
    dimensions.width = provided.width;
  } else if (provided.left && provided.right) {
    dimensions.width = provided.right.minus(provided.left);
  } else {
    return undefined;
  }

  return dimensions;
}

export function getProgress(config: PodcastTemplateConfig): ProgressState {
  const progress = config?.progress;

  if (!progress || isEmpty(progress) || !progress?.enabled) return undefined;

  const dimensions = getProgressDimensions(progress);

  return {
    enabled: progress.enabled,
    color: progress.unelapsedColor,
    fillColor: progress.elapsedColor,
    type: progress.type,
    ...dimensions,
  };
}

export function getTemplateType(template: PodcastWorkflowTemplate) {
  return template?.templateType;
}

export function getTemplateId(template: PodcastWorkflowTemplate) {
  return template?.templateId;
}

export function getHasCubeEffect(config: PodcastTemplateConfig) {
  const slideshow = config.slideshowInfo ?? [];
  return slideshow.some(slide => slide.imageEffect.effect === 'rotatingCube');
}

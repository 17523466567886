import { Button, PaletteCircle } from '@sparemin/blockhead';
import * as React from 'react';

import Tooltip from 'components/Tooltip';
import CaptionsFormControl from './CaptionsFormControl';
import { block } from './utils';

interface IProps {
  isDisabled: boolean;
  onPress?: () => void;
}

const CaptionsStyleButton: React.SFC<IProps> = ({ isDisabled, onPress }) => (
  <Tooltip
    animation={false}
    content="Text styles & animation"
    id="text-styles-tooltip"
    placement="top"
    preventHideOnHover={false}
  >
    <CaptionsFormControl>
      <Button
        className={block('style-button')}
        onPress={onPress}
        isDisabled={isDisabled}
        size="small"
        startIcon={<PaletteCircle />}
      >
        Style captions
      </Button>
    </CaptionsFormControl>
  </Tooltip>
);

export default CaptionsStyleButton;
export { IProps as CaptionsStyleButtonProps };

import cn from 'classnames';
import React from 'react';
import {
  FacebookButton,
  InstagramButton,
  LinkedInButton,
  ThreadsButton,
  TikTokButton,
  TwitterButton,
  YouTubeButton,
} from 'components/SocialButton';
import { DestinationPlatform } from './types';
import { block } from './utils';

export interface DestinationPlatformsContentsProps {
  className?: string;
  onPlatformSelection: (platform: DestinationPlatform) => void;
}

const DestinationPlatformsContents: React.FC<DestinationPlatformsContentsProps> = ({
  className,
  onPlatformSelection,
}) => (
  <div className={cn(block('platforms-container'), className)}>
    <YouTubeButton onClick={() => onPlatformSelection('youtube')} />

    <InstagramButton onClick={() => onPlatformSelection('instagram')} />

    <FacebookButton onClick={() => onPlatformSelection('facebook')} />

    <TikTokButton onClick={() => onPlatformSelection('tiktok')} />

    <LinkedInButton onClick={() => onPlatformSelection('linkedin')} />

    <ThreadsButton onClick={() => onPlatformSelection('threads')} />

    <TwitterButton onClick={() => onPlatformSelection('twitter')} />
  </div>
);

export default DestinationPlatformsContents;

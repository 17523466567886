import classNames from 'classnames';
import * as React from 'react';
import KeyHandler, { KEYDOWN } from 'react-key-handler';
import _ from 'underscore';

import CopyProjectButton from 'components/CopyProjectButton';
import TimelineStatus from 'components/TimelineStatus';
import WebAudioPlayer from 'components/WebAudioPlayer';
import { MAX_VIDEO_EXPORT_DURATION_SECONDS } from 'utils/constants';
import ExportButton from './ExportButton';
import { DataState, IPlaybackPos, IProps, IState } from './types';

export default class PlayBar extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    durationSeconds: 0,
    exportButtonEnabled: false,
    copyButtonEnabled: false,
    onExportClick: _.noop,
    onPause: _.noop,
    onPlay: _.noop,
    onSeek: _.noop,
    onTimeupdate: _.noop,
    playing: false,
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      data: new DataState(),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>): void {
    const { canPlay: nextCanPlay, playing: nextPlaying } = nextProps;
    const { playing } = this.props;
    const { data } = this.state;

    if (data.playbackStatus === 'playPending' && nextCanPlay === true) {
      this.setState(({ data: dataState }) => ({
        data: dataState.set('playbackStatus', 'playing'),
      }));
    }

    if (nextPlaying !== playing) {
      this.setState(({ data: dataState }) => ({
        data: dataState.update('playbackStatus', val => {
          if (val === 'playing' || val === 'playPending') {
            return 'paused';
          }

          if (nextCanPlay) {
            return 'playing';
          }

          return 'playPending';
        }),
      }));
    }
  }

  private handleTimeupdate = (positionData: IPlaybackPos) => {
    const { onTimeupdate } = this.props;
    const { seconds } = positionData;

    this.setPosition(seconds);
    onTimeupdate(positionData);
  };

  private handleSeek = (positionData: IPlaybackPos) => {
    const { onSeek } = this.props;
    const { seconds } = positionData;

    this.setPosition(seconds);
    onSeek(positionData);
  };

  private handleSpaceKeypress = (e: React.SyntheticEvent<KeyboardEvent>) => {
    e.preventDefault();
    this.toggleWebAudioPlaying();
  };

  private setPosition(sec: number) {
    this.setState(({ data }) => ({
      data: data.set('positionSeconds', sec),
    }));
  }

  private toggleWebAudioPlaying(): void {
    const { onPause, onPlay } = this.props;
    const { data } = this.state;

    const currentStatus = data.playbackStatus;
    if (currentStatus === 'playPending' || currentStatus === 'playing') {
      onPause();
    } else {
      onPlay();
    }
  }

  public render() {
    const { data } = this.state;
    const {
      className,
      clips,
      controlButtonClassName,
      controlsClassName,
      durationSeconds,
      exportButtonEnabled,
      copyButtonEnabled,
      onExportClick,
      onPause,
      onPlay,
      playbackControlGroupClassName,
      statusClassName,
      volumeControlGroupClassName,
    } = this.props;

    const containerClassName = classNames({
      playbar: true,
      'playbar--std': true,
      [className]: !!className,
    });

    const statusClass = classNames({
      playbar__status: true,
      [statusClassName]: !!statusClassName,
    });

    const controlsClass = classNames({
      playbar__controls: true,
      [controlsClassName]: !!controlsClassName,
    });

    const controlButtonClass = classNames({
      'playbar__control-button': true,
      [controlButtonClassName]: !!controlButtonClassName,
    });

    const playbackControlGroupClass = classNames({
      'playbar__playback-controls': true,
      [playbackControlGroupClassName]: !!playbackControlGroupClassName,
    });

    const volumeControlGroupClass = classNames({
      'playbar__volume-controls': true,
      [volumeControlGroupClassName]: !!volumeControlGroupClassName,
    });

    const copyButtonClassName = 'playbar__copy-button';

    const TimelineStatusAsAny: any = TimelineStatus;

    return (
      <div className={containerClassName}>
        <KeyHandler
          keyEventName={KEYDOWN}
          keyValue=" "
          onKeyHandle={this.handleSpaceKeypress}
        />
        <TimelineStatusAsAny
          className={statusClass}
          durationSeconds={durationSeconds}
          position={data.get('positionSeconds')}
          timeFormat="mm:ss.SS"
        />
        <WebAudioPlayer
          clips={clips}
          controls
          controlsClassName={controlsClass}
          controlButtonClassName={controlButtonClass}
          durationSeconds={durationSeconds}
          onTimeupdate={this.handleTimeupdate}
          onPlay={onPlay}
          onPause={onPause}
          onSeek={this.handleSeek}
          playbackGroupClassName={playbackControlGroupClass}
          playing={data.get('playbackStatus') === 'playing'}
          volumeGroupClassName={volumeControlGroupClass}
        />
        <CopyProjectButton
          className={copyButtonClassName}
          disabled={!copyButtonEnabled}
        />
        <ExportButton
          disabled={!exportButtonEnabled}
          onClick={onExportClick}
          renderMaxDurationExceededTooltip={
            durationSeconds > MAX_VIDEO_EXPORT_DURATION_SECONDS
          }
        />
      </div>
    );
  }
}

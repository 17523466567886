import { actions as creationService } from 'redux/middleware/api/creation-service';
import { fetchEmbedConfiguration } from 'redux/middleware/api/embed-configuration-service/actions';
import {
  sendDownloadPageAction,
  sendDownloadPageLoad,
} from 'redux/middleware/api/event-ping-service/actions';
import { getUserDisplayPref } from 'redux/middleware/api/headliner-user-service/actions';
import {
  PodcastFeedEpisode,
  actions as podcastService,
  SubscriptionItem,
} from 'redux/middleware/api/podcast-service';
import {
  getSubscriptionById,
  getSubscriptionItemDetail,
} from 'redux/middleware/api/podcast-service/actions';
import { getProfileById } from 'redux/middleware/api/user-service/actions';
import { actions as embedVideoService } from 'redux/middleware/api/video-export-service';
import {
  fetchVideoExportStatus,
  postSocialShare,
} from 'redux/middleware/api/video-export-service/actions';
import { IEmbedExport } from 'redux/middleware/api/video-export-service/types';
import { fetchProjectById } from 'redux/middleware/api/video-project-management-service/actions';
import { getMyDisplayPref } from 'redux/modules/display-pref';
import { embedExportsSelector } from 'redux/modules/entities/selectors';
import { Dispatch, ThunkAction } from 'redux/types';
import {
  DeepImmutableMap,
  IEmbedConfiguration,
  SocialSharePlatform,
} from 'types';
import { getValue } from 'utils/collections';
import reduxPoll, { cancelReduxPoll } from 'utils/redux-poll';
import { Type } from './action-types';
import {
  EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLL_ID,
  SOCIAL_POST_CAPTIONS_POLL_ID,
  VIDEO_SOCIAL_POST_CAPTIONS_POLL_ID,
} from './constants';
import {
  embedVideoSocialPostCaptionInfoSelector,
  embedVideoSocialPostCaptionsStatusSelector,
  isPollingSocialPostCaptionsSelector,
  projectByIdSelector,
  videoIdSelector,
  videoSocialPostCaptionInfoSelector,
  videoSocialPostCaptionsStatusSelector,
} from './selectors';
import { GetVideoExportStatusError } from './types';

const getEmbedConfiguration = (
  wid: string,
): ThunkAction<Promise<IEmbedConfiguration>> => async dispatch => {
  const res = await dispatch(fetchEmbedConfiguration(wid));
  return res.response.configuration;
};

const getReferrerProject = (
  projectId: string,
): ThunkAction<Promise<string>> => async dispatch => {
  if (!projectId) return undefined;

  try {
    await dispatch(fetchProjectById(projectId));
    return projectId;
  } catch (e) {
    // when project not found (been removed), we get 404 and return projectId
    if (e.status === 404) {
      return projectId;
    }

    // throw error otherwise
    throw e;
  }
};

const fetchReferrerProjectSubscriptionIfExists = (
  futProjectId: Promise<string>,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const projectId = await futProjectId;
  const project = projectByIdSelector(projectId, getState());
  const subscriptionId = project?.getIn([
    'automationSubscriptionSource',
    'subId',
  ]);

  if (!subscriptionId) {
    return;
  }

  await dispatch(getSubscriptionById(subscriptionId));
};

const fetchOwnerDisplayPref = (
  futProjectId: Promise<string>,
  queuerUserId: number,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const projectId = await futProjectId;
  const project = projectByIdSelector(projectId, getState());

  const ownerUserId = project?.get('creatorUserId') ?? queuerUserId;

  if (!ownerUserId) {
    return;
  }

  await dispatch(getUserDisplayPref(ownerUserId));
};

const fetchCreatorUserProfileIfExists = (
  queuerUserId: number,
): ThunkAction<Promise<void>> => async dispatch => {
  if (!queuerUserId) {
    return;
  }

  await dispatch(getProfileById(queuerUserId));
};

const getVideoFrequency = (
  futProjectId: Promise<string>,
): ThunkAction<Promise<SubscriptionItem['videoFrequencyPref']>> => async (
  dispatch,
  getState,
) => {
  const projectId = await futProjectId;

  if (!projectId) return null;

  const project = projectByIdSelector(projectId, getState());

  const subscriptionId = getValue(
    project,
    ['automationSubscriptionSource', 'subId'],
    0,
  );
  const subscriptionItemId = getValue(
    project,
    ['automationSubscriptionSource', 'subItemId'],
    0,
  );

  if (!subscriptionId || !subscriptionItemId) return null;

  const res = await dispatch(
    getSubscriptionItemDetail(subscriptionId, subscriptionItemId),
  );

  return getValue(res, ['response', 'videoFrequencyPref'], null);
};

const getAutoCreateEpisode = (
  videoId: number,
): ThunkAction<Promise<void>> => async dispatch => {
  try {
    await dispatch(podcastService.getAutoCreateEpisodeByVideo(videoId));
  } catch (error) {
    if (error.status !== 404) {
      throw error;
    }
  }
};
const getEpisodeByVideo = (
  videoId: number,
): ThunkAction<Promise<PodcastFeedEpisode>> => async dispatch => {
  try {
    const { response } = await dispatch(
      podcastService.getEpisodeByVideo(videoId),
    );

    return response.entities.podcastFeedEpisodes[response.result];
  } catch (error) {
    if (error.status === 404) {
      return null;
    }
    throw error;
  }
};

const getVideoExportStatus = (
  wid: string,
): ThunkAction<Promise<DeepImmutableMap<IEmbedExport>>> => async (
  dispatch,
  getState,
) => {
  try {
    const { response } = await dispatch(fetchVideoExportStatus(wid));
    const exportStatus: DeepImmutableMap<IEmbedExport> = embedExportsSelector(
      getState(),
    ).get(response.result);

    if (exportStatus.get('status') === 'notFound') {
      throw new GetVideoExportStatusError();
    }

    return exportStatus;
  } catch (err) {
    if (err.status >= 400 && err.status < 600) {
      throw new GetVideoExportStatusError();
    }
    throw err;
  }
};

export const loadDownloadData = (
  wid: string,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.LOAD_DATA_REQUEST });

  try {
    await dispatch(getMyDisplayPref());
    const videoExportStatus = await dispatch(getVideoExportStatus(wid));

    const futConfig = dispatch(getEmbedConfiguration(wid));

    const futProjectId = dispatch(
      getReferrerProject(videoExportStatus.get('referrerProjectId')),
    );

    const futVideoFrequency = dispatch(getVideoFrequency(futProjectId));
    const videoDurationMillis = videoExportStatus.get('durationMillis');

    const futSubscription = dispatch(
      fetchReferrerProjectSubscriptionIfExists(futProjectId),
    );

    const futEpisode = dispatch(getEpisodeByVideo(videoExportStatus.get('id')));
    const futCreatorUserProfile = dispatch(
      fetchCreatorUserProfileIfExists(videoExportStatus.get('queuerUserId')),
    );

    const futAutoCreateEpisode = dispatch(
      getAutoCreateEpisode(videoExportStatus.get('id')),
    );

    const futOwnerDisplayPref = dispatch(
      fetchOwnerDisplayPref(
        futProjectId,
        videoExportStatus.get('queuerUserId'),
      ),
    );

    const podcastInfo = videoExportStatus.get('podcastInfo');

    const [
      configuration,
      projectId,
      videoFrequency,
      podcastEpisode,
    ] = await Promise.all([
      futConfig,
      futProjectId,
      futVideoFrequency,
      futEpisode,
      futSubscription,
      futCreatorUserProfile,
      futAutoCreateEpisode,
      futOwnerDisplayPref,
    ]);

    dispatch({
      payload: {
        // TODO configuration should go to entities.  this requires an update to
        // a few other redux keys that use the embed config (which in all places
        // but here is stored in the embed redux key)
        configuration,
        projectId,
        videoDurationMillis,
        videoFrequency,
        podcastEpisode,
        podcastInfo,
      },
      type: Type.LOAD_DATA_SUCCESS,
    });
  } catch (e) {
    dispatch({ type: Type.LOAD_DATA_FAILURE });
    throw e;
  }
};

export const clearDownload = () => (dispatch: Dispatch) =>
  dispatch({ type: Type.DOWNLOAD_CLEAR });

export const sendDownloadPageLoadEvent = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  dispatch(sendDownloadPageLoad(videoIdSelector(getState())));
};

export const sendDownloadPageActionEvent = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  dispatch(sendDownloadPageAction(videoIdSelector(getState())));
};

export const saveSocialShare = (
  platform: SocialSharePlatform,
  sharePostUrl: string,
): ThunkAction<void> => (dispatch, getState) => {
  const embedVideoId = videoIdSelector(getState());
  dispatch(
    postSocialShare({
      embedVideoId,
      platform,
      sharePostUrl,
    }),
  ).catch(() => {});
};

export const performDpa = {
  type: Type.DOWNLOAD_PAGE_ACTION,
};

export const cancelSocialPostCaptionsCreation = (): ThunkAction<void> => async dispatch => {
  dispatch({
    type: Type.SOCIAL_POST_CAPTIONS_POLLING_END,
  });

  return cancelReduxPoll(dispatch, SOCIAL_POST_CAPTIONS_POLL_ID);
};

export const pollForSocialPostCaptions = (
  episodeId: number,
  jobId: number,
): ThunkAction<void> => async (dispatch, getState) => {
  if (isPollingSocialPostCaptionsSelector(getState())) {
    return;
  }

  dispatch({
    type: Type.SOCIAL_POST_CAPTIONS_POLLING_REQUEST,
  });

  await reduxPoll(
    dispatch,
    async () => {
      const { response } = await dispatch(
        podcastService.getSocialPostCaptions(episodeId, jobId),
      );

      if (response.status === 'completed') {
        const videoId = videoIdSelector(getState());

        await dispatch(getEpisodeByVideo(videoId));

        dispatch({
          type: Type.SOCIAL_POST_CAPTIONS_POLLING_SUCCESS,
        });
      }

      return response;
    },
    {
      id: SOCIAL_POST_CAPTIONS_POLL_ID,
      shouldContinue: (_, value) => {
        if (value.status === 'error') {
          dispatch({
            type: Type.SOCIAL_POST_CAPTIONS_POLLING_FAILURE,
          });

          return false;
        }

        if (value.status === 'completed') {
          return false;
        }

        return true;
      },
      intervalMillis: 2500,
      maxAttempts: Infinity,
    },
  );
};

export const createSocialPostCaptions = (
  episodeId: number,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.SOCIAL_POST_CAPTIONS_CREATE_REQUEST,
    payload: { episodeId },
  });

  try {
    const {
      response: { jobId },
    } = await dispatch(podcastService.createSocialPostCaptions(episodeId));

    dispatch({
      type: Type.SOCIAL_POST_CAPTIONS_CREATE_SUCCESS,
      payload: { jobId },
    });

    dispatch(pollForSocialPostCaptions(episodeId, jobId));
  } catch (err) {
    dispatch({
      type: Type.SOCIAL_POST_CAPTIONS_CREATE_FAILURE,
    });

    throw err;
  }
};

export const cancelVideoSocialPostCaptionsCreation = (): ThunkAction<void> => async dispatch => {
  dispatch({
    type: Type.VIDEO_SOCIAL_POST_CAPTIONS_POLLING_END,
  });

  return cancelReduxPoll(dispatch, VIDEO_SOCIAL_POST_CAPTIONS_POLL_ID);
};

export const pollForVideoSocialPostCaptions = (
  creationRequestId: number,
  jobId: number,
): ThunkAction<void> => async (dispatch, getState) => {
  if (isPollingSocialPostCaptionsSelector(getState())) {
    return;
  }

  dispatch({
    type: Type.VIDEO_SOCIAL_POST_CAPTIONS_POLLING_REQUEST,
  });

  await reduxPoll(
    dispatch,
    async () => {
      const { response } = await dispatch(
        creationService.getVideoSocialPostCaptionsJob(creationRequestId, jobId),
      );
      const status = videoSocialPostCaptionsStatusSelector(getState());

      if (status === 'completed') {
        dispatch(creationService.getVideoSocialPostCaptions(creationRequestId));
        dispatch({
          type: Type.VIDEO_SOCIAL_POST_CAPTIONS_POLLING_SUCCESS,
        });
      }

      return response;
    },
    {
      id: VIDEO_SOCIAL_POST_CAPTIONS_POLL_ID,
      shouldContinue: () => {
        const status = videoSocialPostCaptionsStatusSelector(getState());

        if (status === 'error') {
          dispatch({
            type: Type.VIDEO_SOCIAL_POST_CAPTIONS_POLLING_FAILURE,
          });

          return false;
        }

        if (status === 'completed') {
          return false;
        }

        return true;
      },
      intervalMillis: 2500,
      maxAttempts: Infinity,
    },
  );
};

export const createVideoSocialPostCaptions = (
  creationRequestId: number,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.VIDEO_SOCIAL_POST_CAPTIONS_CREATE_REQUEST,
    payload: { creationRequestId },
  });

  try {
    const {
      response: { jobId },
    } = await dispatch(
      creationService.createVideoSocialPostCaptions(creationRequestId),
    );

    dispatch({
      type: Type.VIDEO_SOCIAL_POST_CAPTIONS_CREATE_SUCCESS,
      payload: { jobId },
    });

    dispatch(pollForVideoSocialPostCaptions(creationRequestId, jobId));
  } catch (err) {
    dispatch({
      type: Type.VIDEO_SOCIAL_POST_CAPTIONS_CREATE_FAILURE,
    });

    throw err;
  }
};

export const getVideoSocialPostCaptions = (creationRequestId: number) => async (
  dispatch,
  getState,
) => {
  try {
    const { response } = await dispatch(
      creationService.getVideoSocialPostCaptions(creationRequestId),
    );
    dispatch({
      type: Type.VIDEO_SOCIAL_POST_CAPTIONS_GET_SUCCESS,
      payload: { jobId: response.result },
    });
    return videoSocialPostCaptionInfoSelector(getState());
  } catch (error) {
    if (error.status === 404) {
      return null;
    }
    throw error;
  }
};

export const cancelEmbedVideoSocialPostCaptionsCreation = (): ThunkAction<void> => async dispatch => {
  dispatch({
    type: Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_END,
  });

  return cancelReduxPoll(dispatch, EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLL_ID);
};

export const pollForEmbedVideoSocialPostCaptions = (
  videoId: number,
  jobId: number,
): ThunkAction<void> => async (dispatch, getState) => {
  if (isPollingSocialPostCaptionsSelector(getState())) {
    return;
  }

  dispatch({
    type: Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_REQUEST,
  });

  await reduxPoll(
    dispatch,
    async () => {
      const { response } = await dispatch(
        embedVideoService.getEmbedVideoSocialPostCaptionsJob(videoId, jobId),
      );

      const status = embedVideoSocialPostCaptionsStatusSelector(getState());

      if (status === 'completed') {
        dispatch(embedVideoService.getEmbedVideoSocialPostCaptions(videoId));
        dispatch({
          type: Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_SUCCESS,
        });
      }

      return response;
    },
    {
      id: EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLL_ID,
      shouldContinue: () => {
        const status = embedVideoSocialPostCaptionsStatusSelector(getState());

        if (status === 'error') {
          dispatch({
            type: Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_FAILURE,
          });

          return false;
        }

        if (status === 'completed') {
          return false;
        }

        return true;
      },
      intervalMillis: 2500,
      maxAttempts: Infinity,
    },
  );
};

export const createEmbedVideoSocialPostCaptions = (
  videoId: number,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_CREATE_REQUEST,
    payload: { videoId },
  });

  try {
    const {
      response: { jobId },
    } = await dispatch(
      embedVideoService.createEmbedVideoSocialPostCaptions(videoId),
    );

    dispatch({
      type: Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_CREATE_SUCCESS,
      payload: { jobId },
    });
    dispatch(pollForEmbedVideoSocialPostCaptions(videoId, jobId));
  } catch (err) {
    dispatch({
      type: Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_CREATE_FAILURE,
    });

    throw err;
  }
};

export const getEmbedVideoSocialPostCaptions = (videoId: number) => async (
  dispatch,
  getState,
) => {
  try {
    const { response } = await dispatch(
      embedVideoService.getEmbedVideoSocialPostCaptions(videoId),
    );

    dispatch({
      type: Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_GET_SUCCESS,
      payload: { jobId: response.result },
    });

    return embedVideoSocialPostCaptionInfoSelector(getState());
  } catch (error) {
    if (error.status === 404) {
      return null;
    }

    throw error;
  }
};

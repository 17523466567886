import { RecordOf } from 'immutable';
import { TemplateEditorStateExport } from 'components/VideoTemplateEditor';
import { ClipAudioStepProps } from 'containers/ProjectWizards/ClipAudioStep';

import { Action } from 'redux/types';
import {
  AspectRatioDimensions,
  AudioClipOffsets,
  CaptionsOverride,
  FrameSize,
  PodcastIdentifier,
  ProjectCreationMethod,
  RequestStatus,
} from 'types';
import { Type } from './action-types';

type Transcription = ClipAudioStepProps['transcription'] & {
  transcriptUrl?: string;
  override?: CaptionsOverride;
};

interface Audio {
  source: string | File;
}

interface ClippedAudio extends AudioClipOffsets {
  entireAudioInstanceId: number;
}

export function isClippedAudio(arg: any): arg is ClippedAudio {
  return !!arg?.entireAudioInstanceId;
}

interface Video extends AudioClipOffsets {
  source: string | File;
  uploadedVideoId?: number;
}

export interface ExportConfiguration extends TemplateEditorStateExport {
  aspectRatio: AspectRatioDimensions;
  audio?: Audio | ClippedAudio;
  fullEpisodeExport?: boolean;
  podcastIdentifier?: PodcastIdentifier;
  video?: Video;
  projectName: string;
  initiateExport: boolean;
  projectCreatMethod: ProjectCreationMethod;
  templateId: string | 'none';
  transcription: Transcription;
  customTraceId?: string;
  traceId?: string;
  expectedDurationMilli?: number;
  frameSizeOverride?: FrameSize;
  isAutoFrame?: boolean;
  isClipSuggestion?: boolean;
  clipSuggestionLengthSecs?: number;
  enableBlur?: boolean;
  isVideoConfigLocked?: boolean;
}

export interface State {
  creationId: number;
  status: RequestStatus;
  pollStatus: RequestStatus;
}

export type WizardExportState = RecordOf<State>;

type CreationRequestAction = Action<Type.CREATION_REQUEST>;
type CreationSuccessAction = Action<
  Type.CREATION_SUCCESS,
  { creationId: number }
>;
type CreationFailureAction = Action<Type.CREATION_FAILURE>;
type ClearCreationAction = Action<Type.CREATION_CLEAR>;

type CreationPollRequestAction = Action<Type.CREATION_STATUS_POLL_REQUEST>;
type CreationPollSuccessAction = Action<Type.CREATION_STATUS_POLL_SUCCESS>;
type CreationPollFailureAction = Action<Type.CREATION_STATUS_POLL_FAILURE>;

export type WizardExportAction =
  | ClearCreationAction
  | CreationRequestAction
  | CreationSuccessAction
  | CreationFailureAction
  | CreationPollRequestAction
  | CreationPollSuccessAction
  | CreationPollFailureAction;

import cn from 'classnames';
import memoize from 'memoizee';
import * as React from 'react';
import _ from 'underscore';

import InputToggler from 'components/InputToggler';
import MaskedDurationInput, {
  durationToValue,
} from 'components/MaskedDurationInput';
import { getValue } from 'utils/collections';
import { block } from '../utils';

export interface BoundsMillis {
  lower: number;
  upper: number;
}

export interface SubmitResult {
  startMillis?: number;
  endMillis?: number;
}

export type OnSubmit = (value: number) => SubmitResult | void;

export interface IProps {
  activeClassName?: string;
  boundsMillis?: BoundsMillis;
  className?: string;
  defaultMillis: number;
  displayClassName?: string;
  inputClassName?: string;
  onClick?: OnSubmit;

  /**
   * to render a validation error, throw an error from this function.  the error's `message` field
   * will be used as the error message
   */
  onSubmit?: OnSubmit;

  target: 'end' | 'start';
}

interface State {
  inputMode: boolean;
  millis: number;
}

const TARGET_RESULT_MAP = {
  end: 'endMillis',
  start: 'startMillis',
};

export default class EditableTime extends React.Component<IProps, State> {
  public static defaultProps: Partial<IProps> = {
    onSubmit: _.noop,
  };

  public state: Readonly<State> = {
    inputMode: false,
    millis: this.props.defaultMillis,
  };

  private timeInput: HTMLInputElement;

  public componentDidUpdate(_1, prevState: State) {
    const { inputMode } = this.state;
    const { inputMode: prevInputMode } = prevState;

    if (!prevInputMode && inputMode && this.timeInput) {
      this.timeInput.focus();
    }
  }

  // private handleCancelInput = (): void => this.closeInput();
  private handleCancelInput = () => {
    this.setState({ millis: this.props.defaultMillis });
    this.closeInput();
  };

  private handleInputSubmit = (
    __,
    e: React.MouseEvent<HTMLInputElement>,
  ): void => {
    const { onSubmit, target } = this.props;
    const { millis } = this.state;

    e.preventDefault();
    e.stopPropagation();
    this.closeInput();

    const result = onSubmit(millis);

    // This is a way for preventing the value of the input to remain as the non
    // adjusted value if the change is reset when updating the value.
    // The resulting value from the onSubmit value is checked (for the target)
    // value to match the value that was saved. If they are different, the current
    // local millis value is updated.
    // Before, this value was controlled using the parent's value as key. The problem
    // with that approach is that there are now cases where the update function can
    // adjust the updated value to the same value that is currently stored at the
    // parent. This produces the component's key not to be updated and the re render
    // does not happen resulting into an unsynchronized value between the parent and
    // the component.
    const resultKey = TARGET_RESULT_MAP[target];

    if (result?.[resultKey] !== undefined && result[resultKey] !== millis) {
      this.setState({ millis: result[resultKey] });
    }
  };

  private handleOpenInput = () => this.setState({ inputMode: true });

  private handleChange = millis => this.setState({ millis });

  private closeInput() {
    this.setState({
      inputMode: false,
    });
  }

  private getFormattedTime = memoize(
    (millis: number) => durationToValue(millis),
    { max: 1 },
  );

  private setTimeInput = el => {
    this.timeInput = el;
  };

  public render() {
    const {
      activeClassName,
      boundsMillis,
      className,
      displayClassName,
      inputClassName,
    } = this.props;
    const { inputMode, millis } = this.state;

    return (
      <InputToggler
        activeClassName={activeClassName}
        className={cn(block('editable-time'), className)}
        formClassName={block('time-form')}
        inputMode={inputMode}
        onCancel={this.handleCancelInput}
        onOpen={this.handleOpenInput}
        onSubmit={this.handleInputSubmit}
        renderInput={(_1, _2, onKeyDown) => (
          <MaskedDurationInput
            className={inputClassName}
            inputRef={this.setTimeInput}
            maxMillis={getValue(boundsMillis, 'upper')}
            millis={millis}
            minMillis={getValue(boundsMillis, 'lower')}
            onChange={this.handleChange}
            onKeyDown={onKeyDown}
            allowArrows
          />
        )}
        renderValue={() => (
          <div className={displayClassName}>
            {this.getFormattedTime(millis)}
          </div>
        )}
      />
    );
  }
}

export { IProps as EditableTimeProps };

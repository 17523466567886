import React from 'react';
import { useDispatch } from 'react-redux';

import { TextOverlay } from 'blocks/TextOverlayModal';
import { TextOverlayV2 } from 'blocks/TextOverlayModal/v2';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';

import { ActiveModal } from './types';
import { transformOverlayToLegacy, transformOverlayToV2 } from './utils';

export type ModalSwitcher = (
  onShowCurrentModal?: (
    modalSwitcher: ReturnType<ModalSwitcher>,
    transitionOverlay: TextOverlayV2,
  ) => void,
  onShowLegacyModal?: (
    modalSwitcher: ReturnType<ModalSwitcher>,
    transitionOverlay: TextOverlay,
  ) => void,
) => (
  target: ActiveModal,
  onHide: () => void,
) => (partialOverlay?: TextOverlay | TextOverlayV2) => Promise<void>;

interface UseTextOverlayStatelessSwitch {
  onToggleLegacyMode: ModalSwitcher;
}

const useTextOverlayStatelessSwitch = (): UseTextOverlayStatelessSwitch => {
  const dispatch = useDispatch<Dispatch>();

  const handleToggleLegacyMode: ModalSwitcher = React.useCallback(
    (
      onShowCurrentModal?: (modalSwitcher, transitionOverlay) => void,
      onShowLegacyModal?: (modalSwitcher, transitionOverlay) => void,
    ) => (target, onHide) => async partialOverlay => {
      // First step in the transition is to hide the modal and to clear
      // the current modal. This allows a smooth transition between the
      // modals
      onHide();

      // second step is to pop the modal and set the transition modal that
      // allows keeping the current partial state without having to commit
      // the text overlay to the state.
      // Overlay transformation is only performed if the modal confirm action
      // is selected as well as toggling the current visible mode.
      const result = await dispatch(pushModal({ name: 'TextOverlaySwitch' }));

      const modalSwitcher = handleToggleLegacyMode(
        onShowCurrentModal,
        onShowLegacyModal,
      );

      // If the user picks to switch the moral, the right modal opener will
      // be called based on the targe modal. The modal pop function will be
      // called using the transformed overlay and a recursion of this same
      // switcher function that will handle further transformations.
      // That last statement is a workaround for handling the UCS modal manager
      // which does not have a "show" for text modal in particular but
      // a general one. By passing down the current switchers, it is possible
      // to allow the next shown modal to be able to call this same modal keeping
      // all the current props and just modifying the overlay config.
      if (result.shouldSwitch) {
        if (target === 'legacy') {
          onShowLegacyModal(
            modalSwitcher,
            transformOverlayToLegacy?.(partialOverlay),
          );
        } else {
          onShowCurrentModal(
            modalSwitcher,
            transformOverlayToV2?.(partialOverlay),
          );
        }
      }
      // If the user chooses not to switch, the opposite to the target modal
      // is shown.
      else if (target === 'legacy') {
        onShowCurrentModal?.(modalSwitcher, partialOverlay);
      } else {
        onShowLegacyModal?.(modalSwitcher, partialOverlay);
      }
    },
    [dispatch],
  );

  return {
    onToggleLegacyMode: handleToggleLegacyMode,
  };
};

export default useTextOverlayStatelessSwitch;

import * as React from 'react';
import { Position, Rnd as ReactRnd, RndResizeCallback } from 'react-rnd';

import { noop } from 'underscore';
import Rnd, { RndProps } from 'components/Rnd';
import usePrevious from 'hooks/usePrevious';
import { Dimensions, Size } from 'types';
import bem from 'utils/bem';
import { createChainedFunction } from 'utils/functions';
import { hasDimensions, numberOrPxToPx } from 'utils/placement';
import { Measurement, Pixels } from '../../../utils/measurement';
import { useCanvasSize } from '../context/CanvasSizeContext';
import { useEditorDispatch } from '../context/VideoEditorDispatchContext';
import { useEditorState } from '../context/VideoEditorStateContext';
import { useDragAlignment } from '../DragAlignmentContext';
import useRndAssetAutoAdjust from './useRndAssetAutoAdjust';

const { useCallback, useEffect, useMemo, useRef, useState } = React;

const block = bem('rnd-asset');

export type RndAssetCallback = (value: Dimensions<Pixels>, params: any) => void;

export interface RndAssetProps
  extends Dimensions<Measurement>,
    Pick<
      RndProps,
      | 'bounds'
      | 'disableDragging'
      | 'enableResizing'
      | 'lockAspectRatio'
      | 'onMouseDown'
    > {
  assetId: string;
  children?: React.ReactNode;
  minSizePx?: Size<number>;
  onDragStop?: RndAssetCallback;
  onResize?: (delta: Size<number>, dimensions: Size<number>) => void;
  onResizeStart?: () => void;
  onResizeStop?: RndAssetCallback;
  params?: any;
  resizeWhenBelowMin?: boolean;
  disableOutline?: boolean;
}

const RndAsset: React.FC<RndAssetProps> = ({
  assetId,
  bounds,
  children,
  disableDragging,
  enableResizing,
  height,
  left,
  lockAspectRatio = true,
  minSizePx,
  onDragStop: onDragStopProp = noop,
  onMouseDown = noop,
  onResize: onResizeProp = noop,
  onResizeStart: onResizeStartProp = noop,
  onResizeStop: onResizeStopProp = noop,
  params,
  resizeWhenBelowMin,
  top,
  width,
  disableOutline,
}) => {
  const dispatch = useEditorDispatch();
  const { disabled, selectedAsset } = useEditorState();
  const { toPx } = useCanvasSize();
  const rndRef = useRef<ReactRnd>();

  const active = selectedAsset.id === assetId;

  const dimensions = useMemo(() => ({ height, left, top, width }), [
    height,
    left,
    top,
    width,
  ]);

  const prevDimensions = usePrevious(dimensions);

  const topPx = useMemo(() => toPx(top), [top, toPx]);
  const leftPx = useMemo(() => toPx(left), [left, toPx]);
  const heightPx = useMemo(() => toPx(height), [height, toPx]);
  const widthPx = useMemo(() => toPx(width), [width, toPx]);

  // TODO: storing position and size locally is an optimization so that onDrag
  // doesn't cause too much of the VideoTemplateEditor tree to re-render.
  // single source of truth would be better - compare both implementations and
  // see if we can minimize unnecessary renders if it's a problem
  const [position, setPosition] = useState({
    x: leftPx?.value,
    y: topPx?.value,
  });
  const [size, setSize] = useState({
    height: heightPx?.value,
    width: widthPx?.value,
  });

  const heightValue = heightPx?.value;
  const widthValue = widthPx?.value;
  const numericSize = useMemo(
    () => ({
      height: heightValue,
      width: widthValue,
    }),
    [heightValue, widthValue],
  );

  const {
    dragAxis,
    onDrag,
    onDragStart,
    onDragStop,
    onResizeStart,
    onResizeStop,
  } = useDragAlignment({
    onDragStart: (_1, _2, { x, y }) => {
      setPosition({ x, y });
      dispatch({
        type: 'ASSET_DRAG_START',
        payload: { id: assetId },
      });
    },
    onDrag: (_1, _2, { x, y }) => {
      setPosition({ x, y });
    },
    onDragStop: (_1, _2, { x, y }) => {
      // technically unneccessary, since onDragStopProp values will hit the reducer
      // and will be caught in the useEffect hook below
      setPosition({ x, y });
      onDragStopProp(
        {
          height: heightPx,
          left: new Pixels(x),
          top: new Pixels(y),
          width: widthPx,
        },
        params,
      );
      dispatch({ type: 'ASSET_DRAG_STOP' });
    },
    size: numericSize,
  });

  useEffect(() => {
    if (!hasDimensions(dimensions)) return;

    const haveDimensionsChanged = ['height', 'left', 'top', 'width'].some(
      key => !dimensions[key]?.eq(prevDimensions?.[key]),
    );

    // handles changes from the outside - e.g. double click on the canvas to effect
    // a change in the asset
    if (haveDimensionsChanged) {
      setSize({ height: heightPx.value, width: widthPx.value });
      setPosition({ x: leftPx.value, y: topPx.value });
    }
  }, [dimensions, heightPx, leftPx, prevDimensions, topPx, widthPx]);

  const handleSizeAdjust = useCallback(
    (newSize: Size<number>): void => {
      setSize({ height: newSize.height, width: newSize.width });
      onResizeStopProp(
        numberOrPxToPx({
          height: newSize.height,
          left: position.x,
          top: position.y,
          width: newSize.width,
        }),
        params,
      );
    },
    [onResizeStopProp, params, position.x, position.y],
  );

  useRndAssetAutoAdjust({
    minSizePx,
    onResize: handleSizeAdjust,
    size,
    shouldAdjust: resizeWhenBelowMin,
  });

  const handleResizeStart = useCallback(
    (e, dir, ref) => {
      onResizeStartProp();
      onResizeStart(e, dir, ref);
      dispatch({
        type: 'ASSET_DRAG_START',
        payload: { id: assetId },
      });
    },
    [assetId, dispatch, onResizeStart, onResizeStartProp],
  );

  const handleResize = useCallback(
    (_1, _2, ref, delta, pos: Position) => {
      const newSize = { height: ref.offsetHeight, width: ref.offsetWidth };

      setSize(size);
      setPosition({ x: pos.x, y: pos.y });

      onResizeProp(delta, newSize);
    },
    [onResizeProp, size],
  );

  const handleResizeStop: RndResizeCallback = useCallback(
    (e, dir, ref, delta, pos) => {
      onResizeStop(e, dir, ref, delta, pos);
      handleResize(e, dir, ref, delta, pos);
      dispatch({ type: 'ASSET_DRAG_STOP' });
      onResizeStopProp(
        numberOrPxToPx({
          height: ref.offsetHeight,
          left: pos.x,
          top: pos.y,
          width: ref.offsetWidth,
        }),
        params,
      );
    },
    [dispatch, handleResize, onResizeStop, onResizeStopProp, params],
  );

  const handleMouseDown = useCallback(() => {
    if (!active) {
      dispatch({
        type: 'ASSET_SELECT',
        payload: { id: assetId },
      });
    }
  }, [active, assetId, dispatch]);

  return (
    <Rnd
      className={block({
        active,
        interactable:
          !disabled &&
          (!selectedAsset.dragging || selectedAsset.id === assetId),
      })}
      disableDragging={disabled || disableDragging}
      cornerHandleSize="large"
      enableResizing={disabled || !active ? false : enableResizing}
      minSize={minSizePx}
      onMouseDown={createChainedFunction(onMouseDown, handleMouseDown)}
      onResizeStart={handleResizeStart}
      onResize={handleResize}
      onResizeStop={handleResizeStop}
      ref={rndRef}
      {...{
        active,
        bounds,
        dragAxis,
        lockAspectRatio,
        onDrag,
        onDragStart,
        onDragStop,
        position,
        size,
        disableOutline,
      }}
    >
      {children}
    </Rnd>
  );
};

export default RndAsset;

import { List, Map, RecordOf } from 'immutable';
import * as ids from 'short-id';
import _ from 'underscore';

import { VideoPosition } from 'blocks/VideoCropper';
import {
  RecommendedImageAsset,
  RecommendedVideoAsset,
} from 'redux/middleware/api/keyword-extractor-service';
import { IVideoUpload } from 'redux/middleware/api/media-upload-service';
import { CropInfo, EmbedVideoState } from 'redux/modules/embed/types';
import { positionFactory, videoFactory } from 'redux/modules/embed/utils';

import {
  AspectRatioDimensions,
  AspectRatioName,
  CropMetadata,
  Dimensions,
  IImmutableMap,
  Position,
  Size,
} from 'types';

import { getAspectRatio } from 'utils/aspect-ratio';
import { AudioTransition } from 'utils/audio';
import { getValue } from 'utils/collections';
import { ViewportHeight, ViewportWidth } from 'utils/measurement';
import { percentageOf, scale } from 'utils/numbers';

import { scaleCropDimensions } from 'utils/video-crop';
import { IPosition, IVideoClip } from '../../types/embed-config';
import { defaultTrackIndex } from './tracks';

const DEFAULT_MEDIA_TRACK_ID = defaultTrackIndex('media');

const isDefined = _.negate(_.isUndefined);

export enum videoTransitionDirections {
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
}

export enum videoTransitionTypes {
  FADE = 'FADE',
  SLIDE_UP = 'SLIDE_UP',
  SLIDE_DOWN = 'SLIDE_DOWN',
  SLIDE_RIGHT = 'SLIDE_RIGHT',
  SLIDE_LEFT = 'SLIDE_LEFT',
  CUT = 'CUT',
}

// TODO move this to common location
export enum VideoScaling {
  FILL = 'FILL',
  FIT = 'FIT',
}

export const videoTransitions = {
  [videoTransitionTypes.FADE]: {
    durationMilli: 1000,
    effect: 'fade',
    to: null,
  },
  [videoTransitionTypes.SLIDE_RIGHT]: {
    durationMilli: 1000,
    effect: 'slide',
    to: videoTransitionDirections.RIGHT,
  },
  [videoTransitionTypes.SLIDE_LEFT]: {
    durationMilli: 1000,
    effect: 'slide',
    to: videoTransitionDirections.LEFT,
  },
  [videoTransitionTypes.SLIDE_UP]: {
    durationMilli: 1000,
    effect: 'slide',
    to: videoTransitionDirections.TOP,
  },
  [videoTransitionTypes.SLIDE_DOWN]: {
    durationMilli: 1000,
    effect: 'slide',
    to: videoTransitionDirections.BOTTOM,
  },
  [videoTransitionTypes.CUT]: { effect: 'cut' },
};

export interface IDimensions {
  height: number;
  width: number;
}

const getTransitionKey = (videoClip: IVideoClip, end: string): string => {
  const transition = getValue(videoClip, ['transition', end]);

  if (!transition) return undefined;

  if (transition.effect === 'slide') {
    switch (transition.to) {
      case videoTransitionDirections.TOP:
        return videoTransitionTypes.SLIDE_UP;
      case videoTransitionDirections.BOTTOM:
        return videoTransitionTypes.SLIDE_DOWN;
      case videoTransitionDirections.RIGHT:
        return videoTransitionTypes.SLIDE_RIGHT;
      case videoTransitionDirections.LEFT:
        return videoTransitionTypes.SLIDE_LEFT;
      default:
        return videoTransitionTypes.SLIDE_UP;
    }
  }

  return videoTransitionTypes[transition.effect.toUpperCase()];
};

const parsePosition = (position: IPosition) => {
  if (!position || _.isEmpty(position)) return undefined;

  return positionFactory({
    left: parseFloat(position.left),
    top: parseFloat(position.top),
  }) as RecordOf<Position<number>>;
};

const parseFloatValue = (floatString: string) => {
  if (!floatString) return undefined;

  return parseFloat(floatString);
};

export function formatVideoFromConfig(videoClip: IVideoClip) {
  const zoom = getValue(videoClip, ['editor', 'zoom']);

  return videoFactory({
    zoom,
    assetType: getValue(videoClip, 'assetType', 'video'),
    audioFadeInDurationMillis: getValue(
      videoClip,
      ['audio', 'transition', 'in', 'durationMilli'],
      0,
    ),
    audioFadeOutDurationMillis: getValue(
      videoClip,
      ['audio', 'transition', 'out', 'durationMilli'],
      0,
    ),
    audioLevel: getValue(videoClip, ['audio', 'volume', 'self']),
    audioSrc: getValue(videoClip, ['audio', 'audioUrl']),
    blurredBackground: getValue(videoClip, ['backfill', 'enableBlur']),
    endMillis: videoClip.endMilli,
    height:
      parseFloatValue(videoClip.style.height) || videoClip.editor.videoHeight,
    id: ids.generate(),
    mainAudioLevel: getValue(videoClip, ['audio', 'volume', 'other']),
    playFromMillis: videoClip.playOffsetMilli,
    cropInfo: videoClip.cropInfo,
    editor: getValue(videoClip, 'editor'),
    position: parsePosition(videoClip.position),
    previewThumbnailUrl: getValue(videoClip, ['previewThumbnail', 'url']),
    scaling: getValue(videoClip, ['editor', 'scaling']),
    serverId: videoClip.videoId,
    sourceDurationMillis: videoClip.sourceDurationMilli,
    src: videoClip.videoUrl,
    startMillis: videoClip.startMilli,
    transitionIn: getTransitionKey(videoClip, 'in'),
    transitionOut: getTransitionKey(videoClip, 'out'),
    width:
      parseFloatValue(videoClip.style.width) || videoClip.editor.videoWidth,
  });
}

export function createVideoClipFromVideo(
  video: any,
  sourceDurationMillis: number,
  ratio: IDimensions,
  enableBlur = false,
) {
  const durationMillis =
    video.get('trimEndMillis') - video.get('trimStartMillis') ||
    sourceDurationMillis;

  const style = getVideoStyle(
    VideoScaling.FIT,
    video.get('videoWidth'),
    video.get('videoHeight'),
    ratio,
  );
  return {
    audio: {
      audioUrl: video.getIn(['audioExtract', 'url']),
      enableVideoRenderMixdown: false,
      transition: {
        in: {
          durationMilli: 0,
          effect: 'cut',
        },
        out: {
          durationMilli: 0,
          effect: 'cut',
        },
      },
      volume: {
        other: 1,
        self: 1,
      },
    },
    backfill: {
      enableBlur,
      position: {
        left: '0px',
        right: '0px',
      },
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        height: '100vh',
        width: '100vw',
      },
    },
    editor: {
      scaling: VideoScaling.FIT,
      videoHeight: video.get('videoHeight'),
      videoWidth: video.get('videoWidth'),
      viewport: { ...ratio },
      zoom: style.zoom,
    },
    /*
     * video is hardcoded to start at 0 on timeline, so it will end at a time equal to
     * its trimmed duration
     */
    endMilli: durationMillis,
    layerId: DEFAULT_MEDIA_TRACK_ID,
    playOffsetMilli: 0,
    // embed-preview requires position key to be an object, but it doesn't have to be populated
    position: {
      top: `${style.top || 0}vh`,
      left: `${style.left || 0}vw`,
    },
    previewThumbnail: {
      url: video.getIn(['previewThumbnail', 'thumbnails', 0, 'url']),
    },
    sourceDurationMilli: durationMillis,
    startMilli: 0,
    style: {
      height: `${style.height || 100}vh`,
      width: `${style.width || 100}vw`,
    },
    videoId: video.get('id'),
    videoUrl: video.get('transcodedVideoUrl'),
  };
}

export const buildVideoBackfill = (enableBlur = false) => ({
  enableBlur,
  position: {
    left: '0px',
    top: '0px',
  },
  style: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    height: '100vh',
    width: '100vw',
  },
});

export function getVideoStyle(
  scaling: VideoScaling,
  videoWidth: number,
  videoHeight: number,
  aspectRatioDimensions: IDimensions,
) {
  const { height: vh, width: vw } = aspectRatioDimensions;
  const aspectRatio = vw / vh;
  const videoAspectRatio = videoWidth / videoHeight;

  const createResponse = ({
    height,
    width,
    top,
    left,
    zoom,
  }: {
    height?: number;
    width?: number;
    top?: number;
    left?: number;
    zoom: number;
  }) => {
    const style = { height, width, top, left, zoom };
    return _.pick(style, val => !_.isUndefined(val));
  };

  if (videoAspectRatio === aspectRatio) {
    return createResponse({
      height: 100,
      left: 0,
      top: 0,
      width: 100,
      zoom: 1,
    });
  }

  let scaledWidth;
  let scaledHeight;
  let scaledLeft;
  if (
    (videoAspectRatio < aspectRatio && scaling === VideoScaling.FIT) ||
    (videoAspectRatio > aspectRatio && scaling === VideoScaling.FILL)
  ) {
    scaledHeight = vh;
    scaledWidth = scale(videoWidth, videoHeight, scaledHeight);
    scaledLeft = (vw - scaledWidth) / 2;
    return createResponse({
      height: percentageOf(scaledHeight, vh),
      left: percentageOf(scaledLeft, vw),
      top: 0,
      width: percentageOf(scaledWidth, vw),
      zoom: scaling === VideoScaling.FIT ? 1 : scaledWidth / vw,
    });
  }
  scaledWidth = vw;
  scaledHeight = scale(videoHeight, videoWidth, scaledWidth);
  const scaledTop = (vh - scaledHeight) / 2;
  return createResponse({
    height: percentageOf(scaledHeight, vh),
    left: 0,
    top: percentageOf(scaledTop, vh),
    width: percentageOf(scaledWidth, vw),
    zoom: scaling === VideoScaling.FIT ? 1 : scaledHeight / vh,
  });
}

export function formatVideoForConfig(
  video: EmbedVideoState,
  aspectRatioDimensions: IDimensions,
  trackIndex: number,
) {
  return {
    assetType: video.assetType,
    audio: {
      audioUrl: video.audioSrc,
      enableVideoRenderMixdown: false,
      transition: {
        in: {
          durationMilli: video.audioFadeInDurationMillis || 0,
          effect: video.audioFadeInDurationMillis > 0 ? 'fade' : 'cut',
        },
        out: {
          durationMilli: video.audioFadeOutDurationMillis || 0,
          effect: video.audioFadeOutDurationMillis > 0 ? 'fade' : 'cut',
        },
      },
      volume: {
        other: video.mainAudioLevel,
        self: video.audioLevel,
      },
    },
    backfill: buildVideoBackfill(video.blurredBackground),
    editor: {
      scaling: video.scaling,
      videoHeight: video.height,
      videoWidth: video.width,
      viewport: {
        ...aspectRatioDimensions,
      },
      zoom: video.zoom,
    },
    endMilli: video.endMillis,
    layerId: trackIndex,
    playOffsetMilli: video.playFromMillis,
    position: formatPosition(video.position),
    previewThumbnail: {
      url: video.previewThumbnailUrl,
    },
    sourceDurationMilli: video.sourceDurationMillis,
    cropInfo: video.cropInfo,
    startMilli: video.startMillis,
    style: {
      height: `${video.height || 100}vh`,
      width: `${video.width || 100}vw`,
    },
    transition: {
      in: video.transitionIn && videoTransitions[video.transitionIn],
      out: video.transitionOut && videoTransitions[video.transitionOut],
    },
    videoId: video.serverId,
    videoUrl: video.src,
  };
}

export const transformPlacementToEditorConfig = (
  placement?: Dimensions<ViewportHeight, ViewportWidth>,
  scaledVideoDimensions?: Size<number>,
): IVideoClip['editor'] => {
  if (!placement) {
    return undefined;
  }

  const { width, height } = scaledVideoDimensions ?? {};

  const videoHeight = height ?? placement.height.value;
  const videoWidth = width ?? placement.width.value;
  const zoom = (videoHeight > videoWidth ? videoHeight : videoWidth) / 100;

  return {
    videoHeight,
    videoWidth,
    viewport: {
      width: 1,
      height: 1,
    },
    zoom,
  };
};

const formatPosition = (
  position: VideoPosition | IImmutableMap<VideoPosition>,
) => {
  const top = getValue(position, 'top');
  const left = getValue(position, 'left');

  if (top === undefined || left === undefined) {
    return undefined;
  }

  return { top: `${top}vh`, left: `${left}vw` };
};

const transformMetadataToCropInfo = (
  videoClip: IVideoClip,
  metadata?: CropMetadata,
): CropInfo => {
  if (!metadata) {
    return undefined;
  }

  const { crop, imageData } = metadata;

  return {
    staticCrop: scaleCropDimensions(
      crop,
      {
        width: videoClip?.editor?.videoWidth,
        height: videoClip?.editor?.videoHeight,
      },
      {
        width: imageData?.naturalWidth,
        height: imageData?.naturalHeight,
      },
    ),
  };
};

export const getVideoConfigFromUCS = (
  videoClip: IVideoClip,
  canvas: Size<number, number>,
  layerIndex: number,
  placement: Dimensions<
    ViewportHeight,
    ViewportWidth,
    ViewportHeight,
    ViewportWidth
  >,
  enableBlur: boolean,
  metadata: CropMetadata,
) => {
  return {
    ...videoClip,
    position: {
      left: placement.left.toUnit('vw', canvas).toString(),
      top: placement.top.toUnit('vh', canvas).toString(),
    },
    style: {
      height: placement.height.toUnit('vh', canvas).toString(),
      width: placement.width.toUnit('vw', canvas).toString(),
    },
    backfill: buildVideoBackfill(enableBlur),
    editor: transformPlacementToEditorConfig(placement),
    layerId: layerIndex,
    cropInfo: transformMetadataToCropInfo(videoClip, metadata),
  };
};

export function audioFadeDurationToTransition(
  durationMillis: number,
): AudioTransition {
  const durationSec =
    _.isUndefined(durationMillis) || durationMillis === 0
      ? 0
      : durationMillis / 1000;

  const effect = durationSec === 0 ? 'cut' : 'fade';

  return { durationSec, effect };
}

export function audioFadeUiOptToDurationMillis(
  option: 'none' | 'short' | 'long',
) {
  switch (option) {
    case 'short':
      return 1000;

    case 'long':
      return 2000;

    default:
      return 0;
  }
}

export function audioFadeDurationToUiOpt(durationMillis: number) {
  switch (durationMillis) {
    case 1000:
      return 'short';

    case 2000:
      return 'long';

    default:
      return 'none';
  }
}

export function sortVideos(
  videosById: Map<string, EmbedVideoState>,
): List<string> {
  if (!videosById) return undefined;
  return videosById
    .keySeq()
    .sortBy(id => videosById.get(id).startMillis)
    .toList();
}

type RichRecommendedVideoAsset = RecommendedVideoAsset & IVideoUpload;

export function isVideo<T extends RecommendedVideoAsset>(
  asset: RecommendedImageAsset | T,
): asset is T {
  return asset.type === 'video';
}

export function getVideoClipsFromRecommendation(
  recommendation: Array<RecommendedImageAsset | RichRecommendedVideoAsset>,
  aspectRatio: AspectRatioName | AspectRatioDimensions,
): IVideoClip[] {
  const ratio = _.isString(aspectRatio)
    ? getAspectRatio(aspectRatio).toJS()
    : aspectRatio;

  return recommendation
    .filter(isVideo)
    .sort((v1, v2) => v1.startMilli - v2.startMilli)
    .map(video => {
      const { height, width, top, left, zoom } = getVideoStyle(
        VideoScaling.FILL,
        video.videoWidth,
        video.videoHeight,
        ratio,
      );

      return {
        assetType: 'video',
        backfill: buildVideoBackfill(false),
        editor: {
          zoom,
          scaling: VideoScaling.FILL,
          videoHeight: video.videoHeight,
          videoWidth: video.videoWidth,
          viewport: { ...ratio },
        },
        endMilli: video.endMilli,
        layerId: DEFAULT_MEDIA_TRACK_ID,
        playOffsetMilli: video.assetDetail.playOffsetMilli,
        position: { top: `${top}vh`, left: `${left}vw` },
        previewThumbnail: {
          url: video.previewThumbnail.thumbnails[0].url,
        },
        sourceDurationMilli: video.durationMillis,
        startMilli: video.startMilli,
        style: { height: `${height}vh`, width: `${width}vw` },
        transition: video.assetDetail.transition,
        videoId: video.id,
        videoUrl: video.transcodedVideoUrl,
      };
    });
}

export function hasAllDimensions(video: {
  position?: {
    top?: number;
    left?: number;
  };
  height: number;
  width: number;
}) {
  if (!video) return false;
  return [
    ['position'],
    ['position', 'top'],
    ['position', 'left'],
    ['height'],
    ['width'],
  ].every(path => isDefined(getValue(video, path)));
}

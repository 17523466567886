import cn from 'classnames';
import * as React from 'react';

import WaveGenerationInput, {
  WaveGenerationInputProps,
} from 'components/WaveGenerationInput';
import { Soundwave, SoundwaveOption, SoundwaveType } from 'types';
import { getValue, omitUndefined } from 'utils/collections';
import AudioFadeControls from '../../components/AudioFadeControls';
import WavePositionField, {
  WaveDimensions,
  WavePositionFieldProps,
} from '../../components/WavePositionField';
import WaveStyleField, {
  WaveStyleValue,
} from '../../components/WaveStyleField';

const { useCallback } = React;

interface IProps {
  audioFadeInDuration?: number;
  audioFadeOutDuration?: number;
  className?: string;
  type?: SoundwaveType;
  position?: WaveDimensions;
  color?: string;
  generatingWaveform?: boolean;
  onAudioFadeInChange?: (value: number) => void;
  onAudioFadeOutChange?: (value: number) => void;
  onTypeChange?: (type: SoundwaveType) => void;
  onPositionChange?: WavePositionFieldProps['onChange'];
  onColorChange?: (color: string) => void;
  onGenerationChange?: WaveGenerationInputProps['onChange'];
  showAudioFadeControls?: boolean;
  showWaveGenerationField?: boolean;
  showWaveStyleFields?: boolean;
  waveGeneration?: WaveGenerationInputProps['value'];
  defaultSoundwave?: Soundwave;
  defaultColorReadonly?: boolean;
  defaultGenerationReadonly?: boolean;
  defaultPositionReadonly?: boolean;
  defaultTypeReadonly?: boolean;
  generationOptions?: SoundwaveOption[];
  positionOptions?: SoundwaveOption[];
  typeOptions?: SoundwaveOption[];
}

const AudioWaveOptions: React.SFC<IProps> = ({
  className,
  generatingWaveform,
  onTypeChange,
  onPositionChange,
  onColorChange,
  onGenerationChange,
  type,
  position,
  color,
  showWaveGenerationField,
  waveGeneration,
  showAudioFadeControls,
  audioFadeInDuration,
  audioFadeOutDuration,
  onAudioFadeInChange,
  onAudioFadeOutChange,
  defaultSoundwave,
  defaultColorReadonly,
  defaultGenerationReadonly,
  defaultPositionReadonly,
  defaultTypeReadonly,
  generationOptions,
  positionOptions,
  typeOptions,
  showWaveStyleFields = true,
}) => {
  const handleStyleChange = useCallback(
    (value: WaveStyleValue) => {
      if (value.color !== color) {
        onColorChange(value.color);
      }

      if (value.type !== type) {
        onTypeChange(value.type);
      }
    },
    [color, type, onColorChange, onTypeChange],
  );

  return (
    <form className={cn('audio-wave-options', className)}>
      {showWaveStyleFields && (
        <>
          <WaveStyleField
            defaultValue={omitUndefined({
              color: getValue(defaultSoundwave, 'waveColor'),
              type: getValue(defaultSoundwave, 'waveType'),
            })}
            generatingWaveform={generatingWaveform}
            lockColor={defaultColorReadonly}
            lockType={defaultTypeReadonly}
            onChange={handleStyleChange}
            soundwaveOptions={typeOptions}
            value={{ color, type }}
          />
          <WavePositionField
            onChange={onPositionChange}
            value={position}
            defaultValue={getValue(defaultSoundwave, 'wavePosition')}
            disabled={type === 'none' || generatingWaveform}
            locked={defaultPositionReadonly}
            options={positionOptions}
          />
        </>
      )}
      {showWaveGenerationField && (
        <WaveGenerationInput
          disabled={type === 'none' || generatingWaveform}
          onChange={onGenerationChange}
          value={waveGeneration}
          defaultValue={getValue(defaultSoundwave, 'waveGeneration')}
          locked={defaultGenerationReadonly}
          options={generationOptions}
        />
      )}

      {showAudioFadeControls && (
        <AudioFadeControls
          audioFadeInDuration={audioFadeInDuration}
          audioFadeOutDuration={audioFadeOutDuration}
          onAudioFadeInChange={onAudioFadeInChange}
          onAudioFadeOutChange={onAudioFadeOutChange}
        />
      )}
    </form>
  );
};

AudioWaveOptions.defaultProps = {};

export default AudioWaveOptions;
export { IProps as AudioWaveOptionsProps };

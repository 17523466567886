import { useEffect, useMemo, useRef } from 'react';
import { isUndefined, noop, pick } from 'underscore';

import useFileSize, { FileSource, UseFileSizeOptions } from './useFileSize';
import usePrevious from './usePrevious';

interface UseMaxSizeFileOptions extends UseFileSizeOptions {
  onAccept?: (size: number, file: FileSource) => void;
  onReject?: (size: number, file: FileSource) => void;
}

const DEFAULT_OPTIONS = {
  onAccept: noop,
  onReject: noop,
};

export default function useMaxSizeFile(
  maxSizeMb: number,
  options?: UseMaxSizeFileOptions,
) {
  const opts = useMemo(
    () => ({
      ...DEFAULT_OPTIONS,
      ...options,
    }),
    [options],
  );

  const optsRef = useRef(opts);

  useEffect(() => {
    optsRef.current = opts;
  }, [opts]);

  const { fileSize, isGettingSize, setSource, source } = useFileSize(
    pick(opts, 'onError'),
  );
  const previousFileSize = usePrevious(fileSize);

  useEffect(() => {
    if (isUndefined(fileSize)) return;

    if (fileSize !== previousFileSize) {
      if (fileSize / 1024 ** 2 > maxSizeMb) {
        optsRef.current.onReject(fileSize, source);
      } else {
        optsRef.current.onAccept(fileSize, source);
      }
    }
  }, [fileSize, maxSizeMb, previousFileSize, source]);

  return {
    fileSize,
    isGettingSize,
    setSource,
    source,
  };
}

import { AuthenticationProvider } from 'types';
import { isEditorActive, isWizardActive } from 'utils/routes';
import { CompatibilityModalSources } from './types';

export const AUTH_PROVIDER_LOGIN_SOURCE_MAP: Record<
  AuthenticationProvider,
  string
> = {
  headliner: 'Email',
  'google-one-tap': 'GoogleOneTap',
  'google-sign-in': 'GoogleSignIn',
  apple: 'Apple',
};

export const COMPATIBILITY_MODAL_SOURCES: CompatibilityModalSources = {
  editor: isEditorActive,
  wizard: isWizardActive,
};
export const ADD_AUDIOGRAM_IMAGE = 'Add Audiogram Image';
export const ADD_EPISODE_IMAGE = 'Add Episode Image';
export const AUDIO_UPLOAD_LONG_ERROR = 'Error Long Audio';
export const AUTOMATION_WATERMARK_POPOVER = 'AutomationWatermarkPopper';
export const BROWSER_SIZE_INVALID_EVENT = 'Browser Size Invalid';
export const BROWSER_SIZE_VALID_EVENT = 'Browser Size Valid';
export const CHANGE_CANVAS_COLOR = 'Change Background Color';
export const CHANGE_CAPTION_STATE = 'Change Caption State';
export const CLAIM_PODCAST_BANNER = 'ClickClaimPodcastBanner';
export const CLAIM_PODCAST_SUBMIT = 'SubmitClaimPodcastFlow';
export const CLICK_START_NEW_PROJECT = 'Click Start Wizard';
export const CLICK_AUDIOGRAM_PROJECT_EVENT = 'New Audiogram Project';
export const CLICK_TEXT_TO_VIDEO_PROJECT_EVENT = 'New Text to Video PRoject';
export const CLICK_SELF_SERVE_DISCO_BANNER = 'ClickSelfServeDiscoBanner';
export const CLICK_VIDEO_TRANSCRIPT_PROJECT_EVENT =
  'New Video Transcription Project';
export const CLICK_BLANK_PROJECT_EVENT = 'New Blank Project';
export const CLICK_FULL_EPISODE_PROJECT_EVENT = 'New Full Episode Project';
export const CLICK_RECOMMENDED_PROJECT_EVENT = 'New Automated Project';
export const CLICK_SEARCH_IMAGE = 'Click Search Image';
export const CLICK_SEARCH_VIDEO = 'Click Search Video';
export const CLICK_SEARCH_GIF = 'Click Search Gif';
export const CLICK_TWEET_VIDEO = 'Click Tweet Video';
export const CLICK_POST_FACEBOOK_VIDEO = 'Click Post Facebook Video';
export const CLICK_POST_YOUTUBE_VIDEO = 'Click Post Youtube Video';
export const CLICK_POST_LINKEDIN_VIDEO = 'Click Post Linkedin Video';
export const CLICK_POST_THREADS_VIDEO = 'ClickThreadsButton';
export const CLICK_POST_TIKTOK_VIDEO = 'Click Post Tiktok Video';
export const LOAD_INSTAGRAM_POST_MODAL = 'LoadInstagramPostModal';
export const CLIK_INSTAGRAM_POST = 'ClickInstagramPostModalCTA';
export const TIKTOK_SUBMIT_FOR_UPLOAD = 'Tiktok Submit For Upload';
export const TIK_TOK_VIDEO_UPLOADED_SUCCESSFULLY =
  'TikTok Video Uploaded Successfully';
export const INSTAGRAM_SUBMIT_FOR_UPLOAD = 'Instagram Submit For Upload';
export const INSTAGRAM_VIDEO_UPLOADED_SUCCESSFULLY =
  'Instagram Video Uploaded Successfully';
export const THREADS_VIDEO_UPLOADED_SUCCESSFULLY = 'ThreadsVideoPosted';
export const FACEBOOK_VIDEO_UPLOADED_SUCCESSFULLY =
  'Facebook Video Uploaded Successfully';
export const TWITTER_SUBMIT_FOR_UPLOAD = 'Twitter Submit For Upload';
export const TWITTER_VIDEO_UPLOADED_SUCCESSFULLY =
  'Twitter Video Uploaded Successfully';
export const CLICK_INSTAGRAM_BUTTON = 'Click Instagram Button';
export const CLICK_INSTAGRAM_MOBILE_APP_LINK =
  'Click Instagram Mobile App Link';
export const EDDY_PROMO_MODAL_LOAD = 'EddyPromoModalLoad';
export const COMPLETE_WIZARD = 'Complete Wizard';
export const COMPLETE_UPGRADE = 'Complete Upgrade';
export const COMPLETE_DOWNGRADE = 'Complete Downgrade';
export const CANCEL_WIZARD = 'Cancel Wizard';
export const CREATE_USER_EVENT = 'Create User';
export const ERROR_NOTIFICATION = 'Error Notification';
export const EXPORT_VIDEO = 'Export Video';
export const FORGOT_PASSWORD = 'Forgot Password';
export const GO_BACK_TO_EDITING = 'GoBackToEditing';
export const IMAGE_SEARCH = 'Image Search';
export const LOGIN_EVENT = 'Login';
export const LOGOUT_EVENT = 'Logout';
export const NAME_PROJECT = 'Name Project';
export const OPEN_PROJECT = 'Open project';
export const PREVIEW_AUTOMATION = 'PreviewAutomation';
export const SIGNUP = 'Signup';
export const SIGNUP_COMPLETE = 'Complete Signup';
export const SIGNUP_ERROR = 'Error On Signup';
export const WATCH_INFO_VIDEO = 'Watch Info Video';
export const CLICK_SAMPLE_PROJECT = 'Click Sample Project';
export const VIDEO_SEARCH = 'Video Search';
export const CLICK_CLOUD_SAVE = 'Click Cloud Save';
export const COPY_PROJECT = 'Copy Project';
export const ADD_CAPTION = 'Add Caption';
export const DELETE_CAPTION = 'Delete Caption';
export const ADD_EMOJI = 'Add Emoji';
export const CLICK_DOWNLOAD_LINK = 'Click Download Link';
export const RESIZE_TIMELINE_ASSET = 'Resize Timeline Asset';
export const CLICK_WIZARD_NEXT = 'Click Wizard Next';
export const CLICK_ADJUST_COLORS = 'Click Adjust Colors';
export const CLICK_BACK_TO_TEMPLATE_STYLES = 'Click Back to Template Styles';
export const COPY_CAPTION = 'Copy Caption';
export const COPY_ALL_CAPTIONS = 'Copy All Captions';
export const EDDY_POPPER_SHOWN = 'EddyPopperShown';
export const EDDY_POPPER_CLICKED = 'EddyPopperClicked';
export const APPLY_TEXT_TEMPLATE = 'Apply Text Template';
export const GIF_SEARCH = 'Gif Search';
export const REPLACE_ASSET = 'Replace Asset';
export const CLICK_REVERT_PROJECT = 'Revert Project';
export const CLICK_MARKETING_LINK = 'Click Referral Link';
export const ON_APP_ERROR = 'App Error';
export const CLICK_ERROR_REFRESH = 'Click Error Refresh';
export const FILE_UPLOAD = 'File Upload';
export const INTRO_OUTRO_LIMIT =
  'Sorry, that [intro or outro] clip exceeds our limits';
export const START_WALKTHROUGH = 'Start Walkthrough';
export const CLICK_WALKTHROUGH_NEXT = 'Click Walkthrough Next';
export const CLICK_WALKTHROUGH_BACK = 'Click Walkthrough Back';
export const COMPLETE_WALKTHROUGH = 'Complete Walkthrough';
export const CANCEL_WALKTHROUGH = 'Cancel Walkthrough';
export const TWEET_VIDEO_SUCCESS = 'Video Tweeted Successfully';
export const FACEBOOK_POST_VIDEO_SUCCESS = 'Facebook Video Posted Successfully';
export const YOUTUBE_POST_VIDEO_SUCCESS = 'Youtube Video Posted Successfully';
export const LINKEDIN_POST_VIDEO_SUCCESS = 'Linkedin Video Posted Successfully';
export const CLIK_DOWNLOAD_PAGE_DASHBOARD_LINK = 'DownloadPageDashboardLink';
export const CLICK_DOWNLOAD_VIDEO = 'Click Download Video';
export const CLICK_COPY_VIDEO_URL = 'Click Copy Video URL';
export const CLICK_KNOWLEDGE_BASE_URL = 'Click Knowledge Base URL';
export const CLICK_UPGRADE_PLAN = 'Click Upgrade Now';
export const CLICK_BOGO = 'Click BOGO';
export const CLICK_ADJUST_PLAN = 'Click Adjust My Plan';
export const CLICK_CANCEL_PLAN = 'Click Cancel Your Plan';
export const CLICK_TRY_EDDY_MODAL = 'ClickTryEddyModal';
export const CLICK_FONT_UPGRADE = 'Click Font Upgrade';
export const COMPLETE_PRO_ADJUSMENT = 'Complete Pro Adjustment';
export const COMPLETE_PRO_CANCELLATION = 'Complete Pro Cancellation';
export const CLICK_SAVE_TEMPLATE = 'Click Save Template';
export const SAVE_TEMPLATE = 'Save Template';
export const CLICK_EXPORT_UPGRADE = 'Click Export Upgrade';
export const CLICK_PROGRESS_BAR = 'Click Progress Bar';
export const CHANGE_PROGRESS_BAR = 'Change Progress Bar';
export const CLICK_GETTING_STARTED_GUIDE = 'Click Getting Started Guide';
export const CLICK_HEADER_BADGE = 'Click Header Badge';
export const CLICK_REFERRAL_SEND = 'Click Referral Send';
export const CLICK_REFERRAL_COPY = 'Click Referral Copy';
export const CLICK_PODCAST_SEARCH = 'Click Podcast Search';
export const CLICK_PODCAST_NAME = 'Click Podcast Name';
export const CLICK_EPISODE_NAME = 'Click Episode Name';
export const CLICK_PODCAST_TAB = 'Click Podcast Tab';
export const DELETE_AUTOMATED_PODCAST = 'Delete Automated Podcast';
export const CLICK_UPGRADE_UST_ES = 'Click Upgrade UST ES';
export const DISMISS_POST_TASK_PROMPT = 'Dismiss Post-Task Prompt';
export const CLICK_POST_TASK_PROMPT = 'Click Post-Task Prompt';
export const RECLIP_VISIBLE = 'Re-clip button visible';
export const CLICK_AUTOMATIC_PODCAST_VIDEOS = 'Click Automatic Podcast Videos';
export const REVIEW_STEP_LOADED = 'Review Step Loaded';
export const REVIEW_STEP_SHARE = 'Review Step: Share Button Clicked';
export const CLICK_RECLIP_FEEDBACK_SURVEY = 'Click ReClip Feedback Survey';
export const ONBOARDING_FLOW_NEXT = 'Onboarding Flow Next';
export const DOWNLOAD_PAGE_LOADED = 'Download Page Loaded';
export const EDITOR_LOADED = 'Editor Loaded';
export const CLICK_PRO_TEMPLATE = 'Click Pro Template';
export const CLICK_EDIT_AUTOMATION_SUBSCRIPTION =
  'Click Edit Automation Subscription';
export const CLICK_PRO_FEATURE = 'Click Pro Feature';
export const UPDATE_AUTOMATION_SUBSCRIPTION = 'Update Automation Subscription';
export const AUTO_CMS_BACK_CATALOG_NEW_BUTTON =
  'AutoCMS Back Catalog New Button';
export const AUTO_CMS_BACK_CATALOG_MODAL_SUBMIT_BUTTON =
  'AutoCMS Back Catalog Modal Submit Button';
export const NEW_AUTOMATION = 'New Automation';
export const CLICK_PRIORITY_QUEUE_UPGRADE = 'Click Priority Queue Upgrade';
export const CLICK_TRANSCRIPTION_TIME_BANK_UPGRADE = 'Click Time Bank Upgrade';
export const FREE_TRIAL_LEARN_MORE_CTA = 'Free Trial: Learn More CTA';
export const FREE_TRIAL_EXTEND_TRIAL_CTA = 'Free Trial: Extend Trial CTA';
export const SHOW_FREE_TRIAL_MODAL = 'Show Free Trial Modal';
export const UPLOADING_AUDIO = 'UploadingAudio';
export const TRANSFER_AUDIO_TO_STORAGE = 'ProcessingAudioTransferToS3';
export const BEGIN_AUDIO_ANALYSIS = 'ProcessingAudioStartAnalysisJob';
export const ANALYZE_AUDIO = 'ProcessingAudioAnalyzing';
export const REENCODING_AUDIO = 'ReencodingAudio';
export const FINALIZING_AUDIO = 'FinalizingAudio';
export const AUDIO_LOADED = 'AudioLoaded';
export const CLICK_ADD_PAYMENT_INFO = 'Click Add Payment Info';
export const PROJECTS_TAB_LOADED = 'ProjectsTabLoaded';
export const PROJECTS_PAGINATED_LOADER = 'ProjectsPaginatedLoader';
export const INCREASE_UPLOAD_LIMIT = 'IncreaseUploadLimit';
export const CLICK_UNLOCK_SMART_CLIPS = 'ClickUnlockSmartClips';
export const CLIP_SELECTION_PAGE_SMART_CLIP_CTA =
  'ClipSelectionPageSmartClipCTA';
export const FULL_EPISODE_ADD_CAPTIONS_FEATURE_FAKE =
  'FullEpisodeAddCaptionsFeatureFake';
export const PAUSE_SUBSCRIPTION = 'Pause Subscription';
export const COMPLETE_UNPAUSE_PLAN = 'CompleteUnpausePlan';
export const SUBMIT_PAUSE_MY_PLAN_DURATION = 'SubmitPauseMyPlanDuration';
export const CLICK_UNPAUSE_MY_PLAN = 'ClickUnpauseMyPlan';
export const CLICK_CANVA_BUTTON = 'ClickCanvaButton';
export const EXIT_TEMPLATE_WIZARD = 'ExitTemplateWizard';
export const CLICK_TEMPLATE_THUMBNAIL = 'ClickTemplateThumbnail';
export const SAVE_WAVEFORM_PRESET = 'SaveWaveformPreset';
export const DELETE_WAVEFORM_PRESET = 'DeleteWaveformPreset';
export const CLICK_FEED_COLLECT_BANNER = 'ClickFeedCollectBanner';
export const CLICK_SETUP_AUTO_POSTING_BANNER = 'ClickSetupAutoPostingBanner';
export const PODCAST_CONNECTED_MODAL = 'PodcastConnectedModal';
export const CLICK_DLP_BANNER = 'ClickDLPBanner';
export const CLICK_ACCOUNT_SETTINGS = 'Click Account Settings';
export const UNLOCK_THIS_FEATURE = 'UnlockThisFeature';
export const RETURN_TO_CLIP_AUDIO_STEP = 'ReturnToClipAudioStep';
export const CLICK_ASSET = 'ClickAsset';
export const SKIP_ONBOARDING_FEED_COLLECT = 'SkipOnboardingFeedCollect';
export const AI_GEN_SOCIAL_CAPTIONS_UPGRADE_POINT =
  'AiGenSocialCaptionsUpgradePoint';
export const AI_GEN_SOCIAL_CAPTIONS_CLEAR_TEXT = 'AiGenSocialCaptionsClearText';
export const GOOGLE_AUTH_ERROR = 'Google Auth Error';
export const UNSAVED_WORK_ALERT = 'UnsavedWorkAlert';
export const EXTRACT_AUDIO_FROM_VIDEO = 'ExtractAudioFromVideo';
export const TRANSCRIBE_VIDEO_FILE = 'TranscribeVideoFile';
export const SHOW_RETRY_PERIOD_BANNER = 'RetryPeriodBannerShown';
export const CLICK_RETRY_PERIOD_BANNER = 'RetryPeriodBannerClicked';
export const SHOW_UPDATE_CREDIT_CARD_MODAL = 'UpdateCreditCardModalShown';
export const CLICK_UPDATE_CREDIT_CARD_MODAL = 'UpdateCreditCardModalClicked';
export const CLICK_ANIMATE_IMAGE = 'AnimateImage';
export const SHOW_PLAN_CANCELLED_MODAL = 'YourPlanWasCancelledModalShown';
export const CLICK_PLAN_CANCELLED_MODAL = 'YourPlanWasCancelledModalClicked';
export const SUGGESTED_CLIPS_PAGE_LOADED = 'SuggestedClipsPageLoaded';
export const CHOOSE_ANOTHER_CLIP_FROM_THIS_EPISODE =
  'ChooseAnotherClipFromThisEpisode';
export const DISLIKE_SUGGESTED_CLIP = 'DislikeSuggestedClip';
export const ADJUST_SUGGESTED_CLIP = 'AdjustSuggestedClip';
export const OPEN_SUGGESTED_CLIP_IN_ADVANCED_EDITOR =
  'OpenSuggestedClipInAdvancedEditor';
export const ADD_CLIP_TO_VIDEO = 'AddClipToVideo';
export const SELECT_YOUR_OWN_CLIP = 'SelectYourOwnClip';
export const ADD_CAPTIONS_MODAL_SUBMIT = 'AddCaptionsModalSubmit';
export const CREATE_VIDEO_FROM_EPISODE_ROW = 'CreateVideoFromEpisodeRow';
export const INCORRECT_FILE_TYPE = 'IncorrectFileType';
export const PROJECTS_TAB_ADD_BUTTON = 'ProjectsTabAddButton';
export const ON_DISPLAY_TRANSCRIBE_UNSUPPORTED_LANGUAGE_MODAL =
  'DisplayTranscribeUnsupportedLanguageModal';
export const ON_DISPLAY_TRANSCRIBE_OVERSIZED_FILE_MODAL =
  'DisplayTranscribeOversizedFileModal';
export const UPGRADE_TO_ADD_MORE_AUTOMATIONS = 'UpgradeToAddMoreAutomations';
export const CLICK_EPISODE_TRANSCRIPT_BUTTON = 'TranscriptButton';
export const CLICK_UNLOCK_EPISODE_TRANSCRIPTS = 'UnlockTranscripts';
export const CLICK_TRANSCRIBE_EPISODE = 'TranscribeThisEpisode';
export const DOWNLOAD_EPISODE_TRANSCRIPT = 'DownloadTranscript';
export const CLICK_SWITCH_TO_BASIC_MODAL = 'ClickSwitchToBasicModal';
export const EXPORTING_STEP_LOADED = 'ExportingStepLoaded';
export const CLICK_GO_TO_VIDEO = 'ClickGoToVideoButton';
export const CHECKOUT_MODAL_CHANGE_PLAN = 'CheckoutModalSelectAnotherPlan';
export const CLICK_REMOVE_DISCO_BUTTON = 'ClickRemoveDiscoButton';
export const EXPORT_DISCO_WIDGET_VARIANT_INITIALIZED =
  'ExportDiscoWidgetVariantInitialized';
export const CLICK_PODCAST_PERSONALIZATION_MODAL =
  'PodcastPersonalizationModal';
export const CLICK_AUTOMATION_TILE = 'Click Automation Tile';
export const CREATE_MANUAL_AUDIOGRAM = 'CreateManualAudiogram';
export const CLICK_TRANSCRIBE = 'ClickEddyButton';
export const CLICK_AUDIOGRAM_DESTINATION_MODAL =
  'ClickAudiogramDestinationModal';
export const EDDY_PROMO_MODAL_CTA_CLICK = 'EddyPromoModalClick';
export const CLICK_AUDIOGRAM_VIDEO_TYPES_MODAL = 'ClickVideoTypesModal';
export const INCOMPATIBLE_TEMPLATE_LEARN_MORE = 'IncompatibleTemplateLearnMore';
export const FULL_LENGTH_TEMPLATES_MODAL_LOADED =
  'FullLengthTemplatesModalLoaded';
export const FULL_LENGTH_TEMPLATES_MODAL_CTA = 'FullLengthTemplatesModalCTA';
export const SAVE_TEMPLATE_USE_CASE_SELECTED = 'SaveTemplateUseCaseSelected';
export const YOUTUBE_ACCOUNT_VERIFICATION_CHECK =
  'YouTubeAccountVerificationCheck';
export const CLICK_BACK_CATALOG_GATED_CARD = 'FullEpisodeBackCatalogGatedCard';
export const UNLOCK_1080P_EXPORT = 'Unlock1080pExports';
export const SHOW_EDDY_AI_BANNER = 'AiBannerShown';
export const CLICK_EDDY_AI_BANNER = 'AiBannerClicked';
export const CLICK_HOMEPAGE_ANALYTICS_TAB = 'ClickHomepageAnalyticsTab';

import { KarokeAnimationOption } from './types';

export const karaokeAnimationOptions: KarokeAnimationOption[] = [
  {
    label: 'text',
    value: 'activeWordColor',
  },
  {
    label: 'highlight',
    value: 'activeWordHighlight',
  },
  {
    label: 'underline',
    value: 'activeWordUnderline',
  },
];

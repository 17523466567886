import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { TextOverlay as ModalTextOverlay } from 'blocks/TextOverlayModal';
import {
  duplicateTextOverlayToTrack,
  removeTextOverlay,
  saveTextOverlay,
} from 'redux/modules/embed/actions';
import {
  aspectRatioSelector,
  canvasColorSelector,
  selectedTrackElementSelector,
  slidesSelector,
  textOverlayByIdSelector,
  tracksByIdSelector,
} from 'redux/modules/embed/selectors';
import { Dispatch, ThunkAction } from 'redux/types';
import { findSlideContainingMillis } from 'utils/embed/slideshow';
import { createChainedFunction } from 'utils/functions';
import LegacyEditTextModal, { TextOverlay } from './LegacyEditTextModal';
import { sanitizeLegacyTextOverlay } from './utils';

const selectedOverlayIdSelector = createSelector(
  [selectedTrackElementSelector, tracksByIdSelector],
  (element, tracksById) => {
    if (!element || !tracksById) return undefined;

    const track = tracksById.get(element.get('trackId'));
    if (track.get('type') !== 'text') return undefined;

    return element.get('dataId');
  },
);

const selectedOverlaySelector = createSelector(
  [selectedOverlayIdSelector, textOverlayByIdSelector],
  (textOverlayId, textOverlaysById) => {
    if (!textOverlayId || !textOverlaysById) return undefined;
    return textOverlaysById.get(textOverlayId);
  },
);

const textOverlayStartMillisSelector = createSelector(
  [selectedOverlaySelector],
  textOverlay => textOverlay && textOverlay.getIn(['time', 'startMillis']),
);

const backgroundImageSelector = createSelector(
  [textOverlayStartMillisSelector, slidesSelector],
  (startMillis, slidesById) => {
    const slide = findSlideContainingMillis(startMillis, slidesById);
    return slide && slide.get('imageUrl');
  },
);

const handleDuplicate = (
  textOverlay: TextOverlay,
): ThunkAction<TextOverlay> => (dispatch, getState) => {
  const selectedTrackElement = selectedTrackElementSelector(getState());
  const trackId = selectedTrackElement.get('trackId');

  return dispatch(duplicateTextOverlayToTrack(textOverlay, trackId));
};

export type ConnectedLegacyEditTextModalProps = {
  onEditAssetHide: () => void;
  onExited: () => void;
  onSwitchLegacyMode: () => void;
  show: boolean;
  transitionOverlay: ModalTextOverlay;
};

const ConnectedLegacyEditTextModal: React.FC<ConnectedLegacyEditTextModalProps> = props => {
  const {
    onEditAssetHide,
    onExited,
    onSwitchLegacyMode,
    show,
    transitionOverlay,
  } = props;

  const dispatch = useDispatch<Dispatch>();

  const backgroundColor = useSelector(canvasColorSelector);
  const backgroundImage = useSelector(backgroundImageSelector);
  const currTextOverlay = useSelector(selectedOverlaySelector);
  const aspectRatio = useSelector(aspectRatioSelector);
  const textOverlay = transitionOverlay ?? currTextOverlay;

  return (
    <LegacyEditTextModal
      {...{
        backgroundColor,
        backgroundImage,
        onExited,
        show,
        textOverlay,
        aspectRatio,
      }}
      onDelete={createChainedFunction(
        id => dispatch(removeTextOverlay(id)),
        onEditAssetHide as any,
      )}
      onDuplicate={overlay => dispatch(handleDuplicate(overlay))}
      onHide={onEditAssetHide}
      onSave={createChainedFunction(
        overlay =>
          dispatch(saveTextOverlay(sanitizeLegacyTextOverlay(overlay))),
        onEditAssetHide as any,
      )}
      onSwitchLegacyMode={onSwitchLegacyMode}
    />
  );
};

export default ConnectedLegacyEditTextModal;

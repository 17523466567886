import { ProjectCreationMethod, TemplateType } from 'types';
import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'VIDEO_PROJECT_MANAGEMENT';
export const ACTION_KEY: ActionKey = 'VIDEO_PROJECT_MANAGEMENT';

export enum ServiceMethod {
  CREATE_PROJECT = 'CREATE_PROJECT',
  GET_ALL_PROJECTS = 'GET_ALL_PROJECTS',
  GET_PROJECT_BY_ID = 'GET_PROJECT_BY_ID',
  GET_PROJECTS_BY_URL = 'GET_PROJECTS_BY_URL',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  DELETE_PROJECT = 'DELETE_PROJECT',
  GET_REVISION_HISTORY_BY_PROJECT_ID = 'GET_REVISION_HISTORY_BY_PROJECT_ID',
}

export interface ProjectListItemAspectRatio {
  height: number;
  width: number;
}

interface LatestSuccessfulVideo {
  widgetUuid: string;
  embedVideoId: number;
}

interface FromTemplateReference {
  templateId: string;
  automatedTemplateId: string;
}

interface AutomationSubscriptionSource {
  subId: number;
  subItemId: number;
  episodeId: number;
}

interface PodcastSource {
  podcastFeedId: number;
  remotePodcastEpisodeId: number;
  podcastEpisodeId: number;
}

interface PlainProjectListItemConfig {
  ownerUserId: number;
  embedConfigurationUuid: string;
  projectName: string;
  thumbnailUrl?: string;
  aspectRatio: ProjectListItemAspectRatio;
  traceId?: string;
  customTraceId?: string;
  eddyProjectCuid: string | null;
  eddyProjectUrl: string | null;
  isEditable: boolean;
  updatedBy: number;
  updatedAt: number;
}

interface PlainProjectListItemDetail {
  projectId: number;
  projectUuid: string;
  creatorUserId: number;
  createMethod: ProjectCreationMethod;
  createdAt: number;
  thumbnailUrl?: string;
  projectConfig: PlainProjectListItemConfig;
  latestSuccessfulVideo: LatestSuccessfulVideo;
  fromTemplate: FromTemplateReference;
  automationSubscriptionSource: AutomationSubscriptionSource;
  podcastSource: PodcastSource;
  link: string;
}

interface AutoSuggestionGroupListItemDetail {
  name: string;
  createdAt: number;
  thumbnailUrl: string;
  aspectRatio: ProjectListItemAspectRatio;
  clipCount: number;
  episodeId: number;
  subscriptionItemId: number;
  groupRequestId: number;
}

interface CreationSuggestionGroupListItemDetail {
  name: string;
  createdAt: number;
  thumbnailUrl: string;
  aspectRatio: ProjectListItemAspectRatio;
  clipCount: number;
  creationRequestId: number;
}

export type ProjectType =
  | 'plainProject'
  | 'creationSuggestionGroup'
  | 'automationSuggestionGroup';

interface BaseProjectListItem<T extends ProjectType> {
  projectType?: T;
  reprAt: number;
}

export interface PlainProjectListItem
  extends BaseProjectListItem<'plainProject'> {
  detail: PlainProjectListItemDetail;
}

export interface AutoSuggestionGroupListItem
  extends BaseProjectListItem<'automationSuggestionGroup'> {
  detail: AutoSuggestionGroupListItemDetail;
}

export interface CreationSuggestionGroupListItem
  extends BaseProjectListItem<'creationSuggestionGroup'> {
  detail: CreationSuggestionGroupListItemDetail;
}

export type ProjectListItem =
  | PlainProjectListItem
  | AutoSuggestionGroupListItem
  | CreationSuggestionGroupListItem;

interface IProjectResult {
  entities: {
    projects: {
      [key: string]: ProjectListItem;
    };
  };
}

interface IPage {
  next?: number;
  number: number;
  prev?: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

interface IRevisionHistory {
  embedConfigurationUuid: string;
  projectName: string;
  updatedBy: number;
  updatedAt: number;
}

interface IRevisionHistoryResult {
  entities: {
    revisionHistory: {
      [key: string]: IRevisionHistory;
    };
  };
}

export interface IAspectRatio {
  height: number;
  width: number;
}

export type CreateProjectArgs = [
  string, // id
  string, // name
  IAspectRatio,
  ProjectCreationMethod,
  string | undefined, // thumbnail url
  string | undefined, // referrer project id
  string | undefined, // trace id
  string | undefined, // custom trace id
  string | undefined, // createdFromTemplateId
  TemplateType, // createdFromTemplateType
];
export interface CreateProjectResult extends IProjectResult {
  result: string;
}

export type GetProjectByIdArgs = [string];
export interface GetProjectByIdResult extends IProjectResult {
  result: string;
}

export type GetAllProjectsArgs = [
  number, // ownerId
  ProjectCreationMethod, // createMethod
  number, // pageSize
  string | undefined, // nextToken
];
export interface GetAllProjectsResult extends IProjectResult {
  result?: string[];
  nextToken?: string;
  totalResults?: number;
}

export type GetProjectsByUrlArgs = [string /* url */];
export type GetProjectsByUrlResult = IProjectResult & {
  result?: string[];
  page: IPage;
};

export type UpdateProjectArgs = [
  string,
  string,
  string,
  any,
  string | undefined,
];
export interface UpdateProjectResult extends IProjectResult {
  result: string;
}

export type DeleteProjectArgs = [string];
export type DeleteProjectResult = null;

export type GetRevisionHistoryByProjectIdArgs = [string, number, number];
export interface GetRevisionHistoryByProjectIdResult
  extends IRevisionHistoryResult {
  result: string[];
  page: IPage;
}

export type ServiceResults =
  | CreateProjectResult
  | GetProjectByIdResult
  | GetAllProjectsResult
  | UpdateProjectResult
  | DeleteProjectResult
  | GetRevisionHistoryByProjectIdResult;

export type IHandle = (
  method: ServiceMethod,
  args: any,
  token?: string,
) => Promise<ServiceResults>;

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export type CreateProjectAction = ApiAction<
  ServiceMethod.CREATE_PROJECT,
  CreateProjectArgs
>;
export type GetProjectByIdAction = ApiAction<
  ServiceMethod.GET_PROJECT_BY_ID,
  GetProjectByIdArgs
>;
export type GetAllProjectsAction = ApiAction<
  ServiceMethod.GET_ALL_PROJECTS,
  GetAllProjectsArgs
>;
export type GetProjectsByUrlAction = ApiAction<
  ServiceMethod.GET_PROJECTS_BY_URL,
  GetProjectsByUrlArgs
>;
export type UpdateProjectAction = ApiAction<
  ServiceMethod.UPDATE_PROJECT,
  UpdateProjectArgs
>;
export type DeleteProjectAction = ApiAction<
  ServiceMethod.DELETE_PROJECT,
  DeleteProjectArgs
>;
export type GetRevisionHistoryByProjectIdAction = ApiAction<
  ServiceMethod.GET_REVISION_HISTORY_BY_PROJECT_ID,
  GetRevisionHistoryByProjectIdArgs
>;

export interface VideoProjectManagementServiceDispatch {
  (action: CreateProjectAction): Promise<IApiResponse<CreateProjectResult>>;
  (action: GetProjectByIdAction): Promise<IApiResponse<GetProjectByIdResult>>;
  (action: GetAllProjectsAction): Promise<IApiResponse<GetAllProjectsResult>>;
  (action: GetProjectsByUrlAction): Promise<
    IApiResponse<GetProjectsByUrlResult>
  >;
  (action: UpdateProjectAction): Promise<IApiResponse<UpdateProjectResult>>;
  (action: DeleteProjectAction): Promise<IApiResponse<DeleteProjectResult>>;
  (action: GetRevisionHistoryByProjectIdAction): Promise<
    IApiResponse<GetRevisionHistoryByProjectIdResult>
  >;
}

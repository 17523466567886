import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import { SPAREMIN_SERVICES_HEADLINERUSER_URL } from 'config';
import { createRequest } from '../utils';
import * as types from './types';

const BASE_PATH = '/api/v1/headliner-user';
const LANGUAGE_REQUEST_PATH = `${BASE_PATH}/language`;

const personalFontSchema = new schema.Entity(
  'personalFonts',
  {},
  {
    idAttribute: 'familyName',
  },
);

const googleFontSchema = new schema.Entity(
  'googleFonts',
  {},
  {
    idAttribute: 'previewCssUrl',
  },
);

const sampleAudiosSchema = new schema.Entity(
  'sampleAudios',
  {},
  {
    idAttribute: 'id',
  },
);

const announcementsSchema = new schema.Entity(
  'announcements',
  {},
  {
    idAttribute: 'id',
  },
);

const projectTemplatesSchema = new schema.Entity(
  'projectTemplates',
  {},
  {
    idAttribute: 'id',
  },
);

const displayPrefsSchema = new schema.Entity(
  'displayPrefs',
  {},
  { idAttribute: 'userId' },
);

const waveformPrefsSchema = new schema.Entity('waveformPrefs', {});

const userPrefSchema = new schema.Entity(
  'userPrefs',
  {
    waveformPrefs: [waveformPrefsSchema],
  },
  { idAttribute: 'userId' },
);

const experimentsSchema = new schema.Entity(
  'experiments',
  {},
  {
    idAttribute: 'experimentName',
  },
);

const textPresetsSchema = new schema.Entity(
  'textPresets',
  {},
  { idAttribute: 'textPresetId' },
);

// NB: most of the data here is serialized using the user's id as a key in the object,
// however that is generally unnecessary as all of the user and display preferences
// are associated with the logged-in user.  we don't store any data for unauthenticated
// users.
//
// videoSizes in particular are not associated with the user, so the user id key
// is not used for this normalization.
const videoSizesSchema = new schema.Entity('videoSizes');

const eddySupportedProjectLanguagesSchema = new schema.Entity(
  'eddySupportedProjectLanguages',
  {},
  {
    idAttribute: eddySupportedLanguage => eddySupportedLanguage.languageCode,
  },
);

const automationSupportedLanguagesSchema = new schema.Entity(
  'automationSupportedLanguages',
  {},
  {
    idAttribute: automationSupportedLanguage =>
      automationSupportedLanguage.languageCode,
  },
);

const captionSupportedLanguagesSchema = new schema.Entity(
  'captionSupportedLanguages',
  {},
  {
    idAttribute: captionSupportedLanguage =>
      captionSupportedLanguage.languageCode,
  },
);

const notificationAlertSchema = new schema.Entity(
  'notificationAlert',
  {},
  { idAttribute: () => 'alerts' },
);

function getMyPersonalFonts(
  request: SuperAgentStatic,
): Promise<types.GetPersonalFontsResult> {
  return request
    .get('/api/v1/headliner-user/personal-fonts/me')
    .then(({ body }) => {
      const { personalFonts } = body;
      return normalize(personalFonts, [personalFontSchema]);
    });
}

function getGoogleFonts(
  request: SuperAgentStatic,
): Promise<types.GetGoogleFontsResult> {
  return request.get('/api/v1/headliner-user/google-fonts').then(({ body }) => {
    const { items } = body;
    return normalize(items, [googleFontSchema]);
  });
}

async function postPersonalFonts(
  request: SuperAgentStatic,
  args: types.PostPersonalFontsArgs,
): Promise<types.GetPersonalFontsResult> {
  const { fontFiles, googleFamily } = args;
  const formData = new FormData();

  if (fontFiles) {
    for (const file of fontFiles) {
      formData.append('fontFile', file);
    }
  }

  if (googleFamily) {
    formData.append('googleFamily', googleFamily);
  }

  const res = await request
    .post('/api/v1/headliner-user/personal-fonts')
    .send(formData);
  return res.body;
}

async function sendPodcastSupportRequest(
  request: SuperAgentStatic,
  args: types.SendPodcastSupportArgs,
): Promise<types.GetPersonalFontsResult> {
  const { requestType, searchTerm } = args;

  const payload = {
    requestType,
    requestAttribute: {
      searchTerm,
    },
  };

  const res = await request
    .post('/api/v1/headliner-user/support/support-request')
    .send(payload);

  return res.body;
}

async function updateFontName(
  request: SuperAgentStatic,
  args: types.UpdateFontNameArgs,
): Promise<types.GetPersonalFontsResult> {
  const { displayName, fontId } = args;
  const res = await request
    .put(`/api/v1/headliner-user/personal-fonts/${fontId}`)
    .send({ displayName });
  return res.body;
}

function deleteFont(
  request: SuperAgentStatic,
  arg: types.DeleteFontArgs,
): Promise<types.GetPersonalFontsResult> {
  return request
    .delete(`/api/v1/headliner-user/personal-fonts/${arg.fontId}`)
    .then(res => res.body);
}

function getMyDisplayPref(
  request: SuperAgentStatic,
): Promise<types.GetDisplayPrefResult> {
  return request
    .get('/api/v2/headliner-user/display-pref/me')
    .then(res => res.body);
}

function updateMyCaptionsPrefs(
  request: SuperAgentStatic,
  args: types.PutCaptionsPrefArgs,
): Promise<types.PutCaptionsPrefResult> {
  const [captionEnabled = false, captionLanguage = 'en-US'] = args;
  return request
    .put('/api/v1/headliner-user/user-pref/captions/me')
    .send({ captionEnabled, captionLanguage })
    .then(res => res.body);
}

function getUserDisplayPref(
  request: SuperAgentStatic,
  args?: types.GetUserDisplayPrefArgs,
): Promise<types.GetUserDisplayPrefResult> {
  const [userId] = args;
  return request
    .get(`/api/v2/headliner-user/display-pref/${userId}`)
    .then(({ body }) => {
      return normalize({ ...body, userId }, displayPrefsSchema);
    });
}

function getSampleAudios(
  request: SuperAgentStatic,
): Promise<types.GetSampleAudiosResult> {
  return request
    .get('/api/v1/headliner-user/media-library/sample-audio')
    .then(({ body }) => {
      const { sampleAudios } = body;
      return normalize(sampleAudios, [sampleAudiosSchema]);
    });
}

function getViewConfirmation(
  request: SuperAgentStatic,
  userId?: string,
  args?: types.GetViewConfirmationArgs,
): Promise<types.GetViewConfirmationResult> {
  const [announcementId] = args;
  return request
    .get('/api/v1/headliner-user/announcement/view-confirmation')
    .query({ userId, announcementId })
    .then(res => res.body);
}

function getAnnouncements(
  request: SuperAgentStatic,
): Promise<types.GetAnnouncementsResult> {
  return request
    .get('/api/v1/headliner-user/announcement/me')
    .then(({ body }) => {
      const { announcements } = body;
      return normalize(announcements, [announcementsSchema]);
    });
}

function getOnboarding(
  request: SuperAgentStatic,
  args?: types.GetOnboardingArgs,
): Promise<types.GetOnboardingResult> {
  const [name] = args;
  return request
    .get('/api/v1/headliner-user/onboarding/me')
    .query({ name })
    .then(res => res.body);
}

function getOnboardingViewConfirmation(
  request: SuperAgentStatic,
  userId?: string,
  args?: types.GetOnboardingViewConfirmationArgs,
): Promise<types.GetOnboardingViewConfirmationResult> {
  const [name] = args;
  return request
    .get('/api/v1/headliner-user/onboarding/view-confirmation')
    .query({ userId, name })
    .then(res => res.body);
}

function getProjectTemplates(
  request: SuperAgentStatic,
): Promise<types.GetProjectTemplatesResult> {
  return request
    .get('/api/v1/headliner-user/project-templates')
    .then(({ body }) => {
      const { projectTemplates } = body;
      return normalize(projectTemplates, [projectTemplatesSchema]);
    });
}

function createProjectTemplate(
  request: SuperAgentStatic,
  args?: types.CreateProjectTemplateArgs,
): Promise<types.CreateProjectTemplateResult> {
  const [configurationId, displayName, useMockAudioForPreview] = args;

  return request
    .post('/api/v1/headliner-user/project-templates')
    .send({ configurationId, displayName, useMockAudioForPreview })
    .then(res => res.body);
}

function overwriteProjectTemplate(
  request: SuperAgentStatic,
  args?: types.CreateProjectTemplateArgs,
): Promise<types.CreateProjectTemplateResult> {
  const [configurationId, templateId, useMockAudioForPreview] = args;

  return request
    .put(`/api/v1/headliner-user/project-templates/${templateId}`)
    .send({ configurationId, useMockAudioForPreview })
    .then(res => res.body);
}

function deleteProjectTemplate(
  request: SuperAgentStatic,
  args?: types.DeleteProjectTemplateArgs,
): Promise<types.DeleteProjectTemplateResult> {
  const [templateId] = args;

  return request
    .delete(`/api/v1/headliner-user/project-templates/${templateId}`)
    .then(res => res.body);
}

function getProjectTemplateCompatibilityFromConfigurationId(
  request: SuperAgentStatic,
  args?: types.GetProjectTemplateCompatibilityFromConfigurationIdArgs,
): Promise<types.GetProjectTemplatecompatibilityFromConfigurationIdResult> {
  const [configurationId] = args;

  return request
    .get(
      `/api/v1/headliner-user/project-templates/widget-config/${configurationId}/compatibility`,
    )
    .send({ configurationId })
    .then(res => res.body);
}

function getUserTemplateCompatibility(
  request: SuperAgentStatic,
  args?: types.GetUserTemplateCompatibilityArgs,
): Promise<types.GetUserTemplatecompatibilityResult> {
  const [templateId] = args;

  return request
    .get(`/api/v1/headliner-user/project-templates/${templateId}/compatibility`)
    .send({ templateId })
    .then(res => res.body);
}

async function createQuestionnaireResponse(
  request: SuperAgentStatic,
  questionResponses: types.CreateQuestionnaireResponseArgs,
): Promise<types.CreateQuestionnaireResponseResult> {
  const response = await request
    .post('/api/v1/headliner-user/questionaire/response')
    .send({ questionResponses });

  return response.body;
}

async function getQuestionnaireResponse(
  request: SuperAgentStatic,
): Promise<types.GetQuestionnaireResponseResult> {
  const response = await request.get(
    `/api/v1/headliner-user/questionaire/response/me`,
  );
  return response.body;
}

async function getQuestionnaireStatus(
  request: SuperAgentStatic,
): Promise<types.GetQuestionnaireStatusResponse> {
  const response = await request.get(
    '/api/v1/headliner-user/questionaire/status/me',
  );
  return response.body;
}

async function getMyUserPref(
  request: SuperAgentStatic,
  userId?: string,
): Promise<types.GetMyUserPrefResult> {
  return request.get('/api/v1/headliner-user/user-pref/me').then(({ body }) => {
    return normalize({ ...body, userId }, userPrefSchema);
  });
}

async function getPrefVideoSizes(
  request: SuperAgentStatic,
): Promise<types.GetPrefVideoSizesResult> {
  return request
    .get('/api/v1/headliner-user/user-pref/video-sizes')
    .then(({ body }) => {
      const { data } = body;
      return normalize(data, [videoSizesSchema]);
    });
}

async function getEddySupportedProjectLanguages(
  request: SuperAgentStatic,
): Promise<types.GetEddySupportedProjectLanguagesResult> {
  return request
    .get(`${LANGUAGE_REQUEST_PATH}/eddy-project`)
    .then(({ body }) => {
      const { data } = body;
      return normalize(data, [eddySupportedProjectLanguagesSchema]);
    });
}

async function getAutomationSupportedLanguages(
  request: SuperAgentStatic,
): Promise<types.GetAutomationSupportedLanguagesResult> {
  return request
    .get(`${LANGUAGE_REQUEST_PATH}/make-automation`)
    .then(({ body }) => {
      const { data } = body;
      return normalize(
        data.map((language, originalPosition) => ({
          ...language,
          originalPosition,
        })),
        [automationSupportedLanguagesSchema],
      );
    });
}

async function getCaptionSupportedLanguages(
  request: SuperAgentStatic,
): Promise<types.GetCaptionSupportedLanguagesResult> {
  return request
    .get(`${LANGUAGE_REQUEST_PATH}/make-caption`)
    .then(({ body }) => {
      const { data } = body;
      return normalize(
        data.map((language, originalPosition) => ({
          ...language,
          originalPosition,
        })),
        [captionSupportedLanguagesSchema],
      );
    });
}

async function createWaveformPref(
  request: SuperAgentStatic,
  args: types.CreateWaveformPrefArgs,
): Promise<types.CreateWaveformPrefResult> {
  const [dimensionWidth, dimensionHeight, waveformConfig] = args;
  return request
    .post('/api/v1/headliner-user/user-pref/waveform')
    .send({
      dimensionWidth,
      dimensionHeight,
      waveformConfig,
    })
    .then(({ body }) => body);
}

async function deleteWaveformPref(
  request: SuperAgentStatic,
  args: types.DeleteWaveformPrefArgs,
): Promise<types.DeleteWaveformPrefResult> {
  const [waveformPrefId] = args;
  return request
    .delete(`/api/v1/headliner-user/user-pref/waveform/${waveformPrefId}`)
    .then(() => ({
      waveformPrefId,
    }));
}

async function updateVideoExportPref(
  request: SuperAgentStatic,
  args: types.VideoExportPrefsArgs,
): Promise<types.UpdateVideoExportPrefResult> {
  return request
    .put(`/api/v1/headliner-user/user-pref/video-export/me`)
    .send(args)
    .then(res => res.body);
}

async function getExperimentVariant(
  request: SuperAgentStatic,
  args: types.GetExperimentVariantArgs,
): Promise<types.GetExperimentVariantResult> {
  const [experimentNames] = args;
  return request
    .get(`/api/v1/headliner-user/experiments/variants/me`)
    .query({
      experimentName: experimentNames,
    })
    .then(({ body }) => {
      return normalize(body, [experimentsSchema]);
    });
}

async function updateColorPref(
  request: SuperAgentStatic,
  args: types.UpdateColorPrefArgs,
): Promise<types.UpdateColorPrefResult> {
  const [colorPrefs] = args;
  return request
    .put(`/api/v1/headliner-user/user-pref/colors/me`)
    .send({ colorPrefs })
    .then(({ body }) => body);
}

async function getTextPresets(
  request: SuperAgentStatic,
): Promise<types.GetTextPresetsResult> {
  const result = await request.get('/api/v1/headliner-user/text-preset/me');
  const { textPresets } = result.body;

  return normalize(textPresets, [textPresetsSchema]);
}

async function getNotificationAlert(
  request: SuperAgentStatic,
): Promise<types.GetNotificationAlertResult> {
  const result = await request.get(
    '/api/v1/headliner-user/notification-alert/me',
  );
  const { hasNewSocialShareData } = result.body;

  return normalize({ hasNewSocialShareData }, notificationAlertSchema);
}

async function updateNotificationAlert(
  request: SuperAgentStatic,
  args: types.UpdateNotificationAlertArgs,
): Promise<types.UpdateNotificationAlertResult> {
  const { hasNewSocialShareData = false } = args;
  return request
    .put('/api/v1/headliner-user/notification-alert/me')
    .send({ hasNewSocialShareData })
    .then(res => res.body);
}

export const handle: types.IHandle = (
  method: any,
  args: any,
  token?: string,
  userId?: string,
): any => {
  const request = createRequest({
    token,
    baseUrl: SPAREMIN_SERVICES_HEADLINERUSER_URL,
  });
  switch (method) {
    case types.ServiceMethod.GET_MY_FONTS:
      return getMyPersonalFonts(request);

    case types.ServiceMethod.GET_GOOGLE_FONTS:
      return getGoogleFonts(request);

    case types.ServiceMethod.POST_PERSONAL_FONTS:
      return postPersonalFonts(request, args);

    case types.ServiceMethod.PODCAST_SUPPORT:
      return sendPodcastSupportRequest(request, args);

    case types.ServiceMethod.UPDATE_FONT_NAME:
      return updateFontName(request, args);

    case types.ServiceMethod.DELETE_FONT:
      return deleteFont(request, args);

    case types.ServiceMethod.GET_MY_DISPLAY_PREF:
      return getMyDisplayPref(request);

    case types.ServiceMethod.PUT_MY_CAPTIONS_PREF:
      return updateMyCaptionsPrefs(request, args);

    case types.ServiceMethod.GET_USER_DISPLAY_PREF:
      return getUserDisplayPref(request, args);

    case types.ServiceMethod.GET_SAMPLE_AUDIOS:
      return getSampleAudios(request);

    case types.ServiceMethod.GET_VIEW_CONFIRMATION:
      return getViewConfirmation(request, userId, args);

    case types.ServiceMethod.GET_ANNOUNCMENTS:
      return getAnnouncements(request);

    case types.ServiceMethod.GET_ONBOARDING:
      return getOnboarding(request, args);

    case types.ServiceMethod.GET_ONBOARDING_VIEW_CONFIRMATION:
      return getOnboardingViewConfirmation(request, userId, args);

    case types.ServiceMethod.GET_PROJECT_TEMPLATES:
      return getProjectTemplates(request);

    case types.ServiceMethod.CREATE_PROJECT_TEMPLATE:
      return createProjectTemplate(request, args);

    case types.ServiceMethod.OVERWRITE_PROJECT_TEMPLATE:
      return overwriteProjectTemplate(request, args);

    case types.ServiceMethod.DELETE_PROJECT_TEMPLATE:
      return deleteProjectTemplate(request, args);

    case types.ServiceMethod
      .GET_PROJECT_TEMPLATE_COMPATIBILITY_FROM_CONFIGURATION_ID:
      return getProjectTemplateCompatibilityFromConfigurationId(request, args);

    case types.ServiceMethod.GET_USER_TEMPLATE_COMPATIBILITY:
      return getUserTemplateCompatibility(request, args);

    case types.ServiceMethod.CREATE_QUESTIONNAIRE_RESPONSE:
      return createQuestionnaireResponse(request, args);

    case types.ServiceMethod.GET_QUESTIONNAIRE_RESPONSE:
      return getQuestionnaireResponse(request);

    case types.ServiceMethod.GET_QUESTIONNAIRE_STATUS:
      return getQuestionnaireStatus(request);

    case types.ServiceMethod.GET_MY_USER_PREF:
      return getMyUserPref(request, userId);

    case types.ServiceMethod.CREATE_WAVEFORM_PREF:
      return createWaveformPref(request, args);

    case types.ServiceMethod.DELETE_WAVEFORM_PREF:
      return deleteWaveformPref(request, args);

    case types.ServiceMethod.GET_EXPERIMENT_VARIANT:
      return getExperimentVariant(request, args);

    case types.ServiceMethod.UPDATE_COLOR_PREF:
      return updateColorPref(request, args);

    case types.ServiceMethod.UPDATE_VIDEO_EXPORT_PREF:
      return updateVideoExportPref(request, args);

    case types.ServiceMethod.GET_PREF_VIDEOS_SIZES:
      return getPrefVideoSizes(request);

    case types.ServiceMethod.GET_EDDY_SUPPORTED_PROJECT_LANGUAGES:
      return getEddySupportedProjectLanguages(request);

    case types.ServiceMethod.GET_TEXT_PRESETS:
      return getTextPresets(request);

    case types.ServiceMethod.GET_NOTIFICATION_ALERT:
      return getNotificationAlert(request);

    case types.ServiceMethod.UPDATE_NOTIFICATION_ALERT:
      return updateNotificationAlert(request, args);

    case types.ServiceMethod.GET_AUTOMATION_SUPPORTED_LANGUAGES:
      return getAutomationSupportedLanguages(request);

    case types.ServiceMethod.GET_CAPTION_SUPPORTED_LANGUAGES:
      return getCaptionSupportedLanguages(request);
  }

  return undefined;
};

import * as Sentry from '@sentry/browser';
import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import _, { omit } from 'underscore';

import SteppedModal from 'components/SteppedModal';
import VideoFormWithPreview from 'components/VideoFormWithPreview';
import useEditAssetModal from 'containers/ConnectedModal/useEditAssetModal';
import {
  addingToMediaTrack,
  addingToTrack,
  replaceSelectedTrackElement,
} from 'redux/modules/embed/actions';
import {
  removeFromVideoTrack,
  saveVideo,
} from 'redux/modules/embed/actions/video';
import {
  selectedTrackElementSelector,
  videosByIdSelector,
} from 'redux/modules/embed/selectors';
import { StaticCrop } from 'redux/modules/embed/types';
import { onReplaceAsset } from 'redux/modules/mixpanel';
import { pushModal } from 'redux/modules/modal/actions';
import { showError } from 'redux/modules/notification/actions';
import { Dispatch } from 'redux/types';
import { Size } from 'types';
import { hasAllDimensions } from 'utils/embed/video';
import { calculateFinalVideoDimensions } from 'utils/video-crop';
import { VideoToEdit } from './types';

export interface UseEditVideoModalResult {
  modalRef: MutableRefObject<SteppedModal>;
  show: boolean;
  video: VideoToEdit;
  formRef: MutableRefObject<VideoFormWithPreview>;
  metadataLoaded: boolean;
  onLoadedMetadata: () => void;
  onGoToEditPropertiesStep: () => void;
  onGoToAdjustCropStep: () => void;
  onSave: (
    staticCrop?: StaticCrop,
    videoFileSize?: Size<number>,
    dimensions?: Size<number>,
  ) => void;
  onDelete: () => void;
  onReplace: () => void;
  onHide: () => void;
  onExited: () => void;
}

const videoSelector = createSelector(
  [selectedTrackElementSelector, videosByIdSelector],
  (element, videosById) => {
    if (!element || !videosById) return undefined;

    const video = videosById.get(element.get('dataId'));

    if (!video) return undefined;

    return video
      .withMutations(v =>
        v
          .update('audioLevel', level =>
            _.isUndefined(level) ? undefined : Math.trunc(level * 100),
          )
          .update('mainAudioLevel', level =>
            _.isUndefined(level) ? undefined : Math.trunc(level * 100),
          ),
      )
      .update('scaling', scaling => scaling && scaling.toUpperCase())
      ?.toJS();
  },
);

export default function useEditVideoModal(): UseEditVideoModalResult {
  const [metadataLoaded, setMetadataLoaded] = useState(false);

  const modalRef = useRef<SteppedModal>();
  const formRef = useRef<VideoFormWithPreview>();

  const trackId = useSelector(selectedTrackElementSelector)?.get('trackId');
  const video = useSelector(videoSelector);

  const { show, onHide, onExited } = useEditAssetModal('EditVideoModal');
  const dispatch = useDispatch<Dispatch>();

  const handleLoadedMetadata = useCallback((): void => {
    setMetadataLoaded(true);
  }, []);

  const handleSave = useCallback(
    async (
      staticCrop?: StaticCrop,
      videoFileSize?: Size<number>,
      dimensions?: Size<number>,
    ): Promise<void> => {
      const {
        clipStartMillis,
        clipEndMillis,
        mediaToReplaceId,
        transcriptionEnabled,
        transcriptionLanguage,
        ...rest
      } = formRef.current.values;

      dispatch(async (__, getState) => {
        const v = videoSelector(getState());

        if (!hasAllDimensions(rest)) {
          const message = 'Error updating asset. Please try again';

          dispatch(showError(message));

          Sentry.captureException(new Error(message));

          onHide();
        } else {
          dispatch(
            saveVideo(v.id, {
              ...omit(rest, ['width', 'height']),
              ...calculateFinalVideoDimensions(
                {
                  width: rest.width,
                  height: rest.height,
                },
                dimensions,
                staticCrop,
                videoFileSize,
              ),
              cropInfo: { staticCrop },
            }),
          );
        }
      });
      onHide();
    },
    [dispatch, onHide],
  );

  const handleDelete = useCallback((): void => {
    dispatch((__, getState) => {
      const v = videoSelector(getState());

      dispatch(removeFromVideoTrack(v.id));
      onHide();
    });
  }, [dispatch, onHide]);

  const handleReplace = useCallback((): void => {
    dispatch(onReplaceAsset('video'));
    dispatch(addingToMediaTrack());
    dispatch(replaceSelectedTrackElement());

    onHide();

    dispatch(pushModal({ name: 'AddMediaModal' }));
    dispatch(addingToTrack(trackId));
  }, [dispatch, onHide, trackId]);

  const handleGoToEditPropertiesStep = useCallback((): void => {
    modalRef.current.stepHistory.replace('edit-properties');
  }, []);

  const handleGoToAdjustCropStep = useCallback((): void => {
    modalRef.current.stepHistory.replace('adjust-crop');
  }, []);

  return {
    modalRef,
    show,
    video,
    formRef,
    metadataLoaded,
    onLoadedMetadata: handleLoadedMetadata,
    onGoToEditPropertiesStep: handleGoToEditPropertiesStep,
    onGoToAdjustCropStep: handleGoToAdjustCropStep,
    onSave: handleSave,
    onDelete: handleDelete,
    onReplace: handleReplace,
    onHide,
    onExited,
  };
}

import { getValue } from 'utils/collections';

const createMethods = {
  audio: 'audio',
  audiogram: 'audiogram',
  blankProject: 'blank',
  copy: 'copy',
  episode: 'episode',
  podcastFeedAutoCreate: 'podcast-feed-auto-create',
  podcastFeedAutoCreateEpisode: 'podcast-feed-auto-create-episode',
  recommended: 'automated',
  sample: 'sample',
  template: 'template',
  textToVideo: 'textToVideo',
  unknown: 'unknown',
  videoTranscript: 'videoTranscript',
  videoTranscriptClipSuggestion: 'videoTranscriptClipSuggestion',
};

export const mapCreateMethodToMixpanelEventProjectType = (
  name: string = 'unknown',
) => getValue(createMethods, name);

export function getTemplateId(project) {
  if (!project) return undefined;

  const templateId = project.getIn(['fromTemplate', 'templateId']);
  const automationTemplateId = project.getIn([
    'fromTemplate',
    'automatedTemplateId',
  ]);

  return templateId || automationTemplateId;
}

export const getProjectTypeByProject = project =>
  project &&
  mapCreateMethodToMixpanelEventProjectType(getValue(project, 'createMethod'));

export function getSubscriptionId(project) {
  return project?.getIn(['automationSubscriptionSource', 'subId']);
}

import {
  createPaymentMethod,
  getExternalPortalLink,
  getMyCoupons,
  getMyCustomerDetails,
  getMyReferral,
  getMySubscription,
  getPlans,
  sendReferralInvitations,
  updateMySubscription,
} from 'redux/middleware/api/plan-service/actions';
import {
  CustomerSource,
  PlanName,
  SubscribablePlanName,
} from 'redux/middleware/api/plan-service/types';
import { hideModal } from 'redux/modules/modal/actions';
import { showNotification } from 'redux/modules/notification/actions';
import { ThunkAction } from 'redux/types';
import { userRegisteredSelector } from '../auth/selectors';
import { modalParamsSelector, pushModal, replaceModal } from '../modal';
import { DowngradeIntent } from '../modal/types';
import { Type } from './action-types';
import { isLoadingSelector, planNameSelector } from './selectors';
import { LoadPricingDataOptions, PaymentMethod } from './types';

const loadMySubscription = (): ThunkAction<Promise<void>> => async dispatch => {
  const { response } = await dispatch(getMySubscription());
  const {
    planUsage,
    subscribedPlanAssociation,
    autoRenewal,
    feature,
    planLimit,
    purchaseManagement,
    subscribedPlan,
    subscribedProduct,
    currentPeriodStart,
    currentPlanStart,
    currentPeriodEnd,
    invoicePastDue,
    forcedCancellationNotice,
    pauseSchedule,
  } = response;
  dispatch({
    payload: {
      mySubscription: {
        discoEnabled: feature.exportScreenRecWidget.enabled,
        exportBalance: planUsage.videoExport.remaining,
        eddyDurationLimitHours: planLimit.eddyDurationLimitHours,
        fullEpisodeCaptionEnabled: feature.fullEpisodeCaption.enabled,
        fullEpisodeDurationLimitHours: planLimit.fullEpisodeDurationLimitHours,
        organizationName: subscribedPlanAssociation.displayName,
        plan: subscribedPlan.name,
        planEndSec: currentPeriodEnd,
        planStartSec: currentPeriodStart,
        purchaseDateSec: currentPlanStart,
        purchaseManagementPlatform: purchaseManagement?.platform,
        subtier: subscribedProduct.subtier,
        tier: subscribedProduct.tier,
        transcriptionBalanceMillis: planUsage.transcriptDuration.remaining,
        eddyUploadMB: planLimit.eddyUploadMB,
        videoUploadLimitMb: planLimit.videoUploadMB,
        autoRenewal,
        invoicePastDue,
        forcedCancellationNotice,
        pauseSchedule,
      },
    },
    type: Type.PRICING_DATA_SUBSCRIPTION_LOAD_SUCCESS,
  });
};

const loadMyCoupons = (): ThunkAction<Promise<void>> => async dispatch => {
  const result = await dispatch(getMyCoupons());
  dispatch({
    payload: {
      myCoupons: result.response.coupons,
    },
    type: Type.PRICING_DATA_COUPONS_LOAD_SUCCESS,
  });
};

export const loadMyPricingData = (): ThunkAction<Promise<
  void[]
>> => dispatch => {
  const futSubscription = dispatch(loadMySubscription());
  const futCoupons = dispatch(loadMyCoupons());
  const futCard = dispatch(getMyCard());
  return Promise.all([futSubscription, futCoupons, futCard]);
};

export const loadMyReferralData = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  const referral = await dispatch(getMyReferral());
  const { referralUrl } = referral.response;

  dispatch({
    payload: {
      myReferralUrl: referralUrl,
    },
    type: Type.PRICING_REFERRAL_DATA_LOAD_SUCCESS,
  });
};

export const loadPricingData = (
  opts: LoadPricingDataOptions = {},
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const { force = false } = opts;
  const isLoading = isLoadingSelector(getState());

  if (!userRegisteredSelector(getState())) {
    return;
  }

  // if isLoading === true or false, data has been fetched.  only when
  // isLoading === undefined have we not even initiated fetching yet
  if (!force && isLoading !== undefined) return;

  dispatch({ type: Type.PRICING_DATA_LOAD_REQUEST });

  try {
    const futPlans = dispatch(getPlans());
    const futMyData = dispatch(loadMyPricingData());
    await Promise.all([futPlans, futMyData]);

    dispatch({ type: Type.PRICING_DATA_LOAD_SUCCESS });
  } catch (err) {
    dispatch({ type: Type.PRICING_DATA_LOAD_FAILURE });
  }
};

export const savePaymentMethod = ({
  paymentMethodId,
  stripeToken,
}: PaymentMethod): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.PRICING_CREATE_PAYMENT_METHOD_REQUEST });

  try {
    await dispatch(createPaymentMethod({ stripeToken, paymentMethodId }));
    dispatch({ type: Type.PRICING_CREATE_PAYMENT_METHOD_SUCCESS });
  } catch (error) {
    dispatch({ type: Type.PRICING_CREATE_PAYMENT_METHOD_FAILURE });
    throw error;
  }
};

export const updateSubscription = (
  planName: SubscribablePlanName,
  autoRenewal: boolean = true,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.PRICING_UPDATE_SUBSCRIPTION_REQUEST });

  try {
    await dispatch(
      updateMySubscription({
        subscribeTo: planName,
        autoRenewal,
      }),
    );

    dispatch({ type: Type.PRICING_UPDATE_SUBSCRIPTION_SUCCESS });
  } catch (err) {
    dispatch({ type: Type.PRICING_UPDATE_SUBSCRIPTION_FAILURE });

    throw err;
  }
};

export const cancelSubscription = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  dispatch({ type: Type.PRICING_UPDATE_SUBSCRIPTION_REQUEST });

  try {
    await dispatch(updateMySubscription({ autoRenewal: false }));
    dispatch({ type: Type.PRICING_UPDATE_SUBSCRIPTION_SUCCESS });
  } catch (err) {
    dispatch({ type: Type.PRICING_UPDATE_SUBSCRIPTION_FAILURE });
  }
};

export const pauseSubscription = (
  period: number,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.PRICING_UPDATE_SUBSCRIPTION_REQUEST });

  try {
    await dispatch(
      updateMySubscription({
        pausePlanDurationMonths: period,
      }),
    );
    dispatch({ type: Type.PRICING_UPDATE_SUBSCRIPTION_SUCCESS });
    dispatch(
      showNotification({
        message: 'You can unpause it at any time',
        code: '0',
        level: 'success',
        type: 'help',
        title: 'Your plan will pause soon',
      }),
    );
  } catch (err) {
    dispatch({ type: Type.PRICING_UPDATE_SUBSCRIPTION_FAILURE });
    throw err;
  } finally {
    dispatch(loadPricingData({ force: true }));
    dispatch(hideModal());
  }
};

export const unpauseSubscription = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  dispatch({ type: Type.PRICING_UPDATE_SUBSCRIPTION_REQUEST });

  try {
    await dispatch(
      updateMySubscription({
        cancelPause: true,
      }),
    );
    dispatch({ type: Type.PRICING_UPDATE_SUBSCRIPTION_SUCCESS });
    dispatch(
      showNotification({
        message: 'You’ve regained access to paid features',
        code: '0',
        level: 'success',
        type: 'help',
        title: 'Your plan is active again',
        actionLabel: 'contact us',
      }),
    );
  } catch (err) {
    dispatch({ type: Type.PRICING_UPDATE_SUBSCRIPTION_FAILURE });
    throw err;
  } finally {
    dispatch(loadPricingData({ force: true }));
    dispatch(hideModal());
  }
};

const getDefaultCard = (sources: CustomerSource[]) => {
  if (Array.isArray(sources)) {
    const defaultCard = sources.find(s => s.isDefault);
    if (defaultCard) {
      return {
        brand: defaultCard.brand,
        last4Digits: defaultCard.last4,
      };
    }
  }
  return null;
};

export const getMyCard = (): ThunkAction<Promise<void>> => async dispatch => {
  const result = await dispatch(getMyCustomerDetails());
  const { customerDetail, customerId } = result.response;
  const defaultCard = getDefaultCard(customerDetail?.sources);
  dispatch({
    type: Type.PRICING_CUSTOMER_CARD_GET_SUCCESS,
    payload: {
      customerId,
      myCard: defaultCard,
    },
  });
};

export const sendReferralEmailInvites = (
  emails: string[],
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.PRICING_REFERRAL_INVITATIONS_SEND_REQUEST });

  try {
    await dispatch(sendReferralInvitations(emails));
    dispatch({ type: Type.PRICING_REFERRAL_INVITATIONS_SEND_SUCCESS });
  } catch (err) {
    dispatch({ type: Type.PRICING_REFERRAL_INVITATIONS_SEND_FAILURE });
    throw err;
  }
};

export const markFreeTrialReminderSeen = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const params = modalParamsSelector(getState());
  if (params?.type) {
    dispatch({
      type: Type.MARK_FREE_TRIAL_REMINDER_SEEN,
      payload: params?.type,
    });
  }
};

export const loadExternalPortalLink = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  dispatch({ type: Type.LOAD_EXTERNAL_PORTAL_LINK_REQUEST });

  try {
    const { response } = await dispatch(getExternalPortalLink());
    dispatch({
      type: Type.LOAD_EXTERNAL_PORTAL_LINK_SUCCESS,
      payload: response.url,
    });
  } catch (err) {
    dispatch({ type: Type.LOAD_EXTERNAL_PORTAL_LINK_FAILURE });
  }
};

interface LegacyDowngradeGuardOptions {
  modalOperation?: 'push' | 'replace';
}

export const legacyDowngradeGuard = (
  intent: DowngradeIntent,
  fn: () => void,
  opts: LegacyDowngradeGuardOptions = {},
): ThunkAction<void> => (dispatch, getState) => {
  const { modalOperation = 'push' } = opts;
  const planName = planNameSelector(getState());

  if (planName === PlanName.PRO_YEARLY || planName === PlanName.PRO_MONTHLY) {
    const modalOpts = {
      name: 'LegacyDowngradeModal',
      params: { intent },
    } as const;

    if (modalOperation === 'push') {
      dispatch(pushModal(modalOpts));
    } else {
      dispatch(replaceModal(modalOpts));
    }
  } else {
    fn();
  }
};

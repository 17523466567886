import { useCallback } from 'react';
import { createMediaProxyUrl } from 'redux/middleware/api/audio-proxy-service';
import { IVideoUpload } from 'redux/middleware/api/media-upload-service';
import { isBlobUrl } from 'utils/file';

import { generateRandomNumericId } from 'utils/numbers';
import { secToMillis } from 'utils/time';
import { createVideoElement, getVideoThumbnail } from 'utils/video';

export type CreateVideoAssetResult = {
  videoAsset: IVideoUpload;
  startMillis: number;
  endMillis: number;
};

export type UseCreateVideoAssetResult = {
  createVideoAsset: (
    src: string,
    trimStartMillis: number,
    trimEndMillis: number,
  ) => Promise<CreateVideoAssetResult>;
};

const THUMBNAIL_SCALE = 0.8;

export default function useCreateVideoAsset(): UseCreateVideoAssetResult {
  const createVideoAsset = useCallback(
    async (
      src: string,
      trimStartMillis: number,
      trimEndMillis: number,
    ): Promise<CreateVideoAssetResult> => {
      const video = await createVideoElement(
        isBlobUrl(src) ? src : createMediaProxyUrl(src),
        {
          crossOrigin: 'anonymous',
          preload: 'metadata',
        },
      );
      const thumbnail = await getVideoThumbnail(video, {
        scale: THUMBNAIL_SCALE,
      });

      const { videoWidth, videoHeight } = video;
      const durationMillis = secToMillis(video.duration);
      const startMillis = trimStartMillis ?? 0;
      const endMillis = trimEndMillis ?? durationMillis;

      const videoAsset = {
        id: generateRandomNumericId(),
        videoWidth,
        videoHeight,
        durationMillis,
        transcodedVideoUrl: src,
        acceptableFinalDurationSecs: endMillis - startMillis,
        isResolved: true,
        status: 'completed',
        variations: [],
        previewThumbnail: {
          thumbnails: [
            {
              id: generateRandomNumericId(),
              url: thumbnail,
              width: videoWidth * THUMBNAIL_SCALE,
              height: videoHeight * THUMBNAIL_SCALE,
              type: 'firstFrame',
              status: 'completed',
              isResolved: true,
            },
          ],
        },
      } as IVideoUpload;

      return {
        videoAsset,
        startMillis,
        endMillis,
      };
    },
    [],
  );

  return {
    createVideoAsset,
  };
}

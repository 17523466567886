import { RequestStatus } from 'types';
import { Type } from './action-types';
import { stateFactory } from './factories';
import { ClipSelectAction, ClipSelectState } from './types';

const defaultState = stateFactory();

export default function reducer(
  state: ClipSelectState = defaultState,
  action: ClipSelectAction,
) {
  switch (action.type) {
    case Type.LOAD_DATA_SUCCESS: {
      const { subscriptionItemId } = action.payload;

      return state.set('subscriptionItemId', subscriptionItemId);
    }

    case Type.GROUP_CLIP_SUGGESTION_DISLIKE: {
      const { suggestionId } = action.payload;

      return state.update('dislikedGroupSuggestionIds', ids =>
        ids.push(suggestionId),
      );
    }

    case Type.LOAD_GROUP_CLIP_SUGGESTIONS_REQUEST:
      return state.set('groupClipSuggestionsStatus', RequestStatus.REQUEST);

    case Type.LOAD_GROUP_CLIP_SUGGESTIONS_SUCCESS: {
      return state.set('groupClipSuggestionsStatus', RequestStatus.SUCCESS);
    }

    case Type.LOAD_GROUP_CLIP_SUGGESTIONS_FAILURE: {
      const { isBlocked } = action.payload;
      return state.withMutations(s =>
        s
          .set('groupClipSuggestionsStatus', RequestStatus.FAILURE)
          .set('isBlockedError', isBlocked),
      );
    }

    case Type.LOAD_CREATION_CLIP_SUGGESTIONS_REQUEST:
      return state.set('creationClipSuggestionsStatus', RequestStatus.REQUEST);

    case Type.LOAD_CREATION_CLIP_SUGGESTIONS_SUCCESS: {
      return state.set('creationClipSuggestionsStatus', RequestStatus.SUCCESS);
    }

    case Type.LOAD_CREATION_CLIP_SUGGESTIONS_FAILURE: {
      const { isBlocked } = action.payload;
      return state.withMutations(s =>
        s
          .set('creationClipSuggestionsStatus', RequestStatus.FAILURE)
          .set('isBlockedError', isBlocked),
      );
    }

    case Type.CREATION_CLIP_SUGGESTION_DISLIKE: {
      const { suggestionId } = action.payload;

      return state.update('dislikedCreationSuggestionIds', ids =>
        ids.push(suggestionId),
      );
    }

    case Type.RESET:
      return defaultState;

    default:
      return state;
  }
}

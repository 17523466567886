import * as React from 'react';

import Tooltip from 'components/Tooltip';
import useTranscriptionBalance from 'hooks/useTranscriptionBalance';
import { EditorTranscriptionOptionsProvider } from './EditorTranscriptionOptionsContext';
import ReplaceTranscriptWarning from './ReplaceTranscriptWarning';
import TranscriptionBalance from './TranscriptionBalance';
import TranscriptionLanguageSelector from './TranscriptionLanguageSelector';
import TranscriptionToggle from './TranscriptionToggle';
import { EditorTranscriptionOptionsProps } from './types';

interface EditorTranscriptionOptionsComponent
  extends React.FC<EditorTranscriptionOptionsProps> {
  Balance: typeof TranscriptionBalance;
  Toggle: typeof TranscriptionToggle;
  Language: typeof TranscriptionLanguageSelector;
  Warning: typeof ReplaceTranscriptWarning;
}

/**
 * Inputs are exposed as as properties of EditorTranscriptionOptions.  All of
 * the "wiring" is passed into EditorTranscriptionOptions and then shared with
 * children via context so that the calling code can structure the form in
 * different ways without having to re-implement the business logic for the form.
 *
 * For example, at the time of writing AudioOptionsForm uses the regular form inputs
 * but TranscriptionSection (in the media modal) wraps each input in a <Col />
 */
const EditorTranscriptionOptions: EditorTranscriptionOptionsComponent = ({
  children,
  className,
  clipDurationMillis,
  disabled,
  language,
  onLanguageSelected,
  onToggleTranscription,
  transcriptionEnabled,
}) => {
  const {
    balanceMillis,
    canTranscribe,
    hasBalance,
    newBalanceMillis,
    outOfTimeMessage,
    showBalance,
  } = useTranscriptionBalance({
    transcriptionEnabled,
    durationMillis: clipDurationMillis,
    onToggleTranscription,
  });

  return (
    <EditorTranscriptionOptionsProvider
      {...{
        balanceMillis,
        canTranscribe,
        disabled,
        hasBalance,
        language,
        newBalanceMillis,
        onLanguageSelected,
        onToggleTranscription,
        outOfTimeMessage,
        showBalance,
        transcriptionEnabled,
      }}
    >
      <Tooltip
        content={outOfTimeMessage}
        placement="top"
        show={!canTranscribe ? undefined : false}
      >
        <div className={className}>{children}</div>
      </Tooltip>
    </EditorTranscriptionOptionsProvider>
  );
};

EditorTranscriptionOptions.Toggle = TranscriptionToggle;
EditorTranscriptionOptions.Language = TranscriptionLanguageSelector;
EditorTranscriptionOptions.Warning = ReplaceTranscriptWarning;
EditorTranscriptionOptions.Balance = TranscriptionBalance;

export default EditorTranscriptionOptions;

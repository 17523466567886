import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CreationStatus } from 'redux/middleware/api/creation-service/types';
import { clipSuggestionsCreationProgressSelector } from 'redux/modules/entities';
import {
  cancelWaitForCreation,
  waitForClipSuggestionsCreation,
} from 'redux/modules/wizard-export/actions';
import { creationIdSelector } from 'redux/modules/wizard-export/selectors';
import { Dispatch } from 'redux/types';
import { getSuggestedClipsPath } from 'utils/routes';
import ExportVideoStepContainer from '../../ExportVideoStep/ExportVideoStepContainer';
import ExportVideoStepMainSection from '../../ExportVideoStep/ExportVideoStepMainSection';
import ProgressBlock from '../../ExportVideoStep/ProgressBlock';

export interface VideoWizardMultipleClipsExportStepProps {
  onError?: (error: Error) => void;
}

const VideoWizardMultipleClipsExportStep: React.FC<VideoWizardMultipleClipsExportStepProps> = props => {
  const { onError } = props;
  const creationSuggestionsProgress = useSelector(
    clipSuggestionsCreationProgressSelector,
  );
  const creationId = useSelector(creationIdSelector);

  const history = useHistory();

  const dispatch = useDispatch<Dispatch>();

  const status = creationSuggestionsProgress?.get('status');
  const progress = creationSuggestionsProgress?.get('progress');

  useEffect(() => {
    const waitForExport = async () => {
      try {
        await dispatch(
          waitForClipSuggestionsCreation(
            creationStatus => creationStatus === CreationStatus.COMPLETED,
          ),
        );
      } catch (error) {
        onError(error);
      }
    };

    if (creationId) {
      waitForExport();
    }

    return () => {
      dispatch(cancelWaitForCreation());
    };
  }, [creationId, dispatch, onError]);

  useEffect(() => {
    if (status === CreationStatus.COMPLETED) {
      history.push(getSuggestedClipsPath(creationId));
    }
  }, [creationId, history, status]);

  return (
    <ExportVideoStepContainer>
      <ExportVideoStepMainSection>
        <ProgressBlock
          exportMessage="Your video clips should be ready soon"
          subtitle="You don't need to wait here, we'll email it to you soon."
          layout="vertical"
          showEmailCallout={false}
          progress={Math.round((progress ?? 0) * 100)}
        />
      </ExportVideoStepMainSection>
    </ExportVideoStepContainer>
  );
};

export default VideoWizardMultipleClipsExportStep;

import ExtendoError from 'extendo-error';
import { RecordOf } from 'immutable';

import { PodcastSubscriptionType } from 'redux/middleware/api/podcast-service/types';
import { IEmbedExport } from 'redux/middleware/api/video-export-service/types';
import { Action } from 'redux/types';
import { DeepImmutableMap, IEmbedConfiguration, RequestStatus } from 'types';
import { Type } from './action-types';

export interface State {
  configuration: DeepImmutableMap<IEmbedConfiguration>;
  podcastInfo: DeepImmutableMap<IEmbedExport['podcastInfo']>;
  dpaPerformed: boolean;
  loadingState: RequestStatus;
  projectId: string;
  podcastEpisodeId: number;
  videoDurationMillis: number;
  videoFrequency: PodcastSubscriptionType['videoFrequency'];
  isPollingSocialPostCaptions: boolean;
  jobId: number;
  hasError: boolean;
  creationRequestId: number;
  videoId: number;
}

export type DownloadState = RecordOf<State>;

export type DownloadSocialPlatforms =
  | 'facebook'
  | 'twitter'
  | 'instagram'
  | 'linkedin'
  | 'youtube'
  | 'tiktok';

type LoadDataRequestAction = Action<Type.LOAD_DATA_REQUEST>;
type LoadDataSuccessAction = Action<
  Type.LOAD_DATA_SUCCESS,
  {
    configuration: IEmbedConfiguration;
    podcastInfo: IEmbedExport['podcastInfo'];
    projectId: string;
    videoDurationMillis: number;
    videoFrequency: PodcastSubscriptionType['videoFrequency'];
    podcastEpisodeId?: number;
  }
>;
type LoadDataFailureAction = Action<Type.LOAD_DATA_FAILURE, string>;

type ClearAction = Action<Type.DOWNLOAD_CLEAR>;

type DownloadPageAction = Action<Type.DOWNLOAD_PAGE_ACTION>;

type CreateSocialPostCaptionsRequest = Action<
  Type.SOCIAL_POST_CAPTIONS_CREATE_REQUEST,
  {
    podcastEpisodeId: number;
  }
>;

type CreateSocialPostCaptionsSuccess = Action<
  Type.SOCIAL_POST_CAPTIONS_CREATE_SUCCESS,
  {
    jobId: number;
  }
>;

type CreateSocialPostCaptionsFailure = Action<
  Type.SOCIAL_POST_CAPTIONS_CREATE_FAILURE
>;

type GetSocialPostCaptionsRequest = Action<
  Type.SOCIAL_POST_CAPTIONS_POLLING_REQUEST
>;

type GetSocialPostCaptionsSuccess = Action<
  Type.SOCIAL_POST_CAPTIONS_POLLING_SUCCESS
>;

type GetSocialPostCaptionsFailure = Action<
  Type.SOCIAL_POST_CAPTIONS_POLLING_FAILURE
>;

type GetSocialPostCaptionsEnd = Action<Type.SOCIAL_POST_CAPTIONS_POLLING_END>;

type CreateVideoSocialPostCaptionsRequest = Action<
  Type.VIDEO_SOCIAL_POST_CAPTIONS_CREATE_REQUEST,
  {
    creationRequestId: number;
  }
>;

type CreateVideoSocialPostCaptionsSuccess = Action<
  Type.VIDEO_SOCIAL_POST_CAPTIONS_CREATE_SUCCESS,
  {
    jobId: number;
  }
>;

type CreateVideoSocialPostCaptionsFailure = Action<
  Type.VIDEO_SOCIAL_POST_CAPTIONS_CREATE_FAILURE
>;

type GetVideoSocialPostCaptionsRequest = Action<
  Type.VIDEO_SOCIAL_POST_CAPTIONS_POLLING_REQUEST
>;

type GetVideoSocialPostCaptionsSuccess = Action<
  Type.VIDEO_SOCIAL_POST_CAPTIONS_POLLING_SUCCESS
>;

type GetVideoSocialPostCaptionsFailure = Action<
  Type.VIDEO_SOCIAL_POST_CAPTIONS_POLLING_FAILURE
>;

type GetVideoSocialPostCaptionsEnd = Action<
  Type.VIDEO_SOCIAL_POST_CAPTIONS_POLLING_END
>;

type LoadVideoSocialPostCaptionsSuccess = Action<
  Type.VIDEO_SOCIAL_POST_CAPTIONS_GET_SUCCESS,
  { jobId: number }
>;

type CreateEmbedVideoSocialPostCaptionsRequest = Action<
  Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_CREATE_REQUEST,
  {
    videoId: number;
  }
>;

type CreateEmbedVideoSocialPostCaptionsSuccess = Action<
  Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_CREATE_SUCCESS,
  {
    jobId: number;
  }
>;

type CreateEmbedVideoSocialPostCaptionsFailure = Action<
  Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_CREATE_FAILURE
>;

type GetEmbedVideoSocialPostCaptionsRequest = Action<
  Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_REQUEST
>;

type GetEmbedVideoSocialPostCaptionsSuccess = Action<
  Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_SUCCESS
>;

type GetEmbedVideoSocialPostCaptionsFailure = Action<
  Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_FAILURE
>;

type GetEmbedVideoSocialPostCaptionsEnd = Action<
  Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_POLLING_END
>;

type LoadEmbedVideoSocialPostCaptionsSuccess = Action<
  Type.EMBED_VIDEO_SOCIAL_POST_CAPTIONS_GET_SUCCESS,
  { jobId: number }
>;

export type DownloadAction =
  | LoadDataRequestAction
  | LoadDataSuccessAction
  | LoadDataFailureAction
  | ClearAction
  | DownloadPageAction
  | CreateSocialPostCaptionsRequest
  | CreateSocialPostCaptionsSuccess
  | CreateSocialPostCaptionsFailure
  | GetSocialPostCaptionsRequest
  | GetSocialPostCaptionsSuccess
  | GetSocialPostCaptionsFailure
  | GetSocialPostCaptionsEnd
  | CreateVideoSocialPostCaptionsRequest
  | CreateVideoSocialPostCaptionsSuccess
  | CreateVideoSocialPostCaptionsFailure
  | GetVideoSocialPostCaptionsRequest
  | GetVideoSocialPostCaptionsSuccess
  | GetVideoSocialPostCaptionsFailure
  | GetVideoSocialPostCaptionsEnd
  | LoadVideoSocialPostCaptionsSuccess
  | CreateEmbedVideoSocialPostCaptionsRequest
  | CreateEmbedVideoSocialPostCaptionsSuccess
  | CreateEmbedVideoSocialPostCaptionsFailure
  | GetEmbedVideoSocialPostCaptionsRequest
  | GetEmbedVideoSocialPostCaptionsSuccess
  | GetEmbedVideoSocialPostCaptionsFailure
  | GetEmbedVideoSocialPostCaptionsEnd
  | LoadEmbedVideoSocialPostCaptionsSuccess;

export class GetVideoExportStatusError extends ExtendoError {}

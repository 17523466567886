import * as React from 'react';

import Button from 'components/Button';
import { H1 } from 'components/Heading';
import { CaptionsCircle } from 'components/icons';
import LanguageSelector from 'components/LanguageSelector';
import Tooltip from 'components/Tooltip';
import FreeTranscriptionBalance from 'containers/FreeTranscriptionBalance';
import { ProjectTypeByAssets, TranscriptStatus } from 'utils/constants';
import { block } from '../../../utils';
import TranscriptInProgressDisplay from './TranscriptInProgressDisplay';

interface IProps {
  projectTypeByAssets: ProjectTypeByAssets;
  status: TranscriptStatus;
  onClickAddAudio: () => void;
  onClickCaptionUpload: () => void;
  onClickGenerateCaption: () => void;
  onLanguageSelected: (language: string) => void;
  language: string;
  canTranscribe: boolean;
  showBalance: boolean;
  durationInMilliseconds?: number;
}

export default class TranscriptStatusDisplay extends React.PureComponent<
  IProps
> {
  private renderTranscriptMessage = (showAddAudioButton: boolean) => {
    const { onClickCaptionUpload } = this.props;
    return (
      <span className={block('transcript-message')}>
        {showAddAudioButton ? (
          'Select a caption source to get started:'
        ) : (
          <>
            We’ll auto-transcribe your audio to create captions, or you can{' '}
            <a onClick={onClickCaptionUpload}>upload pre-existing captions</a>.
          </>
        )}
      </span>
    );
  };

  private renderTranscriptActions = (showAddAudioButton: boolean) => {
    const {
      language,
      canTranscribe,
      onClickAddAudio,
      onClickGenerateCaption,
      onLanguageSelected,
    } = this.props;

    const tooltipProps = canTranscribe ? { show: false } : {};
    return (
      <div className={block('transcript-actions')}>
        {showAddAudioButton && (
          <Button
            size="large"
            theme="submit"
            onClick={onClickAddAudio}
            className={block('button')}
            fluid
            data-test="add-audio-button"
          >
            Add audio
          </Button>
        )}

        {showAddAudioButton ? null : (
          <>
            <Tooltip
              content="No enough free transcription time left this month"
              placement="top"
              {...tooltipProps}
            >
              <div className={block('language-selector-container')}>
                <LanguageSelector
                  label="Select a language"
                  disabled={!canTranscribe}
                  onChange={onLanguageSelected}
                  value={language}
                />
              </div>
            </Tooltip>

            <Tooltip
              content="No enough free transcription time left this month"
              placement="top"
              {...tooltipProps}
            >
              <div className={block('tooltip-wrapper')}>
                <Button
                  size="large"
                  onClick={onClickGenerateCaption}
                  theme="submit"
                  fluid
                  disabled={!canTranscribe}
                >
                  Generate captions
                </Button>
              </div>
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  public render() {
    const {
      projectTypeByAssets,
      status,
      showBalance,
      durationInMilliseconds = 0,
    } = this.props;
    const { renderTranscriptMessage, renderTranscriptActions } = this;
    /**
     * no transcription
     * or transcription not available and has audio assets
     */
    const showAddAudioButton = () =>
      projectTypeByAssets !== ProjectTypeByAssets.PROJECT_WITH_AUDIO_ASSETS;

    switch (status) {
      case TranscriptStatus.TRANSCRIPT_STATUS_AVAILABLE:
        return null;

      case TranscriptStatus.TRANSCRIPT_STATUS_IN_PROGESS:
        return <TranscriptInProgressDisplay />;

      default:
        return (
          <div className={block('generate-caption')}>
            <div className={block('transcript', { na: true })}>
              <CaptionsCircle className={block('transcript-icon')} />
              <H1>Add captions</H1>

              {renderTranscriptMessage(showAddAudioButton())}

              {renderTranscriptActions(showAddAudioButton())}
            </div>
            {!showAddAudioButton() && showBalance && (
              <div className={block('balance-container')}>
                <FreeTranscriptionBalance
                  className={block('balance')}
                  source="project"
                  durationInMilliseconds={durationInMilliseconds}
                />
              </div>
            )}
          </div>
        );
    }
  }
}

export { IProps as TranscriptStatusDisplayProps };

import React from 'react';

import BemCSSTransition from 'components/BemCssTransition';
import LoadingMessage from 'components/LoadingMessage';
import { pageBlock as block } from '../utils';
import { useClipSelectAnimation } from './ClipSelectAnimationContext';
import ClipSelectHeader from './ClipSelectHeader';
import ClipSelectIntro from './ClipSelectIntro';
import ClipSelectMediaClipper from './ClipSelectMediaClipper';
import ClipSelector from './ClipSelector';
import ClipSelectTranscriptEditor from './ClipSelectTranscriptEditor';
import ClipSelectVideoExport from './ClipSelectVideoExport';

export interface ClipSelectPageContentsProps {}

const ClipSelectPageContents: React.FC<ClipSelectPageContentsProps> = () => {
  const animations = useClipSelectAnimation();

  return (
    <>
      <BemCSSTransition className={block('loader')} {...animations.loader}>
        <LoadingMessage message="Loading Clip Data" variation="bars" />
      </BemCSSTransition>
      <BemCSSTransition className={block('header')} {...animations.header}>
        <ClipSelectHeader />
      </BemCSSTransition>
      <div className={block('body')}>
        <BemCSSTransition className={block('intro')} {...animations.intro}>
          <ClipSelectIntro />
        </BemCSSTransition>
        <BemCSSTransition
          className={block('selector')}
          {...animations.clipSelector}
        >
          <ClipSelector />
        </BemCSSTransition>

        <BemCSSTransition className={block('clipper')} {...animations.clipper}>
          <ClipSelectMediaClipper />
        </BemCSSTransition>
        <ClipSelectTranscriptEditor />
      </div>
      <BemCSSTransition className={block('export')} {...animations.export}>
        <ClipSelectVideoExport />
      </BemCSSTransition>
    </>
  );
};

export default ClipSelectPageContents;

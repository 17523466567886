import { OnTranscriptUpdate } from './types';
import useMessageHandler from './useEddyMessaging/useMessageHandler';

export type TranscriptUpdateMessageData = {
  type: 'transcriptUpdate';
  transcriptId: string;
};

export type UseTranscriptUpdateMessagingProps = {
  onTranscriptUpdate?: OnTranscriptUpdate;
};

export default function useTranscriptUpdateMessaging({
  onTranscriptUpdate,
}: UseTranscriptUpdateMessagingProps) {
  useMessageHandler<TranscriptUpdateMessageData>(e => {
    if (e.data.type === 'transcriptUpdate') {
      onTranscriptUpdate?.();
    }
  });
}

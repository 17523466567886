import BluebirdPromise from 'bluebird';
import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';

import { SPAREMIN_SERVICES_MEDIAUPLOAD_URL } from 'config';
import { transcriptSchema } from 'redux/middleware/api/transcript-service/service';
import { attachFields } from 'utils/request';
import { createRequest } from '../utils';
import * as types from './types';

const videoSchema = new schema.Entity(
  'videos',
  {
    transcript: transcriptSchema,
  },
  {
    idAttribute: 'id',
    processStrategy: value => ({
      ...value,
      transcript: {
        ...value.transcript,
        id: value.id,
      },
    }),
  },
);

const textSchema = new schema.Entity(
  'textBlobs',
  {},
  {
    idAttribute: 'textBlobId',
  },
);

const uploadVideo = (
  [
    file,
    transcribe,
    trimStart,
    trimEnd,
    lang,
    directUploadBucket,
    directUploadKey,
    initiateProcessing,
    providerUserId,
    googleDriveFileId,
    zoomMeetingId,
    zoomRecordingFileId,
    youtubeUrl,
  ]: types.UploadVideoArgs,
  request: SuperAgentStatic,
): BluebirdPromise<types.INormalizedVideoUpload> => {
  return new BluebirdPromise((resolve, reject, onCancel) => {
    const postRequest = request.post('/api/v1/media-upload/video-upload/');

    if (typeof file === 'string') {
      postRequest.field('videoUrl', file);
    } else {
      postRequest.attach('videoFile', file);
    }

    attachFields(postRequest, {
      enableTranscription: transcribe,
      language: lang,
      trimEndMillis: trimEnd,
      trimStartMillis: trimStart,
      directUploadBucket,
      directUploadKey,
      initiateProcessing,
      providerUserId,
      googleDriveFileId,
      zoomMeetingId,
      zoomRecordingFileId,
      youtubeUrl,
    });

    onCancel?.(() => {
      postRequest.abort();
    });

    return postRequest
      .then(res => resolve(normalize(res.body, videoSchema)))
      .catch(reject);
  });
};

const getVideo = (
  [id]: types.GetVideoArgs,
  request: SuperAgentStatic,
): Promise<types.INormalizedVideoUpload> =>
  request
    .get(`/api/v1/media-upload/video-upload/${id}`)
    .then(res => normalize(res.body, videoSchema));

const searchVideo = async (
  [
    md5,
    trimStartMillis,
    trimEndMillis,
    enableTranscription,
    language,
  ]: types.SearchVideoArgs,
  request: SuperAgentStatic,
): Promise<types.IVideoUpload[]> => {
  const {
    body: { data },
  } = await request
    .get(
      // NB: the trailing slash is necessary here.  see https://stash.sparemin.com/projects/SPAR/repos/embed-generator-app/pull-requests/4770/overview
      `/api/v1/media-upload/video-upload/search/`,
    )
    .query({
      md5,
      trimStartMillis,
      trimEndMillis,
      enableTranscription,
      language,
    });

  return data;
};

const uploadText = (
  [text, urlSummaryId]: types.UploadTextArgs,
  request: SuperAgentStatic,
): Promise<types.INormalizedTextUpload> => {
  const postRequest = request.post('/api/v1/media-upload/text-blob/');

  attachFields(postRequest, {
    urlSummaryId,
    textBlobBody: text,
  });

  return postRequest.then(res => normalize(res.body, textSchema));
};

const getText = (
  [id]: types.GetTextArgs,
  request: SuperAgentStatic,
): Promise<types.INormalizedTextUpload> => {
  return request
    .get(`/api/v1/media-upload/text-blob/${id}`)
    .then(res => normalize(res.body, textSchema));
};

const convertGifToVideo = (
  [source]: types.ConvertGifToVideoArgs,
  request: SuperAgentStatic,
): Promise<types.ConvertGifToVideoResult> => {
  const req = request.post('/api/v1/media-upload/gif-converter/');
  if (typeof source === 'string') {
    req.field('gifUrl', source);
  } else {
    req.attach('gifFile', source);
  }

  return req.then(res => res.body);
};

const parseExternalContent = async (
  [url]: types.ParseExternalContentArgs,
  request: SuperAgentStatic,
): Promise<types.ParseExternalContentResult> => {
  const {
    body: { data },
  } = await request
    .get('/api/v1/media-upload/external-media/parse-content')
    .query({ url });

  return data;
};

async function directUpload(
  [fileType, contentType, filename]: types.DirectUploadArgs,
  request: SuperAgentStatic,
): Promise<types.DirectUploadResult> {
  const req = request.post('/api/v1/media-upload/direct-upload/');
  attachFields(req, {
    fileType,
    filename,
    contentType,
  });
  const { body } = await req;
  return body;
}

async function getMediaContentInfo(
  [url]: types.GetMediaContentInfoArgs,
  request: SuperAgentStatic,
): Promise<types.MediaContentInfo> {
  const { body } = await request
    .get('/api/v1/media-upload/external-media/media-content-info')
    .query({ url });

  return body;
}

export const handle = (method: any, args: any, token?: string): any => {
  const request = createRequest({
    token,
    baseUrl: SPAREMIN_SERVICES_MEDIAUPLOAD_URL,
  });

  switch (method) {
    case types.ServiceMethod.UPLOAD_VIDEO:
      return uploadVideo(args, request);

    case types.ServiceMethod.GET_VIDEO:
      return getVideo(args, request);

    case types.ServiceMethod.SEARCH_VIDEO:
      return searchVideo(args, request);

    case types.ServiceMethod.CONVERT_GIF_TO_VIDEO:
      return convertGifToVideo(args, request);

    case types.ServiceMethod.UPLOAD_TEXT:
      return uploadText(args, request);

    case types.ServiceMethod.GET_TEXT:
      return getText(args, request);

    case types.ServiceMethod.PARSE_EXTERNAL_CONTENT:
      return parseExternalContent(args, request);

    case types.ServiceMethod.DIRECT_UPLOAD:
      return directUpload(args, request);

    case types.ServiceMethod.GET_MEDIA_CONTENT_INFO:
      return getMediaContentInfo(args, request);

    default:
      throw new Error(`${types.ACTION_KEY} cannot handle method ${method}`);
  }
};

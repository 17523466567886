import { isNumber } from 'underscore';
import { StaticCrop } from 'redux/modules/embed/types';
import { CropMetadata, Size } from 'types';
import bem from 'utils/bem';
import { getScaleFactors } from 'utils/placement';
import { CropInfo, Dimension } from './types';

const block = bem('video-cropper');

function calculateDimension(
  videoWidth: number,
  videoHeight: number,
  workspaceWidth: number,
  workspaceHeight: number,
  zoom: number,
): Dimension {
  const widthRatio = workspaceWidth / videoWidth;
  const heighRatio = workspaceHeight / videoHeight;
  const fitRatio = Math.min(widthRatio, heighRatio);
  const ratio = fitRatio * zoom;

  return { width: ratio * videoWidth, height: ratio * videoHeight };
}

function calculateCropInfo(
  { width, height }: Size<number>,
  workspaceWidth: number,
  workspaceHeight: number,
  zoom: number,
  top?: number,
  left?: number,
  prevZoom?: number,
): CropInfo {
  const dimension = calculateDimension(
    width,
    height,
    workspaceWidth,
    workspaceHeight,
    zoom,
  );

  let newTop = isNumber(top) ? top : undefined;
  let newLeft = isNumber(left) ? left : undefined;
  if (isNumber(prevZoom)) {
    const prevDimension = calculateDimension(
      width,
      height,
      workspaceWidth,
      workspaceHeight,
      prevZoom,
    );
    newTop = isNumber(top)
      ? top - (dimension.height - prevDimension.height) / 2
      : undefined;
    newLeft = isNumber(left)
      ? left - (dimension.width - prevDimension.width) / 2
      : undefined;
  }
  const widthDiff = dimension.width - workspaceWidth;
  const heightDiff = dimension.height - workspaceHeight;

  const initialTop = -heightDiff / 2;
  const initialLeft = -widthDiff / 2;

  return {
    dimension,
    axis: 'both',
    initialPosition: {
      left: isNumber(newLeft) ? newLeft : initialLeft,
      top: isNumber(newTop) ? newTop : initialTop,
    },
  };
}

function calculateFillZoom(
  aspectRatio: number,
  videoWidth: number,
  videoHeight: number,
) {
  const videoAspectRatio = videoWidth / videoHeight;
  if (videoAspectRatio < aspectRatio) {
    return (videoHeight / videoWidth) * aspectRatio;
  }

  if (videoAspectRatio > aspectRatio) {
    return videoWidth / videoHeight / aspectRatio;
  }

  return 1;
}

export function getVideoContainerStyles(
  topPosition: number,
  leftPosition: number,
  dimension: CropInfo['dimension'],
  boundingBox?: StaticCrop,
): React.CSSProperties {
  if (!boundingBox) {
    return {} as React.CSSProperties;
  }

  const { left = 0, top = 0, right = 0, bottom = 0 } = boundingBox;

  const targetWidth = Math.abs(right - left);
  const targetHeight = Math.abs(top - bottom);

  const { scaleX, scaleY } = getScaleFactors(
    {
      width: targetWidth,
      height: targetHeight,
    },
    dimension,
  );

  return {
    width: `${targetWidth}px`,
    height: `${targetHeight}px`,
    top: `${topPosition}px`,
    left: `${leftPosition}px`,
    transform: `scale(${scaleX}, ${scaleY})`,
  };
}

export function getVideoStyles(
  boundingBox?: StaticCrop,
): React.CSSProperties | undefined {
  if (!boundingBox) {
    return undefined;
  }

  const { left = 0, top = 0 } = boundingBox;

  return {
    objectFit: 'cover',
    objectPosition: `-${left}px -${top}px`,
  };
}

export function getScaledVideoDimensions(
  videoFileSize?: Size<number>,
  containerDimension?: Size<number>,
  cropMetadata?: CropMetadata,
  boundingBox?: StaticCrop,
): Size<number> {
  const { imageData, crop } = cropMetadata ?? {};
  const { naturalWidth, naturalHeight } = imageData ?? {};
  const { left, top, right, bottom } = boundingBox ?? {};

  const { scaleX, scaleY } = getScaleFactors(
    {
      width: cropMetadata ? naturalWidth : videoFileSize?.width,
      height: cropMetadata ? naturalHeight : videoFileSize?.height,
    },
    {
      width: containerDimension?.width,
      height: containerDimension?.height,
    },
  );

  const width = (cropMetadata ? crop?.width : Math.abs(right - left)) * scaleX;
  const height =
    (cropMetadata ? crop?.height : Math.abs(top - bottom)) * scaleY;

  return {
    width,
    height,
  };
}

export { block, calculateCropInfo, calculateFillZoom };

import { isEqual } from 'underscore';
import { TextOverlayV2 } from './types';

export const SUPPORTED_LEGACY_STYLES_VALUES = {
  background: [
    'transparent',
    'rgba(0, 0, 0, 0)',
    'rgba(255, 255, 255, 0)',
    undefined,
    null,
  ],
  lineHeight: [1.2, undefined, null],
  paddingTop: [0, undefined, null],
  paddingRight: [0, undefined, null],
  paddingBottom: [0, undefined, null],
  paddingLeft: [0, undefined, null],
};

export const SUPPORTED_LEGACY_TRANSITION_VALUE = 'cut';

/**
 * Validates if an overlay complies with a set of default styles for
 * the set of styles for which the new text overlay modal does not
 * offer controls.
 */
export const validateCompatibleLegacyStyles = (
  textOverlay?: TextOverlayV2,
): boolean => {
  const overlayStyles = textOverlay?.get('style')?.toJS();

  if (!overlayStyles) {
    return true;
  }

  return Object.entries(
    SUPPORTED_LEGACY_STYLES_VALUES,
  ).every(([style, allowedValues]) =>
    allowedValues.some(styleValue => isEqual(overlayStyles[style], styleValue)),
  );
};

/**
 * Valitates if an overlay contains transitions that do not match the
 * default ones (the new editor does not support legacy transitions)
 */
export const validateCompatibleLegacyTransitions = (
  textOverlay?: TextOverlayV2,
): boolean => {
  const overlayTransitions = textOverlay?.get('transitions')?.toJS();
  const inTransition = overlayTransitions?.in;
  const outTransition = overlayTransitions?.out;

  const inTransitionIsValid =
    !inTransition ||
    isEqual(inTransition.value, SUPPORTED_LEGACY_TRANSITION_VALUE);
  const outTransitionIsValid =
    !outTransition ||
    isEqual(outTransition.value, SUPPORTED_LEGACY_TRANSITION_VALUE);

  return inTransitionIsValid && outTransitionIsValid;
};

/**
 * Validates the config of a text overlay and checks if the elements that
 * can not be controlled with the new editor modal comply with the expected
 * values for them.
 */
export const validateCompatibleLegacyConfig = (
  textOverlay?: TextOverlayV2,
): boolean => {
  return (
    validateCompatibleLegacyStyles(textOverlay) &&
    validateCompatibleLegacyTransitions(textOverlay)
  );
};

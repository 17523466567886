import { Heading, Spacer, useMediaQuery } from '@sparemin/blockhead';
import React from 'react';

import EddyEmbed, { EddyClipSuggestion } from 'blocks/EddyEmbed';
import ClipSelectorControls from '../ClipSelectorControls';
import ClipOutdatedWarning, {
  ClipOutdatedWarningProps,
} from './ClipOutdatedWarning';
import ClipTimecodes, { ClipTimecodesProps } from './ClipTimecodes';
import { DEFAULT_LOADING_CLIP_SUGGESTION_TRANSCRIPT_MESSAGE } from './constants';
import { block } from './utils';

export interface AudioClipCardTextProps
  extends ClipTimecodesProps,
    Pick<ClipOutdatedWarningProps, 'clipId'> {
  projectId: string;
  suggestedClips?: EddyClipSuggestion[];
  isLoadingTranscript: boolean;
  isVideoOutdated?: boolean;
  isDisabled: boolean;
  playable: boolean;
  onTranscriptUpdate: () => void;
}

const AudioClipCardText: React.FC<AudioClipCardTextProps> = ({
  projectId,
  suggestedClips,
  isLoadingTranscript,
  isVideoOutdated,
  isDisabled,
  playable,
  clipId,
  clipStartMillis,
  clipEndMillis,
  onTranscriptUpdate,
}) => {
  const isMobile = useMediaQuery(`@media (max-width: 1045px)`);

  return (
    <div className={block('text-container', { disabled: !playable })}>
      <ClipTimecodes {...{ clipStartMillis, clipEndMillis }} />

      {isLoadingTranscript && (
        <div className={block('loading-message-container')}>
          <Heading
            level={2}
            className={block('loading-message')}
            lineHeight={2}
            letterSpacing="normal"
          >
            {DEFAULT_LOADING_CLIP_SUGGESTION_TRANSCRIPT_MESSAGE}
          </Heading>
        </div>
      )}

      {!isLoadingTranscript && projectId && (
        <EddyEmbed
          {...{ suggestedClips, onTranscriptUpdate }}
          width="100%"
          height="100%"
          eddyProjectId={projectId}
          context="inline"
          margin={isMobile ? '0' : '0, 42, 0, 42'}
          startMillis={clipStartMillis}
          endMillis={clipEndMillis}
          className="clip-card-eddy-embed"
        />
      )}

      <Spacer
        space="24px"
        orientation="vertical"
        className={block('controls-wrapper')}
      >
        {isVideoOutdated && <ClipOutdatedWarning clipId={clipId} />}

        <ClipSelectorControls {...{ clipId, isDisabled, playable }} />
      </Spacer>
    </div>
  );
};

export default AudioClipCardText;

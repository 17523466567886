import classNames from 'classnames';
import * as React from 'react';

import VideoExportModal from 'blocks/VideoExportModal';
import AddAudioModal from 'containers/AddAudioModal';
import AddMediaModal from 'containers/AddMediaModal';
import AddTextModal from 'containers/AddTextModal';
import useModalMonitor from 'containers/ConnectedModal/useModalMonitor';
import EditAudioModal from 'containers/EditAudioModal';
import EditBackgroundAudioModal from 'containers/EditBackgroundAudioModal';
import EditSlideModal from 'containers/EditSlideModal';
import EditTextModal from 'containers/EditTextModal';
import EditVideoModal from 'containers/EditVideoModal';
import MediaUploadModal from 'containers/MediaUploadModal/MediaUploadModal';
import TextTrackStyleModal from 'containers/TextTrackStyleModal';
import AddWaveformModal from 'containers/WaveformModal/AddWaveformModal';
import EditWaveformModal from 'containers/WaveformModal/EditWaveformModal';
import { ModalName } from 'redux/modules/modal/types';
import { VideoEditorModalsProps } from './types';

const MODAL_NAMES: ModalName[] = [
  'AddTextModal',
  'EditTextModal',
  'AddMediaModal',
  'EditSlideModal',
  'AddAudioModal',
  'EditAudioModal',
  'EditVideoModal',
  'GlobalStyleModal',
  'VideoExportModal',
];

const VideoEditorModals: React.FC<VideoEditorModalsProps> = ({
  className,
  onModalHide,
  onModalShow,
}) => {
  const containerClassName = classNames('video-modals', className);

  useModalMonitor({
    names: MODAL_NAMES,
    onHide: onModalHide,
    onShow: onModalShow,
  });

  return (
    <div className={containerClassName}>
      <AddTextModal />
      <EditTextModal />
      <AddMediaModal uploadMethod="mediaImporter" />
      <EditSlideModal />
      <AddAudioModal />
      <EditAudioModal />
      <EditBackgroundAudioModal />
      <EditVideoModal />
      <TextTrackStyleModal />
      <VideoExportModal />
      <AddWaveformModal />
      <EditWaveformModal />
      <MediaUploadModal />
    </div>
  );
};

export default VideoEditorModals;

import * as React from 'react';

import { Link } from 'react-router-dom';
import Button from 'components/Button';
import { FileSizeUpsellDialog, Variant } from 'containers/FileSizeUpsellModal';
import { block } from './utils';

export interface FileSizeUpsellContentsProps {
  onCancelClick?: () => void;
  onUpgradeClick?: (e: React.MouseEvent<any>) => void;
  variant?: Variant;
}

const ButtonAsAny = Button as any;

const FileSizeUpsellContents: React.FC<FileSizeUpsellContentsProps> = ({
  onUpgradeClick,
  onCancelClick,
  variant = 'default',
}) => {
  return (
    <>
      <FileSizeUpsellDialog
        className={block('upsell-dialog')}
        variant={variant}
      />

      <div className={block('upsell-actions')}>
        <ButtonAsAny
          componentClass={Link}
          fluid
          onClick={onUpgradeClick}
          theme="rainbow"
          to="/settings/my-plan"
        >
          increase your upload limit
        </ButtonAsAny>
        <Button theme="cancel" onClick={onCancelClick} fluid>
          no thanks
        </Button>
      </div>
    </>
  );
};

export default FileSizeUpsellContents;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import DownloadPageDetails from 'blocks/DownloadPage/components/DownloadPageDetails';
import BemCSSTransition from 'components/BemCssTransition/BemCssTransition';
import Button from 'components/Button';
import useOnMount from 'hooks/useOnMount';
import {
  autoCreateEpisodeSelector,
  createMethodSelector,
  initiatorInfoSelector,
  isVideoTiedToSuggestionGroupSelector,
} from 'redux/modules/download';
import { onChooseAnotherClipFromThisEpisode } from 'redux/modules/mixpanel';

import { ProjectCreationMethod } from 'types';
import {
  getAutomationSuggestedClipsPath,
  getSuggestedClipsPath,
} from 'utils/routes';
import { block } from '../../utils';
import CloudUploadSection from '../CloudUploadSection';
import CopyVideoLinkForm from '../CopyVideoLinkForm';
import DownloadPageSocialSection from '../DownloadPageSocialSection';
import ShareKbLink from '../ShareKbLink';

const ButtonAsAny = Button as any;

export interface DownloadShareDetailsProps {
  canReclip: boolean;
  hidden?: boolean;
  isAutogramReviewDetailsActive: boolean;
  videoDurationMillis: number;
  videoFileName?: string;
  videoUrl?: string;
  projectCreateMethod?: ProjectCreationMethod;
  onInitialLoad?: () => void;
}

const DownloadShareDetails: React.SFC<DownloadShareDetailsProps> = ({
  canReclip,
  hidden,
  isAutogramReviewDetailsActive,
  videoDurationMillis,
  videoFileName,
  videoUrl,
  onInitialLoad,
}) => {
  const createMethod = useSelector(createMethodSelector);
  const autoCreateEpisode = useSelector(autoCreateEpisodeSelector);
  const isVideoTiedToSuggestionGroup = useSelector(
    isVideoTiedToSuggestionGroupSelector,
  );
  const initiatorInfo = useSelector(initiatorInfoSelector);

  const dispatch = useDispatch();

  const onChooseAnotherClipClick = React.useCallback((): void => {
    dispatch(onChooseAnotherClipFromThisEpisode(createMethod));
  }, [createMethod, dispatch]);

  useOnMount(() => {
    if (canReclip) {
      onInitialLoad();
    }
  });

  return (
    <BemCSSTransition
      className={block('share-details-wrapper')}
      in={isAutogramReviewDetailsActive}
      timeout={300}
      enter={false}
    >
      <DownloadPageDetails
        className={block('share', { hidden })}
        subtitle={
          <>
            Get tips on sharing from our <ShareKbLink /> articles.
          </>
        }
        title="Share your video"
      >
        <DownloadPageSocialSection
          videoDurationMillis={videoDurationMillis}
          videoFileName={videoFileName}
        />
        <CopyVideoLinkForm videoUrl={`${window.location.href}`} />
        <CloudUploadSection videoFileName={videoFileName} videoUrl={videoUrl} />
        {((canReclip && autoCreateEpisode) || isVideoTiedToSuggestionGroup) && (
          <ButtonAsAny
            className={block('reclip-button')}
            componentClass={Link}
            theme="submit"
            to={
              isVideoTiedToSuggestionGroup
                ? getSuggestedClipsPath(initiatorInfo?.creationRequestId)
                : getAutomationSuggestedClipsPath({
                    episodeId: autoCreateEpisode?.get('episodeId'),
                    subscriptionItemId: autoCreateEpisode?.get(
                      'autoCreateSubItemId',
                    ),
                    groupRequestId: autoCreateEpisode?.get('groupRequestId'),
                    source: 'DownloadPageButton',
                  })
            }
            fluid
            onClick={onChooseAnotherClipClick}
          >
            choose another clip from this episode
          </ButtonAsAny>
        )}
      </DownloadPageDetails>
    </BemCSSTransition>
  );
};

export default DownloadShareDetails;

import cn from 'classnames';
import React, { isValidElement } from 'react';
import { headerBlock as block } from '../../utils';

type ChildProps = {
  className: string;
};

export type HeaderItemProps = {
  children?: React.ReactElement<ChildProps>;
  disableInteraction?: boolean;
};

const HeaderItem: React.FC<HeaderItemProps> = ({
  children,
  disableInteraction,
}) => {
  const child = React.Children.only(children);

  return !isValidElement(child)
    ? child
    : React.cloneElement(child, {
        className: cn(
          block('item', { disabled: disableInteraction }),
          child.props.className,
        ),
      });
};

export default HeaderItem;

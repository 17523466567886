import deepmerge, { Options } from 'deepmerge';
import { isFunction } from 'underscore';

/**
 * HACK for importing deepmerge
 *
 * deepmerge is built for umd as well as es modules - in node_modules/deepmerge/dist, there is both
 * a umd.js and es.js file.  The package.json file for deepmerge has "main" pointed to umd.js and
 * "module" pointed to es.js.  When the es.js file is picked up,
 * `import * as deepmerge from 'deepmerge'` works correctly, but when the umd.js file is picked up,
 * default export must be used.
 *
 * Webpack is configured (by default) to pickup "module" before "main" in dependency package.json
 * files.  `import * as deepmerge from 'deepmerge'` makes the application work correctly since
 * webpack is picking up es.js from the "module" key.  This import however doesn't work in tests.
 *
 * Jest is configured to use "main" by default and there's really no way to point jest to "module".
 * There is an open ticket for this here https://github.com/facebook/jest/issues/2702 where someone
 * created a gist (https://gist.github.com/loklaan/9fe7768576466c5a31c2e7af4cfdecd0) that maps
 * "module" to "main" - in which case Jest would pickup exactly what we want.
 *
 * The issue with Jest picking up "module" over "main" is that "module" code often needs to be
 * transpiled.  So, we'd have to send all of the node_modules through a transform so that tests
 * can run.  This breaks most of our existing tests without setting up the transform.
 */
// @ts-ignore
const merge = isFunction(deepmerge) ? deepmerge : deepmerge.default;
export default merge;

const emptyTarget = value => (Array.isArray(value) ? [] : {});
const clone = (value: any, opts?: Options) =>
  merge(emptyTarget(value), value, opts);

/**
 * An array merge function for deepmerge that combines arrays based on index
 *
 * https://github.com/TehShrike/deepmerge/tree/v3.0.0?tab=readme-ov-file#combine-array
 */
export const combineMerge: Options['arrayMerge'] = (target, source, opts) => {
  const dest = target.slice();

  source.forEach((item, index) => {
    if (typeof dest[index] === 'undefined') {
      const cloneRequested = opts.clone !== false;
      const shouldClone = cloneRequested && opts.isMergeableObject(item);
      dest[index] = shouldClone ? clone(item, opts) : item;
    } else if (opts.isMergeableObject(item)) {
      dest[index] = merge(target[index], item, opts);
    } else if (target.indexOf(item) === -1) {
      dest.push(item);
    }
  });

  return dest;
};

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import usePodcastData from 'hooks/usePodcastData';
import { createDefaultProjectNameSelector } from 'redux/modules/project/selectors';
import { AudioSourceType, PodcastIdentifier } from 'types';

import CustomizeStep, { CustomizeStepProps } from '../CustomizeStep';
import CustomizeStepCta, {
  CustomizeStepCtaProps,
} from '../CustomizeStep/CustomizeStepCta';
import { Transcription } from './types';

type PickedCustomizeProps = Pick<
  CustomizeStepProps,
  | 'compatibilityTypes'
  | 'defaults'
  | 'features'
  | 'lastUsedStyle'
  | 'onChangeView'
  | 'onError'
  | 'onSelectTemplate'
  | 'onSelectAspectRatio'
  | 'onStyleChange'
  | 'onTemplatesClick'
  | 'templateId'
  | 'templateTypes'
  | 'view'
>;

type PickedCtaProps = Pick<CustomizeStepCtaProps, 'transcriptionEnabled'>;

export type CustomizeStepSubmitType = 'export' | 'edit';

export interface AudiogramCustomizeStepProps
  extends PickedCustomizeProps,
    PickedCtaProps {
  transcription: Transcription;
  onSubmit?: CustomizeStepCtaProps['onClick'];
  podcastIdentifier: PodcastIdentifier;
  audioSourceType: AudioSourceType;
  audioClipDurationSec?: number;
  language: string;
  source?: string | File;
  shouldGenerateAssets?: boolean;
}

const AudiogramCustomizeStep: React.FC<AudiogramCustomizeStepProps> = ({
  compatibilityTypes,
  transcription,
  defaults,
  features,
  lastUsedStyle,
  onChangeView,
  onError,
  onSelectTemplate,
  onSelectAspectRatio,
  onStyleChange,
  onSubmit,
  onTemplatesClick,
  podcastIdentifier,
  templateId,
  templateTypes,
  transcriptionEnabled,
  audioSourceType,
  audioClipDurationSec,
  language,
  view,
  source,
  shouldGenerateAssets,
}) => {
  const { episodeId, podcastId } = podcastIdentifier ?? {};
  const { artworkUrl, episodeTitle, podcastTitle } = usePodcastData(
    podcastIdentifier ?? {},
  );
  const defaultProjectName = useSelector(
    useMemo(
      () =>
        createDefaultProjectNameSelector({
          source,
          episodeId,
          podcastId,
        }),
      [episodeId, podcastId, source],
    ),
  );

  return (
    <CustomizeStep
      {...{
        compatibilityTypes,
        features,
        lastUsedStyle,
        onChangeView,
        onError,
        onSelectTemplate,
        onSelectAspectRatio,
        onStyleChange,
        onTemplatesClick,
        templateId,
        episodeId,
        podcastId,
        templateTypes,
        transcriptionEnabled,
        view,
        audioClipDurationSec,
      }}
      transcription={{
        transcribe: transcription?.transcribe,
        language: transcription?.language,
      }}
      defaults={{
        keyAssets: {
          episodeTitle,
          podcastTitle,
          mainImage: artworkUrl,
        },
        ...defaults,
      }}
      podcastId={podcastIdentifier?.podcastId}
      renderCta={({ className, disabled, exportEditorState, state }) => (
        <CustomizeStepCta
          {...{
            className,
            disabled,
            exportEditorState,
            state,
            transcriptionEnabled,
            defaultProjectName,
            audioSourceType,
            language,
            shouldGenerateAssets,
          }}
          onClick={onSubmit}
        />
      )}
    />
  );
};

export default AudiogramCustomizeStep;

import { Description, Tooltip, useListItem } from '@sparemin/blockhead';
import cn from 'classnames';
import { createPath } from 'history';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { getProjectPath } from 'utils/routes';
import { ProjectTileControl } from '../types';
import { block, makeProjectClipsPathSelector } from '../utils';
import ProjectRowActions from './ProjectRowActions';
import { ProjectRowProvider } from './ProjectRowContext';
import ProjectRowHeadline from './ProjectRowHeadline';
import ProjectRowThumbnail from './ProjectRowThumbnail';

export interface ProjectRowProps {
  projectId?: string;
  tooltipMessage?: string;
  headerClassName?: string;
  onControlClick?: (type: ProjectTileControl, projectId: string) => void;
}

const ProjectRow: React.FC<ProjectRowProps> = ({
  projectId,
  tooltipMessage,
  headerClassName,
  onControlClick,
}) => {
  const { isDisabled } = useListItem();
  const location = useLocation();

  const clipsPath = useSelector(makeProjectClipsPathSelector(projectId));

  const projectPath = getProjectPath(projectId, {
    source: location.pathname,
  });

  return (
    <ProjectRowProvider projectId={projectId}>
      <Tooltip
        contents={tooltipMessage}
        isDisabled={!isDisabled}
        placement="top"
        arrow
      >
        <Description
          as={Link}
          isDisabled={isDisabled}
          className={block('project-row', { disabled: isDisabled })}
          onClick={e => isDisabled && e.preventDefault()}
          {...(clipsPath
            ? {
                to: createPath(clipsPath),
                target: '_blank',
              }
            : {
                to: projectPath,
                target: '_self',
              })}
        >
          <Description.Header className={cn(block('header'), headerClassName)}>
            <ProjectRowThumbnail />

            <ProjectRowHeadline />

            <ProjectRowActions onControlClick={onControlClick} />
          </Description.Header>
        </Description>
      </Tooltip>
    </ProjectRowProvider>
  );
};

export default ProjectRow;

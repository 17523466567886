import { useBoundCounter } from '@sparemin/blockhead';
import * as React from 'react';

import Tooltip from 'components/Tooltip';
import ToolbarButtonV2 from '../ToolbarButtonV2';
import { block } from '../utils';
import { TOGGLE_CONFIG_BY_CASE } from './constants';
import { TEXT_CASES, TextCase } from './types';

export interface CaseToggleProps {
  value?: TextCase;
  onChange?: (state: TextCase) => void;
}

const CaseToggle: React.FC<CaseToggleProps> = ({
  value = 'none',
  onChange,
}) => {
  const buttonRef = React.useRef<HTMLDivElement>(null);

  const { increment } = useBoundCounter({
    initialValue: 0,
    max: TEXT_CASES.length - 1,
  });

  const handleChange = React.useCallback(() => {
    const nextIndex = increment();

    onChange(TEXT_CASES[nextIndex]);
  }, [increment, onChange]);

  return (
    <ToolbarButtonV2 ref={buttonRef} onPress={handleChange}>
      <Tooltip
        content={TOGGLE_CONFIG_BY_CASE[value].tooltipMessage}
        placement="top"
      >
        <div className={block('case-toggle-icon-container')}>
          {TOGGLE_CONFIG_BY_CASE[value].icon}
        </div>
      </Tooltip>
    </ToolbarButtonV2>
  );
};

export default CaseToggle;

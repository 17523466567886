import { OnClipSelect } from './types';
import useMessageHandler from './useEddyMessaging/useMessageHandler';

export type ClipSelectMessageData = {
  type: 'clipSelect';
  startMillis: number;
  endMillis: number;
};

export type UseClipSelectMessagingProps = {
  onClipSelect?: OnClipSelect;
};

export default function useClipSelectMessaging({
  onClipSelect,
}: UseClipSelectMessagingProps) {
  useMessageHandler<ClipSelectMessageData>(e => {
    if (e.data.type === 'clipSelect') {
      const { startMillis, endMillis } = e.data;
      onClipSelect?.({ startMillis, endMillis });
    }
  });
}

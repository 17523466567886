import React, { useCallback, useEffect, useState } from 'react';
import ids from 'short-id';
import { identity, noop } from 'underscore';
import { useTemplateDispatch } from 'components/VideoTemplateEditor/state';
import { EditorState } from '..';
import {
  MediaIntegrationId,
  OnIsAutoFrameChange,
  OnStateChange,
  VideoTemplateState,
} from '../types';
import {
  DynamicVideoIntegration,
  IntegrationProps,
  PlaceholderVideo,
} from '../types/integrations';
import useCreateVideoAsset from '../useCreateVideoAsset';
import VideoPodcastSettings from '../VideoPodcastSettings';

const INTEGRATION_ID = MediaIntegrationId.DYNAMIC as const;

interface UseDynamicVideoIntegrationConfig
  extends IntegrationProps,
    Pick<DynamicVideoIntegration, 'childViewProps'> {
  placeholderVideo?: PlaceholderVideo;
  state: VideoTemplateState;
  onIsAutoFrameChange?: OnIsAutoFrameChange;
  onChange: OnStateChange;
}

export default function useDynamicVideoIntegration({
  state,
  placeholderVideo,
  priority,
  childViewProps,
  onIsAutoFrameChange,
  onChange,
}: UseDynamicVideoIntegrationConfig): DynamicVideoIntegration {
  const [hasAddedVideo, setHasAddedVideo] = useState(false);

  const { src, fileName, trimStartMillis, trimEndMillis, framingMethod } =
    placeholderVideo ?? {};
  const isAutoFrame = framingMethod === 'autoframe';

  const templateDispatch = useTemplateDispatch({
    onChange,
  });

  const { createVideoAsset } = useCreateVideoAsset();

  const handleAddPlaceholderVideo = useCallback(async (): Promise<void> => {
    const { videoAsset, startMillis, endMillis } = await createVideoAsset(
      src,
      trimStartMillis,
      trimEndMillis,
    );

    templateDispatch({
      type: 'VIDEOCLIP_ADD',
      payload: {
        src: videoAsset,
        fileName,
        id: ids.generate(),
        integrationData: {
          id: INTEGRATION_ID,
        },
        original: undefined,
        enableBlur: true,
        framingMethod,
        fitType: isAutoFrame ? 'fill' : 'fit',
        trimStartMillis: startMillis,
        trimEndMillis: endMillis,
      },
    });
  }, [
    createVideoAsset,
    fileName,
    framingMethod,
    isAutoFrame,
    src,
    templateDispatch,
    trimEndMillis,
    trimStartMillis,
  ]);

  useEffect(() => {
    if (
      hasAddedVideo ||
      !placeholderVideo ||
      !EditorState.getContent(state)?.canvas
    ) {
      return undefined;
    }

    handleAddPlaceholderVideo();
    onIsAutoFrameChange(isAutoFrame);
    setHasAddedVideo(true);

    return undefined;
  }, [
    handleAddPlaceholderVideo,
    hasAddedVideo,
    isAutoFrame,
    onIsAutoFrameChange,
    placeholderVideo,
    state,
  ]);

  return {
    priority,
    creationType: 'dynamic',
    type: 'video',
    id: INTEGRATION_ID,
    postProcessor: identity,
    compatibleIntegrationIds: [INTEGRATION_ID],
    childViewProps: {
      title: childViewProps?.title ?? 'video podcast',
      body: childViewProps?.body ?? (
        <VideoPodcastSettings onIsAutoFrameChange={onIsAutoFrameChange} />
      ),
    },
    onAddMedia: noop,
    onReplaceMedia: noop,
    onDeleteMedia: noop,
  };
}

import { useToggle } from '@sparemin/blockhead';
import React from 'react';
import { isFunction, noop } from 'underscore';

import TextWorkspace from 'components/TextWorkspace';
import {
  getDraggingEnabledByTemplateId,
  getResizableEdgesByTemplateId,
} from 'utils/embed/text-overlay';

import useTextOverlayCaptionsAnimations from '../../hooks/useTextOverlayCaptionsAnimations';
import useTextOverlayGooeyEffect from '../../hooks/useTextOverlayGooeyEffect';
import useOverlayPosition from '../../hooks/useTextOverlayPosition';
import useTextOverlayResizeDelta from '../../hooks/useTextOverlayResizeDelta';
import useTextOverlayStyle from '../../hooks/useTextOverlayStyle';
import { useTextOverlay } from '../../state/TextOverlayProvider';
import { block } from '../../utils';
import TextOverlayTextPreview from '../TextOverlayTextPreview';
import { RESIZE_HANDLES_STYLES } from './constants';

export interface TextOverlayPreviewPaneProps {
  aspectRatio: number;
  background: React.ReactElement | (({ viewport }) => React.ReactElement);
  editable: boolean;
  inputDisabled?: boolean;
  templateId?: string;
  isCaption?: boolean;
}

const TextOverlayPreviewPane: React.FunctionComponent<TextOverlayPreviewPaneProps> = props => {
  const {
    aspectRatio,
    background,
    editable,
    inputDisabled = false,
    templateId,
    isCaption,
  } = props;

  const {
    boxSizeFitCheckerExtension,
    canvasSize,
    draftEditorData,
    onChangeText,
    textValue,
  } = useTextOverlay();

  const { textAreaStyles, textBuilderStyles } = useTextOverlayStyle();

  const {
    toggleOff: turnOffInputMode,
    toggleOn: turnOnInputMode,
    value: inputModeEnabled,
  } = useToggle(false);

  const {
    onDrag,
    onDragStop,
    onResizeStart,
    onResizeStop,
    position,
    size,
  } = useOverlayPosition();

  const {
    resizeDeltaObserver: boxFitCheckerResizeDeltaObserver,
  } = boxSizeFitCheckerExtension;

  const {
    resizeDeltaObserver: gooeyEffectResizeDeltaObserver,
  } = useTextOverlayGooeyEffect();

  const {
    resizeDeltaObserver: captionsAnimationsResizeDeltaObserver,
  } = useTextOverlayCaptionsAnimations();

  const resizeDeltaObservers = React.useMemo(
    () => [
      boxFitCheckerResizeDeltaObserver,
      gooeyEffectResizeDeltaObserver,
      captionsAnimationsResizeDeltaObserver,
    ],
    [
      boxFitCheckerResizeDeltaObserver,
      captionsAnimationsResizeDeltaObserver,
      gooeyEffectResizeDeltaObserver,
    ],
  );

  const {
    onTextBoxResize,
    onTextBoxResizeEnd,
    onTextBoxResizeStart,
  } = useTextOverlayResizeDelta({
    canvasSize,
    draftEditorData,
    resizeObservers: resizeDeltaObservers,
  });

  const draggingEnabled =
    !inputModeEnabled && getDraggingEnabledByTemplateId(templateId);

  return (
    <TextWorkspace
      aspectRatio={aspectRatio}
      background={
        <div>
          <div className={block('preview-fade')} />
          {isFunction(background)
            ? background({ viewport: canvasSize })
            : background}
        </div>
      }
      boxBorderClassName={block('resize-box-border', {
        'in-edition': inputModeEnabled,
      })}
      className={block('workspace')}
      containerSize={canvasSize}
      defaultSize={canvasSize}
      disabled={inputDisabled}
      draggingEnabled={draggingEnabled}
      onTextBoxDrag={onDrag}
      onTextBoxDragStop={onDragStop}
      onTextBoxResize={onTextBoxResize}
      onTextBoxResizeStart={onTextBoxResizeStart(onResizeStart)}
      onTextBoxResizeStop={onTextBoxResizeEnd(onResizeStop)}
      onWorkspaceSizeChange={noop}
      positionOverlayClassName={block('position-overlay')}
      resizeHandleStyles={RESIZE_HANDLES_STYLES}
      resizingEnabled={!inputModeEnabled}
      textBoxClassName={block('text-box')}
      textBoxPosition={position}
      textBoxSize={size}
      resizableEdges={getResizableEdgesByTemplateId(templateId)}
    >
      <TextOverlayTextPreview
        editable={editable}
        inputModeEnabled={inputModeEnabled}
        onChangeText={onChangeText}
        onDisableInputMode={turnOffInputMode}
        onEnableInputMode={turnOnInputMode}
        textAreaStyles={textAreaStyles}
        textBuilderStyles={textBuilderStyles}
        textValue={textValue}
        isCaption={isCaption}
      />
    </TextWorkspace>
  );
};

export default TextOverlayPreviewPane;

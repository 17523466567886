import { useCallback } from 'react';

import useEventHandler from 'hooks/useEventHandler';
import { createChainedFunction } from 'utils/functions';
import { WaveSurferProps } from './types';
import { attachHandler } from './utils';

export default function useWaveSurferEventHandlers({
  onAudioprocess,
  onDestroy,
  onError,
  onFinish,
  onInteraction,
  onLoading,
  onMute,
  onPause,
  onPlay,
  onReady,
  onScroll,
  onSeek,
  onVolume,
  onWaveformReady,
  onZoom,
}: WaveSurferProps) {
  const audioProcessRef = useEventHandler(
    onAudioprocess,
    attachHandler('audioprocess'),
  );
  const destroyRef = useEventHandler(onDestroy, attachHandler('destroy'));
  const errorRef = useEventHandler(onError, attachHandler('error'));
  const finishRef = useEventHandler(onFinish, attachHandler('finish'));
  const interactionRef = useEventHandler(
    onInteraction,
    attachHandler('interaction'),
  );
  const loadingRef = useEventHandler(onLoading, attachHandler('loading'));
  const muteRef = useEventHandler(onMute, attachHandler('mute'));
  const pauseRef = useEventHandler(onPause, attachHandler('pause'));
  const playRef = useEventHandler(onPlay, attachHandler('play'));
  const readyRef = useEventHandler(onReady, attachHandler('ready'));
  const scrollRef = useEventHandler(onScroll, attachHandler('scroll'));
  const seekRef = useEventHandler(onSeek, attachHandler('seek'));
  const volumeRef = useEventHandler(onVolume, attachHandler('volume'));
  const waveformReadyRef = useEventHandler(
    onWaveformReady,
    attachHandler('waveform-ready'),
  );
  const zoomRef = useEventHandler(onZoom, attachHandler('zoom'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    createChainedFunction(
      audioProcessRef,
      destroyRef,
      errorRef,
      finishRef,
      interactionRef,
      loadingRef,
      muteRef,
      pauseRef,
      playRef,
      readyRef,
      scrollRef,
      seekRef,
      volumeRef,
      waveformReadyRef,
      zoomRef,
    ),
    [
      audioProcessRef,
      destroyRef,
      errorRef,
      finishRef,
      interactionRef,
      loadingRef,
      muteRef,
      pauseRef,
      playRef,
      readyRef,
      scrollRef,
      seekRef,
      volumeRef,
      waveformReadyRef,
      zoomRef,
    ],
  );
}

import cn from 'classnames';
import { fromJS, List, Record, RecordOf } from 'immutable';
import * as React from 'react';
import { isFunction, noop } from 'underscore';

import FadingScrollBars from 'components/FadingScrollBars';
import FontLoader from 'components/hoc/FontLoader';
import Modal from 'components/Modal';
import RichTextEditor, { RichTextEditorProps } from 'components/RichTextEditor';
import RndTextEditor, { RndTextEditorData } from 'components/RndTextEditor';
import Tabs from 'components/Tabs';
import TextBoxOptions from 'components/TextBoxOptions';
import TextOverlayTransitionsForm from 'components/TextOverlayTransitionsForm';
import TextPresetSelector, {
  TextPresetSelectorProps,
} from 'components/TextPresetSelector';
import TextTemplateSelector, {
  TextTemplateSelectorProps,
} from 'components/TextTemplateSelector';
import TextWorkspace from 'components/TextWorkspace';
import { UseHook } from 'components/UseHook';
import useCustomFontsLoader from 'hooks/useCustomFontsLoader';
import { ImmutableTextPreset } from 'redux/modules/text-presets';
import { Size } from 'types';
import { MINIMUM_TEXT_DURATION_MILLIS } from 'utils/constants';
import {
  getDraggingEnabledByTemplateId,
  getResizableEdgesByTemplateId,
  scaleInlineStyles,
} from 'utils/embed/text-overlay';
import { GRID_SIZE, scaleToGrid } from 'utils/embed/ui';
import { getFontName } from 'utils/fonts';
import { percentageOf, round, scale } from 'utils/numbers';
import { splitStyle } from 'utils/rte';
import { prefix } from 'utils/ui';
import { ContainerDecorator, ContainerStyle, TextOverlay } from '../../types';
import {
  block,
  convertEditorDataToOverlay,
  convertOverlayToEditorData,
  formatPresetStyles,
  getWorkspaceColSize,
  STYLE_PRESETS_COL_SIZE,
  STYLE_TABS_COL_SIZE,
} from '../../utils';

const MIN_DURATION_MILLIS = MINIMUM_TEXT_DURATION_MILLIS;

interface IFont {
  name: string;
  familyName: string;
  family: string;
  url?: string;
}

export interface TextOverlayModalContentsProps {
  background: React.ReactNode;
  banner?: React.ReactNode;
  cancelButton?: JSX.Element;
  defaultText?: string;
  defaultInTransitionName?: string;
  defaultOutTransitionName?: string;
  deleteButton?: JSX.Element;
  duplicateButton?: JSX.Element;
  editable?: boolean;
  fonts?: IFont[];
  footerLeftSlot?:
    | React.ReactNode
    | ((props: {
        partialSubmitHandlerBuilder: (
          partialSubmitter: (textOverlay: TextOverlay) => void,
        ) => (e?: unknown) => void;
      }) => React.ReactElement);
  inputDisabled?: boolean;
  onEmojiSelect?: RichTextEditorProps['onEmojiSelect'];
  onTemplateSelect?: (name: string) => void;
  showEmoji?: boolean;
  showStyleTabs?: boolean;
  shouldDisableSubmitIfEmpty?: boolean;
  showPresetSelector?: boolean;
  styleTemplates?: TextTemplateSelectorProps['templates'];
  submitButton?: JSX.Element;
  textOverlay: TextOverlay;
  workspaceAspectRatio?: number;
  onWorkspaceSizeChange?: (size: Size<number>) => void;
}

interface ITextOverlayState {
  containerDecorator: ContainerDecorator;
  containerStyle: ContainerStyle;
  transitions: any;
}

interface IUiState {
  inputMode: boolean;
  isResizing: boolean;
  selectedFontName: string;
  selectedTemplateId: string;
  selectedTransitionTab: string | number;
  textHtml: string;
  toolbarInitialized: boolean;
}

interface IState {
  isContentEmpty: boolean;
  textOverlay: RecordOf<ITextOverlayState>;
  selectedPreset?: ImmutableTextPreset;
  ui: RecordOf<IUiState>;
}

const textOverlayFactory = Record<ITextOverlayState>({
  containerDecorator: undefined,
  containerStyle: undefined,
  transitions: undefined,
});

const uiFactory = Record<IUiState>({
  inputMode: false,
  isResizing: false,
  selectedFontName: undefined,
  selectedTemplateId: undefined,
  selectedTransitionTab: undefined,
  textHtml: undefined,
  toolbarInitialized: false,
});

const NON_SUPPORTED_PRESET_STYLE_KEYS = ['textOutline'];

const tabContentClassName = cn(
  'text-modal__tab-content',
  'text-modal__tab-content--std',
);

const styleTabContentClassName = cn(
  tabContentClassName,
  'text-modal__style-tab-content',
);

export default class TextOverlayModalContents extends React.Component<
  TextOverlayModalContentsProps,
  IState
> {
  public static defaultProps: Partial<TextOverlayModalContentsProps> = {
    editable: true,
    inputDisabled: false,
    onTemplateSelect: noop,
    showEmoji: true,
    showStyleTabs: true,
  };

  private toolbar: RichTextEditor;
  private textEditor: RndTextEditor;
  private textarea: HTMLDivElement;

  public state = {
    isContentEmpty: false,
    selectedPreset: undefined,
    textOverlay: textOverlayFactory({
      containerDecorator: this.props.textOverlay.get('containerDecorator'),
      transitions: this.props.textOverlay.get('transitions'),
    }),
    ui: uiFactory({
      selectedFontName: getFontName(
        this.props.textOverlay.getIn(['style', 'fontFamily']),
        this.props.fonts,
      ),
      selectedTemplateId: this.props.textOverlay.get('templateId'),
      selectedTransitionTab: 'in',
      /*
       * textHtml is part of the UI key because it just holds a value used to initialize the
       * inline editor.  the actual value that gets saved back to the text overlay comes from the
       * inline editor on submit
       */
      textHtml: undefined,
      toolbarInitialized: false,
    }),
  };

  private handleTransitionInChange = tIn =>
    this.setState(({ textOverlay }) => ({
      textOverlay: textOverlay.setIn(['transitions', 'in'], tIn),
    }));

  private handleTransitionOutChange = tOut =>
    this.setState(({ textOverlay }) => ({
      textOverlay: textOverlay.setIn(['transitions', 'out'], tOut),
    }));

  private handleTemplateSelect = (
    data,
    onUpdate,
    formatStyleRules,
  ): TextTemplateSelectorProps['onSelect'] => ({
    template,
    displayName,
    templateId,
  }) => {
    const { fonts, onTemplateSelect } = this.props;
    const formattedTemplate = convertOverlayToEditorData(template);

    const fontName = getFontName(
      template.getIn(['style', 'fontFamily']),
      fonts,
    );
    this.setState(({ ui, textOverlay }) => ({
      textOverlay: textOverlay.set(
        'containerDecorator',
        template.get('containerDecorator'),
      ),
      ui: ui
        .set('selectedFontName', fontName)
        .set('selectedTemplateId', templateId),
    }));

    onUpdate(formattedTemplate.set('time', data.get('time')), updatedData => {
      const { innerStyle } = splitStyle(updatedData.get('containerStyle'));
      this.toolbar.clearAllStyles();
      this.toolbar.setContainerStyle({
        ...prefix(formatStyleRules(innerStyle)),
      });
      this.toolbar.setTextStyle({
        ...prefix(formatStyleRules(updatedData.get('textStyle'))),
      });
    });

    onTemplateSelect(displayName);
  };

  private handlePresetSelect = (
    formatStyleRules,
  ): TextPresetSelectorProps['onPickPreset'] => (
    preset: ImmutableTextPreset,
  ) => {
    const { fonts } = this.props;
    const processedPreset = formatPresetStyles(
      preset,
      NON_SUPPORTED_PRESET_STYLE_KEYS,
    );

    const fontName = getFontName(processedPreset.get('fontFamily'), fonts);

    this.setState(({ ui }) => ({
      ui: ui.set('selectedFontName', fontName),
    }));

    this.toolbar.setTextStyle(formatStyleRules(processedPreset));
    this.toolbar.setSelectedFont(processedPreset.get('fontFamily'));
    this.setState({ selectedPreset: preset });
  };

  private handlePresetTargetStyleChange = (): void => {
    const { selectedPreset } = this.state;

    if (selectedPreset) {
      this.setState({ selectedPreset: undefined });
    }
  };

  private handleTextBoxDrag = setPosition => ({ x, y }) =>
    setPosition({ x, y });

  private handleTextBoxDragStop = setPosition => ({ x, y }) =>
    setPosition({ x, y });

  private handleTextBoxResizeStart = () =>
    this.setState(({ ui }) => ({
      ui: ui.set('isResizing', true),
    }));

  private handleTextBoxResizeStop = setSize => ({ height, width, x, y }) => {
    setSize(x, y, height, width);
    this.setState(({ ui }) => ({
      ui: ui.set('isResizing', false),
    }));
  };

  private handleWorkspaceSizeChange = setWorkspaceSize => ({
    height,
    width,
  }) => {
    const { onWorkspaceSizeChange } = this.props;

    setWorkspaceSize(height, width);

    onWorkspaceSizeChange?.({ height, width });
  };

  private handleTextBoxDoubleClick = event => {
    event.stopPropagation();
    this.setInputMode(true);
    this.toolbar.focusEditor();
  };

  private handleContentChange = (content: string) => {
    this.setState({ isContentEmpty: !content.trim() });
  };

  private handleToolbarBlur = () => this.setInputMode(false);

  private handleToolbarInit = () => {
    const { textOverlay } = this.props;

    const overlayWidth = textOverlay.getIn(['viewport', 'width']);
    const workspaceWidth = this.textEditor
      .getData()
      .getIn(['viewport', 'width']);
    const scaledContent = scaleInlineStyles(
      textOverlay.get('textHtml'),
      'fontSize',
      val => `${scale(parseFloat(val), overlayWidth, workspaceWidth)}px`,
    );

    this.setState(({ ui }) => ({
      ui: ui.withMutations(u =>
        u.set('textHtml', scaledContent).set('toolbarInitialized', true),
      ),
    }));
  };

  private handleTimeChange = handlers => ({ startMillis, endMillis }) =>
    handlers.setTime(startMillis, endMillis);

  private handleDecoratorStyleChange = index => dynamicStyle =>
    this.setState(({ textOverlay }) => ({
      textOverlay: (textOverlay as any).setIn(
        ['containerDecorator', 'styles', index],
        fromJS(dynamicStyle),
      ),
    }));

  private handleLineSpacingChange = handlers => value => {
    handlers.setLineHeight(value);
    this.toolbar.setContainerStyle({ lineHeight: value });
  };

  private handleLineSpacingBlur = handlers => value => {
    const newValue = value === '' ? 1 : value;

    handlers.setLineHeight(newValue);
    this.toolbar.setContainerStyle({ lineHeight: newValue });
  };

  private handlePaddingChange = handlers => value => {
    handlers.setPaddingTop(value.top);
    handlers.setPaddingBottom(value.bottom);
    handlers.setPaddingLeft(value.left);
    handlers.setPaddingRight(value.right);
  };

  private handlePaddingBlur = handlers => value => {
    const newValue = {
      bottom: value.bottom === '' ? 0 : value.bottom,
      left: value.left === '' ? 0 : value.left,
      right: value.right === '' ? 0 : value.right,
      top: value.top === '' ? 0 : value.top,
    };

    handlers.setPaddingTop(newValue.top);
    handlers.setPaddingBottom(newValue.bottom);
    handlers.setPaddingLeft(newValue.left);
    handlers.setPaddingRight(newValue.right);
  };

  private handlePositionChange = handlers => value =>
    handlers.setPosition(value, GRID_SIZE, true);

  private handlePositionBlur = handlers => value => {
    const newValue = {
      x: value.x === '' ? 0 : value.x,
      y: value.y === '' ? 0 : value.y,
    };

    handlers.setPosition(newValue, GRID_SIZE, true);
  };

  private setInputMode(value) {
    this.setState(({ ui }) => ({
      ui: ui.set('inputMode', value),
    }));
  }

  private createSubmitHandler = parentOnSubmit => {
    if (!isFunction(parentOnSubmit)) return undefined;

    return e => {
      const { textOverlay: overlayProp } = this.props;
      const { textOverlay: overlayState, ui } = this.state;

      const viewportWidth = this.textEditor
        .getData()
        .getIn(['viewport', 'width']);
      const overlayWidth = overlayProp.getIn(['viewport', 'width']);

      const content = scaleInlineStyles(
        this.toolbar.content,
        'fontSize',
        val => `${scale(val, viewportWidth, overlayWidth)}px`,
      );

      const customizedOverlay = convertEditorDataToOverlay(
        this.textEditor.getData(overlayProp.get('viewport')) as any,
        content,
        overlayProp.get('transitions'),
      );

      // TODO instead of rounding font size, just save the display size somewhere
      const fullyCustomizedOverlay = overlayProp.withMutations(o =>
        o
          .merge(customizedOverlay, overlayState)
          .updateIn(['style', 'fontSize'], size => round(size))
          .set('templateId', ui.get('selectedTemplateId')),
      );

      parentOnSubmit(fullyCustomizedOverlay, e);
    };
  };

  private handleTransitionTabSelect = (tabKey: string | number) =>
    this.setState(({ ui }) => ({
      ui: ui.set('selectedTransitionTab', tabKey),
    }));

  private renderTransitionOptionsFormTab = (className?: string) => {
    const { defaultInTransitionName, defaultOutTransitionName } = this.props;
    const { ui } = this.state;
    const selectedTransitionTab = ui.get('selectedTransitionTab');
    const isScrollDisabled = () =>
      (selectedTransitionTab === 'in' && !!defaultInTransitionName) ||
      (selectedTransitionTab === 'out' && !!defaultOutTransitionName);

    return (
      <FadingScrollBars
        className="text-modal__fade-scroller"
        hideTracksWhenNotNeeded
        disabled={isScrollDisabled()}
      >
        <div className={className}>{this.renderTransitionOptionsForm()}</div>
      </FadingScrollBars>
    );
  };

  private renderTransitionOptionsForm() {
    const {
      defaultInTransitionName,
      defaultOutTransitionName,
      inputDisabled,
    } = this.props;
    const { textOverlay } = this.state;

    return (
      <TextOverlayTransitionsForm
        className="text-modal__trnstn-form"
        transitionIn={textOverlay.getIn(['transitions', 'in'])}
        transitionOut={textOverlay.getIn(['transitions', 'out'])}
        onInTransitionChange={this.handleTransitionInChange}
        onOutTransitionChange={this.handleTransitionOutChange}
        disabled={inputDisabled}
        defaultInTransitionName={defaultInTransitionName}
        defaultOutTransitionName={defaultOutTransitionName}
        onTabSelect={this.handleTransitionTabSelect}
      />
    );
  }

  private renderFooterButtons() {
    const {
      submitButton,
      cancelButton,
      deleteButton,
      duplicateButton,
      inputDisabled,
      shouldDisableSubmitIfEmpty,
    } = this.props;

    const { isContentEmpty } = this.state;

    const buttons = [];

    const isSubmitBtnDisabled = shouldDisableSubmitIfEmpty && isContentEmpty;

    if (cancelButton) {
      buttons.push(
        React.cloneElement(cancelButton, {
          className: cn('text-modal__cancel', cancelButton.props.className),
          disabled: inputDisabled,
          key: 'cancel',
        }),
      );
    }

    if (deleteButton) {
      buttons.push(
        React.cloneElement(deleteButton, {
          className: cn('text-modal__delete', deleteButton.props.className),
          disabled: inputDisabled,
          key: 'delete',
        }),
      );
    }

    if (duplicateButton) {
      buttons.push(
        React.cloneElement(duplicateButton, {
          className: cn(
            'text-modal__duplicate',
            duplicateButton.props.className,
          ),
          disabled: inputDisabled || isSubmitBtnDisabled,
          key: 'duplicate',
          onClick: this.createSubmitHandler(duplicateButton.props.onClick),
        }),
      );
    }

    if (submitButton) {
      buttons.push(
        React.cloneElement(submitButton, {
          className: cn('text-modal__submit', submitButton.props.className),
          disabled: inputDisabled || isSubmitBtnDisabled,
          key: 'submit',
          onClick: this.createSubmitHandler(submitButton.props.onClick),
        }),
      );
    }

    return <Modal.FooterButtons>{buttons}</Modal.FooterButtons>;
  }

  private renderWorkspace(data, _, handlers, formatStyleRules) {
    const {
      background,
      editable,
      inputDisabled,
      workspaceAspectRatio,
    } = this.props;
    const { ui } = this.state;

    const { outerStyle: containerStyle } = splitStyle(
      data.get('containerStyle'),
    );
    const prefixedContainerStyle = prefix({
      ...formatStyleRules(containerStyle),
      visibility: !ui.get('toolbarInitialized') ? 'hidden' : 'visible',
    });

    const position = ui.get('isResizing')
      ? undefined
      : {
          x:
            data.getIn(['position', 'left']) === ''
              ? 0
              : data.getIn(['position', 'left']),
          y:
            data.getIn(['position', 'top']) === ''
              ? 0
              : data.getIn(['position', 'top']),
        };

    const size = {
      height: data.getIn(['size', 'height']),
      width: data.getIn(['size', 'width']),
    };
    const templateId = ui.get('selectedTemplateId');

    return (
      <TextWorkspace
        aspectRatio={workspaceAspectRatio}
        background={background}
        boxBorderClassName={cn('text-modal__box-border', {
          'text-modal__box-border--focus': ui.get('inputMode'),
        })}
        className="text-modal__workspace"
        disabled={inputDisabled}
        draggingEnabled={
          !ui.get('inputMode') && getDraggingEnabledByTemplateId(templateId)
        }
        onTextBoxDrag={this.handleTextBoxDrag(handlers.setPosition)}
        onTextBoxDragStop={this.handleTextBoxDragStop(handlers.setPosition)}
        onTextBoxResizeStart={this.handleTextBoxResizeStart}
        onTextBoxResizeStop={this.handleTextBoxResizeStop(handlers.setSize)}
        onWorkspaceSizeChange={this.handleWorkspaceSizeChange(
          handlers.setWorkspaceSize,
        )}
        resizeHandleClassName="text-modal__box-resize-handle"
        resizingEnabled={!ui.get('inputMode')}
        textBoxPosition={position}
        textBoxSize={size}
        resizableEdges={getResizableEdgesByTemplateId(templateId)}
      >
        <div className="text-modal__input" style={prefixedContainerStyle}>
          {!ui.get('inputMode') && (
            <div
              className="text-modal__input-readonly-overlay"
              onDoubleClick={
                editable ? this.handleTextBoxDoubleClick : undefined
              }
            />
          )}
          <div
            className="text-workspace__input"
            ref={el => {
              this.textarea = el;
            }}
          />
        </div>
      </TextWorkspace>
    );
  }

  private renderStyleForm(data, handlers) {
    const { inputDisabled } = this.props;
    const { textOverlay } = this.state;

    const containerDecoratorStyles = textOverlay
      .getIn(['containerDecorator', 'styles'], List())
      .toJS();

    const { x = 0, y = 0 } = scaleToGrid(
      data.getIn(['position', 'left']),
      data.getIn(['position', 'top']),
      data.getIn(['viewport', 'width']),
      data.getIn(['viewport', 'height']),
    );

    const position = {
      x: data.getIn(['position', 'left']) === '' ? ('' as '') : x,
      y: data.getIn(['position', 'top']) === '' ? ('' as '') : y,
    };

    const viewportWidth = data.getIn(['viewport', 'width']);
    const paddings = {
      bottom:
        data.getIn(['containerStyle', 'paddingBottom']) === ''
          ? ('' as '')
          : percentageOf(
              data.getIn(['containerStyle', 'paddingBottom']),
              viewportWidth,
            ),
      left:
        data.getIn(['containerStyle', 'paddingLeft']) === ''
          ? ('' as '')
          : percentageOf(
              data.getIn(['containerStyle', 'paddingLeft']),
              viewportWidth,
            ),
      right:
        data.getIn(['containerStyle', 'paddingRight']) === ''
          ? ('' as '')
          : percentageOf(
              data.getIn(['containerStyle', 'paddingRight']),
              viewportWidth,
            ),
      top:
        data.getIn(['containerStyle', 'paddingTop']) === ''
          ? ('' as '')
          : percentageOf(
              data.getIn(['containerStyle', 'paddingTop']),
              viewportWidth,
            ),
    };

    return (
      <TextBoxOptions className="text-modal__font-form">
        <TextBoxOptions.Time
          disabled={inputDisabled}
          endMillis={data.getIn(['time', 'endMillis'])}
          minDurationMillis={MIN_DURATION_MILLIS}
          startMillis={data.getIn(['time', 'startMillis'])}
          onChange={this.handleTimeChange(handlers)}
        />
        {/* TODO these styles should be given an id so we don't have to use array index as key */
        /* eslint-disable */
        containerDecoratorStyles.map((style, index) => (
          <TextBoxOptions.DynamicStyle
            dynamicStyle={style}
            key={index}
            onChange={this.handleDecoratorStyleChange(index)}
          />
        ))
        /* eslint-enable */
        }
        <TextBoxOptions.Background
          value={data.getIn(['containerStyle', 'background'])}
          onChange={handlers.setBackground}
        />
        <TextBoxOptions.LineSpacing
          value={data.getIn(['containerStyle', 'lineHeight'])}
          onChange={this.handleLineSpacingChange(handlers)}
          onBlur={this.handleLineSpacingBlur(handlers)}
        />
        <TextBoxOptions.Padding
          value={paddings}
          onChange={this.handlePaddingChange(handlers)}
          onBlur={this.handlePaddingBlur(handlers)}
        />
        <TextBoxOptions.Position
          value={position}
          onChange={this.handlePositionChange(handlers)}
          onBlur={this.handlePositionBlur(handlers)}
        />
      </TextBoxOptions>
    );
  }

  private renderStyleTabs(data, handlers, formatStyleRules) {
    const { styleTemplates } = this.props;

    return (
      <Tabs
        defaultActiveTabKey="textTemplateForm"
        id="text-modal-style-tabs"
        tabs={[
          {
            content: (
              <FadingScrollBars
                className="text-modal__fade-scroller"
                hideTracksWhenNotNeeded
              >
                <div className={styleTabContentClassName}>
                  <TextTemplateSelector
                    templates={styleTemplates}
                    onSelect={this.handleTemplateSelect(
                      data,
                      handlers.setData,
                      formatStyleRules,
                    )}
                  />
                </div>
              </FadingScrollBars>
            ),
            tabKey: 'textTemplateForm',
            title: 'styles',
          },
          {
            content: (
              <FadingScrollBars
                className="text-modal__fade-scroller"
                hideTracksWhenNotNeeded
              >
                <div className={tabContentClassName}>
                  {this.renderStyleForm(data, handlers)}
                </div>
              </FadingScrollBars>
            ),
            tabKey: 'textOptionsForm',
            title: 'custom',
          },
          {
            content: this.renderTransitionOptionsFormTab(tabContentClassName),
            tabKey: 'textTransitionsForm',
            title: 'transitions',
          },
        ]}
      />
    );
  }

  public render() {
    const {
      banner,
      defaultText,
      fonts,
      footerLeftSlot,
      onEmojiSelect,
      showEmoji,
      showStyleTabs,
      showPresetSelector,
      textOverlay,
    } = this.props;
    const { selectedPreset, ui } = this.state;

    const font =
      fonts.find(f => f.name === ui.get('selectedFontName')) || ({} as IFont);

    const colClassName = 'text-modal__col';
    const workspaceColClassName = cn(colClassName, 'text-modal__workspace-col');

    const fontFormColClassName = cn(colClassName, 'text-modal__font-form-col');

    const textEditorRef = el => {
      this.textEditor = el;
    };

    const overlayWidth = textOverlay.getIn(['viewport', 'width']);

    const workspaceColSize = getWorkspaceColSize(
      showStyleTabs,
      showPresetSelector,
    );

    return (
      <UseHook
        // preloads the fonts for its usage on standalone usages of the modal (i.e. advanced editor)
        hook={useCustomFontsLoader}
        args={[]}
      >
        {() => (
          <div>
            <FontLoader
              family={font.familyName}
              style={
                textOverlay.getIn(['containerStyle', 'fontStyle']) || 'normal'
              }
              url={font.url}
              weight={
                textOverlay.getIn(['containerStyle', 'fontWeight']) || 400
              }
            />
            <Modal.Body>
              <RndTextEditor
                data={
                  (convertOverlayToEditorData(
                    textOverlay,
                  ) as unknown) as RndTextEditorData
                }
                ref={textEditorRef}
                text={textOverlay.get('text')}
                render={({ data, text, handlers, formatStyleRules }) => {
                  const viewportWidth = data.getIn(['viewport', 'width']);
                  const { innerStyle: baseInlineStyle } = splitStyle(
                    data.get('containerStyle'),
                  );
                  const baseTextStyle = data.get('textStyle');

                  return (
                    <Modal.Row>
                      <Modal.Col
                        size={workspaceColSize}
                        className={workspaceColClassName}
                      >
                        {banner && (
                          <div className="text-modal__banner-container">
                            {banner}
                          </div>
                        )}
                        <RichTextEditor
                          bodyClassName="text-modal__inline-editor-body"
                          className="text-modal__inline-toolbar"
                          content={ui.get('textHtml')}
                          placeholderContent="Double click to add your text"
                          defaultContent={defaultText}
                          defaultIframeStyle={{ position: 'absolute' }}
                          disableIframe={!ui.get('inputMode')}
                          fonts={fonts}
                          formatters={{
                            fontsize: {
                              styleToToolbar: size =>
                                round(scale(size, viewportWidth, overlayWidth)),
                              toolbarToStyle: size =>
                                scale(size, overlayWidth, viewportWidth),
                            },
                          }}
                          onBlur={this.handleToolbarBlur}
                          onEmojiSelect={onEmojiSelect}
                          onInit={this.handleToolbarInit}
                          onContentChange={this.handleContentChange}
                          onPresetTargetStyleChanged={
                            this.handlePresetTargetStyleChange
                          }
                          defaultStyle={prefix(
                            formatStyleRules(baseInlineStyle),
                          )}
                          defaultTextStyle={prefix(
                            formatStyleRules(baseTextStyle),
                          )}
                          ref={el => {
                            this.toolbar = el;
                          }}
                          showEmoji={showEmoji}
                          target={this.textarea}
                        />
                        <div className="text-modal__workspace-container">
                          {this.renderWorkspace(
                            data,
                            text,
                            handlers,
                            formatStyleRules,
                          )}
                        </div>
                      </Modal.Col>

                      {showStyleTabs && (
                        <Modal.Col
                          size={STYLE_TABS_COL_SIZE}
                          className={fontFormColClassName}
                        >
                          {this.renderStyleTabs(
                            data,
                            handlers,
                            formatStyleRules,
                          )}
                        </Modal.Col>
                      )}
                      {showPresetSelector && (
                        <Modal.Col
                          size={STYLE_PRESETS_COL_SIZE}
                          className={block('presets-col')}
                        >
                          <TextPresetSelector
                            onPickPreset={this.handlePresetSelect(
                              formatStyleRules,
                            )}
                            selectedPreset={selectedPreset}
                          />
                        </Modal.Col>
                      )}
                    </Modal.Row>
                  );
                }}
              />
            </Modal.Body>

            <Modal.Footer>
              {isFunction(footerLeftSlot)
                ? footerLeftSlot({
                    partialSubmitHandlerBuilder: this.createSubmitHandler,
                  })
                : footerLeftSlot}
              {this.renderFooterButtons()}
            </Modal.Footer>
          </div>
        )}
      </UseHook>
    );
  }
}

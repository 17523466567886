import { Tier } from 'redux/middleware/api/plan-service/types';
import bem from 'utils/bem';
import { DowngradePath, ModalConfig } from './types';

export const block = bem('plan-downgrade-modal');

export function getModalConfig({
  fromTier,
  toTier,
}: DowngradePath): ModalConfig {
  if (fromTier === Tier.PRO && toTier === Tier.BASIC) {
    return {
      heading: 'Give up unlimited videos?',
      subheading:
        'If you downgrade you’ll give up your unlimited unwatermarked videos.',
      callouts: [
        {
          key: 'free',
          action: 'cancelPlan',
          ctaLabel: 'downgrade to free',
          isPro: false,
          toVideos: 1,
        },
        {
          key: 'basic',
          action: 'downgrade',
          ctaLabel: 'downgrade to basic',
          isPro: false,
          toVideos: 10,
        },
        {
          key: 'current',
          action: 'closeModal',
          ctaLabel: 'keep headliner pro',
          isPro: true,
          toVideos: Infinity,
        },
      ],
    };
  }

  return undefined;
}

import { ShareVideoOptions } from 'redux/middleware/api/podcast-service';
import { shareVideo } from 'redux/middleware/api/podcast-service/actions';
import { getSocialProfile } from 'redux/middleware/api/third-party-authentication-service/actions';
import { GetSocialProfileAction } from 'redux/middleware/api/third-party-authentication-service/types';
import { isShareStatusResolved } from 'redux/middleware/api/video-export-service';
import { getSocialShare } from 'redux/middleware/api/video-export-service/actions';
import {
  onTwitterSubmitForUpload,
  onTwitterVideoUploadedSuccessfully,
} from 'redux/modules/mixpanel';
import { showNotification } from 'redux/modules/notification/actions';
import { ThunkAction } from 'redux/types';
import { SocialSharePlatform } from 'types/common';
import { VIDEO_EXPORT_POLL_MAX_ATTEMPTS } from 'utils/constants';
import reduxPoll from 'utils/redux-poll';
import { Type } from './action-types';
import {
  twitterSelector,
  twitterSocialShareIdSelector,
  twitterSocialSharesSelector,
  twitterUserDataSelector,
} from './selectors';
import { TwitterAuthorizeSuccessAction, TwitterPostMessageData } from './types';

const TWITTER_POST_ID = 'app/twitter/post-poll';
const TWITTER_PROFILE_ID = 'app/twitter/profile-poll';

export const getTwitterUser = (
  response: GetSocialProfileAction,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.TWITTER_USER_DATA_GET_REQUEST });

  try {
    dispatch({
      payload: response,
      type: Type.TWITTER_USER_DATA_GET_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: new Error('Error getting user data'),
      type: Type.TWITTER_USER_DATA_GET_FAILURE,
    });

    throw error;
  }
};

export const clearTwitterUser = () => ({
  type: Type.TWITTER_USER_DATA_CLEAR,
});

const showSuccessNotification = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const { sharePostUrl } = twitterSocialSharesSelector(getState());

  dispatch(
    showNotification(
      'You can see your tweet {{link}}',
      'success',
      0,
      'link',
      'Your video was tweeted',
      undefined,
      sharePostUrl,
      'here',
    ),
  );
};

const showErrorNotification = () =>
  showNotification({
    message: 'Please try again or {{link}} so we can help',
    code: 'ER013',
    level: 'error',
    type: 'help',
    title: "Sorry, we couldn't tweet your video",
    actionLabel: 'contact us',
  });

const waitForTwitterPost = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  dispatch({ type: Type.TWITTER_VIDEO_POST_AWAIT_REQUEST });

  const socialShareId = twitterSocialShareIdSelector(getState());

  try {
    await reduxPoll(dispatch, () => dispatch(getSocialShare(socialShareId)), {
      id: TWITTER_POST_ID,
      intervalMillis: 3000,
      maxAttempts: VIDEO_EXPORT_POLL_MAX_ATTEMPTS,
      shouldContinue: err => {
        if (err) return false;

        const share = twitterSocialSharesSelector(getState());

        return isShareStatusResolved(share, ['uploaded']);
      },
    });

    dispatch({
      type: Type.TWITTER_VIDEO_POST_AWAIT_SUCCESS,
    });

    dispatch(showSuccessNotification());
    dispatch(onTwitterVideoUploadedSuccessfully());
  } catch (err) {
    dispatch({ type: Type.TWITTER_VIDEO_POST_AWAIT_FAILURE });

    throw err;
  }
};

export const waitForTwitterProfile = (
  twitterUserId?: string,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.TWITTER_USER_DATA_GET_AWAIT_REQUEST });

  try {
    await reduxPoll(
      dispatch,
      async () => {
        const {
          providerUserId = twitterUserId,
          idToken,
          accessToken,
          accessTokenSecret,
        } = twitterUserDataSelector(getState());
        const { response } = await dispatch(
          getSocialProfile(
            SocialSharePlatform.TWITTER,
            providerUserId,
            idToken,
            accessToken,
            accessTokenSecret,
          ),
        );

        if (response) {
          dispatch(getTwitterUser(response));
        }
      },
      {
        id: TWITTER_PROFILE_ID,
        intervalMillis: 3000,
        maxAttempts: VIDEO_EXPORT_POLL_MAX_ATTEMPTS,
        shouldContinue: err => {
          if (err) return false;

          const { username } = twitterSelector(getState());

          return username === undefined;
        },
      },
    );

    dispatch({
      type: Type.TWITTER_USER_DATA_GET_AWAIT_SUCCESS,
    });
  } catch (err) {
    dispatch({ type: Type.TWITTER_USER_DATA_GET_AWAIT_FAILURE });

    throw err;
  }
};

export const postTwitterVideo = (
  embedVideoId: string,
  opts: ShareVideoOptions,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.TWITTER_VIDEO_POST_REQUEST });

  const { accessToken, accessTokenSecret } = twitterUserDataSelector(
    getState(),
  );

  dispatch(onTwitterSubmitForUpload());

  try {
    const {
      response: { socialShareId },
    } = await dispatch(
      shareVideo(embedVideoId, SocialSharePlatform.TWITTER, accessToken, {
        ...opts,
        accessTokenSecret,
      }),
    );

    dispatch({
      payload: { socialShareId },
      type: Type.TWITTER_VIDEO_POST_SUCCESS,
    });

    await dispatch(waitForTwitterPost());
  } catch (err) {
    dispatch({
      error: err,
      type: Type.TWITTER_VIDEO_POST_FAILURE,
    });
    dispatch(showErrorNotification());
  }
};

export const twitterAuthorizeSuccess = (
  data: Pick<
    TwitterPostMessageData['tokenInfo'],
    'accessToken' | 'accessTokenSecret' | 'idToken' | 'providerUserId'
  >,
): TwitterAuthorizeSuccessAction => ({
  type: Type.TWITTER_AUTHORIZE_SUCCESS,
  payload: data,
});

import React from 'react';
import { isEqual } from 'underscore';

import { useTextOverlay } from '../state/TextOverlayProvider';
import {
  scaleEditorDataPosition,
  scaleEditorDataSize,
  unscaleCanvasPosition,
  unscaleCanvasSize,
} from '../style-transform-utils';
import { Position, Rect, Size } from '../types';

interface UseTextOverlayPosition {
  isResizing: boolean;
  onDrag: (nextPos: Position) => void;
  onDragStop: (nextPos: Position) => void;
  onResizeStart: () => void;
  onResizeStop: (rect: Rect) => void;
  position: Position;
  size?: Size;
}

const useTextOverlayPosition = (): UseTextOverlayPosition => {
  const { canvasSize, draftEditorData, onUpdateOverlay } = useTextOverlay();

  const unscaledPosition = {
    x: draftEditorData?.getIn(['position', 'left']),
    y: draftEditorData?.getIn(['position', 'top']),
  };

  const unscaledSize = {
    height: draftEditorData?.getIn(['size', 'height']),
    width: draftEditorData?.getIn(['size', 'width']),
  };

  const [isResizing, setIsResizing] = React.useState(false);

  // by using a draft position the performance of the dragging operation
  // is heavily increased. The draftPosition dictates what the user can see
  // at the preview pane, and the changes are only commited to the global
  // draft state when the dragging stops.
  const [draftPosition, setDraftPosition] = React.useState(
    scaleEditorDataPosition(unscaledPosition, draftEditorData, canvasSize),
  );

  const [prevCanvasSize, setPrevCanvasSize] = React.useState(canvasSize);

  if (!isEqual(prevCanvasSize, canvasSize)) {
    setPrevCanvasSize(canvasSize);
    setDraftPosition(
      scaleEditorDataPosition(unscaledPosition, draftEditorData, canvasSize),
    );
  }

  const handleDrag = React.useCallback((nextPos: Position) => {
    setDraftPosition(nextPos);
  }, []);

  const handleDragStop = React.useCallback(
    (nextPos: Position) => {
      setDraftPosition(nextPos);
      onUpdateOverlay(
        overlay =>
          overlay.withMutations(s => {
            const unscaledNextPos = unscaleCanvasPosition(
              nextPos,
              overlay,
              canvasSize,
            );
            s.setIn(['position', 'left'], unscaledNextPos.x).setIn(
              ['position', 'top'],
              unscaledNextPos.y,
            );
            return s;
          }),
        true,
      );
    },
    [canvasSize, onUpdateOverlay],
  );

  const handleResizeStart = React.useCallback(() => {
    setIsResizing(true);
  }, []);

  const handleResizeStop = React.useCallback(
    ({ height, width, x, y }: Rect) => {
      onUpdateOverlay(overlay => {
        setDraftPosition({ x, y });
        const unscaledNextPos = unscaleCanvasPosition(
          { x, y },
          overlay,
          canvasSize,
        );
        const unscaledNextSize = unscaleCanvasSize(
          { height, width },
          overlay,
          canvasSize,
        );
        return overlay.withMutations(s =>
          s
            .setIn(['position', 'left'], unscaledNextPos.x)
            .setIn(['position', 'top'], unscaledNextPos.y)
            .setIn(['size', 'height'], unscaledNextSize.height)
            .setIn(['size', 'width'], unscaledNextSize.width),
        );
      }, true);
      setIsResizing(false);
    },
    [canvasSize, onUpdateOverlay],
  );

  const size = isResizing
    ? undefined
    : scaleEditorDataSize(unscaledSize, draftEditorData, canvasSize);

  return {
    isResizing,
    onDrag: handleDrag,
    onDragStop: handleDragStop,
    onResizeStart: handleResizeStart,
    onResizeStop: handleResizeStop,
    position: draftPosition,
    size,
  };
};

export default useTextOverlayPosition;

import { SocialShareScope } from '@sparemin/auth';
import { LocationDescriptor } from 'history';
import { RecordOf } from 'immutable';
import { DestinationStepView } from 'blocks/AutomationOptions/types';
import {
  DestinationPlatform,
  VideoTypeConfig,
} from 'blocks/DestinationPlatforms';
import { CustomizeStepSubmitType } from 'containers/ProjectWizards/AudiogramWizard/AudiogramCustomizeStep';
import {
  FacebookPostType,
  InstagramMediaType,
  PodcastFeedEpisode,
  YoutubeAssetInfoType,
} from 'redux/middleware/api/podcast-service';
import { TikTokPrivacyLevelOptionName } from 'redux/middleware/api/third-party-authentication-service/types';
import {
  MixpanelAudiogramDestination,
  MixpanelAudiogramVideoTypes,
  MixpanelVideoWizardVideoTypes,
} from 'redux/modules/mixpanel/types';

export type AspectRatioName = 'landscape' | 'portrait' | 'square';
export interface AspectRatioDimensions {
  height: number;
  width: number;
}

export type Asset = 'audio' | 'video' | 'text';

/**
 * NOTE: using a type different from Asset above becuase captions might be created from
 * non-asset sources.  In this case "text" isn't a text asset on the timeline but a blob of text
 * entered by the user.  We might also need to support something like "url" here in the future.
 */
export type CaptionsMediaSourceType = 'audio' | 'video' | 'text';

export type SoundwaveType =
  | 'none'
  | 'linearDots'
  | 'blob'
  | 'pulse'
  | 'burst'
  | 'waveBars'
  | 'wave'
  | 'roundBars'
  | 'line'
  | 'curve'
  | 'bricks'
  | 'pixel'
  | 'equalizer'
  | 'wideRoundBars';

export type SoundwavePositionValue =
  | 'bottom'
  | 'custom'
  | 'middle'
  | 'lower-third'
  | 'padded-bottom'
  | 'top';

export type SoundWaveGeneration = 'amplitudeBased' | 'accurate';

export type SoundWaveGenerationValue = 'fast' | 'slow';

export interface Soundwave {
  waveColor?: string;
  waveGeneration?: SoundWaveGeneration;
  waveType?: SoundwaveType;
  waveSecondaryColor?: string;
  waveSize?: Size;
  wavePosition?: Position;
  waveformPrefId?: number;
  startMilli?: number;
  endMilli?: number;
}

export interface SoundwaveOption {
  value: string;
  displayName: string;
}

export type SoundwavePositionId =
  | 'prisa-default'
  | 'top'
  | 'middle'
  | 'bottom'
  | 'padded-bottom'
  | 'lower-third'
  | 'custom';

export type TrackType = 'text' | 'media' | 'audio' | 'waveform';
export type AudioTrackType = 'main' | 'background';
export type TrackAssetType =
  | 'text'
  | 'image'
  | 'video'
  | 'gif-video'
  | 'audio'
  | 'waveform';

export enum ProgressType {
  BAR = 'twoColorBar',
  PLAYER = 'player',
}

export interface Timing {
  endMillis: number;
  startMillis: number;
}

export interface AudioClipOffsets extends Timing {
  originalDurationMillis: number;
}

export interface AudioClip extends Timing {
  file: File | string;
  source: AudioBuffer;
}

export type PurchasePlatform = 'stripe' | 'itunesAppStore' | 'playStore';

export enum RequestStatus {
  REQUEST = 'request',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export type ReadyState = 'idle' | 'loading' | 'ready' | 'error';

export type PropUpgradeFrom =
  | 'Priority Queue Upgrade'
  | 'Add Intro'
  | 'Add Outro';

export type ExportUpgradeFrom =
  | 'topBanner'
  | 'button'
  | 'topBannerBasicUser'
  | 'buttonBasicUser'
  | 'topBannerProUser'
  | 'buttonProUser'
  | 'UCS'
  | 'UCSFullEpisode';

export type Unlock1080pSource = 'FullEpisodeWizard';

export type ProjectLength = 'long' | 'short' | 'both';

export type AudioFadeLength = 'none' | 'long' | 'short';

export interface Position<T = string | number, L = T> {
  left: L;
  top: T;
}

export interface Size<H = string | number, W = H> {
  height: H;
  width: W;
}

export type Dimensions<T = string | number, L = T, H = T, W = L> = Position<
  T,
  L
> &
  Size<H, W>;

export interface ProgressAnimationOptions<H = string | number, W = H>
  extends Size<W, H>,
    Position<W, H> {
  enabled: boolean;
  color: string;
  fillColor: string;
  type?: ProgressType;
}

export interface TimerOptions<H = any, W = H> {
  color: string;
  enabled: boolean;
  fontSize: H;
  position: Position<H, W>;
  // to prevent "size" key collision on immutable records
  timerSize: Size<H, W>;
}

export interface ProgressAndTimerOptions<H = string | number, W = H> {
  progress: ProgressAnimationOptions<H, W>;
  timer: TimerOptions<string>;
}

export type ProgressAnimationOptionsState = RecordOf<ProgressAnimationOptions>;

export type TimerOptionsState = RecordOf<TimerOptions<string>>;

export interface Query {
  [key: string]: string | string[];
}

export interface AnyObject {
  [k: string]: any;
}

export type WizardType =
  | 'audio'
  | 'audiogram'
  | 'automated'
  | 'editAutoVideo'
  | 'episode'
  | 'textToVideo'
  | 'videoTranscription'
  | 'autoVideosMVP'
  | 'templateWizard'
  | 'videoClipWizard';

export interface ClippingOption {
  clipStart: number;
  clipEnd: number;
  clipDuration: number;
  clipped: boolean;
}

export type AudioSourceType = 'upload' | 'podcast' | 'sample' | 'soundbite';

export type WizardPathType = 'BackCatalogAutomation' | 'ManualCreation';

export type AutoPostOptions = 'YouTube' | 'None';

export enum FileSource {
  UPLOAD,
  PODCAST,
  LIBRARY,
}

export interface CompleteWizardProps {
  audioSource?: AudioSourceType;
  autoPost?: AutoPostOptions;
  aspectRatio?: AspectRatioName;
  type: WizardType;
  addedText?: boolean;
  addedImage?: boolean;
  autopostPlatform?: AutoPostPlatform;
  backgroundColor?: string;
  canvaImage?: boolean;
  clipStart?: number;
  clipEnd?: number;
  clipDuration?: number;
  clipped?: boolean;
  enabledProgressBar?: boolean;
  frequency?: AutogramFrequency;
  length?: ProjectLength;
  originalAudioDuration?: number;
  platform?: AutogramMixpanelPlatform | VideoWizardMixpanelPlatform;
  videoType?: MixpanelAudiogramVideoTypes | MixpanelVideoWizardVideoTypes;
  templateId?: string;
  waveColor?: string;
  waveGeneration?: SoundWaveGenerationValue;
  wavePosition?: SoundwavePositionValue;
  waveStyle?: SoundwaveType;
  waveformPrefId?: number;
  transcription?: boolean;
  submissionType?: CustomizeStepSubmitType;
  isAutoSelect?: boolean;
  clipSelectionMethod?: AutogramClipSelectionMethod;
  blurValue?: number;
  captions?: boolean;
  videoPodcastType?: VideoPodcastType;
}

export interface OnAddAudioProps {
  clipStart: number;
  clipEnd: number;
  clipDuration: number;
  clipped: boolean;
  originalAudioDuration: number;
  waveGeneration: SoundWaveGenerationValue;
}

export interface PodcastIdentifier {
  podcastId: string;
  episodeId: string;
}

/*
 * metadata that gets passed around when Audio is added.  If the audio came from
 * PodcastSearch, podcastId and possibly episodeId will be defined.  If the audio
 * was added via Descript link, transcriptUrl will be defined
 */
export interface AddAudioMeta extends Partial<PodcastIdentifier> {
  transcriptUrl?: string;
  originalAudioUrl?: string;
}

export type AutogramMixpanelPlatform =
  | MixpanelAudiogramDestination
  | 'InstagramStories'
  | 'InstagramLinkedIn'
  | 'X'
  | 'Facebook'
  | 'YouTube';

export type VideoWizardMixpanelPlatform = DestinationPlatform | 'None';

export type AutogramMixpanelEditSource =
  | 'audio selection'
  | 'output'
  | 'starting point';

export type AutogramAutopostPlatform = 'None' | 'YouTube';

export type AutogramFrequency =
  | 'daily'
  | 'weekly'
  | 'onPublish'
  | 'fromFirstEpisode'
  | 'targetedSeason';

export enum VideoTypes {
  FULL_EPISODE = 'fullEpisode',
  RANDOM_CLIP = 'randomClip',
  SHORT_CLIP = 'shortClip',
}

export type AutogramVideoType =
  | VideoTypes.FULL_EPISODE
  | VideoTypes.RANDOM_CLIP;

export type CompatibilityVideoType =
  | VideoTypes.FULL_EPISODE
  | VideoTypes.SHORT_CLIP;

export enum AutogramClipSelectionMethod {
  MANUAL = 'manual',
  SMART = 'smart',
  SOUNDBITE_TAG = 'soundbiteTag',
}

export type AutoPostPlatform = AudiogramDestination;

export type VideoPodcastType = 'SingleClip' | 'MultipleClips' | 'FullEpisode';

export interface AutogramSubscriptionOptions {
  autopost: {
    defaultLanguage?: string;
    googleId?: string;
    visibility?: YoutubeVideoVisibility;
    title?: string;
    description?: string;
    tags?: string[];
    madeForKids?: boolean;
    categoryId?: number;
    platform?: AutoPostPlatform;
    playlists?: string[];
    tiktokUserId?: string;
    tiktokPrivacyLevel?: TikTokPrivacyLevelOptionName;
    commentEnabled?: boolean;
    duetEnabled?: boolean;
    stitchEnabled?: boolean;
    brandContentToggle?: boolean;
    brandOrganicToggle?: boolean;
    instagramUserId?: string;
    instagramMediaType?: InstagramMediaType;
    linkedinUserId?: string;
    linkedinAuthorId?: string;
    postType?: FacebookPostType;
    facebookPageId?: string;
    facebookId?: string;
    assetInfo?: YoutubeAssetInfoType;
    twitterUserId?: string;
    threadsUserId?: string;
    scopeContext?: SocialShareScope;
  };
  durationSeconds: number;
  frequency: AutogramFrequency;
  targetedSeasons?: number[];
  isCaptionEnabled: boolean;
  videoType: AutogramVideoType;
  clipSelectionMethod?: AutogramClipSelectionMethod;
}

export enum TemplateType {
  HEADLINER_DEFAULT = 'headlinerDefault',
  USER_GENERATED = 'userGenerated',
  ORIGINAL = 'headlinerDefaultOriginal',
}

export type ProjectCreationMethod =
  | 'unknown'
  | 'blankProject'
  | 'recommended'
  | 'audiogram'
  | 'copy'
  | 'sample'
  | 'videoTranscript'
  | 'videoTranscriptEpisode'
  | 'videoTranscriptClipSuggestion'
  | 'textToVideo'
  | 'episode'
  | 'audio'
  | 'audioLong'
  | 'template'
  | 'templateLong'
  | 'podcastFeedAutoCreate'
  | 'podcastFeedAutoCreateEpisode';

export type HeadlinerUsage =
  | 'education'
  | 'largeCompany'
  | 'nonprofit'
  | 'personal'
  | 'smallBusiness';

export interface PreviewVideo {
  videoUrl: string;
  mediaType: string;
}
export type YoutubeVideoVisibility = 'private' | 'public' | 'unlisted';
export type YoutubeVideoPlaylist = string;
export type YouTubeVideoPostType = 'youtubeDefault' | 'youtubeShorts';

export type LinkedinVideoVisibility = 'public';

export type MobilePlatform = 'Android' | 'iOS';

export interface Identifiable<T extends string | number = string | number> {
  id: T;
}

export enum PlanCancellationReason {
  DONT_RUN_OUT_OF_VIDEOS = 'dont run out of videos',
  ANOTHER_VIDEO_EDITOR = 'another video editor',
  NOT_ENOUGH_FEATURES = 'not enough features',
  CANT_AFFORD = 'can’t afford',
  FILE_NOT_SUPPORTED = 'file not supported',
  TECHNICAL_PROBLEMS = 'technical problems',
  OTHER = 'other',
}

export type PodcastSearchIntegration = 'descript';

export type PodcastSearchIntegrationStatus = {
  [k in PodcastSearchIntegration]: boolean;
};

export enum CaptionFormat {
  VTT = 'vtt',
  SRT = 'srt',
  PDF = 'pdf',
  TXT = 'txt',
  DOC = 'docx',
}

export type FitType = 'fit' | 'fill';

export type ProgressSizeId = 'small' | 'medium' | 'large' | 'custom';
export type ProgressPositionId = 'top' | 'middle' | 'bottom' | 'custom';

export interface ProgressSizeOption {
  value: ProgressSizeId;
  displayName: string;
}
export type LayerOrderType = 'grouped' | 'free';

export type TranscriptionTimeBankUpgradeSource =
  | 'project'
  | 'wizard'
  | 'ClipSelectionPage';

export interface WatermarkOption {
  position: Position;
  size: Size;
  url: string;
  displayName: string;
}

export type AudioVariationType =
  | 'randomClip'
  | 'fullEpisode'
  | 'userClip'
  | 'userPreSelectClip'
  | 'soundbiteTag';

export enum ImageOriginId {
  CANVA = 'canva',
}

export enum SocialSharePlatform {
  TWITTER = 'twitter',
  FACEBOOK = 'facebook',
  YOUTUBE = 'youtube',
  INSTAGRAM = 'instagram',
  THREADS = 'threads',
  LINKEDIN = 'linkedin',
  TIKTOK = 'tiktok',
}

export type AuthenticationProvider =
  | 'apple'
  | 'headliner'
  | 'google-sign-in'
  | 'google-one-tap';

export type ClipSelectPageSource =
  | 'PreSelect'
  | 'ReClip'
  | 'Email'
  | 'DownloadPageButton';

export enum ClipSuggestionDislikeReason {
  Advertisement = 'advertisement',
  NotInteresting = 'notInteresting',
  CutsOffMidSentence = 'cutsOffMidSentence',
  Other = 'other',
}

export type TemplateThumbnailStatus =
  | 'queued'
  | 'processing'
  | 'completed'
  | 'error'
  | 'errorAck';

export interface TemplateThumbnail {
  url?: string;
  status?: TemplateThumbnailStatus;
}

export type DownloadCaptionsModalType = 'editor' | 'episode';

export interface CMSEpisode extends PodcastFeedEpisode {
  manualTriggerStatus: RequestStatus;
  thumbnailImageUrl: string;
}

/**
 * Autogram frequencies that are exposed to the user in the application.
 *
 * The API accepts various values for the autogram frequency field, however the user
 * is only aware of what we expose to them in the app.
 */
export type SelectableAutogramFrequency = Extract<
  AutogramFrequency,
  'onPublish' | 'fromFirstEpisode' | 'targetedSeason'
>;

export type ValueOf<T> = T[keyof T];

export type AudiogramDestination =
  | 'youtube'
  | 'instagram'
  | 'facebook'
  | 'tiktok'
  | 'linkedin'
  | 'threads'
  | 'twitter';

export type SinglePathAudiogramDestination = Extract<
  AudiogramDestination,
  'twitter' | 'linkedin'
>;

export type MultiPathAudiogramDestination = Extract<
  AudiogramDestination,
  'youtube' | 'instagram' | 'facebook' | 'tiktok' | 'threads'
>;

export interface AutogramWizardOptions {
  destination: DestinationStepView | null;
  videoType: AutogramVideoType;
  clipDuration?: number | null;
  aspectRatioName: AspectRatioName;
}

export interface AudiogramVideoTypeConfigMetadata {
  as: React.ReactNode;
}

export interface AudiogramVideoTypeConfig
  extends VideoTypeConfig<AudiogramVideoTypeConfigMetadata> {
  destination: LocationDescriptor;
  autogramWizardSettings?: AutogramWizardOptions;
}

export interface AudiogramDestinationConfig {
  id: MultiPathAudiogramDestination;
  icon: React.ReactNode;
  videoTypes: AudiogramVideoTypeConfig[];
}

export type DownloadPageEventType = 'downloadPageAction';

export interface ScaleFactors {
  scaleX: number;
  scaleY: number;
}

export type SocialPostCaptionInfoPlatform =
  | 'linkedin'
  | 'instagram'
  | 'facebook'
  | 'twitter'
  | 'tiktok'
  | 'youtube'
  | 'threads';

export type SocialPostCaptions = {
  platform: SocialPostCaptionInfoPlatform;
  postText: string;
};

import { isFinite, isUndefined } from 'underscore';
import dayjs, { Dayjs } from 'utils/dayjs';

import { divideEvenly, int } from './numbers';

const MINUTE_IN_SECONDS = 60;

export function isAfterNow(test?: Dayjs): boolean {
  if (!test) {
    return undefined;
  }

  const now = dayjs.utc();
  return test.isAfter(now);
}

export function secToMillis(sec: number) {
  return isUndefined(sec) ? undefined : sec * 1000;
}

export function millisToSec(millis: number) {
  return isUndefined(millis) ? undefined : millis / 1000;
}

export function millisToHours(millis: number): number {
  return dayjs.duration(millis).asHours();
}

export function secToHours(seconds?: number) {
  return isUndefined(seconds) ? undefined : seconds / 3600;
}

export function hoursToSec(hours?: number) {
  return isUndefined(hours) ? undefined : hours * 3600;
}

export interface FormatDurationOptions {
  hour?: '2-digit' | 'numeric';
  minute?: '3-digit' | '2-digit' | 'numeric';
  second?: '2-digit' | 'numeric';
  millisecond?: '3-digit';
  asSeconds?: boolean;
  trim?: boolean;
}

const hourToken: Record<FormatDurationOptions['hour'], string> = {
  '2-digit': 'HH',
  numeric: 'H',
};

const minuteToken: Record<FormatDurationOptions['minute'], string> = {
  '3-digit': '',
  '2-digit': 'mm',
  numeric: 'm',
};

const secondToken: Record<FormatDurationOptions['second'], string> = {
  '2-digit': 'ss',
  numeric: 's',
};

const millisecondToken: Record<FormatDurationOptions['millisecond'], string> = {
  '3-digit': 'SSS',
};

function formatDurationPart(value: number, maxLength: number) {
  return `${Math.floor(value)}`.padStart(maxLength, '0');
}

export function formatDurationMillis(
  value: number,
  options: FormatDurationOptions = {
    trim: true,
    asSeconds: false,
  },
) {
  if (!isFinite(value)) return '';

  const millis = Math.round(value);

  const parts = [];
  const duration = dayjs.duration(millis);
  const { hour, minute, second, millisecond, trim, asSeconds } = options;
  if (
    hour &&
    (trim === false || (!minute && !second) || duration.hours() > 0)
  ) {
    parts.push(hourToken[hour]);
  }

  if (
    minute &&
    (parts.length || !second || trim === false || duration.minutes() > 0)
  ) {
    if (minute === '3-digit') {
      parts.push(formatDurationPart(duration.asMinutes(), 2));
    } else {
      parts.push(minuteToken[minute]);
    }
  }

  if (second) {
    parts.push(secondToken[second]);
  }

  let format = parts.join(':');

  if (millisecond) {
    format = [format, millisecondToken[millisecond]].filter(Boolean).join('.');
  }

  if (asSeconds) {
    return `${duration.asSeconds()} sec`;
  }

  return duration.format(format);
}

export function formatDurationSeconds(
  seconds: number,
  options: FormatDurationOptions,
): string {
  return formatDurationMillis(seconds * 1000, options);
}

function numToString(n: number) {
  if (n < 10) {
    return `0${n}`;
  }
  return n.toString();
}

/**
 * specifically formats millis to mm:ss:SS which is the format used for the
 * timeline
 */
export function formatTimelineMillis(millis: number) {
  const sec = millis / 1000;
  const { value: minutes, remainder } = divideEvenly(sec, MINUTE_IN_SECONDS);

  const minStr = numToString(minutes);
  const [secStr, fractStr] = remainder
    .toFixed(2)
    .split('.')
    .map(d => (d.length === 1 ? `0${d}` : d));

  return `${minStr}:${secStr}:${fractStr}`;
}

/**
 * Formats duration millis to its main unit (hour, minute or second) and creates
 * a short string with the format [main_unit] [hour|min|sec]
 * @param {number} durationMs Duration expressed in milliseconds
 * @returns {string} Formatted string
 */
export const formatDurationToRoundedStr = (durationMs: number) => {
  const duration = dayjs.duration(durationMs, 'millisecond');
  const hours = duration.asHours();
  const mins = Math.floor(duration.asMinutes());
  let format = '';

  if (hours >= 1) {
    format = 'H [hour]';
  } else if (mins >= 1) {
    format = 'm [min]';
  } else {
    format = 's [sec]';
  }

  return duration.format(format);
};

/**
 * Formats a duration in milliseconds to a full, readable format.
 * Omits any time components (hours, minutes, seconds) that are equal
 * to zero from the final result.
 *
 * Examples of possible formats:
 * - "1 hour, 2 min and 3 sec"
 * - "2 min and 3 sec"
 * - "3 sec"
 *
 * @param {number} durationMs Duration expressed in milliseconds.
 *
 * @returns {string} Formatted string.
 */
export const formatDurationInFull = (durationMs: number) => {
  const duration = dayjs.duration(durationMs, 'millisecond');

  const hours = duration.format('H [hour]');
  const minutes = duration.format('m [min]');
  const seconds = duration.format('s [sec]');
  const timeComponents = [];

  if (int(hours) > 0) {
    timeComponents.push(hours);
  }

  if (int(minutes) > 0) {
    timeComponents.push(minutes);
  }

  if (int(seconds) > 0) {
    timeComponents.push(seconds);
  }

  return (
    timeComponents
      // Replace the last comma with 'and'.
      .join(timeComponents.length === 2 ? ' and ' : ', ')
      .replace(/, ([^,]*)$/, ' and $1')
  );
};

import React, { useCallback, useRef } from 'react';

import { createChainedFunction } from 'utils/functions';
import ClipCtaButton, { ClipCtaButtonProps } from '../components/ClipCtaButton';
import { useClipSelect } from './ClipSelectContext';
import { useClipSelectNavigation } from './ClipSelectNavigationContext/ClipSelectNavigationContext';
import { useClipSelectTracking } from './ClipSelectTrackingContext';

export interface AddToVideoButtonProps
  extends Pick<
    ClipCtaButtonProps,
    'disabled' | 'id' | 'theme' | 'tooltip' | 'icon' | 'children' | 'className'
  > {
  onClick: () => void;
}

const AddToVideoButton: React.FC<AddToVideoButtonProps> = ({
  disabled,
  id,
  theme,
  tooltip,
  icon,
  children,
  className,
  onClick,
}) => {
  const { isClipSuggestionLoading, hasClipSuggestions } = useClipSelect();
  // The ref is used to retain the initial value of hasClipSuggestions.
  // This ensures that if any new clip is added, there is a way to determine
  // the state when the component was mounted. It is primarily useful in
  // scenarios where there's no clip suggestions, as we do not want to
  // close the clipper after submission.
  const initialHasClipSuggestions = useRef(hasClipSuggestions);
  const { onAddClip } = useClipSelectTracking();
  const [, send] = useClipSelectNavigation();

  const handleSubmit = useCallback(() => {
    if (initialHasClipSuggestions.current) {
      send({ type: 'EDITOR_CLOSE' });
    }

    onClick();
  }, [onClick, send]);

  return (
    <ClipCtaButton
      disabled={disabled || isClipSuggestionLoading}
      onClick={createChainedFunction(handleSubmit, onAddClip)}
      {...{ icon, disabled, id, theme, tooltip, className }}
    >
      {children}
    </ClipCtaButton>
  );
};

export default AddToVideoButton;

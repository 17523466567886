import cn from 'classnames';
import React, { useCallback } from 'react';
import useObjectUrl from 'hooks/useObjectUrl';
import { useEditorDispatch } from './context/VideoEditorDispatchContext';
import Tile from './Tile';
import useVideo from './useVideo';
import { imageViewBlock as block } from './utils';

interface Props {
  className?: string;
  disabled?: boolean;
  id: string;
  onClick(id: string): void;
}

function VideoChildTile(props: Props) {
  const { id, className, disabled, onClick } = props;

  const editorDispatch = useEditorDispatch();
  const slide = useVideo(id);

  const imageSrc = useObjectUrl(slide.original.previewThumbnail?.url);

  const handleClick = useCallback(
    (tileId: string): void => {
      onClick(tileId);

      editorDispatch({
        type: 'ASSET_SELECT',
        payload: { id: tileId },
      });
    },
    [editorDispatch, onClick],
  );

  return (
    <Tile
      disabled={disabled}
      onClick={handleClick}
      params={id}
      className={cn(className, block('image-child-tile'))}
    >
      <img style={{ height: '100%' }} src={imageSrc} alt={slide.fileName} />
    </Tile>
  );
}

export default VideoChildTile;

import cn from 'classnames';
import React from 'react';
import BemCSSTransition from 'components/BemCssTransition';
import { headerBlock as block } from '../../utils';
import { useClipSelectAnimation } from '../ClipSelectAnimationContext';
import EditClipHeader from './EditClipHeader';
import SelectClipHeader from './SelectClipHeader';

export type ClipSelectHeaderProps = {
  className?: string;
};

const ClipSelectHeader: React.FC<ClipSelectHeaderProps> = ({ className }) => {
  const animations = useClipSelectAnimation();

  return (
    <div className={cn(block(), className)}>
      <BemCSSTransition {...animations.headerSelect}>
        <SelectClipHeader />
      </BemCSSTransition>
      <BemCSSTransition {...animations.headerEdit}>
        <EditClipHeader />
      </BemCSSTransition>
    </div>
  );
};

export default ClipSelectHeader;

import React from 'react';
import { noop } from 'underscore';

import TextOverlayModal, {
  ITextOverlay,
  TextOverlay,
  TextOverlayModalProps,
} from 'blocks/TextOverlayModal';
import { ModalFooterButton } from 'components/Modal';
import SwitchEditorModalBanner from 'components/SwitchEditorModalBanner';
import { DeepImmutableMap } from 'types';

import { useTemplateState } from '../../context/VideoTemplateStateContext';
import { textAssetModalBlock as block } from '../../utils';
import EditorModalStaticBackground from '../EditorModalStaticBackground';

type PickedModalProps = Pick<
  TextOverlayModalProps,
  | 'defaultText'
  | 'banner'
  | 'editable'
  | 'textOverlay'
  | 'show'
  | 'showEmoji'
  | 'title'
  | 'onExited'
>;

export interface TextAssetLegacyModalProps extends PickedModalProps {
  assetId: string;
  isEdit: boolean;
  onCancel?: () => void;
  onDelete?: () => void;
  onSubmit?: (overlay: DeepImmutableMap<ITextOverlay>) => void;
  onSwitchToCurrentModal?: (partialOverlay: TextOverlay) => void;
}

const TextAssetLegacyModal: React.FC<TextAssetLegacyModalProps> = ({
  assetId,
  banner,
  defaultText,
  editable = true,
  isEdit,
  onCancel,
  onDelete,
  onExited,
  onSubmit,
  onSwitchToCurrentModal,
  show,
  showEmoji = true,
  textOverlay,
  title,
}) => {
  const state = useTemplateState();
  const { aspectRatio, backgroundColor } = state;

  return (
    <TextOverlayModal
      background={
        <EditorModalStaticBackground assetId={assetId} showFadeLayer />
      }
      cancelButton={
        <ModalFooterButton onClick={onCancel}>cancel</ModalFooterButton>
      }
      deleteButton={
        isEdit && (
          <ModalFooterButton onClick={onDelete} theme="delete">
            delete
          </ModalFooterButton>
        )
      }
      footerLeftSlot={({ partialSubmitHandlerBuilder }) => (
        <div className={block('new-text-modal-banner')}>
          <SwitchEditorModalBanner
            onSwitchMode={partialSubmitHandlerBuilder(
              onSwitchToCurrentModal ?? noop,
            )}
          />
        </div>
      )}
      onExited={onExited}
      onHide={onCancel}
      showStyleTabs={false}
      showPresetSelector
      submitButton={
        <ModalFooterButton onClick={onSubmit as any} theme="submit">
          {isEdit ? 'Save' : 'Add To Video'}
        </ModalFooterButton>
      }
      shouldDisableSubmitIfEmpty
      {...{
        aspectRatio,
        backgroundColor,
        banner,
        defaultText,
        editable,
        show,
        showEmoji,
        textOverlay,
        title,
      }}
    />
  );
};

export default TextAssetLegacyModal;

import { TranscriptNotFoundError } from 'redux/middleware/api/transcript-service/types';
import {
  EMBED_VIDEO_EXPORT_URL_PATTERN,
  ERROR_MESSAGES_TO_BE_FILTERED,
  LOGIN_STATUS_CODES,
  LOGIN_URL_PATTERN,
} from './constants';
import { ReportedError, WithReported } from './types';

export function isErrorReported<T>(obj: WithReported<T>) {
  if (typeof obj !== 'object') {
    return false;
  }

  // eslint-disable-next-line no-underscore-dangle
  return obj?._reported;
}

export function isReportableError(err: any) {
  const { response, status } = err;
  const errorMessage = err.message;
  const req = response && response.req;
  const url = req && req.url;
  const method = req && req.method;

  // Errors that are filtered out based on error message can be found in the
  // Sentry initialization code, but are included here because sentry matches
  // on the error message and for some of our errors have an odd shape and
  // have the error message in an unexpected place
  const isBlacklisted =
    err instanceof TranscriptNotFoundError ||
    status < 500 ||
    (EMBED_VIDEO_EXPORT_URL_PATTERN.test(url) && status === 404) ||
    (LOGIN_URL_PATTERN.test(url) &&
      LOGIN_STATUS_CODES.includes(status) &&
      method === 'POST') ||
    ERROR_MESSAGES_TO_BE_FILTERED.includes(errorMessage);

  return !isBlacklisted;
}

export function shouldReportError(err: any): boolean {
  return !isErrorReported(err) && isReportableError(err);
}

export function markErrorReported<E extends ReportedError>(err: E) {
  if (typeof err === 'object') {
    // eslint-disable-next-line no-underscore-dangle
    err._reported = true;
  }
  return err;
}

import React, { useCallback, useMemo } from 'react';
import Select, { Option, SelectProps } from 'components/Select';
import { SWITCH_ACCOUNT_VALUE } from './constants';
import SwitchAccountIcon from './SwitchAccountIcon';

type AccountSelectProps = SelectProps & {
  onSwitchAccountSelect?: () => void;
};

const AccountSelect: React.FC<AccountSelectProps> = props => {
  const { options, onSwitchAccountSelect, onChange, ...rest } = props;

  const optionsWithSwitchAccount = useMemo(
    () => [
      ...options,
      {
        label: 'Switch Account',
        logo: <SwitchAccountIcon />,
        value: SWITCH_ACCOUNT_VALUE,
      } as Option,
    ],
    [options],
  );

  const handleChange = useCallback(
    (val: Option) => {
      if (val.value === SWITCH_ACCOUNT_VALUE) {
        onSwitchAccountSelect?.();
      } else {
        onChange(val);
      }
    },
    [onChange, onSwitchAccountSelect],
  );
  return (
    <Select
      options={optionsWithSwitchAccount}
      onChange={handleChange}
      {...rest}
    />
  );
};
export default AccountSelect;

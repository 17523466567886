import { Map } from 'immutable';
import { ITextOverlay } from 'blocks/TextOverlayModal';

import { RUNTIME_ENV } from 'config';
import { EntireEpisodeTranscriptStatus } from 'redux/middleware/api/podcast-service/types';
import { GettyAuthProvider } from 'redux/middleware/api/third-party-authentication-service/types';
import {
  AspectRatioName,
  createMap,
  DeepImmutableMap,
  KeyImageType,
  KeyTextType,
  ProgressAnimationOptions,
  ProjectCreationMethod,
  TemplateType,
  VideoExportPreferences,
} from 'types';
import dayjs from './dayjs';
import { DEFAULT_PROGRESS_OPTIONS } from './embed/progress';
import { defaultFontFamily } from './fonts';
import measurement, { Measurement } from './measurement';
import { getFontStyle, getTextDecoration } from './ui';

export const TEMPLATE_EDITOR_PLACEHOLDER_IMAGE =
  'https://static.sparemin.com/auto-video-templates/subject-image-placeholder/202008131323/subject-image-placeholder.png';

export const DEFAULT_PROJECT_PAGE_SIZE = 20;
export const DEFAULT_PROJECT_NAME = 'Untitled Project';

export const PROVIDER_LIST: GettyAuthProvider[] = [
  'getty',
  'thinkstock',
  'gettyCreative',
];

export enum Split {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export const DEFAULT_REVISION_HISTORY_PAGE = 1;
export const DEFAULT_REVISION_HISTORY_SIZE = 20;

export const DEFAULT_TRANSITION_DURATION_IN_SEC = 0.8;

export const WEB_MOBILE_PAGE = 'http://headliner.app/mobile';

export const HEADLINER_SHOUTOUT = '(video made with https://www.headliner.app)';

export const EDDY_URL = 'https://eddy.headliner.app';

export const DASHBOARD_URL = 'https://dashboard.headliner.app';

export const MAKE_PAGE_DASHBOARD_URL = `${DASHBOARD_URL}/dashboard/headliner-studio`;

export const SAVE_TEMPLATE_KNOWLEDGE_BASE_URL =
  'https://learn.headliner.app/hc/en-us/articles/4402275074327-How-to-make-save-a-template-using-Headliner';

export enum TranscriptStatus {
  TRANSCRIPT_STATUS_NA = 'n/a',
  TRANSCRIPT_STATUS_NONE = 'none',
  TRANSCRIPT_STATUS_IN_PROGESS = 'inProgress',
  TRANSCRIPT_STATUS_AVAILABLE = 'available',
}

export enum ProjectTypeByAssets {
  PROJECT_NO_ASSETS = 'noAssets',
  PROJECT_WITH_AUDIO_ASSETS = 'audioAssets',
}

export enum DropZoneType {
  AUDIOGRAM_AUDIO = 'audiogramAudio',
  AUDIOGRAM_IMAGE = 'audiogramImage',
  AUTOMATED = 'automated',
  EPISODE = 'episode',
  VIDEO_TRANSCRIPTION = 'videoTranscription',
  EDITOR_MEDIA = 'editorMedia',
  EDITOR_AUDIO = 'editorAudio',
  EDITOR_CAPTION = 'editorCaption',
  EDITOR_WATERMARK = 'editorWatermark',
}

export const UPLOAD_RECORDING_MAX_DURATION = 600;

export const AUDIOGRAM_CUTOFF_MILLIS = UPLOAD_RECORDING_MAX_DURATION * 1000;

export const AUDIOGRAM_CUTOFF_DURATION_STRING = dayjs
  .duration(AUDIOGRAM_CUTOFF_MILLIS)
  .format('m [minutes]');

export const CAPTION_STYLE_FIT_TEXT =
  'This box shows the amount of text that fits into one caption. As you change the style of the captions, you can preview how much text will fit.';

export const DEFAULT_TEXT_OVERLAY_DURATION_MILLIS = 5000;

export const SAVED_TEMPLATE_NAME_MAX_CHARACTER_LENGTH = 70;

export const TEMPLATE_TAB_KEY_ALL = 'all';
export const TEMPLATE_TAB_KEY_HEADLINER_DEFAULT =
  TemplateType.HEADLINER_DEFAULT;
export const TEMPLATE_TAB_KEY_USER_GENERATED = TemplateType.USER_GENERATED;
export const TEMPLATE_TAB_KEY_REFRESH_TEMPLATE_LIST = 'refresh';

export const TEMPLATE_TABS = {
  [TEMPLATE_TAB_KEY_ALL]: 'All',
  [TEMPLATE_TAB_KEY_HEADLINER_DEFAULT]: 'Pre-made',
  [TEMPLATE_TAB_KEY_USER_GENERATED]: 'Saved',
};

export const VIEWPORTS = {
  landscape: { height: 432, width: 768 },
  portrait: { height: 430.22, width: 242 },
  square: { height: 380, width: 380 },
} as const;

export const DEFAULT_TEXT_SIZES: Record<
  AspectRatioName,
  { width: Measurement; height: Measurement }
> = {
  landscape: {
    width: measurement('69.28836916353025vw'),
    height: measurement('10.184844269895816vh'),
  },
  portrait: {
    width: measurement('78.45807674088113vw'),
    height: measurement('39.69164495499765vh'),
  },
  square: {
    width: measurement('69.28999999999999vw'),
    height: measurement('23.157894736842106vh'),
  },
};

export const DEFAULT_TEXT_OVERLAY = (createMap({
  style: createMap({
    background: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: defaultFontFamily,
    fontSize: 36,
    fontStyle: getFontStyle(false),
    fontWeight: 'bold',
    lineHeight: 1.2,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    textAlign: 'center',
    textDecoration: getTextDecoration(false),
    textHighlight: 'rgba(0, 0, 0, 0)',
    textShadow: Map({
      blur: 8,
      color: 'rgba(0, 0, 0, 0)',
      x: 0,
      y: 0,
    }),
  }),
  text: '',
  transitions: Map({
    in: Map({
      duration: 0,
      value: 'cut',
    }),
    out: Map({
      duration: 0,
      value: 'cut',
    }),
  }),
}) as unknown) as DeepImmutableMap<ITextOverlay>;

export const DESCRIPT_INTEGRATION_DOMAIN = 'share.descript.com';

export const AUTOMATION_TEMPLATE_PLACEHOLDERS: Record<
  Exclude<KeyTextType, 'mainText'> | KeyImageType,
  string
> = {
  episodeTitle: '%%Episode%%',
  mainImage: '%%EpisodeImage%%',
  podcastTitle: '%%Podcast%%',
};

export const DYNAMIC_ELEMENTS = ['%%Episode%%', '%%Podcast%%'];

export const DEFAULT_EPISODE_TITLE = 'The Episode Title Will Go Here';

export const KEY_TEXT_TYPE: KeyTextType[] = [
  'episodeTitle',
  'mainText',
  'podcastTitle',
];
export const KEY_IMAGE_TYPE: KeyImageType[] = ['mainImage'];

export const ACCEPTABLE_CONSTANT_BITRATE_DEVIATION_BPS = 50;

export const SM_BLUE = '#19b5fe';
export const SM_ERROR_RED = '#c82828';

export const BLANK_CANVAS_TEMPLATE_ID: Record<AspectRatioName, string> = {
  landscape: 'cks92ubgg0001bvoydnzbe8cf',
  portrait: 'cks92ubpy0002bvoyqdumtwop',
  square: 'cks92u0mu0000bvoy4qitylar',
};
interface UcsEditorDefaults {
  backgroundColor: string;
  progress: ProgressAnimationOptions;
}

export const UCS_EDITOR_DEFAULTS: UcsEditorDefaults = {
  backgroundColor: 'black',
  progress: DEFAULT_PROGRESS_OPTIONS,
};

export const EPISODE_TRANSCRIPTION_TRANSCRIBABLE_STATUSES: Readonly<EntireEpisodeTranscriptStatus[]> = [
  'error',
  'errorAck',
  'notFound',
];

export const EPISODE_TRANSCRIPTION_BUSY_STATUSES: Readonly<EntireEpisodeTranscriptStatus[]> = [
  'queued',
  'createTranscriptRequest',
  'transcriptRequested',
  'cleanUpTranscript',
];

export const EPISODE_TRANSCRIPTION_ERROR_STATUSES: Readonly<EntireEpisodeTranscriptStatus[]> = [
  'error',
  'errorAck',
];

export const EPISODE_TRANSCRIPTION_SUCCESS_STATUSES: Readonly<EntireEpisodeTranscriptStatus[]> = [
  'completed',
];

export const MAIL_TO_SUPPORT_LINK = 'support@headliner.app';

export const DISCO_ADS_PROMO_LINK =
  'https://dashboard.headliner.app/dashboard/disco-ads/create-ad';

export const PLAY_APP_LINK = 'https://play.headliner.app';

export const EPISODE_MAX_DURATION_UPGRADE_HOURS_VALUE = 4;

export const EPISODE_MAX_DURATION_UPGRADE_SECONDS_VALUE =
  EPISODE_MAX_DURATION_UPGRADE_HOURS_VALUE * 3600;

export const DISCO_LISTENS_STATUS = {
  displayVariant: 'DV_ckykjxrsd0000ytogtbm8exe4',
};

export const EPISODE_MAX_DURATION_UPGRADE_MS_VALUE =
  EPISODE_MAX_DURATION_UPGRADE_HOURS_VALUE * 3600 * 1000;

export const AUDIO_CLIPPER_WAVESURFER_PIXEL_RATIO = 1.15;

export const INTRO_OUTRO_CLIP_MAX_MINS = 1;

export const INTRO_OUTRO_MAX_DURATION_SECS = INTRO_OUTRO_CLIP_MAX_MINS * 60;

export const UPLOAD_VIDEO_MAX_DURATION_SECONDS = 3600;

export const MINIMUM_TEXT_DURATION_MILLIS = 500;

export const VIDEO_EXPORT_PREFS_DEFAULTS: Readonly<VideoExportPreferences> = {
  frameRate: 24,
  fullEpisodeFrameRate: 24,
  frameQuality: 100,
  frameSize: 'hd',
  fullEpisodeFrameSize: 'hd',
};

export const PLAYLIST_POLL_MAX_ATTEMPTS = 5;
export const PLAYLIST_POLL_INTERVAL_MILLIS = 3000;

export const REFERRAL_KEY_QUERY_PARAM = 'referralKey';
export const REFERRAL_TYPE_QUERY_PARAM = 'referralType';

export const AUDIO_FADE_DURATION_MAX_MILLIS = 2000;

export const PRO_TIER_MAX_MEDIA_DURATION_HOURS = 4;
export const PRO_TIER_MAX_MEDIA_SIZE_MB = 500;

export const DEFAULT_UPGRADE_UPLOAD_LIMIT = '5GB';

export const KARAOKE_PREVIEW_URL =
  'https://static.sparemin.com/img/editor/animation-icons/karaoke.gif';

export const LONG_PROJECT_TYPES: ProjectCreationMethod[] = [
  'episode',
  'audioLong',
  'podcastFeedAutoCreateEpisode',
  'videoTranscriptEpisode',
];

export const VIDEO_TRANSCRIPTION_POLL_INTERVAL_MILLIS = 30000;

export const VIDEO_TRANSCRIPTION_POLL_MAX_ATTEMPTS = 200;

export const MINIMUM_AUDIO_DURATION_MILLIS = 1000;

export const VIDEO_EXPORT_POLL_MAX_ATTEMPTS = 500;

export const WAVEFORM_POLL_INTERVAL_MILLIS = 5000;

export const WAVEFORM_POLL_MAX_ATTEMPTS = 1500;

export const VIDEO_UPLOAD_POLL_MAX_ATTEMPTS = 1200;

export const VIDEO_UPLOAD_POLL_INTERVAL_MILLIS = 5000;

export const TRANSCRIPTION_POLL_INTERVAL_MILLIS = 5000;

export const TRANSCRIPTION_POLL_MAX_ATTEMPTS = 1500;

export const KEYWORD_POLL_INTERVAL_MILLIS = 5000;

export const KEYWORD_POLL_MAX_ATTEMPTS = 500;

export const VIDEO_EXPORT_POLL_INTERVAL_MILLIS = 5000;

export const VIDEO_EXPORT_RETRY_ATTEMPTS = 3;

export const STANDARDIZATION_POLL_INTERVAL_MILLIS = 5000;

export const STANDARDIZATION_POLL_MAX_ATTEMPTS = 60;

export const RECORDING_IMPORT_POLL_INTERVAL_MILLIS = 5000;

export const RECORDING_IMPORT_POLL_MAX_ATTEMPTS = 240;

export const DEFAULT_PROFILE_IMAGE_URL =
  'https://static.sparemin.com/profile-images/dev1/profile/3a/a6/bf/5d/3aa6bf5dc746b7eaf4ea456e7679d257_600_600.JPEG';

export const IMAGE_SEARCH_TIMEOUT_MILLIS = 10000;

export const RECORDING_IMPORT_RETRY_ATTEMPTS = 3;

export const VIDEO_CLIP_MAX_DURATION_SECONDS = 600;

export const UPLOAD_VIDEO_MAX_FILE_SIZE_MB = 500;

export const TEXT_TO_VIDEO_MAX_CHARS = 1000;

export const DEFAULT_AUDIO_CLIP_DURATION_MILLIS = 60000;

export const RAW_AUDIO_MAX_DURATION_SECONDS = 7200;

export const MAX_VIDEO_EXPORT_DURATION_SECONDS = 600.1;

export const TERMS_OF_SERVICE_URL =
  'https://www.headliner.app/docs/terms-of-service.html';

export const MINIMUM_SLIDE_DURATION_MILLIS = 500;

export const EDITOR_MIN_HEIGHT = 600;

export const EDITOR_MIN_WIDTH = 1000;

export const MEDIA_USE_TERMS_URL =
  'https://www.headliner.app/docs/media-use-terms.html';

export const INTRO_OUTRO_CLIP_MAX_MB = 25;

export const TEXT_TRANSITION_IN_URL_BASE =
  'https://static.sparemin.com/static/webapp-assets/text-transition-animations/in/20180830/';

export const TEXT_TRANSITION_OUT_URL_BASE =
  'https://static.sparemin.com/static/webapp-assets/text-transition-animations/out/20180710/';

export const TINYMCE_CONTENT_CSS_SELECTOR = 'link#app-style';

export const PRIVACY_POLICY_URL =
  'https://www.headliner.app/docs/privacy-policy.html';

export const SAMPLE_AUDIO_URL =
  'https://static.sparemin.com/static/webapp-assets/audio/202008271522/demo-audio-file.mp3';

export const EPISODE_MAX_AUDIO_FILE_SIZE_MB = 500;

export const SAMPLE_VIDEO_URL =
  'https://static.sparemin.com/static/webapp-assets/videos/20180822/wizard-sample-video.mp4';

export const AUTOSAVE_INTERVAL_MILLIS = 5000;

export const SPAREMIN_LIBRARY_ENVIRONMENT = (() => {
  if (process.env.NODE_ENV === 'development') {
    return 'local';
  }

  if (RUNTIME_ENV === 'production') {
    return 'prod';
  }

  return 'dev';
})();

export const CAPTIONS_ANIMATIONS_MAX_DURATION_MINUTES = 10;
export const CAPTIONS_ANIMATIONS_MAX_DURATION_SEC =
  CAPTIONS_ANIMATIONS_MAX_DURATION_MINUTES * 60;

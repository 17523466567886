import { PurchasePlatform } from 'types';

import { IApiAction, IApiResponse } from '../types';

export const ACTION_KEY = 'PLAN_SERVICE' as const;

export enum ServiceMethod {
  CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD',
  GET_CUSTOMER = 'GET_CUSTOMER',
  GET_MY_COUPONS = 'GET_MY_COUPONS',
  GET_MY_REFERRAL = 'GET_MY_REFERRAL',
  GET_MY_SUBSCRIPTION = 'GET_MY_SUBSCRIPTION',
  GET_PLANS = 'GET_PLANS',
  SEND_REFERRAL_INVITATIONS = 'SEND_REFERRAL_INVITATIONS',
  UPDATE_MY_SUBSCRIPTION = 'UPDATE_MY_SUBSCRIPTION',
  GET_EXTERNAL_PORTAL_LINK = 'GET_EXTERNAL_PORTAL_LINK',
  ACCEPT_PROMOTION = 'ACCEPT_PROMOTION',
}

export enum PlanName {
  FREE_FOREVER = 'freeForever',
  PRO_MONTHLY = 'proMonthly',
  PRO_YEARLY = 'proYearly',
  ENTERPRISE_FOREVER = 'enterpriseForever',
  ENTERPRISE_API_FOREVER = 'enterpriseApiIntegratorForever',
  PRO_EDUCATION_FOREVER = 'proEducationCharityForever',
  PRO_ASSIGNED = 'proAdminAssignedManual',
  PRO_TRIAL = 'proTrialManual',
  PRO_20200922_MONTHLY = 'pro20200922Monthly',
  PRO_20200922_YEARLY = 'pro20200922Yearly',
  BASIC_20200922_MONTHLY = 'basic20200922Monthly',
  BASIC_20200922_YEARLY = 'basic20200922Yearly',
  EDUCATION_NONPROFIT_FOREVER = 'educationNonprofitForever',
  UNLIMITED_20211112_MONTHLY = 'unlimited20211112Monthly',
  UNLIMITED_20211112_YEARLY = 'unlimited20211112Yearly',
  PRO_20211112_MONTHLY = 'pro20211112Monthly',
  PRO_20211112_YEARLY = 'pro20211112Yearly',
  BASIC_20211112_MONTHLY = 'basic20211112Monthly',
  BASIC_20211112_YEARLY = 'basic20211112Yearly',
}

export type SubscribablePlanName =
  | PlanName.FREE_FOREVER
  | PlanName.PRO_MONTHLY
  | PlanName.PRO_YEARLY
  | PlanName.PRO_20200922_MONTHLY
  | PlanName.PRO_20200922_YEARLY
  | PlanName.PRO_20211112_MONTHLY
  | PlanName.PRO_20211112_YEARLY
  | PlanName.BASIC_20200922_MONTHLY
  | PlanName.BASIC_20200922_YEARLY
  | PlanName.BASIC_20211112_MONTHLY
  | PlanName.BASIC_20211112_YEARLY
  | PlanName.UNLIMITED_20211112_MONTHLY
  | PlanName.UNLIMITED_20211112_YEARLY;

export type MonthlyPlanName =
  | PlanName.PRO_MONTHLY
  | PlanName.PRO_20200922_MONTHLY
  | PlanName.PRO_20211112_MONTHLY
  | PlanName.BASIC_20200922_MONTHLY
  | PlanName.BASIC_20211112_MONTHLY
  | PlanName.UNLIMITED_20211112_MONTHLY;

export type YearlyPlanName =
  | PlanName.PRO_YEARLY
  | PlanName.PRO_20200922_YEARLY
  | PlanName.PRO_20211112_YEARLY
  | PlanName.BASIC_20200922_YEARLY
  | PlanName.BASIC_20211112_YEARLY
  | PlanName.UNLIMITED_20211112_YEARLY;

export enum Tier {
  ENTERPRISE = 'enterprise',
  FREE = 'free',
  PRO = 'pro',
  BASIC = 'basic',
  EDUCATION_NONPROFIT = 'educationNonprofit',
  UNLIMITED = 'unlimited',
}

export enum SubTier {
  FREE = 'free',
  PRO_PAUSED = 'proPaused',
  BASIC_PAUSED = 'basicPaused',
  PRO_PAID = 'proPaid',
  ENTERPRISE = 'enterprise',
  ENTERPRISE_API = 'enterpriseApiIntegrator',
  PRO_EDUCATION = 'proEducationCharity',
  PRO_TRIAL = 'proTrial',
  PRO_ASSIGNED = 'proAdminAssigned',
  PRO_20200922_PAID = 'pro20200922Paid',
  PRO_20211112_PAID = 'pro20211112Paid',
  PRO_FREE_TEMPORARY = 'proFreeTemporary',
  BASIC_20200922_PAID = 'basic20200922Paid',
  BASIC_20211112_PAID = 'basic20211112Paid',
  BASIC_EDUCATION_CHARITY = 'basicEducationCharity',
  EDUCATION_NONPROFIT = 'educationNonprofit',
  UNLIMITED_20211112_PAID = 'unlimited20211112Paid',
  UNLIMITED_ASSIGNED = 'unlimitedAdminAssigned',
}

export type PurchaseableTier = Tier.PRO | Tier.BASIC | Tier.UNLIMITED;

export type PurchaseableSubTier =
  | SubTier.PRO_PAID
  | SubTier.PRO_20200922_PAID
  | SubTier.PRO_20211112_PAID
  | SubTier.BASIC_20200922_PAID
  | SubTier.BASIC_20211112_PAID
  | SubTier.UNLIMITED_20211112_PAID;

export type EnterpriseSubTier = SubTier.ENTERPRISE | SubTier.ENTERPRISE_API;

export const PRO_TIERS = [
  SubTier.BASIC_20200922_PAID,
  SubTier.BASIC_20211112_PAID,
  SubTier.ENTERPRISE,
  SubTier.ENTERPRISE_API,
  SubTier.PRO_20200922_PAID,
  SubTier.PRO_20211112_PAID,
  SubTier.PRO_ASSIGNED,
  SubTier.PRO_FREE_TEMPORARY,
  SubTier.PRO_EDUCATION,
  SubTier.PRO_PAID,
  SubTier.UNLIMITED_20211112_PAID,
  SubTier.UNLIMITED_ASSIGNED,
  SubTier.PRO_PAUSED,
  SubTier.BASIC_PAUSED,
];

export interface Plan {
  id: string;
  name: PlanName;
  amount: number | null;
  currency: string | null;
}

interface NormalizedPlans {
  entities: {
    plans: { [name in PlanName]: Plan };
  };
  result: PlanName[];
}

interface PlanFeatureUsage {
  limit: number;
  remaining: number;
  periodStartMillis: number;
  periodEndMillis: number;
  overageReached: true;
}

interface Subscription {
  subscribedProduct: {
    id: string;
    subtier: SubTier;
    tier: Tier;
  };
  subscribedPlan: {
    id: string;
    name: PlanName;
  };
  pauseSchedule: {
    pauseEnd: number;
    pauseStart: number;
    pauseStatus: string;
  };
  purchaseManagement: {
    platform: PurchasePlatform | null;
  };
  subscribedPlanAssociation?: {
    displayName: string;
  };
  planUsage: {
    transcriptDuration: PlanFeatureUsage;
    videoExport: PlanFeatureUsage;
    overageReached: true;
  };
  planLimit: {
    eddyDurationLimitHours: number;
    eddyUploadMB: number;
    fullEpisodeDurationLimitHours: number;
    videoUploadMB: number;
  };
  currentPeriodStart: number;
  currentPeriodEnd: number;
  currentPlanStart: number;
  autoRenewal: boolean;
  invoicePastDue: boolean;
  forcedCancellationNotice: boolean;
  feature: {
    exportScreenRecWidget: {
      enabled: boolean;
    };
    fullEpisodeCaption: {
      enabled: boolean;
    };
  };
}

export interface CustomerSource {
  id: string;
  brand: string;
  expMonth: string;
  expYear: string;
  last4: string;
  isDefault: boolean;
}

interface CustomerDetail {
  id: string;
  sources: CustomerSource[];
}

interface Customer {
  customerId: string;
  customerDetail: CustomerDetail;
}

export interface Coupon {
  id: string;
  name: string;
  couponDisplayHint?: string;
}

export interface Referral {
  id: number;
  referralUrl: string;
}

interface ReferralInvitation {
  email: string;
}

export type GetPlansArgs = PlanName[];
export type GetPlanResult = NormalizedPlans;

export type GetMySubscriptionArgs = undefined;
export type GetMySubscriptionResult = Subscription;

export type UpdateMySubscriptionArgs = [
  SubscribablePlanName,
  boolean,
  boolean,
  number, // pausePlanDurationMonths
  boolean, // cancelPause
];
export type UpdateMySubscriptionResult = undefined;

export type CreatePaymentMethodArgs = [
  string, // stripeToken
  string, // paymentMethodId
];
export type CreatePaymentMethodResult = undefined;

export type GetCustomerArgs = undefined;
export type GetCustomerResult = Customer;

export type GetMyCouponsArgs = undefined;

export type GetMyReferralArgs = undefined;
export type GetMyReferralResult = Referral;

export type SendReferralInvitationsArgs = [ReferralInvitation[]];
export type SendReferralInvitationResult = undefined;

type ReturnUrl = string;
export type GetExternalPortalLinkArgs = [ReturnUrl];
export type GetExternalPortalLinkResult = { url: string | undefined };

export interface GetMyCouponsResult {
  coupons: Coupon[];
}

export interface AcceptPromotionArgs {
  promotionKey: string;
}
export type AcceptPromotionResult = void;

type ApiAction<M extends ServiceMethod, A> = IApiAction<
  typeof ACTION_KEY,
  M,
  A
>;
export type GetPlansAction = ApiAction<ServiceMethod.GET_PLANS, GetPlansArgs>;
export type GetMyPlanAction = ApiAction<
  ServiceMethod.GET_MY_SUBSCRIPTION,
  GetMySubscriptionArgs
>;
export type GetMySubscriptionAction = ApiAction<
  ServiceMethod.GET_MY_SUBSCRIPTION,
  GetMySubscriptionArgs
>;
export type UpdateMySubscriptionAction = ApiAction<
  ServiceMethod.UPDATE_MY_SUBSCRIPTION,
  UpdateMySubscriptionArgs
>;
export type CreatePaymentMethodAction = ApiAction<
  ServiceMethod.CREATE_PAYMENT_METHOD,
  CreatePaymentMethodArgs
>;
export type GetCustomerAction = ApiAction<
  ServiceMethod.GET_CUSTOMER,
  GetCustomerArgs
>;
export type GetMyCouponsAction = ApiAction<
  ServiceMethod.GET_MY_COUPONS,
  GetMyCouponsArgs
>;
export type GetMyReferralAction = ApiAction<
  ServiceMethod.GET_MY_REFERRAL,
  GetMyReferralArgs
>;
export type SendReferralInvitationsAction = ApiAction<
  ServiceMethod.SEND_REFERRAL_INVITATIONS,
  SendReferralInvitationsArgs
>;
export type GetExternalPortalLinkAction = ApiAction<
  ServiceMethod.GET_EXTERNAL_PORTAL_LINK,
  GetExternalPortalLinkArgs
>;

export type AcceptPromotionAction = ApiAction<
  ServiceMethod.ACCEPT_PROMOTION,
  AcceptPromotionArgs
>;

export interface PlanServiceDispatch {
  (action: GetPlansAction): Promise<IApiResponse<GetPlanResult>>;
  (action: GetMySubscriptionAction): Promise<
    IApiResponse<GetMySubscriptionResult>
  >;
  (action: UpdateMySubscriptionAction): Promise<
    IApiResponse<UpdateMySubscriptionResult>
  >;
  (action: CreatePaymentMethodAction): Promise<
    IApiResponse<CreatePaymentMethodResult>
  >;
  (action: GetCustomerAction): Promise<IApiResponse<GetCustomerResult>>;
  (action: GetMyCouponsAction): Promise<IApiResponse<GetMyCouponsResult>>;
  (action: GetMyReferralAction): Promise<IApiResponse<GetMyReferralResult>>;
  (action: SendReferralInvitationsAction): Promise<
    IApiResponse<SendReferralInvitationResult>
  >;
  (action: GetExternalPortalLinkAction): Promise<
    IApiResponse<GetExternalPortalLinkResult>
  >;
  (action: AcceptPromotionAction): Promise<IApiResponse<AcceptPromotionResult>>;
}

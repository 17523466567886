import * as React from 'react';

import { IconProps } from 'components/icons/types';

const CapitalizedTextIcon: React.FC<IconProps> = (props: IconProps) => (
  <svg
    width="30"
    height="22"
    viewBox="0 0 30 22"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.509 16.74a.494.494 0 0 1-.357-.15.473.473 0 0 1-.152-.349c0-.073.013-.148.039-.224L5.927.605c.059-.166.156-.309.291-.427C6.354.059 6.546 0 6.795 0h2.649c.249 0 .44.06.577.178.135.118.232.26.291.427L16.2 16.017a.692.692 0 0 1 .039.224c0 .134-.05.25-.152.35a.494.494 0 0 1-.357.148h-2.128c-.222 0-.386-.053-.49-.16a1.044 1.044 0 0 1-.217-.296l-1.138-2.913H4.482l-1.138 2.913a.804.804 0 0 1-.204.295c-.106.108-.274.161-.503.161H.509zm4.828-6.116h5.565L8.115 3.223l-2.778 7.401zM23.937 22c-1.182 0-2.156-.15-2.923-.45-.766-.3-1.37-.665-1.813-1.095-.443-.43-.758-.855-.944-1.278-.187-.423-.288-.757-.304-1.003a.476.476 0 0 1 .161-.419.613.613 0 0 1 .426-.17h2.14c.15 0 .276.034.378.103.1.069.184.199.25.39.09.203.218.426.382.668.163.242.413.447.748.615.335.168.794.251 1.377.251.631 0 1.158-.087 1.579-.261a1.86 1.86 0 0 0 .96-.893c.218-.42.327-.993.327-1.719v-1.62c-.394.45-.897.82-1.507 1.107-.61.288-1.362.432-2.253.432-.873 0-1.63-.139-2.274-.415a4.21 4.21 0 0 1-1.597-1.17 5.28 5.28 0 0 1-.964-1.782 8.29 8.29 0 0 1-.378-2.265 17.036 17.036 0 0 1 0-1.29c.03-.799.154-1.543.373-2.231a5.32 5.32 0 0 1 .964-1.802 4.367 4.367 0 0 1 1.602-1.204c.643-.29 1.401-.434 2.274-.434.924 0 1.707.17 2.347.511.64.341 1.161.765 1.562 1.271v-.954a.575.575 0 0 1 .587-.589h1.971a.589.589 0 0 1 .602.59L30 16.431c0 1.15-.218 2.14-.653 2.968-.436.83-1.1 1.47-1.993 1.922-.894.452-2.032.678-3.417.678zm-.105-7.856c.665 0 1.2-.147 1.61-.442.407-.294.71-.669.906-1.123.196-.455.307-.928.333-1.42.016-.194.024-.453.024-.778s-.008-.581-.024-.769a4.102 4.102 0 0 0-.333-1.42 2.665 2.665 0 0 0-.907-1.123c-.408-.294-.944-.441-1.609-.441-.68 0-1.222.145-1.626.437a2.41 2.41 0 0 0-.867 1.16 5.527 5.527 0 0 0-.312 1.579 9.865 9.865 0 0 0 0 1.164c.033.57.137 1.097.312 1.579s.464.868.867 1.16c.404.291.946.437 1.626.437z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default CapitalizedTextIcon;

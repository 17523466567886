import { ProjectListItemAspectRatio } from 'redux/middleware/api/video-project-management-service/types';
import {
  makeProjectSelector,
  makeProjectWidgetUuidSelector,
} from 'redux/modules/entities';
import { State } from 'redux/types';
import { getAspectRatioName, getAspectRatioValue } from 'utils/aspect-ratio';
import bem from 'utils/bem';
import { LONG_PROJECT_TYPES } from 'utils/constants';
import {
  getAutomationSuggestedClipsPath,
  getSuggestedClipsPath,
} from 'utils/routes';

export const block = bem('project-history');

export const getProjectRowThumbnailAspectRatioValue = (
  aspectRatio?: ProjectListItemAspectRatio,
): number | undefined => {
  if (!aspectRatio) {
    return undefined;
  }

  const { height, width } = aspectRatio;

  const aspectRatioName = getAspectRatioName(height, width);

  return getAspectRatioValue(aspectRatioName);
};

export const makeProjectRowTooltipMessageSelector = (projectId: string) => (
  state: State,
) => {
  const { detail, projectType } = makeProjectSelector(projectId)(state) ?? {};
  const widgetId = makeProjectWidgetUuidSelector(projectId)(state);

  if (projectType !== 'plainProject') {
    return null;
  }

  const {
    createMethod,
    projectConfig: { isEditable },
  } = detail || {};

  if (!isEditable && !widgetId) {
    return 'Sit tight! We’ll have your video ready soon.';
  }

  if (LONG_PROJECT_TYPES.includes(createMethod)) {
    return 'Full episodes cannot be edited... yet!';
  }

  if (!isEditable) {
    return 'This project cannot be edited.';
  }

  return null;
};

export const makeProjectClipsPathSelector = (projectId: string) => (
  state: State,
) => {
  const { detail, projectType } = makeProjectSelector(projectId)(state) ?? {};

  if (projectType === 'automationSuggestionGroup') {
    return getAutomationSuggestedClipsPath({
      episodeId: String(detail?.episodeId),
      subscriptionItemId: detail?.subscriptionItemId,
      groupRequestId: detail?.groupRequestId,
    });
  }

  if (projectType === 'creationSuggestionGroup') {
    return getSuggestedClipsPath(detail?.creationRequestId);
  }

  return null;
};

import cn from 'classnames';
import React, { useContext, useEffect, useRef } from 'react';
import { addPlugin, block, createWaveColorGradient } from '../utils';
import WaveSurferContext from '../WaveSurferContext';
import MinimapPlugin from './MinimapPlugin';

interface Props {
  className?: string;
}

function Minimap(props: Props) {
  const { className } = props;
  const ref = useRef<HTMLDivElement>();
  const ws = useContext(WaveSurferContext);

  useEffect(() => {
    if (!ws) {
      return () => {};
    }

    const plugin = MinimapPlugin.create({
      container: ref.current,
      height: 40,
      showOverview: true,
      showRegions: true,
      cursorColor: 'transparent',
      progressColor: 'transparent',
      waveColor: createWaveColorGradient(
        {
          width: ref?.current?.clientWidth,
          height: ref?.current?.clientHeight,
        },
        '#5DBEFC',
        '#DD92FE',
      ),
    });

    return addPlugin(ws, plugin as any);
  }, [ws]);

  return <div ref={ref} className={cn(className, block('minimap'))} />;
}

export default Minimap;

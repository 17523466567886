import { isEmpty, isNull, isUndefined, omit } from 'underscore';
import { CaptionsOverride, ICaptions } from 'types';
import measurement, {
  Measurement,
  Pixels,
  ViewportWidth,
} from 'utils/measurement';

import { CaptionsState, ToPx, VideoTemplateStateContent } from '../types';

const FALLBACK_VIEWPORT = {
  height: 1,
  width: 1,
};

const getCaptionsPositionInPx = (
  captions: CaptionsState,
  toPx: ToPx,
): CaptionsOverride['position'] => {
  return {
    left: toPx(captions.position?.left)?.value,
    top: toPx(captions.position?.top)?.value,
  };
};

const getCaptionsSizeInPx = (
  captions: CaptionsState,
  toPx: ToPx,
): CaptionsOverride['size'] => {
  return {
    height: toPx(captions.size?.height).value,
    width: toPx(captions.size?.width).value,
  };
};

const getCaptionsFontSizeInPx = (
  captions: CaptionsState,
  toPx: ToPx,
): CaptionsOverride['containerStyle']['fontSize'] => {
  const fontSizePx = toPx(
    new ViewportWidth(parseFloat(captions.containerStyle?.fontSize)),
  );

  return fontSizePx.value;
};

export const getPaddingValue = (value?: string): number | undefined => {
  const parsedValue = parseFloat(value);

  return new ViewportWidth(
    isNull(parsedValue) || isUndefined(parsedValue) || isNaN(parsedValue)
      ? 0
      : parsedValue,
  ).value;
};

export const formatCaptionsPadding = (
  containerStyle: CaptionsOverride['containerStyle'],
): CaptionsOverride['containerStyle'] => {
  return {
    ...containerStyle,
    paddingTop: getPaddingValue(containerStyle.paddingTop),
    paddingRight: getPaddingValue(containerStyle.paddingRight),
    paddingBottom: getPaddingValue(containerStyle.paddingBottom),
    paddingLeft: getPaddingValue(containerStyle.paddingLeft),
  };
};

export const getCaptionsFromConfig = (
  captions: ICaptions,
): CaptionsState | undefined => {
  const viewport = captions.editor?.styleContext?.viewport ?? FALLBACK_VIEWPORT;
  const textBoxHeight = captions.editor?.styleContext?.textBoxHeight ?? 0;

  const height = new Pixels(textBoxHeight).toUnit('vh', viewport);
  const width = captions.containerStyle?.width;
  const left = captions.region?.properties?.left ?? 0;
  const top = captions.region?.properties?.top ?? 0;

  return {
    advancedTextConfigs: captions.advancedTextConfigs,
    advancedAnimation: captions.advancedAnimation,
    animation: captions.animation,
    containerStyle: omit(captions.containerStyle, ['width']),
    editor: captions.editor,
    position: {
      left: measurement(left),
      top: measurement(top),
    },
    size: {
      height: measurement(height.toString()),
      width: measurement(width),
    },
    region: captions.region,
    textStyle: captions.textStyle,
    textBoxHeight,
  };
};

export const getCaptionsExportConfig = (
  state: VideoTemplateStateContent,
): CaptionsOverride => {
  const { captions, canvas, transcription } = state;

  if (isEmpty(captions) || !transcription?.transcribe) {
    return undefined;
  }

  const viewport = captions.editor?.styleContext?.viewport ?? canvas;

  const toPx = (m: Measurement) => m?.toUnit('px', viewport);

  const position = getCaptionsPositionInPx(captions, toPx);
  const size = getCaptionsSizeInPx(captions, toPx);

  return {
    ...captions,
    containerStyle: formatCaptionsPadding({
      ...captions.containerStyle,
      fontSize: getCaptionsFontSizeInPx(captions, toPx),
      width: captions.size?.width?.toString(),
    }),
    enabled: transcription?.transcribe,
    hasBeenEdited: true,
    region: {
      ...captions.region,
      properties: {
        ...captions.region?.properties,
        left: captions.position?.left?.toString(),
        top: captions.position?.top?.toString(),
      },
    },
    textBoxHeight: size.height,
    position,
    size,
  };
};

import * as React from 'react';

import BemCssTransition from 'components/BemCssTransition';
import Tooltip from 'components/Tooltip';
import useTemplateThumbnail from 'hooks/useTemplateThumbnail';
import { AutogramVideoType, TrackType } from 'types';
import LayerOrderControls from './assets/LayerOrderControls/LayerOrderControls';
import { useCanvasSize } from './context/CanvasSizeContext';
import { useEditorState } from './context/VideoEditorStateContext';
import { useTemplateState } from './context/VideoTemplateStateContext';
import { DragAlignmentProvider } from './DragAlignmentContext';
import EditorCanvasInfobox from './EditorCanvasInfobox';
import {
  BackgroundLayer,
  MediaLayer,
  MediaLayerProps,
  SoundwaveLayer,
  SoundwaveLayerProps,
  TextLayer,
  TextLayerProps,
  WatermarkLayer,
} from './layers';
import CaptionsLayer from './layers/CaptionsLayer';
import ProgressLayer from './layers/ProgressLayer';
import useMediaLayer from './useMediaLayer';
import {
  canvasBlock as block,
  checkIsDynamicAutoframedVideoClipLayer,
  getEditorCanvasInfoBoxMessage,
  getEditorCanvasTooltipMessage,
  getLayerIdByAssetId,
  getMediaLayerFramingMethod,
} from './utils';

const LAYER_COMPONENTS: Record<
  Exclude<TrackType, 'audio'>,
  React.ComponentType<{ layerId: string }>
> = {
  media: MediaLayer,
  text: TextLayer,
  waveform: SoundwaveLayer,
};

interface EditorCanvasProps
  extends MediaLayerProps,
    TextLayerProps,
    SoundwaveLayerProps {
  videoType?: AutogramVideoType;
}

/*
 * The interactable preview canvas
 */
const EditorCanvas: React.FC<EditorCanvasProps> = props => {
  const { videoType } = props;
  const { canvas, container } = useCanvasSize();
  const state = useTemplateState();
  const { selectedAsset, features } = useEditorState();
  const { layers, template, isComplex, transcription, aspectRatio } = state;
  const { isUCSEditorCompatible, isUCSEditorCompatibleLoading, templateType } =
    template || {};
  const {
    isGenerating: isGeneratingThumbnail,
    imageUrl: thumbnailUrl,
    progressOverlay,
  } = useTemplateThumbnail({
    template,
  });

  const layerRenderOrder = [...layers?.order].reverse();
  const selectedLayerId = getLayerIdByAssetId(state, selectedAsset.id);

  const mediaLayer = useMediaLayer({ layerId: selectedLayerId });

  const framingMethod = getMediaLayerFramingMethod(mediaLayer);
  const infoboxMessage = getEditorCanvasInfoBoxMessage(
    mediaLayer,
    framingMethod,
    aspectRatio,
  );
  const tooltipMessage = getEditorCanvasTooltipMessage(
    mediaLayer,
    framingMethod,
    aspectRatio,
  );

  return !canvas?.height || !canvas?.width ? null : (
    <Tooltip
      placement="top"
      show={tooltipMessage ? undefined : false}
      content={tooltipMessage}
    >
      <div
        className={block({
          'no-thumbnail': isGeneratingThumbnail,
          disabled: checkIsDynamicAutoframedVideoClipLayer(
            mediaLayer,
            framingMethod,
            aspectRatio,
          ),
        })}
        style={{
          height:
            canvas?.height === container?.height ? '100%' : canvas?.height,
          width: canvas?.width === container?.width ? '100%' : canvas?.width,
        }}
      >
        {infoboxMessage && <EditorCanvasInfobox message={infoboxMessage} />}

        {templateType === 'userGenerated' &&
        (!isUCSEditorCompatible || isUCSEditorCompatibleLoading) ? (
          (() => (
            <>
              {thumbnailUrl && (
                <img className={block('fallback-img')} src={thumbnailUrl} />
              )}
              {progressOverlay}
            </>
          ))()
        ) : (
          <DragAlignmentProvider>
            <BackgroundLayer />
            {layerRenderOrder.map(layerId => {
              const layer = layers.data[layerId];

              if (layer) {
                const { type } = layer;
                const Component = LAYER_COMPONENTS[type];

                return <Component layerId={layerId} key={layerId} {...props} />;
              }
              return null;
            })}
            <ProgressLayer />
            <WatermarkLayer />
            {features.captions !== 'hidden' && transcription?.transcribe && (
              <CaptionsLayer videoType={videoType} />
            )}
          </DragAlignmentProvider>
        )}
        <BemCssTransition
          in={isComplex || (!!selectedAsset.id && !!selectedLayerId)}
          transitionClassName={block('layer-order-controls-container')}
          timeout={300}
          mountOnEnter
          unmountOnExit
        >
          <LayerOrderControls />
        </BemCssTransition>
      </div>
    </Tooltip>
  );
};

export default EditorCanvas;

import React, { useContext } from 'react';
import { ProjectRowContextType } from './types';

interface ProjectRowProviderProps extends ProjectRowContextType {
  children?: React.ReactNode;
}

const ProjectRowContext = React.createContext<
  ProjectRowContextType | undefined
>(undefined);

export const ProjectRowProvider: React.FC<ProjectRowProviderProps> = ({
  projectId,
  children,
}) => (
  <ProjectRowContext.Provider value={{ projectId }}>
    {children}
  </ProjectRowContext.Provider>
);

export function useProjectRow() {
  const context = useContext(ProjectRowContext);

  if (context === undefined) {
    throw new Error('useProjectRow must be used within ProjectRowProvider');
  }

  return context;
}

import { Spacer, Thickness } from '@sparemin/blockhead';
import React from 'react';

import useStyleToggle from '../hooks/useStyleToggle';
import { TextColorPicker, TextSlider } from '../TextControls';
import ToolbarPopoverButton from '../ToolbarPopoverButton';
import { textControlsBlock as block } from '../utils';

import { TextOutline } from './types';

interface OutlineInputProps {
  value?: TextOutline;
  defaultValue: TextOutline;
  onChange: (value: TextOutline) => void;
}

/**
 * Coeficient for adjusting the text stroke to the font size.
 */
const TEXT_OUTLINE_THICKNESS_VALUE_EM_COEF = 0.04;

const OutlineInput: React.FunctionComponent<OutlineInputProps> = props => {
  const { value, defaultValue, onChange } = props;

  const { onToggle, isEnabled } = useStyleToggle({
    value,
    defaultValue,
    onChange,
  });

  const handleColorChange = (color: TextOutline['color']): void => {
    onChange({
      ...value,
      color,
    });
  };

  const handleThicknessChange = (nextValue: TextOutline['thickness']): void => {
    onChange({
      ...value,
      thickness: nextValue,
    });
  };

  const handleClickOutlineButton = React.useCallback((): void => {
    onToggle();
  }, [onToggle]);

  return (
    <ToolbarPopoverButton
      isEnabled={isEnabled}
      offset={8}
      placement="top"
      strategy="fixed"
      popoverContent={
        <Spacer
          orientation="vertical"
          space={1}
          className={block('controls-popover-content')}
        >
          <TextColorPicker value={value?.color} onChange={handleColorChange} />

          <TextSlider
            label="Thickness"
            minValue={0}
            maxValue={TEXT_OUTLINE_THICKNESS_VALUE_EM_COEF * 10}
            step={TEXT_OUTLINE_THICKNESS_VALUE_EM_COEF}
            value={value?.thickness}
            icon={<Thickness />}
            onChange={handleThicknessChange}
          />
        </Spacer>
      }
      onClickTrigger={handleClickOutlineButton}
    >
      <span
        className={block('outline-icon')}
        style={{ borderColor: value?.color }}
      />
      Outline
    </ToolbarPopoverButton>
  );
};

export default OutlineInput;

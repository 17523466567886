import { useTwitterLogin, UseTwitterLoginConfig } from '@sparemin/auth';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'underscore';
import { tokenSelector } from 'redux/modules/auth';
import {
  clearTwitterUser,
  twitterAuthorizeSuccess,
  waitForTwitterProfile,
} from 'redux/modules/social';
import { FatalError } from 'utils/FatalError';

export interface UseTwitterAuthenticatorConfig
  extends Pick<UseTwitterLoginConfig, 'force' | 'onError'> {
  onAuthSuccess?: () => void;
}

export default function useTwitterAuthenticator({
  onAuthSuccess,
  onError,
  force,
}: UseTwitterAuthenticatorConfig) {
  const dispatch = useDispatch();
  const spareminToken = useSelector(tokenSelector);

  return useTwitterLogin({
    force,
    spareminToken,
    onError,
    onSuccess: async data => {
      dispatch(clearTwitterUser());

      if (data) {
        dispatch(
          twitterAuthorizeSuccess(
            pick(
              data.tokenInfo,
              'accessToken',
              'accessTokenSecret',
              'idToken',
              'providerUserId',
            ),
          ),
        );
      }

      try {
        await dispatch(waitForTwitterProfile());
        onAuthSuccess?.();
      } catch (error) {
        throw new FatalError(error.message);
      }
    },
  });
}

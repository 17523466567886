import React from 'react';
import HistoricalColorPicker from 'containers/HistoricalColorPicker';
import useTextOverlayAdvancedAnimation from '../../useTextOverlayAdvancedAnimation';
import { block } from '../utils';
import KaraokeAnimationControlContainer from './KaraokeAnimationControlContainer';

export interface KaraokeAnimationColorSelectorProps {}

const KaraokeAnimationColorSelector: React.FC<KaraokeAnimationColorSelectorProps> = () => {
  const {
    selectedAdvancedAnimation,
    onWordEmphasisColorChange,
  } = useTextOverlayAdvancedAnimation();

  return (
    <KaraokeAnimationControlContainer title="Color">
      <HistoricalColorPicker
        swatchClassName={block('animation-color-picker')}
        color={selectedAdvancedAnimation?.properties?.wordEmphasisColor}
        onChangeComplete={onWordEmphasisColorChange}
      />
    </KaraokeAnimationControlContainer>
  );
};

export default KaraokeAnimationColorSelector;

import React from 'react';

import TextOverlayModal, {
  ITextOverlayV2,
  TextOverlayFooterExternalControls,
  TextOverlayModalProps,
  TextOverlayV2,
} from 'blocks/TextOverlayModal/v2';
import { DeepImmutableMap } from 'types';

import EditorModalStaticBackground from '../EditorModalStaticBackground';

type PickedModalProps = Pick<
  TextOverlayModalProps,
  | 'defaultText'
  | 'editable'
  | 'onExited'
  | 'show'
  | 'showEmoji'
  | 'subTitle'
  | 'textOverlay'
  | 'title'
>;

export interface TextAssetModalProps extends PickedModalProps {
  aspectRatio: number;
  assetId: string;
  backgroundColor: string;
  isEdit: boolean;
  isV2CompatibilityMode: boolean;
  onCancel?: () => void;
  onDelete?: () => void;
  onSubmit?: (overlay: DeepImmutableMap<ITextOverlayV2>) => void;
  onSwitchToLegacyModal?: (partialOverlay: TextOverlayV2) => void;
}

const TextAssetModal: React.FC<TextAssetModalProps> = props => {
  const {
    aspectRatio,
    assetId,
    backgroundColor,
    defaultText,
    editable = true,
    isEdit,
    isV2CompatibilityMode,
    onCancel,
    onDelete,
    onExited,
    onSubmit,
    onSwitchToLegacyModal,
    show,
    showEmoji = true,
    subTitle,
    textOverlay,
    title,
  } = props;

  const DeleteOverlayButton = React.useMemo(() => {
    if (!isEdit) {
      return null;
    }

    return (
      <TextOverlayFooterExternalControls.DeleteOverlayButton
        onDelete={onDelete}
      />
    );
  }, [isEdit, onDelete]);

  return (
    <TextOverlayModal
      background={<EditorModalStaticBackground assetId={assetId} />}
      footerLeftActions={DeleteOverlayButton}
      isCompatibilityMode={isV2CompatibilityMode}
      onExited={onExited}
      onHide={onCancel}
      onSwitchToLegacy={onSwitchToLegacyModal}
      onSubmit={onSubmit}
      showPresetSelector
      submitButtonText={isEdit ? 'Save Changes' : 'Add To Video'}
      shouldDisableSubmitIfEmpty
      {...{
        aspectRatio,
        backgroundColor,
        defaultText,
        editable,
        show,
        showEmoji,
        subTitle,
        textOverlay,
        title,
      }}
    />
  );
};

export default TextAssetModal;

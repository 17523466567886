import cn from 'classnames';
import React from 'react';
import { headerBlock as block } from '../../utils';

export type HeaderContainerProps = {
  children?: React.ReactNode;
  className?: string;
};

const HeaderContainer: React.FC<HeaderContainerProps> = ({
  children,
  className,
}) => <div className={cn(block('container'), className)}>{children}</div>;

export default HeaderContainer;

import cn from 'classnames';
import React from 'react';

import Button, { ButtonProps } from 'components/Button';

import Tooltip from 'components/Tooltip';
import { ctaBlock as block } from '../utils';

type PickedButtonProps = Pick<
  ButtonProps,
  'children' | 'className' | 'disabled' | 'onClick' | 'theme'
>;

export interface ClipCtaButtonProps extends PickedButtonProps {
  icon?: React.ReactNode;
  id?: string;
  tooltip?: string;
}

const ClipCtaButton: React.FC<ClipCtaButtonProps> = ({
  className,
  children,
  disabled,
  icon,
  id,
  onClick,
  theme,
  tooltip,
}) => (
  <Tooltip
    content={tooltip}
    id={id}
    placement="top"
    show={!tooltip ? false : undefined}
  >
    <Button
      className={cn(block({ 'icon-only': !children && !!icon }), className)}
      shape="chip"
      {...{ disabled, onClick, theme }}
    >
      {React.isValidElement(icon) && (
        <>
          {React.cloneElement(icon, {
            className: icon.props.className,
          })}
        </>
      )}
      {children}
    </Button>
  </Tooltip>
);

export default ClipCtaButton;

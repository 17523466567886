import { LocationDescriptor } from 'history';
import { Map, RecordOf } from 'immutable';
import mixpanel from 'mixpanel-browser';
import { isBoolean, isString, isUndefined } from 'underscore';
import { ClipSelectMediaType } from 'blocks/ClipSelect/types';
import { OnboardingName } from 'blocks/OnboardingAnimations/types';
import { WizardStep } from 'blocks/OnboardingWizard/types';
import { Discount } from 'blocks/Pricing/constants';
import { SubscriptionPeriod } from 'blocks/Pricing/types';
import { APP_VERSION } from 'config';
import { Step } from 'containers/ProjectWizards/AudiogramWizard/types';
import {
  PurchaseableTier,
  SubTier,
  Tier,
} from 'redux/middleware/api/plan-service/types';
import {
  discountSelector,
  headlinerWatermarkEnabledSelector,
  transcriptionBalanceMinutesSelector,
} from 'redux/modules/pricing/selectors';
import { Subscription } from 'redux/modules/pricing/types';
import { projectIdSelector } from 'redux/modules/project/selectors';
import { Dispatch, GetState, ThunkAction } from 'redux/types';
import {
  AnyObject,
  AutogramClipSelectionMethod,
  CaptionFormat,
  ClipSelectPageSource,
  CompleteWizardProps,
  ExportUpgradeFrom,
  HeadlinerUsage,
  MobilePlatform,
  PlanCancellationReason,
  ProjectCreationMethod,
  ProjectLength,
  Size,
  TranscriptionTimeBankUpgradeSource,
  Unlock1080pSource,
} from 'types';
import { getAspectRatioNameFromRatio } from 'utils/aspect-ratio';
import { omitUndefined } from 'utils/collections';
import * as routes from 'utils/routes';
import { camelToPascal, capitalize, kebabToCamel } from '../../../utils/string';
import {
  favoritePodcastsSelector,
  podcastSubscriptionsSelector,
  podcastWorkflowTemplateSelector,
  podcastWorkflowTemplatesSelector,
  projectsDetailsSelector,
} from '../entities/selectors';
import { experimentVariantSelector } from '../experiments/selectors';
import {
  favoritePodcastIdsSelector,
  searchHandlerIntegrationSelector,
  selectedEpisodeSelector,
  selectedPodcastSelector,
} from '../podcast-search/selectors';
import { SurveyData } from '../survey/types';
import { Type } from './action-types';
import * as constants from './constants';
import {
  authSourceSelector,
  completeWizardPropsSelector,
  downloadPageActionPropertiesSelector,
  downloadPagePropertiesSelector,
} from './selectors';
import {
  AnimateImageButtonState,
  AnimateImageProvider,
  ClickAudiogramVideoTypesModalProperties,
  ClickCloudSaveProperties,
  ClickCopyVideoUrlProperties,
  ClickDownloadLinkProperties,
  ClickDownloadVideoProperties,
  ClickPostFacebookVideoProperties,
  ClickPostLinkedinVideo,
  ClickPostThreadsVideo,
  ClickPostTiktokVideo,
  ClickPostYoutubeVideo,
  ClickProFeatureProperties,
  ClickProTemplateProperties,
  ClickSaveTemplateProperties,
  ClickSelfServeDiscoBannerSource,
  ClickTweetVideoProperties,
  CompatibilityModalSource,
  CompleteProUpgradeProperties,
  CompleteWizardEventProperties,
  DownloadPageDashboardLinkSource,
  DownloadPageLoadedProperties,
  EddyPopperShownState,
  EddyPromoModalCTAProperties,
  EditAutomationProperties,
  EpisodeTranscriptButtonState,
  ExportVideoProperties,
  FreeTrialCtaProperties,
  FreeTrialModalName,
  FullLengthTemplatesModalProperties,
  InstagramPostClick,
  InstagramPostVideoModalView,
  MediaUploadSource,
  MixpanelAudiogramDestination,
  MixpanelWizardRouterSelection,
  OnboardingFlowNextProperties,
  OnWizardNextProps,
  PodcastPersonalizationModalButtonClick,
  PodcastPersonalizationModalState,
  PostLinkedinVideoDestination,
  ProcessAudioEvent,
  UnlockThisFeatureModal,
  UpgradePlanLocation,
  UpgradePlanSource,
  UploadProps,
  YouTubeAccountVerificationCheckProperties,
} from './types';

import {
  getAutogramStartingPoint,
  getAutoPost,
  getLanguageProperty,
  getRetryPeriodBannerSource,
  getSourceFromLocation,
  getWizardTypeFromLocation,
} from './utils';

export const clearMixpanel = (): ThunkAction<void> => dispatch =>
  dispatch({ type: Type.MIXPANEL_CLEAR });

const setMixpanelUsername = (username: string) => mixpanel.identify(username);

export const setMixpanelAlias = (username: string) => mixpanel.alias(username);

function capitalizeKeys(map: Map<string, string>) {
  if (isUndefined(map)) {
    return map;
  }

  return Object.keys(map).reduce((acc, cur) => {
    acc[capitalize(cur)] = map[cur];
    return acc;
  }, {});
}

export const clearMixpanelUserInfo = () => {
  mixpanel.reset();
};

export const setMixpanelUserInfo = (
  username: string,
  userId: string,
  mySubscription: RecordOf<Subscription>,
  surveyData: SurveyData,
) => {
  const planProperties = {};
  const surveyProperties = {};

  if (mySubscription.plan) {
    // before sending these dates, we need to convert them into milliseconds
    const purchaseDateInMillis = mySubscription.purchaseDateSec * 1000;
    const planEndInMillis = mySubscription.planEndSec * 1000;

    if (purchaseDateInMillis) {
      planProperties['Plan Purchase Date'] = new Date(
        purchaseDateInMillis,
      ).toISOString();
    }
    planProperties['Plan Type'] = mySubscription.plan;
    // calculate as milliseconds
    if (planEndInMillis) {
      planProperties['Plan Will Renew At'] = new Date(
        planEndInMillis,
      ).toISOString();
    }
  }

  if (surveyData) {
    Object.assign(surveyProperties, {
      'What You Do': surveyData.tellUsWhatYouDo,
      'Use For Podcast': surveyData.podcastYesNo,
    });
  }

  mixpanel.register({
    ...planProperties,
    AppVersion: APP_VERSION,
  });

  setMixpanelUsername(username);

  mixpanel.people.set({
    $email: username,
    $last_login: new Date(),
    user_id: userId,
    ...planProperties,
    ...surveyProperties,
  });

  mixpanel.people.set_once('firstLogin', new Date().toISOString());
};

const setMixpanelNewUserReferrerId = (referralKey: string) => {
  if (!referralKey) return;
  mixpanel.people.set({
    ReferrerID: referralKey,
  });
};

export const setMixpanelABTestingExperimentPlay = (
  experimentName: string,
  variantName: string,
  id: string = 'Experiment Play',
) => mixpanel.track(id, { Experiment: experimentName, Variant: variantName });

export const setMixpanelABTestingExperimentWin = (
  experimentName: string,
  variantName: string,
  id: string = 'Experiment Win',
) => mixpanel.track(id, { Experiment: experimentName, Variant: variantName });

export const setMixpanelWizardRouterSelection = (
  value: MixpanelWizardRouterSelection,
) =>
  mixpanel.people.set({
    'Wizard Router Selection': capitalize(value),
  });

export const setMixpanelWhatYouDo = (value: HeadlinerUsage) =>
  mixpanel.people.set({
    'What You Do': value,
  });

export const setMixpanelPodcast = (value: boolean) =>
  mixpanel.people.set({
    'Use For Podcast': value ? 'yes' : 'no',
  });

export const setMixpanelWaveformPresetsValue = (count: number) => {
  mixpanel.people.set({
    WaveformPresetsValue: count,
  });
};

export const setExperimentVariants = (
  experimentNames: string[],
): ThunkAction<void> => (_, getState) => {
  const experimentMap = experimentNames.reduce((acc, experimentName) => {
    const variant = experimentVariantSelector(experimentName)(getState());
    return variant ? { ...acc, [experimentName]: variant } : acc;
  }, {});
  mixpanel.people.set(experimentMap);
};

export const setCollectFeedProperties = (): ThunkAction<void> => (
  _,
  getState,
) => {
  const podcasts = favoritePodcastsSelector(getState());
  const podcastIds = favoritePodcastIdsSelector(getState()) ?? [];
  const podcastTitles = podcastIds.map(id => {
    return podcasts.get(id)?.get('title');
  });

  mixpanel.people.set(
    omitUndefined({
      FeedsSet: podcastTitles.length,
      DefaultFeed: podcastTitles,
    }),
  );
};

export const sendMixpanelEvent = (event: string, props = undefined) => {
  // props is expected to have lower cased keys, but mixpanel props
  // use capitalized keys
  const capitalizedProps = capitalizeKeys(props);

  return {
    type: Type.MIXPANEL_SEND,
    meta: {
      mixpanel: {
        event,
        props: {
          ...capitalizedProps,
          AppName: 'Make',
        },
      },
    },
  };
};

export const onWebappLoad = (username: string) => setMixpanelUsername(username);

export const onReclipButtonVisible = () =>
  sendMixpanelEvent(constants.RECLIP_VISIBLE);

export const onSignup = (
  tier: Tier.FREE | Tier.PRO | Tier.BASIC | Tier.UNLIMITED,
  referralKey?: string,
) => {
  setMixpanelNewUserReferrerId(referralKey);
  return sendMixpanelEvent(constants.SIGNUP, {
    plan: tier,
    referrer: referralKey,
  });
};

export const onSignupComplete = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const authSource = authSourceSelector(getState());
  mixpanel.people.set({
    SignUpMethod: authSource,
  });
  dispatch(
    sendMixpanelEvent(constants.SIGNUP_COMPLETE, {
      source: authSource,
      ...getLanguageProperty(),
    }),
  );
};

export const onSignupError = (error: string) =>
  sendMixpanelEvent(constants.SIGNUP_ERROR, { error });

export const onForgotPassword = () =>
  sendMixpanelEvent(constants.FORGOT_PASSWORD);

export const onLogin = (username: string) => (dispatch, getState) =>
  dispatch(
    sendMixpanelEvent(constants.LOGIN_EVENT, {
      username,
      source: authSourceSelector(getState()),
    }),
  );

export const onCreateUser = (username: string) => (dispatch, getState) => {
  dispatch(
    sendMixpanelEvent(constants.CREATE_USER_EVENT, {
      username,
      source: authSourceSelector(getState()),
    }),
  );
};

export const onLogout = () => sendMixpanelEvent(constants.LOGOUT_EVENT);

const clickStartNewProject = (type: string, properties: AnyObject = {}) =>
  sendMixpanelEvent(constants.CLICK_START_NEW_PROJECT, {
    type,
    source: getSourceFromLocation(),
    ...properties,
  });

export const onClickAudiogramProject = () => clickStartNewProject('audiogram');

export const onClickTextToVideoProject = () =>
  clickStartNewProject('textToVideo');

export const onClickVideoTranscriptProject = () =>
  clickStartNewProject('videoTranscription');

export const onClickBlankProject = () => clickStartNewProject('blank');

export const onClickFullEpisode = () => clickStartNewProject('episode');

export const onClickRecommendedProject = () =>
  clickStartNewProject('automated');

export const onClickAutomationWizard = () =>
  clickStartNewProject('autoVideosMVP');

export const onStartAudioProject = (length: ProjectLength) =>
  clickStartNewProject('audio', { length });

export const onStartVideoClipWizard = () =>
  clickStartNewProject('videoClipWizard');

export const onOpenTemplateWizard = (
  templateId: string,
  templateName: string,
) => clickStartNewProject('templateWizard', { templateId, templateName });

export const onOpenTemplateInEditor = () => clickStartNewProject('template');

export const onCompleteWizard = (
  wizardProps: CompleteWizardProps,
): ThunkAction<void> => (dispatch, getState) => {
  const {
    autopostPlatform,
    frequency,
    submissionType,
    templateId,
    videoType,
    transcription,
    waveformPrefId,
    clipSelectionMethod,
    videoPodcastType,
    ...rest
  } = wizardProps;
  const template = podcastWorkflowTemplatesSelector(getState())?.get(
    templateId,
  );

  const props: CompleteWizardEventProperties = {
    ...rest,
    autoPost: getAutoPost(autopostPlatform),
    templateId,
    videoType,
    videoPodcastType,
    waveSource: waveformPrefId ? 'Preset' : 'New',
    headlinerwatermarkenabled: headlinerWatermarkEnabledSelector(getState()),
  };

  if (template) {
    props.templateName = template.get('name') || template.get('displayName');
    props.templateType = template.get('templateType');
  }

  if (transcription !== undefined) {
    props.transcription = transcription ? 'show' : 'hide';
  }

  if (submissionType !== undefined) {
    props.buttonClick = submissionType === 'export' ? 'export' : 'editor';
  }

  if (frequency !== undefined) {
    props.startingPoint = getAutogramStartingPoint(frequency);
  }

  if (clipSelectionMethod) {
    const clipSelectionMap: Record<
      AutogramClipSelectionMethod,
      CompleteWizardEventProperties['clipSelectionMethod']
    > = {
      [AutogramClipSelectionMethod.MANUAL]: 'PreSelect',
      [AutogramClipSelectionMethod.SMART]: 'SmartClips',
      [AutogramClipSelectionMethod.SOUNDBITE_TAG]: 'Soundbite',
    };
    props.clipSelectionMethod = clipSelectionMap[clipSelectionMethod];
  }

  dispatch(sendMixpanelEvent(constants.COMPLETE_WIZARD, omitUndefined(props)));

  // set props in store for onEditorLoaded event
  dispatch({
    type: Type.MIXPANEL_SET_COMPLETE_WIZARD_PROPS,
    payload: { props },
  });
};

export const onEditAutomation = (
  editProps: EditAutomationProperties,
): ThunkAction<void> => dispatch => {
  const {
    aspectRatio,
    autopostPlatform,
    startingPoint,
    length,
    mixpanelPlatform,
    templateId,
    templateName,
    templateType,
  } = editProps;

  const props: CompleteWizardEventProperties = {
    aspectRatio,
    autoPost: autopostPlatform,
    startingPoint,
    length,
    platform: mixpanelPlatform,
    templateId,
    templateName,
    templateType,
    type: 'editAutoVideo',
  };

  dispatch(sendMixpanelEvent(constants.COMPLETE_WIZARD, omitUndefined(props)));
};

export const onEditorLoaded = (): ThunkAction<void> => async (
  dispatch,
  getState,
) => {
  // read props from store we set in onCompleteWizard
  const props = completeWizardPropsSelector(getState());

  await dispatch(sendMixpanelEvent(constants.EDITOR_LOADED, { ...props }));

  // after sending props, we don't need those props anymore, so clear it
  dispatch(clearMixpanel());
};

export const onCancelWizard = (
  type: string,
  step: string,
  length?: ProjectLength,
) => {
  const props = omitUndefined({ type, step, length });
  return sendMixpanelEvent(constants.CANCEL_WIZARD, props);
};

export const onCompleteAudioWizard = (
  length: ProjectLength,
): ThunkAction<void> => (dispatch, getState) => {
  const headlinerwatermarkenabled = headlinerWatermarkEnabledSelector(
    getState(),
  );

  dispatch(
    sendMixpanelEvent(constants.COMPLETE_WIZARD, {
      headlinerwatermarkenabled,
      length,
      type: 'audio',
    }),
  );
};

const getProjectSource = (source: string) => {
  switch (source) {
    case '/automation':
      return 'Automations Tab';
    case '/projects':
      return 'Projects Tab';
    default:
      return undefined;
  }
};

export const onOpenProject = (
  projectId: string,
  referrer: string,
): ThunkAction<void> => (dispatch, getState) => {
  const source = getProjectSource(referrer);
  const projects = projectsDetailsSelector(getState());
  const project = projects?.get(projectId);
  const latestWidgetId = project?.getIn([
    'latestSuccessfulVideo',
    'widgetUuid',
  ]);

  if (source) {
    dispatch(
      sendMixpanelEvent(constants.OPEN_PROJECT, {
        id: projectId,
        source,
        exported: latestWidgetId ? 'Yes' : 'No',
      }),
    );
  }
};

export const onAudioUploadLongError = () =>
  sendMixpanelEvent(constants.AUDIO_UPLOAD_LONG_ERROR);

export const onClaimPodcastBanner = (buttonClick: 'CTA' | 'HyperLink') =>
  sendMixpanelEvent(constants.CLAIM_PODCAST_BANNER, { buttonClick });

export const onClaimPodcastCompleteFlow = (
  outcome: 'Success' | 'FailureIncorrectEmail' | 'FailureGeneralError',
) => sendMixpanelEvent(constants.CLAIM_PODCAST_SUBMIT, { outcome });

export const onChangeCaptionState = (enabled: boolean) =>
  sendMixpanelEvent(constants.CHANGE_CAPTION_STATE, { enabled });

export const onNameProject = (name: string) =>
  sendMixpanelEvent(constants.NAME_PROJECT, { name });

export const onWatchInfoVideo = (name: string) =>
  sendMixpanelEvent(constants.WATCH_INFO_VIDEO, { name });

export const onImageSearch = (engine: string, query: string) =>
  sendMixpanelEvent(constants.IMAGE_SEARCH, { engine, query });

export const onVideoSearch = (engine: string, query: string) =>
  sendMixpanelEvent(constants.VIDEO_SEARCH, { engine, query });

export const onGifSearch = (engine: string, query: string) =>
  sendMixpanelEvent(constants.GIF_SEARCH, { engine, query });

export const onClickSearchImage = (provider: string, url: string) =>
  sendMixpanelEvent(constants.CLICK_SEARCH_IMAGE, { provider, url });

export const onClickSearchVideo = (provider: string, url: string) =>
  sendMixpanelEvent(constants.CLICK_SEARCH_VIDEO, { provider, url });

export const onClickSearchGif = (provider: string, url: string) =>
  sendMixpanelEvent(constants.CLICK_SEARCH_GIF, { provider, url });

export const onAddAudiogramImage = () =>
  sendMixpanelEvent(constants.ADD_AUDIOGRAM_IMAGE);

export const onAddEpisodeImage = () =>
  sendMixpanelEvent(constants.ADD_EPISODE_IMAGE);

export const onErrorNotification = (message: string, code?: string) =>
  sendMixpanelEvent(constants.ERROR_NOTIFICATION, { message, code });

export const onClickSampleProject = (source: string) =>
  sendMixpanelEvent(constants.CLICK_SAMPLE_PROJECT, { source });

const getCopyProjectSourceFromLocation = () => {
  if (routes.isDownloadActive()) {
    return 'DLP';
  }
  if (routes.isEditorActive()) {
    return 'editor';
  }
  if (routes.isProjectsActive()) {
    return 'projectsPage';
  }

  return null;
};

export const onCopyProject = (
  id: string,
  fromAspectRatio: string,
  toAspectRatio: string,
) =>
  sendMixpanelEvent(constants.COPY_PROJECT, {
    fromAspectRatio,
    id,
    toAspectRatio,
    source: getCopyProjectSourceFromLocation(),
  });

export const onAddCaption = (startTime: number, endTime: number) =>
  sendMixpanelEvent(constants.ADD_CAPTION, { startTime, endTime });

export const onDeleteCaption = () =>
  sendMixpanelEvent(constants.DELETE_CAPTION);

export const onAddEmoji = (emoji: string) =>
  sendMixpanelEvent(constants.ADD_EMOJI, { emoji });

export const onWizardNext = (props: OnWizardNextProps): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const { templateId } = props;

  const properties: any = { ...props };

  if (templateId) {
    const templates = podcastWorkflowTemplatesSelector(getState());
    const template = templates?.get(templateId);
    properties.templateName =
      template?.get('name') || template?.get('displayName');
    properties.templateType = template?.get('templateType');
  }

  properties['Time Left'] = transcriptionBalanceMinutesSelector(getState());

  dispatch(sendMixpanelEvent(constants.CLICK_WIZARD_NEXT, properties));
};

export const onAdjustColors = () =>
  sendMixpanelEvent(constants.CLICK_ADJUST_COLORS);

export const onBackToTemplateStyles = (
  templateId: string,
): ThunkAction<void> => (dispatch, getState) => {
  const templates = podcastWorkflowTemplatesSelector(getState());
  const template = templates?.get(templateId);
  dispatch(
    sendMixpanelEvent(constants.CLICK_BACK_TO_TEMPLATE_STYLES, {
      templateId: template?.get('templateId'),
      templateName: template?.get('name') || template?.get('displayName'),
      templateType: template?.get('templateType'),
    }),
  );
};

export const onClickSelfServeDiscoBanner = (
  source: ClickSelfServeDiscoBannerSource,
) => sendMixpanelEvent(constants.CLICK_SELF_SERVE_DISCO_BANNER, { source });

export const onCopyCaption = (id: string) =>
  sendMixpanelEvent(constants.COPY_CAPTION, { id });

export const onCopyAllCaptions = () =>
  sendMixpanelEvent(constants.COPY_ALL_CAPTIONS);

export const onEddyPopperShown = (state: EddyPopperShownState) =>
  sendMixpanelEvent(constants.EDDY_POPPER_SHOWN, { state });

export const onEddyPopperClicked = (
  buttonClick: 'Visit' | 'Dismiss' | 'CreateAssets',
) => sendMixpanelEvent(constants.EDDY_POPPER_CLICKED, { buttonClick });

export const onApplyTextTemplate = (templateType: string, title: string) =>
  sendMixpanelEvent(constants.APPLY_TEXT_TEMPLATE, { templateType, title });

export const onExportVideo = (props: ExportVideoProperties) =>
  sendMixpanelEvent(constants.EXPORT_VIDEO, props);

export const onClickDownloadLink = (props: ClickDownloadLinkProperties) =>
  sendMixpanelEvent(constants.CLICK_DOWNLOAD_LINK, props);

export const invalidBrowserSize = (
  size: Partial<Size<number>>,
  minSize: Partial<Size<number>>,
) => sendMixpanelEvent(constants.BROWSER_SIZE_INVALID_EVENT, { size, minSize });

export const validBrowserSize = (
  size: Partial<Size<number>>,
  minSize: Partial<Size<number>>,
) => sendMixpanelEvent(constants.BROWSER_SIZE_VALID_EVENT, { size, minSize });

export const onReplaceAsset = (assetType: string) =>
  sendMixpanelEvent(constants.REPLACE_ASSET, { assetType });

export const onCickRevertProject = (
  timeDifference: number,
): ThunkAction<void> => (dispatch: Dispatch, getState: GetState) => {
  const projectId = projectIdSelector(getState());

  dispatch(
    sendMixpanelEvent(constants.CLICK_REVERT_PROJECT, {
      timeDifference,
      id: projectId,
    }),
  );
};

export const onClickMarketingLink = () =>
  sendMixpanelEvent(constants.CLICK_MARKETING_LINK);

export const onAppError = () => sendMixpanelEvent(constants.ON_APP_ERROR);

export const onClickErrorRefresh = () =>
  sendMixpanelEvent(constants.CLICK_ERROR_REFRESH);

export const onFileUpload = (
  props: UploadProps,
): ThunkAction<void> => dispatch => {
  const { source } = props;
  const getUploadProperties = () => {
    switch (source) {
      case 'localFile': {
        const { file, ...rest } = props;

        return {
          fileSize: file?.size,
          fileType: file?.type,
          sourceDetail: file?.name,
          ...rest,
        };
      }

      case 'youtube': {
        const { url } = props;

        return { ...props, sourceDetail: url };
      }

      case 'googleDrive':
      case 'zoom': {
        const { fileName } = props;

        return { ...props, sourceDetail: fileName };
      }

      default: {
        return { ...props };
      }
    }
  };

  const properties = getUploadProperties();

  source && dispatch(sendMixpanelEvent(constants.FILE_UPLOAD, properties));
};

export const onStartWalkthrough = (flow: OnboardingName) =>
  sendMixpanelEvent(constants.START_WALKTHROUGH, { flow });

export const onClickWalkthroughNext = (flow: OnboardingName, step: string) =>
  sendMixpanelEvent(constants.CLICK_WALKTHROUGH_NEXT, { flow, step });

export const onClickWalkthroughBack = (flow: OnboardingName, step: string) =>
  sendMixpanelEvent(constants.CLICK_WALKTHROUGH_BACK, { flow, step });

export const onCompleteWalkthrough = (flow: OnboardingName) =>
  sendMixpanelEvent(constants.COMPLETE_WALKTHROUGH, { flow });

export const onCancelWalkthrough = (flow: OnboardingName, source: string) =>
  sendMixpanelEvent(constants.CANCEL_WALKTHROUGH, { flow, source });

export const onPostFacebookVideoSuccess = () =>
  sendMixpanelEvent(constants.FACEBOOK_POST_VIDEO_SUCCESS);

export const onClickPostYoutubeVideo = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const properties: ClickPostYoutubeVideo = {
    ...downloadPageActionPropertiesSelector(getState()),
  };
  dispatch(sendMixpanelEvent(constants.CLICK_POST_YOUTUBE_VIDEO, properties));
};

export const onPostYoutubeVideoSuccess = () => (dispatch: Dispatch) =>
  dispatch(sendMixpanelEvent(constants.YOUTUBE_POST_VIDEO_SUCCESS));

export const onClickPostLinkedinVideo = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const properties: ClickPostLinkedinVideo = {
    ...downloadPageActionPropertiesSelector(getState()),
  };
  dispatch(sendMixpanelEvent(constants.CLICK_POST_LINKEDIN_VIDEO, properties));
};

export const onClickPostThreadsVideo = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const properties: ClickPostThreadsVideo = {
    ...downloadPageActionPropertiesSelector(getState()),
  };

  dispatch(sendMixpanelEvent(constants.CLICK_POST_THREADS_VIDEO, properties));
};

export const onClickPostTikTokVideo = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const properties: ClickPostTiktokVideo = {
    ...downloadPageActionPropertiesSelector(getState()),
  };
  dispatch(sendMixpanelEvent(constants.CLICK_POST_TIKTOK_VIDEO, properties));
};

export const onInstagramPostModalLoad = (view: InstagramPostVideoModalView) =>
  sendMixpanelEvent(constants.LOAD_INSTAGRAM_POST_MODAL, { view });

export const onClickInstagramPostModalCTA = (buttonClick: InstagramPostClick) =>
  sendMixpanelEvent(constants.CLIK_INSTAGRAM_POST, {
    buttonClick,
  });

export const onPostLinkedinVideoSuccess = (
  destination: PostLinkedinVideoDestination,
) => (dispatch: Dispatch) =>
  dispatch(
    sendMixpanelEvent(constants.LINKEDIN_POST_VIDEO_SUCCESS, {
      destination,
    }),
  );

export const onDownloadPageDashboardLinkClick = (
  source: DownloadPageDashboardLinkSource,
) => (dispatch: Dispatch) =>
  dispatch(
    sendMixpanelEvent(constants.CLIK_DOWNLOAD_PAGE_DASHBOARD_LINK, {
      source,
    }),
  );

export const onTiktokSubmitForUpload = () => (dispatch: Dispatch) =>
  dispatch(sendMixpanelEvent(constants.TIKTOK_SUBMIT_FOR_UPLOAD));

export const onTikTokVideoUploadedSuccessfully = () => (dispatch: Dispatch) =>
  dispatch(sendMixpanelEvent(constants.TIK_TOK_VIDEO_UPLOADED_SUCCESSFULLY));

export const onInstagramSubmitForUpload = () => (dispatch: Dispatch) =>
  dispatch(sendMixpanelEvent(constants.INSTAGRAM_SUBMIT_FOR_UPLOAD));

export const onInstagramVideoUploadedSuccessfully = () => (
  dispatch: Dispatch,
) =>
  dispatch(sendMixpanelEvent(constants.INSTAGRAM_VIDEO_UPLOADED_SUCCESSFULLY));

export const onThreadsVideoUploadedSuccessfully = () => (dispatch: Dispatch) =>
  dispatch(sendMixpanelEvent(constants.THREADS_VIDEO_UPLOADED_SUCCESSFULLY));

export const onFacebookVideoUploadedSuccessfully = () => (dispatch: Dispatch) =>
  dispatch(sendMixpanelEvent(constants.FACEBOOK_VIDEO_UPLOADED_SUCCESSFULLY));

export const onTwitterSubmitForUpload = () => (dispatch: Dispatch) =>
  dispatch(sendMixpanelEvent(constants.TWITTER_SUBMIT_FOR_UPLOAD));

export const onTwitterVideoUploadedSuccessfully = () => (dispatch: Dispatch) =>
  dispatch(sendMixpanelEvent(constants.TWITTER_VIDEO_UPLOADED_SUCCESSFULLY));

export const onClickInstagramButton = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const properties = downloadPageActionPropertiesSelector(getState());
  dispatch(sendMixpanelEvent(constants.CLICK_INSTAGRAM_BUTTON, properties));
};

export const onClickInstagramMobileAppLink = (platform: MobilePlatform) => (
  dispatch: Dispatch,
) =>
  dispatch(
    sendMixpanelEvent(constants.CLICK_INSTAGRAM_MOBILE_APP_LINK, { platform }),
  );

export const onEddyPromoModalLoad = (source: 'audiogram') => (
  dispatch: Dispatch,
) => dispatch(sendMixpanelEvent(constants.EDDY_PROMO_MODAL_LOAD, { source }));

export const onClickKnowledgeBaseUrl = () =>
  sendMixpanelEvent(constants.CLICK_KNOWLEDGE_BASE_URL);

export const onClickUpgradePlan = (
  source: UpgradePlanSource,
  location: UpgradePlanLocation,
  billingCycle?: SubscriptionPeriod,
): ThunkAction<void> => (dispatch, getState) => {
  const discount = discountSelector(getState());

  if (discount === Discount.BOGO) {
    dispatch(onClickBogo());
  } else {
    dispatch(
      sendMixpanelEvent(constants.CLICK_UPGRADE_PLAN, {
        billingCycle,
        location,
        source,
      }),
    );
  }
};

export const onClickBogo = () => sendMixpanelEvent(constants.CLICK_BOGO);

export const onClickAdjustPlan = () =>
  sendMixpanelEvent(constants.CLICK_ADJUST_PLAN);

export const onClickCancelPlan = () =>
  sendMixpanelEvent(constants.CLICK_CANCEL_PLAN);

export const onClickTryEddyModal = (button: 'Eddy' | 'Cancel') =>
  sendMixpanelEvent(constants.CLICK_TRY_EDDY_MODAL, {
    button,
  });

export const onCompleteUpgrade = (properties: CompleteProUpgradeProperties) => {
  const {
    bogo,
    subscriptionPeriod,
    tier,
    button,
    isPayNowButtonVisible,
  } = properties;
  return sendMixpanelEvent(constants.COMPLETE_UPGRADE, {
    bogo,
    plan: subscriptionPeriod,
    tier: capitalize(tier),
    button,
    ...(isBoolean(isPayNowButtonVisible) && {
      payNowButton: isPayNowButtonVisible ? 'Shown' : 'Hidden',
    }),
  });
};

export const onCompleteDowngrade = (fromTier: Tier, toTier: Tier) =>
  sendMixpanelEvent(constants.COMPLETE_DOWNGRADE, {
    fromTier,
    toTier,
  });

export const onClickFontUpgrade = () => {
  return sendMixpanelEvent(constants.CLICK_FONT_UPGRADE, {
    from: getSourceFromLocation(),
  });
};

export const onChangeCanvasColor = () =>
  sendMixpanelEvent(constants.CHANGE_CANVAS_COLOR);

export const onCompleteProAdjusment = (
  subscriptionPeriod: SubscriptionPeriod,
) =>
  sendMixpanelEvent(constants.COMPLETE_PRO_ADJUSMENT, {
    plan: subscriptionPeriod,
  });

export const onCompleteProCancellation = (
  subscriptionPeriod: SubscriptionPeriod,
  reason: PlanCancellationReason,
  reasonDetails: string,
) =>
  sendMixpanelEvent(constants.COMPLETE_PRO_CANCELLATION, {
    plan: subscriptionPeriod,
    reason,
    reasonDetails,
  });

export const onClickSaveTemplate = (props?: ClickSaveTemplateProperties) => {
  return sendMixpanelEvent(constants.CLICK_SAVE_TEMPLATE, props);
};

export const onSaveTemplate = () => sendMixpanelEvent(constants.SAVE_TEMPLATE);

export const onClickExportUpgrade = (from: ExportUpgradeFrom) =>
  sendMixpanelEvent(constants.CLICK_EXPORT_UPGRADE, { from });

export const onUnlock1080pExport = (source: Unlock1080pSource) =>
  sendMixpanelEvent(constants.UNLOCK_1080P_EXPORT, { source });

export const onClickProgressBar = () =>
  sendMixpanelEvent(constants.CLICK_PROGRESS_BAR);

export const onChangeProgressBar = (display: boolean) =>
  sendMixpanelEvent(constants.CHANGE_PROGRESS_BAR, { display });

const convertSubTierToBadgeType = (
  subtier: SubTier,
): 'pro' | 'free' | 'FreeTrial' | 'Basic' => {
  switch (subtier) {
    case SubTier.FREE:
      return 'free';
    case SubTier.PRO_TRIAL:
      return 'FreeTrial';
    case SubTier.PRO_ASSIGNED:
    case SubTier.PRO_EDUCATION:
    case SubTier.PRO_PAID:
    case SubTier.PRO_FREE_TEMPORARY:
      return 'pro';
    case SubTier.BASIC_20200922_PAID:
      return 'Basic';
    default:
      return undefined;
  }
};

export const onClickGettingStartedGuide = () =>
  sendMixpanelEvent(constants.CLICK_GETTING_STARTED_GUIDE);

export const onClickHeaderBadge = (subTier: SubTier) =>
  sendMixpanelEvent(constants.CLICK_HEADER_BADGE, {
    badgeType: convertSubTierToBadgeType(subTier),
  });

export const onClickReferralSend = () =>
  sendMixpanelEvent(constants.CLICK_REFERRAL_SEND);

export const onClickReferralCopy = () =>
  sendMixpanelEvent(constants.CLICK_REFERRAL_COPY);

export const onClickPodcastSearch = (podcastSearchTerms: string) => {
  return sendMixpanelEvent(constants.CLICK_PODCAST_SEARCH, {
    podcastSearchTerms,
    source: getSourceFromLocation(),
  });
};

export const onClickPodcastName = () =>
  sendMixpanelEvent(constants.CLICK_PODCAST_NAME, {
    source: getSourceFromLocation(),
  });

export const onClickEpisodeName = () =>
  sendMixpanelEvent(constants.CLICK_EPISODE_NAME, {
    source: getSourceFromLocation(),
  });

export const onClickPodcastTab = () =>
  sendMixpanelEvent(constants.CLICK_PODCAST_TAB);

export const onDeleteAutomationWorkflow = () =>
  sendMixpanelEvent(constants.DELETE_AUTOMATED_PODCAST);

export const onClickUpgradeUstEs = (source: 'project' | 'wizard') =>
  sendMixpanelEvent(constants.CLICK_UPGRADE_UST_ES, { source });

export const onDismissPostTaskPrompt = () =>
  sendMixpanelEvent(constants.DISMISS_POST_TASK_PROMPT);

export const onClickPostTaskPrompt = () =>
  sendMixpanelEvent(constants.CLICK_POST_TASK_PROMPT);

export const onClickAutomaticPodcastVideos = () =>
  sendMixpanelEvent(constants.CLICK_AUTOMATIC_PODCAST_VIDEOS);

export const onReviewStepLoaded = () =>
  sendMixpanelEvent(constants.REVIEW_STEP_LOADED);

export const onReviewStepShare = () =>
  sendMixpanelEvent(constants.REVIEW_STEP_SHARE);

export const onClickReclipFeedbackSurvey = () =>
  sendMixpanelEvent(constants.CLICK_RECLIP_FEEDBACK_SURVEY);

export const onOnboardingWizardNext = (
  wizardStep: WizardStep,
  props?: OnboardingFlowNextProperties,
) => {
  const {
    fileRouterSelection,
    selection,
    destination,
    platform,
    videoType,
    buttonClick,
  } = props ?? {};
  const step = capitalize(wizardStep)
    .split(/(?=[A-Z])/)
    .join(' ');

  return sendMixpanelEvent(
    constants.ONBOARDING_FLOW_NEXT,
    omitUndefined({
      fileRouterSelection: capitalize(fileRouterSelection),
      selection: capitalize(selection),
      destination: capitalize(destination),
      platform: capitalize(platform),
      videoType: capitalize(videoType),
      step,
      buttonClick: capitalize(buttonClick),
    }),
  );
};

export const onDownloadPageLoaded = (
  source?: 'autoVideosMVPEmail',
): ThunkAction<void> => (dispatch, getState) => {
  const properties: DownloadPageLoadedProperties = {
    ...downloadPagePropertiesSelector(getState()),
    source,
  };
  dispatch(sendMixpanelEvent(constants.DOWNLOAD_PAGE_LOADED, properties));
};

export const onClickCloudSave = (provider: string): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const properties: ClickCloudSaveProperties = {
    ...downloadPageActionPropertiesSelector(getState()),
    provider,
  };
  dispatch(sendMixpanelEvent(constants.CLICK_CLOUD_SAVE, properties));
};

export const onClickCopyVideoUrl = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const properties: ClickCopyVideoUrlProperties = {
    ...downloadPageActionPropertiesSelector(getState()),
  };
  dispatch(sendMixpanelEvent(constants.CLICK_COPY_VIDEO_URL, properties));
};

export const onClickDownloadVideo = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const properties: ClickDownloadVideoProperties = {
    ...downloadPageActionPropertiesSelector(getState()),
  };
  dispatch(sendMixpanelEvent(constants.CLICK_DOWNLOAD_VIDEO, properties));
};

export const onClickPostFacebookVideo = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const properties: ClickPostFacebookVideoProperties = {
    ...downloadPageActionPropertiesSelector(getState()),
  };
  dispatch(sendMixpanelEvent(constants.CLICK_POST_FACEBOOK_VIDEO, properties));
};

export const onClickTweetVideo = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const properties: ClickTweetVideoProperties = {
    ...downloadPageActionPropertiesSelector(getState()),
  };

  dispatch(sendMixpanelEvent(constants.CLICK_TWEET_VIDEO, properties));
};

export const onClickProTemplate = (templateId: string): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const template = podcastWorkflowTemplateSelector(templateId)(getState());
  const properties: ClickProTemplateProperties = {
    templateId,
    templateName: template?.name,
    templateType: template?.templateType,
  };

  dispatch(sendMixpanelEvent(constants.CLICK_PRO_TEMPLATE, properties));
};

export const onClickEditAutomationSubscription = () =>
  sendMixpanelEvent(constants.CLICK_EDIT_AUTOMATION_SUBSCRIPTION);

export const onClickProFeature = (props: ClickProFeatureProperties) => {
  return sendMixpanelEvent(constants.CLICK_PRO_FEATURE, props);
};

export const onUpdateAutomationSubscription = (enabled: boolean) => {
  return sendMixpanelEvent(constants.UPDATE_AUTOMATION_SUBSCRIPTION, {
    toggle: enabled ? 'On' : 'Off',
  });
};

export const onAutoCmsBackCatalogNewButton = (empty: boolean) => {
  return sendMixpanelEvent(constants.AUTO_CMS_BACK_CATALOG_NEW_BUTTON, {
    source: empty ? 'Zero State' : 'Default State',
  });
};

export const onAutoCmsBackCatalogModalSubmitButton = (
  subscriptionIds: number[],
): ThunkAction<void> => (dispatch, getState) => {
  const subscriptions = podcastSubscriptionsSelector(getState());
  const isAutoSelect = subscriptionIds.map(subId =>
    subscriptions
      .get(String(subId))
      ?.get('subscriptionItems')
      ?.first()
      ?.get('isAutoSelect'),
  );

  dispatch(
    sendMixpanelEvent(constants.AUTO_CMS_BACK_CATALOG_MODAL_SUBMIT_BUTTON, {
      'Number of Automations': subscriptionIds.length,
      automationSubIDs: subscriptionIds,
      isAutoSelect,
    }),
  );
};

export const onNewAutomation = (
  source: 'Automation Row' | 'Back Catalog Modal',
) => {
  return sendMixpanelEvent(constants.NEW_AUTOMATION, {
    source,
  });
};

export const onClickPriorityQueueUpgrade = () => {
  return sendMixpanelEvent(constants.CLICK_PRIORITY_QUEUE_UPGRADE);
};

export const onTimeBankUpgrade = (
  source: TranscriptionTimeBankUpgradeSource,
) => (dispatch, getState) => {
  return dispatch(
    sendMixpanelEvent(constants.CLICK_TRANSCRIPTION_TIME_BANK_UPGRADE, {
      source,
      'Time Left': transcriptionBalanceMinutesSelector(getState()),
    }),
  );
};

export const onFreeTrialLearnMoreCta = (properties: FreeTrialCtaProperties) => {
  return sendMixpanelEvent(constants.FREE_TRIAL_LEARN_MORE_CTA, properties);
};

export const onFreeTrialExtendTrialCta = (
  properties: FreeTrialCtaProperties,
) => {
  return sendMixpanelEvent(constants.FREE_TRIAL_EXTEND_TRIAL_CTA, properties);
};

export const onShowFreeTrialModal = (modalName: FreeTrialModalName) => {
  return sendMixpanelEvent(constants.SHOW_FREE_TRIAL_MODAL, { modalName });
};

const createProcessAudioAction = (event: ProcessAudioEvent) => (
  id: string,
  src: string | Blob,
  progress: number,
): ThunkAction<void> => (dispatch, getState) => {
  const episode = selectedEpisodeSelector(getState());
  const podcast = selectedPodcastSelector(getState());
  const searchHandler = searchHandlerIntegrationSelector(getState());

  const baseProperties = {
    progress,
    uploadAudioSessionId: id,
  };

  const podcastProperties = {
    podcastName: podcast?.title,
    episodeName: episode?.get('title'),
  };

  if (!isString(src)) {
    dispatch(
      sendMixpanelEvent(event, {
        ...baseProperties,
        source: 'Upload',
      }),
    );
  } else if (searchHandler) {
    const podcastSearchProperties = {
      ...baseProperties,
      ...podcastProperties,
      podcastAudioURL: src,
    };

    dispatch(
      sendMixpanelEvent(event, {
        ...podcastSearchProperties,
        source:
          searchHandler === 'listennotes' || searchHandler === 'favorite'
            ? 'PodcastSearch'
            : 'URL',
      }),
    );
  }
};

export const onUploadingAudio = createProcessAudioAction(
  constants.UPLOADING_AUDIO,
);

export const onTransferAudioToStorage = createProcessAudioAction(
  constants.TRANSFER_AUDIO_TO_STORAGE,
);

export const onBeginAudioAnalysis = createProcessAudioAction(
  constants.BEGIN_AUDIO_ANALYSIS,
);

export const onAnalyzeAudio = createProcessAudioAction(constants.ANALYZE_AUDIO);

export const onReencodingAudio = createProcessAudioAction(
  constants.REENCODING_AUDIO,
);

export const onFinalizingAudio = createProcessAudioAction(
  constants.FINALIZING_AUDIO,
);

export const onAudioLoaded = createProcessAudioAction(constants.AUDIO_LOADED);

export const onClickAddPaymentInfo = (
  billingCycle: SubscriptionPeriod,
  price: number,
  plan: PurchaseableTier,
) => {
  return sendMixpanelEvent(constants.CLICK_ADD_PAYMENT_INFO, {
    billingCycle,
    price,
    plan: capitalize(plan),
  });
};

export const onProjectsTabLoad = sendMixpanelEvent(
  constants.PROJECTS_TAB_LOADED,
);

export const onNextProjectsPage = (numberOfLoads: number) =>
  sendMixpanelEvent(constants.PROJECTS_PAGINATED_LOADER, { numberOfLoads });

export const onIncreaseUploadLimit = (
  source: MediaUploadSource,
  type: 'length' | 'size',
) => {
  return sendMixpanelEvent(constants.INCREASE_UPLOAD_LIMIT, { source, type });
};

export const onClickUnlockSmartClips = () => {
  return sendMixpanelEvent(constants.CLICK_UNLOCK_SMART_CLIPS);
};

export const onClipSelectionPageSmartClipCTA = () => {
  return sendMixpanelEvent(constants.CLIP_SELECTION_PAGE_SMART_CLIP_CTA);
};

export const onPauseSubscription = () =>
  sendMixpanelEvent(constants.PAUSE_SUBSCRIPTION);

export const onSubmitPauseMyPlanDuration = (pauseDuration: string) =>
  sendMixpanelEvent(constants.SUBMIT_PAUSE_MY_PLAN_DURATION, {
    selection: pauseDuration,
  });

export const onCompleteUnpausePlan = () =>
  sendMixpanelEvent(constants.COMPLETE_UNPAUSE_PLAN);

export const onClickUnpauseMyPlan = () =>
  sendMixpanelEvent(constants.CLICK_UNPAUSE_MY_PLAN);

export const onClickCanvaButton = (source: 'UCS' | 'Editor') =>
  sendMixpanelEvent(constants.CLICK_CANVA_BUTTON, { source });

export const onExitTemplateWizard = (from: string) =>
  sendMixpanelEvent(constants.EXIT_TEMPLATE_WIZARD, { from });

export const onClickTemplateThumbnail = (
  templateId: string,
  templateName: string,
) =>
  sendMixpanelEvent(constants.CLICK_TEMPLATE_THUMBNAIL, {
    templateId,
    templateName,
  });

export const onSaveWaveformPreset = (
  source: 'UCS' | 'Editor',
  aspectRatio: number,
) => {
  return sendMixpanelEvent(constants.SAVE_WAVEFORM_PRESET, {
    source,
    aspectRatio: getAspectRatioNameFromRatio(aspectRatio),
  });
};

export const onDeleteWaveformPreset = (
  source: 'UCS' | 'Editor',
  aspectRatio: number,
) => {
  return sendMixpanelEvent(constants.DELETE_WAVEFORM_PRESET, {
    source,
    aspectRatio: getAspectRatioNameFromRatio(aspectRatio),
  });
};

export const onClickSetupAutoPostingBanner = (
  linkDestination: 'Autogram' | 'LearnCenter',
): ThunkAction<void> => dispatch => {
  return dispatch(
    sendMixpanelEvent(constants.CLICK_SETUP_AUTO_POSTING_BANNER, {
      linkDestination,
    }),
  );
};

export const onClickFeedCollectBanner = (): ThunkAction<void> => dispatch => {
  return dispatch(sendMixpanelEvent(constants.CLICK_FEED_COLLECT_BANNER));
};

export const onPodcastConnectedModal = (
  buttonClick: 'NotRightNow' | 'CreateANewVideo' | 'SetupAutomation',
) => {
  return sendMixpanelEvent(constants.PODCAST_CONNECTED_MODAL, {
    buttonClick,
  });
};

export const onClickDlpBanner = (
  buttonClick:
    | 'CreateNewVideo'
    | 'GetMarketingTips'
    | 'SetupAutomation'
    | 'ChangeAspectRatio',
) => {
  return sendMixpanelEvent(constants.CLICK_DLP_BANNER, { buttonClick });
};

export const onClickAccountSettings = () => {
  return sendMixpanelEvent(constants.CLICK_ACCOUNT_SETTINGS);
};

export const onUnlockThisFeature = (modal: UnlockThisFeatureModal) => {
  return sendMixpanelEvent(constants.UNLOCK_THIS_FEATURE, {
    modal,
  });
};

export const onReturnToClipAudioStep = (
  step: Step,
): ThunkAction<void> => dispatch => {
  if (step === 'customize' || step === 'aspect-ratio') {
    dispatch(
      sendMixpanelEvent(constants.RETURN_TO_CLIP_AUDIO_STEP, {
        from: camelToPascal(kebabToCamel(step)),
      }),
    );
  }
};

export const onClickAsset = (assetType: string) => {
  return sendMixpanelEvent(constants.CLICK_ASSET, {
    assetType: capitalize(assetType),
    type: getWizardTypeFromLocation(),
  });
};

export const onSkipOnboardingFeedCollect = () => {
  return sendMixpanelEvent(constants.SKIP_ONBOARDING_FEED_COLLECT);
};

export const onAiGenSocialCaptionsUpgradePoint = () => {
  return sendMixpanelEvent(constants.AI_GEN_SOCIAL_CAPTIONS_UPGRADE_POINT);
};

export const onAiGenSocialCaptionsClearText = () => {
  return sendMixpanelEvent(constants.AI_GEN_SOCIAL_CAPTIONS_CLEAR_TEXT);
};

export const onGoogleAuthError = () =>
  sendMixpanelEvent(constants.GOOGLE_AUTH_ERROR);

export const onUnsavedWorkAlert = (result: boolean, step: string) => {
  return sendMixpanelEvent(constants.UNSAVED_WORK_ALERT, {
    buttonClick: result ? 'LeaveStep' : 'StayHere',
    type: getWizardTypeFromLocation(),
    step,
  });
};

export const onExtractAudioFromVideo = () => {
  return sendMixpanelEvent(constants.EXTRACT_AUDIO_FROM_VIDEO, {
    source: getSourceFromLocation(),
  });
};

export const onTranscribeVideoFile = () => {
  return sendMixpanelEvent(constants.TRANSCRIBE_VIDEO_FILE, {
    source: getSourceFromLocation(),
  });
};

export const onShowRetryPeriodBanner = () => (dispatch: Dispatch) => {
  const source = getRetryPeriodBannerSource();

  if (source) {
    dispatch(sendMixpanelEvent(constants.SHOW_RETRY_PERIOD_BANNER, { source }));
  }
};

export const onClickRetryPeriodBanner = () => (dispatch: Dispatch) => {
  const source = getRetryPeriodBannerSource();

  if (source) {
    dispatch(
      sendMixpanelEvent(constants.CLICK_RETRY_PERIOD_BANNER, { source }),
    );
  }
};

export const onShowCreditCardUpdateModal = () =>
  sendMixpanelEvent(constants.SHOW_UPDATE_CREDIT_CARD_MODAL);

export const onClickUpdateCreditCardModal = (success: boolean) =>
  sendMixpanelEvent(constants.CLICK_UPDATE_CREDIT_CARD_MODAL, {
    updateCreditCard: success,
  });

export const onClickAnimateImage = (
  buttonState: AnimateImageButtonState,
  provider: AnimateImageProvider,
) =>
  sendMixpanelEvent(constants.CLICK_ANIMATE_IMAGE, {
    buttonState,
    provider,
  });

export const onShowPlanCancelledModal = () =>
  sendMixpanelEvent(constants.SHOW_PLAN_CANCELLED_MODAL);

export const onClickPlanCancelledModal = (upgrade: boolean) =>
  sendMixpanelEvent(constants.CLICK_PLAN_CANCELLED_MODAL, {
    buttonClick: upgrade ? 'UpgradeAgain' : 'UseOurFreePlan',
  });

export const onSuggestedClipsPageLoad = (
  source: ClipSelectPageSource,
  sourceMediaType: ClipSelectMediaType,
  suggestedClipCount?: number,
) => dispatch => {
  dispatch(
    sendMixpanelEvent(constants.SUGGESTED_CLIPS_PAGE_LOADED, {
      source,
      sourceMediaType,
      suggestedClipCount: suggestedClipCount ?? 0,
    }),
  );
};

export const onChooseAnotherClipFromThisEpisode = (
  projectType: ProjectCreationMethod,
) => dispatch => {
  dispatch(
    sendMixpanelEvent(constants.CHOOSE_ANOTHER_CLIP_FROM_THIS_EPISODE, {
      projectType,
    }),
  );
};

export const onDislikeSuggestedClip = (
  source: ClipSelectPageSource,
  dislikeReason: string,
) =>
  sendMixpanelEvent(constants.DISLIKE_SUGGESTED_CLIP, {
    source,
    dislikeReason: capitalize(dislikeReason),
  });

export const onAdjustClip = (source: ClipSelectPageSource) =>
  sendMixpanelEvent(constants.ADJUST_SUGGESTED_CLIP, {
    source,
  });

export const onOpenInAdvancedEditor = () =>
  sendMixpanelEvent(constants.OPEN_SUGGESTED_CLIP_IN_ADVANCED_EDITOR);

export const onAddClipToVideo = (
  source: ClipSelectPageSource,
  clipAdjusted?: boolean,
) => {
  return sendMixpanelEvent(constants.ADD_CLIP_TO_VIDEO, {
    source,
    clipAdjusted: isBoolean(clipAdjusted)
      ? capitalize(String(clipAdjusted))
      : undefined,
  });
};

export const onSelectOwnClip = (source: ClipSelectPageSource) =>
  sendMixpanelEvent(constants.SELECT_YOUR_OWN_CLIP, {
    source,
  });

export const onCaptionsModalSubmit = (captionsEnabled: boolean) =>
  sendMixpanelEvent(constants.ADD_CAPTIONS_MODAL_SUBMIT, {
    buttonClick: captionsEnabled ? 'addCaptions' : 'noThanks',
  });

export const onCreateVideoFromEpisodeRow = (button: 'manual' | 'automatic') => {
  return sendMixpanelEvent(constants.CREATE_VIDEO_FROM_EPISODE_ROW, {
    buttonClick: capitalize(button),
  });
};

export const onIncorrectFileType = (type: 'Audio' | 'Video') => {
  return sendMixpanelEvent(constants.INCORRECT_FILE_TYPE, {
    source: getSourceFromLocation(),
    type,
  });
};

export const onProjectsTabAddButton = () => {
  return sendMixpanelEvent(constants.PROJECTS_TAB_ADD_BUTTON);
};

export const onDisplayTranscribeUnsupportedLanguageModal = (
  language: string,
) => {
  return sendMixpanelEvent(
    constants.ON_DISPLAY_TRANSCRIBE_UNSUPPORTED_LANGUAGE_MODAL,
    {
      language,
    },
  );
};

export const onDisplayTranscribeOversizedFileModal = (
  fileSize: number,
  episodeDuration: number,
) => {
  return sendMixpanelEvent(
    constants.ON_DISPLAY_TRANSCRIBE_OVERSIZED_FILE_MODAL,
    {
      fileSize,
      episodeDuration,
    },
  );
};

export const onUpgradeToAddMoreAutomations = () => {
  return sendMixpanelEvent(constants.UPGRADE_TO_ADD_MORE_AUTOMATIONS);
};

export function onClickEpisodeTranscriptButton(
  buttonState: Exclude<EpisodeTranscriptButtonState, 'download'>,
);
export function onClickEpisodeTranscriptButton(
  buttonState: Extract<EpisodeTranscriptButtonState, 'download'>,
  source: 'EpisodeRow' | 'Toast',
);
export function onClickEpisodeTranscriptButton(
  buttonState: EpisodeTranscriptButtonState,
  source?: string,
) {
  return dispatch => {
    const sendEvent = (state, eventSource?: string) =>
      dispatch(
        sendMixpanelEvent(constants.CLICK_EPISODE_TRANSCRIPT_BUTTON, {
          state,
          source: eventSource,
        }),
      );

    if (buttonState === 'free') {
      sendEvent('Gated');
    }

    if (buttonState === 'transcribable') {
      sendEvent('Request');
    }

    if (buttonState === 'download') {
      sendEvent('Download', source);
    }
  };
}

export const onClickUnlockEpisodeTranscripts = sendMixpanelEvent(
  constants.CLICK_UNLOCK_EPISODE_TRANSCRIPTS,
);

export const onClickTranscribeEpisode = sendMixpanelEvent(
  constants.CLICK_TRANSCRIBE_EPISODE,
);

export const onDownloadEpisodeTranscript = (fileType: CaptionFormat) =>
  sendMixpanelEvent(constants.DOWNLOAD_EPISODE_TRANSCRIPT, { fileType });

export const onClickFromDowngradeUpsellModal = (
  buttonClick: 'Switch' | 'Cancel',
) => sendMixpanelEvent(constants.CLICK_SWITCH_TO_BASIC_MODAL, { buttonClick });

export const onExportingStepLoaded = sendMixpanelEvent(
  constants.EXPORTING_STEP_LOADED,
);

export const onClickGoToVideo = sendMixpanelEvent(constants.CLICK_GO_TO_VIDEO);

export const onCheckoutModalChangePlan = sendMixpanelEvent(
  constants.CHECKOUT_MODAL_CHANGE_PLAN,
);

export const onClickRemoveDiscoButton = (source: 'AdvancedEditor' | 'Wizard') =>
  sendMixpanelEvent(constants.CLICK_REMOVE_DISCO_BUTTON, { source });

export const onExportDiscoWidgetInitialized = (
  displayVariantId: string | undefined,
) =>
  sendMixpanelEvent(constants.EXPORT_DISCO_WIDGET_VARIANT_INITIALIZED, {
    displayVariantId,
  });

export const onClickPodcastPersonalizationModal = (
  state: PodcastPersonalizationModalState,
  buttonClick: PodcastPersonalizationModalButtonClick,
) =>
  sendMixpanelEvent(constants.CLICK_PODCAST_PERSONALIZATION_MODAL, {
    state,
    buttonClick,
  });

export const onClickAutomationTile = () =>
  sendMixpanelEvent(constants.CLICK_AUTOMATION_TILE);

export const onCreateManualAudiogram = () =>
  sendMixpanelEvent(constants.CREATE_MANUAL_AUDIOGRAM);

export const onClickTranscribe = () =>
  sendMixpanelEvent(constants.CLICK_TRANSCRIBE);

export const onClickAudiogramDestinationModal = (
  destination: MixpanelAudiogramDestination,
) =>
  sendMixpanelEvent(constants.CLICK_AUDIOGRAM_DESTINATION_MODAL, {
    destination,
  });

export const onEddyPromoModalCTA = (
  properties: EddyPromoModalCTAProperties,
) => {
  return sendMixpanelEvent(constants.EDDY_PROMO_MODAL_CTA_CLICK, properties);
};

export const onClickStartWizardDestination = (
  location: LocationDescriptor,
  params: AnyObject = {},
) => {
  const wizardType = routes.getWizardTypeFromSearch(location);

  switch (wizardType) {
    case 'audio': {
      return clickStartNewProject('audio', { ...params });
    }

    case 'audiogram': {
      return onClickAudiogramProject();
    }

    case 'automated': {
      return onClickRecommendedProject();
    }

    case 'episode': {
      return onClickFullEpisode();
    }

    case 'textToVideo': {
      return onClickTextToVideoProject();
    }

    case 'videoTranscription': {
      return onClickVideoTranscriptProject();
    }

    case 'autoVideosMVP': {
      return onClickAutomationWizard();
    }

    case 'templateWizard': {
      return clickStartNewProject('templateWizard', { ...params });
    }

    default: {
      return undefined;
    }
  }
};

export const onClickAudiogramVideoTypesModal = (
  properties: ClickAudiogramVideoTypesModalProperties,
) => sendMixpanelEvent(constants.CLICK_AUDIOGRAM_VIDEO_TYPES_MODAL, properties);

export const onClickIncompatibleTemplateLearnMore = () =>
  sendMixpanelEvent(constants.INCOMPATIBLE_TEMPLATE_LEARN_MORE);

export const onFullLengthTemplatesModalLoaded = (
  properties: FullLengthTemplatesModalProperties,
) =>
  sendMixpanelEvent(constants.FULL_LENGTH_TEMPLATES_MODAL_LOADED, properties);

export const onClickFullLengthTemplatesModalCTA = (
  source: CompatibilityModalSource,
) => sendMixpanelEvent(constants.FULL_LENGTH_TEMPLATES_MODAL_CTA, { source });

export const onClickTemplateLengthSelectorModalCTA = (
  buttonClick: 'short' | 'fullLength',
) =>
  sendMixpanelEvent(constants.SAVE_TEMPLATE_USE_CASE_SELECTED, { buttonClick });
export const onYouTubeAccountVerificationCheck = (
  properties: YouTubeAccountVerificationCheckProperties,
) =>
  sendMixpanelEvent(constants.YOUTUBE_ACCOUNT_VERIFICATION_CHECK, properties);

export const onClickFullEpisodeBackCatalogGatedCard = () =>
  sendMixpanelEvent(constants.CLICK_BACK_CATALOG_GATED_CARD);

export const onAutomationWatermarkPopover = () =>
  sendMixpanelEvent(constants.AUTOMATION_WATERMARK_POPOVER);

export const onGoBackToEditing = () =>
  sendMixpanelEvent(constants.GO_BACK_TO_EDITING);

export const onPreviewAutomation = () =>
  sendMixpanelEvent(constants.PREVIEW_AUTOMATION);

export const onShowEddyAiBanner = () =>
  sendMixpanelEvent(constants.SHOW_EDDY_AI_BANNER);

export const onClickEddyAiBanner = () =>
  sendMixpanelEvent(constants.CLICK_EDDY_AI_BANNER);

export const onClickHomepageAnalyticsTab = () =>
  sendMixpanelEvent(constants.CLICK_HOMEPAGE_ANALYTICS_TAB);

import Immutable from 'immutable';
import { isUndefined } from 'underscore';
import { fontsSelector } from 'redux/selectors';

import { getValue } from 'utils/collections';
import reduxPoll from 'utils/redux-poll';
import {
  addSubchunk,
  convertAutoTranscriptToManual,
  deleteSubchunk,
  rechunk,
  shiftChunks as shiftChunksUtil,
} from 'utils/transcript';
import { actions as transcriptServiceActions } from '../../../middleware/api/transcript-service';
import { manualTranscriptsSelector } from '../../entities/selectors';
import { showError } from '../../notification/actions';
import * as types from '../action-types';
import {
  audioByIdSelector,
  captionsConfigSelector,
  captionsDeletedPhraseIdsSelector,
  embedAutoTranscriptSelector,
  embedConfigSelector,
  embedManualTranscriptSelector,
  embedTranscriptSelector,
} from '../selectors';

// TODO remove.  callers of this function can just call updateTranscriptAction
// with the same exact signature
export const updateTranscript = (transcriptId, transcript) => dispatch =>
  dispatch(
    transcriptServiceActions.updateTranscriptAction(transcriptId, transcript),
  );

const updateTranscriptSubchunk = (
  transcriptId,
  chunkId,
  subchunkId,
  subchunk,
) => dispatch =>
  dispatch(
    transcriptServiceActions.updateTranscriptSubchunkAction(
      transcriptId,
      chunkId,
      subchunkId,
      subchunk,
    ),
  );

// Creates a manual transcript from the one that we got back from the automated transcript
export const createManualTranscript = automatedTranscript => (
  dispatch,
  getState,
) => {
  const immTranscript =
    automatedTranscript || embedAutoTranscriptSelector(getState());
  const transcriptObj = Immutable.Map.isMap(immTranscript)
    ? immTranscript.toJS()
    : immTranscript;
  const { transcript, participants } = convertAutoTranscriptToManual(
    transcriptObj,
  );

  return dispatch(
    transcriptServiceActions.createTranscriptRequest(transcript, participants),
  );
};

export const getTranscriptByRevisionId = (
  transcriptId,
  revisionId,
) => dispatch =>
  dispatch(
    transcriptServiceActions.fetchTranscriptByRevisionIdRequest(
      transcriptId,
      revisionId,
    ),
  );

export const getTranscriptByVideoId = videoId => dispatch =>
  dispatch(transcriptServiceActions.fetchTranscriptByVideoIdRequest(videoId));

export const downloadTranscripts = format => (dispatch, getState) => {
  const config = captionsConfigSelector(getState());
  return dispatch(
    transcriptServiceActions.downloadTranscriptByRevisionIdRequest(
      config.get('transcriptId'),
      config.get('transcriptRevisionId'),
      format,
    ),
  ).catch(() =>
    dispatch(
      showError(
        'Error occurred when downloading captions. ' +
          'Please try again later.',
        15,
      ),
    ),
  );
};

export const copyTranscriptByRevisionId = (
  transcriptId,
  revisionId,
) => dispatch =>
  dispatch(getTranscriptByRevisionId(transcriptId, revisionId)).then(res => {
    const transcriptPath = [
      'response',
      'entities',
      'manualTranscripts',
      transcriptId,
    ];

    const { transcript, participants } = getValue(res, transcriptPath);

    return dispatch(
      transcriptServiceActions.createTranscriptRequest(
        transcript,
        participants,
      ),
    );
  });

/*
 * NOTE: subchunk ids are used to identify "phrases"
 */
export const updatePhrase = (phraseId, { text, startMillis, endMillis }) => (
  dispatch,
  getState,
) => {
  const transcript = embedTranscriptSelector(getState());
  const phrase = transcript
    .get('transcript')
    .find(chunk => chunk.get('id') === phraseId);

  const updatedPhrase = phrase.withMutations(p => {
    if (text) p.set('text', text);
    if (!isUndefined(startMillis)) p.set('startMillis', startMillis);
    if (!isUndefined(endMillis)) p.set('endMillis', endMillis);
  });

  if (updatedPhrase.equals(phrase)) return Promise.resolve();

  dispatch({
    type: types.EMBED_PHRASE_UPDATE_REQUEST,
    payload: { phraseId },
  });

  return dispatch(
    updateTranscriptSubchunk(
      transcript.get('id'),
      phrase.get('chunkId'),
      phraseId,
      updatedPhrase.delete('chunkId').toJS(),
    ),
  )
    .then(() =>
      dispatch({
        type: types.EMBED_PHRASE_UPDATE_SUCCESS,
        payload: { phraseId },
      }),
    )
    .catch(() => {
      dispatch(
        showError({
          message: 'There was an error updating your transcript.',
          code: 'ER011',
        }),
      );
      dispatch({
        type: types.EMBED_PHRASE_UPDATE_FAILURE,
        payload: { phraseId },
      });
    });
};

export const rechunkTranscript = (
  maxChars,
  transcriptId,
  splitAt,
  styles,
) => async (dispatch, getState) => {
  function getTranscript() {
    if (transcriptId) {
      const transcripts = manualTranscriptsSelector(getState());
      return transcripts.get(transcriptId);
    }
    return embedManualTranscriptSelector(getState());
  }

  const transcript = getTranscript();
  if (!transcript) return Promise.resolve();

  const { transcript: chunks, ...restTranscript } = transcript.toJS();
  const rechunks = await rechunk(
    chunks,
    maxChars,
    splitAt,
    styles,
    fontsSelector(getState()),
  );
  const rechunkedTranscript = {
    ...restTranscript,
    transcript: rechunks,
  };

  return dispatch(
    updateTranscript(rechunkedTranscript.id, rechunkedTranscript),
  );
};

export const deleteChunk = phraseId => (dispatch, getState) => {
  dispatch({
    type: types.EMBED_PHRASE_DELETE_REQUEST,
    payload: { phraseId },
  });

  const transcript = embedManualTranscriptSelector(getState());
  const deletedPhraseIds = captionsDeletedPhraseIdsSelector(getState()).add(
    phraseId,
  );
  const updatedTranscript = transcript
    .update('transcript', t => deleteSubchunk(t, deletedPhraseIds))
    .toJS();

  return dispatch(updateTranscript(updatedTranscript.id, updatedTranscript))
    .then(() =>
      dispatch({
        type: types.EMBED_PHRASE_DELETE_SUCCESS,
        payload: { phraseId },
      }),
    )
    .catch(err => {
      dispatch({
        type: types.EMBED_PHRASE_DELETE_FAILURE,
        payload: { phraseId },
      });
      throw err;
    });
};

export const addChunk = (startMillis, endMillis, text = '') => (
  dispatch,
  getState,
) => {
  dispatch({ type: types.EMBED_PHRASE_ADD_REQUEST });

  const transcript = embedManualTranscriptSelector(getState());
  const updatedTranscript = transcript
    .update('transcript', t => addSubchunk(t, startMillis, endMillis, text))
    .toJS();

  return dispatch(updateTranscript(updatedTranscript.id, updatedTranscript))
    .then(() => dispatch({ type: types.EMBED_PHRASE_ADD_SUCCESS }))
    .catch(err => {
      dispatch({ type: types.EMBED_PHRASE_ADD_FAILURE });
      throw err;
    });
};

export const shiftChunks = offsetMillis => (dispatch, getState) => {
  const transcript = embedManualTranscriptSelector(getState());

  if (!transcript) return Promise.resolve();

  const updatedTranscript = transcript
    .update('transcript', t => shiftChunksUtil(t, offsetMillis))
    .toJS();

  return dispatch(updateTranscript(updatedTranscript.id, updatedTranscript));
};

export const importManualTranscript = file => dispatch => {
  dispatch({ type: types.EMBED_CAPTIONS_UPLOAD_REQUEST });
  return dispatch(transcriptServiceActions.importManualTranscriptAction(file))
    .then(res => {
      dispatch({ type: types.EMBED_CAPTIONS_UPLOAD_SUCCESS });

      return res;
    })
    .catch(err => {
      dispatch({ type: types.EMBED_CAPTIONS_UPLOAD_FAILURE, err: err.message });
      throw err;
    });
};

export const pollForFreeFormTranscript = jobId => async dispatch => {
  const transcript = await reduxPoll(
    dispatch,
    async () => {
      const { response } = await dispatch(
        transcriptServiceActions.getFreeFormTranscript(jobId),
      );

      return response;
    },
    {
      id: `free-form-transcript-poll-${jobId}`,
      shouldContinue: (_, value) =>
        value.status !== 'failed' && value.status !== 'completed',
      intervalMillis: 2500,
      maxAttempts: Infinity,
    },
  );

  return transcript;
};

export const importFreeFormTranscript = textFile => (dispatch, getState) => {
  dispatch({ type: types.FREE_FORM_CAPTIONS_UPLOAD_REQUEST });

  const { embedConfig } = embedConfigSelector(getState()) || {};
  const audioTrack = audioByIdSelector(getState());
  const isInitialRecording = !!embedConfig?.recordingId;
  const recordingId = isInitialRecording
    ? embedConfig?.recordingId
    : embedConfig?.audioInfo?.[0]?.recordingId;

  const videoId = embedConfig?.videoClips?.[0].videoId;

  const startMillis = isInitialRecording
    ? undefined
    : audioTrack?.get(recordingId)?.get('startMillis');
  const endMillis = isInitialRecording
    ? undefined
    : audioTrack?.get(recordingId)?.get('endMillis');

  return dispatch(
    transcriptServiceActions.importFreeFormTranscriptAction(
      textFile,
      recordingId,
      videoId,
      startMillis,
      endMillis,
    ),
  )
    .then(async ({ response }) =>
      dispatch(pollForFreeFormTranscript(response?.jobId)),
    )
    .then(async ({ transcriptId, revisionId }) => {
      const transcript = dispatch(
        getTranscriptByRevisionId(transcriptId, revisionId),
      );

      dispatch({ type: types.FREE_FORM_CAPTIONS_UPLOAD_SUCCESS });

      return transcript;
    })
    .catch(err => {
      dispatch({
        type: types.FREE_FORM_CAPTIONS_UPLOAD_FAILURE,
        err: err.message,
      });

      throw err;
    });
};

export default {
  createManualTranscript,
  getTranscriptByRevisionId,
  updatePhrase,
};

import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import { SPAREMIN_SERVICES_AUDIOGRAMDATA_URL } from 'config';
import { createRequest } from '../utils';

import * as types from './types';
import { isWaveformResolved } from './utils';

const BASE_API_PATH = '/api/v1/audiogram-data';

const waveformSchema = new schema.Entity(
  'waveforms',
  {},
  {
    idAttribute: waveform => waveform.id,
  },
);

function getByRecordingVersionId(
  [versionId]: types.GetByVersionIdArgs,
  request: SuperAgentStatic,
): Promise<types.GetByVersionIdResult> {
  return (
    request
      .get(`${BASE_API_PATH}/recording-version/${versionId}`)

      // inject version id for normalizr to use as entity id for this waveform
      .then(res => ({ ...res.body, id: versionId }))
      .then(waveform => normalize(waveform, waveformSchema))
  );
}

function waveformResolved(
  [versionId]: types.GetByVersionIdArgs,
  request: SuperAgentStatic,
): Promise<types.WaveformResolvedResult> {
  return getByRecordingVersionId([versionId], request).then(res => {
    const waveforms = res.entities && res.entities.waveforms;
    const waveform = waveforms[versionId];
    const { status } = waveform;
    const isResolved = isWaveformResolved(status);

    return {
      ...res,
      isResolved,
    };
  });
}

export const handle: types.IHandle = (
  method: any,
  args: any,
  token?: string,
): any => {
  const request = createRequest({
    token,
    baseUrl: SPAREMIN_SERVICES_AUDIOGRAMDATA_URL,
  });

  switch (method) {
    case types.ServiceMethod.GET_AUDIOGRAM_DATA_BY_RECORDING_VERSION:
      return getByRecordingVersionId(args, request);

    case types.ServiceMethod.WAVEFORM_RESOLVED:
      return waveformResolved(args, request);

    default:
      throw new Error(`${types.ACTION_KEY} cannot handle method ${method}`);
  }
};

import dayjs from 'dayjs';
import * as React from 'react';
import { useSelector } from 'react-redux';
import usePodcastData from 'hooks/usePodcastData';
import { Tier } from 'redux/middleware/api/plan-service';
import { subscriptionSelector } from 'redux/modules/pricing';
import { createDefaultProjectNameSelector } from 'redux/modules/project/selectors';
import { AudioSourceType, PodcastIdentifier } from 'types';
import CustomizeStep, { CustomizeStepProps } from '../CustomizeStep';
import CustomizeStepCta, {
  CustomizeStepCtaProps,
} from '../CustomizeStep/CustomizeStepCta';
import { EPISODE_TEMPLATE_EDITOR_FEATURES } from '../utils';
import { Transcription } from './types';

const { useMemo } = React;

type PickedCustomizeProps = Pick<
  CustomizeStepProps,
  | 'compatibilityTypes'
  | 'lastUsedStyle'
  | 'isProjectEditable'
  | 'onError'
  | 'onChangeView'
  | 'onSelectTemplate'
  | 'onSelectAspectRatio'
  | 'onStyleChange'
  | 'onTemplatesClick'
  | 'templateId'
  | 'templateTypes'
  | 'view'
  | 'isLoading'
>;

export interface EpisodeWizardCustomizeStepProps extends PickedCustomizeProps {
  aspectRatio: number;
  fullEpisodeCaptionEnabled: boolean;
  onExportClick: CustomizeStepCtaProps['onClick'];
  podcastIdentifier: PodcastIdentifier;
  transcription: Transcription;
  audioSource?: File | string;
  skipTemplateSelection: boolean;
  audioSourceType: AudioSourceType;
  language: string;
  audioClipDurationSec?: number;
  shouldGenerateAssets?: boolean;
}

const EpisodeWizardCustomizeStep: React.FC<EpisodeWizardCustomizeStepProps> = ({
  aspectRatio,
  compatibilityTypes,
  fullEpisodeCaptionEnabled,
  isProjectEditable,
  lastUsedStyle,
  onChangeView,
  onError,
  onExportClick,
  onSelectTemplate,
  onSelectAspectRatio,
  onStyleChange,
  onTemplatesClick,
  podcastIdentifier,
  skipTemplateSelection,
  templateId,
  templateTypes,
  transcription,
  view,
  audioSource,
  isLoading,
  audioSourceType,
  language,
  audioClipDurationSec,
  shouldGenerateAssets,
}) => {
  const { podcastId, episodeId } = podcastIdentifier ?? {};
  const { artworkUrl, episodeTitle, podcastTitle } = usePodcastData(
    podcastIdentifier ?? {},
  );

  const subscription = useSelector(subscriptionSelector);

  const offerUpgrade =
    subscription.tier === Tier.BASIC || subscription.tier === Tier.FREE;

  const defaultProjectName = useSelector(
    useMemo(
      () =>
        createDefaultProjectNameSelector(
          {
            source: audioSource,
            episodeId,
            podcastId,
          },
          `${dayjs().format('ddd, D MMM YYYY')} - Full Episode`,
        ),
      [audioSource, episodeId, podcastId],
    ),
  );

  return (
    <CustomizeStep
      {...{
        compatibilityTypes,
        isProjectEditable,
        lastUsedStyle,
        onChangeView,
        onError,
        onSelectTemplate,
        onSelectAspectRatio,
        onStyleChange,
        onTemplatesClick,
        templateId,
        podcastId,
        episodeId,
        templateTypes,
        view,
        isLoading,
        audioClipDurationSec,
      }}
      ctaLabel="export"
      defaults={{
        aspectRatio,
        keyAssets: {
          episodeTitle,
          podcastTitle,
          mainImage: artworkUrl,
        },
      }}
      features={{
        ...EPISODE_TEMPLATE_EDITOR_FEATURES,
        captions: fullEpisodeCaptionEnabled ? true : 'pro-gated',
        templates: skipTemplateSelection ? 'hidden' : true,
      }}
      renderCta={({ className, disabled, exportEditorState, state }) => (
        <CustomizeStepCta
          {...{
            className,
            defaultProjectName,
            disabled,
            exportEditorState,
            state,
            audioSourceType,
            language,
            shouldGenerateAssets,
          }}
          disableSubmitOptions={!offerUpgrade}
          projectType="fullEpisode"
          onClick={onExportClick}
          transcriptionEnabled={false}
        />
      )}
      transcription={{
        transcribe: transcription?.transcribe,
        language: transcription?.language,
      }}
      transcriptionEnabled={transcription?.transcribe}
    />
  );
};

export default EpisodeWizardCustomizeStep;

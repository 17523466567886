import cn from 'classnames';
import * as React from 'react';

import CardWizard from 'blocks/OnboardingWizard/components/CardWizard';
import HeadlinerUsageStep from 'blocks/OnboardingWizard/components/HeadlinerUsageStep';
import NewsletterStep from 'blocks/OnboardingWizard/components/NewsletterStep';
import { OnboardingWizardProvider } from 'blocks/OnboardingWizard/OnboardingWizardContext';
import { RequestStatus } from 'types';
import ReferrerStep from '../../components/ReferrerStep';
import RoutingStep from '../../components/RoutingStep';
import { OnboardingWizardProps } from '../../types';
import {
  onboardingWizardBlock as block,
  STEPS_TO_IGNORE_STEPPER,
} from '../../utils';
import WelcomeStep from '../WelcomeStep';
import useOnboardingWizard from './useOnboardingWizard';

const OnboardingWizard: React.FC<OnboardingWizardProps> = ({ className }) => {
  const {
    submitStatus,
    onFileSelect,
    onChangeUsage,
    onRouteSelect,
    onSubmitNewsletter,
    onSubmitReferrer,
    onStepChange,
    onWelcomeNext,
    onCompleteFeedCollect,
    onSkipFeedCollect,
    setDestinationPlatform,
    setStepId,
    destinationPlatform,
    step,
    stepId,
    welcomeType,
    onSelectVideoMediaType,
  } = useOnboardingWizard();

  if (!welcomeType) {
    return null;
  }

  return (
    <OnboardingWizardProvider welcomeType={welcomeType}>
      <CardWizard
        className={cn(block(), className)}
        onStepClick={onStepChange}
        step={step}
        submitting={submitStatus === RequestStatus.REQUEST}
        showStepper={!STEPS_TO_IGNORE_STEPPER.includes(stepId)}
      >
        <WelcomeStep onSubmit={onWelcomeNext} />
        <HeadlinerUsageStep onSubmit={onChangeUsage} />
        <NewsletterStep onSubmit={onSubmitNewsletter} />
        <ReferrerStep onSubmit={onSubmitReferrer} />
        <RoutingStep
          destinationPlatform={destinationPlatform}
          onSelectRoute={onRouteSelect}
          onFileSelect={onFileSelect}
          onCompleteFeedCollect={onCompleteFeedCollect}
          onSkipFeedCollect={onSkipFeedCollect}
          setDestinationPlatform={setDestinationPlatform}
          stepId={stepId}
          onStepChange={setStepId}
          onVideoTypeSelect={onSelectVideoMediaType}
        />
      </CardWizard>
    </OnboardingWizardProvider>
  );
};

export default OnboardingWizard;
export { OnboardingWizardProps };

import React, { ForwardedRef } from 'react';

import { useDispatch } from 'react-redux';
import { ProFeatureTooltipContent } from 'blocks/Pricing';
import { ClosedCaptioning } from 'components/icons';
import Tooltip from 'components/Tooltip';
import { useProIndicator } from 'containers/ProIndicator';
import { pushModal } from 'redux/modules/modal';

import { useEditorState } from '../context/VideoEditorStateContext';
import { OPTION_TILE_FEATURE_PATHS } from '../state/constants';
import AccessControlOptionTile, {
  AccessControlOptionTileProps,
} from './AccessControlOptionTile';
import ProGatedTile from './ProGatedTile';

export type CaptionsTileProps<P = unknown> = Pick<
  AccessControlOptionTileProps<P>,
  'className' | 'onClick' | 'params'
>;

const CaptionsTile = React.forwardRef(
  <P extends {} = unknown>(
    props: CaptionsTileProps<P>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { className, onClick, params } = props;

    const { isFree } = useProIndicator();
    const { features, disabled } = useEditorState();
    const dispatch = useDispatch();

    const handleUpgrade = React.useCallback(() => {
      dispatch(pushModal({ name: 'CaptionsUpsell' }));
    }, [dispatch]);

    if (features.captions === 'hidden') {
      return null;
    }

    return (
      <Tooltip
        animation
        preventHideOnHover
        id="captions-tile-tooltip"
        placement="top"
        content={ProFeatureTooltipContent}
        show={isFree ? undefined : false}
      >
        <div>
          <ProGatedTile
            ref={ref}
            tileBaseClassName={className}
            disabled={disabled}
            isProDisabledOverride={features.captions === 'pro-gated'}
            onClickFeature={onClick}
            onUpgrade={handleUpgrade}
          >
            {({ proDisabled, proClickHandler, tileClassName }) => {
              return (
                <AccessControlOptionTile
                  id="templates-captions-tile"
                  label="captions"
                  className={tileClassName}
                  disabled={disabled}
                  showProLabel={proDisabled}
                  featurePath={OPTION_TILE_FEATURE_PATHS.captions}
                  icon={<ClosedCaptioning width="48px" height="36px" />}
                  params={params}
                  onClick={proClickHandler}
                />
              );
            }}
          </ProGatedTile>
        </div>
      </Tooltip>
    );
  },
);

export default CaptionsTile;

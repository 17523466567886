import { fromJS } from 'immutable';
import React from 'react';
import { omit } from 'underscore';

import { CaptionsConfig } from 'blocks/TextOverlayModal/v2';
import { IEmbedConfig } from 'types';
import { ICaptions } from 'utils/embed/captions/types';
import measurement, {
  Measurement,
  Pixels,
  ViewportWidth,
} from 'utils/measurement';

// The legacy modal, allows picking a templase whose name is submitted with the
// captions config. In the case of the new captions editor, it is not possible to
// pick a template. In order to keep compatibility with the legacy editor, the
// default template name is passed
export const DEFAULT_CAPTIONS_TEMPLATE_NAME = 'default';
export const FALLBACK_VIEWPORT = { height: 1, width: 1 };

export const getFontSize = (
  containerStyle: React.CSSProperties = {},
  toPx: (m: Measurement) => number,
): number => {
  return Math.round(
    toPx(new ViewportWidth(parseFloat(String(containerStyle.fontSize ?? 0)))),
  );
};

export const getCaptionsConfigFromEmbed = (
  captionsConfig?: IEmbedConfig['captions'],
): CaptionsConfig | undefined => {
  if (!captionsConfig) {
    return undefined;
  }

  const viewport =
    captionsConfig.editor?.styleContext?.viewport ?? FALLBACK_VIEWPORT;
  const textBoxHeight = captionsConfig.editor?.styleContext?.textBoxHeight ?? 0;

  const height = new Pixels(textBoxHeight).toUnit('vh', viewport);
  const width = captionsConfig.containerStyle?.width;
  const left = captionsConfig.region?.properties?.left ?? 0;
  const top = captionsConfig.region?.properties?.top ?? 0;

  return {
    advancedTextConfigs: captionsConfig.advancedTextConfigs,
    advancedAnimation: captionsConfig.advancedAnimation,
    animation: captionsConfig.animation,
    containerStyle: omit(captionsConfig.containerStyle, ['width']),
    editor: captionsConfig.editor,
    position: {
      left: measurement(left),
      top: measurement(top),
    },
    size: {
      height: measurement(height.toString()),
      width: measurement(width),
    },
    region: captionsConfig.region,
    textStyle: captionsConfig.textStyle,
    textBoxHeight,
  };
};

export const getCaptionsStyleFromEditorConfig = (
  captionsConfig: CaptionsConfig,
): ICaptions['style'] => {
  const viewport =
    captionsConfig.editor.styleContext?.viewport ?? FALLBACK_VIEWPORT;

  const toPx = (m: Measurement) => m.toUnit('px', viewport).value;

  const fontSize = getFontSize(captionsConfig.containerStyle, toPx);

  const style = {
    advancedTextConfigs: captionsConfig.advancedTextConfigs,
    advancedAnimation: captionsConfig.advancedAnimation,
    animation: {
      enabled: captionsConfig.animation?.follow?.enabled,
      color: captionsConfig.animation?.follow?.textStyle?.color,
    },
    containerStyle: {
      ...captionsConfig.containerStyle,
      fontSize,
    },
    position: {
      left: toPx(captionsConfig.position.left),
      top: toPx(captionsConfig.position.top),
    },
    size: {
      width: toPx(captionsConfig.size.width),
    },
    textBoxHeight: toPx(captionsConfig.size.height),
    textStyle: captionsConfig.textStyle,
    viewport,
  };

  return fromJS(style);
};

export const shouldEnableRechunk = (
  hasBeenResized: boolean,
  captionsTimesLocked: boolean,
): boolean => hasBeenResized && !captionsTimesLocked;

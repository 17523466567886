import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppStoreIcon,
  ArrowRight,
  InstagramGradientSquareButton,
} from 'components/icons';
import Modal from 'components/Modal';
import SocialAccountInfo from 'components/SocialAccountInfo';
import { onInstagramPostModalLoad } from 'redux/modules/mixpanel';
import { instagramUserDataSelector } from 'redux/modules/social';
import { Dispatch } from 'redux/types';
import { block } from './utils';

export interface SelectAccountStepProps {
  onSwitchAccountSelect?: () => void;
}

const SelectAccountStep: React.FC<SelectAccountStepProps> = ({
  onSwitchAccountSelect,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const instagramUserData = useSelector(instagramUserDataSelector);

  useEffect(() => {
    dispatch(onInstagramPostModalLoad('AccountsExist'));
  }, [dispatch]);

  return (
    <Modal.Body className={block('select-account-step')}>
      <div className={block('select-account-step--icons')}>
        <div className={block('icon-headliner-container')}>
          <AppStoreIcon
            className={block('select-account-step--icon-headliner')}
          />
        </div>

        <ArrowRight
          className={block('select-account-step--arrow')}
          height={26}
        />

        <InstagramGradientSquareButton
          className={block('select-account-step--icon-instagram')}
          width="86"
          height="86"
        />
      </div>

      <p className={block('title')}>Share to Instagram</p>

      <p className={block('description')}>
        Select the location you would like to share to.
      </p>

      <SocialAccountInfo
        username={instagramUserData?.username}
        avatarSrc={instagramUserData?.profileImageUrl}
        onSwitchAccountClick={onSwitchAccountSelect}
        className={block('social')}
      />
    </Modal.Body>
  );
};

export default SelectAccountStep;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { OnboardingName } from 'blocks/OnboardingAnimations/types';
import usePrevious from 'hooks/usePrevious';
import {
  getEpisodeById,
  getSubscriptionById,
} from 'redux/middleware/api/podcast-service/actions';
import { getAudioUpload } from 'redux/middleware/api/recording-upload-service/actions';
import { getUserIntegrators, loggedInSelector } from 'redux/modules/auth';
import {
  groupClipSuggestionsIsLoadingSelector,
  loadGroupClipSuggestions,
  loadSubscriptionItemInformation,
  pollForGroupClipSuggestion,
} from 'redux/modules/clip-select';
import { getMyDisplayPref } from 'redux/modules/display-pref';
import { pollGroupClipSuggestionsIdsSelector } from 'redux/modules/entities';
import { showError } from 'redux/modules/notification/actions';
import { getOnboardingAction } from 'redux/modules/onboarding';
import { goToAppHome } from 'redux/modules/router/actions';
import {
  autoCreateSubscriptionIdSelector,
  hasSubscriptionItemInformationSelector,
} from './selectors';
import useAutomationSuggestedClipInfo from './useAutomationSuggestedClipInfo';

export default function useAutomationSuggestedClipsPage() {
  const {
    episodeId,
    episode,
    podcast,
    subscriptionItemId,
    groupRequestId,
    entireAudioInstanceId,
  } = useAutomationSuggestedClipInfo();

  const location = useLocation();

  const isLoadingStatus = useSelector(groupClipSuggestionsIsLoadingSelector);

  const pollGroupClipSuggestionsIds = useSelector(
    pollGroupClipSuggestionsIdsSelector,
  );

  const autoCreateSubId = useSelector(state =>
    autoCreateSubscriptionIdSelector(state, subscriptionItemId),
  );

  const prevAutoCreateSubId = usePrevious(autoCreateSubId);

  const hasSubscriptionItemInformation = useSelector(state =>
    hasSubscriptionItemInformationSelector(state, subscriptionItemId),
  );

  const isLoggedIn = useSelector(loggedInSelector);

  const prevEntireAudioInstanceId = usePrevious(entireAudioInstanceId);
  const prevPollGroupClipSuggestionsIds = usePrevious(
    pollGroupClipSuggestionsIds,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    Promise.resolve()
      .then(() => {
        dispatch(getMyDisplayPref());
      })
      .then(() => {
        if (isLoggedIn) {
          return Promise.all([
            dispatch(getUserIntegrators()),
            dispatch(getOnboardingAction(OnboardingName.NPS_SURVEY)),
          ]);
        }

        return null;
      })
      .then(() => dispatch(loadSubscriptionItemInformation(subscriptionItemId)))
      .catch(() => {
        dispatch(
          showError({
            message: 'Error loading clips',
            code: 'ER014',
            dismissAfterSec: 5,
          }),
        );

        dispatch(goToAppHome());
      });
  }, [dispatch, isLoggedIn, subscriptionItemId]);

  useEffect(() => {
    if (!prevAutoCreateSubId && autoCreateSubId) {
      dispatch(getSubscriptionById(autoCreateSubId));
      dispatch(getEpisodeById(Number(episodeId)));
    }
  }, [autoCreateSubId, dispatch, episode, episodeId, prevAutoCreateSubId]);

  useEffect(() => {
    if (!prevEntireAudioInstanceId && entireAudioInstanceId) {
      dispatch(getAudioUpload(entireAudioInstanceId));
    }
  }, [dispatch, entireAudioInstanceId, prevEntireAudioInstanceId]);

  useEffect(() => {
    if (
      prevPollGroupClipSuggestionsIds === undefined &&
      pollGroupClipSuggestionsIds &&
      !!pollGroupClipSuggestionsIds?.length
    ) {
      pollGroupClipSuggestionsIds.forEach(suggestion => {
        dispatch(pollForGroupClipSuggestion(suggestion));
      });
    }
  }, [dispatch, pollGroupClipSuggestionsIds, prevPollGroupClipSuggestionsIds]);

  useEffect(() => {
    if (!episodeId || !subscriptionItemId) {
      return;
    }

    dispatch(
      loadGroupClipSuggestions(
        Number(episodeId),
        subscriptionItemId,
        groupRequestId,
      ),
    );
  }, [dispatch, episodeId, groupRequestId, location, subscriptionItemId]);

  return {
    isLoading:
      !hasSubscriptionItemInformation ||
      !episode ||
      !podcast ||
      isLoadingStatus,
  };
}

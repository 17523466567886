import React from 'react';
import { noop } from 'underscore';

import { CaptionsConfig } from 'blocks/TextOverlayModal/v2';
import { useEditorState } from 'components/VideoTemplateEditor/context/VideoEditorStateContext';
import useCaptionsAssetAnimations from 'components/VideoTemplateEditor/useCaptionsAssetAnimations';
import { AutogramVideoType, Size } from 'types';
import { CAPTION_STYLE_FIT_TEXT } from 'utils/constants';

import { isCaptionsAnimationsTabDisabled } from 'utils/embed/captions';
import { useCanvasSize } from '../../context/CanvasSizeContext';
import { useNavigation } from '../../context/NavigationContext';
import { useTemplateDispatch } from '../../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../../context/VideoTemplateStateContext';
import { useModalManager } from '../../ModalManager';
import { CaptionsState } from '../../types';
import useTextAssetEffects from '../../useTextAssetEffects';
import { canvasBlock as block } from '../../utils';
import RndAsset, { RndAssetCallback } from '../RndAsset';
import { getCaptionStyle } from '../utils';
import useCaptionsAssetAdjust from './useCaptionsAssetAdjust';

interface CaptionsAssetProps {
  captionsOverride: CaptionsState;
  videoType?: AutogramVideoType;
}

const ASSET_ID = 'captions-asset';

const CaptionsAsset: React.FunctionComponent<CaptionsAssetProps> = props => {
  const { captionsOverride, videoType } = props;

  const [, send] = useNavigation();
  const { audioClipDurationSec } = useEditorState();
  const { canvas } = useCanvasSize();
  const { captions, transcription } = useTemplateState();
  const dispatch = useTemplateDispatch();
  const { toPx } = useCanvasSize();
  const modalManager = useModalManager();

  const trancribeEnabled = transcription?.transcribe;

  const handleDimensionsChange: RndAssetCallback = React.useCallback(
    value => {
      dispatch({
        type: 'CAPTIONS_DIMENSIONS_CHANGE',
        payload: {
          dimensions: value,
        },
      });
    },
    [dispatch],
  );

  const handleMouseDown = React.useCallback(() => {
    send({
      type: 'CHILD_VIEW_OPEN',
      payload: 'captions',
      meta: { source: 'preview' },
    });
  }, [send]);

  const handleDoubleClick = React.useCallback(() => {
    const showTabDisabled = isCaptionsAnimationsTabDisabled(
      audioClipDurationSec,
      videoType,
    );

    modalManager.showModal('edit-captions', {
      animationsTabEnabled: true,
      animationsTabDisplayConfig: {
        showTabDisabled,
        tabTooltip:
          showTabDisabled && 'Not supported for content longer than 10 minutes',
      },
      captionsOverride: captions,
      onSubmit: (captionsConfig: CaptionsConfig) => {
        dispatch({
          type: 'CAPTIONS_CONFIG_UPDATE',
          payload: {
            captions: captionsConfig,
          },
        });
      },
    });
  }, [audioClipDurationSec, captions, dispatch, modalManager, videoType]);

  const captionsStyle = React.useMemo(() => {
    return getCaptionStyle(captionsOverride, toPx);
  }, [captionsOverride, toPx]);

  const {
    minimumSize,
    onResize: captionsAssetAdjustResizeListener,
    onResizeStart,
    onResizeStop,
    truncatedTextValue,
  } = useCaptionsAssetAdjust({
    captionsState: captionsOverride,
    textValue: CAPTION_STYLE_FIT_TEXT,
  });

  const {
    onResize: textEffectsResizeListener,
    wrapperNodeRef: textEffectsWrapperNodeRef,
  } = useTextAssetEffects({
    advancedTextConfigs: captions?.advancedTextConfigs,
    externalObservableData: canvas,
  });

  const {
    onResize: captionsAnimationsResizeListener,
    wrapperNodeRef: captionsAnimationsWrapperNodeRef,
  } = useCaptionsAssetAnimations({
    captionsContent: truncatedTextValue,
    advancedAnimation: captions?.advancedAnimation,
    textStyles: captionsOverride.textStyle,
    externalObservableData: canvas,
  });

  const handleResize = React.useCallback(
    (size: Size<number>): void => {
      captionsAssetAdjustResizeListener(size);
      textEffectsResizeListener(size);
      captionsAnimationsResizeListener(size);
    },
    [
      captionsAnimationsResizeListener,
      captionsAssetAdjustResizeListener,
      textEffectsResizeListener,
    ],
  );

  if (!trancribeEnabled || !captionsStyle) {
    return null;
  }

  const { height, left, top, width, ...style } = captionsStyle;

  return (
    <RndAsset
      assetId="captions-asset"
      bounds="parent"
      enableResizing
      height={height}
      left={left}
      lockAspectRatio={false}
      minSizePx={minimumSize}
      onDragStop={handleDimensionsChange}
      onResize={handleResize}
      onResizeStart={onResizeStart(noop)}
      onResizeStop={onResizeStop(handleDimensionsChange)}
      onMouseDown={handleMouseDown}
      params={{ id: ASSET_ID }}
      resizeWhenBelowMin
      top={top}
      width={width}
    >
      <div ref={textEffectsWrapperNodeRef}>
        <p
          className={block('captions-asset')}
          onDoubleClick={handleDoubleClick}
          style={style}
        >
          <span
            style={captionsOverride.textStyle}
            ref={captionsAnimationsWrapperNodeRef}
          >
            {!captions?.advancedAnimation?.enabled && truncatedTextValue}
          </span>
        </p>
      </div>
    </RndAsset>
  );
};

export default CaptionsAsset;

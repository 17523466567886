import { Slider } from '@sparemin/blockhead';
import React from 'react';

import { textControlsBlock as block } from '../utils';
import TextControl from './TextControl';

interface TextSliderProps {
  icon: React.ReactNode;
  label: string;
  maxValue: number;
  minValue: number;
  step?: number;
  onChange: (value: number) => void;
  value: number;
}

const TextSlider: React.FunctionComponent<TextSliderProps> = props => {
  const { icon, label, maxValue, minValue, step = 1, onChange, value } = props;

  return (
    <TextControl icon={icon} label={label} layout="vertical">
      <Slider
        aria-label={`Slider control for ${label}`}
        className={block('slider')}
        maxValue={maxValue}
        minValue={minValue}
        onChange={onChange}
        value={value}
        step={step}
      />
    </TextControl>
  );
};

export default TextSlider;

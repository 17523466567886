import { Flipped, Flipper } from 'react-flip-toolkit';
import Slider from 'react-slick';
import { BemCssTransitionProps } from 'components/BemCssTransition';
import { AsyncAudioClipperProps } from 'containers/AsyncAudioClipper';
import { ClipSuggestionStatus } from 'redux/middleware/api/podcast-service';
import { AspectRatioName } from 'types';
import { useNavigation } from './containers/ClipSelectNavigationContext/navigation-machine';

export type ClipSelectNavigationContextType = ReturnType<typeof useNavigation>;

export type Region = AsyncAudioClipperProps['region'] & {
  /**
   * a suggestionId should exist if this region corresponds to a suggestion
   */
  suggestionId?: number;
};

export type ClipSelectPageView = 'select' | 'clip';

export type TranscriptSegment = {
  speaker: string;
  text: string;
};

export type ClipSuggestionText = string | TranscriptSegment[];

export interface ClipSuggestion {
  id: number;
  startMillis: number;
  endMillis: number;
  isUserSelected: boolean;
  text?: ClipSuggestionText;
  widgetId?: string;
  videoUrl?: string;
  status: ClipSuggestionStatus;
  isVideoOutdated?: boolean;
  projectUuid?: string;
}

export type EddyClipSuggestion = Pick<
  ClipSuggestion,
  'id' | 'startMillis' | 'endMillis'
>;

export interface ClipsPageInfo {
  title?: string;
  subtitle?: string;
  artwork?: string;
  aspectRatioName?: AspectRatioName;
  artworkAspectRatioName?: AspectRatioName;
  entireAudioInstanceId?: number;
  videoUrl?: string;
  mediaDurationMillis?: number;
  publishedAtMillis?: number;
}

export enum ClipSelectionType {
  DEFAULT = 'Default',
  SUGGESTED_CLIP = 'SuggestedClip',
  CUSTOM_CLIP = 'CustomClip',
}

export interface FormValues {
  region: Region;
  lastClipSelectionType: ClipSelectionType;
  isCaptionEnabled: boolean;
  clipSuggestion: ClipSuggestion;
}

export type CreateVideo = (values: FormValues) => void;

export type ClipSelectMediaType = 'audio' | 'video';

type AnimateableComponents =
  | 'clipper'
  | 'clipSelector'
  | 'export'
  | 'header'
  | 'headerEdit'
  | 'headerSelect'
  | 'intro'
  | 'loader'
  | 'transcript';

type FlipperProps = Omit<
  React.ComponentPropsWithoutRef<typeof Flipper>,
  'children'
>;
type FlippedProps = Omit<
  React.ComponentPropsWithoutRef<typeof Flipped>,
  'children'
>;

type SuggestionCardAnimationConfig = Record<
  'sizer' | 'sizerInverter' | 'vanisher',
  FlippedProps & { className: string }
>;

export interface ClipSelectAnimationContextType
  extends Record<AnimateableComponents, BemCssTransitionProps> {
  clipSelectorFlipper: FlipperProps;
  clipSelectorSliderRef: React.MutableRefObject<Slider>;
  onClipSelected: () => void;
  createSuggestionCardAnimationConfig: (
    id: number,
  ) => SuggestionCardAnimationConfig;
}

export interface ClipSelectTrackingContextType {
  onAddClip: () => void;
  onAdjustClip: () => void;
  onOpenInAdvancedEditor: () => void;
  onCaptionsModalSubmit: (enabled: boolean) => void;
  onDislikeClip: (reason: string) => void;
  onSelectOwnClip: () => void;
}

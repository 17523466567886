import { isUndefined } from 'underscore';

import { getValue } from 'utils/collections';
import { int } from './numbers';

function computedStyle(element) {
  return element instanceof HTMLElement ? getComputedStyle(element) : element;
}

/**
 * calculates the usable space inside of a DOM element that has { box-sizing: border-box }.  In
 * this case, the inner width is the total width minus padding and borders
 */
export function innerWidth(element) {
  const style = computedStyle(element);
  const totalWidth = parseFloat(style.width);
  const paddingWidth =
    parseFloat(style.paddingRight) + parseFloat(style.paddingLeft);
  const borderWidth =
    parseFloat(style.borderRightWidth) + parseFloat(style.borderLeftWidth);

  return totalWidth - paddingWidth - borderWidth;
}

export function innerHeight(element) {
  const style = computedStyle(element);
  const totalHeight = parseFloat(style.height);
  const paddingHeight =
    parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
  const borderWidth =
    parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);

  return totalHeight - paddingHeight - borderWidth;
}

export function isOverflowing(element) {
  if (!element) return false;
  return element.offsetWidth < element.scrollWidth;
}

export function htmlStringToDomTree(html) {
  const root = document.createElement('div');
  root.innerHTML = html;
  return root.firstElementChild;
}

export function htmlStringQueryAll(htmlString, selector) {
  const element = htmlStringToDomTree(htmlString);
  const root = document.createElement('div');
  root.appendChild(element);

  return root.querySelectorAll(selector);
}

export function domTreeWalker(tree, whatToShow = NodeFilter.SHOW_ELEMENT) {
  return document.createTreeWalker(tree, whatToShow, {
    acceptNode: () => NodeFilter.FILTER_ACCEPT,
  });
}

export function htmlStringToTreeWalker(
  str,
  whatToShow = NodeFilter.SHOW_ELEMENT,
) {
  const root = document.createElement('div');
  root.innerHTML = str;
  return document.createTreeWalker(root, whatToShow, {
    acceptNode: () => NodeFilter.FILTER_ACCEPT,
  });
}

export function domTreeToHtmlString(domTree) {
  const root = document.createElement('div');
  root.appendChild(domTree);
  return root.firstElementChild.innerHTML;
}

export function textContentFromHtmlString(html, defaultValue = undefined) {
  if (!html) return defaultValue;

  const root = document.createElement('div');
  root.innerHTML = html;
  return root.textContent;
}

export function extractStyle(htmlRoot, selector) {
  const element =
    typeof htmlRoot === 'string' ? htmlStringToDomTree(htmlRoot) : htmlRoot;

  /*
   * wrap content in a div since querySelector will only match descendents and caller might want
   * the root element
   */
  const root = document.createElement('div');
  root.appendChild(element);

  const selectedElement = root.querySelector(selector);
  return getValue(selectedElement, 'style');
}

export function offsetX(event: React.MouseEvent, offsetParent?: HTMLElement) {
  // if event is wrapped in react's synthetic event, offsetX will only exist on
  // the nativeEvent
  const nativeEvent: any = event.nativeEvent || event;

  if (!isUndefined(nativeEvent.offsetX)) {
    return nativeEvent.offsetX;
  }

  const parent = offsetParent || event.currentTarget;
  const rectangle = parent.getBoundingClientRect();
  return event.pageX - rectangle.left;
}

/**
 * Removes specified CSS properties from inline styles in an HTML string.
 */
export function removeCSSPropsFromInlineStyle(
  htmlString: string,
  propsToRemove: string[],
): string {
  // Parse the HTML string into a DOM element.
  const tempDiv = document.createElement('div');

  tempDiv.innerHTML = htmlString;

  const element = tempDiv.firstChild;

  // Recursive function to remove the properties from inline styles.
  function removePropsFromElement(el: HTMLElement): void {
    if (el.style) {
      propsToRemove.forEach(prop => el.style.removeProperty(prop));
    }

    // Process child elements recursively.
    Array.from(el.children).forEach(removePropsFromElement);
  }

  // Start the property removal.
  if (element instanceof HTMLElement) {
    removePropsFromElement(element);
  }

  // Return the modified HTML as a string.
  return tempDiv.innerHTML;
}

/**
 * Gets computed translate values
 */
export function getTranslateValues(element: HTMLElement) {
  const style = window.getComputedStyle(element);
  const matrix =
    style.transform || style.webkitTransform || (style as any).mozTransform;

  // No transform property. Simply return 0 values.
  if (matrix === 'none' || typeof matrix === 'undefined') {
    return {
      x: 0,
      y: 0,
      z: 0,
    };
  }

  // Can either be 2d or 3d transform
  const matrixType = matrix.includes('3d') ? '3d' : '2d';
  const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ');

  // 2d matrices have 6 values
  // Last 2 values are X and Y.
  // 2d matrices does not have Z value.
  if (matrixType === '2d') {
    return {
      x: int(matrixValues[4]),
      y: int(matrixValues[5]),
      z: 0,
    };
  }

  // 3d matrices have 16 values
  // The 13th, 14th, and 15th values are X, Y, and Z
  if (matrixType === '3d') {
    return {
      x: int(matrixValues[12]),
      y: int(matrixValues[13]),
      z: int(matrixValues[14]),
    };
  }

  return {
    x: 0,
    y: 0,
    z: 0,
  };
}

interface Filter {
  blur?: {
    radius: string;
  };
}

/**
 * Formats css filter property
 */
export const formatCSSFilter = (filter: Filter): string | undefined => {
  let formatted = '';

  if (filter?.blur?.radius) {
    formatted += `blur(${filter.blur.radius})`;
  }

  return formatted === '' ? undefined : formatted;
};

export const parseCSSFilter = (value: string): Filter | undefined => {
  if (!value) {
    return undefined;
  }

  const match = value.match(/blur\([\d\\.]*vw\)/);
  if (!match) {
    return undefined;
  }

  const sbstr = value.substring(match.index).trim();
  const blurRadius = sbstr.substring(
    sbstr.indexOf('(') + 1,
    sbstr.indexOf(')'),
  );

  if (blurRadius) {
    return {
      blur: {
        radius: blurRadius,
      },
    };
  }

  return undefined;
};

export default { innerWidth, innerHeight };

import { configure as configureApi, SuperAgentClient } from '@sparemin/api-sdk';
import { configure as configureAuth, sso } from '@sparemin/auth';
import {
  Action,
  DeepPartial,
  PreloadedState,
  Reducer,
  StoreEnhancerStoreCreator,
} from 'redux';
import { APPLE_AUTH_REDIRECT_URI } from 'config';
import { SPAREMIN_LIBRARY_ENVIRONMENT } from 'utils/constants';

configureAuth({
  appleRedirectURI: APPLE_AUTH_REDIRECT_URI,
  application: 'makeHeadliner',
  environment: SPAREMIN_LIBRARY_ENVIRONMENT,
});

configureApi({
  client: new SuperAgentClient(),
  environment: SPAREMIN_LIBRARY_ENVIRONMENT,
  getSpareminToken: sso.getToken,
});

interface TokenEnhancerConfig<TState> {
  setToken: (
    state: DeepPartial<TState>,
    token: string | undefined,
  ) => DeepPartial<TState>;
  getToken: (state: TState | undefined) => string | undefined;
}

export function createTokenEnhancer<TState>({
  getToken,
  setToken,
}: TokenEnhancerConfig<TState>) {
  return (next: StoreEnhancerStoreCreator) => {
    return <TAction extends Action>(
      reducer: Reducer<TState, TAction>,
      preloadedState?: DeepPartial<TState>,
    ) => {
      // read the stored token when the app is initialized
      let currentToken = sso.getToken();

      // set the stored token on the preloaded state that will be used to
      // create the store.  this will effectively "rehydrate" the token
      const preloadedStateWithToken = setToken(
        preloadedState,
        currentToken,
      ) as PreloadedState<TState>;

      const store = next(reducer, preloadedStateWithToken);

      // listen for store changes.  if the token changes, call storeToken to
      // persist it
      store.subscribe(() => {
        const nextToken = getToken(store.getState());

        if (nextToken !== currentToken) {
          currentToken = nextToken;
          sso.storeToken(nextToken);
        }
      });

      return store;
    };
  };
}

import React from 'react';

import TextToolbar from 'components/TextToolbar';
import HistoricalColorPicker from 'containers/HistoricalColorPicker';

import useTextOverlayFontStyles from '../../hooks/useTextOverlayFontStyles';
import { block } from '../../utils';
import TextOverlayControlWrapper from './TextOverlayControlWrapper';

const COLOR_PICKER_RIGHT_POS_CORRECTION = 80;

const TextOverlayFontStylesToolbar: React.FunctionComponent = () => {
  const {
    availableFonts,
    onChangeFontColor,
    onChangeFontSize,
    onSelectFont,
    selectedFont,
    selectedFontColor,
    selectedFontSize,
  } = useTextOverlayFontStyles();

  return (
    <div className={block('toolbar-row')}>
      <TextToolbar.FontFamilySelectorV2
        fonts={availableFonts}
        onChange={onSelectFont}
        shouldUpdateRecentFonts
        value={selectedFont.familyName}
      />
      <TextToolbar.FontSizeInputV2
        onChange={onChangeFontSize}
        value={selectedFontSize}
      />
      <TextOverlayControlWrapper label="Color">
        <HistoricalColorPicker
          color={selectedFontColor}
          onChangeComplete={onChangeFontColor}
          popoverStyle={{ right: COLOR_PICKER_RIGHT_POS_CORRECTION }}
          swatchClassName={block('toolbar-color-picker')}
        />
      </TextOverlayControlWrapper>
    </div>
  );
};

export default TextOverlayFontStylesToolbar;

import * as React from 'react';

import { useEditorState } from './context/VideoEditorStateContext';
import { getFeatureState } from './state/features-utils';
import { ComingSoon, Disabled, Lockable, ProGated } from './types';

const { useMemo } = React;

interface RenderProps {
  state: Lockable | ComingSoon | Disabled | ProGated | true;
  reason?: string;
}

export interface AccessControlProps {
  children?: React.FunctionComponent<RenderProps>;
  featurePath: Array<string | number>;
}

const AccessControl: React.FC<AccessControlProps> = ({
  children,
  featurePath,
}) => {
  const { features } = useEditorState();
  const state = useMemo(() => getFeatureState(features, featurePath), [
    features,
    featurePath,
  ]);

  if (state === 'hidden') {
    return null;
  }

  if (typeof state === 'string' || typeof state === 'boolean') {
    return children({ state });
  }

  return children({ state: 'disabled', reason: state.reason });
};

export default AccessControl;

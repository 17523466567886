import FontFaceObserver from 'fontfaceobserver';
import _ from 'underscore';
import { asArray } from 'utils/collections';
import { domTreeWalker, htmlStringToDomTree } from './dom';
import { RENDER_OFFSCREEN_RULES, setStyle } from './ui';

// borrowed from https://github.com/winmarkltd/BootstrapFormHelpers/blob/master/js/lang/en_US/bootstrap-formhelpers-fonts.en_US.js
export const fonts = {
  Alegreya: '"Alegreya", serif',
  'Alegreya Sans': '"Alegreya Sans", sans-serif',
  Almendra: '"Almendra", serif',
  'Anonymous Pro': '"Anonymous Pro", monospace',
  Archivo: '"Archivo", sans-serif',
  Arvo: '"Arvo", serif',
  'Asap Condensed': '"Asap Condensed", sans-serif',
  Audiowide: '"Audiowide", sans-serif',
  'Averia Sans Libre': '"Averia Sans Libre", cursive',
  'Barlow Condensed': '"Barlow Condensed", sans-serif',
  'Bebas Neue': '"Bebas Neue", cursive',
  BioRhyme: '"BioRhyme", serif',
  Butcherman: '"Butcherman", cursive',
  Cabin: '"Cabin", sans-serif',
  Cardo: '"Cardo", serif',
  Cedarville: '"Cedarville", serif',
  Chivo: '"Chivo", sans-serif',
  'Cormorant Infant': '"Cormorant Infant", serif',
  Creepster: '"Creepster", cursive',
  'Crimson Pro': '"Crimson Pro", serif',
  'Crimson Text': '"Crimson Text", serif',
  Cuprum: '"Cuprum", sans-serif',
  'DM Mono': '"DM Mono", monospace',
  Eater: '"Eater", cursive',
  Eczar: '"Eczar", serif',
  Economica: '"Economica", sans-serif',
  'Expletus Sans': '"Expletus Sans", cursive',
  Faustina: '"Faustina", serif',
  'Fira Sans': '"Fira Sans", sans-serif',
  Fraunces: '"Fraunces", serif',
  Fredoka: '"Fredoka", sans-serif',
  'IBM Plex Sans': '"IBM Plex Sans", sans-serif',
  Inconsolata: '"Inconsolata", monospace',
  'Inknut Antiqua': '"Inknut Antiqua", serif',
  Inter: '"Inter", sans-serif',
  'Josefin Slab': '"Josefin Slab", serif',
  Karla: '"Karla", sans-serif',
  Lato: '"Lato", sans-serif',
  'Libre Baskerville': '"Libre Baskerville", serif',
  'Libre Franklin': '"Libre Franklin", sans-serif',
  'Lobster Two': '"Lobster Two", cursive',
  Lora: '"Lora", serif',
  Marvel: '"Marvel", sans-serif',
  Merriweather: '"Merriweather", serif',
  Molle: '"Molle", serif',
  Montserrat: '"Montserrat", sans-serif',
  Mukta: '"Mukta", sans-serif',
  Neuton: '"Neuton", serif',
  Nosifer: '"Nosifer", cursive',
  'Noto Sans': '"Noto Sans", sans-serif',
  'Noto Serif': '"Noto Serif", serif',
  Nunito: '"Nunito", sans-serif',
  'Open Sans': '"Open Sans", sans-serif',
  Oswald: '"Oswald", sans-serif',
  Parisienne: '"Parisienne", cursive',
  'Playfair Display': '"Playfair Display", serif',
  Poppins: '"Poppins", sans-serif',
  'Proza Libre': '"Proza Libre", sans-serif',
  'PT Sans': '"PT Sans", sans-serif',
  'PT Serif': '"PT Serif", serif',
  Puritan: '"Puritan", sans-serif',
  Quantico: '"Quantico", sans-serif',
  Raleway: '"Raleway", sans-serif',
  Roboto: '"Roboto", sans-serif',
  'Roboto Slab': '"Roboto Slab", serif',
  Rubik: '"Rubik", sans-serif',
  Sansita: '"Sansita", sans-serif',
  Shrikhand: '"Shrikhand", serif',
  Simonetta: '"Simonetta", cursive',
  Sofia: '"Sofia", serif',
  'Sofia Sans Condensed': '"Sofia Sans Condensed", sans-serif',
  'Source Sans Pro': '"Source Sans Pro", sans-serif',
  'Source Serif Pro': '"Source Serif Pro", serif',
  'Space Grotesk': '"Space Grotesk", sans-serif',
  'Space Mono': '"Space Mono", monospace',
  'Special Elite': '"Special Elite", serif',
  Spectral: '"Spectral", serif',
  Tajawal: '"Tajawal", sans-serif',
  'Tenor Sans': '"Tenor Sans", serif',
  Ubuntu: '"Ubuntu", sans-serif',
  Vollkorn: '"Vollkorn", serif',
  VT323: '"VT323", sans-serif',
  'Waiting For The Sunrise': '"Waiting For The Sunrise", serif',
  'Work Sans': '"Work Sans", sans-serif',
};

export const fontList = Object.keys(fonts).reduce((acc, font) => {
  acc.push({
    name: font,
    family: fonts[font],
  });
  return acc;
}, []);

// family should be formatted as they are above
export function getFamilyName(family) {
  if (!family) return undefined;
  const [name] = family.split(',');
  return name.replace(/["|']/g, '').trim();
}

export const defaultFontFamily = fonts.Inter;

// formatting fonts for font list
export function getFormattedPersonalFonts(personalFonts) {
  const formattedPersonalFonts = personalFonts.reduce(
    (acc, font) => [
      ...acc,
      {
        name: font.displayName,
        familyName: font.familyName,
        family: font.familyName,
        url: font.url,
        id: font.fontId,
        isOrganizationAccess: font.isOrganizationAccess,
      },
    ],
    [],
  );

  return _.sortBy(formattedPersonalFonts, 'name');
}

export function getFormattedNormalizedFonts(personalFonts) {
  const formattedNormalizedPersonalFonts = personalFonts.reduce(
    (acc, font) => [
      ...acc,
      {
        name: font.get('displayName'),
        familyName: font.get('familyName'),
        family: font.get('familyName'),
        url: font.get('url'),
        id: font.get('fontId'),
        isOrganizationAccess: font.get('isOrganizationAccess'),
      },
    ],
    [],
  );

  const sortedPersonalFonts = _.sortBy(
    formattedNormalizedPersonalFonts,
    font => font.name,
  );

  return sortedPersonalFonts;
}

export function getCombinedFontList(personalFonts) {
  const formattedPersonalFonts = getFormattedNormalizedFonts(personalFonts);

  const formattedFonts = fontList.reduce(
    (acc, font) => [
      ...acc,
      {
        name: font.name,
        familyName: font.name,
        family: font.family,
      },
    ],
    [],
  );

  return [...formattedPersonalFonts, ...formattedFonts];
}

// The searchFontList used here is the same list that getCombinedFontList returns
export function getFontName(family, searchFontList, defaultFontName = 'Lato') {
  if (!family || !searchFontList || searchFontList.length === 0)
    return undefined;
  const familyName = getFamilyName(family);
  const font = searchFontList.find(f => f.familyName === familyName);
  return (font && font.name) || defaultFontName;
}

// load inline fonts from an html string
export async function loadElementFonts(htmlString) {
  const htmlStrings = asArray(htmlString);
  const htmlFonts = htmlStrings.reduce((acc, html) => {
    const root = htmlStringToDomTree(html);
    const container = document.createElement('div');
    setStyle(container, { ...RENDER_OFFSCREEN_RULES });

    container.appendChild(root);
    document.body.appendChild(container);

    const walker = domTreeWalker(root, NodeFilter.SHOW_TEXT);
    const textNode = walker.nextNode();

    let node = textNode.parentElement;
    while (node) {
      const familyName = getFamilyName(node.style.fontFamily);

      if (familyName) {
        const weight = window.getComputedStyle(node).fontWeight;

        if (
          !acc.find(f => f.familyName === familyName && f.weight === weight)
        ) {
          acc.push({ familyName, weight });
        }
      }

      node = node.parentElement;
    }

    container.remove();

    return acc;
  }, []);

  const observers = htmlFonts.map(f =>
    new FontFaceObserver(f.familyName, { weight: f.weight }).load(),
  );

  return Promise.all(observers);
}

import React, { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { Step, WizardControlButton } from 'components/Wizard';
import useMediaPlayback from 'hooks/useMediaPlayback';
import {
  entireAudioInstanceIdSelector,
  templateSelector,
  uploadProgressSelector,
} from 'redux/modules/template-wizard/selectors';
import { VideoTypes } from 'types';
import {
  AUDIOGRAM_CUTOFF_DURATION_STRING,
  AUDIOGRAM_CUTOFF_MILLIS,
} from 'utils/constants';
import { createChainedFunction } from 'utils/functions';
import ClipAudioStep, { ClipAudioStepProps } from '../ClipAudioStep';
import { TranscriptionFormValue } from '../types';
import ChooseAnotherTemplateLink from './ChooseAnotherTemplateLink';
import { TemplateWizardStep } from './types';
import { block } from './utils';

type PickedAudioStepProps = Pick<
  ClipAudioStepProps,
  | 'clipperRef'
  | 'src'
  | 'onClearError'
  | 'onError'
  | 'onPresetChange'
  | 'onReady'
  | 'onRegionUpdate'
>;

export interface UseClipAudioStepConfig extends PickedAudioStepProps {
  hidden?: boolean;
  onNextClick?: () => void;
  regionSelected?: boolean;
  transcription?: TranscriptionFormValue;
}

interface UseClipAudioStepResult {
  step: Step<TemplateWizardStep.CLIP_AUDIO>;
  transcription: TranscriptionFormValue;
}

const defaultCompatibilityTypes = [];

export default function useClipAudioStep({
  clipperRef,
  hidden,
  onPresetChange,
  onClearError,
  onError,
  onNextClick,
  onReady,
  onRegionUpdate,
  regionSelected,
  src,
}: UseClipAudioStepConfig): UseClipAudioStepResult {
  const { pause, play, playing } = useMediaPlayback();
  const uploadProgress = useSelector(uploadProgressSelector);
  const entireAudioInstanceId = useSelector(entireAudioInstanceIdSelector);
  const template = useSelector(templateSelector);
  const [transcription, setTranscription] = useState<TranscriptionFormValue>({
    language: 'en-US',
    transcribe: false,
  });

  useEffect(() => {
    if (hidden) {
      pause();
    }
  }, [hidden, pause]);

  const compatibilityTypes =
    template?.compatibleVideoTypes ?? defaultCompatibilityTypes;

  const isShortClipOnly =
    compatibilityTypes.includes(VideoTypes.SHORT_CLIP) &&
    !compatibilityTypes.includes(VideoTypes.FULL_EPISODE);

  const transcriptionConfig = useMemo(
    () => ({
      transcriptionToggleAllowed: compatibilityTypes.includes(
        VideoTypes.SHORT_CLIP,
      ),
    }),
    [compatibilityTypes],
  );

  return {
    transcription,
    step: {
      completedName: 'Audio Clipped',
      component: (
        <ClipAudioStep
          {...{
            clipperRef,
            src,
            onClearError,
            onError,
            playing,
            transcription,
            uploadProgress,
          }}
          entireAudioInstanceId={src ? entireAudioInstanceId : undefined}
          className={block('clip-audio-step', { hidden })}
          maxDurationMillis={
            isShortClipOnly ? AUDIOGRAM_CUTOFF_MILLIS : Infinity
          }
          onPause={pause}
          onPlay={play}
          onStop={pause}
          onReady={onReady}
          onRegionUpdate={onRegionUpdate}
          onPresetChange={onPresetChange}
          onTranscriptionChange={setTranscription}
          transcriptionConfig={transcriptionConfig}
        />
      ),
      description: isShortClipOnly ? (
        <div>
          The template you selected supports up to{' '}
          {AUDIOGRAM_CUTOFF_DURATION_STRING} of audio, for longer clips{' '}
          <ChooseAnotherTemplateLink stepName="ClipAudioStep" />.
        </div>
      ) : (
        `Clips over ${AUDIOGRAM_CUTOFF_DURATION_STRING} cannot include captions or be opened in our advanced editor.`
      ),
      keepMounted: true,
      name: 'Clip Audio',
      renderNextButton: ({ onClick, ...props }) => (
        <WizardControlButton
          {...props}
          disabled={!regionSelected}
          onClick={createChainedFunction(onClick, onNextClick)}
          theme="next"
        >
          next
        </WizardControlButton>
      ),
      stepId: TemplateWizardStep.CLIP_AUDIO,
      title: 'Select your audio clip',
    },
  };
}

import { Spacer, Text } from '@sparemin/blockhead';
import React from 'react';
import useTextOverlayAdvancedAnimation from '../../useTextOverlayAdvancedAnimation';
import { block } from '../utils';
import { karaokeAnimationOptions } from './constants';

export interface KaraokeAnimationEmphasisOptionsProps {}

const KaraokeAnimationEmphasisOptions: React.FC<KaraokeAnimationEmphasisOptionsProps> = () => {
  const {
    selectedAdvancedAnimation,
    onActiveAnimationChange,
  } = useTextOverlayAdvancedAnimation();

  return (
    <Spacer
      orientation="horizontal"
      space="12px"
      align="center"
      justify="center"
      className={block('animation-emphasis-options')}
    >
      {karaokeAnimationOptions.map(({ label, value }) => {
        const checked = selectedAdvancedAnimation?.animationName === value;

        return (
          <label key={value} className={block('emphasis-option', { checked })}>
            <input
              type="radio"
              checked={checked}
              value={value}
              onChange={() => onActiveAnimationChange(value)}
            />

            <Text variant="p">{label}</Text>
          </label>
        );
      })}
    </Spacer>
  );
};

export default KaraokeAnimationEmphasisOptions;

import { Button, Spacer } from '@sparemin/blockhead';
import React from 'react';

import ModalContentWithMedia from 'components/Modal/ModalContentWithMedia';
import { Step } from 'components/SteppedModal';

import { block } from '../../utils';
import { InstagramPostModalStep } from '../types';
import ComposerDefaultView from './ComposerDefaultView';
import { UsePostComposerBaseConfig } from './types';

export interface UsePostComposerStepConfig extends UsePostComposerBaseConfig {
  description: string;
  allowSubmit: boolean;
  username: string;
  profileImageUrl: string;
  onDescriptionChange: (newDescription: string) => void;
  onHideModal?: () => void;
  onSubmit?: () => void;
  onSwitchAccountClick?: () => void;
}

export default function usePostComposerStep({
  description,
  allowSubmit,
  username,
  profileImageUrl,
  onDescriptionChange,
  onHideModal,
  onSubmit,
  onSwitchAccountClick,
}: UsePostComposerStepConfig): Step<InstagramPostModalStep> {
  return {
    id: 'composer',
    hideFooter: true,
    replaceBody: true,
    component: (
      <>
        <ModalContentWithMedia
          className="composer-step-content"
          onClose={onHideModal}
        >
          <ModalContentWithMedia.ModalContentWithMediaColumn>
            <img
              src="https://static.sparemin.com/static/webapp-assets/auto-posting-detail/auto-posting-detail-instagram-image-a-202407311738.png"
              className={block('composer-image')}
            />
          </ModalContentWithMedia.ModalContentWithMediaColumn>

          <ModalContentWithMedia.ModalContentWithMediaColumn className="composer-content-column">
            <Spacer
              space="20px"
              orientation="vertical"
              align="flex-start"
              className={block('composer-view')}
            >
              <ComposerDefaultView
                {...{
                  username,
                  profileImageUrl,
                  description,
                  onDescriptionChange,
                  onSwitchAccountClick,
                }}
              />
            </Spacer>

            <Button
              fluid
              variant="solid"
              onPress={onSubmit}
              isDisabled={!allowSubmit}
            >
              confirm auto-posting details
            </Button>
          </ModalContentWithMedia.ModalContentWithMediaColumn>
        </ModalContentWithMedia>
      </>
    ),
  };
}

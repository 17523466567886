import React, { useCallback } from 'react';

import { omit } from 'underscore';
import VideoTemplateEditor, {
  ActionType,
  OnIsAutoFrameChange,
  TemplateAction,
  VideoTemplateState,
} from 'components/VideoTemplateEditor';
import {
  useIntroOutroUploadIntegration,
  useMediaUploadIntegration,
  useStaticTextIntegration,
} from 'components/VideoTemplateEditor/integrations';
import useDynamicVideoIntegration from 'components/VideoTemplateEditor/integrations/useDynamicVideoIntegration';
import { PlaceholderVideo } from 'components/VideoTemplateEditor/types/integrations';
import { Style } from 'components/VideoTemplateEditor/useOnStyleChange';
import useCustomizeStepState, {
  UseCustomizeStepStateConfig,
} from 'containers/ProjectWizards/CustomizeStep/useCustomizeStepState';
import { TranscriptionFormValue } from 'containers/TranscriptionForm';
import useCustomFontsLoader from 'hooks/useCustomFontsLoader';
import useLastUsedStyleDispatch from 'hooks/useLastUsedStyleDispatch';
import useLastUsedStyleSelector from 'hooks/useLastUsedStyleSelector';
import { Omit } from 'types';
import { block } from './utils';

export interface VideoWizardCustomizeStepProps
  extends Omit<
    UseCustomizeStepStateConfig,
    'templateId' | 'lastUsedStyle' | 'onStyleChange'
  > {
  transcription?: TranscriptionFormValue;
  audioClipDurationSec?: number;
  ctaLabel?: string;
  placeholderVideo?: PlaceholderVideo;
  onIsAutoFrameChange?: OnIsAutoFrameChange;
  onTranscriptionChange: (transcription: TranscriptionFormValue) => void;
}

const VideoWizardCustomizeStep: React.FC<VideoWizardCustomizeStepProps> = ({
  defaults,
  transcription,
  audioClipDurationSec,
  ctaLabel,
  placeholderVideo,
  features: baseFeatures,
  onError,
  onIsAutoFrameChange,
  onTranscriptionChange,
  onSubmit: onSubmitProp,
}) => {
  const { setLastUsedStyle } = useLastUsedStyleDispatch('videoTranscription');
  const { lastUsedStyle } = useLastUsedStyleSelector('videoTranscription');

  const handleStyleChange = useCallback(
    (style: Style, actionType: ActionType): void => {
      if (actionType === 'TRANSCRIPTION_CHANGE') {
        onTranscriptionChange(style.transcription);
      }

      setLastUsedStyle(omit(style, 'image', 'video'));
    },
    [onTranscriptionChange, setLastUsedStyle],
  );

  const {
    state,
    features,
    imageProcessorStatus,
    onChange,
    onSubmit,
  } = useCustomizeStepState({
    defaults,
    transcription,
    lastUsedStyle,
    features: baseFeatures,
    initializeSoundwave: false,
    onError,
    onStyleChange: handleStyleChange,
    onSubmit: onSubmitProp,
  });

  // Preloads the fonts for getting the font styles when mounting the customize step.
  useCustomFontsLoader();

  const handleChange = useCallback(
    (
      stateUpdater: (prevState: VideoTemplateState) => VideoTemplateState,
      action: TemplateAction,
    ) => {
      onChange(stateUpdater, action);
    },
    [onChange],
  );

  const integrations = [
    useDynamicVideoIntegration({
      placeholderVideo,
      state,
      priority: 0,
      onIsAutoFrameChange,
      onChange: handleChange,
    }),
    useMediaUploadIntegration({ priority: 1 }),
    useStaticTextIntegration({
      priority: 2,
    }),
    useIntroOutroUploadIntegration({ priority: 3 }),
  ];

  return (
    <div className={block()}>
      <VideoTemplateEditor
        {...{
          state,
          integrations,
          features,
          transcription,
          audioClipDurationSec,
          ctaLabel,
        }}
        loading={imageProcessorStatus === 'processing'}
        className={block('editor')}
        showControls={false}
        onChange={handleChange}
        onCtaClick={onSubmit}
      />
    </div>
  );
};

export default VideoWizardCustomizeStep;

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  canGenerateSocialPostCaptionsSelector,
  createSocialPostCaptions,
  hasSocialPostCaptionInfoSelector,
  socialPostCaptionInfoByPlatformSelector,
} from 'redux/modules/download';
import { UseSocialPostCaptionsResult } from './types';

export type UseAudioSocialPostCaptionsResult = UseSocialPostCaptionsResult;

export default function useAudioSocialPostCaptions(
  episodeId: number | undefined,
): UseAudioSocialPostCaptionsResult {
  const dispatch = useDispatch();

  const socialPostCaptionInfoByPlatform = useSelector(
    socialPostCaptionInfoByPlatformSelector,
  );

  const hasSocialPostCaptions = useSelector(hasSocialPostCaptionInfoSelector);

  const canGenerateSocialPostCaptions = useSelector(
    canGenerateSocialPostCaptionsSelector,
  );

  const handleCreateSocialPostCaptions = useCallback(() => {
    if (episodeId && canGenerateSocialPostCaptions) {
      dispatch(createSocialPostCaptions(episodeId));
    }
  }, [canGenerateSocialPostCaptions, dispatch, episodeId]);

  return {
    canGenerateSocialPostCaptions,
    hasSocialPostCaptions,
    socialPostCaptionInfoByPlatform,
    createSocialPostCaptions: handleCreateSocialPostCaptions,
  };
}

import * as React from 'react';
import { Col } from 'react-bootstrap';

interface IProps {
  sm?: number;
  xs?: number;
  children: React.ReactNode;
}

const VideoOptionsField: React.SFC<IProps> = ({
  sm = 6,
  xs = 12,
  children,
}) => <Col {...{ sm, xs }}>{children}</Col>;

export default VideoOptionsField;
export { IProps as VideoOptionsFieldProps };

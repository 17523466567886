import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { ClipsPageInfo, ClipSuggestion } from 'blocks/ClipSelect/types';
import { CreationResponse } from 'redux/middleware/api/creation-service/types';
import { IVideoUpload } from 'redux/middleware/api/media-upload-service';
import { dislikedCreationSuggestionIdsSelector } from 'redux/modules/clip-select';
import {
  creationEddyProjectIdSelector,
  makeVideoSelector,
} from 'redux/modules/entities';
import { ClipSelectPageSource } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { parseSearch } from 'utils/routes';
import { ClipSelectPageLocationSearch } from 'utils/routes/types';
import {
  creationSelector,
  hasCreationsSelector,
  suggestedClipsSelector,
} from './selectors';

export interface UseVideoTranscriptionSuggestedClipInfoResult {
  creationRequestId?: number;
  creation: CreationResponse;
  eddyProjectId?: string;
  video: IVideoUpload;
  hasCreations: boolean;
  suggestedClips?: ClipSuggestion[];
  dislikedSuggestionIds?: number[];
  clipsPageInfo?: ClipsPageInfo;
  source?: ClipSelectPageSource;
}

export default function useVideoTranscriptionSuggestedClipInfo(): UseVideoTranscriptionSuggestedClipInfoResult {
  const location = useLocation();

  const eddyProjectId = useSelector(creationEddyProjectIdSelector);

  const suggestedClips = useSelector(suggestedClipsSelector);

  const dislikedSuggestionIds = useSelector(
    dislikedCreationSuggestionIdsSelector,
  );

  const {
    params: { creationRequestId },
  } = useRouteMatch<{
    creationRequestId?: string;
  }>();

  const creationId = Number(creationRequestId);

  const hasCreations = useSelector(hasCreationsSelector);

  const creation = useSelector(
    useMemo(() => state => creationSelector(state, creationId), [creationId]),
  );

  const videoSelector = useMemo(
    () => makeVideoSelector(creation?.requestObj?.videoInfo?.videoId),
    [creation],
  );
  const video = useSelector(videoSelector);
  const aspectRatioName = getAspectRatioName(
    creation?.requestObj?.aspectRatioHeight,
    creation?.requestObj?.aspectRatioWidth,
  );
  const { thumbnails } = video?.previewThumbnail || {};
  const { source }: ClipSelectPageLocationSearch = parseSearch(location.search);

  return {
    creationRequestId: creationId,
    creation,
    eddyProjectId,
    video,
    hasCreations,
    suggestedClips,
    dislikedSuggestionIds,
    clipsPageInfo: {
      title: creation?.requestObj?.projectName,
      artwork:
        thumbnails?.find(({ type }) => type === 'firstFrameMedium')?.url ??
        thumbnails?.find(({ type }) => type === 'firstFrame')?.url ??
        '',
      aspectRatioName,
      artworkAspectRatioName: getAspectRatioName(
        video?.videoHeight,
        video?.videoWidth,
      ),
      videoUrl: video?.transcodedVideoUrl,
      mediaDurationMillis: video?.durationMillis,
      publishedAtMillis: creation?.createdAt,
    },
    source,
  };
}

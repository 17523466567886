import { Button, Edit } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import Tooltip from 'components/Tooltip';
import { selectorBlock as block } from '../../utils';
import { useClipSelect } from '../ClipSelectContext';
import { useClipSelectTracking } from '../ClipSelectTrackingContext';

export interface EditClipButtonProps {
  isDisabled: boolean;
}

const EditClipButton: React.FC<EditClipButtonProps> = ({ isDisabled }) => {
  const { onOpenInAdvancedEditor } = useClipSelect();
  const {
    onOpenInAdvancedEditor: trackOpenInAdvancedEditor,
  } = useClipSelectTracking();

  const handleGoToAdvancedEditor = useCallback((): void => {
    trackOpenInAdvancedEditor();
    onOpenInAdvancedEditor();
  }, [onOpenInAdvancedEditor, trackOpenInAdvancedEditor]);

  return (
    <Tooltip
      placement="top"
      hideArrow={false}
      content={!isDisabled && 'Open in Advanced Editor'}
      show={isDisabled ? false : undefined}
    >
      <Button
        isDisabled={isDisabled}
        className={block('action-round-button')}
        color="secondary"
        onPress={handleGoToAdvancedEditor}
      >
        <Edit style={{ width: '16px', height: '16px' }} />
      </Button>
    </Tooltip>
  );
};

export default EditClipButton;

import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import { block } from './utils';

export interface RecommendationStampProps {
  icon?: React.ReactNode;
  description?: React.ReactNode;
  theme?: 'default' | 'youtube';
}

const RecommendationStamp: React.FC<RecommendationStampProps> = ({
  icon,
  description,
  theme = 'default',
}) => {
  return (
    <Spacer
      orientation="horizontal"
      align="center"
      justify="space-between"
      space="6px"
      className={block({ [theme]: !!theme })}
    >
      {icon && <div className={block('icon-container')}>{icon}</div>}

      {description && (
        <span className={block('description')}>{description}</span>
      )}
    </Spacer>
  );
};

export default RecommendationStamp;

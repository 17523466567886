/*
 Migrations file for the new text modal editor. This file is not intended
 for migrations between the legacy and the new text modal but for migrations
 for the existing modal.

 Migrations so far:
 - Gooey text effect.
*/
import { fromJS } from 'immutable';
import { TextOverlayV2 } from './types';

const gooeyTextMigration = (overlay: TextOverlayV2): TextOverlayV2 => {
  return overlay.withMutations(s => {
    // if gooeyText config is set for the overlay it means that the there
    // is no need for any migration.
    if (s.getIn(['advancedTextConfigs', 'gooeyText'])) {
      return s;
    }

    // if textHighlight style is not present it means that there is no need
    // for adding the gooey text config.
    if (!s.getIn(['editor', 'textStyle', 'textHighlight'])) {
      return s;
    }

    // when the text highlight is set, the gooey text config is extracted
    // from the highlight color and the radius set to 0
    s.setIn(
      ['advancedTextConfigs', 'gooeyText'],
      fromJS({
        color: s.getIn(['editor', 'textStyle', 'textHighlight']),
        radius: 0,
      }),
    );
    s.deleteIn(['editor', 'textStyle', 'textHighlight']);

    return s;
  });
};

const applyMigrations = (overlay: TextOverlayV2): TextOverlayV2 => {
  return overlay.withMutations(s => {
    if (!s.get('advancedTextConfigs')) {
      s.set('advancedTextConfigs', fromJS({}));
    }

    if (!s.get('advancedAnimation')) {
      s.set('advancedAnimation', fromJS({}));
    }

    gooeyTextMigration(s);
  });
};

export default applyMigrations;

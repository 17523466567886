import * as React from 'react';
import ResizeDetector from 'react-resize-detector';

import ScrollBars, { ScrollBarsProps } from 'components/ScrollBars';
import { MAX_VIDEO_EXPORT_DURATION_SECONDS } from 'utils/constants';
import { GUTTER_PX } from '../constants';
import TrackLabels, { TrackLabelsProps } from '../containers/TrackLabels';
import { block, secToPosition } from '../utils';
import TimelineWarningOverlay from './TimelineWarningOverlay';

export interface TimelineBodyProps
  extends Pick<TrackLabelsProps, 'onLabelEntered'> {
  children?: React.ReactNode;
  onClick?: (position: number) => void;
  onDeleteTrack?: TrackLabelsProps['onDeleteButtonClick'];
  onResize?: (width: number) => void;
  onUserScroll?: ScrollBarsProps['onScroll'];
  pxPerSec: number;
  scrollLeft?: number;
  width?: number;
}

export default class TimelineBody extends React.Component<TimelineBodyProps> {
  private content: ScrollBars;
  private body: ScrollBars;
  private workspace: HTMLDivElement;

  public componentDidUpdate(prevProps: Readonly<TimelineBodyProps>) {
    const { scrollLeft: prevScrollLeft } = prevProps;
    const { scrollLeft } = this.props;

    if (scrollLeft !== prevScrollLeft) {
      this.content.scrollLeft = scrollLeft;
    }
  }

  private handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { onClick } = this.props;
    const workspaceRect = this.workspace.getBoundingClientRect();
    const position = Math.max(0, e.pageX - workspaceRect.left);
    onClick(position);
  };

  private setContent = el => {
    this.content = el;
  };

  private setBody = el => {
    this.body = el;
  };

  private setWorkspace = el => {
    this.workspace = el;
  };

  public scrollBody(offsetTop: number) {
    this.body.scrollTo({
      behavior: 'smooth',
      top: offsetTop,
    });
  }

  public render() {
    const {
      children,
      onDeleteTrack,
      onLabelEntered,
      onResize,
      onUserScroll,
      pxPerSec,
      width,
    } = this.props;

    const warningOverlayPosition = secToPosition(
      MAX_VIDEO_EXPORT_DURATION_SECONDS,
      pxPerSec,
    );

    return (
      <ScrollBars ref={this.setBody} style={{ flexGrow: 1 }}>
        <div className={block('body')}>
          <TrackLabels
            onLabelEntered={onLabelEntered}
            onDeleteButtonClick={onDeleteTrack}
          />
          <ScrollBars
            onScroll={onUserScroll}
            ref={this.setContent}
            style={{ height: 'auto' }}
          >
            <ResizeDetector handleWidth onResize={onResize} />
            <div
              className={block('content')}
              style={{
                width,
                paddingLeft: GUTTER_PX,
              }}
              onClick={this.handleClick}
            >
              {width > warningOverlayPosition && (
                <TimelineWarningOverlay
                  position={warningOverlayPosition}
                  width={width - warningOverlayPosition}
                />
              )}
              <div className={block('workspace')} ref={this.setWorkspace}>
                {children}
              </div>
            </div>
          </ScrollBars>
        </div>
      </ScrollBars>
    );
  }
}

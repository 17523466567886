import { fromJS } from 'immutable';
import { ImmutableTextPreset } from 'redux/modules/text-presets';
import bem from 'utils/bem';
import { splitStyle } from 'utils/rte';
import {
  getUpperLeft,
  parseTextShadowString,
  parseTextStrokeConfig,
} from 'utils/ui';
import { EditorData, TextOverlay, Transitions } from './types';

export const block = bem('text-modal');

export const BASE_WORKSPACE_COL_SIZE = 12;
export const STYLE_TABS_COL_SIZE = 4;
export const STYLE_PRESETS_COL_SIZE = 2;

export const TEXT_STYLE_MAP = [
  ['background', 'textHighlight'],
  ['paddingLeft', 'textPaddingLeft'],
  ['paddingRight', 'textPaddingRight'],
  ['boxDecorationBreak', 'boxDecorationBreak'],
  ['textTransform', 'textTransform'],
] as const;

// Targets the text styles that can be set using presets
export const TEXT_PRESET_DEFAULT_OVERRIDE = fromJS({
  background: 'rgba(0, 0, 0, 0)',
  fontFamily: '"Inter", sans-serif',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textHighlight: 'rgba(0, 0, 0, 0)',
  textOutline: undefined,
  textShadow: undefined,
  textDecoration: 'none',
});

export const TEXT_PRESET_STYLE_KEY_NORMALIZATION = {
  webkitTextStroke: 'textOutline',
};

// Applies custom sanitizations for particular style props that needs to be
// transformed like the textShadow one.
export const TEXT_PRESET_SANITIZERS = {
  textOutline: (textOutlineStr: string) =>
    textOutlineStr ? parseTextStrokeConfig(textOutlineStr) : undefined,
  textShadow: (textShadowStr: string) =>
    textShadowStr ? parseTextShadowString(textShadowStr) : undefined,
};

export function convertOverlayToEditorData(overlay: TextOverlay): EditorData {
  return (overlay.withMutations(to => {
    to.delete('containerDecorator');
    to.delete('transitions');
    to.delete('text');
    to.set('containerStyle', to.get('style'));
    to.set(
      'position',
      getUpperLeft(to.get('position'), to.get('size'), to.get('viewport')),
    );

    TEXT_STYLE_MAP.forEach(([textStypeProp, styleProp]) => {
      const propValue = to.getIn(['style', styleProp]);
      if (propValue) {
        to.setIn(['textStyle', textStypeProp], propValue);
      }
    });
    to.delete('style');
  }) as any) as EditorData;
}

export function formatPresetStyles(
  presetData: ImmutableTextPreset,
  styleKeysBlackList: string[] = [],
): typeof TEXT_PRESET_DEFAULT_OVERRIDE {
  return TEXT_PRESET_DEFAULT_OVERRIDE.withMutations(s => {
    presetData
      .get('cssStyles')
      .keySeq()
      .forEach(key => {
        s.set(key, presetData.getIn(['cssStyles', key]));
      });

    Object.entries(TEXT_PRESET_STYLE_KEY_NORMALIZATION).forEach(
      ([prop, normalizedName]) => {
        s.set(normalizedName, s.get(prop));
        s.delete(prop);
      },
    );

    Object.entries(TEXT_PRESET_SANITIZERS).forEach(([prop, sanitizer]) => {
      s.set(prop, sanitizer(s.get(prop)));
    });

    styleKeysBlackList.forEach(key => {
      s.delete(key);
    });

    return s;
  });
}

export function getWorkspaceColSize(
  showStyleTabs: boolean,
  showPresetSelector: boolean,
): number {
  const styleTabsColSize = showStyleTabs ? STYLE_TABS_COL_SIZE : 0;
  const presetsColSize = showPresetSelector ? STYLE_PRESETS_COL_SIZE : 0;
  return BASE_WORKSPACE_COL_SIZE - styleTabsColSize - presetsColSize;
}

export function convertEditorDataToOverlay(
  data: EditorData,
  textHtml: string,
  transitions: Transitions,
): TextOverlay {
  return (data.withMutations(d => {
    const containerStyle = d.get('containerStyle');
    const textStyle = d.get('textStyle');

    const style = TEXT_STYLE_MAP.reduce((acc, [textStypeProp, styleProp]) => {
      const propValue = textStyle.get(textStypeProp);
      if (propValue) {
        return acc.set(styleProp, propValue);
      }
      return acc;
    }, containerStyle);

    /*
     * the only data we need to store as part of style is the "outer" style (since it won't get
     * picked up by tinymce) and lineHeight, since it's an inner style that's applied from outside
     * of tinymce since it doesn't have a toolbar input.  all other styles are
     * applied via the RTE and exist on textHtml
     */
    d.set(
      'style',
      splitStyle(style).outerStyle.set('lineHeight', style.get('lineHeight')),
    );
    d.set('transitions', transitions);
    d.set('textHtml', textHtml);
    d.delete('containerStyle');
    d.delete('textStyle');
  }) as any) as TextOverlay;
}

import cn from 'classnames';
import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import uncontrollable from 'uncontrollable';
import _, { isEqual } from 'underscore';

import { DestinationPreset } from 'blocks/DestinationPlatforms';
import { AudioClipperComponent } from 'components/AudioClipper';
import { IRegion, RegionUpdateAction } from 'components/AudioClipper/types';
import AsyncAudioClipper, {
  AsyncAudioClipperProps as AudioClipperProps,
} from 'containers/AsyncAudioClipper';
import TranscriptionForm, {
  TranscriptionFormValue,
} from 'containers/TranscriptionForm';
import useKey from 'hooks/useKey';
import { podcastDetailEpisodeSelector } from 'redux/modules/entities/selectors';
import { captionsPrefsSelector } from 'redux/modules/user-pref/selectors';
import { PodcastIdentifier } from 'types/common';
import bem from 'utils/bem';
import {
  DEFAULT_AUDIO_CLIP_DURATION_MILLIS,
  UPLOAD_RECORDING_MAX_DURATION,
} from 'utils/constants';
import { createChainedFunction } from 'utils/functions';
import useForkRef from 'utils/useForkRef';

import SoundbiteBanner from './SoundbiteBanner';
import { TranscriptionConfig } from './types';

const { useEffect, useState } = React;

const block = bem('clip-audio-step');

export enum ClipSelectionMethod {
  DEFAULT = 'Default',
  SOUNDBITE = 'Soundbite',
  MANUAL_SELECT = 'ManualSelect',
}

export interface ClipAudioStepProps
  extends Omit<AudioClipperProps, 'onRegionUpdate'> {
  children?: React.ReactNode;
  className?: string;
  clipperRef?: (el: AudioClipperComponent) => void;
  defaultClipDurationMillis?: number;
  defaultPresetKey?: DestinationPreset['key'];
  style?: Partial<React.CSSProperties>;
  maxDurationMillis?: number;
  onTranscriptionChange?: (value: TranscriptionFormValue) => void;
  transcription: TranscriptionFormValue;
  showTranscriptionToggle?: boolean;
  podcastIdentifier?: PodcastIdentifier;
  onRegionUpdate?: (region: IRegion, method: ClipSelectionMethod) => void;
  transcriptionConfig: TranscriptionConfig;
}

const getDefaultRegion = (
  durationMillis: number,
  isPodcastSearch: boolean,
  defaultDurationMillis?: number,
): IRegion => {
  const getEndMillis = () => {
    if (defaultDurationMillis !== undefined) {
      return defaultDurationMillis;
    }

    if (isPodcastSearch || durationMillis >= 60000 * 10) {
      return DEFAULT_AUDIO_CLIP_DURATION_MILLIS;
    }

    return durationMillis;
  };

  return {
    startMillis: 0,
    endMillis: Math.round(Math.min(durationMillis, getEndMillis())),
  };
};

const ClipAudioStep: React.FC<ClipAudioStepProps> = ({
  children,
  className,
  clipperRef,
  defaultClipDurationMillis,
  defaultPresetKey,
  disabled,
  entireAudioInstanceId,
  onReady,
  src,
  style,
  maxDurationMillis,
  onTranscriptionChange,
  region,
  showTranscriptionToggle = true,
  transcription,
  transcriptionConfig,
  podcastIdentifier,
  onRegionUpdate,
  ...clipperProps
}) => {
  const { captionEnabled, captionLanguage } = useSelector(
    captionsPrefsSelector,
  );
  const clipperKey = useKey(src);
  const [clipperReady, setClipperReady] = useState(false);
  const defaultRegionRef = useRef<IRegion>(null);
  const ref = useRef<AudioClipperComponent>();
  const { episodeId } = podcastIdentifier ?? {};
  const isPodcastSearch = !!episodeId;
  const episode = useSelector(
    useMemo(() => podcastDetailEpisodeSelector(episodeId), [episodeId]),
  );

  const handleClipperReady = () => {
    const { originalDurationMillis } = ref.current.clip;
    if (!defaultRegionRef.current) {
      defaultRegionRef.current = getDefaultRegion(
        originalDurationMillis,
        isPodcastSearch,
        defaultClipDurationMillis,
      );
      onRegionUpdate(defaultRegionRef.current, ClipSelectionMethod.DEFAULT);
    }
    setClipperReady(true);
  };

  useEffect(() => {
    defaultRegionRef.current = null;
    setClipperReady(false);
  }, [src]);

  const handleSoundbiteSelect = (value: IRegion) => {
    defaultRegionRef.current = value;
    onRegionUpdate(value, ClipSelectionMethod.SOUNDBITE);
  };

  const handleRegionUpdate = (value: IRegion, action: RegionUpdateAction) => {
    if (action !== 'init') {
      const isDefault = isEqual(value, defaultRegionRef.current);
      onRegionUpdate(
        value,
        isDefault
          ? ClipSelectionMethod.DEFAULT
          : ClipSelectionMethod.MANUAL_SELECT,
      );
    }
  };

  const handleTranscriptionChange = (value: TranscriptionFormValue) => {
    onTranscriptionChange?.(value);
  };

  useEffect(() => {
    if (
      !transcriptionConfig.transcriptionToggleAllowed &&
      transcriptionConfig.lockedConfig
    ) {
      onTranscriptionChange?.(transcriptionConfig.lockedConfig);
    } else if (captionEnabled || captionLanguage) {
      onTranscriptionChange?.({
        transcribe: captionEnabled,
        language: captionLanguage,
      });
    }
  }, [
    captionEnabled,
    captionLanguage,
    onTranscriptionChange,
    transcriptionConfig,
  ]);

  return (
    <div className={cn(block(), className)}>
      <SoundbiteBanner
        episodeId={podcastIdentifier?.episodeId}
        podcastId={podcastIdentifier?.podcastId}
        region={region}
        onRegionUpdate={handleSoundbiteSelect}
        className={block('banner')}
      />
      <AsyncAudioClipper
        {...clipperProps}
        className={block('clipper')}
        wavesurferClassName={block('clip-audio-wavesurfer')}
        entireAudioInstanceId={entireAudioInstanceId}
        initialPresetKey={defaultPresetKey}
        key={clipperKey}
        onReady={createChainedFunction(onReady, handleClipperReady)}
        disabled={disabled}
        src={src}
        proxySrc={episode?.proxyAudioUrl}
        estimatedDurationSec={episode?.durationSec}
        region={region}
        onRegionUpdate={handleRegionUpdate}
        ref={useForkRef(clipperRef, ref)}
        maxDurationMillis={maxDurationMillis}
        captionsControl={
          transcriptionConfig.transcriptionToggleAllowed && (
            <TranscriptionForm
              disabled={!clipperReady}
              durationMillis={
                // passing 0 for duration until clipper is ready avoids any flicker of
                // of the transcription balance as the audio loads into the clipper
                // and the clipper calculates the default region
                !clipperReady ? 0 : region?.endMillis - region?.startMillis ?? 0
              }
              onChange={handleTranscriptionChange}
              value={transcription}
            >
              {showTranscriptionToggle && <TranscriptionForm.Toggle />}
              <TranscriptionForm.LanguageSelect />
            </TranscriptionForm>
          )
        }
      />
    </div>
  );
};

ClipAudioStep.defaultProps = {
  clipperRef: _.noop,
  maxDurationMillis: UPLOAD_RECORDING_MAX_DURATION * 1000,
};

// most wizards don't pass region or onRegionUpdate.  AsyncAudioClipper is uncontrollable
// so it doesn't require these props.  this component is uncontrollable because
// the Audio wizard stores the region in redux and feeds it back into the clipper
// component so that redux is the single source of truth
const UncontrolledClipAudioStep = uncontrollable(ClipAudioStep, {
  region: 'onRegionUpdate',
});

export default UncontrolledClipAudioStep;

import { List, ThemeProvider } from '@sparemin/blockhead';
import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { makeProjectNameSelector } from 'redux/modules/entities';
import { getProjectHistory } from 'redux/modules/project/actions';
import { projectHistoryHasMoreSelector } from 'redux/modules/project/selectors';
import { Dispatch } from 'redux/types';
import { ProjectTileControl } from '../types';
import { makeProjectRowTooltipMessageSelector } from '../utils';
import ProjectRow from './ProjectRow';

export interface ProjectsListProps {
  active?: boolean;
  projects: string[];
  projectRowHeaderClassName?: string;
  onControlClick?: (type: ProjectTileControl, projectId: string) => void;
}

const ProjectsList: React.FC<ProjectsListProps> = ({
  active,
  projects,
  projectRowHeaderClassName,
  onControlClick,
}) => {
  const hasMore = useSelector(projectHistoryHasMoreSelector);

  const dispatch = useDispatch<Dispatch>();

  const { disabledKeys, items } = (projects ?? []).reduce(
    (acc, projectId) => {
      const projectName = dispatch((_, getState) =>
        makeProjectNameSelector(projectId)(getState()),
      );
      const tooltipMessage = dispatch((_, getState) =>
        makeProjectRowTooltipMessageSelector(projectId)(getState()),
      );

      const item = (
        <List.Item key={projectId} textValue={projectName}>
          <ProjectRow
            headerClassName={projectRowHeaderClassName}
            {...{ projectId, tooltipMessage, onControlClick }}
          />
        </List.Item>
      );

      // Modeled as an array so that it can be spread into disabledKeys array
      // without having to worry about removing undefined values later.
      const disabledKey = tooltipMessage ? [projectId] : [];

      return {
        disabledKeys: [...acc.disabledKeys, ...disabledKey],
        items: [...acc.items, item],
      };
    },
    { disabledKeys: [], items: [] },
  );

  const handleLoadMore = React.useCallback(() => {
    if (active) {
      dispatch(getProjectHistory(true));
    }
  }, [active, dispatch]);

  return (
    <ThemeProvider theme="dark">
      <List
        aria-label="projects list"
        hasMore={hasMore && active}
        disabledKeys={disabledKeys}
        onLoadMore={handleLoadMore}
      >
        {items}
      </List>
    </ThemeProvider>
  );
};

export default ProjectsList;

import React, { useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';
import List from 'components/List';
import Tooltip from 'components/Tooltip';
import {
  abortAwaitEpisodeTranscription,
  awaitEpisodeTranscription,
} from 'redux/modules/automation-cms';
import { Dispatch } from 'redux/types';
import { RequestStatus } from 'types';
import { EPISODE_TRANSCRIPTION_BUSY_STATUSES } from 'utils/constants';
import EpisodeListEpisodeDescription from '../../components/EpisodeListEpisodeDescription';
import EpisodeListRow from '../../components/EpisodeListRow';
import {
  episodeListBlock as block,
  isGenerating,
  isVideoPending,
} from '../../utils';
import EpisodeRowProgressOverlay from './EpisodeRowProgressOverlay';
import EpisodeRowStatus from './EpisodeRowStatus';
import useEpisodeListEpisodeRowContext from './useEpisodeListEpisodeRowContext';
import useProgressAnimation, {
  UseProgressAnimationConfig,
} from './useProgressAnimation';

type PickedAnimationHookProps = Pick<
  UseProgressAnimationConfig,
  'onAnimationBegin' | 'onAnimationEnd'
>;

export interface EpisodeListRowHeadlineProps extends PickedAnimationHookProps {
  onCreateClick: () => void;
  active: boolean;
  canTranscribe: boolean;
}

const EpisodeListRowHeadline: React.FC<EpisodeListRowHeadlineProps> = ({
  onAnimationBegin,
  onAnimationEnd,
  onCreateClick,
  active,
  canTranscribe,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { episode, projects } = useEpisodeListEpisodeRowContext();
  const episodeId = String(episode?.id);
  const transcriptionStatus = episode?.transcriptInfo?.status;
  const isTranscribing = EPISODE_TRANSCRIPTION_BUSY_STATUSES.includes(
    transcriptionStatus,
  );
  const initialIsTranscribingRef = useRef(isTranscribing);

  const videoPending = isVideoPending(episode);
  const generating = isGenerating(episode);

  const {
    isAnimating,
    onProgressAnimationEntered,
    onProgressAnimationExited,
    progressDelay,
    progressTimeout,
    progressTransitionIn,
    showStatus,
  } = useProgressAnimation({
    onAnimationBegin,
    onAnimationEnd,
    inProgress: episode?.manualTriggerStatus === RequestStatus.REQUEST,
  });

  // effectively a mount hook.  we want to poll for the transcript status when
  // the episode mounts and stop polling when it unmounts
  useEffect(() => {
    if (initialIsTranscribingRef.current) {
      dispatch(awaitEpisodeTranscription(episodeId));
    }

    return () => {
      dispatch(abortAwaitEpisodeTranscription(episodeId));
    };
  }, [dispatch, episodeId]);

  return (
    <>
      <EpisodeRowProgressOverlay
        delay={progressDelay}
        onEntered={onProgressAnimationEntered}
        onExited={onProgressAnimationExited}
        timeout={progressTimeout}
        transitionIn={progressTransitionIn}
      />
      <Tooltip
        className={block('tooltip')}
        content="Sit tight! We'll have your video ready soon."
        id="cms-episode-tooltip"
        placement="top"
        preventHideOnHover={false}
        show={videoPending && !isAnimating ? undefined : false}
      >
        <EpisodeListRow.Container collapsible>
          <List.Item.Artwork>
            <img
              src={episode?.thumbnailImageUrl}
              className={block('artwork')}
            />
          </List.Item.Artwork>
          <EpisodeListEpisodeDescription
            durationMillis={episode?.audioDurationMillis}
            publishedAtMillis={episode?.publishedAtMillis}
            title={episode?.title}
          />
          <EpisodeRowStatus
            // force rerender NEW button: fixes stucked animation classes on project deletion
            key={projects?.length === 0 ? 'empty' : 'non-empty'}
            hide={!showStatus}
            {...{ generating, active, canTranscribe, onCreateClick }}
          />
        </EpisodeListRow.Container>
      </Tooltip>
    </>
  );
};

export default EpisodeListRowHeadline;

import React, { useState } from 'react';
import { DestinationPreset } from 'blocks/DestinationPlatforms';
import CloseIconButton from 'components/CloseIconButton';
import Error from 'components/icons/Error';
import Popover from 'components/Popover';
import { formatDurationInFull } from 'utils/time';
import { block, isPresetBlocked, SOCIAL_PRESET_POPOVER_OPTIONS } from './utils';

type SocialPresetPopoverProps = {
  onClose: () => void;
  preset: DestinationPreset;
};

const EMPTY_PRESET = { durationMs: 0, icon: Error, type: '' };

const SocialPresetPopover: React.FunctionComponent<SocialPresetPopoverProps> = props => {
  const { children, onClose, preset } = props;

  const [triggerRef, setTriggerRef] = useState<HTMLSpanElement>();

  const getPresetConfig = React.useCallback(
    (nextPreset?: DestinationPreset) =>
      SOCIAL_PRESET_POPOVER_OPTIONS[nextPreset?.key] ?? EMPTY_PRESET,
    [],
  );

  const presetConfig = React.useRef(getPresetConfig(preset));

  const show = isPresetBlocked(preset);

  const [prevShow, setPrevShow] = React.useState(show);

  // As preset selection triggers the popup, when the preset is set to undefined or a non blocking one
  // the popover will hide. Given this, there would be a small blink while animating the popover fade.
  // For avoiding this the preset config is saved at a ref and only updated when show state switches
  // to visible
  if (show !== prevShow) {
    setPrevShow(show);
    if (show) {
      presetConfig.current = getPresetConfig(preset);
    }
  }

  const content = (
    <div className={block('preset-popover-body')}>
      <presetConfig.current.icon />
      <div className={block('preset-popover-mid-container')}>
        <h6 className={block('preset-popover-title')}>MAX. DURATION</h6>
        <p className={block('preset-popover-duration')}>
          {presetConfig.current.type} (
          {formatDurationInFull(presetConfig.current.durationMs)})
        </p>
      </div>
      <CloseIconButton
        className={block('preset-popover-preset-close')}
        onClick={onClose}
      />
    </div>
  );

  return (
    <>
      <div ref={setTriggerRef}>
        {children}
        <Popover
          arrow
          autoFocus={false}
          bordered
          contain={false}
          className={block('preset-popover')}
          flip={false}
          inline
          isDismissable={false}
          isOpen={show}
          offset={10}
          shape="chip"
          shouldCloseOnBlur={false}
          triggerElement={triggerRef}
        >
          {content}
        </Popover>
      </div>
    </>
  );
};

export default SocialPresetPopover;

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import LoadingMessage from 'components/LoadingMessage';
import { getFavoritePodcasts } from 'redux/modules/podcast-search/actions';
import { favoritePodcastIdsSelector } from 'redux/modules/podcast-search/selectors';
import bem from 'utils/bem';
import AutomationCMSRich from '../AutomationCMSRich';

export interface AutomationCMSProps {}

const block = bem('automation-cms');

const AutomationCMS: React.FC<AutomationCMSProps> = () => {
  const podcastIds = useSelector(favoritePodcastIdsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFavoritePodcasts());
  }, [dispatch]);

  if (!podcastIds) {
    return <LoadingMessage className={block('loading')} />;
  }

  return <AutomationCMSRich />;
};

export default AutomationCMS;

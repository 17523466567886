import { Spacer } from '@sparemin/blockhead';
import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { makePodcastTitleSelector } from 'redux/modules/podcast-search/selectors';
import { cmsBlock as block } from '../utils';
import RefreshFeedButton from './RefreshFeedButton';

export interface PodcastHeadingProps {
  podcastId: string;
  isDisabled?: boolean;
  showRefreshButton?: boolean;
}

const PodcastHeading: React.FC<PodcastHeadingProps> = ({
  podcastId,
  isDisabled = false,
  showRefreshButton = true,
}) => {
  const title = useSelector(
    useMemo(() => makePodcastTitleSelector(podcastId), [podcastId]),
  );

  return (
    <Spacer
      orientation="horizontal"
      align="center"
      justify="space-between"
      className={block('heading')}
    >
      <span className={block('title')}>{title}</span>

      {showRefreshButton && (
        <RefreshFeedButton {...{ podcastId, isDisabled }} />
      )}
    </Spacer>
  );
};

export default PodcastHeading;

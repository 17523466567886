import React from 'react';

import Tip from 'components/Tip/Tip';
import { imageViewBlock as block } from '../utils';

export interface ChildViewTipProps {
  children?: React.ReactNode;
}

const ChildViewTip: React.FC<ChildViewTipProps> = ({
  children = 'Double click media to toggle between fitting or filling canvas',
}) => <Tip className={block('tip')}>{children}</Tip>;

export default ChildViewTip;

import {
  createCreationClipSuggestion as createCreationClipSuggestionAction,
  createCreationClipSuggestionFeedback,
  getCreationClipSuggestion,
  getCreationClipSuggestions,
  reprocessCreationClipSuggestion,
} from 'redux/middleware/api/creation-service/actions';
import { actions as podcastService } from 'redux/middleware/api/podcast-service';
import { ThunkAction } from 'redux/types';
import { ClipSuggestionDislikeReason } from 'types/common';
import merge, { combineMerge } from 'utils/deepmerge';
import { reduxPoll } from 'utils/redux-poll';
import {
  creationClipSuggestionsByIdSelector,
  creationClipSuggestionsSelector,
  groupClipSuggestionsByIdSelector,
  groupClipSuggestionsSelector,
} from '../entities';
import { Type } from './action-types';
import {
  CLIP_FEEDBACK_REASON_TO_TYPE,
  CREATION_CLIP_SUGGESTION_POLL_ID,
  GROUP_CLIP_SUGGESTION_POLL_ID,
} from './constants';
import { subscriptionItemIdSelector } from './selectors';

export const loadGroupClipSuggestions = (
  episodeId: number,
  subscriptionItemId: number,
  groupRequestId?: number,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.LOAD_GROUP_CLIP_SUGGESTIONS_REQUEST,
  });

  try {
    await dispatch(
      podcastService.getGroupClipSuggestions(
        episodeId,
        subscriptionItemId,
        groupRequestId,
      ),
    );

    dispatch({
      type: Type.LOAD_GROUP_CLIP_SUGGESTIONS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: Type.LOAD_GROUP_CLIP_SUGGESTIONS_FAILURE,
      payload: { isBlocked: error.status === 403 },
    });
  }
};

export const loadSubscriptionItemInformation = (
  subscriptionItemId: number,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.LOAD_SUBSCRIPTION_ITEM_INFORMATION_REQUEST });

  try {
    await dispatch(
      podcastService.getSubscriptionItemInformation(subscriptionItemId),
    );

    dispatch({
      type: Type.LOAD_SUBSCRIPTION_ITEM_INFORMATION_SUCCESS,
    });
  } catch (error) {
    dispatch({ type: Type.LOAD_SUBSCRIPTION_ITEM_INFORMATION_FAILURE });
  }
};

export const dislikeGroupSuggestedClip = (
  reason: ClipSuggestionDislikeReason,
  suggestionId?: number,
  autoCreateEpisodeId?: number,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const subscriptionItemId = subscriptionItemIdSelector(getState());

  dispatch({
    type: Type.GROUP_CLIP_SUGGESTION_DISLIKE,
    payload: { suggestionId },
  });

  await dispatch(
    podcastService.createEpisodeClipSuggestionFeedback({
      suggestionId,
      subscriptionItemId,
      autoCreateEpisodeId,
      feedbackType: CLIP_FEEDBACK_REASON_TO_TYPE[reason],
    }),
  );
};

export const pollForGroupClipSuggestion = (
  autoCreateEpisodeId: number,
  replaceAutoCreateEpisodeId?: number,
  startMillis?: number,
  endMillis?: number,
  isReprocessing?: boolean,
): ThunkAction<void> => async (dispatch, getState) => {
  const currentSuggestions = groupClipSuggestionsSelector(getState())?.toJS();
  const { clipSuggestions: suggestionList = [] } = currentSuggestions ?? {};

  const suggestionIndex = suggestionList.findIndex(
    s =>
      s.autoCreateEpisodeId ===
      (replaceAutoCreateEpisodeId ?? autoCreateEpisodeId),
  );

  const newSuggestionIndex =
    suggestionIndex >= 0 ? suggestionIndex : suggestionList.length;

  const newClipSuggestions = Array(suggestionList.length).map(() => undefined);

  newClipSuggestions[newSuggestionIndex] = {
    autoCreateEpisodeId,
    startMillis,
    endMillis,
    isVideoOutdated: false,
    status: isReprocessing ? 'exportingVideo' : 'queued',
    text: '',
    videoUrl: null,
    widgetId: null,
  };

  if (
    (startMillis !== undefined && endMillis !== undefined) ||
    isReprocessing
  ) {
    dispatch({
      type: Type.GROUP_CLIP_SUGGESTION_POLLING_REQUEST,
      response: {
        entities: {
          groupClipSuggestions: {
            clipGroup: merge(
              currentSuggestions,
              {
                clipSuggestions: newClipSuggestions,
              },
              { arrayMerge: combineMerge },
            ),
          },
        },
      },
    });
  }

  await reduxPoll(
    dispatch,
    async () => {
      const { response } = await dispatch(
        podcastService.getGroupClipSuggestion(autoCreateEpisodeId),
      );

      const groupClipSuggestions = groupClipSuggestionsSelector(
        getState(),
      )?.toJS();
      const { clipSuggestions } = groupClipSuggestions;

      const { text } =
        groupClipSuggestionsByIdSelector(getState())[autoCreateEpisodeId] || {};

      if (response?.status === 'completed' || !text) {
        // Find the index of the existing suggestion with the same autoCreateEpisodeId.
        const index = clipSuggestions.findIndex(
          suggestion =>
            suggestion.autoCreateEpisodeId === response.autoCreateEpisodeId,
        );

        // Create a new array with the updated or new suggestion.
        const updatedSuggestions =
          index === -1
            ? [...clipSuggestions, response] // Add new suggestion.
            : clipSuggestions.map((suggestion, i) =>
                i === index ? response : suggestion,
              ); // Update existing suggestion.

        dispatch({
          type: Type.GROUP_CLIP_SUGGESTION_POLLING_SUCCESS,
          response: {
            entities: {
              groupClipSuggestions: {
                clipGroup: {
                  ...groupClipSuggestions,
                  clipSuggestions: updatedSuggestions,
                },
              },
            },
          },
        });
      }

      return response;
    },
    {
      id: `${GROUP_CLIP_SUGGESTION_POLL_ID}_${autoCreateEpisodeId}`,
      shouldContinue: (_, value) => {
        const groupClipSuggestions = groupClipSuggestionsSelector(
          getState(),
        )?.toJS();

        if (value?.status === 'error') {
          dispatch({
            type: Type.GROUP_CLIP_SUGGESTION_POLLING_FAILURE,
            response: {
              entities: {
                groupClipSuggestions: {
                  clipGroup: {
                    ...groupClipSuggestions,
                    clipSuggestions: groupClipSuggestions.clipSuggestions.filter(
                      suggestion =>
                        suggestion.autoCreateEpisodeId !== autoCreateEpisodeId,
                    ),
                  },
                },
              },
            },
          });

          return false;
        }

        if (value?.status === 'completed') {
          return false;
        }

        return true;
      },
      intervalMillis: 2500,
      maxAttempts: Infinity,
    },
  );
};

export const createGroupClipSuggestion = (
  episodeId: number,
  subItemId: number,
  groupRequestId: number,
  replaceAutoCreateEpisodeId: number,
  newTrimStartMillis: number,
  newTrimEndMillis: number,
): ThunkAction<Promise<number | void>> => async dispatch => {
  const { response } = await dispatch(
    podcastService.createGroupClipSuggestion(
      episodeId,
      subItemId,
      groupRequestId,
      replaceAutoCreateEpisodeId,
      newTrimStartMillis,
      newTrimEndMillis,
    ),
  );

  dispatch(
    pollForGroupClipSuggestion(
      response.autoCreateEpisodeId,
      replaceAutoCreateEpisodeId,
      newTrimStartMillis,
      newTrimEndMillis,
    ),
  );

  return response.autoCreateEpisodeId;
};

export const reprocessGroupClipSuggestion = (
  autoCreateEpisodeId: number,
  subItemId: number,
  groupRequestId: number,
  newTrimStartMillis: number,
  newTrimEndMillis: number,
): ThunkAction<Promise<void>> => async dispatch => {
  await dispatch(
    podcastService.reprocessAutoCreateEpisode({
      autoCreateEpisodeId,
      groupRequestId,
      subItemId,
      newTrimStartMillis,
      newTrimEndMillis,
    }),
  );

  dispatch(
    pollForGroupClipSuggestion(
      autoCreateEpisodeId,
      undefined,
      newTrimStartMillis,
      newTrimEndMillis,
      true,
    ),
  );
};

export const loadCreationClipSuggestions = (
  creationRequestId: number,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.LOAD_CREATION_CLIP_SUGGESTIONS_REQUEST,
  });

  try {
    const { response } = await dispatch(
      getCreationClipSuggestions(creationRequestId),
    );

    dispatch({
      type: Type.LOAD_CREATION_CLIP_SUGGESTIONS_SUCCESS,
      response: {
        entities: {
          creationClipSuggestions: response.entities.creationClipSuggestions,
        },
      },
    });
  } catch (error) {
    dispatch({
      type: Type.LOAD_CREATION_CLIP_SUGGESTIONS_FAILURE,
      payload: { isBlocked: error.status === 403 },
    });
  }
};

export const dislikeCreationSuggestedClip = (
  reason: ClipSuggestionDislikeReason,
  creationRequestId?: number,
  suggestionId?: number,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.CREATION_CLIP_SUGGESTION_DISLIKE,
    payload: { suggestionId },
  });

  await dispatch(
    createCreationClipSuggestionFeedback(
      CLIP_FEEDBACK_REASON_TO_TYPE[reason],
      creationRequestId,
      suggestionId,
    ),
  );
};

export const pollForCreationClipSuggestion = (
  creationRequestId: number,
  suggestionId: number,
  replaceSuggestionId?: number,
  startMillis?: number,
  endMillis?: number,
  isReprocessing?: boolean,
): ThunkAction<void> => async (dispatch, getState) => {
  const currentSuggestions = creationClipSuggestionsSelector(
    getState(),
  )?.toJS();

  const { suggestions: suggestionList = [] } = currentSuggestions ?? {};

  const suggestionIndex =
    suggestionList.findIndex(
      s => s.suggestionId === (replaceSuggestionId ?? suggestionId),
    ) ?? suggestionList.length;

  const newSuggestionIndex =
    suggestionIndex >= 0 ? suggestionIndex : suggestionList.length;

  const newSuggestions = Array(suggestionList.length).map(() => undefined);

  newSuggestions[newSuggestionIndex] = {
    endMillis,
    isVideoOutdated: false,
    startMillis,
    status: isReprocessing ? 'exportingVideo' : 'submitted',
    suggestionId,
    text: '',
    videoUrl: null,
    widgetId: null,
  };

  if (
    (startMillis !== undefined && endMillis !== undefined) ||
    isReprocessing
  ) {
    dispatch({
      type: Type.CREATION_CLIP_SUGGESTION_POLLING_REQUEST,
      response: {
        entities: {
          creationClipSuggestions: {
            clipGroup: merge(
              currentSuggestions,
              {
                suggestions: newSuggestions,
              },
              {
                arrayMerge: combineMerge,
              },
            ),
          },
        },
      },
    });
  }

  await reduxPoll(
    dispatch,
    async () => {
      const { response } = await dispatch(
        getCreationClipSuggestion(creationRequestId, suggestionId),
      );

      const creationClipSuggestions = creationClipSuggestionsSelector(
        getState(),
      )?.toJS();
      const { suggestions } = creationClipSuggestions;

      const { text } =
        creationClipSuggestionsByIdSelector(getState())[suggestionId] || {};

      if (response?.status === 'completed' || !text) {
        // Find the index of the existing suggestion with the same suggestionId.
        const index = suggestions.findIndex(
          suggestion => suggestion.suggestionId === response.suggestionId,
        );

        // Create a new array with the updated or new suggestion.
        const updatedSuggestions =
          index === -1
            ? [...suggestions, response] // Add new suggestion.
            : suggestions.map((suggestion, i) =>
                i === index ? response : suggestion,
              ); // Update existing suggestion.

        dispatch({
          type: Type.CREATION_CLIP_SUGGESTION_POLLING_SUCCESS,
          response: {
            entities: {
              creationClipSuggestions: {
                clipGroup: {
                  ...creationClipSuggestions,
                  suggestions: updatedSuggestions,
                },
              },
            },
          },
        });
      }

      return response;
    },
    {
      id: `${CREATION_CLIP_SUGGESTION_POLL_ID}_${suggestionId}`,
      shouldContinue: (_, value) => {
        const creationClipSuggestions = creationClipSuggestionsSelector(
          getState(),
        )?.toJS();

        if (value?.status === 'error') {
          dispatch({
            type: Type.CREATION_CLIP_SUGGESTION_POLLING_FAILURE,
            response: {
              entities: {
                creationClipSuggestions: {
                  clipGroup: {
                    ...creationClipSuggestions,
                    suggestions: creationClipSuggestions.suggestions.filter(
                      suggestion => suggestion.suggestionId !== suggestionId,
                    ),
                  },
                },
              },
            },
          });

          return false;
        }

        if (value?.status === 'completed') {
          return false;
        }

        return true;
      },
      intervalMillis: 2500,
      maxAttempts: Infinity,
    },
  );
};

export const createCreationClipSuggestion = (
  creationRequestId: number,
  replaceSuggestionId: number,
  newTrimStartMillis: number,
  newTrimEndMillis: number,
): ThunkAction<Promise<number | void>> => async dispatch => {
  const { response } = await dispatch(
    createCreationClipSuggestionAction(
      creationRequestId,
      replaceSuggestionId,
      newTrimStartMillis,
      newTrimEndMillis,
    ),
  );

  dispatch(
    pollForCreationClipSuggestion(
      creationRequestId,
      response.suggestionId,
      replaceSuggestionId,
      newTrimStartMillis,
      newTrimEndMillis,
    ),
  );

  return response.suggestionId;
};

export const reprocessCreationSuggestion = (
  creationRequestId: number,
  suggestionId: number,
  newTrimStartMillis: number,
  newTrimEndMillis: number,
): ThunkAction<Promise<void>> => async dispatch => {
  await dispatch(
    reprocessCreationClipSuggestion({
      creationRequestId,
      suggestionId,
      newTrimStartMillis,
      newTrimEndMillis,
    }),
  );

  dispatch(
    pollForCreationClipSuggestion(
      creationRequestId,
      suggestionId,
      undefined,
      newTrimStartMillis,
      newTrimEndMillis,
      true,
    ),
  );
};

export const reset = () => ({
  type: Type.RESET,
});

import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isUndefined } from 'underscore';

import {
  debouncedSaveConfiguration,
  updateTextOverlay,
} from 'redux/modules/embed/actions';
import { textOverlayByIdSelector } from 'redux/modules/embed/selectors';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import { MINIMUM_TEXT_DURATION_MILLIS } from 'utils/constants';
import TextAsset, { TextAssetProps } from '../components/TextAsset';
import { millisToPosition, positionToMillis } from '../utils';

interface OwnProps extends Pick<TextAssetProps, 'id'> {
  pxPerSec: number;
}

type StateProps = Pick<
  TextAssetProps,
  'minWidth' | 'position' | 'width' | 'text'
>;
type DispatchProps = Pick<
  TextAssetProps,
  'onDrag' | 'onDragStop' | 'onResize' | 'onResizeStop'
>;

const idSelector = (_, ownProps: OwnProps) => ownProps.id;

const pxPerSecSelector = (_, ownProps: OwnProps) => ownProps.pxPerSec;

const makeMapStateToProps = () => {
  const textOverlaySelector = createSelector(
    [textOverlayByIdSelector, idSelector],
    (textOverlayById, id) =>
      !textOverlayById || !id ? undefined : textOverlayById.get(id),
  );

  const textOverlayTimeSelector = createSelector(
    textOverlaySelector,
    textOverlay =>
      textOverlay && {
        endMillis: textOverlay.getIn(['time', 'endMillis']),
        startMillis: textOverlay.getIn(['time', 'startMillis']),
      },
  );

  const positionSelector = createSelector(
    [textOverlayTimeSelector, pxPerSecSelector],
    (time, pxPerSec) =>
      isUndefined(time) ? 0 : millisToPosition(time.startMillis, pxPerSec),
  );

  const widthSelector = createSelector(
    [textOverlayTimeSelector, pxPerSecSelector],
    (time, pxPerSec) =>
      isUndefined(time)
        ? 0
        : millisToPosition(time.endMillis - time.startMillis, pxPerSec),
  );

  const textSelector = createSelector(
    textOverlaySelector,
    textOverlay => textOverlay && textOverlay.get('text'),
  );

  return (state: State, ownProps: OwnProps): StateProps => ({
    minWidth: millisToPosition(MINIMUM_TEXT_DURATION_MILLIS, ownProps.pxPerSec),
    position: positionSelector(state, ownProps),
    text: textSelector(state, ownProps),
    width: widthSelector(state, ownProps),
  });
};

const makeMapDispatchToProps = () => (
  dispatch: Dispatch,
  ownProps: OwnProps,
): DispatchProps => ({
  onDrag: data =>
    dispatch(
      updateTextOverlay({
        id: ownProps.id,
        time: {
          endMillis: positionToMillis(data.x + data.width, ownProps.pxPerSec),
          startMillis: positionToMillis(data.x, ownProps.pxPerSec),
        },
      }),
    ),
  onDragStop: (_, moved) => {
    if (moved) {
      dispatch(debouncedSaveConfiguration());
    }
  },
  onResize: (width, _, pos) =>
    dispatch(
      updateTextOverlay({
        id: ownProps.id,
        time: {
          endMillis: positionToMillis(pos + width, ownProps.pxPerSec),
          startMillis: positionToMillis(pos, ownProps.pxPerSec),
        },
      }),
    ),
  onResizeStop: () => dispatch(debouncedSaveConfiguration()),
});

const component = connect(
  makeMapStateToProps,
  makeMapDispatchToProps,
)(TextAsset);

export type TextOverlayAssetProps = PropsOf<typeof component>;
export default component;

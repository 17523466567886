import cn from 'classnames';
import React from 'react';
import { headerBlock as block } from '../../utils';
import HeaderContainer from './HeaderContainer';
import HeaderPodcastInfo from './HeaderPodcastInfo';
import HeaderSelectClipButton from './HeaderSelectClipButton';

export type SelectClipHeaderProps = {
  className?: string;
};

const SelectClipHeader: React.FC<SelectClipHeaderProps> = ({ className }) => {
  return (
    <HeaderContainer className={cn(block('select'), className)}>
      <HeaderPodcastInfo />
      <HeaderSelectClipButton />
    </HeaderContainer>
  );
};

export default SelectClipHeader;

import { Button, Heading, HeadyGradient, Spacer } from '@sparemin/blockhead';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinkButton from 'components/LinkButton';
import LoadingOverlay from 'components/LoadingOverlay';
import useSocialPostCaptions from 'hooks/useSocialPostCaptions';
import { loggedInSelector } from 'redux/modules/auth';
import {
  isErrorSocialPostCaptionsSelector,
  isPollingSocialPostCaptionsSelector,
} from 'redux/modules/download';

import {
  onAiGenSocialCaptionsClearText,
  onAiGenSocialCaptionsUpgradePoint,
} from 'redux/modules/mixpanel';
import { replaceModal } from 'redux/modules/modal/actions';
import { isFreeSelector } from 'redux/modules/pricing';
import { Dispatch } from 'redux/types';
import RainbowFieldContainer from './RainbowFieldContainer';
import ShareFormGroup from './ShareFormGroup';
import ShareFormTextarea, { ShareFormTextareaProps } from './ShareFormTextarea';
import { block } from './utils';

interface CaptionInfo {
  captionMessage: string;
  captionActionButton: React.ReactElement;
}

export interface ShareAICaptionsFieldProps {
  allowAiDisclaimer?: boolean;
  inputProps: ShareFormTextareaProps;
  onUnlockAICaptions: () => void;
  onCancelUnlockAICaptions: () => void;
}

const ShareAICaptionsField: React.FC<ShareAICaptionsFieldProps> = ({
  allowAiDisclaimer,
  inputProps,
  onUnlockAICaptions,
  onCancelUnlockAICaptions,
}) => {
  const [showAiDisclaimer, setShowAiDisclaimer] = useState(
    allowAiDisclaimer && !!inputProps.value,
  );

  const isAuthenticated = useSelector(loggedInSelector);
  const isPollingSocialPostCaptions = useSelector(
    isPollingSocialPostCaptionsSelector,
  );

  const isSocialPostCaptionsError = useSelector(
    isErrorSocialPostCaptionsSelector,
  );
  const isFree = useSelector(isFreeSelector);

  const {
    hasSocialPostCaptions,
    canGenerateSocialPostCaptions,
    createSocialPostCaptions,
  } = useSocialPostCaptions();

  const dispatch = useDispatch<Dispatch>();

  const handleUpgrade = useCallback(async () => {
    dispatch(onAiGenSocialCaptionsUpgradePoint());

    onUnlockAICaptions();

    const shouldCancel = await dispatch(
      replaceModal({ name: 'AICaptionsUpsell' }),
    );

    if (shouldCancel) {
      onCancelUnlockAICaptions();
    }
  }, [dispatch, onCancelUnlockAICaptions, onUnlockAICaptions]);

  const handleClearText = useCallback(() => {
    dispatch(onAiGenSocialCaptionsClearText());
    inputProps.onChange('');
    setShowAiDisclaimer(false);
  }, [dispatch, inputProps]);

  const captionInfo: CaptionInfo | undefined = useMemo(() => {
    if (isFree) {
      return {
        captionMessage: 'Headliner can now write social copy for you!',
        captionActionButton: (
          <LinkButton
            theme="rainbow"
            className={block('action-button')}
            onClick={handleUpgrade}
          >
            Unlock this feature
          </LinkButton>
        ),
      };
    }
    if (hasSocialPostCaptions) {
      return {
        captionMessage: 'We wrote social copy for you, hope you like it!',
        captionActionButton: (
          <Button
            size="small"
            className={block('clear-button')}
            onPress={handleClearText}
          >
            Clear text
          </Button>
        ),
      };
    }
    if (canGenerateSocialPostCaptions && isSocialPostCaptionsError) {
      return {
        captionMessage:
          'Sorry, we couldn’t write unique social copy for this clip…',
        captionActionButton: (
          <LinkButton
            theme="rainbow"
            onClick={() => createSocialPostCaptions()}
            className={block('action-button')}
          >
            try again
          </LinkButton>
        ),
      };
    }
    if (canGenerateSocialPostCaptions) {
      return {
        captionMessage: 'Headliner can now write social copy for you!',
        captionActionButton: (
          <LinkButton
            theme="rainbow"
            onClick={() => createSocialPostCaptions()}
            className={block('action-button')}
          >
            generate copy
          </LinkButton>
        ),
      };
    }

    return undefined;
  }, [
    canGenerateSocialPostCaptions,
    createSocialPostCaptions,
    handleClearText,
    handleUpgrade,
    hasSocialPostCaptions,
    isFree,
    isSocialPostCaptionsError,
  ]);

  return (
    <ShareFormGroup>
      {isPollingSocialPostCaptions && <LoadingOverlay />}

      {showAiDisclaimer && isAuthenticated ? (
        <RainbowFieldContainer>
          {!!captionInfo && (
            <Spacer
              orientation="horizontal"
              align="center"
              justify="space-between"
            >
              <Spacer
                orientation="horizontal"
                space="6px"
                align="center"
                justify="space-between"
              >
                <HeadyGradient width="20px" height="20px" color="transparent" />

                <Heading level={4}>{captionInfo?.captionMessage}</Heading>
              </Spacer>

              {captionInfo?.captionActionButton}
            </Spacer>
          )}

          <ShareFormTextarea
            {...inputProps}
            className={block('ai-captions-field-input')}
          />
        </RainbowFieldContainer>
      ) : (
        <ShareFormTextarea
          {...inputProps}
          className={block('ai-captions-field-input')}
        />
      )}
    </ShareFormGroup>
  );
};

export default ShareAICaptionsField;

import { useToggle } from '@sparemin/blockhead';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DEFAULT_CLIP_START_MILLIS } from 'blocks/ClipSelect/constants';
import { ClipSuggestion } from 'blocks/ClipSelect/types';
import { AudioClipperComponent } from 'components/AudioClipper';
import { useClipSelectNavigation } from '../ClipSelectNavigationContext';
import { VideoPlayerRefs } from './types';
import useClipSelectVideoPlayer from './useClipSelectVideoPlayer';

type UsePlaybackProps = {
  activeSuggestion: ClipSuggestion | undefined;
  suggestionIds: number[] | undefined;
};

/**
 * Hook to manage playback of the various media assets in this component -
 * audio clipper, video clipper, and each suggested clip's video
 */
export default function usePlayback({
  activeSuggestion,
  suggestionIds,
}: UsePlaybackProps) {
  const [navState] = useClipSelectNavigation();

  const [isSuggestionPlaying, setIsSuggestionPlaying] = useState(false);

  const [suggestionVideoPlayerRefs, setSuggestionVideoPlayerRefs] = useState<
    VideoPlayerRefs
  >();

  // although we have access to the videoClipperPlayer, playback state for that
  // component is managed reactively via passing in the isVideoPlaying prop.
  // the video clipper ref is only used for seeking
  const {
    toggleOn: playVideoClipper,
    toggleOff: pauseVideoClipper,
    value: isVideoClipperPlaying,
  } = useToggle(false);

  const activeSuggestionVideoPlayerRef =
    suggestionVideoPlayerRefs?.[activeSuggestion?.id];

  const audioClipperRef = useRef<AudioClipperComponent>();

  const {
    play: playClip,
    pause: pauseClip,
    onSelectionChange,
  } = useClipSelectVideoPlayer(suggestionVideoPlayerRefs, activeSuggestion);

  const pauseClipper = useCallback(() => {
    audioClipperRef.current?.pause();
    pauseVideoClipper();
  }, [pauseVideoClipper]);

  const pauseAll = useCallback(() => {
    pauseClip();
    pauseClipper();
    setIsSuggestionPlaying(false);
  }, [pauseClip, pauseClipper]);

  const toggleClipPlayback = useCallback(() => {
    if (isSuggestionPlaying) {
      pauseClip();
      setIsSuggestionPlaying(false);
    } else {
      // business requirement that suggestions should always start playing from the beginning
      activeSuggestionVideoPlayerRef.current.currentTime = DEFAULT_CLIP_START_MILLIS;
      playClip();
      setIsSuggestionPlaying(true);
    }
  }, [
    isSuggestionPlaying,
    pauseClip,
    playClip,
    activeSuggestionVideoPlayerRef,
  ]);

  const addVideoPlayerRef = useCallback(
    (suggestionId: number) => {
      const videoPlayerRefsCopy = {
        ...suggestionVideoPlayerRefs,
      };

      videoPlayerRefsCopy[suggestionId] = React.createRef();

      setSuggestionVideoPlayerRefs(videoPlayerRefsCopy);
    },
    [suggestionVideoPlayerRefs],
  );

  useEffect(() => {
    if (!navState.matches('select')) {
      pauseClip();
      setIsSuggestionPlaying(false);
    }

    if (!navState.matches('edit')) {
      pauseClipper();
    }
  }, [navState, pauseClip, pauseClipper]);

  if (!!suggestionIds.length && !suggestionVideoPlayerRefs) {
    setSuggestionVideoPlayerRefs(
      suggestionIds.reduce((res, id) => {
        res[id] = React.createRef();

        return res;
      }, {}),
    );
  }

  return {
    addVideoPlayerRef,
    audioClipperRef,
    isSuggestionPlaying,
    onChangeClip: onSelectionChange,
    pauseAll,
    suggestionVideoPlayerRefs,
    toggleClipPlayback,
    videoClipperPlayerControls: {
      playing: isVideoClipperPlaying,
      onPlay: playVideoClipper,
      onPause: pauseVideoClipper,
    },
  };
}

import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import AlignCenterToggle from './AlignCenterToggle';
import AlignLeftToggle from './AlignLeftToggle';
import AlignRightToggle from './AlignRightToggle';
import BoldToggle from './BoldToggle';
import CaseToggle from './CaseToggle';
import EmojiPicker from './EmojiPicker';
import EmojiPickerV2 from './EmojiPickerV2';
import FontColorPicker from './FontColorPicker';
import FontFamilySelectorV2 from './FontFamilySelectorV2';
import FontSizeInput from './FontSizeInput';
import FontSizeInputV2 from './FontSizeInputV2';
import FontFamilySelector from './FontsSelector/FontFamilySelector';
import ItalicsToggle from './ItalicsToggle';
import OutlineInput from './OutlineInput';
import TextAlignSelector from './TextAlignSelector';
import TextAlignSelectorV2 from './TextAlignSelectorV2';
import TextHighlightPicker from './TextHighlightPicker';
import TextHighlightPickerV2 from './TextHighlightPickerV2';
import TextShadowInput from './TextShadowInput';
import TextShadowInputV2 from './TextShadowInputV2';
import ToolGroup from './ToolGroup';
import UnderlineToggle from './UnderlineToggle';

import { block } from './utils';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  domRef?: (el: HTMLDivElement) => void;
}

export default class TextToolbar extends React.Component<IProps> {
  public static AlignCenterToggle = AlignCenterToggle;
  public static AlignLeftToggle = AlignLeftToggle;
  public static AlignRightToggle = AlignRightToggle;
  public static BoldToggle = BoldToggle;
  public static EmojiPicker = EmojiPicker;
  public static EmojiPickerV2 = EmojiPickerV2;
  public static FontColorPicker = FontColorPicker;
  public static FontFamilySelector = FontFamilySelector;
  public static FontFamilySelectorV2 = FontFamilySelectorV2;
  public static FontSizeInput = FontSizeInput;
  public static FontSizeInputV2 = FontSizeInputV2;
  public static ItalicsToggle = ItalicsToggle;
  public static TextAlignSelector = TextAlignSelector;
  public static TextAlignSelectorV2 = TextAlignSelectorV2;
  public static TextHighlightPicker = TextHighlightPicker;
  public static TextHighlightPickerV2 = TextHighlightPickerV2;
  public static TextShadowInput = TextShadowInput;
  public static TextShadowInputV2 = TextShadowInputV2;
  public static OutlineInput = OutlineInput;
  public static ToolGroup = ToolGroup;
  public static UnderlineToggle = UnderlineToggle;
  public static CaseToggle = CaseToggle;

  public static defaultProps: Partial<IProps> = {
    domRef: noop,
  };

  public render() {
    const { children, className, domRef } = this.props;

    return (
      <div className={cn(block(), className)} ref={domRef}>
        {children}
      </div>
    );
  }
}

export { IProps as TextToolbarProps };

import { fromJS } from 'immutable';
import React from 'react';

import { DEFAULT_DISABLED_ADVANCED_ANIMATION_CONFIGS } from 'utils/embed/captions/constants';

import { EditorDataV2, Size, TextOverlayV2 } from '../../types';
import animationModules from './animations';
import { AnimationsTab, AnimationsTabConfig } from './types';

interface UseAnimationsTabConfig {
  canvasSize: Size;
  draftEditorData: EditorDataV2;
  extensionConfig: AnimationsTabConfig;
}

type UseAnimationsTab = AnimationsTab;

const DEFAULT_ANIMATIONS = [];

const useAnimationsTab = (config: UseAnimationsTabConfig): UseAnimationsTab => {
  const { extensionConfig = {} } = config;
  const {
    animations = DEFAULT_ANIMATIONS,
    enabled = false,
    displayConfig = {
      showTabDisabled: false,
    },
  } = extensionConfig;

  const targetAnimationModules = React.useMemo(() => {
    return animations.map(animation => animationModules[animation]);
  }, [animations]);

  const onSubmitAnimations = React.useCallback(
    (textOverlay: TextOverlayV2): TextOverlayV2 => {
      if (enabled || !displayConfig?.showTabDisabled) {
        return textOverlay;
      }

      // Resets the animations style if the animations tab extension is not
      // enabled.
      return textOverlay.withMutations(s =>
        s
          .set(
            'advancedAnimation',
            fromJS(DEFAULT_DISABLED_ADVANCED_ANIMATION_CONFIGS),
          )
          .set('animation', undefined),
      );
    },
    [displayConfig, enabled],
  );

  return {
    enabled,
    displayConfig,
    animationModules: targetAnimationModules,
    onSubmitAnimations,
  };
};

export default useAnimationsTab;

import * as React from 'react';
import { MEDIA_USE_TERMS_URL } from 'utils/constants';

interface IProps {
  children?: React.ReactNode;
  className?: string;
}

const MediaSearchTermsLink: React.SFC<IProps> = ({ children, className }) => (
  <a
    className={className}
    href={MEDIA_USE_TERMS_URL}
    rel="noopener noreferrer"
    target="_blank"
  >
    {children}
  </a>
);

MediaSearchTermsLink.defaultProps = {
  children: 'Media Use Terms',
};

export default MediaSearchTermsLink;

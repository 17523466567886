import { Spacer, Switch } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';
import { AssetType } from 'redux/middleware/api/podcast-service';
import AssetTitle from './AssetTitle';
import AssetTypeSelector from './AssetTypeSelector';
import { AssetValues } from './AssetTypeSelector/types';
import CustomIdInput from './CustomIdInput';
import { block } from './utils';

export type YoutubePartnerProgramSettingsProps = {
  onAssetTypeChange: (assetType: AssetValues) => void;
  onMonetizationChange?: (val: boolean) => void;
  onCustomIdChange?: (val: string) => void;
  assetType: AssetType | undefined;
  customId: string | undefined;
};

const YoutubePartnerProgramSettings: React.FC<YoutubePartnerProgramSettingsProps> = ({
  onAssetTypeChange,
  onMonetizationChange,
  onCustomIdChange,
  assetType,
  customId,
}) => {
  const [isMonetizationEnabled, setIsMonetizationEnabled] = useState(
    !!customId,
  );

  const handleMonetizationChange = useCallback(() => {
    setIsMonetizationEnabled(!isMonetizationEnabled);
    onMonetizationChange?.(!isMonetizationEnabled);
  }, [isMonetizationEnabled, onMonetizationChange]);

  return (
    <Spacer orientation="vertical" className={block()} space={2}>
      <Switch
        labelPosition="before"
        isSelected={isMonetizationEnabled}
        onChange={handleMonetizationChange}
        className={block('toggle')}
      >
        YouTube Partner Program Features
      </Switch>
      <AssetTitle disabled={!isMonetizationEnabled} />
      <AssetTypeSelector
        onChange={onAssetTypeChange}
        value={assetType}
        disabled={!isMonetizationEnabled}
      />
      <CustomIdInput
        customId={customId}
        disabled={!isMonetizationEnabled}
        onChange={onCustomIdChange}
      />
    </Spacer>
  );
};
export default YoutubePartnerProgramSettings;

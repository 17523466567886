import cn from 'classnames';
import React from 'react';
import bem from 'utils/bem';
import IconButton, { IconButtonProps } from './IconButton';

interface Props extends IconButtonProps {
  checked?: boolean;
  disabled?: boolean;
  className?: string;
}

const block = bem('toggle-icon-button');

const ToggleIconButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ className, checked, disabled, children, ...rest }, ref) => (
    <IconButton
      {...rest}
      className={cn(className, block({ checked }))}
      disabled={disabled}
      aria-pressed={checked}
      ref={ref}
    >
      {children}
    </IconButton>
  ),
);

export default ToggleIconButton;

import * as React from 'react';
import CreateTabCardContents from './CreateTabCardContents';

import CreateTabCardHeader from './CreateTabCardHeader';
import { block } from './utils';

export interface CreateTabCardProps {
  children?: React.ReactNode;
}

interface CreateTabCardComponent extends React.FC<CreateTabCardProps> {
  Contents: typeof CreateTabCardContents;
  Header: typeof CreateTabCardHeader;
}

const CreateTabCard: CreateTabCardComponent = ({ children, ...rest }) => (
  <section className={block()} {...rest}>
    {children}
  </section>
);

CreateTabCard.Header = CreateTabCardHeader;
CreateTabCard.Contents = CreateTabCardContents;

export default CreateTabCard;

import cn from 'classnames';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import ResizeDetector from 'react-resize-detector';
import { debounce, isFinite, isUndefined, noop } from 'underscore';

import Draggable from 'components/Draggable';
import Needle from 'components/Needle';
import { VideoEditorPlaybackTimeContext as VideoEditorPlaybackTimeContextType } from 'containers/VideoEditor/types';
import VideoEditorPlaybackTimeContext from 'containers/VideoEditor/VideoEditorPlaybackTimeContext';
import { getValue } from 'utils/collections';
import { MAX_VIDEO_EXPORT_DURATION_SECONDS } from 'utils/constants';
import { max, min, round } from 'utils/numbers';
import { prefix } from 'utils/ui';
import MinimapWindow from './MinimapWindow';

export interface MinimapProps {
  className?: string;

  /**
   * total duration in seconds of the audio which the minimap represents
   */
  durationSeconds: number;

  needleClassName?: string;

  /**
   * regions in the minimap which should be highlighted
   */
  regions?: Array<{
    /**
     * unique id for a region
     */
    id: any;

    /**
     * start time for the region
     */
    startMillis: number;

    /**
     * end time for the region
     */
    endMillis: number;

    /**
     * background property used to shade the region.  this should just be normal css property
     */
    background: string;
  }>;

  /**
   * called when user drags the viewport
   * arguments:
   *    - viewport start and end times in the form { startSec: nuber, endSec: number }
   */
  onViewportWindowDrag?: (startSec: number, endSec: number) => void;

  /**
   * called when the user clicks on the minimap
   * arguments:
   *    - the position seeked to, in seconds
   */
  onSeek?: (seconds: number) => void;

  /**
   * start and end position of the viewport, in seconds.  when passed, will set the viewport
   * to the given position with the given size
   */
  viewport?: {
    startSec: number;
    endSec: number;
  };

  viewportClassName?: string;

  /**
   * provided by WithWindowResize
   */
  windowSize?: {
    height?: number;
    width?: number;
  };
}

interface IViewportState {
  dragging: boolean;
  offsetX: number;
  widthPx: number;
}

interface INeedleState {
  dragging: boolean;
  offsetX: number;
}

interface IState {
  viewport: RecordOf<IViewportState>;
  needle: RecordOf<INeedleState>;
}

const viewportFactory = Record<IViewportState>({
  dragging: false,
  offsetX: 0,
  widthPx: 0,
});

const needleFactory = Record<INeedleState>({
  dragging: false,
  offsetX: 0,
});

/**
 * Timeline Minimap
 */
// TODO rename TimelineMinimap
export default class Minimap extends React.Component<MinimapProps, IState> {
  private previousContext: VideoEditorPlaybackTimeContextType;

  public static defaultProps: Partial<MinimapProps> = {
    onSeek: noop,
    onViewportWindowDrag: noop,
  };

  private container: HTMLDivElement;
  private pxPerSec: number;
  private width: number;

  public state: Readonly<IState> = {
    needle: needleFactory(),
    viewport: viewportFactory(),
  };

  public componentDidMount() {
    this.previousContext = this.context;
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps,
    context: VideoEditorPlaybackTimeContextType,
  ) {
    const {
      durationSeconds: nextDurationSeconds,
      viewport: nextViewportProps,
      windowSize: nextWindowSize,
    } = nextProps;
    const { durationSeconds, windowSize } = this.props;
    const { needle, viewport: viewportState } = this.state;
    const pos = this.previousContext.positionSec;
    const nextPos = context.positionSec;

    const roundedNextPos = nextPos && round(nextPos, -3);
    const roundedPos = pos && round(pos, -3);

    /*
     * only update needle position if the next position is different from our current one and the
     * user isn't dragging.  if the user is dragging, then each drag will make a roundtrip back
     * through the component tree to this function, creating an infinite loop
     */
    if (roundedNextPos !== roundedPos && needle.get('dragging') === false) {
      this.setState(({ needle: needleState }) => ({
        needle: needleState.set('offsetX', this.secToPx(nextPos)),
      }));
    }

    const windowResize =
      getValue(nextWindowSize, 'width') !== getValue(windowSize, 'width');

    // NB: the reason for "else if" is because pxPerSec gets set in calculateLayoutMeasurements
    if (windowResize) {
      this.calculateLayoutMeasurements(nextProps);
    } else if (nextDurationSeconds !== durationSeconds) {
      this.setPxPerSec(nextDurationSeconds);
    }

    // only update the viewport if it's not being dragged (see comment above)
    if (nextViewportProps && viewportState.get('dragging') === false) {
      const { startSec, endSec } = nextViewportProps;
      const startPx = this.secToPx(max(0, min(nextDurationSeconds, startSec)));
      const endPx = this.secToPx(min(nextDurationSeconds, endSec));
      const widthPx = endPx - startPx;
      this.setState(({ viewport }) => ({
        viewport: viewport.withMutations(v =>
          v.set('offsetX', startPx).set('widthPx', widthPx),
        ),
      }));
    }
  }

  public componentDidUpdate(_, prevState) {
    const { onViewportWindowDrag, onSeek } = this.props;
    const { needle: prevNeedle, viewport: prevViewport } = prevState;
    const { needle, viewport } = this.state;

    // only call viewport drag callback if we're actually dragging and the position has changed
    if (
      viewport.get('dragging') === true &&
      viewport.get('offsetX') !== prevViewport.get('offsetX')
    ) {
      const { startSec, endSec } = this.getViewportSec(
        viewport.get('offsetX'),
        viewport.get('widthPx'),
      );
      onViewportWindowDrag(startSec, endSec);
    }

    // similarly, only call seek callback if we're dragging and position has changed
    if (
      needle.get('dragging') === true &&
      needle.get('offsetX') !== prevNeedle.get('offsetX')
    ) {
      onSeek(this.pxToSec(needle.get('offsetX')));
    }

    this.previousContext = this.context;
  }

  private onNeedleDragStart = () =>
    this.setState(({ needle }) => ({
      needle: needle.set('dragging', true),
    }));

  private onNeedleDragStop = () =>
    this.setState(({ needle }) => ({
      needle: needle.set('dragging', false),
    }));

  private onNeedleDrag = (_, dragData) => {
    const position = this.pxToSec(dragData.x);
    if (!isFinite(position)) return;

    this.setState(({ needle }) => ({
      needle: needle.set('offsetX', dragData.x),
    }));
  };

  private onClick = onSeek => event => {
    const { durationSeconds } = this.props;
    const mouseX = event.clientX;
    const minimapMouseX = mouseX - this.container.getBoundingClientRect().left;
    const seekToSec = this.pxToSec(minimapMouseX);
    if (seekToSec > durationSeconds) return;

    this.setState(({ needle, viewport }) => {
      const viewportMinimapX = max(
        minimapMouseX - viewport.get('widthPx') / 2,
        0,
      );
      return {
        needle: needle.set('offsetX', minimapMouseX),
        viewport: viewport.set('offsetX', viewportMinimapX),
      };
    });

    /*
     * the onSeek called in componentDidUpdate is called for dragging.  this callback will handle
     * single clicks which don't lead to a drag but are still considered seeks
     */
    onSeek(seekToSec);
  };

  private handleViewportWindowDragStart = () =>
    this.setState(({ viewport }) => ({
      viewport: viewport.set('dragging', true),
    }));

  private handleViewportWindowDragStop = () =>
    this.setState(({ viewport }) => ({
      viewport: viewport.set('dragging', false),
    }));

  private handleViewportWindowDrag = (_, dragData) => {
    this.setState(({ viewport }) => {
      const offsetSec = this.pxToSec(dragData.x);
      const widthSec = this.pxToSec(viewport.get('widthPx'));
      const leftEdgeSec = offsetSec + widthSec;

      /*
       * pxToSec will return Infinity and NaN due to potentially dividing by 0 when the timeline is
       * empty and has no duration.  check that the math results in something finite, otherwise
       * don't let the user seek to that location
       */
      if (!isFinite(leftEdgeSec)) return undefined;

      return {
        viewport: viewport.set('offsetX', dragData.x),
      };
    });
  };

  private handleViewportWindowClick = e => {
    e.stopPropagation();
  };

  private handleResize = () => this.calculateLayoutMeasurements(this.props);

  private getViewportSec(offsetX: number, widthPx: number) {
    const startSec = offsetX / this.pxPerSec;
    const endSec = (offsetX + widthPx) / this.pxPerSec;
    return {
      endSec: round(endSec, -3),
      startSec: round(startSec, -3),
    };
  }

  private setPxPerSec(durationSeconds: number) {
    const { durationSeconds: durationSecondsProp } = this.props;

    const sec = !isUndefined(durationSeconds)
      ? durationSeconds
      : durationSecondsProp;
    this.pxPerSec = sec === 0 ? 0 : this.width / sec;
  }

  /**
   * this is relatively expensive becuase the layout calculations cause a reflow.  debouncing for
   * performance. We debounce on the leading edge so that we always set the width, which is used
   * for other operations.
   */
  private calculateLayoutMeasurements = debounce(
    props => {
      const { durationSeconds, startSec, endSec } = props || this.props;
      const position = this.context.positionSec;

      if (!this.container) return;

      this.width = this.container.getBoundingClientRect().width;
      this.setPxPerSec(durationSeconds);
      this.setState(({ needle, viewport }) => ({
        needle: needle.set('offsetX', position * this.pxPerSec),
        viewport: viewport.withMutations(v =>
          v
            .update('offsetX', x =>
              isUndefined(startSec) ? x : this.secToPx(startSec),
            )
            .update('widthPx', w =>
              isUndefined(startSec) || isUndefined(endSec)
                ? w
                : this.secToPx(endSec - startSec),
            ),
        ),
      }));
    },
    250,
    true,
  );

  private millisToPx(millis: number) {
    return this.secToPx(millis / 1000);
  }

  private secToPx(sec: number) {
    if (isUndefined(this.pxPerSec)) return 0;
    return sec * this.pxPerSec;
  }

  private pxToSec(px: number) {
    return px / this.pxPerSec;
  }

  private renderRegions(regions: MinimapProps['regions']) {
    if (!regions) return null;

    return regions.map((region, index) => {
      const id = `region-${region.id}`;

      const offsetX = this.millisToPx(region.startMillis);

      const style = prefix({
        background: region.background,
        display: 'inline-block',
        height: '100%',
        position: 'absolute',
        top: 0,
        transform: `translate(${round(offsetX)}px, 0px)`,
        width: round(this.millisToPx(region.endMillis - region.startMillis)),
        zIndex: index + 1,
      });

      return (
        <div
          key={region.id}
          id={id}
          data-id={region.id}
          className="tl-minimap__region"
          style={style}
        />
      );
    });
  }

  public render() {
    const {
      regions,
      onSeek,
      className,
      needleClassName,
      viewportClassName,
    } = this.props;
    const { needle, viewport } = this.state;

    const needlePos = !isUndefined(needle.get('offsetX'))
      ? { x: needle.get('offsetX'), y: 0 }
      : undefined;

    const containerClassName = cn({
      'tl-minimap': true,
      [className]: !!className,
    });

    const needleClass = cn('grabbable', 'tl-minimap__needle', needleClassName);

    const containerRef = el => {
      this.container = el;
    };

    const warningPosition = this.secToPx(MAX_VIDEO_EXPORT_DURATION_SECONDS);

    return (
      <div
        className={containerClassName}
        ref={containerRef}
        onClick={this.onClick(onSeek)}
      >
        <ResizeDetector handleWidth onResize={this.handleResize} />
        <div className="tl-minimap__inner tl-minimap__inner--std">
          <Draggable
            bounds={{ left: 0, right: this.width }}
            handle="#minimap-needle"
            position={needlePos}
            onStart={this.onNeedleDragStart}
            onStop={this.onNeedleDragStop}
            onDrag={this.onNeedleDrag}
          >
            <Needle
              id="minimap-needle"
              className={needleClass}
              headSize="small"
              width={2}
            />
          </Draggable>
          <MinimapWindow
            className={viewportClassName}
            dragging={viewport.get('dragging')}
            onClick={this.handleViewportWindowClick}
            onStart={this.handleViewportWindowDragStart}
            onStop={this.handleViewportWindowDragStop}
            onDrag={this.handleViewportWindowDrag}
            minimapWidth={this.width}
            position={viewport.get('offsetX')}
            width={viewport.get('widthPx')}
          />
          {this.renderRegions(regions)}
          {warningPosition > 0 && (
            <div
              className="tl-minimap__warning"
              style={{
                marginLeft: warningPosition,
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

Minimap.contextType = VideoEditorPlaybackTimeContext;

import { createSelector } from 'reselect';
import { isEmpty } from 'underscore';
import { ClipSuggestion } from 'blocks/ClipSelect/types';
import { CreationResponse } from 'redux/middleware/api/creation-service/types';
import {
  creationClipSuggestionsSelector,
  creationsSelector,
} from 'redux/modules/entities';

const creationIdSelector = (_, creationId: number) => creationId;

export const suggestedClipsSelector = createSelector(
  creationClipSuggestionsSelector,
  (suggestions): ClipSuggestion[] =>
    suggestions?.get('suggestions')?.reduce((acc, s) => {
      if (s.get('status') !== 'error') {
        const {
          endMillis,
          isUserSelected,
          isVideoOutdated,
          startMillis,
          suggestionId,
          text,
          transcriptSegments,
          status,
          videoUrl,
          widgetId,
          projectUuid,
        } = s.toJS();

        acc.push({
          id: suggestionId,
          isVideoOutdated,
          isUserSelected,
          endMillis,
          startMillis,
          status,
          text: isEmpty(transcriptSegments) ? text : transcriptSegments,
          videoUrl,
          widgetId,
          projectUuid,
        });
      }
      return acc;
    }, []) ?? [],
);

export const creationSelector = createSelector(
  creationIdSelector,
  creationsSelector,
  (creationId, creations) =>
    creations?.get(String(creationId))?.toJS() as CreationResponse,
);

export const hasCreationsSelector = createSelector(
  creationsSelector,
  creations => creations !== undefined,
);

import { Card, Heading, Spacer, VideoSingleStar } from '@sparemin/blockhead';
import React from 'react';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { OnIsAutoFrameChange } from '../types';
import { block } from './utils';
import VideoPodcastSettingsBlurToggle from './VideoPodcastSettingsBlurToggle';
import VideoPodcastSettingsFramingMethod from './VideoPodcastSettingsFramingMethod';
import VideoPodcastSettingsHeader from './VideoPodcastSettingsHeader';

export interface VideoPodcastSettingsProps {
  onIsAutoFrameChange?: OnIsAutoFrameChange;
}

const VideoPodcastSettings: React.FC<VideoPodcastSettingsProps> = ({
  onIsAutoFrameChange,
}) => {
  const { aspectRatio } = useTemplateState();

  if (getAspectRatioName(aspectRatio) === 'landscape') {
    return (
      <Card
        className={block('dynamic-video-disclaimer-card')}
        header={<></>}
        footer={<></>}
      >
        <VideoSingleStar
          width="76px"
          height="40px"
          className={block('disclaimer-icon')}
        />
        <Heading level={2}>
          This is a preview of your video podcast content.
        </Heading>
      </Card>
    );
  }

  return (
    <Card className={block()} header={<></>} footer={<></>}>
      <Spacer
        orientation="vertical"
        space="16px"
        align="center"
        justify="center"
      >
        <VideoPodcastSettingsHeader />

        <VideoPodcastSettingsFramingMethod
          onIsAutoFrameChange={onIsAutoFrameChange}
        />

        <VideoPodcastSettingsBlurToggle />
      </Spacer>
    </Card>
  );
};

export default VideoPodcastSettings;

import { Ban } from '@sparemin/blockhead';
import React from 'react';

import { AnimationModule } from '../types';
import useTextOverlayAdvancedAnimation from '../useTextOverlayAdvancedAnimation';
import AnimationModuleButton from './AnimationModuleButton';

export interface NoAnimationModuleProps {}

const NoAnimation: React.FC<NoAnimationModuleProps> = () => {
  const {
    selectedAdvancedAnimation,
    onTurnAnimationsOff,
  } = useTextOverlayAdvancedAnimation();

  return (
    <AnimationModuleButton
      label="None"
      icon={<Ban width={50} height={50} />}
      isSelected={!selectedAdvancedAnimation?.enabled}
      onPress={onTurnAnimationsOff}
    />
  );
};

const noAnimationModule: AnimationModule = {
  key: 'none',
  name: 'None',
  control: NoAnimation,
};

export default noAnimationModule;

import { useCallback, useEffect, useState } from 'react';
import { DISCO_SDK_URL, DISCO_WIDGET_ID } from 'config';

const SCRIPT_ID = 'disco-script';

interface UseDiscoConfig {
  defaultInitializeOnLoad?: boolean;
  injectionPoint: HTMLElement | string;
  displayVariant?: string;
}

interface UseDiscoResult {
  initializeDisco: () => void;
}

export default function useDisco({
  defaultInitializeOnLoad = true,
  injectionPoint,
  displayVariant,
}: UseDiscoConfig): UseDiscoResult {
  const [initializeOnLoad, setInitializeOnLoad] = useState(
    defaultInitializeOnLoad,
  );

  const initializeDisco = useCallback(() => {
    if (window.disco) {
      window.disco.render(injectionPoint, {
        displayVariant,
        title: undefined,
        url: undefined,
        widgetId: DISCO_WIDGET_ID,
      });
    } else {
      setInitializeOnLoad(true);
    }
  }, [injectionPoint, displayVariant]);

  useEffect(() => {
    let currentScript = document.getElementById(SCRIPT_ID) as HTMLScriptElement;

    const handleScriptLoad = () => {
      if (initializeOnLoad) {
        initializeDisco();
      }
    };

    if (!currentScript) {
      currentScript = document.createElement('script');
      currentScript.id = SCRIPT_ID;
      currentScript.defer = true;
      currentScript.addEventListener('load', handleScriptLoad);
      currentScript.src = DISCO_SDK_URL;
      document.head.appendChild(currentScript);
    } else if (!window.disco) {
      currentScript.addEventListener('load', handleScriptLoad);
    } else {
      initializeDisco();
    }

    return () => {
      if (currentScript) {
        currentScript.removeEventListener('load', handleScriptLoad);
      }
    };
  }, [initializeDisco, initializeOnLoad]);

  return { initializeDisco };
}

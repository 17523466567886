import { Record, RecordOf } from 'immutable';

import { TikTokCreatorInfo } from 'redux/middleware/api/third-party-authentication-service/types';
import { Action } from 'redux/types';
import { PostStatus } from '../constants';
import { Type } from './action-types';

export interface TiktokPostMessageData {
  tokenInfo: {
    accessToken: string;
    idToken: string;
    provider: 'tiktok';
    providerUserId: string;
  };
}

export type TiktokAuthData = Pick<
  TiktokPostMessageData['tokenInfo'],
  'accessToken' | 'idToken' | 'providerUserId'
>;

interface State extends TiktokAuthData {
  accessToken: string;
  postStatus: PostStatus;
  profileImageUrl: string;
  socialShareId: number;
  username: string;
  creatorInfo: TikTokCreatorInfo;
}

export type TiktokState = RecordOf<State>;

export const tiktokStateFactory = Record<State>({
  accessToken: undefined,
  idToken: undefined,
  postStatus: undefined,
  profileImageUrl: undefined,
  providerUserId: undefined,
  socialShareId: undefined,
  username: undefined,
  creatorInfo: undefined,
});

type GetTikTokUserRequestAction = Action<Type.TIKTOK_USER_DATA_GET_REQUEST>;
type GetTikTokUserSuccessAction = Action<
  Type.TIKTOK_USER_DATA_GET_SUCCESS,
  {
    username: string;
    profileImageUrl: string;
  }
>;
type GetTikTokUserFailureAction = Action<Type.TIKTOK_USER_DATA_GET_FAILURE>;

type ClearTiktokUserAction = Action<Type.TIKTOK_USER_DATA_CLEAR>;

type PostVideoRequestAction = Action<Type.TIKTOK_VIDEO_POST_REQUEST>;
type PostVideoFailureAction = Action<Type.TIKTOK_VIDEO_POST_FAILURE>;
type PostVideoSuccessAction = Action<
  Type.TIKTOK_VIDEO_POST_SUCCESS,
  {
    socialShareId: number;
  }
>;

type AwaitGetUserRequestAction = Action<
  Type.TIKTOK_USER_DATA_GET_AWAIT_REQUEST
>;
type AwaitGetUserSuccessAction = Action<
  Type.TIKTOK_USER_DATA_GET_AWAIT_SUCCESS
>;
type AwaitGetUserFailureAction = Action<
  Type.TIKTOK_USER_DATA_GET_AWAIT_FAILURE
>;

type AwaitPostRequestAction = Action<Type.TIKTOK_VIDEO_POST_AWAIT_REQUEST>;
type AwaitPostSuccessAction = Action<Type.TIKTOK_VIDEO_POST_AWAIT_SUCCESS>;
type AwaitPostFailureAction = Action<Type.TIKTOK_VIDEO_POST_AWAIT_FAILURE>;

type GetTikTokCreatorInfoRequestAction = Action<
  Type.TIKTOK_CREATOR_INFO_REQUEST
>;
type GetTikTokCreatorInfoSuccessAction = Action<
  Type.TIKTOK_CREATOR_INFO_SUCCESS,
  TikTokCreatorInfo
>;
type GetTikTokCreatorInfoFailureAction = Action<
  Type.TIKTOK_CREATOR_INFO_FAILURE
>;

export type TiktokAuthorizeSuccessAction = Action<
  Type.TIKTOK_AUTHORIZE_SUCCESS,
  TiktokAuthData
>;

export type TiktokAction =
  | GetTikTokUserRequestAction
  | GetTikTokUserSuccessAction
  | GetTikTokUserFailureAction
  | ClearTiktokUserAction
  | PostVideoRequestAction
  | PostVideoSuccessAction
  | PostVideoFailureAction
  | TiktokAuthorizeSuccessAction
  | AwaitPostRequestAction
  | AwaitPostSuccessAction
  | AwaitPostFailureAction
  | AwaitGetUserRequestAction
  | AwaitGetUserSuccessAction
  | AwaitGetUserFailureAction
  | GetTikTokCreatorInfoRequestAction
  | GetTikTokCreatorInfoSuccessAction
  | GetTikTokCreatorInfoFailureAction;

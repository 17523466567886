import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { spring } from 'react-flip-toolkit';
import Slider from 'react-slick';
import { delay } from 'utils/promise';

import { ClipSelectAnimationContextType, ClipSelectionType } from '../types';
import { pageBlock as block, headerBlock, selectorBlock } from '../utils';
import { useClipSelect } from './ClipSelectContext';
import { useClipSelectNavigation } from './ClipSelectNavigationContext/ClipSelectNavigationContext';

const BASE_ANIMATION_MILLIS = 600;
const BASE_ANIMATION_DELAY_MILLIS = 300;
const INTRO_ANIMATION_PAUSE_MILLIS = 1300;

const ClipSelectAnimationContext = React.createContext<
  ClipSelectAnimationContextType | undefined
>(undefined);

type BlockTransitionModifiers = {
  noDelay?: boolean;
};

function createElement(root: string, modifiers?: BlockTransitionModifiers) {
  return modifiers?.noDelay ? `${root}--no-delay` : root;
}

function blockTransition(
  element: string,
  modifiers?: BlockTransitionModifiers,
) {
  return block(createElement(element, modifiers));
}

function headerBlockTransition(
  element: string,
  modifiers?: BlockTransitionModifiers,
) {
  return headerBlock(createElement(element, modifiers));
}

export const ClipSelectAnimationProvider: React.FC = ({ children }) => {
  const clipSelectorSliderRef = useRef<Slider>();
  const {
    activeSuggestionId,
    dislikedSuggestionIds,
    removeSuggestion,
    onSuggestionSelect,
    suggestionIds,
    visibleSuggestionIds,
    clipSelectionType,
  } = useClipSelect();
  const introAnimationId = useRef<number>();
  const [state, send] = useClipSelectNavigation();
  const {
    clipsPageInfo: { aspectRatioName },
  } = useClipSelect();

  const handleIntroExited = useCallback(() => {
    send({
      type: 'INTRO_EXITED',
      payload: {
        suggestionCount: suggestionIds?.length,
      },
    });
  }, [send, suggestionIds]);

  const handleIntroEntered = useCallback(() => {
    introAnimationId.current = window.setTimeout(() => {
      send({ type: 'INTRO_EXIT' });
    }, INTRO_ANIMATION_PAUSE_MILLIS);
  }, [send]);

  const handleClipSelected = useCallback((): void => {
    clipSelectorSliderRef?.current?.slickGoTo(
      clipSelectionType === ClipSelectionType.SUGGESTED_CLIP
        ? visibleSuggestionIds.indexOf(activeSuggestionId)
        : visibleSuggestionIds?.length,
      true,
    );
  }, [activeSuggestionId, clipSelectionType, visibleSuggestionIds]);

  useEffect(
    () => () => {
      if (introAnimationId.current) {
        window.clearTimeout(introAnimationId.current);
      }
    },
    [],
  );

  const hasSuggestions = suggestionIds?.length > 0;

  // the clipper appears instantly when there are no suggestions because in that
  // view, the "appear" animation has no staggering - everything fades in all at once.
  // after that entry animation, however, the clipper fades in/out as the user switches
  // between clipper and transcript view, so the entry delay is necessary while waiting
  // for the transcript editor to fade out
  const isClipperInstantEntry =
    !hasSuggestions && state.history?.matches('intro');

  // header fades in instantly when there are no suggestions because that animation
  // has no staggering
  const isHeaderInstantEntry = !hasSuggestions;

  const value: ClipSelectAnimationContextType = {
    clipSelectorSliderRef,
    onClipSelected: handleClipSelected,
    clipper: {
      mountOnEnter: true,
      in: state.matches('edit.waveform'),
      timeout: {
        enter: isClipperInstantEntry
          ? BASE_ANIMATION_MILLIS
          : 2 * BASE_ANIMATION_MILLIS,
        exit: BASE_ANIMATION_MILLIS,
      },
      transitionClassName: blockTransition('clipper-animation', {
        noDelay: isClipperInstantEntry,
      }),
    },
    clipSelector: {
      appear: true,
      in: state.matches('select'),
      timeout: BASE_ANIMATION_MILLIS,
      transitionClassName: blockTransition('selector-animation'),
    },
    // flipper instance which controls the "dislike" animation, removing the suggestion
    // from the carousel. when a sugestion is disliked it is immediately added to the
    // dislikedSuggestionIds array, which will then trigger Flipped components inside
    // of the Flipper to animate.  The disliked suggestion is still visible until the
    // animation completes and it is added to the list of removed suggestions.
    clipSelectorFlipper: {
      flipKey: useMemo(() => JSON.stringify(dislikedSuggestionIds.sort()), [
        dislikedSuggestionIds,
      ]),
      handleEnterUpdateDelete: useCallback(
        async ({
          hideEnteringElements,
          animateEnteringElements,
          animateExitingElements,
          animateFlippedElements,
        }) => {
          // dislike animation doesn't have any entering elements but this is called
          // as a best practice
          hideEnteringElements();

          const currentActiveIndex = visibleSuggestionIds.indexOf(
            activeSuggestionId,
          );

          // this guards against attempting to run the dislike animation if the Flipper
          // is triggered more than once per dislike.  This shouldn't happen.
          if (currentActiveIndex >= 0) {
            // in general, when a suggestion is removed, the next suggestion to its right
            // should slide into the active position - unless the last suggestion in
            // the list is being removed, in which case the suggestion to the left should
            // become active.
            //
            // the animation should progress by initiating the "vanish" (suggestion
            // scaling out) and 50ms later, initiating the "slide" where the next
            // suggestion to become active slides into the active position
            const exiting = animateExitingElements();
            await delay(50);

            if (currentActiveIndex + 1 >= visibleSuggestionIds.length) {
              // when a suggestion is removed from the list, the index of the active
              // element remains the same, e.g. when the first suggestion is removed,
              // the following suggestion moves to index 0, but index 0 is still the
              // active index.
              //
              // when the last suggestion is removed, the active index has to be updated,
              // e.g. when the last suggestion in a list of 4 is removed, the new
              // active index becomes 2.  slickPrev() is used to update the active slide.
              // this only has to be called when the last slide is being removed
              if (visibleSuggestionIds.length >= 2) {
                clipSelectorSliderRef.current.slickPrev();
              }
              onSuggestionSelect(visibleSuggestionIds[currentActiveIndex - 1]);
            } else {
              onSuggestionSelect(visibleSuggestionIds[currentActiveIndex + 1]);
            }

            await Promise.all([exiting, animateFlippedElements()]);
          } else {
            await Promise.all([
              animateExitingElements(),
              animateFlippedElements(),
            ]);
          }

          // we don't have any entering elements, but best practice is to call this
          await animateEnteringElements();
        },
        [activeSuggestionId, onSuggestionSelect, visibleSuggestionIds],
      ),
    },
    createSuggestionCardAnimationConfig: id => {
      const index = visibleSuggestionIds.indexOf(id);
      const last = index === visibleSuggestionIds.length - 1;
      const disliked = dislikedSuggestionIds.includes(id);
      const sizerFlipId = `${id}-sizer`;
      const only = visibleSuggestionIds.length === 1;

      return {
        // the "sizer" component saves space in the carousel while the suggestion vanishes.
        // the sizer begins at the card's width and react-flip-toolkit tweens this to
        // zero thereby sliding all of the following cards down to fill the space of
        // the card that was removed
        sizer: {
          className: selectorBlock('suggestion-sizer', {
            last,
            only,
            removing: disliked,
            [aspectRatioName]: true,
          }),
          flipId: sizerFlipId,
          scale: false,
          shouldFlip: () => visibleSuggestionIds.length > 1,
          // all of the cards need to be inverted except the last card when it is
          // the one being removed
          shouldInvert: () =>
            index > visibleSuggestionIds.indexOf(activeSuggestionId),
          // react-slick's Slider is set to use 600ms linear transitions.  These spring
          // settings mimic that so that all animations have the same duration
          spring: {
            stiffness: 110,
            damping: 20,
          },
          translate: true,
        },
        // the "sizer" component saves space in the carousel while the suggestion vanishes.
        // the sizer animation causes undesirable animations in the sizer's children.
        // the inverter cancels out these animations
        sizerInverter: {
          className: selectorBlock('suggestion-sizer-inverter'),
          inverseFlipId: sizerFlipId,
        },
        // the "vanisher" is repsonsible for scaling the suggestion card out.  Once this
        // animation completes, the card has to be removed from the suggestion list so that
        // it's no longer visible
        vanisher: {
          className: selectorBlock('suggestion-vanisher'),
          flipId: `${id}-vanisher`,
          key: id,
          onExit: (el: HTMLElement, _, removeElement: () => void) => {
            spring({
              onComplete: () => {
                removeElement();
                removeSuggestion(id);
              },
              onUpdate: (val: number) => {
                if (!only) {
                  el.style.transform = `scale(${1 - val})`;
                }
              },
            });
          },
          shouldFlip: () => visibleSuggestionIds.length > 1,
          transformOrigin: 'center',
        },
      };
    },
    transcript: {
      in: state.matches('edit.transcript'),
      mountOnEnter: true,
      unmountOnExit: false,
      timeout: {
        enter: BASE_ANIMATION_MILLIS * 2,
        exit: BASE_ANIMATION_MILLIS,
      },
      transitionClassName: blockTransition('editor-transcript-animation'),
    },
    export: {
      in: state.matches('export'),
      mountOnEnter: true,
      timeout: 2 * BASE_ANIMATION_MILLIS,
      transitionClassName: blockTransition('export-animation'),
      unmountOnExit: true,
    },
    header: {
      in: ['edit', 'select'].some(state.matches),
      mountOnEnter: true,
      timeout: {
        enter: isHeaderInstantEntry
          ? BASE_ANIMATION_MILLIS
          : BASE_ANIMATION_DELAY_MILLIS + 2 * BASE_ANIMATION_MILLIS,
        exit: BASE_ANIMATION_MILLIS,
      },
      transitionClassName: blockTransition('header-animation', {
        noDelay: isHeaderInstantEntry,
      }),
    },
    headerEdit: {
      in: state.matches('edit'),
      enter: !isHeaderInstantEntry,
      mountOnEnter: true,
      timeout: {
        enter: 2 * BASE_ANIMATION_MILLIS,
        exit: BASE_ANIMATION_MILLIS,
      },
      transitionClassName: headerBlockTransition('edit-animation'),
    },
    headerSelect: {
      in: state.matches('select'),
      mountOnEnter: true,
      timeout: {
        enter: 2 * BASE_ANIMATION_MILLIS,
        exit: BASE_ANIMATION_MILLIS,
      },
      transitionClassName: headerBlockTransition('select-animation'),
    },
    intro: {
      in: state.matches('intro.idle'),
      mountOnEnter: true,
      onEntered: handleIntroEntered,
      onExited: handleIntroExited,
      timeout: {
        enter: BASE_ANIMATION_MILLIS * 2,
        exit: BASE_ANIMATION_MILLIS,
      },
      transitionClassName: blockTransition('intro-animation'),
      unmountOnExit: true,
    },
    loader: {
      in: state.matches('loading'),
      mountOnEnter: true,
      timeout: {
        enter: 0,
        exit: BASE_ANIMATION_MILLIS,
      },
      transitionClassName: blockTransition('loader-animation'),
      unmountOnExit: true,
    },
  };

  return (
    <ClipSelectAnimationContext.Provider value={value}>
      {children}
    </ClipSelectAnimationContext.Provider>
  );
};

export function useClipSelectAnimation() {
  const context = useContext(ClipSelectAnimationContext);

  if (context === undefined) {
    throw new Error(
      'useClipSelectAnimation must be used with ClipSelectAnimationProvider',
    );
  }

  return context;
}

import { createSelector } from 'reselect';
import { isEmpty } from 'underscore';
import { ClipSuggestion } from 'blocks/ClipSelect/types';
import { SubscriptionItemInformation } from 'redux/middleware/api/podcast-service/types';
import {
  groupClipSuggestionsSelector,
  podcastSubscriptionsSelector,
  subscriptionItemInformationSelector,
} from 'redux/modules/entities';
import { getValue } from 'utils/collections';

const subscriptionItemIdSelector = (_, subscriptionItemId: number) =>
  subscriptionItemId;

const subscriptionItemSelector = createSelector(
  subscriptionItemIdSelector,
  subscriptionItemInformationSelector,
  (subscriptionItemId, info) =>
    info
      ?.get(String(subscriptionItemId))
      ?.toJS() as SubscriptionItemInformation,
);

export const hasSubscriptionItemInformationSelector = createSelector(
  subscriptionItemSelector,
  item => !isEmpty(item),
);

export const autoCreateSubscriptionIdSelector = createSelector(
  subscriptionItemSelector,
  item => item?.autoCreateSubId,
);

export const podcastSubscriptionSelector = createSelector(
  autoCreateSubscriptionIdSelector,
  podcastSubscriptionsSelector,
  (autoCreateSubscriptionId, subscriptions) => {
    if (!autoCreateSubscriptionId || !subscriptions) {
      return undefined;
    }

    return getValue(subscriptions, String(autoCreateSubscriptionId))?.toJS();
  },
);

export const suggestedClipsSelector = createSelector(
  groupClipSuggestionsSelector,
  (suggestions): ClipSuggestion[] =>
    suggestions?.get('clipSuggestions')?.reduce((acc, s) => {
      if (s.get('status') !== 'error') {
        const {
          autoCreateEpisodeId,
          transcriptSegments,
          text,
          ...rest
        } = s.toJS();

        acc.push({
          ...rest,
          id: autoCreateEpisodeId,
          text: isEmpty(transcriptSegments) ? text : transcriptSegments,
        });
      }
      return acc;
    }, []) ?? [],
);

import { useStaticCallback } from '@sparemin/blockhead';
import { useMemo } from 'react';
import { debounce } from 'underscore';
import { ClipSuggestion } from 'blocks/ClipSelect/types';
import { EddyClipSuggestion } from 'blocks/EddyEmbed';
import { pick } from 'utils/collections';

export type UseEddyTranscriptConfig = {
  suggestedClips: ClipSuggestion[];
  onUpdateSuggestions?: () => void;
};

export type UseEddyTranscriptResult = {
  eddySuggestedClips: EddyClipSuggestion[];
  onTranscriptUpdate: () => void;
};

export default function useEddyTranscript({
  onUpdateSuggestions,
  suggestedClips,
}: UseEddyTranscriptConfig): UseEddyTranscriptResult {
  const eddySuggestedClips: EddyClipSuggestion[] = useMemo(
    () =>
      suggestedClips?.reduce((acc, clip) => {
        if (!clip.isUserSelected) {
          acc.push(pick(clip, 'id', 'startMillis', 'endMillis'));
        }
        return acc;
      }, []),
    [suggestedClips],
  );

  const staticOnUpdateSuggestions = useStaticCallback(onUpdateSuggestions);

  const handleTranscriptUpdate = useMemo(
    () => debounce(staticOnUpdateSuggestions, 5000),
    [staticOnUpdateSuggestions],
  );

  return {
    eddySuggestedClips,
    onTranscriptUpdate: handleTranscriptUpdate,
  };
}

import { Thumbnail } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import EpisodeListEpisodeDescription from 'blocks/AutomationCMS/components/EpisodeListEpisodeDescription';
import EpisodeListRow from 'blocks/AutomationCMS/components/EpisodeListRow';
import { getAspectRatioValue } from 'utils/aspect-ratio';
import { headerBlock as block } from '../../utils';
import { useClipSelect } from '../ClipSelectContext';
import HeaderItem from './HeaderItem';

export interface HeaderPodcastInfoProps {
  className?: string;
}

const HeaderPodcastInfo: React.FC<HeaderPodcastInfoProps> = ({ className }) => {
  const {
    clipsPageInfo: {
      title,
      subtitle,
      artwork,
      mediaDurationMillis,
      publishedAtMillis,
      artworkAspectRatioName,
    },
  } = useClipSelect();

  return (
    <HeaderItem disableInteraction>
      <EpisodeListRow
        className={cn(block('podcast-info'), className)}
        active={false}
        style={{ height: 'auto' }}
      >
        <EpisodeListRow.Container
          className={block('podcast-info-container')}
          collapsible
        >
          <EpisodeListRow.Artwork>
            <div className={block('artwork-container')}>
              <Thumbnail
                src={artwork}
                alt="Clip artwork"
                ratio={getAspectRatioValue(artworkAspectRatioName)}
                basis={
                  getAspectRatioValue(artworkAspectRatioName) < 1
                    ? 'height'
                    : 'width'
                }
              />
            </div>
          </EpisodeListRow.Artwork>
          {title && (
            <EpisodeListEpisodeDescription
              title={title}
              subtitle={subtitle}
              durationMillis={mediaDurationMillis}
              publishedAtMillis={publishedAtMillis}
            />
          )}
        </EpisodeListRow.Container>
      </EpisodeListRow>
    </HeaderItem>
  );
};

export default HeaderPodcastInfo;

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectTileControl } from 'blocks/ProjectHistory/types';
import { projectsDetailsSelector } from 'redux/modules/entities';
import { onClickDownloadLink } from 'redux/modules/mixpanel/actions';
import { pushModal } from 'redux/modules/modal';
import { showError } from 'redux/modules/notification';
import {
  copyProject,
  deleteProjectById,
  getProjectSubscriptionIfExists,
} from 'redux/modules/project/actions';
import { getTemplateId } from 'redux/modules/project/utils';
import { Dispatch } from 'redux/types';

export interface UseProjectControlActionsResult {
  onControlClick: (type: ProjectTileControl, projectId: string) => void;
  onDeleteProject: (projectId: string) => void;
}

export default function useProjectControlActions(): UseProjectControlActionsResult {
  const dispatch = useDispatch<Dispatch>();
  const projects = useSelector(projectsDetailsSelector);

  const handleDeleteProject = useCallback(
    (projectId: string): void => {
      dispatch(deleteProjectById(projectId));
    },
    [dispatch],
  );

  const handleCopyProject = useCallback(
    async (projectId: string, name: string, ratio: number): Promise<void> => {
      try {
        await dispatch(copyProject(projectId, name, ratio));
      } catch {
        dispatch(showError('There was an error copying your project', 5));
      }
    },
    [dispatch],
  );

  const handleDownloadProject = useCallback(
    async (projectId: string): Promise<void> => {
      const templateId = getTemplateId(projects?.get(projectId));
      const subscription = await dispatch(
        getProjectSubscriptionIfExists(projectId),
      );

      dispatch(
        onClickDownloadLink({
          id: projectId,
          templateId,
          templateName: subscription?.templateName,
          templateType: subscription?.templateType,
        }),
      );
    },
    [dispatch, projects],
  );

  const handleControlClick = useCallback(
    async (type: ProjectTileControl, projectId: string): Promise<void> => {
      switch (type) {
        case 'delete':
          dispatch(
            pushModal({
              name: 'ProjectDelete',
              params: { projectId },
            }),
          );
          break;

        case 'copy': {
          try {
            const copyResult = await dispatch(
              pushModal({
                name: 'ProjectCopy',
                params: { projectId },
              }),
            );

            if (copyResult) {
              const { aspectRatio, projectName } = copyResult;

              handleCopyProject(projectId, projectName, aspectRatio);
            }
          } catch {
            dispatch(
              showError({
                title: 'Something went wrong',
                message: 'Please try again',
                dismissAfterSec: 5,
              }),
            );
          }
          break;
        }

        case 'download':
          handleDownloadProject(projectId);
          break;

        default:
          break;
      }
    },
    [dispatch, handleCopyProject, handleDownloadProject],
  );

  return {
    onControlClick: handleControlClick,
    onDeleteProject: handleDeleteProject,
  };
}

import * as React from 'react';
import { noop } from 'underscore';

import { SelectField } from 'components/Form';
import CaptionsFormControl from './CaptionsFormControl';
import { block } from './utils';

interface IProps {
  value?: boolean;
  onChange?: (enabled: boolean) => void;
}

export default class EnableCaptionsField extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onChange: noop,
    value: false,
  };

  private handleChange = (val: string) => {
    const { onChange } = this.props;
    const enabled = val === 'on';
    onChange(enabled);
  };

  public render() {
    const { value } = this.props;

    return (
      <CaptionsFormControl>
        <SelectField
          className={block('enable-field')}
          onChange={this.handleChange}
          value={value ? 'on' : 'off'}
        >
          <option value="on">Show captions</option>
          <option value="off">Hide captions</option>
        </SelectField>
      </CaptionsFormControl>
    );
  }
}

export { IProps as EnableCaptionsFieldProps };

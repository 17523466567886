import { VideoOutline, Waveform } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { onOnboardingWizardNext } from 'redux/modules/mixpanel';
import CardWizardStep from '../CardWizard/CardWizardStep';
import PodcastMediaOptionButton from './PodcastMediaOptionButton';
import { block } from './utils';

type PodcastMediaStepProps = {
  onAudioSelect: () => void;
  onVideoSelect: () => void;
};

const PodcastMediaStep: React.FC<PodcastMediaStepProps> = ({
  onAudioSelect,
  onVideoSelect,
}) => {
  const dispatch = useDispatch();
  const handleAudioSelect = useCallback(() => {
    dispatch(
      onOnboardingWizardNext('podcastType', { buttonClick: 'audioPod' }),
    );
    onAudioSelect();
  }, [dispatch, onAudioSelect]);

  const handleVideoSelect = useCallback(() => {
    dispatch(
      onOnboardingWizardNext('podcastType', { buttonClick: 'videoPod' }),
    );
    onVideoSelect();
  }, [dispatch, onVideoSelect]);

  return (
    <CardWizardStep className={block()}>
      <div className={block('heading')}>
        <CardWizardStep.H1>
          What are we starting with this time?
        </CardWizardStep.H1>
        <CardWizardStep.H2>
          You can always choose another option later.
        </CardWizardStep.H2>
      </div>
      <div className={block('media-options')}>
        <PodcastMediaOptionButton
          title="Audio file"
          description="Turn audio into videos with a waveform and subtitles."
          onPress={handleAudioSelect}
        >
          <Waveform />
        </PodcastMediaOptionButton>
        <PodcastMediaOptionButton
          title="Video file"
          description="Create shareable clips with subtitles."
          onPress={handleVideoSelect}
        >
          <VideoOutline />
        </PodcastMediaOptionButton>
      </div>
    </CardWizardStep>
  );
};
export default PodcastMediaStep;

import cn from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Button, { ButtonProps } from 'components/Button';
import FontAwesome from 'components/FontAwesome';
import Tooltip from 'components/Tooltip';
import { onProjectsTabAddButton } from 'redux/modules/mixpanel/actions';
import { pushModal, replaceModal } from 'redux/modules/modal/actions';
import { Dispatch } from 'redux/types';
import bem from 'utils/bem';

export interface AutomationCMSActionButtonProps
  extends Pick<ButtonProps, 'onClick'> {
  className?: string;
}

const block = bem('automation-cms-action-button');

const AutomationCMSActionButton: React.FC<AutomationCMSActionButtonProps> = ({
  className,
  onClick,
}) => {
  const dispatch = useDispatch<Dispatch>();

  const handleClick = useCallback(
    async (e: React.MouseEvent) => {
      onClick?.(e);

      dispatch(onProjectsTabAddButton());

      const podcast = await dispatch(
        pushModal({
          name: 'PodcastConnect',
        }),
      );

      dispatch(
        replaceModal({
          name: 'PodcastConnected',
          params: {
            podcastId: podcast.podcastId,
            title: podcast.title,
          },
        }),
      );
    },
    [dispatch, onClick],
  );

  return (
    <Tooltip
      content="Add a podcast"
      id="add-podcast-tooltip"
      placement="right"
      hideArrow
    >
      <Button
        className={cn(block(), className)}
        componentClass={Link}
        onClick={handleClick}
        theme="submit"
      >
        <FontAwesome icon="plus" style={{ height: 19, width: 19 }} />
      </Button>
    </Tooltip>
  );
};

export default AutomationCMSActionButton;

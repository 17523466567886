import { isEmpty } from 'underscore';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { Measurement, ViewportHeight, ViewportWidth } from 'utils/measurement';
import { createTextShadowString, createTextStrokeConfig } from 'utils/ui';

import { CaptionsState, FramingMethod, ToPx, VideoClip } from '../types';
import { AssetStyle } from './types';

export const getCaptionStyle = (
  captionsState: CaptionsState,
  toPx: ToPx,
): AssetStyle => {
  if (!captionsState || isEmpty(captionsState)) {
    return undefined;
  }

  const { containerStyle } = captionsState;

  const toPxValue = (m: Measurement) => toPx(m).value;

  const textShadow = containerStyle?.textShadow;
  const textShadowStyle =
    typeof textShadow === 'string'
      ? textShadow
      : createTextShadowString(containerStyle?.textShadow);

  return {
    ...containerStyle,
    ...createTextStrokeConfig(containerStyle?.textOutline),
    width: captionsState.size?.width,
    height: captionsState.size?.height,
    left: captionsState.position?.left,
    top: captionsState.position?.top,
    paddingLeft: toPxValue(
      new ViewportWidth(parseFloat(containerStyle?.paddingLeft)),
    ),
    paddingTop: toPxValue(
      new ViewportWidth(parseFloat(containerStyle?.paddingTop)),
    ),
    paddingRight: toPxValue(
      new ViewportWidth(parseFloat(containerStyle?.paddingRight)),
    ),
    paddingBottom: toPxValue(
      new ViewportWidth(parseFloat(containerStyle?.paddingBottom)),
    ),
    position: captionsState.region?.type as React.CSSProperties['position'],
    textShadow: textShadowStyle,
    fontSize: toPxValue(
      new ViewportWidth(parseFloat(containerStyle?.fontSize)),
    ),
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  };
};

export const checkIsDynamicAutoframedVideoClip = (
  videoClip: VideoClip<ViewportHeight, ViewportWidth>,
  framingMethod: FramingMethod,
  aspectRatio: number,
): boolean => {
  return (
    videoClip?.integrationData?.id === 'dynamic' &&
    framingMethod === 'autoframe' &&
    getAspectRatioName(aspectRatio) !== 'landscape'
  );
};

export const getVideoClipFramingMethod = (
  videoClip: VideoClip<ViewportHeight, ViewportWidth>,
): FramingMethod | undefined => {
  return videoClip?.framingMethod;
};

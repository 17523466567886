import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { receiveCreationSuggestionEvent } from 'redux/middleware/api/creation-service/actions';
import { receiveAutoCreateEpisodeEvent } from 'redux/middleware/api/podcast-service/actions';
import {
  autoCreateEpisodeSelector,
  initiatorInfoSelector,
  isAutoCreateEpisodeSelector,
  isVideoTiedToSuggestionGroupSelector,
  performDpa,
  sendDownloadPageActionEvent,
} from 'redux/modules/download';
import { Dispatch } from 'redux/types';
import { MIXPANEL_EVENTS_BY_ACTION } from './constants';
import { DownloadPageAction, DownloadPageEventHandlers } from './types';

type DownloadPageActionHandler = <T extends DownloadPageAction>(
  actionType: T,
  ...args: Parameters<DownloadPageEventHandlers[T]>
) => void;

export interface UseOnDownloadPageActionResult {
  onDownloadPageAction: DownloadPageActionHandler;
}

export default function useOnDownloadPageAction(): UseOnDownloadPageActionResult {
  const dispatch = useDispatch<Dispatch>();

  const { creationRequestId, creationSuggestionId } = useSelector(
    initiatorInfoSelector,
  );
  const isVideoTiedToSuggestionGroup = useSelector(
    isVideoTiedToSuggestionGroupSelector,
  );
  const isAutoCreateEpisode = useSelector(isAutoCreateEpisodeSelector);
  const autoCreateEpisode = useSelector(autoCreateEpisodeSelector);

  const handleReceiveEvent = useCallback((): void => {
    if (isVideoTiedToSuggestionGroup) {
      dispatch(
        receiveCreationSuggestionEvent({
          creationRequestId,
          suggestionId: creationSuggestionId,
          eventType: 'downloadPageAction',
        }),
      );

      return undefined;
    }

    if (isAutoCreateEpisode) {
      dispatch(
        receiveAutoCreateEpisodeEvent({
          autoCreateEpisodeId: autoCreateEpisode?.get('autoCreateSubItemId'),
          eventType: 'downloadPageAction',
        }),
      );

      return undefined;
    }

    return undefined;
  }, [
    autoCreateEpisode,
    creationRequestId,
    creationSuggestionId,
    dispatch,
    isAutoCreateEpisode,
    isVideoTiedToSuggestionGroup,
  ]);

  const handleDownloadPageAction: DownloadPageActionHandler = useCallback(
    (actionType, ...args) => {
      dispatch(performDpa);
      dispatch(MIXPANEL_EVENTS_BY_ACTION[actionType].apply(null, args));
      dispatch(sendDownloadPageActionEvent());

      handleReceiveEvent();
    },
    [dispatch, handleReceiveEvent],
  );

  return {
    onDownloadPageAction: handleDownloadPageAction,
  };
}

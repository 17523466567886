import { CropMetadata, MixedPartial } from 'types';

export type Size = CropMetadata['container'];
export type Dimensions = CropMetadata['canvas'];

export interface CropperData extends CropMetadata {
  /**
   * HTML canvas element representing the cropped area
   */
  croppedCanvas: HTMLCanvasElement;

  /**
   *  the image src passed to the cropper component
   */
  originalImageSrc: string;

  constrained: boolean;
}

export type DefaultMetadata = MixedPartial<
  CropMetadata,
  'canvas' | 'container'
>;

export enum DragMode {
  Crop = 'crop',
  Move = 'move',
  None = 'none',
}

export interface CropperSides {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

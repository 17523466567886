import * as React from 'react';
import { constant, noop } from 'underscore';

import { SampleAudioRecord } from 'components/AudioLibrary';
import Wizard, {
  IndexedStep,
  Navigator,
  Step,
  WizardProps,
} from 'components/Wizard';
import { AspectRatioName } from 'types';
import bem from 'utils/bem';
import { TEXT_TO_VIDEO_MAX_CHARS } from 'utils/constants';
import { createChainedFunction } from 'utils/functions';
import AspectRatioStep from '../AspectRatioStep';
import EditSummaryStep from '../EditSummaryStep';
import SelectAudioStep from '../SelectAudioStep';
import ImportTextStep from './ImportTextStep';
import TextWizardProgressStep from './TextWizardProgressStep';
import { ImportType } from './types';

const block = bem('text-wizard');
const returnNull = constant(null);

type Audio = SampleAudioRecord | 'none';

export enum Steps {
  IMPORT_TEXT = 0,
  EDIT_SUMMARY = 1,
  SELECT_ASPECT_RATIO = 2,
  SELECT_AUDIO = 3,
}

interface IProps extends Pick<WizardProps, 'onStepChange'> {
  onCancel?: (nav: Navigator) => void;
  onMount?: () => void;
  onNextClick?: (nav: Navigator, state: IState) => void;
  originalArticle?: string;
  onUnmount?: () => void;
  summarizing?: boolean;
  summary?: string;
  onComplete: () => void;
}

interface IState {
  aspectRatioName?: AspectRatioName;
  audio: Audio;
  importType: ImportType;
  stepIndex?: number;
  summary?: string;
  text?: string;
  url?: string;
}

const defaultState: Readonly<IState> = {
  aspectRatioName: 'square',
  audio: 'none',
  importType: ImportType.LINK,
  stepIndex: undefined,
  summary: undefined,
  text: '',
  url: '',
};

export default class TextWizard extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    onNextClick: noop,
  };

  private static countChars(text: string) {
    if (!text) return 0;
    return text.replace(/[\r\n]+/g, '').length;
  }

  public state: Readonly<IState> = defaultState;

  public componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const { summary } = this.props;
    const { summary: nextSummary } = nextProps;

    if (nextSummary !== summary) {
      this.handleSummaryChange(nextSummary);
    }
  }

  public componentWillUnmount() {
    const { onUnmount } = this.props;
    onUnmount();
  }

  private getMaxTextLength = () => TEXT_TO_VIDEO_MAX_CHARS;

  private handleStepChange = (toStep: IndexedStep) => {
    if (toStep.index === 0) {
      this.setState(defaultState);
      this.props.onMount();
    }

    this.setState({ stepIndex: toStep.index });
  };

  private handleUrlChange = (url: string) => this.setState({ url });

  private handleTextChange = (text: string) => this.setState({ text });

  private handleNextClick = (nav: Navigator) => {
    const { importType } = this.state;

    if (nav.step === Steps.IMPORT_TEXT && importType === ImportType.TEXT) {
      const { text } = this.state;
      const maxTextLength = this.getMaxTextLength();
      const summaryText = text.substr(0, maxTextLength);

      this.setState({ summary: summaryText });
      nav.next();
      return;
    }

    this.props.onNextClick(nav, { ...this.state });
  };

  private handleSummaryChange = (summary?: string) =>
    this.setState({ summary });

  private handleAudioSelect = (audio: Audio) => this.setState({ audio });

  private handleAspectRatioSelect = (__, aspectRatioName: AspectRatioName) =>
    this.setState({ aspectRatioName });

  private handleOnSelectType = (importType: ImportType) => {
    this.setState({ importType });
  };

  private getSteps(): Step[] {
    const { originalArticle, summarizing, onComplete } = this.props;
    const {
      aspectRatioName,
      audio,
      importType,
      summary,
      text,
      url,
    } = this.state;

    const ifNotSummarizing = <T extends {}>(val: T) =>
      summarizing ? null : val;
    const maxTextLength = this.getMaxTextLength();
    const charCount = TextWizard.countChars(summary);
    const disabledImportTextStepNextButton = () =>
      (importType === ImportType.LINK && !url) ||
      (importType === ImportType.TEXT && !text);
    const getOriginalText = () =>
      importType === ImportType.LINK ? originalArticle : text;

    return [
      {
        component: (
          <ImportTextStep
            onSelectType={this.handleOnSelectType}
            onUrlChange={this.handleUrlChange}
            onTextChange={this.handleTextChange}
            summarizing={summarizing}
            text={text}
            type={importType}
            url={url}
          />
        ),
        description: ifNotSummarizing(
          <div className={block('description')}>
            We’ll automatically add relevant media, text styles, and
            transitions.
          </div>,
        ),
        name: 'Import Text',
        renderCancelButton: summarizing ? constant(null) : undefined,
        renderNextButton: props =>
          ifNotSummarizing(
            <Wizard.Button
              {...props}
              disabled={disabledImportTextStepNextButton()}
              theme="next"
            >
              Next
            </Wizard.Button>,
          ),
        stepId: 'importText',
        title: ifNotSummarizing('Add an article, we’ll add the magic'),
      },
      {
        component: (
          <EditSummaryStep
            maxLength={maxTextLength}
            onChange={this.handleSummaryChange}
            originalArticle={getOriginalText()}
            value={{ charCount, text: summary }}
          />
        ),
        description: `We allow ${this.getMaxTextLength()} characters, which makes a one minute video.`,
        name: 'Edit Script',
        renderNextButton: props => (
          <Wizard.Button
            {...props}
            disabled={!summary || charCount === 0 || charCount > maxTextLength}
            theme="next"
          >
            Looks good
          </Wizard.Button>
        ),
        stepId: 'editScript',
        title: 'Here is your video script',
      },
      {
        component: (
          <AspectRatioStep
            onSelect={this.handleAspectRatioSelect}
            value={aspectRatioName}
          />
        ),
        description:
          'Pick a format that best fits where you plan to share your video.',
        name: 'Choose Aspect Ratio',
        stepId: 'aspectRatio',
        title: 'Choose an Aspect Ratio',
      },
      {
        component: (
          <SelectAudioStep
            onSelect={this.handleAudioSelect}
            selectedId={audio === 'none' ? audio : audio.get('id')}
          />
        ),
        description:
          'The audio library is completely free to use for any purpose without any attribution. Enjoy!',
        name: 'Select Audio',
        renderNextButton: props => (
          <Wizard.Button {...props} theme="next">
            create project
          </Wizard.Button>
        ),
        stepId: 'uploadAudio',
        title: 'Select Background Audio',
      },
      {
        component: <TextWizardProgressStep onCompleted={onComplete} />,
        stepId: 'create',
        name: 'loading',
        renderCancelButton: returnNull,
        renderNextButton: returnNull,
        showInNav: false,
      },
    ];
  }

  public render() {
    const { onCancel, onStepChange } = this.props;
    const { stepIndex } = this.state;

    const steps = this.getSteps();

    return (
      <Wizard
        canBacktrack={stepIndex !== steps.length - 1}
        className={block()}
        bodyClassName={block('body', {
          'max-height': stepIndex === Steps.EDIT_SUMMARY,
        })}
        buttonClassName={block('buttons', {
          'audio-step': stepIndex === Steps.SELECT_AUDIO,
        })}
        onCancelClick={onCancel}
        onNextClick={this.handleNextClick}
        onStepChange={createChainedFunction(
          this.handleStepChange,
          onStepChange,
        )}
        steps={steps}
      />
    );
  }
}

export { IProps as TextWizardProps, IState as TextWizardState };

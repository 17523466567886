import React from 'react';

import TimerPreview from 'components/TimerPreview';
import VideoProgressPreview from 'components/VideoProgressPreview';
import {
  ProgressState,
  TimerState,
} from 'components/VideoTemplateEditor/types';
import { viewportToPct } from 'utils/placement';

import { editorModalPreviewBlock as block } from '../../utils';

interface EditorModalProgressOverlayProps {
  progress: ProgressState;
  timer: TimerState;
}

const EditorModalProgressOverlay: React.FunctionComponent<EditorModalProgressOverlayProps> = props => {
  const { progress, timer } = props;

  const ProgressComponent = React.useMemo(() => {
    if (!progress?.enabled) {
      return null;
    }

    const placement = viewportToPct({
      left: progress.left,
      top: progress.top,
    });
    const size = viewportToPct({
      height: progress.height,
      width: progress.width,
    });

    return (
      <div
        className={block('progress')}
        style={{
          ...placement,
          ...size,
        }}
      >
        <VideoProgressPreview
          color={progress.color}
          fillColor={progress.fillColor}
          type={progress.type}
        />
      </div>
    );
  }, [progress]);

  const TimerComponent = React.useMemo(() => {
    if (!timer?.enabled) {
      return null;
    }

    const placement = viewportToPct(timer.position);
    const size = viewportToPct(timer.timerSize);

    return (
      <div
        className={block('progress')}
        style={{
          ...placement,
          ...size,
        }}
      >
        <TimerPreview color={timer.color} fontSize={timer.fontSize} />
      </div>
    );
  }, [timer]);

  return (
    <>
      {ProgressComponent}
      {TimerComponent}
    </>
  );
};

export default EditorModalProgressOverlay;

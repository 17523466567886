import { ProjectCreationMethod, TemplateType } from 'types';
import {
  DEFAULT_REVISION_HISTORY_PAGE,
  DEFAULT_REVISION_HISTORY_SIZE,
} from '../../../../utils/constants';
import { createApiAction } from '../utils';
import * as types from './types';

interface ICreateProjectOptions {
  thumbnailUrl?: string;
  copiedFromProjectId?: string;
  traceId?: string;
  customTraceId?: string;
  createdFromTemplateId?: string;
  createdFromTemplateType?: TemplateType;
}

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export const createProject = (
  id: string,
  name: string,
  aspectRatio: types.IAspectRatio,
  createMethod: ProjectCreationMethod,
  options: ICreateProjectOptions = {},
): types.CreateProjectAction => {
  const {
    thumbnailUrl,
    copiedFromProjectId,
    traceId,
    customTraceId,
    createdFromTemplateId,
    createdFromTemplateType,
  } = options;
  return apiCallAction(types.ServiceMethod.CREATE_PROJECT, [
    id,
    name,
    aspectRatio,
    createMethod,
    thumbnailUrl,
    copiedFromProjectId,
    traceId,
    customTraceId,
    createdFromTemplateId,
    createdFromTemplateType,
  ] as types.CreateProjectArgs);
};

export const fetchProjectById = (id: string): types.GetProjectByIdAction =>
  apiCallAction(types.ServiceMethod.GET_PROJECT_BY_ID, [
    id,
  ] as types.GetProjectByIdArgs);

export const fetchProjects = (
  ownerId: number,
  createMethod: ProjectCreationMethod,
  pageSize: number,
  nextToken: string,
): types.GetAllProjectsAction =>
  apiCallAction(types.ServiceMethod.GET_ALL_PROJECTS, [
    ownerId,
    createMethod,
    pageSize,
    nextToken,
  ] as types.GetAllProjectsArgs);

export const fetchProjectsByUrl = (url: string): types.GetProjectsByUrlAction =>
  apiCallAction(types.ServiceMethod.GET_PROJECTS_BY_URL, [url]);

export const putProject = (
  id: string,
  embedConfigurationId: string,
  name: string,
  aspectRatio: any,
  thumbnail?: string,
): types.UpdateProjectAction =>
  apiCallAction(types.ServiceMethod.UPDATE_PROJECT, [
    id,
    embedConfigurationId,
    name,
    aspectRatio,
    thumbnail,
  ] as types.UpdateProjectArgs);

export const deleteProject = (id: string): types.DeleteProjectAction =>
  apiCallAction(types.ServiceMethod.DELETE_PROJECT, [
    id,
  ] as types.DeleteProjectArgs);

export const getRevisionHistoryByProjectId = (
  id: string,
  page: number = DEFAULT_REVISION_HISTORY_PAGE,
  size: number = DEFAULT_REVISION_HISTORY_SIZE,
): types.GetRevisionHistoryByProjectIdAction =>
  apiCallAction(types.ServiceMethod.GET_REVISION_HISTORY_BY_PROJECT_ID, [
    id,
    page,
    size,
  ] as types.GetRevisionHistoryByProjectIdArgs);

import React, { useCallback, useRef } from 'react';
import SteppedModal from 'components/SteppedModal';
import useComposerStep from './steps/useComposerStep';
import useLoaderStep from './steps/useLoaderStep';
import useSelectAccountStep from './steps/useSelectAccountStep';
import useSwitchAccountStep from './steps/useSwitchAccountStep';
import { InstagramPostModalProps } from './types';
import { block } from './utils';

export interface InstagramPostVideoModalProps
  extends Pick<InstagramPostModalProps, 'onHide' | 'show'> {
  videoDurationSec: number;
}

const InstagramPostVideoModal: React.FC<InstagramPostVideoModalProps> = ({
  show = false,
  videoDurationSec,
  onHide,
}) => {
  const modalRef = useRef<SteppedModal>(null);

  const goToStep = useCallback((nextStep: string): void => {
    modalRef.current.stepHistory.replace(nextStep);
  }, []);

  const loaderStep = useLoaderStep({ goToStep, onHide });

  const selectAccountStep = useSelectAccountStep({
    videoDurationSec,
    goToStep,
  });

  const switchAccount = useSwitchAccountStep({ goToStep });

  const composerStep = useComposerStep({
    goToStep,
  });

  return (
    <SteppedModal
      defaultStep="select-account"
      title="Post your video on instagram"
      baseClassName={block()}
      onHide={onHide}
      ref={modalRef}
      show={show}
      steps={[loaderStep, switchAccount, selectAccountStep, composerStep]}
    />
  );
};

export default InstagramPostVideoModal;

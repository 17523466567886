import { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { InstagramMediaType } from 'redux/middleware/api/podcast-service';
import {
  instagramAccountsLoadingSelector,
  instagramUserDataSelector,
} from 'redux/modules/social';
import useInstagramPostModalSelector from '../../useInstagramPostModalSelector';
import {
  UseAutopostingComposerStep,
  UseAutopostingComposerStepConfig,
} from './types';
import usePostComposerStep from './usePostComposerStep';

const useAutopostingComposerStep = (
  config: UseAutopostingComposerStepConfig,
): UseAutopostingComposerStep => {
  const {
    activeStep,
    initialValues,
    onHideModal,
    onSubmit,
    onSwitchAccountClick,
  } = config;

  const isActive = activeStep === 'composer';

  const {
    defaultDescription,
    defaultMediaType,
    defaultUserId,
    accountsList,
  } = useInstagramPostModalSelector({
    defaultDescription: initialValues?.description,
    defaultMediaType: initialValues.instagramMediaType,
    defaultInstagramUserId: initialValues?.instagramUserId,
  });

  const { profileImageUrl, username } = useSelector(instagramUserDataSelector);
  const isLoading = useSelector(instagramAccountsLoadingSelector);

  const [prevActiveState, setPrevActiveState] = useState<boolean>(isActive);
  const [prevInitialValues, setPrevInitialValues] = useState(initialValues);
  const [prevIsLoading, setPrevIsLoading] = useState(isLoading);
  const [description, setDescription] = useState<string>(defaultDescription);
  const [mediaType, setMediaType] = useState<InstagramMediaType>(
    defaultMediaType,
  );
  const [instagramUserId, setInstagramUserId] = useState<string | number>(
    defaultUserId,
  );

  const handleSetDefaultProps = useCallback(() => {
    setDescription(defaultDescription);
    setMediaType(defaultMediaType);
    setInstagramUserId(defaultUserId);
  }, [defaultDescription, defaultMediaType, defaultUserId]);

  const handleSubmit = useCallback((): void => {
    onSubmit?.({
      description: mediaType === 'stories' ? '' : description,
      commentEnabled: true,
      instagramUserId: instagramUserId ?? defaultUserId,
      instagramMediaType: mediaType,
      scopeContext: 'instagramBusinessManageV2',
    });

    handleSetDefaultProps();
  }, [
    defaultUserId,
    description,
    handleSetDefaultProps,
    instagramUserId,
    mediaType,
    onSubmit,
  ]);

  const handleSwitchAccountClick = useCallback(() => {
    onSwitchAccountClick();
    setInstagramUserId(undefined);
  }, [onSwitchAccountClick]);

  const postComposer = usePostComposerStep({
    allowSubmit: Boolean(description),
    description,
    profileImageUrl,
    username,
    onDescriptionChange: setDescription,
    onHideModal,
    onSubmit: handleSubmit,
    onSwitchAccountClick: handleSwitchAccountClick,
  });

  if (
    prevIsLoading &&
    !isLoading &&
    !instagramUserId &&
    Boolean(accountsList.length)
  ) {
    setInstagramUserId(defaultUserId);
    setPrevIsLoading(isLoading);
  }

  if (prevActiveState !== isActive) {
    handleSetDefaultProps();
    setPrevActiveState(isActive);
  }

  if (!isEqual(prevInitialValues, initialValues)) {
    handleSetDefaultProps();
    setPrevInitialValues(initialValues);
  }

  return postComposer;
};

export default useAutopostingComposerStep;

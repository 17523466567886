import React, { useEffect, useRef } from 'react';
import { VideoClip } from 'components/VideoTemplateEditor/types';
import { measurementToPx, measurementToString } from 'utils/placement';
import Layer from '../../layers/Layer';
import { editorModalPreviewBlock as block } from '../../utils';

type EditorModalVideoLayerProps = {
  clip: Omit<VideoClip, 'style' | 'position'>;
  canvasSize: { height: number; width: number };
};

const EditorModalVideoLayer: React.FC<EditorModalVideoLayerProps> = ({
  clip,
  canvasSize,
}) => {
  const videoRef = useRef<HTMLVideoElement>();

  const videoUrl = clip?.videoUrl;

  useEffect(() => {
    const element = videoRef.current;

    return () => {
      element?.pause();
    };
  }, [videoUrl]);

  if (!clip || (!clip.videoUrl && !clip.previewThumbnail)) return null;

  const props = {
    style: measurementToString(measurementToPx(clip.placement, canvasSize)),
    className: block('video-layer'),
  };

  return (
    <Layer>
      {clip.videoUrl ? (
        <video
          autoPlay
          loop
          muted
          ref={videoRef}
          src={clip.videoUrl}
          {...props}
        />
      ) : (
        <img src={clip.previewThumbnail.url} {...props} />
      )}
    </Layer>
  );
};
export default EditorModalVideoLayer;

import React from 'react';
import { noop } from 'underscore';

import { LegacyEditCaptionsModalProps } from 'containers/LegacyEditCaptionsModal';
import UploadCaptionsModal from 'containers/UploadCaptionsModal';
import { TranscriptStatus } from 'utils/constants';

import CaptionsStyleEditor from '../CaptionsStyleEditor';
import useUploadCaptionsModal from './useUploadCaptionsModal';

export type CaptionsModal = 'add' | 'edit';

export interface CaptionsModalsProps {
  onEditCaptionsSubmit: LegacyEditCaptionsModalProps['onSubmit'];
  onHide?: () => void;
  onOpenEditModal?: () => void;
  openUploadCaptionsModal?: () => void;
  transcriptStatus: TranscriptStatus;
  show: CaptionsModal | undefined;
}

const CaptionsModals: React.SFC<CaptionsModalsProps> = ({
  onEditCaptionsSubmit,
  onHide,
  onOpenEditModal = noop,
  openUploadCaptionsModal,
  show,
  transcriptStatus,
}) => {
  const { onFileAccepted, onFileError } = useUploadCaptionsModal({
    onUploadNewFile: openUploadCaptionsModal,
    onHide,
  });

  return (
    <>
      {transcriptStatus === TranscriptStatus.TRANSCRIPT_STATUS_AVAILABLE && (
        <CaptionsStyleEditor
          onHide={onHide}
          onOpen={onOpenEditModal}
          onSubmit={onEditCaptionsSubmit}
          show={show === 'edit'}
        />
      )}

      <UploadCaptionsModal
        show={show === 'add'}
        onCaptionAccepted={onFileAccepted}
        onHide={onHide}
        onError={onFileError}
      />
    </>
  );
};

export default CaptionsModals;

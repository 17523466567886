import React from 'react';
import ResizeDetector from 'react-resize-detector';

import TextPresetSelector from 'components/TextPresetSelector';
import { ImmutableTextPreset } from 'redux/modules/text-presets';

import { block } from '../../utils';

interface TextOverlayPresetsProps {
  onPickPreset: (presetStyles: ImmutableTextPreset) => void;
  selectedPreset?: ImmutableTextPreset;
}

const TextOverlayPresets: React.FunctionComponent<TextOverlayPresetsProps> = props => {
  const { onPickPreset, selectedPreset } = props;

  const [wrapperWidth, setWrapperWidth] = React.useState(0);

  const handleResize = React.useCallback((width: number): void => {
    setWrapperWidth(width);
  }, []);

  return (
    <ResizeDetector handleWidth onResize={handleResize}>
      <div className={block('presets-toolbar-container')}>
        <TextPresetSelector
          // Fixes an issue that was producing the fades not to appear when the modal
          // was just opened
          key={String(wrapperWidth)}
          mode="horizontal"
          onPickPreset={onPickPreset}
          selectedPreset={selectedPreset}
        />
      </div>
    </ResizeDetector>
  );
};

export default TextOverlayPresets;

import * as React from 'react';

import { SelectField, SelectFieldProps } from 'components/Form';
import useAutomationSupportedLanguages from 'hooks/useAutomationSupportedLanguages';

interface Props extends SelectFieldProps {
  label?: string;
}

const AutomationLanguageSelector: React.SFC<Props> = ({ label, ...props }) => {
  const supportedLanguages = useAutomationSupportedLanguages();

  return (
    <SelectField label={label} {...props}>
      {supportedLanguages.map(option => (
        <option value={option.languageCode} key={option.name}>
          {option.name}
        </option>
      ))}
    </SelectField>
  );
};

AutomationLanguageSelector.defaultProps = {
  label: 'Transcription Language',
  value: 'en-US',
};

export default AutomationLanguageSelector;

import accept from 'attr-accept';
import fileExtension from 'file-extension';
import { MimeType } from 'file-type';
import { FileExtension, FileTypeResult, fromStream } from 'file-type/browser';
import { isSafari } from 'react-device-detect';
import { isString } from 'underscore';
import { isFileLike } from './file';

type FileMetadata = FileTypeResult & {
  type: string;
};

export const SUPPORTED_WATERMARK_IMAGE_TYPES = ['.png', '.jpg'];

export const SUPPORTED_IMAGE_TYPES = ['.jpg', '.png', '.gif', '.svg'];

export const SUPPORTED_VIDEO_TYPES = ['.mp4', '.mov', '.webm'];

export const SUPPORTED_AUDIO_TYPES = ['.mp3', '.wav', '.m4a'];

export const SUPPORTED_FONT_TYPES = [
  '.ttf',
  '.otf',
  '.eot',
  '.svg',
  '.woff',
  '.woff2',
];

export const SUPPORTED_VIDEO_MIME_TYPES: Readonly<string[]> = [
  'video/mp4',
  'video/quicktime',
  'video/webm',
];

export const getSupportedVideoMimeTypes = () =>
  SUPPORTED_VIDEO_MIME_TYPES.filter(
    mimeType => !(mimeType.includes('webm') && isSafari),
  );

export const getSupportedVideoTypes = () =>
  SUPPORTED_VIDEO_TYPES.filter(type => !(type.includes('webm') && isSafari));

export const getSupportedMediaMimeTypes = () => [
  'image/*',
  getSupportedVideoMimeTypes(),
];

export function getSupportedFontTypes() {
  SUPPORTED_FONT_TYPES.filter(
    fontType => !(fontType.includes('webm') && isSafari),
  );
}

export const getSupportedMediaTypes = () =>
  [].concat(SUPPORTED_IMAGE_TYPES, getSupportedVideoTypes());

export const getFileTypeFromUrl = async (
  source: string,
): Promise<FileTypeResult> => {
  const response = await fetch(source);
  const fileType = await fromStream(response.body);

  return fileType;
};

function getFileTypeFromMimeType(mimeType: string): string | undefined {
  return ['image', 'video', 'audio'].find(type => mimeType.includes(type));
}

export async function getFileMetadata(
  source: Blob | File | string,
): Promise<FileMetadata | undefined> {
  if (isFileLike(source)) {
    return {
      type: getFileTypeFromMimeType(source?.type),
      mime: source?.type as MimeType,
      ext: fileExtension(source?.type),
    };
  }

  if (isString(source)) {
    const metadata = await getFileTypeFromUrl(source);

    return {
      type: getFileTypeFromMimeType(metadata?.mime),
      ...metadata,
    };
  }

  return undefined;
}

export const isSupportedVideoFile = (file: File | string) => {
  if (!file) {
    return false;
  }

  if (isString(file)) {
    return accept({ name: file, type: '' }, getSupportedVideoTypes());
  }

  return accept(file, getSupportedVideoMimeTypes());
};

export const isSupportedAudioFile = (file: File | string) => {
  if (!file) {
    return false;
  }

  if (isString(file)) {
    return accept({ name: file, type: '' }, SUPPORTED_AUDIO_TYPES);
  }

  return accept(file, SUPPORTED_AUDIO_TYPES);
};

const BLACKLISTED_IMAGE_TYPES: FileExtension[] = ['jp2', 'jpm', 'jpx', 'mj2'];

export const isSupportedImageFile = async (
  source: FileMetadata | Blob | File | string,
) => {
  const metadata =
    source instanceof Blob || typeof source === 'string'
      ? await getFileMetadata(source)
      : source;

  return !BLACKLISTED_IMAGE_TYPES.includes(metadata?.ext);
};

export const isSupportedFile = async (
  file: File | string,
): Promise<boolean> => {
  const fileMetadata = await getFileMetadata(file);

  if (!fileMetadata) {
    return false;
  }

  const { type } = fileMetadata;

  if (type === 'image') {
    return isSupportedImageFile(fileMetadata);
  }

  if (type === 'video') {
    return isSupportedVideoFile(file);
  }

  if (type === 'audio') {
    return isSupportedAudioFile(file);
  }

  return false;
};

import cn from 'classnames';
import React from 'react';
import bem from 'utils/bem';
import ClipSelectEditViewContainer from '../components/ClipSelectEditViewContainer';
import ClipSelectAudioClipper from './ClipSelectAudioClipper';
import { useClipSelect } from './ClipSelectContext';
import ClipSelectVideoClipper from './ClipSelectVideoClipper';

export interface ClipSelectMediaClipperProps {
  className?: string;
}

const block = bem('clip-select-media-clipper');

const ClipSelectMediaClipper: React.FC<ClipSelectMediaClipperProps> = ({
  className,
}) => {
  const { mediaType } = useClipSelect();

  return (
    <ClipSelectEditViewContainer className={cn(block(), className)}>
      <div className={block('clipper-container')}>
        {mediaType === 'audio' ? (
          <ClipSelectAudioClipper />
        ) : (
          <ClipSelectVideoClipper />
        )}
      </div>
    </ClipSelectEditViewContainer>
  );
};

export default ClipSelectMediaClipper;

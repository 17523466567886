import { ICaptions, VideoTypes } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { CAPTIONS_ANIMATIONS_MAX_DURATION_SEC } from 'utils/constants';
import {
  isAutogramActive,
  isFullEpisodeWizardActive,
  isVideoTranscriptWizardActive,
} from 'utils/routes';

import { BASE_CAPTION_OVERRIDES_MAP } from './constants';

export const getBaseCaptionsOverride = (aspectRatio: number): ICaptions => {
  try {
    const aspectRatioName = getAspectRatioName(aspectRatio);
    return BASE_CAPTION_OVERRIDES_MAP[aspectRatioName];
  } catch {
    return undefined;
  }
};

export const isCaptionsAnimationsTabDisabled = (
  audioClipDurationSec?: number,
  videoType?: VideoTypes,
) => {
  // cond 1: is full episode or video transcript wizard and the picked episode duration exceeds the max duration
  const exceedsMaxDuration =
    audioClipDurationSec &&
    audioClipDurationSec > CAPTIONS_ANIMATIONS_MAX_DURATION_SEC;
  const isFullEpisodeWizard = isFullEpisodeWizardActive();
  const isVideoTranscriptWizard = isVideoTranscriptWizardActive();

  // cond 2: is autogram wizard and the a full episode video type was picked.
  const isAutogramWizard = isAutogramActive();
  const isFullEpisode = videoType === VideoTypes.FULL_EPISODE;

  return (
    // cond 1
    ((isFullEpisodeWizard || isVideoTranscriptWizard) && exceedsMaxDuration) ||
    // cond 2
    (isAutogramWizard && isFullEpisode)
  );
};

import { Map } from 'immutable';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isUndefined } from 'underscore';

import {
  autoTranscriptIdSelector,
  embedManualTranscriptSelector,
  isUploadingFreeFormCaptionSelector,
} from 'redux/modules/embed/selectors';
import { transcriptsSelector } from 'redux/modules/entities/selectors';
import { State } from 'redux/types';
import { PropsOf } from 'types';
import { TranscriptStatus } from 'utils/constants';
import AssetsPane, { AssetsPaneProps as Props } from './AssetsPane';

type StateProps = Pick<Props, 'transcriptStatus'>;

const transcriptStatusSelector = createSelector(
  [
    transcriptsSelector,
    embedManualTranscriptSelector,
    autoTranscriptIdSelector,
    isUploadingFreeFormCaptionSelector,
  ],
  (
    transcripts: Map<string, any>,
    manualTranscript,
    autoTranscriptId,
    isUploadingFreeFormCaption,
  ): TranscriptStatus => {
    if (isUploadingFreeFormCaption) {
      return TranscriptStatus.TRANSCRIPT_STATUS_IN_PROGESS;
    }

    // already have manual transcript
    if (manualTranscript) {
      return TranscriptStatus.TRANSCRIPT_STATUS_AVAILABLE;
    }

    // if there's no transcript id, there's no transcript
    if (!autoTranscriptId) return TranscriptStatus.TRANSCRIPT_STATUS_NA;

    // status gets populated at request time, so if it's not there we don't have a transcript
    const status = transcripts.getIn([autoTranscriptId.toString(), 'status']);
    const chunks = transcripts.getIn([
      autoTranscriptId.toString(),
      'transcript',
    ]);

    // status will always exist even if a transcript is just requested.  no status, no transcript
    // chunk?.size is nuanced - for video transcription, the "transcripts" entity
    // gets partially filled out with data from media-upload service.  even if the
    // transcription is successful, chunks will be undefined since the api doesn't return it.
    // since the chunks aren't defined, this'll evaluate to undefined <= 0 which is false and will
    // prevent the TRANSCRIPT_STATUS_NONE ui flashing on screen before the actual transcript renders
    if (isUndefined(status) || (status === 'completed' && chunks?.size <= 0)) {
      return TranscriptStatus.TRANSCRIPT_STATUS_NONE;
    }

    return TranscriptStatus.TRANSCRIPT_STATUS_IN_PROGESS;
  },
);

const mapStateToProps = (state: State): StateProps => ({
  transcriptStatus: transcriptStatusSelector(state),
});

const component = connect(mapStateToProps)(AssetsPane);
export type AssetsPaneProps = PropsOf<typeof component>;
export default component;

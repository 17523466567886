import { normalize, schema } from 'normalizr';
import saveAs from 'save-as';
import { SuperAgentStatic } from 'superagent';
import { isString } from 'underscore';

import { SPAREMIN_SERVICES_TRANSCRIPT_URL } from 'config';
import { CaptionFormat } from 'types/common';
import { getFileExtension } from 'utils/file';
import { attachFields } from 'utils/request';
import { createRequest } from '../utils';
import * as types from './types';

const ACTION_KEY = 'TRANSCRIPT_SERVICE';

const BASE_V1_TRANSCRIPT_PATH = '/api/v1/transcript';
const MANUAL_TRANSCRIPT_BASE_PATH = '/api/v2/transcript/manual';

export const transcriptSchema = new schema.Entity(
  'transcripts',
  {},
  {
    idAttribute: transcript => transcript.id,
  },
);

const manualTranscriptSchema = new schema.Entity(
  'manualTranscripts',
  {},
  {
    idAttribute: manualTranscript => manualTranscript.id,
  },
);

function getTranscriptByVersionId(
  [versionId]: types.GetTranscriptByVersionIdArgs,
  request: SuperAgentStatic,
): Promise<types.GetTranscriptByVersionIdResult> {
  return request
    .get(`/api/v1/transcript/recording/version/${versionId}`)
    .type('application/json')
    .then(({ body }) => {
      const transcript = { ...body, id: versionId };
      return normalize(transcript, transcriptSchema);
    })
    .catch(err => {
      if (err.status === 404) {
        throw new types.TranscriptNotFoundError(err.message);
      }
      throw err;
    });
}

function addRecordingToWhitelist(
  [recordingId, language]: types.AddRecordingToWhitelistArgs,
  request: SuperAgentStatic,
): Promise<any> {
  return request
    .post(`/api/v1/transcript/whitelist`)
    .send({
      recordingId,
      language,
    })
    .type('application/json')
    .catch(err => {
      throw err;
    });
}

function createManualTranscript(
  args: types.CreateManualTranscriptArgs,
  request: SuperAgentStatic,
): Promise<types.CreateManualTranscriptResult> {
  const [transcript, participants = []] = args;
  return request
    .post(MANUAL_TRANSCRIPT_BASE_PATH)
    .send({ participants, transcript })
    .then(res => normalize(res.body, manualTranscriptSchema));
}

function getTranscriptById(
  args: types.GetTranscriptByIdArgs,
  request: SuperAgentStatic,
): Promise<types.GetTranscriptByIdResult> {
  const [transcriptId] = args;
  return request
    .get(`${MANUAL_TRANSCRIPT_BASE_PATH}/${transcriptId}`)
    .type('application/json')
    .then(res => normalize(res.body, manualTranscriptSchema));
}

function getTranscriptByRevisionId(
  [transcriptId, revisionId]: types.GetTranscriptByRevisionIdArgs,
  request: SuperAgentStatic,
): Promise<types.GetTranscriptByRevisionIdResult> {
  return request
    .get(
      `${MANUAL_TRANSCRIPT_BASE_PATH}/${transcriptId}/revision/${revisionId}`,
    )
    .type('application/json')
    .then(res => normalize(res.body, manualTranscriptSchema));
}

function downloadTranscriptByRevisionId(
  [
    transcriptId,
    revisionId,
    format = CaptionFormat.VTT,
  ]: types.DownloadTranscriptByRevisionIdArgs,
  request: SuperAgentStatic,
): Promise<types.DownloadTranscriptByRevisionIdResult> {
  return request
    .get(
      `${MANUAL_TRANSCRIPT_BASE_PATH}/${transcriptId}/revision/${revisionId}`,
    )
    .query({ format, download: true })
    .responseType('blob')
    .type(`text/${format}`)
    .then(res => saveAs(res.body, `Headliner Transcript.${format}`));
}

async function downloadEpisodeTranscript(
  [episodeId, format]: types.DownloadEpisodeTranscriptArgs,
  request: SuperAgentStatic,
): Promise<types.DownloadEpisodeTranscriptResult> {
  const response = await request
    .get(`/api/v1/transcript/episode/${episodeId}`)
    .query({ format, download: true })
    .responseType('blob');

  if (response.status === 204) {
    throw new types.TranscriptNoContentError();
  }

  saveAs(response.body, `Headliner Transcript.${format}`);
}

function updateTranscriptSubchunk(
  args: types.UpdateTranscriptSubchunkArgs,
  request: SuperAgentStatic,
): Promise<types.UpdateTranscriptSubchunkResult> {
  const [transcriptId, chunkId, subchunkId, transcriptSubchunk] = args;
  return request
    .put(
      `${MANUAL_TRANSCRIPT_BASE_PATH}/${transcriptId}/chunk/${chunkId}/subchunk/${subchunkId}`,
    )
    .send(transcriptSubchunk)
    .then(res => normalize(res.body, manualTranscriptSchema));
}

function updateTranscript(
  [transcriptId, transcript]: types.UpdateTranscriptArgs,
  request: SuperAgentStatic,
): Promise<types.UpdateTranscriptResult> {
  return request
    .put(`${MANUAL_TRANSCRIPT_BASE_PATH}/${transcriptId}`)
    .send(transcript)
    .then(res => normalize(res.body, manualTranscriptSchema));
}

function getVideoTranscript(
  [videoId]: types.GetVideoTranscriptByVideoIdArgs,
  request: SuperAgentStatic,
): Promise<types.GetVideoTranscriptByVideoIdResult> {
  return request
    .get(`/api/v1/transcript/video/${videoId}`)
    .then(({ body }) => {
      const transcript = { ...body, id: videoId };
      return normalize(transcript, transcriptSchema);
    })
    .catch(err => {
      if (err.status === 404) {
        throw new types.TranscriptNotFoundError(err.message);
      }
      throw err;
    });
}

function importManualTranscript(
  [transcriptFile]: types.ImportManualTranscriptArgs,
  request: SuperAgentStatic,
): Promise<types.ImportManualTranscriptResult> {
  const req = request.post(`${MANUAL_TRANSCRIPT_BASE_PATH}/import`);

  if (isString(transcriptFile)) {
    req.field('transcriptUrl', transcriptFile);
  } else {
    req
      .field('format', getFileExtension(transcriptFile))
      .attach('transcriptFile', transcriptFile);
  }

  return req.then(res => normalize(res.body, manualTranscriptSchema));
}

function importFreeFormTranscript(
  [
    textFile,
    recordingId,
    videoId,
    startMillis,
    endMillis,
  ]: types.ImportFreeFormTranscriptArgs,
  request: SuperAgentStatic,
): Promise<types.ImportFreeFormTranscriptResult> {
  const req = request.post(`${BASE_V1_TRANSCRIPT_PATH}/free-form-import`);

  attachFields(req, {
    textFile,
    recordingId,
    videoId,
    startMillis,
    endMillis,
  });

  return req.then(res => res.body);
}

function getFreeFormTranscript(
  [jobId]: types.GetFreeFormTranscriptArgs,
  request: SuperAgentStatic,
): Promise<types.GetFreeFormTranscriptResult> {
  return request
    .get(`${BASE_V1_TRANSCRIPT_PATH}/free-form-import/${jobId}`)
    .then(res => res.body);
}

export const handle: types.IHandler = (
  method: types.ServiceMethod,
  args: any[],
  token?: string,
): any => {
  const request = createRequest({
    token,
    baseUrl: SPAREMIN_SERVICES_TRANSCRIPT_URL,
    omitBearerPrefix: true,
  });

  switch (method) {
    case types.ServiceMethod.ADD_RECORDING_TO_WHITELIST:
      return addRecordingToWhitelist(
        args as types.AddRecordingToWhitelistArgs,
        request,
      );

    case types.ServiceMethod.GET_TRANSCRIPT_BY_VERSION_ID:
      return getTranscriptByVersionId(
        args as types.GetTranscriptByVersionIdArgs,
        request,
      );

    case types.ServiceMethod.CREATE_MANUAL_TRANSCRIPT:
      return createManualTranscript(
        args as types.CreateManualTranscriptArgs,
        request,
      );

    case types.ServiceMethod.GET_TRANSCRIPT_BY_ID:
      return getTranscriptById(args as types.GetTranscriptByIdArgs, request);

    case types.ServiceMethod.GET_TRANSCRIPT_BY_REVISION_ID:
      return getTranscriptByRevisionId(
        args as types.GetTranscriptByRevisionIdArgs,
        request,
      );

    case types.ServiceMethod.GET_VIDEO_TRANSCRIPT_BY_VIDEO_ID:
      return getVideoTranscript(
        args as types.GetVideoTranscriptByVideoIdArgs,
        request,
      );

    case types.ServiceMethod.DOWNLOAD_TRANSCRIPT_BY_REVISION_ID:
      return downloadTranscriptByRevisionId(
        args as types.DownloadTranscriptByRevisionIdArgs,
        request,
      );

    case types.ServiceMethod.UPDATE_TRANSCRIPT_SUBCHUNK:
      return updateTranscriptSubchunk(
        args as types.UpdateTranscriptSubchunkArgs,
        request,
      );

    case types.ServiceMethod.UPDATE_TRANSCRIPT:
      return updateTranscript(args as types.UpdateTranscriptArgs, request);

    case types.ServiceMethod.IMPORT_MANUAL_TRANSCRIPT:
      return importManualTranscript(
        args as types.ImportManualTranscriptArgs,
        request,
      );

    case types.ServiceMethod.IMPORT_FREE_FORM_TRANSCRIPT:
      return importFreeFormTranscript(
        args as types.ImportFreeFormTranscriptArgs,
        request,
      );

    case types.ServiceMethod.GET_FREE_FORM_TRANSCRIPT:
      return getFreeFormTranscript(
        args as types.GetFreeFormTranscriptArgs,
        request,
      );

    case types.ServiceMethod.DOWNLOAD_EPISODE_TRANSCRIPT:
      return downloadEpisodeTranscript(
        args as types.DownloadEpisodeTranscriptArgs,
        request,
      );

    default:
      throw new Error(`${ACTION_KEY} cannot handle method ${method}`);
  }
};

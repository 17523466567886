import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import { isString, isUndefined } from 'underscore';
import { SPAREMIN_SERVICES_EMBEDVIDEO_URL } from 'config';

import { attachFields } from 'utils/request';
import { createRequest } from '../utils';
import * as types from './types';

const BASE_PATH = '/api/v1/embed-video';

const exportSchema = new schema.Entity(
  'embedExports',
  {},
  {
    idAttribute: 'widgetId',
  },
);

const socialShareSchema = new schema.Entity('socialShares');

const embedVideoSocialPostCaptionsSchema = new schema.Entity(
  'embedVideoSocialPostCaptions',
  {},
  {
    idAttribute: 'jobId',
  },
);

const queueVideoCreate = (
  config: types.QueueVideoCreateArgs,
  request: SuperAgentStatic,
): Promise<types.QueueVideoCreateResult> => {
  const { introVideo, outroVideo, ...fields } = config;

  const req = request.post(`${BASE_PATH}/`);

  const attachFile = (field: 'intro' | 'outro', file: File | string) => {
    if (isUndefined(file)) return;
    if (isString(file)) {
      req.field(`${field}VideoUrl`, file);
    } else {
      req.attach(`${field}Video`, file);
    }
  };

  attachFields(req, fields);
  attachFile('intro', introVideo);
  attachFile('outro', outroVideo);

  return req.then(res => res.body);
};

const getCreateStatus = (
  args: types.GetCreateStatusArgs,
  request: SuperAgentStatic,
): Promise<types.GetCreateStatusResult> => {
  const [widgetId] = args;

  return request.get(`${BASE_PATH}/${widgetId}`).then(res => ({
    ...normalize(res.body, exportSchema),
    ...res.body,
  }));
};

const createSocialShare = (
  args: types.CreateSocialShareArgs,
  request: SuperAgentStatic,
): Promise<types.CreateSocialShareResult> => {
  const { embedVideoId, platform, sharePostUrl } = args;
  return request
    .post(`${BASE_PATH}/${embedVideoId}/social-share`)
    .send({
      platform,
      sharePostUrl,
    })
    .then(res => res.body);
};

async function getSocialShare(
  args: types.GetSocialShareArgs,
  request: SuperAgentStatic,
): Promise<types.GetSocialShareResult> {
  const [socialShareId] = args;
  const { body } = await request.get(
    `${BASE_PATH}/social-share/${socialShareId}`,
  );

  return normalize({ ...body, id: socialShareId }, socialShareSchema);
}

const getEmbedVideos = (
  args: types.GetEmbedVideosArgs,
  request: SuperAgentStatic,
): Promise<types.GetEmbedVideosResult> => {
  const [videoId] = args;

  return request
    .get(`${BASE_PATH}/`)
    .query({
      id: videoId,
    })
    .then(({ body }) => normalize(body.data, [exportSchema]));
};

const createEmbedVideoSocialPostCaptions = (
  args: types.CreateEmbedVideoSocialPostCaptionsArgs,
  request: SuperAgentStatic,
): Promise<types.CreateEmbedVideoSocialPostCaptionsResult> => {
  const [videoId] = args;

  return request
    .post(`${BASE_PATH}/${videoId}/social-post-caption-job`)
    .then(res => res.body);
};

async function getEmbedVideoSocialPostCaptions(
  [videoId]: types.GetEmbedVideoSocialPostCaptionsArgs,
  request: SuperAgentStatic,
): Promise<types.GetEmbedVideoSocialPostCaptionsResult> {
  const res = await request.get(
    `${BASE_PATH}/${videoId}/social-post-caption-job`,
  );

  return normalize(res.body, embedVideoSocialPostCaptionsSchema);
}
const getEmbedVideoSocialPostCaptionsJob = (
  args: types.GetEmbedVideoSocialPostCaptionsJobArgs,
  request: SuperAgentStatic,
): Promise<types.GetEmbedVideoSocialPostCaptionsResult> => {
  const [videoId, jobId] = args;

  return request
    .get(`${BASE_PATH}/${videoId}/social-post-caption-job/${jobId}`)
    .then(res => normalize(res.body, embedVideoSocialPostCaptionsSchema));
};

export const handle: types.IHandle = (
  method: types.ServiceMethod,
  args: any,
  token?: string,
): Promise<types.ServiceResults> => {
  const request = createRequest({
    token,
    baseUrl: SPAREMIN_SERVICES_EMBEDVIDEO_URL,
    omitBearerPrefix: true,
  });

  switch (method) {
    case types.ServiceMethod.QUEUE_VIDEO_CREATE:
      return queueVideoCreate(args, request);

    case types.ServiceMethod.GET_VIDEO_CREATE_STATUS:
      return getCreateStatus(args, request);

    case types.ServiceMethod.SOCIAL_SHARE_CREATE:
      return createSocialShare(args, request);

    case types.ServiceMethod.GET_EMBED_VIDEOS:
      return getEmbedVideos(args, request);

    case types.ServiceMethod.GET_SOCIAL_SHARE:
      return getSocialShare(args, request);

    case types.ServiceMethod.CREATE_EMBED_VIDEO_SOCIAL_POST_CAPTIONS:
      return createEmbedVideoSocialPostCaptions(args, request);

    case types.ServiceMethod.GET_EMBED_VIDEO_SOCIAL_POST_CAPTIONS:
      return getEmbedVideoSocialPostCaptions(args, request);

    case types.ServiceMethod.GET_EMBED_VIDEO_SOCIAL_POST_CAPTIONS_JOB:
      return getEmbedVideoSocialPostCaptionsJob(args, request);

    default:
      throw new Error(`${types.ACTION_KEY} cannot handle method ${method}`);
  }
};

import cn from 'classnames';
import * as React from 'react';

import { Omit } from 'types';
import VideoTemplateEditorContextProvider, {
  VideoTemplateEditorContextProviderProps,
} from './context';
import IntegrationsUi from './integrations/IntegrationsUi';
import KeyHandler from './KeyHandler';
import ModalManager from './ModalManager';
import OptionsPane, { OptionsPaneProps } from './OptionsPane';
import PreviewPane from './PreviewPane';
import {
  CustomizeStepBrandingOptions,
  TemplateAction,
  VideoTemplateState,
} from './types';
import useTemplateDirty, { UseTemplateDirtyConfig } from './useTemplateDirty';
import { block } from './utils';

type PickedOptionsProps = Pick<OptionsPaneProps, 'ctaLabel' | 'renderCta'>;

export interface VideoTemplateEditorProps
  extends PickedOptionsProps,
    Omit<VideoTemplateEditorContextProviderProps, 'children'> {
  brandingOptions?: CustomizeStepBrandingOptions;
  className?: string;
  initialDirtyState?: UseTemplateDirtyConfig['initialState'];
  showControls?: boolean;
  onCtaClick?: (isTemplateDirty?: boolean) => void;
}

/*
 * root component for the VideoTemplateEditor
 */
const VideoTemplateEditor: React.FC<VideoTemplateEditorProps> = ({
  addTextPlaceholder,
  audioClipDurationSec,
  brandingOptions,
  className,
  ctaLabel,
  disabled,
  features,
  initialDirtyState,
  integrations,
  loading,
  onChange,
  onCtaClick,
  renderCta,
  videoType,
  showControls,
  state,
}) => {
  const {
    isTemplateDirty,
    checkChildViewWasOpened,
    resetTemplateDirtyState,
  } = useTemplateDirty({
    initialState: initialDirtyState,
    state: state.present,
  });

  const handleChange = React.useCallback(
    (
      stateUpdater: (prevState: VideoTemplateState) => VideoTemplateState,
      action: TemplateAction,
    ) => {
      checkChildViewWasOpened(action);

      onChange(stateUpdater, action);
    },
    [checkChildViewWasOpened, onChange],
  );

  const handleCtaClick = React.useCallback(() => {
    onCtaClick?.(isTemplateDirty);
  }, [isTemplateDirty, onCtaClick]);

  return (
    <div className={cn(block(), className)}>
      <VideoTemplateEditorContextProvider
        {...{
          addTextPlaceholder,
          audioClipDurationSec,
          disabled,
          features,
          integrations,
          loading,
          state,
        }}
        onChange={handleChange}
      >
        <ModalManager>
          <KeyHandler />
          <PreviewPane videoType={videoType} />
          <OptionsPane
            {...{
              brandingOptions,
              ctaLabel,
              onCtaClick: handleCtaClick,
              renderCta,
              state,
              resetTemplateDirtyState,
              isTemplateDirty,
              videoType,
              showControls,
            }}
          />
          <IntegrationsUi />
        </ModalManager>
      </VideoTemplateEditorContextProvider>
    </div>
  );
};

export default VideoTemplateEditor;

import * as React from 'react';

import VideoPlayer from 'components/VideoPlayer';
import { addSrcParamToUrl } from 'utils/url';
import { block } from '../utils';

export interface DownloadPreviewSectionProps {
  aspectRatioName?: string;
  videoUrl: string;
  onContextMenu?: React.MouseEventHandler<HTMLDivElement>;
}

const DownloadPreviewSection: React.SFC<DownloadPreviewSectionProps> = ({
  videoUrl,
  onContextMenu,
}) => {
  const uniqueUrl = addSrcParamToUrl(videoUrl);

  return (
    <div className={block('preview-section')} {...{ onContextMenu }}>
      <VideoPlayer src={uniqueUrl} />
    </div>
  );
};

export default DownloadPreviewSection;

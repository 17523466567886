import { connect } from 'react-redux';

import { onFileUpload, onWatchInfoVideo } from 'redux/modules/mixpanel';
import { fullEpisodeDurationLimitHoursSelector } from 'redux/modules/pricing';
import { Dispatch, State } from 'redux/types';
import { getAudioMaxDurationSeconds } from 'utils/audiopicker';
import { DropZoneType } from 'utils/constants';
import { createChainedFunction } from 'utils/functions';
import AudiogramAddAudioStep, {
  AudiogramAddAudioStepProps,
} from './AudiogramAddAudioStep';

type StateProps = Pick<AudiogramAddAudioStepProps, 'maxFileDurationSeconds'>;
type DispatchProps = Pick<
  AudiogramAddAudioStepProps,
  'onAudioAdded' | 'onSampleVideoPlay'
>;

type OwnProps = Pick<AudiogramAddAudioStepProps, 'onAudioAdded'>;

const mapStateToProps = (state: State): StateProps => {
  return {
    maxFileDurationSeconds: getAudioMaxDurationSeconds(
      fullEpisodeDurationLimitHoursSelector(state),
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onAudioAdded: createChainedFunction(props.onAudioAdded, src => {
    if (src instanceof File) {
      dispatch(
        onFileUpload({
          dropZoneType: DropZoneType.AUDIOGRAM_AUDIO,
          source: 'localFile',
          file: src,
        }),
      );
    }
  }),
  onSampleVideoPlay: () => dispatch(onWatchInfoVideo('audiogram')),
});

const component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudiogramAddAudioStep);
export type AudiogramAddAudioProps = React.ComponentPropsWithoutRef<
  typeof component
>;
export default component;

import { useStaticCallback } from '@sparemin/blockhead';
import { useEffect } from 'react';
import { EDDY_TRUSTED_ORIGINS } from '../constants';

export default function useMessageHandler<T>(
  handler: (message: MessageEvent<T>) => void,
) {
  const callback = useStaticCallback(handler);

  useEffect(() => {
    const handleMessage = (e: MessageEvent<T>) => {
      if (EDDY_TRUSTED_ORIGINS.includes(e.origin)) {
        callback(e);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [callback]);
}

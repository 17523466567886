import { fromJS } from 'immutable';
import * as request from 'superagent';

import { APP_VERSION } from 'config';
import { summarize } from 'redux/middleware/api/keyword-extractor-service/actions';
import { uploadText } from 'redux/middleware/api/media-upload-service/actions';
import * as commonActions from 'redux/modules/common/actions';
import { defaultLogoUrlSelector } from 'redux/modules/display-pref/selectors';
import { createEmbedConfiguration } from 'redux/modules/embed/actions/embed';
import {
  createManualTranscript,
  rechunkTranscript,
} from 'redux/modules/embed/actions/transcript';
import { postNewProject } from 'redux/modules/project/actions';
import { uploadWizardAudio } from 'redux/modules/recording-upload/actions';
import { ThunkAction } from 'redux/types';
import { AspectRatioName } from 'types';
import { getAspectRatio } from 'utils/aspect-ratio';
import { exportAudio } from 'utils/audio';
import { getValue, omitUndefined } from 'utils/collections';
import {
  DEFAULT_PROJECT_NAME,
  MINIMUM_AUDIO_DURATION_MILLIS,
} from 'utils/constants';
import { createAudioInfoFromRecording } from 'utils/embed/audio';
import {
  convertCaptionToOverlay,
  formatCaptionsTemplate,
} from 'utils/embed/captions';
import { createVersionInfo } from 'utils/embed/embed';
import { getSlideshowFromRecommendation } from 'utils/embed/slideshow';
import { formatOverlayForConfig } from 'utils/embed/text-overlay';
import { DEFAULT_TRACK_ORDER, defaultTrackIndex } from 'utils/embed/tracks';
import { getVideoClipsFromRecommendation } from 'utils/embed/video';
import { formatWatermarkSrcForConfig } from 'utils/embed/watermark';
import { round } from 'utils/numbers';
import { cancelReduxPoll } from 'utils/redux-poll';
import { getTemplate } from 'utils/text-templates';
import { flattenTranscript } from '../embed/utils';
import {
  manualTranscriptsSelector,
  recordingsSelector,
} from '../entities/selectors';
import { Type } from './action-types';
import {
  aspectRatioNameSelector,
  aspectRatioSelector,
  textBlobIdSelector,
  textBlobSelector,
  textBlobTranscriptUrlSelector,
  transcriptIdsSelector,
  urlSummaryIdSelector,
  videoDurationMillisSelector,
  widgetIdSelector,
} from './selectors';
import {
  CreateConfigurationSuccessAction,
  CreateProjectSuccessAction,
  ProcessTranscriptSuccessAction,
  SummarizeSuccessAction,
  UploadTextSuccessAction,
} from './types';

const ANALYZE_POLL_ID = 'app/text-wizard/analyze-poll';
const DEFAULT_TEXT_2_INDEX = defaultTrackIndex('text', 1);

export const summarizeUrl = (
  url: string,
): ThunkAction<Promise<SummarizeSuccessAction>> => dispatch => {
  dispatch({ type: Type.TEXT_WIZARD_SUMMARIZE_REQUEST });
  return dispatch(summarize(url))
    .then(({ response }) => {
      const id = response.result;
      const details = response.entities.summaries[id].summaryDetails;
      // if summary comes back empty, treat it as an error
      if (details.length === 0) {
        throw new Error('empty summary');
      }
      return dispatch({
        payload: { urlSummaryId: id },
        type: Type.TEXT_WIZARD_SUMMARIZE_SUCCESS,
      } as SummarizeSuccessAction);
    })
    .catch(err => {
      dispatch({ type: Type.TEXT_WIZARD_SUMMARIZE_FAILURE });
      throw err;
    });
};

const uploadWizardText = (
  text: string,
): ThunkAction<Promise<UploadTextSuccessAction>> => (dispatch, getState) => {
  dispatch({ type: Type.TEXT_WIZARD_TEXT_UPLOAD_REQUEST });

  const urlSummaryId = urlSummaryIdSelector(getState());

  return dispatch(uploadText(text, urlSummaryId))
    .then(({ response }) =>
      dispatch({
        payload: { textBlobId: response.result },
        type: Type.TEXT_WIZARD_TEXT_UPLOAD_SUCCESS,
      } as UploadTextSuccessAction),
    )
    .then(res => {
      const textBlob = textBlobSelector(getState());
      if (textBlob.get('status') === 'failed') {
        throw new Error('Error uploading text.');
      }
      if (
        ['failed', 'failureAcked'].indexOf(
          textBlob.getIn(['transcript', 'status']),
        ) >= 0
      ) {
        throw new Error('Error creating transcript from text upload.');
      }
      return res;
    });
};

function extractManualTranscriptIds({ response }) {
  const id = response.result;
  return {
    revisionId: getValue(response, [
      'entities',
      'manualTranscripts',
      id,
      'revisionId',
    ]),
    transcriptId: id,
  };
}

const processTranscript = (): ThunkAction<Promise<
  ProcessTranscriptSuccessAction
>> => (dispatch, getState) => {
  dispatch({ type: Type.TEXT_WIZARD_TRANSCRIPT_PROCESS_REQUEST });

  const url = textBlobTranscriptUrlSelector(getState());

  const futManualTranscriptId = request.get(url).then(({ body }) => {
    // make the transcript immutable because that's what createManualTranscript expects
    const transcript = fromJS(body);

    if (!transcript || transcript.get('transcript').size === 0) {
      return Promise.resolve();
    }

    return dispatch(createManualTranscript(transcript)).then(
      extractManualTranscriptIds,
    );
  });

  return futManualTranscriptId
    .then(({ transcriptId }) =>
      dispatch(rechunkTranscript(70, transcriptId, 50)),
    )
    .then(res =>
      dispatch({
        payload: { ...extractManualTranscriptIds(res) },
        type: Type.TEXT_WIZARD_TRANSCRIPT_PROCESS_SUCCESS,
      } as ProcessTranscriptSuccessAction),
    );
};

const uploadAudio = (audioUrl: string): ThunkAction<Promise<string>> => (
  dispatch,
  getState,
) => {
  if (!audioUrl) return Promise.resolve();

  return (
    exportAudio({ source: audioUrl })
      .then(clipped => new Blob([clipped], { type: 'audio/wav' }))
      .then(blob => dispatch(uploadWizardAudio(blob)))
      .then(({ response }) => {
        const recordings = recordingsSelector(getState());
        return recordings.get(response.recording.toString());
      })
      /*
       * swallow errors and return a successful promise.  if something related to audio fails, the
       * user can try again in the editor
       */
      .catch(() => undefined)
  );
};

const analyzeText = (): ThunkAction<Promise<any>> => (dispatch, getState) => {
  dispatch({ type: Type.TEXT_WIZARD_ANALYZE_REQUEST });

  const aspectRatio = aspectRatioSelector(getState());
  const id = textBlobIdSelector(getState());

  return dispatch(
    commonActions.analyzeText(id, aspectRatio, true, ANALYZE_POLL_ID),
  ).then(({ response }) => {
    dispatch({ type: Type.TEXT_WIZARD_ANALYZE_SUCCESS });
    return response.typedRecommendation;
  });
};

const createTextOverlaysFromCaptions = (): ThunkAction<Array<
  ReturnType<typeof formatOverlayForConfig>
>> => (__, getState) => {
  const { transcriptId } = transcriptIdsSelector(getState());
  const transcripts = manualTranscriptsSelector(getState());
  const transcript = transcripts.get(transcriptId);

  const flattenedTranscript = flattenTranscript(transcript);

  const aspectRatioName = aspectRatioNameSelector(getState());
  const textTemplate = fromJS(getTemplate('nowthis', aspectRatioName))
    .setIn(['containerStyle', 'fontSize'], 24)
    .setIn(['containerStyle', 'textAlign'], 'left');
  const captionsTemplate = formatCaptionsTemplate(textTemplate);

  return flattenedTranscript
    .valueSeq()
    .toArray()
    .map(phrase => {
      const overlay = convertCaptionToOverlay(phrase, captionsTemplate, 0);

      const o = overlay.set('text', phrase.get('text'));

      return formatOverlayForConfig(o, DEFAULT_TEXT_2_INDEX);
    });
};

const createConfiguration = (
  recommendation: any,
  recording: any,
): ThunkAction<Promise<CreateConfigurationSuccessAction>> => (
  dispatch,
  getState,
) => {
  dispatch({ type: Type.TEXT_WIZARD_CONFIGURATION_CREATE_REQUEST });

  const aspectRatioName = aspectRatioNameSelector(getState());
  const durationMillis = videoDurationMillisSelector(getState());

  const slideshowInfo = getSlideshowFromRecommendation(
    recommendation,
    aspectRatioName,
  );

  const videoClips = getVideoClipsFromRecommendation(
    recommendation,
    aspectRatioName,
  );

  const versionInfo = createVersionInfo(APP_VERSION);

  const watermark = formatWatermarkSrcForConfig(
    defaultLogoUrlSelector(getState()),
    durationMillis,
  );

  const textOverlayInfo = dispatch(createTextOverlaysFromCaptions());

  const config = omitUndefined({
    slideshowInfo,
    textOverlayInfo,
    versionInfo,
    videoClips,
    audioInfo: !recording
      ? undefined
      : [
          createAudioInfoFromRecording(recording, {
            durationMilli: round(
              Math.max(durationMillis, MINIMUM_AUDIO_DURATION_MILLIS),
              -3,
            ),
          }),
        ],
    dimensions: getAspectRatio(aspectRatioName).toJS(),
    expectedDurationMilli: durationMillis,
    layerOrder: DEFAULT_TRACK_ORDER,
    watermark: watermark && [watermark],
  });

  return dispatch(createEmbedConfiguration(config)).then(({ response }) =>
    dispatch({
      payload: {
        widgetId: getValue(response, ['configuration', 'wid']),
      },
      type: Type.TEXT_WIZARD_CONFIGURATION_CREATE_SUCCESS,
    }),
  );
};

const createProject = (): ThunkAction<Promise<CreateProjectSuccessAction>> => (
  dispatch,
  getState,
) => {
  dispatch({ type: Type.TEXT_WIZARD_PROJECT_CREATE_REQUEST });

  const wid = widgetIdSelector(getState());
  const aspectRatio = aspectRatioSelector(getState());

  return dispatch(
    postNewProject(wid, DEFAULT_PROJECT_NAME, aspectRatio, 'textToVideo'),
  ).then(({ response }) =>
    dispatch({
      payload: { projectId: response.result },
      type: Type.TEXT_WIZARD_PROJECT_CREATE_SUCCESS,
    }),
  );
};

export const createTextProject = (
  text: string,
  aspectRatioName: AspectRatioName,
  audioUrl?: string,
): ThunkAction<Promise<CreateProjectSuccessAction>> => (dispatch, getState) => {
  dispatch({
    payload: {
      aspectRatioName,
      aspectRatio: getAspectRatio(aspectRatioName).toJS(),
    },
    type: Type.TEXT_WIZARD_CREATE_REQUEST,
  });

  const futTranscript = dispatch(uploadWizardText(text)).then(() =>
    dispatch(processTranscript()),
  );
  const futDurationMillis = futTranscript.then(() =>
    videoDurationMillisSelector(getState()),
  );
  const futRecording = futDurationMillis.then(() =>
    dispatch(uploadAudio(audioUrl)),
  );
  const futRecommendation = futTranscript.then(() => dispatch(analyzeText()));

  return Promise.all([futRecommendation, futRecording])
    .then(([recommendation, recording]) =>
      dispatch(createConfiguration(recommendation, recording)),
    )
    .then(() => dispatch(createProject()));
};

export const clearTextWizardState = () => ({ type: Type.TEXT_WIZARD_CLEAR });

export const cancelPolling = (): ThunkAction<void> => dispatch =>
  cancelReduxPoll(dispatch, ANALYZE_POLL_ID);

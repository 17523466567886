import { OverlayContainer, OverlayContainerProps } from '@sparemin/blockhead';
import React from 'react';

export type PopoverDOMProps = Pick<OverlayContainerProps, 'portalContainer'> & {
  children?: React.ReactNode;
  inline?: boolean;
};

const PopoverDOM: React.FC<PopoverDOMProps> = ({
  children,
  inline,
  portalContainer,
}) =>
  inline ? (
    <>{children}</>
  ) : (
    <OverlayContainer portalContainer={portalContainer}>
      {children}
    </OverlayContainer>
  );

export default PopoverDOM;

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'underscore';

import LoadingOverlay from 'components/LoadingOverlay';
import Modal from 'components/Modal';
import useOnMount from 'hooks/useOnMount';
import {
  loadMyPricingData,
  updateSubscription,
} from 'redux/modules/pricing/actions';
import { Dispatch } from 'redux/types';
import { SubscriptionPeriod } from '../../types';
import BillingCycleOptions, {
  BillingCycleOptionsProps,
} from './BillingCycleOptions';
import { block } from './utils';

const { useCallback } = React;

type PickedOptionsProps = Pick<BillingCycleOptionsProps, 'options'>;

export type SubmitType = {
  name: 'MainCTA' | 'PayNow';
  isPayNowButtonVisible: boolean;
};

type OnSubmit = () => Promise<void>;

export interface BillingCycleModalRenderProps {
  billingCycleSelector: JSX.Element;
  loadingOverlay: JSX.Element;
  bodyClassName: string;
  onSubmit: (
    event?: React.MouseEvent,
    submitType?: SubmitType,
  ) => Promise<void>;
  footer: JSX.Element;
}

export interface BillingCycleModalProps extends PickedOptionsProps {
  children?: (props: BillingCycleModalRenderProps) => JSX.Element;
  disabled?: boolean;
  loadingMessage?: string;
  onSubmit?: OnSubmit;
  onSubscriptionPeriodChange?: (period: SubscriptionPeriod) => void;
  subscriptionPeriod: SubscriptionPeriod;
  onHide?: (value?: any) => void;
  onError?: () => void;
  onSaved?: (period: SubscriptionPeriod, submitType?: SubmitType) => void;
}

const defaultChildren = (props: BillingCycleModalRenderProps) => {
  const { billingCycleSelector, loadingOverlay, bodyClassName, footer } = props;
  return (
    <>
      <Modal.Body className={bodyClassName}>
        {loadingOverlay}
        {billingCycleSelector}
      </Modal.Body>
      {footer}
    </>
  );
};

const BillingCycleModalContent: React.SFC<BillingCycleModalProps> = ({
  children = defaultChildren,
  disabled,
  loadingMessage = 'Processing Payment',
  onSubmit = noop,
  onSubscriptionPeriodChange = noop,
  options,
  subscriptionPeriod,
  onHide = noop,
  onError,
  onSaved,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const handleSubPeriodChange = useCallback(
    (period: SubscriptionPeriod) => {
      onSubscriptionPeriodChange(period);
    },
    [onSubscriptionPeriodChange],
  );

  useOnMount(() => handleSubPeriodChange(subscriptionPeriod));

  const billingCycleSelector = (
    <BillingCycleOptions
      disabled={disabled}
      onChange={handleSubPeriodChange}
      options={options}
      value={subscriptionPeriod}
    />
  );

  const handleSubmit = async (
    _event?: React.MouseEvent,
    submitType?: SubmitType,
  ) => {
    const planName = options[subscriptionPeriod];

    try {
      await onSubmit();
      await dispatch(updateSubscription(planName, true));
      await dispatch(loadMyPricingData());
      onSaved(subscriptionPeriod, submitType);
    } catch {
      onError();
    }
  };

  return children({
    billingCycleSelector,
    bodyClassName: block('body'),
    loadingOverlay: disabled && <LoadingOverlay title={loadingMessage} />,
    onSubmit: handleSubmit,
    footer: (
      <Modal.Footer>
        <Modal.FooterButton
          fluid
          className={block('cancel-button')}
          disabled={disabled}
          onClick={onHide}
        >
          cancel
        </Modal.FooterButton>
        <Modal.FooterButton
          fluid
          disabled={disabled}
          theme="submit"
          onClick={handleSubmit}
        >
          submit
        </Modal.FooterButton>
      </Modal.Footer>
    ),
  });
};

export default BillingCycleModalContent;

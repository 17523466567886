import React from 'react';
import FontAwesome from 'components/FontAwesome';
import {
  ClosedCaptioning,
  Font,
  Headset,
  HourglassSand,
  IntroOutro,
  Microphone,
  Resolution,
  SaveAsTemplate,
  Stamp,
  Upload,
  VideoCamera,
} from 'components/icons';
import { DeepPartial } from 'types';
import merge from 'utils/deepmerge';
import NumberValue from './NumberValue';
import { PlanFeatureProps } from './PlanFeature';

type FeatureId =
  | 'automation'
  | 'captions'
  | 'resolution'
  | 'videoUpload'
  | 'audioFiles'
  | 'exportSpeed'
  | 'fonts'
  | 'fullTranscript'
  | 'introOutro'
  | 'share'
  | 'startAutomations'
  | 'templates'
  | 'support'
  | 'unwatermarkedVideos'
  | 'watermarkedVideos';

type FeatureSet = Record<FeatureId, PlanFeatureProps & { id: FeatureId }>;

// this list is equivalent to Unlimited tier access
const allFeatures: FeatureSet = {
  automation: {
    icon: <FontAwesome icon="magic" />,
    id: 'automation',
    label: 'Auto-create audiograms for multiple podcasts',
    tooltip:
      'Link a video template to a podcast to create videos automatically.',
  },
  captions: {
    icon: <ClosedCaptioning />,
    id: 'captions',
    label: 'Unlimited captions per month (10 min per project)',
    tooltip: 'Add closed captions to audio or video.',
  },
  exportSpeed: {
    icon: (
      <FontAwesome icon="clock" regular style={{ width: 13, height: 'auto' }} />
    ),
    id: 'exportSpeed',
    label: 'Faster video exports',
    tooltip: 'You’ll be at the front of the line when exporting videos.',
  },
  fonts: {
    icon: <Font style={{ width: '13px' }} />,
    id: 'fonts',
    label: 'Use your own fonts',
    tooltip:
      'Add any custom fonts to your account to stay consistent with your branding.',
  },
  resolution: {
    icon: <Resolution />,
    id: 'resolution',
    label: '1080p resolution for videos up to 2 hours long',
    tooltip: 'Create 1080p full HD videos.',
  },
  videoUpload: {
    icon: <Upload />,
    id: 'videoUpload',
    label: '5GB video uploads',
    tooltip: 'Add and edit video files that are up to 5GB in file size.',
  },
  audioFiles: {
    icon: <Microphone style={{ width: '13px' }} />,
    id: 'audioFiles',
    label: 'Use 2 hour audio files',
    tooltip: 'Create audiograms from audio files up to 2 hours long.',
  },
  fullTranscript: {
    icon: <FontAwesome icon="quote-left" />,
    id: 'fullTranscript',
    label: 'Transcribe full episodes of any podcast (15 hours per month)',
    tooltip: 'Transcripts help improve a podcast’s SEO and accessibility.',
  },
  introOutro: {
    icon: <IntroOutro />,
    id: 'introOutro',
    label: 'Custom intro & outro videos',
    tooltip: 'Easily add any branded intros and outros to your videos.',
  },
  share: {
    icon: <FontAwesome icon="share" />,
    id: 'share',
    label: 'Auto-post to social media',
    tooltip: 'Post to social networks like YouTube directly from Headliner.',
  },
  startAutomations: {
    icon: <HourglassSand style={{ width: '13px' }} />,
    id: 'startAutomations',
    label: 'Start automations from the first episode of a podcast',
    tooltip: 'Choose which episode to start auto-creating & auto-posting from.',
  },
  templates: {
    icon: <SaveAsTemplate width="100%" />,
    id: 'templates',
    label: 'Make branded templates',
    tooltip: 'Make branded templates.',
  },
  support: {
    icon: <Headset />,
    id: 'support',
    label: 'Dedicated support person',
    tooltip: 'Exclusive access to immediate support, tailored to your needs.',
  },
  unwatermarkedVideos: {
    icon: <VideoCamera />,
    id: 'unwatermarkedVideos',
    label: <NumberValue value={Infinity}>Unlimited videos</NumberValue>,
    tooltip: 'Removes all Headliner watermarks.',
  },
  watermarkedVideos: {
    icon: <Stamp style={{ width: 13, height: 'auto' }} />,
    id: 'watermarkedVideos',
    label: 'Custom watermarks',
    tooltip:
      'Add your own watermark to your videos to add that professional touch.',
  },
};

export const unlimitedFeatures = allFeatures;

export const proFeatures: FeatureSet = merge(unlimitedFeatures, {
  audioFiles: {
    label: 'Use 4 hour audio files',
    tooltip: 'Create audiograms from audio files up to 4 hours long.',
  },
  onboarding: {
    disabled: true,
  },
  support: {
    label: 'Priority support',
    tooltip: 'You’ll be at the front of the line when contacting support.',
  },
} as DeepPartial<FeatureSet>);

export const basicFeatures: FeatureSet = merge(proFeatures, {
  resolution: {
    icon: <Resolution />,
    id: 'resolution',
    label: '1080p resolution for videos up to 10 min long',
    tooltip: 'Create 1080p full HD videos.',
  },
  audioFiles: {
    label: 'Use 2 hour audio files',
    tooltip: 'Create audiograms from audio files up to 2 hours long.',
  },
  startAutomations: {
    disabled: true,
  },
  fullTranscript: {
    disabled: true,
  },
  support: {
    disabled: true,
  },
  unwatermarkedVideos: {
    label: <NumberValue value={10}>Unwatermarked videos per month</NumberValue>,
    tooltip:
      'After your tenth video of the month we’ll add a Headliner watermark.',
  },
  watermarkedVideos: {
    label: 'Unlimited watermarked videos',
    tooltip:
      "Create as many videos as you want! After your tenth of the month we'll add a Headliner watermark.",
  },
} as DeepPartial<FeatureSet>);

export const freeFeatures: FeatureSet = merge(basicFeatures, {
  automation: {
    label: 'Auto-create audiograms for 1 podcast',
    tooltip:
      'Link a video template to a podcast to create videos automatically.',
  },
  captions: {
    label: 'Add up to 10 min of captions per month',
    tooltip: 'Add closed captions to audio or video.',
  },
  exportSpeed: {
    disabled: true,
  },
  fonts: {
    disabled: true,
  },
  introOutro: {
    disabled: true,
  },
  startAutomations: {
    disabled: true,
  },
  templates: {
    disabled: true,
  },
  unwatermarkedVideos: {
    label: <NumberValue value={1}>Unwatermarked video per month</NumberValue>,
    tooltip:
      'After your first video of the month we’ll add a Headliner watermark.',
  },
  videoUpload: {
    label: '500MB video uploads',
    tooltip: 'Add and edit video files that are up to 500MB in file size.',
  },
  watermarkedVideos: {
    tooltip:
      "Create as many videos as you want! After your fith of the month we'll add a Headliner watermark.",
  },
} as DeepPartial<FeatureSet>);

const featureOrder: FeatureId[] = [
  'unwatermarkedVideos',
  'watermarkedVideos',
  'captions',
  'resolution',
  'videoUpload',
  'audioFiles',
  'automation',
  'share',
  'templates',
  'introOutro',
  'fonts',
  'exportSpeed',
  'startAutomations',
  'fullTranscript',
  'support',
];

export const unlimitedFeatureList = featureOrder.map(
  id => unlimitedFeatures[id],
);
export const proFeatureList = featureOrder.map(id => proFeatures[id]);
export const basicFeatureList = featureOrder.map(id => basicFeatures[id]);
export const freeFeatureList = featureOrder.map(id => freeFeatures[id]);

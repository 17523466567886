import { CropperSides } from 'components/Cropper/types';
import { StaticCrop } from 'redux/modules/embed/types';
import { CropMetadata, Size } from 'types';
import { IDimensions } from 'utils/embed/video';
import { getScaleFactors } from 'utils/placement';

/**
 * Scales cropping positions from the dimensions of a target video to the origin video.
 *
 * This function dynamically calculates the corresponding cropping positions on
 * the origin video based on the crop object and the relative dimensions of the
 * target video. It ensures that the scaling is accurately applied to both the
 * X and Y axes, maintaining the same cropping area proportion on the origin video.
 *
 * @param {CropMetadata} cropMetadata - The crop object containt the X and Y
 * coordinates as well as the crop's width and height.
 * @param {IDimensions} origin - The dimensions of the origin video.
 * @param {IDimensions} target - The dimensions of the target video.
 *
 * @returns Translated cropping positions for the origin video
 */
export function scaleCropDimensions(
  crop: CropMetadata['crop'],
  origin: IDimensions,
  target: IDimensions,
): CropperSides {
  const scaleX = origin.width / target.width;
  const scaleY = origin.height / target.height;

  return {
    left: crop.x * scaleX,
    top: crop.y * scaleY,
    right: (crop.x + crop.width) * scaleX,
    bottom: (crop.y + crop.height) * scaleY,
  };
}

export function getScaledCropDimensions(
  videoCrop: StaticCrop,
  originalVideoSize: IDimensions,
  crop?: CropMetadata['crop'],
): Partial<CropMetadata['crop']> {
  const { left, top, right, bottom } = videoCrop;
  const { width, height } = crop ?? {};

  const { scaleX, scaleY } = getScaleFactors(
    {
      width,
      height,
    },
    originalVideoSize,
  );

  return {
    width: Math.abs(right - left) / scaleX,
    height: Math.abs(top - bottom) / scaleY,
    x: left / scaleX,
    y: top / scaleY,
  };
}

export function transformVideoDimensions(
  transformedDimensions: Size<number>,
  originalDimensions: Size<number>,
  multipliers?: Size<number>,
): Size<number> {
  const {
    width: transformedWidth,
    height: transformedHeight,
  } = transformedDimensions;
  const { width: originalWidth, height: originalHeight } = originalDimensions;
  const { width: widthMultiplier = 100, height: heightMultiplier = 100 } =
    multipliers ?? {};

  return {
    width: (transformedWidth / originalWidth) * widthMultiplier,
    height: (transformedHeight / originalHeight) * heightMultiplier,
  };
}

export function calculateFinalVideoDimensions(
  defaultDimensions?: Size<number>,
  newDimensions?: Size<number>,
  staticCrop?: StaticCrop,
  videoFileSize?: Size<number>,
): Size<number> {
  if (!staticCrop) {
    return defaultDimensions;
  }

  const { left, top, right, bottom } = staticCrop ?? {};

  const { scaleX, scaleY } = getScaleFactors(videoFileSize, {
    width: Math.abs(left - right),
    height: Math.abs(top - bottom),
  });

  return {
    width: newDimensions?.width * scaleX,
    height: newDimensions?.height * scaleY,
  };
}

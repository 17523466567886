import React from 'react';

import {
  DESTINATION_PRESETS,
  DestinationConfig,
} from 'blocks/DestinationPlatforms';
import { YouTubeSocialCircle } from 'components/SocialCircle';

import VideoTypeIcon from '../VideoTypeIcon';

const HEADLINER_GRADIENT = 'linear-gradient(135deg, #5dbefc, #dd92fe)';

const youtube: DestinationConfig = {
  id: 'youtube',
  icon: <YouTubeSocialCircle className="white-background" />,
  videoTypes: [
    {
      id: 'episode',
      title: 'Full episode',
      description: 'Add an entire podcast episode',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.youtube.episode.aspectRatio}
          background={HEADLINER_GRADIENT}
        />
      ),
    },
    {
      id: 'clip',
      title: 'Episode clip',
      description: 'Clip a full segment from an episode',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.youtube.clip.aspectRatio}
          background={HEADLINER_GRADIENT}
        />
      ),
    },
    {
      id: 'short',
      title: 'YouTube Short',
      description: 'Share a short clip to go viral',

      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.youtube.short.aspectRatio}
          background={HEADLINER_GRADIENT}
        />
      ),
    },
  ],
};

export default youtube;

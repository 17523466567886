import { fromJS } from 'immutable';
import React from 'react';

import { convertOverlayToEditorData } from 'blocks/TextOverlayModal/utils';
import { ImmutableTextPreset } from 'redux/modules/text-presets';

import useTextOverlayExtensions from '../extensions/useTextOverlayExtensions';
import useTextOverlayFont from '../hooks/useTextOverlayFonts';
import applyMigrations from '../migrations';
import { getInitialOverlay } from '../style-import-utils';
import { EditorDataV2, Size } from '../types';
import {
  EditorContainerAdvancedTextConfigSetter,
  EditorContainerStyleSetter,
  EditorOverlaySetter,
  TextOverlayProviderProps,
  TextOverlayState,
  UseTextOverlay,
} from './types';

const TextOverlayStateContext = React.createContext<TextOverlayState | null>(
  null,
);

const TextOverlayProvider: React.FunctionComponent<TextOverlayProviderProps> = props => {
  const {
    aspectRatio,
    children,
    defaultText,
    extensions = {},
    show,
    textOverlay,
  } = props;

  const editorHtmlRef = React.useRef<HTMLDivElement>(null);

  const [prevShow, setPrevShow] = React.useState(false);
  const [baseTextValue, setBaseTextValue] = React.useState(defaultText);
  const [draftEditorData, setDraftEditorData] = React.useState<EditorDataV2>();
  const [selectedPreset, setSelectedPreset] = React.useState<
    ImmutableTextPreset | undefined
  >();
  const [canvasSize, setCanvasSize] = React.useState<Size>({
    height: 1,
    width: 1,
  });
  const [initialEditorData, setInitialEditorData] = React.useState<
    EditorDataV2
  >(draftEditorData);

  const { fonts } = useTextOverlayFont();

  const clearPresetSelection = React.useCallback(() => {
    setSelectedPreset(undefined);
  }, []);

  const onUpdateStyle: EditorContainerStyleSetter = React.useCallback(
    (updater, keepPresetSelection = false) => {
      if (!keepPresetSelection) {
        clearPresetSelection();
      }

      setDraftEditorData(
        draftEditorData.withMutations(s =>
          s.setIn(
            ['editor', 'textStyle'],
            updater(draftEditorData.getIn(['editor', 'textStyle'])),
          ),
        ),
      );
    },
    [clearPresetSelection, draftEditorData],
  );

  const onUpdateAdvancedTextConfig: EditorContainerAdvancedTextConfigSetter = React.useCallback(
    (updater, keepPresetSelection = false) => {
      if (!keepPresetSelection) {
        clearPresetSelection();
      }

      setDraftEditorData(
        draftEditorData.withMutations(s => {
          if (!s.get('advancedTextConfigs')) {
            s.set('advancedTextConfigs', fromJS({}));
          }

          s.set(
            'advancedTextConfigs',
            updater(draftEditorData.get('advancedTextConfigs')),
          );
        }),
      );
    },
    [clearPresetSelection, draftEditorData],
  );

  const onUpdateOverlay: EditorOverlaySetter = React.useCallback(
    (updater, keepPresetSelection = false) => {
      if (!keepPresetSelection) {
        clearPresetSelection();
      }

      setDraftEditorData(updater(draftEditorData));
    },
    [clearPresetSelection, draftEditorData],
  );

  // As the extension is used from several sub components and at separated parts
  // the extensions config is made globally available for the editor using the
  // provider.
  const {
    animationsTabExtension,
    boxSizeFitCheckerExtension,
    overlayTimingExtension,
    stylePersistorExtension,
  } = useTextOverlayExtensions({
    context: {
      baseTextValue,
      canvasSize,
      draftEditorData,
      initialEditorData,
    },
    extensions,
  });

  if (prevShow !== show) {
    setPrevShow(show);

    // Re-initializes the modal state each time it is opened
    if (show) {
      setSelectedPreset(undefined);
      setBaseTextValue(textOverlay?.get('text') || defaultText);
      const editorData = convertOverlayToEditorData(
        stylePersistorExtension.onApplyLastEditorState(
          applyMigrations(getInitialOverlay(aspectRatio, textOverlay)),
        ),
      );
      setDraftEditorData(editorData);
      setInitialEditorData(editorData);
    }
  }

  const { textValue } = boxSizeFitCheckerExtension;

  return (
    <TextOverlayStateContext.Provider
      value={React.useMemo(
        () => ({
          animationsTabExtension,
          boxSizeFitCheckerExtension,
          canvasSize,
          draftEditorData,
          editorHtmlRef,
          fonts,
          onChangeCanvasSize: setCanvasSize,
          onChangeText: setBaseTextValue,
          onSelectPreset: setSelectedPreset,
          onUpdateAdvancedTextConfig,
          onUpdateOverlay,
          onUpdateStyle,
          overlayTimingExtension,
          selectedPreset,
          stylePersistorExtension,
          textValue,
        }),
        [
          animationsTabExtension,
          boxSizeFitCheckerExtension,
          canvasSize,
          draftEditorData,
          fonts,
          onUpdateAdvancedTextConfig,
          onUpdateOverlay,
          onUpdateStyle,
          overlayTimingExtension,
          selectedPreset,
          stylePersistorExtension,
          textValue,
        ],
      )}
    >
      {children}
    </TextOverlayStateContext.Provider>
  );
};

export const useTextOverlay = (): UseTextOverlay => {
  const textOverlayState = React.useContext(TextOverlayStateContext);

  if (!textOverlayState) {
    throw new Error(
      'useTextOverlay should be used within the TextOverlayProvider context',
    );
  }

  return textOverlayState;
};

export default TextOverlayProvider;

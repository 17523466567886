import * as Immutable from 'immutable';
import { compose } from 'redux';
import _ from 'underscore';

import embedUtils from 'utils/embed';
import embedConfigService from '../../middleware/api/embed-configuration-service';
import * as types from './action-types';
import AutosaveStatus from './constants/AutosaveStatus';
import { entireAudioEnhancer } from './enhancers';

const defaultState = Immutable.Map({
  durationMillis: 0,
});

function deleteFromTracks(state, dataId) {
  state.get('tracksById').forEach((track, trackId) => {
    const index = (track.get('data') || Immutable.List()).indexOf(dataId);
    if (index >= 0) state.deleteIn(['tracksById', trackId, 'data', index]);
  });

  if (state.getIn(['selectedTrackElement', 'dataId']) === dataId) {
    state.delete('selectedTrackElement');
  }
}

function setEmbedSlides(state, slidesById) {
  const slides = Object.keys(slidesById).sort(
    (id1, id2) => slidesById[id1].startMilli - slidesById[id2].startMilli,
  );

  return state.withMutations(s =>
    s.set('slides', slides).set('slidesById', Immutable.fromJS(slidesById)),
  );
}

function updateVideos(state, videosById) {
  return state.withMutations(s =>
    s
      .set('videos', embedUtils.sortVideos(videosById))
      .set('videosById', videosById),
  );
}

function updateTextOverlays(state, textOverlayById) {
  return state.set('textOverlayById', textOverlayById);
}

function updateWatermarks(state, watermarkById) {
  return state.set('watermarkById', watermarkById);
}

function updateCaptionsConfig(state, captions) {
  return state.setIn(['captions', 'config'], captions);
}

function updateCanvasColor(state, color) {
  return state.set('canvasColor', color);
}

function updateProgressAnimation(state, progressAnimationState) {
  return state.set('progressAnimation', progressAnimationState);
}

function updateSoundwave(state, soundwave) {
  return state.set('soundwave', soundwave);
}

function updateAudio(state, audioById) {
  return state.set('audioById', audioById);
}

function updateTimer(state, timer) {
  return state.set('timer', timer);
}

const updateEdgeVideos = (state, edgeVideos) => {
  return state.set('edgeVideos', Immutable.fromJS(edgeVideos));
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.EMBED_DATA_LOAD_REQUEST:
      return state.withMutations(s =>
        s.set('recording', action.payload.recordingId).set('isLoading', true),
      );

    case types.EMBED_DATA_LOAD_FAILURE:
      return state.set('isLoading', false);

    // TODO CAN REMOVE THESE EMBED_EDITOR_LOAD*?
    case types.EMBED_EDITOR_LOAD_REQUEST:
      return defaultState.set('isLoading', true);

    case types.EMBED_EDITOR_LOAD_SUCCESS:
      return state.set('isLoading', false);

    case types.EMBED_EDITOR_READY_SET: {
      const { isReady } = action.payload;
      return state.set('isEditorReady', isReady);
    }

    case types.EMBED_DURATION_SET: {
      const { durationMillis } = action.payload;
      return state.set('durationMillis', durationMillis);
    }

    case types.EMBED_SLIDES_SET: {
      const { slides: slidesById } = action.payload;
      return setEmbedSlides(state, slidesById);
    }

    case types.EMBED_EDITOR_DATA_LOAD: {
      const {
        audioById,
        captions,
        canvasColor,
        edgeVideos,
        progressAnimation,
        slidesById,
        soundwave,
        textOverlayById,
        timer,
        videosById,
        watermarkById,
      } = action.payload;

      const setEdgeVideos = s =>
        edgeVideos ? updateEdgeVideos(s, edgeVideos) : s;
      const setSlides = s => (slidesById ? setEmbedSlides(s, slidesById) : s);
      const setTextOverlay = s =>
        textOverlayById ? updateTextOverlays(s, textOverlayById) : s;
      const setWatermarks = s =>
        watermarkById ? updateWatermarks(s, watermarkById) : s;
      const setCaptions = s =>
        captions ? updateCaptionsConfig(s, captions) : s;
      const setVideos = s => (videosById ? updateVideos(s, videosById) : s);
      const setCanvasColor = s => updateCanvasColor(s, canvasColor);
      const setProgressAnimation = s =>
        updateProgressAnimation(s, progressAnimation);
      const setSoundwave = s => (soundwave ? updateSoundwave(s, soundwave) : s);
      const setAudio = s => (audioById ? updateAudio(s, audioById) : s);
      const setTimer = s => (timer ? updateTimer(s, timer) : s);
      return compose(
        setEdgeVideos,
        setSlides,
        setTextOverlay,
        setWatermarks,
        setCaptions,
        setVideos,
        setCanvasColor,
        setProgressAnimation,
        setSoundwave,
        setAudio,
        setTimer,
      )(state);
    }

    case types.EMBED_EDITOR_LOAD_FAILURE:
      return state.set('isLoading', false);

    case types.EMBED_RECORDING_SET:
      return state.set('recording', action.payload.recordingId);

    case types.EMBED_TRACK_STATE_CLEAR:
      return state.withMutations(s => s.delete('tracks').delete('tracksById'));

    case types.EMBED_TRACK_CREATE: {
      const { data, id, index, name, type } = action.payload;

      const tracksOfType = (state.get('tracks') || Immutable.List()).filter(
        trackId => state.getIn(['tracksById', trackId, 'type']) === type,
      );
      const nTracksOfType = tracksOfType.size;

      return state
        .update('tracks', Immutable.List(), tracks => tracks.insert(index, id))
        .update('tracksById', (tracksById = Immutable.Map()) =>
          tracksById.withMutations(tbid => {
            const newTrack = { id, data, name, type };

            if (!newTrack.name) {
              if (nTracksOfType === 0) {
                newTrack.name = type;
              } else if (nTracksOfType === 1) {
                const onlyTrackOfType = tracksOfType.get(0);
                tbid.updateIn([onlyTrackOfType, 'name'], n => `${n} 1`);
                newTrack.name = `${type} 2`;
              } else {
                newTrack.name = `${type} ${nTracksOfType + 1}`;
              }
            }

            tbid.set(id, Immutable.fromJS(newTrack));
            return tbid;
          }),
        );
    }

    case types.EMBED_TRACK_DELETE: {
      const { trackId, trackData, trackType } = action.payload;

      const deleteAll = obj =>
        obj && obj.withMutations(o => trackData.forEach(id => o.delete(id)));

      return state.withMutations(s => {
        s.update('tracks', tracks => tracks.delete(tracks.indexOf(trackId)));
        s.update('tracksById', tracks => tracks.delete(trackId));

        if (trackType === 'text') {
          s.update('textOverlayById', deleteAll);
        }

        if (trackType === 'media') {
          s.update('videosById', deleteAll);
          s.update('slidesById', deleteAll);

          const slides = s.get('slides');
          if (slides) {
            s.set(
              'slides',
              slides.filter(id => trackData.indexOf(id) < 0),
            );
          }
        }

        if (trackType === 'audio') {
          s.update('audioById', deleteAll);
        }

        if (trackType === 'waveform') {
          s.delete('soundwave');
        }

        const tracksOfType = s
          .get('tracks')
          .filter(id => s.getIn(['tracksById', id, 'type']) === trackType);

        if (tracksOfType.size === 1) {
          s.setIn(['tracksById', tracksOfType.get(0), 'name'], trackType);
        }

        return s;
      });
    }

    case types.EMBED_TRACK_UPDATE: {
      const { track } = action.payload;
      return state.updateIn(
        ['tracksById', track.get('id')],
        Immutable.Map(),
        t => t.merge(track),
      );
    }

    case types.EMBED_TRACK_MOVE: {
      const { trackId, targetIndex } = action.payload;
      return state.update('tracks', tracks => {
        const currentIndex = tracks.indexOf(trackId);
        if (!_.isFinite(currentIndex)) return tracks;

        const splitAtIndex = targetIndex + 1;
        const ary = tracks.toJS();
        const firstHalf = ary.slice(0, splitAtIndex);
        const secondHalf = ary.slice(splitAtIndex);

        if (currentIndex < splitAtIndex) {
          firstHalf.splice(currentIndex, 1);
        } else {
          secondHalf.splice(currentIndex - firstHalf.length, 1);
        }

        return Immutable.List(firstHalf.concat(trackId, secondHalf));
      });
    }

    case types.EMBED_ADDING_TO_TRACK_SET: {
      const { trackId } = action.payload;
      return state.set('addingToTrack', trackId);
    }

    case types.EMBED_ADDING_TO_TRACK_CLEAR:
      return state.withMutations(s =>
        s
          .delete('addingToTrack')
          .delete('isAddingTextOverlay')
          .delete('isAddingAudio'),
      );

    case types.EMBED_TRACK_ELEMENT_SELECT: {
      const { dataId, trackId } = action.payload;
      return state.set(
        'selectedTrackElement',
        Immutable.Map({ dataId, trackId }),
      );
    }

    case types.EMBED_TRACK_ELEMENT_CLEAR:
      return state.delete('selectedTrackElement');

    case types.EMBED_REPLACED_TRACK_ELEMENT_CLEAR:
      return state.delete('replaceTrackElement');

    case types.EMBED_TRACK_ELEMENT_REPLACE:
      return state.set('replaceTrackElement', action.payload);

    case types.EMBED_SLIDESHOW_UPDATE: {
      const { slides, slidesById } = action.payload;
      return state.withMutations(s =>
        s.set('slides', slides).set('slidesById', slidesById),
      );
    }

    case types.EMBED_SLIDE_DELETE: {
      const { slideId } = action.payload;
      return state.withMutations(s => {
        s.deleteIn(['slidesById', slideId]);
        s.update('slides', slides => {
          const index = slides.indexOf(slideId);
          return [...slides.slice(0, index), ...slides.slice(index + 1)];
        });
        deleteFromTracks(s, slideId);
      });
    }

    case types.EMBED_TEXT_OVERLAYS_UPDATE: {
      const { textOverlayById } = action.payload;
      return updateTextOverlays(state, textOverlayById);
    }

    case types.EMBED_TEXT_OVERLAY_ADD_REQUEST:
      return state.set('isAddingTextOverlay', true);

    case types.EMBED_TEXT_OVERLAY_ADD_SUCCESS:
      return state.set('isAddingTextOverlay', false);

    case types.EMBED_TEXT_OVERLAY_ADD_FAILURE:
      return state.set('isAddingTextOverlay', false);

    case types.EMBED_LAST_TEXT_TEMPLATE_UPDATE: {
      const { template } = action.payload;
      return state.set('lastTextOverlayTemplate', template);
    }

    case types.EMBED_LAST_GLOBAL_TEMPLATE_UPDATE: {
      const { template } = action.payload;
      return state.set(
        'lastGlobalTextOverlayTemplate',
        Immutable.fromJS(template),
      );
    }

    /**
     * NB: various forms of interesting performance tradeoffs here.  We might want separate signals
     *     for the different text overlay options we're updating. mergeDeep is probably one of
     *     the worst solutions.
     */
    case types.EMBED_TEXT_OVERLAY_UPDATE: {
      const { textOverlay } = action.payload;
      const id = !_.isUndefined(textOverlay.id)
        ? textOverlay.id
        : textOverlay.get('id');
      return state.updateIn(['textOverlayById', id], o =>
        o.mergeDeep(textOverlay),
      );
    }

    case types.EMBED_TEXT_OVERLAYS_DELETE: {
      const { textOverlayIds } = action.payload;
      return state.withMutations(s => {
        textOverlayIds.forEach(id => {
          s.deleteIn(['textOverlayById', id]);
          deleteFromTracks(s, id);
        });

        return s;
      });
    }

    case types.EMBED_AUDIO_ADD_REQUEST:
      return state.set('addingAudio', true);

    case types.EMBED_AUDIO_ADD_SUCCESS:
    case types.EMBED_AUDIO_ADD_FAILURE:
      return state.delete('addingAudio');

    case types.EMBED_AUDIO_COLLECTION_UPDATE: {
      const { audioById } = action.payload;
      return updateAudio(state, audioById);
    }

    case types.EMBED_AUDIO_UPDATE: {
      const { audio } = action.payload;
      const id = Immutable.Map.isMap(audio) ? audio.get('id') : audio.id;
      return state.updateIn(['audioById', id], a => a.merge(audio));
    }

    case types.EMBED_AUDIO_DELETE: {
      const { audioId } = action.payload;
      return state.withMutations(s => {
        s.deleteIn(['audioById', audioId]);
        deleteFromTracks(s, audioId);
      });
    }

    case types.EMBED_SLIDE_UPDATE: {
      const { payload } = action;
      const { slideId, slide } = payload;
      return state.updateIn(['slidesById', slideId], s =>
        s.mergeWith(
          (oldVal, newVal) => (typeof newVal === 'undefined' ? oldVal : newVal),
          slide,
        ),
      );
    }

    case types.EMBED_CONFIGURATION_SET: {
      const { configuration } = action.payload;
      return state.set('configuration', configuration);
    }

    case `${embedConfigService.Method.GET_CONFIGURATION}_SUCCESS`:
      return state.set('configuration', action.response.configuration);

    case types.EMBED_AUTOSAVE_SCHEDULE:
      return state.set('autosaveStatus', AutosaveStatus.SCHEDULED.name);

    case types.EMBED_CONFIGURATION_SAVE_REQUEST:
      return state.set('autosaveStatus', AutosaveStatus.RUNNING.name);

    case types.EMBED_CONFIGURATION_SAVE_SUCCESS:
    case types.EMBED_CONFIGURATION_SAVE_FAILURE:
      return state.set('autosaveStatus', AutosaveStatus.DONE.name);

    case types.EMBED_SLIDE_ADD_REQUEST:
      return state.set('addSlideStatus', 'adding');

    case types.EMBED_SLIDE_ADD_SUCCESS:
      return state.set('addSlideStatus', 'added');

    case types.EMBED_SLIDE_ADD_FAILURE:
      return state.set('addSlideStatus', 'failed');

    case types.EMBED_ADD_SLIDE_STATUS_CLEAR:
      return state.delete('addSlideStatus');

    case types.EMBED_SLIDE_EDIT_IMAGE_REQUEST:
      return state.set('editSlideImageStatus', 'uploading');

    case types.EMBED_SLIDE_EDIT_IMAGE_SUCCESS:
      return state.set('editSlideImageStatus', 'complete');

    case types.EMBED_SLIDE_EDIT_IMAGE_FAILURE:
      return state.set('editSlideImageStatus', 'failed');

    case types.EMBED_CODE_CREATE:
      return state.set('code', action.payload.code);

    case types.EMBED_STATE_CLEAR:
      return defaultState;

    case types.SPAREMIN_RECORDING_SELECT:
      return state.set('recording', action.payload.recordingId);

    case types.EMBED_PLAYER_STATUS_SET: {
      const { status } = action.payload;
      return state.set('embedPlayerStatus', status);
    }

    case types.EMBED_SOUNDWAVE_UPDATE:
      return updateSoundwave(state, Immutable.fromJS(action.payload));

    case types.EMBED_SOUNDWAVE_REMOVE: {
      return state.withMutations(s => {
        s.delete('soundwave');
        deleteFromTracks(s, 'waveform');
      });
    }

    case types.EMBED_WATERMARKS_UPDATE: {
      const { watermarkById } = action.payload;
      return updateWatermarks(state, watermarkById);
    }

    case types.EMBED_WATERMARK_ADD_REQUEST:
    case types.EMBED_WATERMARK_EDIT_REQUEST:
      return state.set('watermarkStatus', 'adding');

    case types.EMBED_WATERMARK_DELETE_REQUEST:
      return state.set('watermarkStatus', 'deleting');

    case types.EMBED_WATERMARK_ADD_SUCCESS:
    case types.EMBED_WATERMARK_ADD_FAILURE:
    case types.EMBED_WATERMARK_DELETE_SUCCESS:
    case types.EMBED_WATERMARK_DELETE_FAILURE:
    case types.EMBED_WATERMARK_EDIT_SUCCESS:
    case types.EMBED_WATERMARK_EDIT_FAILURE:
      return state.delete('watermarkStatus');

    case types.EMBED_CAPTIONS_CONFIG_SET: {
      const { config } = action.payload;
      return updateCaptionsConfig(state, config);
    }

    case types.EMBED_CAPTIONS_OFFSET_SET_SUCCESS: {
      const { offsetMillis } = action.payload;
      return state.setIn(['captions', 'config', 'offsetMillis'], offsetMillis);
    }

    case types.EMBED_CAPTIONS_LOCK_SET: {
      const { lock } = action.payload;
      return state.setIn(['captions', 'lock'], lock);
    }

    case types.EMBED_CAPTIONS_MEDIA_SOURCE_SET: {
      const { assetType, assetId } = action.payload;
      return state.update('captions', Immutable.Map(), captions =>
        captions
          .setIn(['config', 'mediaSourceType'], assetType)
          .setIn(['config', 'mediaSourceId'], assetId),
      );
    }

    case types.EMBED_CAPTIONS_SOURCE_DELETE:
      return state.updateIn(['captions', 'config'], config =>
        config.withMutations(c =>
          c
            .delete('offsetMillis')
            .delete('transcriptId')
            .delete('transcriptRevisionId')
            .delete('mediaSourceId')
            .delete('mediaSourceType'),
        ),
      );

    case types.EMBED_PHRASE_UPDATE_REQUEST: {
      const { phraseId } = action.payload;
      return state.setIn(['captions', 'phraseStatus', phraseId], 'saving');
    }

    case types.EMBED_PHRASE_UPDATE_SUCCESS: {
      const { phraseId } = action.payload;
      return state.setIn(['captions', 'phraseStatus', phraseId], 'success');
    }

    case types.EMBED_PHRASE_UPDATE_FAILURE: {
      const { phraseId } = action.payload;
      return state.setIn(['captions', 'phraseStatus', phraseId], 'failure');
    }

    case types.EMBED_PHRASE_DELETE_REQUEST: {
      const { phraseId } = action.payload;
      return state.updateIn(
        ['captions', 'deletedPhrases'],
        Immutable.Set(),
        ids => ids.add(phraseId),
      );
    }

    case types.EMBED_PHRASE_DELETE_SUCCESS:
    case types.EMBED_PHRASE_DELETE_FAILURE: {
      const { phraseId } = action.payload;
      return state.updateIn(['captions', 'deletedPhrases'], ids =>
        ids.delete(phraseId),
      );
    }

    case types.EMBED_PHRASE_ADD_REQUEST:
      return state.setIn(['captions', 'addPhraseStatus'], 'adding');

    case types.EMBED_PHRASE_ADD_SUCCESS:
      return state.setIn(['captions', 'addPhraseStatus'], 'success');

    case types.EMBED_PHRASE_ADD_FAILURE:
      return state.setIn(['captions', 'addPhraseStatus'], 'failure');

    case types.EMBED_VIDEO_ADD_REQUEST:
      return state.set('isAddingVideo', true);

    case types.EMBED_VIDEO_ADD_SUCCESS:
    case types.EMBED_VIDEO_ADD_FAILURE:
      return state.set('isAddingVideo', false);

    case types.EMBED_VIDEO_COLLECTION_ADD: {
      const { video } = action.payload;
      return state.update('videosById', (videos = Immutable.Map()) =>
        videos.set(video.id, video),
      );
    }

    case types.EMBED_VIDEO_REMOVE_REQUEST: {
      const { id } = action.payload;
      return state.withMutations(s => {
        s.deleteIn(['videosById', id]);
        s.update('videos', v => v.delete(v.indexOf(id)));
        deleteFromTracks(s, id);
        return s;
      });
    }

    case types.EMBED_VIDEO_UPDATE: {
      const { video } = action.payload;
      return state.setIn(['videosById', video.id], video);
    }

    case types.EMBED_VIDEO_ORDER_SET: {
      const { videos } = action.payload;
      return state.set('videos', videos);
    }

    case types.EMBED_STYLE_TRACK_SELECT: {
      const { trackId } = action.payload;
      return state.set('editingGlobalStyleForTrack', trackId);
    }

    case types.EMBED_STYLE_TRACK_CLEAR:
      return state.delete('editingGlobalStyleForTrack');

    case types.EMBED_CAPTIONS_UPLOAD_REQUEST:
      return state.set('isCaptionUploading', true);

    case types.EMBED_CAPTIONS_UPLOAD_SUCCESS:
    case types.EMBED_CAPTIONS_UPLOAD_FAILURE:
      return state.delete('isCaptionUploading');

    case types.FREE_FORM_CAPTIONS_UPLOAD_REQUEST:
      return state.set('isFreeFormCaptionUploading', true);

    case types.FREE_FORM_CAPTIONS_UPLOAD_SUCCESS:
    case types.FREE_FORM_CAPTIONS_UPLOAD_FAILURE:
      return state.delete('isFreeFormCaptionUploading');

    case types.EMBED_CANVAS_COLOR_SET: {
      const { color } = action.payload;
      return updateCanvasColor(state, color);
    }

    case types.EMBED_PROGRESS_ANIMATION_SET: {
      const { progress } = action.payload;
      return updateProgressAnimation(state, progress);
    }

    case types.EMBED_TIMER_CHANGE: {
      return updateTimer(state, action.payload);
    }

    case types.EMBED_EDGE_VIDEOS_CHANGE:
      return updateEdgeVideos(state, action.payload);

    case types.EMBED_LAST_TEXT_EDITOR_V2_STATE_UPDATE:
      return state.set(
        'lastTextEditorV2State',
        Immutable.fromJS(action.payload.lastTextEditorV2State),
      );

    case types.EMBED_LAST_GLOBAL_TEXT_EDITOR_V2_STATE_UPDATE:
      return state.set(
        'lastGlobalTextEditorV2State',
        Immutable.fromJS(action.payload.lastTextEditorV2State),
      );

    default:
      return state;
  }
}

export default entireAudioEnhancer.createReducer(reducer, defaultState);

import { PulseLoader } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';
import DestinationPlatforms, {
  checkIfDestinationPresetExists,
  destinationConfigMap,
  DestinationPlatform,
  DestinationPlatformsVideoTypes,
  VideoTypeConfig,
} from 'blocks/DestinationPlatforms';
import { block } from './utils';

interface VideoWizardDestinationStepProps {
  isLoading?: boolean;
  onDestinationChosen?: (
    platform: DestinationPlatform,
    videoType?: VideoTypeConfig,
  ) => void;
}

const VideoWizardDestinationStep: React.FC<VideoWizardDestinationStepProps> = ({
  isLoading,
  onDestinationChosen,
}) => {
  const [platform, setPlatform] = useState<DestinationPlatform | undefined>(
    undefined,
  );

  const handlePlatformSelection = useCallback(
    (newPlatform: DestinationPlatform): void => {
      setPlatform(newPlatform);

      if (!checkIfDestinationPresetExists(newPlatform)) {
        onDestinationChosen(newPlatform, {
          id: 'post',
        });
      }
    },
    [onDestinationChosen],
  );

  const handleVideoTypeSelections = useCallback(
    (videoType: VideoTypeConfig): void => {
      onDestinationChosen(platform, videoType);
    },
    [onDestinationChosen, platform],
  );

  if (isLoading) {
    return (
      <div className={block('loading')}>
        <PulseLoader />
      </div>
    );
  }

  return !platform ||
    (platform && !checkIfDestinationPresetExists(platform)) ? (
    <DestinationPlatforms
      contentsClassName={block('destination-options')}
      onPlatformSelection={handlePlatformSelection}
    />
  ) : (
    <div className={block('video-types-container')}>
      <DestinationPlatformsVideoTypes
        icon={destinationConfigMap?.[platform]?.icon}
        videoTypes={destinationConfigMap?.[platform]?.videoTypes.filter(
          videoType => videoType.id !== 'episode',
        )}
        onClick={handleVideoTypeSelections}
        onBackClick={() => setPlatform(undefined)}
      />
    </div>
  );
};

export default VideoWizardDestinationStep;

import { SuperAgentStatic } from 'superagent';
import { SPAREMIN_SERVICES_AUTHENTICATION_URL } from 'config';
import { createRequest } from '../utils';

import * as types from './types';

const login = (
  args: types.LoginArgs,
  request: SuperAgentStatic,
): Promise<types.LoginResult> => {
  const [email, password] = args;

  return request
    .post('/api/v1/auth/sparemin')
    .unset('Authorization')
    .send({
      email,
      password,
    })
    .then(res => ({
      handle: res.body.user.handle,
      token: res.body.spareminToken,
      userId: res.body.user.id,
    }));
};

const forgotPassword = (
  args: types.ForgotPasswordArgs,
  request: SuperAgentStatic,
): Promise<types.ForgotPasswordResult> => {
  const [email] = args;

  return request
    .post('/api/v1/auth/sparemin/forgot-password')
    .unset('Authorization')
    .send({ email })
    .then(res => res.body);
};

const updatePassword = (
  args: types.UpdatePasswordArgs,
  request: SuperAgentStatic,
): Promise<types.UpdatePasswordResult> => {
  const [userId, currentPassword, password, confirmPassword] = args;

  return request
    .post('/api/v1/auth/sparemin/password')
    .send({ userId, currentPassword, password, confirmPassword })
    .then(res => res.body);
};

const confirmEmail = (
  args: types.ConfirmEmailArgs,
  request: SuperAgentStatic,
): Promise<types.ConfirmEmailResult> => {
  const [uuid] = args;

  return request
    .put(`/api/v1/auth/verification/${uuid}`)
    .send({ newStatus: 'verified' })
    .then(res => res.body);
};

export const resendVerificationEmail = (
  args: types.ResendVerificationEmailArgs,
  request: SuperAgentStatic,
): Promise<types.ResendVerificationEmailResult> => {
  const [userId] = args;

  return request
    .post(`/api/v1/auth/verification`)
    .send({ userId })
    .then(res => res.body);
};

export const handle: types.IHandle = (
  method: types.ServiceMethod,
  args: any,
  token?: string,
): Promise<types.ServiceResults> => {
  const request = createRequest({
    baseUrl: SPAREMIN_SERVICES_AUTHENTICATION_URL,
    omitBearerPrefix: true,
    token,
  });

  switch (method) {
    case types.ServiceMethod.LOGIN:
      return login(args, request);

    case types.ServiceMethod.FORGOT_PASSWORD:
      return forgotPassword(args, request);

    case types.ServiceMethod.UPDATE_PASSWORD:
      return updatePassword(args, request);

    case types.ServiceMethod.CONFIRM_EMAIL:
      return confirmEmail(args, request);

    case types.ServiceMethod.RESEND_VERIFICATION_EMAIL:
      return resendVerificationEmail(args, request);

    default:
      throw new Error(`${types.ACTION_KEY} cannot handle method ${method}`);
  }
};

import { useTikTokLogin, UseTikTokLoginConfig } from '@sparemin/auth';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'underscore';
import { tokenSelector } from 'redux/modules/auth';
import {
  clearTiktokUser,
  tiktokAuthorizeSuccess,
  waitForTiktokProfile,
} from 'redux/modules/social';
import { FatalError } from 'utils/FatalError';

export interface UseTikTokAuthenticatorConfig
  extends Pick<UseTikTokLoginConfig, 'force'> {
  onError?: (error: Error) => void;
  onAuthSuccess?: () => void;
}

export default function useTikTokAuthenticator({
  onAuthSuccess,
  onError,
  force,
}: UseTikTokAuthenticatorConfig) {
  const dispatch = useDispatch();
  const spareminToken = useSelector(tokenSelector);

  return useTikTokLogin({
    force,
    spareminToken,
    onSuccess: async data => {
      dispatch(clearTiktokUser());

      if (data) {
        dispatch(
          tiktokAuthorizeSuccess(
            pick(data.tokenInfo, 'accessToken', 'idToken', 'providerUserId'),
          ),
        );
      }

      try {
        await dispatch(waitForTiktokProfile());
        onAuthSuccess?.();
      } catch (error) {
        throw new FatalError(error.message);
      }
    },
    onError,
  });
}

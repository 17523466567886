import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PostTaskUpgradeSection } from 'blocks/PostTask';
import { H3, H4 } from 'components/Heading';

import useDisco from 'hooks/useDisco';
import useStaticCallback from 'hooks/useStaticCallback';

import { onExportDiscoWidgetInitialized } from 'redux/modules/mixpanel';
import { UnlockThisFeatureModal } from 'redux/modules/mixpanel/types';
import { DISCO_LISTENS_STATUS } from 'utils/constants';
import { block } from './utils';

export interface DiscoSectionProps {
  modalName?: UnlockThisFeatureModal;
  className?: string;
}

const DiscoSection: React.FC<DiscoSectionProps> = ({
  modalName,
  className,
}) => {
  const dispatch = useDispatch();
  const [injectionPoint, setInjectionPoint] = useState<HTMLDivElement>();
  const { displayVariant } = DISCO_LISTENS_STATUS;

  const { initializeDisco } = useDisco({
    defaultInitializeOnLoad: false,
    injectionPoint,
    displayVariant,
  });

  const staticInitializeDisco = useStaticCallback(initializeDisco);

  useEffect(() => {
    staticInitializeDisco();
    dispatch(onExportDiscoWidgetInitialized(displayVariant));
  }, [dispatch, displayVariant, staticInitializeDisco]);

  return (
    <section className={cn(block('disco-section'), className)}>
      <div className={block('widget')}>
        <H3 className={block('disco-heading')}>
          Enjoy these clips while you wait!
        </H3>
        <H4 className={block('disco-subheading')}>
          These sponsored clips keep Headliner free & support the podcast
          community.
        </H4>

        <PostTaskUpgradeSection modalName={modalName} />

        <div className={block('disco-placeholder')} ref={setInjectionPoint} />
      </div>
    </section>
  );
};

export default DiscoSection;

import { Heading } from '@sparemin/blockhead';
import cn from 'classnames';
import React, { useState } from 'react';
import CirclePlayButton, {
  CirclePlayButtonProps,
} from 'components/CirclePlayButton';

import ProgressOverlay from 'components/ProgressOverlay';
import { range } from 'utils/numbers';
import { useClipSelect } from '../../ClipSelectContext';
import AudioClipCardText, { AudioClipCardTextProps } from './AudioClipCardText';
import { ClipTagProps } from './ClipTag';
import { ClipTimecodesProps } from './ClipTimecodes';
import { block } from './utils';

type PickedPlayButtonProps = Pick<CirclePlayButtonProps, 'playing'>;

type PickedTextProps = Pick<AudioClipCardTextProps, 'isVideoOutdated'>;

export interface AudioClipCardProps
  extends ClipTagProps,
    ClipTimecodesProps,
    PickedPlayButtonProps,
    PickedTextProps {
  className?: string;
  onClickControlButton?: () => void;
  playable?: boolean;
  isLoadingClipSuggestion?: boolean;
  isLoadingTranscript?: boolean;
  videoUrl: string;
  id?: number;
}

const AudioClipCard: React.FC<AudioClipCardProps> = ({
  className,
  clipEndMillis,
  clipStartMillis,
  onClickControlButton,
  playable,
  playing,
  isLoadingClipSuggestion,
  isLoadingTranscript,
  isVideoOutdated,
  videoUrl,
  id,
}) => {
  const [playbackMillis, setPlaybackMillis] = useState(0);

  const {
    suggestionVideoPlayerRefs,
    eddyProjectId,
    eddySuggestedClips,
    onTranscriptUpdate,
    clipsPageInfo: { aspectRatioName },
  } = useClipSelect();

  return (
    <div
      className={cn(block({ [aspectRatioName]: true, playable }), className)}
    >
      <div className={block('video-player', { playable })}>
        <div className={block('playback')}>
          {!isLoadingClipSuggestion && (
            <video
              ref={suggestionVideoPlayerRefs?.[id]}
              src={videoUrl}
              className={cn(block('video', { [aspectRatioName]: true }))}
              loop
              onTimeUpdate={e => setPlaybackMillis(e.currentTarget.currentTime)}
            />
          )}

          <div className={block('center-content')}>
            {isLoadingClipSuggestion ? (
              <Heading level={1} className={block('loading-message')}>
                This clip will
                <br /> be ready soon
              </Heading>
            ) : (
              <CirclePlayButton
                {...{ playing }}
                className={block('play-button')}
                onClick={onClickControlButton}
                progress={range(
                  0,
                  suggestionVideoPlayerRefs?.[id]?.current?.duration,
                  0,
                  1,
                  playbackMillis,
                )}
              />
            )}
          </div>

          {isLoadingClipSuggestion && (
            <div className={block('overlay-wrapper')}>
              <ProgressOverlay in />
            </div>
          )}
        </div>
      </div>

      <AudioClipCardText
        {...{
          isLoadingTranscript,
          isVideoOutdated,
          clipStartMillis,
          clipEndMillis,
          playable,
          onTranscriptUpdate,
        }}
        clipId={id}
        suggestedClips={eddySuggestedClips}
        projectId={eddyProjectId}
        isDisabled={isLoadingClipSuggestion}
      />
    </div>
  );
};

export default AudioClipCard;

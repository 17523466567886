import React from 'react';

import { TextBuilderStyles } from '../../types';
import { block } from '../../utils';
import TextOverlayStaticText from './TextOverlayStaticText';
import TextOverlayTextArea from './TextOverlayTextArea';

interface TextOverlayTextPreviewProps {
  editable: boolean;
  inputModeEnabled: boolean;
  onChangeText: (value: string) => void;
  onDisableInputMode: () => void;
  onEnableInputMode: () => void;
  textAreaStyles: React.CSSProperties;
  textBuilderStyles: TextBuilderStyles;
  textValue: string;
  isCaption?: boolean;
}

const TextOverlayTextPreview: React.FunctionComponent<TextOverlayTextPreviewProps> = props => {
  const {
    editable,
    inputModeEnabled,
    onChangeText,
    onDisableInputMode,
    onEnableInputMode,
    textAreaStyles,
    textBuilderStyles,
    textValue,
    isCaption,
  } = props;

  const handleTextBoxDoubleClick = React.useCallback((): void => {
    if (!editable) {
      return;
    }

    onEnableInputMode();
  }, [editable, onEnableInputMode]);

  return (
    <div
      className={block('input')}
      onDoubleClick={!inputModeEnabled ? handleTextBoxDoubleClick : undefined}
    >
      <TextOverlayStaticText
        textBuilderStyles={textBuilderStyles}
        textValue={textValue}
        isCaption={isCaption}
      />
      {inputModeEnabled && (
        <TextOverlayTextArea
          autoFocus
          onBlur={onDisableInputMode}
          onChangeText={onChangeText}
          textAreaStyles={textAreaStyles}
          textValue={textValue}
        />
      )}
    </div>
  );
};

export default TextOverlayTextPreview;

import * as React from 'react';

import FadingScrollBars from 'components/FadingScrollBars';
import AICaptionsUpsellModal from 'containers/AICaptionsUpsellModal';
import FacebookPostModal from 'containers/FacebookPostModal';
import useSocialPostCaptions from 'hooks/useSocialPostCaptions';

import { block } from '../../utils';
import DownloadButton from '../DownloadButton';
import FacebookPostButton from '../FacebookPostButton';
import InstagramPostButton from '../InstagramPostButton';
import LinkedinPostButton from '../LinkedinPostButton';
import ThreadsPostButton from '../ThreadsPostButton';
import TiktokPostButton from '../TiktokPostButton';
import TwitterPostButton from '../TwitterPostButton';
import YoutubePostButton from '../YoutubePostButton';
import { SocialSectionProvider } from './SocialSectionContext';
import { SocialSectionModal } from './SocialSectionContext/types';
import useSocialIntegration, {
  SocialIntegration,
} from './useSocialIntegration';

const { useCallback, useState } = React;

export interface DownloadPageSocialSectionProps {
  videoDurationMillis: number;
  videoFileName?: string;
}

const DownloadPageSocialSection: React.SFC<DownloadPageSocialSectionProps> = ({
  videoDurationMillis,
  videoFileName,
}) => {
  const {
    canGenerateSocialPostCaptions,
    createSocialPostCaptions,
  } = useSocialPostCaptions();

  const socialIntegrations = useSocialIntegration();
  const isSingle = socialIntegrations.length === 1;

  const durationSeconds = videoDurationMillis / 1000;
  const [activeModal, setActiveModal] = useState<SocialSectionModal>();
  const handleModalHide = useCallback(() => setActiveModal(undefined), []);

  const handleToggleModalVisibility = (
    socialModal: SocialSectionModal,
    show = true,
  ) => {
    setActiveModal(show ? socialModal : undefined);
  };

  const handleUnlockAICaptions = useCallback((): void => {
    setActiveModal('AICaptionsUpsell');
  }, []);

  return (
    <SocialSectionProvider
      activeModal={activeModal}
      allowAiDisclaimer={canGenerateSocialPostCaptions}
      toggleModalVisibility={handleToggleModalVisibility}
      onUnlockAICaptions={handleUnlockAICaptions}
    >
      <div className={block('social-section', { single: isSingle })}>
        <FadingScrollBars horizontal sideFaderClassName={block('side-fader')}>
          <div className={block('social-buttons')}>
            {socialIntegrations.includes(SocialIntegration.TWITTER) && (
              <TwitterPostButton videoDurationSec={durationSeconds} />
            )}

            {socialIntegrations.includes(SocialIntegration.FACEBOOK) && (
              <FacebookPostButton
                videoDurationSec={durationSeconds}
                onAuthenticatedClick={() =>
                  handleToggleModalVisibility('facebook')
                }
              />
            )}

            {socialIntegrations.includes(SocialIntegration.YOUTUBE) && (
              <YoutubePostButton />
            )}

            {socialIntegrations.includes(SocialIntegration.LINKEDIN) && (
              <LinkedinPostButton videoDurationSec={durationSeconds} />
            )}

            {socialIntegrations.includes(SocialIntegration.TIKTOK) && (
              <TiktokPostButton videoDurationSec={durationSeconds} />
            )}

            {socialIntegrations.includes(SocialIntegration.INSTAGRAM) && (
              <InstagramPostButton videoDurationSec={durationSeconds} />
            )}

            {socialIntegrations.includes(SocialIntegration.THREADS) && (
              <ThreadsPostButton videoDurationSec={durationSeconds} />
            )}
          </div>
        </FadingScrollBars>

        <div className={block('download-button')}>
          {socialIntegrations.includes(SocialIntegration.DIRECT_DOWNLOAD) && (
            <DownloadButton fileName={videoFileName} single />
          )}
        </div>

        <FacebookPostModal
          show={activeModal === 'facebook'}
          onHide={handleModalHide}
        />

        <AICaptionsUpsellModal
          show={activeModal === 'AICaptionsUpsell'}
          onPlanUpgrade={createSocialPostCaptions}
        />
      </div>
    </SocialSectionProvider>
  );
};

export default DownloadPageSocialSection;

import { useSelector } from 'react-redux';

import {
  initiatorInfoSelector,
  podcastEpisodeIdSelector,
  videoIdSelector,
} from 'redux/modules/download';
import { UseSocialPostCaptionsResult } from './types';
import useAudioSocialPostCaptions from './useAudioSocialPostCaptions';
import useEmbedVideoSocialPostCaptions from './useEmbedVideoSocialPostCaptions';
import useVideoSocialPostCaptions from './useVideoSocialPostCaptions';

type EmptySocialPostCaptionResult = {
  [K in keyof UseSocialPostCaptionsResult]: undefined;
};

export default function useSocialPostCaptions():
  | UseSocialPostCaptionsResult
  | EmptySocialPostCaptionResult {
  const episodeId = useSelector(podcastEpisodeIdSelector);

  const initiatorInfo = useSelector(initiatorInfoSelector);
  const creationRequestId = initiatorInfo?.creationRequestId;

  const videoId = useSelector(videoIdSelector);

  const audioSocialPostCaptionConfig = useAudioSocialPostCaptions(episodeId);
  const videoSocialPostCaptionConfig = useVideoSocialPostCaptions(
    creationRequestId,
  );
  const embedVideoSocialPostCaptionConfig = useEmbedVideoSocialPostCaptions(
    videoId,
  );

  if (episodeId) {
    return audioSocialPostCaptionConfig;
  }

  if (creationRequestId) {
    return videoSocialPostCaptionConfig;
  }

  if (videoId) {
    return embedVideoSocialPostCaptionConfig;
  }

  return {
    canGenerateSocialPostCaptions: undefined,
    hasSocialPostCaptions: undefined,
    createSocialPostCaptions: undefined,
    socialPostCaptionInfoByPlatform: undefined,
  };
}

import { Record } from 'immutable';
import { RequestStatus } from 'types';

import { State } from './types';

export const stateFactory = Record<State>({
  configuration: undefined,
  podcastInfo: undefined,
  dpaPerformed: false,
  loadingState: RequestStatus.REQUEST,
  projectId: undefined,
  podcastEpisodeId: undefined,
  videoDurationMillis: undefined,
  videoFrequency: undefined,
  isPollingSocialPostCaptions: false,
  jobId: undefined,
  hasError: false,
  creationRequestId: undefined,
  videoId: undefined,
});

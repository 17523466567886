import { Card, Spacer, Text } from '@sparemin/blockhead';
import React from 'react';
import { Alert } from 'components/icons';
import { editorCanvasInfoboxBlock as block } from '../utils';

export interface EditorCanvasInfoboxProps {
  message: React.ReactNode;
}

const EditorCanvasInfobox: React.FC<EditorCanvasInfoboxProps> = ({
  message,
}) => {
  return (
    <Card className={block()} header={<></>} footer={<></>}>
      <div className={block('container')}>
        <Spacer
          orientation="horizontal"
          space="16px"
          align="center"
          justify="center"
          className={block('contents')}
        >
          <Alert
            width="24px"
            height="24px"
            fill="#fafafa"
            background="transparent"
          />

          <Text variant="label" className={block('message')}>
            {message}
          </Text>
        </Spacer>
      </div>
    </Card>
  );
};

export default EditorCanvasInfobox;

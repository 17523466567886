import * as React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import LoadingOverlay from 'components/LoadingOverlay';
import Modal from 'components/Modal';
import { selectors as embedSelectors } from 'redux/modules/embed';
import { ApplicationError } from 'utils/ApplicationError';
import CaptionUploader from './CaptionUploader';
import { block } from './utils';

const captionUploadStatusSelector = createSelector(
  embedSelectors.captionUploadStatusSelector,
  isUploading => isUploading === true,
);

export interface UploadCaptionsModalProps {
  show: boolean;
  onCaptionAccepted: (file: File) => any | Promise<any>;
  onHide: () => void;
  onError: (error: ApplicationError, file: File) => void;
}

const UploadCaptionsModal: React.FC<UploadCaptionsModalProps> = ({
  show = false,
  onCaptionAccepted,
  onHide,
  onError,
}) => {
  const uploadInProgress = useSelector(captionUploadStatusSelector);

  return (
    <Modal
      className={block()}
      show={show}
      onHide={onHide}
      backdrop="static"
      title="Upload captions"
    >
      <Modal.Body>
        {uploadInProgress && (
          <LoadingOverlay
            title="Uploading captions"
            description="This may take a while, so please keep your browser open and check back later"
          />
        )}

        <CaptionUploader
          onFileAccepted={onCaptionAccepted}
          onFileRejected={onError}
        />

        <div className={block('uploader-warning')}>
          The uploaded file will replace exisiting captions
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Modal.FooterButtons>
          <Modal.FooterButton onClick={onHide}>Cancel</Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadCaptionsModal;

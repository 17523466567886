import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useOnMount from 'hooks/useOnMount';
import {
  onAddClipToVideo,
  onAdjustClip,
  onCaptionsModalSubmit,
  onDislikeSuggestedClip,
  onOpenInAdvancedEditor,
  onSelectOwnClip,
  onSuggestedClipsPageLoad,
} from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import { ClipSelectPageSource } from 'types';
import {
  ClipSelectMediaType,
  ClipSelectTrackingContextType,
  ClipSuggestion,
} from '../types';
import { useClipSelectNavigation } from './ClipSelectNavigationContext/ClipSelectNavigationContext';

const ClipSelectTrackingContext = React.createContext<
  ClipSelectTrackingContextType | undefined
>(undefined);

interface ClipSelectTrackingProviderProps {
  suggestedClips?: ClipSuggestion[];
  mediaType: ClipSelectMediaType;
  source: ClipSelectPageSource;
  children?: React.ReactNode;
}

export const ClipSelectTrackingProvider: React.FC<ClipSelectTrackingProviderProps> = ({
  suggestedClips,
  source,
  mediaType,
  children,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const [state] = useClipSelectNavigation();
  const { clipAdjusted } = state.context;

  const dispatchedLoadEvent = useRef(false);

  useEffect(() => {
    if (suggestedClips.length && !dispatchedLoadEvent.current) {
      dispatch(
        onSuggestedClipsPageLoad(source, mediaType, suggestedClips?.length),
      );
      dispatchedLoadEvent.current = true;
    }
  }, [dispatch, mediaType, source, suggestedClips]);

  const handleDislikeClip = useCallback(
    (reason: string) => {
      dispatch(onDislikeSuggestedClip(source, reason));
    },
    [dispatch, source],
  );

  const handleAdjustClip = useCallback(() => {
    dispatch(onAdjustClip(source));
  }, [dispatch, source]);

  const handleOpenInAdvancedEditor = useCallback(() => {
    dispatch(onOpenInAdvancedEditor());
  }, [dispatch]);

  const handleAddClip = useCallback(() => {
    dispatch(onAddClipToVideo(source, clipAdjusted));
  }, [dispatch, source, clipAdjusted]);

  const handleSelectOwnClip = useCallback(() => {
    dispatch(onSelectOwnClip(source));
  }, [dispatch, source]);

  const handleCaptionsModalSubmit = useCallback(
    (enabled: boolean) => {
      dispatch(onCaptionsModalSubmit(enabled));
    },
    [dispatch],
  );

  const value: ClipSelectTrackingContextType = {
    onAddClip: handleAddClip,
    onAdjustClip: handleAdjustClip,
    onOpenInAdvancedEditor: handleOpenInAdvancedEditor,
    onCaptionsModalSubmit: handleCaptionsModalSubmit,
    onDislikeClip: handleDislikeClip,
    onSelectOwnClip: handleSelectOwnClip,
  };

  useOnMount(() => {
    onSuggestedClipsPageLoad(source, mediaType, suggestedClips?.length);
  });

  return (
    <ClipSelectTrackingContext.Provider value={value}>
      {children}
    </ClipSelectTrackingContext.Provider>
  );
};

export function useClipSelectTracking() {
  const context = useContext(ClipSelectTrackingContext);

  if (context === undefined) {
    throw new Error(
      'useClipSelectTracking must be used within ClipSelectTrackingProvider',
    );
  }

  return context;
}

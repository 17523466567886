import React from 'react';

import { EditorDataV2, TextOverlayV2 } from '../../types';
import { StylePersistor, StylePersistorConfig } from './types';
import { applyEditorStateOverrides } from './utils';

interface UseStylePersitorConfig {
  extensionConfig: StylePersistorConfig;
}

const useStylePersitor = (config: UseStylePersitorConfig): StylePersistor => {
  const { extensionConfig } = config;

  const handleSaveLastEditorState = React.useCallback(
    (editorData: EditorDataV2): void => {
      if (extensionConfig?.enabled) {
        extensionConfig.editorStateSetter({
          advancedTextConfigs: editorData.get('advancedTextConfigs')?.toJS(),
          advancedAnimation: editorData.get('advancedAnimation')?.toJS(),
          position: editorData.get('position')?.toJS(),
          size: editorData.get('size')?.toJS(),
          textStyle: editorData.getIn(['editor', 'textStyle'])?.toJS(),
        });
      }
    },
    [extensionConfig],
  );

  const handleApplyLastEditorState = React.useCallback(
    (textOverlay?: TextOverlayV2): TextOverlayV2 | undefined => {
      if (!extensionConfig?.enabled || extensionConfig.isEdit === true) {
        return textOverlay;
      }

      return applyEditorStateOverrides(
        textOverlay,
        extensionConfig.editorStateGetter(),
      );
    },
    [extensionConfig],
  );

  return {
    enabled: extensionConfig?.enabled ?? false,
    onApplyLastEditorState: handleApplyLastEditorState,
    onSaveLastEditorState: handleSaveLastEditorState,
  };
};

export default useStylePersitor;

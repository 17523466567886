import { darkTheme, ThemeProvider } from '@sparemin/blockhead';
import React from 'react';
import { isUndefined } from 'underscore';

import ClipVideoClipper, {
  IProps as ClipVideoClipperProps,
} from './ClipVideoClipper';
import ClipVideoFullEpisodeBanner from './ClipVideoFullEpisodeBanner';
import ClipVideoTypeSelector from './ClipVideoTypeSelector';
import { ClipSelectionMode } from './types';

type PickedClipVideoClipperProps = Omit<
  ClipVideoClipperProps,
  'durationMillis' | 'isFullEpisode'
>;

interface ClipVideoStepProps extends PickedClipVideoClipperProps {
  clipSelectionMode: ClipSelectionMode;
  onChangeClipSelectionMode: (option: ClipSelectionMode) => void;
}

const ClipVideoStep: React.FunctionComponent<ClipVideoStepProps> = props => {
  const {
    clipSelectionMode,
    onChangeClipSelectionMode,
    onDurationChange,
  } = props;

  const [durationMillis, setDurationMillis] = React.useState<
    number | undefined
  >();

  const handleDurationChange = React.useCallback(
    (millis: number): void => {
      setDurationMillis(millis);
      onDurationChange(millis);
    },
    [onDurationChange],
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <ClipVideoTypeSelector
        isDisabled={isUndefined(durationMillis)}
        onChange={onChangeClipSelectionMode}
        value={clipSelectionMode}
      />
      <ClipVideoClipper
        {...props}
        durationMillis={durationMillis}
        isFullEpisode={clipSelectionMode === 'full-episode'}
        onDurationChange={handleDurationChange}
        showClipDisclaimer={clipSelectionMode !== 'full-episode'}
      />
      <ClipVideoFullEpisodeBanner clipSelectionMode={clipSelectionMode} />
    </ThemeProvider>
  );
};

export default ClipVideoStep;

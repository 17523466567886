import { AnimationKey, AnimationModule } from '../types';
import karaokeAnimationModule from './KaraokeAnimationModule';
import noAnimationModule from './NoAnimationModule';

const animationModules: Record<AnimationKey, AnimationModule> = {
  none: noAnimationModule,
  karaoke: karaokeAnimationModule,
};

export default animationModules;

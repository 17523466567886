import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isFunction } from 'underscore';
import { Discount } from 'blocks/Pricing/constants';
import { PurchaseableTier } from 'redux/middleware/api/plan-service';
import { onCompleteUpgrade } from 'redux/modules/mixpanel/actions';
import { hideModal, replaceModal } from 'redux/modules/modal';
import { showNotification } from 'redux/modules/notification/actions';
import { discountSelector } from 'redux/modules/pricing/selectors';
import { goToPurchaseSuccess } from 'redux/modules/router/actions';
import { Omit } from 'types';
import { SubscriptionPeriod } from '../../types';
import {
  BillingCycleModalContent,
  useBillingCycleState,
} from '../BillingCycleModal';
import { SubmitType } from '../BillingCycleModal/BillingCycleModalContent';
import AddBillingCycleModalBody from './AddBillingCycleModalBody';
import { AddBillingCycleModalProvider } from './AddBillingCycleModalContext';
import AddBillingCycleModalSection from './AddBillingCycleModalSection';
import CheckoutForm from './CheckoutForm';
import { OPTIONS_BY_TIER } from './utils';

export type RenderFooterProps = {
  costCents: number;
  subPeriod: SubscriptionPeriod;
  disabled?: boolean;
  tier?: PurchaseableTier;
  onPaymentSaved?: () => {};
};

type PickedBillingModalProps = Omit<
  React.ComponentPropsWithoutRef<typeof BillingCycleModalContent>,
  'children' | 'options' | 'subscriptionPeriod' | 'onSubscriptionPeriodChange'
>;

export interface AddBillingCycleModalContentProps
  extends PickedBillingModalProps {
  allowTierSelection?: boolean;
  children?: React.ReactNode;
  defaultSubscriptionPeriod?: SubscriptionPeriod;
  renderFooter?: (props: RenderFooterProps) => JSX.Element;
  tier?: PurchaseableTier;
  title?: string;
  loadingMessage?: string;
  disableSuccessRedirect?: boolean;
  onboardingCheckoutMode?: boolean;
  onVisibilityChange?: (visible: boolean) => void;
  disabled?: boolean;
}

const AddBillingCycleModalContent: React.SFC<AddBillingCycleModalContentProps> = ({
  allowTierSelection,
  children,
  defaultSubscriptionPeriod,
  renderFooter,
  tier,
  loadingMessage,
  disableSuccessRedirect,
  onboardingCheckoutMode,
  onVisibilityChange,
  onHide,
  disabled,
  ...props
}) => {
  const options = OPTIONS_BY_TIER[tier];
  const discount = useSelector(discountSelector);
  const dispatch = useDispatch();
  const [
    subPeriod,
    costCents,
    handleSubscriptionPeriodChange,
  ] = useBillingCycleState(defaultSubscriptionPeriod, options);

  const handleError = () => {
    dispatch(
      showNotification({
        message: 'Please try again or {{link}} so we can help',
        code: 'ER013',
        level: 'error',
        dismissAfterSec: 5,
        type: 'help',
        title: "Sorry, we couldn't process your payment",
        actionLabel: 'contact us',
      }),
    );
  };

  const handleSaved = (period: SubscriptionPeriod, submitType: SubmitType) => {
    // When in onboardingCheckout mode, it is not needed to submit onComplete upgrade
    // events. Modal is only hidden.
    if (onboardingCheckoutMode) {
      onHide(true);
      return;
    }
    dispatch(
      onCompleteUpgrade({
        tier,
        bogo: discount === Discount.BOGO,
        subscriptionPeriod: period,
        button: submitType?.name,
        isPayNowButtonVisible: submitType?.isPayNowButtonVisible,
      }),
    );
    if (disableSuccessRedirect) {
      dispatch(
        replaceModal(
          {
            name: 'PaidSubscriptionSuccess',
            params: { tier },
          },
          true,
        ),
      );

      setTimeout(() => {
        dispatch(hideModal(undefined, 'PaidSubscriptionSuccess'));
      }, 5000);
    } else {
      onHide(true);
      dispatch(goToPurchaseSuccess(tier));
    }
  };

  return (
    <AddBillingCycleModalProvider allowTierSelection={allowTierSelection}>
      <BillingCycleModalContent
        onError={handleError}
        onSaved={handleSaved}
        onHide={onHide}
        disabled={disabled}
        loadingMessage={loadingMessage}
        options={options}
        onSubscriptionPeriodChange={handleSubscriptionPeriodChange}
        subscriptionPeriod={subPeriod}
        {...props}
      >
        {({
          billingCycleSelector,
          onSubmit,
          bodyClassName,
          loadingOverlay,
        }) => {
          const footerProps = {
            costCents,
            subPeriod,
            disabled,
            onPaymentSaved: onSubmit,
          };

          if (isFunction(renderFooter)) {
            return (
              <>
                <AddBillingCycleModalBody
                  costCents={costCents}
                  subscriptionPeriod={subPeriod}
                  className={bodyClassName}
                  tier={tier}
                >
                  <AddBillingCycleModalSection title="Your billing cycle">
                    {children}
                  </AddBillingCycleModalSection>
                  {billingCycleSelector}
                  {loadingOverlay}
                </AddBillingCycleModalBody>
                {renderFooter(footerProps)}
              </>
            );
          }

          return (
            <CheckoutForm
              onSaved={onSubmit}
              costCents={costCents}
              tier={tier}
              subscriptionPeriod={subPeriod}
              className={bodyClassName}
              disabled={disabled}
            >
              {children}
              {billingCycleSelector}
              {loadingOverlay}
            </CheckoutForm>
          );
        }}
      </BillingCycleModalContent>
    </AddBillingCycleModalProvider>
  );
};

export default AddBillingCycleModalContent;

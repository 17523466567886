import cn from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import LinkButton from 'components/LinkButton';
import useContextualUpgrade from 'hooks/useContextualUpgrade';
import { Tier } from 'redux/middleware/api/plan-service';
import { onUnlockThisFeature } from 'redux/modules/mixpanel';
import { UnlockThisFeatureModal } from 'redux/modules/mixpanel/types';
import { DISCO_ADS_PROMO_LINK } from 'utils/constants';
import { block } from '../utils';

export type PostTaskUpgradeSectionProps = {
  modalName: UnlockThisFeatureModal;
};

const PostTaskUpgradeSection: React.FC<PostTaskUpgradeSectionProps> = ({
  modalName,
}) => {
  const dispatch = useDispatch();

  const { onUpgrade: onContextualUpgrade } = useContextualUpgrade({
    allowTierSelection: false,
    purchaseTier: Tier.BASIC,
  });

  const handleUpgradeClick = useCallback((): void => {
    if (modalName) {
      dispatch(onUnlockThisFeature(modalName));
    }

    onContextualUpgrade();
  }, [dispatch, modalName, onContextualUpgrade]);

  return (
    <div>
      <LinkButton
        theme="cta"
        target="_blank"
        rel="noopener noreferrer"
        href={DISCO_ADS_PROMO_LINK}
        className={block('disco-link')}
      >
        Promote your podcast here
      </LinkButton>

      <span className={block('link-divider')}>|</span>

      <LinkButton
        theme="cta"
        className={cn(block('upgrade'), block('disco-link'))}
        onClick={handleUpgradeClick}
      >
        Upgrade to remove clips
      </LinkButton>
    </div>
  );
};

export default PostTaskUpgradeSection;

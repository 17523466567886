import Color, { RGBColor } from 'color';
import CompColors from 'complementary-colors';
import { isUndefined } from 'underscore';

export const getComplementaryColor = (primaryColor: string): string => {
  const color = new CompColors(primaryColor);
  const { a, ...complementaryColorObj } = color.complementary()[1];

  // Color is expecting "alpha" while complementaryColor outputs "a".
  return Color({
    ...complementaryColorObj,
    alpha: !isUndefined(a) ? a : 1,
  }).string();
};

export function generateDiagBg(rgb: RGBColor): string {
  const rgb2 = Color(rgb)
    .darken(0.25)
    .string();

  return `repeating-linear-gradient(135deg, ${rgb}, ${rgb} 2px, ${rgb2} 2px, ${rgb2} 4px)`;
}

export const fadeRgb = (rgb: RGBColor, pct: number): string =>
  Color(rgb)
    .fade(pct)
    .string();

export const getColorHex = (rgb: RGBColor) => Color(rgb).hex();

export const getColorAlpha = (rgb: RGBColor): number => Color(rgb).alpha();

export const darkenRgb = (rgb: RGBColor, pct: number): string =>
  Color(rgb)
    .darken(pct)
    .string();

export const rgbObjectToString = (rgba: RGBColor): string => {
  const { r, g, b, a } = rgba;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const hexToRgba = (hex: string, alpha = 1): string => {
  const color = Color(hex).alpha(alpha);
  const { r, g, b } = color.object();

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const updateRgbaStringAlpha = (
  rgbaString: string,
  newAlpha: number,
): string => {
  return rgbaString.replace(
    /rgba\((\d+),\s*(\d+),\s*(\d+),\s*[\d.]+\)/,
    (_, r, g, b) => {
      return `rgba(${r}, ${g}, ${b}, ${newAlpha})`;
    },
  );
};

import React from 'react';
import { throttle } from 'underscore';
import { Size } from 'types';

export type UseOnTextAssetResize = {
  resizeDelta: Size;
  onResize: (size: Size) => void;
};

const DEFAULT_SIZE = { height: 0, width: 0 };
const RESIZE_LISTENER_THROTLE_MS = 25;

const useOnTextAssetResize = (): UseOnTextAssetResize => {
  const [resizeDelta, setResizeDelta] = React.useState<Size>(DEFAULT_SIZE);

  const handleResize = React.useMemo(
    () => throttle(setResizeDelta, RESIZE_LISTENER_THROTLE_MS),
    [],
  );

  return {
    resizeDelta,
    onResize: handleResize,
  };
};

export default useOnTextAssetResize;

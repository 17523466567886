import {
  Button,
  HeadyGradient,
  PromoPopper as Popper,
  ThemeProvider,
} from '@sparemin/blockhead';
import React, { useState } from 'react';
import LinkButton from 'components/LinkButton';
import { popperItems } from './constants';
import usePromoPopper from './usePromoPopper';
import { block } from './utils';

export interface PromoPopperProps {
  eddyProjectUrl?: string;
}

const PromoPopper: React.FC<PromoPopperProps> = ({ eddyProjectUrl }) => {
  const [triggerElement, triggerRef] = useState(null);

  const {
    showPopper,
    isLoading,
    toggleShowPopper,
    onOpenProject,
    onCreateAssets,
    onDismissPromoPopper,
  } = usePromoPopper({ hasEddyProject: Boolean(eddyProjectUrl) });

  return (
    <ThemeProvider theme="dark">
      <div className={block()}>
        <Popper
          triggerElement={triggerElement}
          show={showPopper}
          bannerSrc="https://static.sparemin.com/static/eddy/promopack-popper-header-image-202312121904.jpg"
          className={block('popper')}
        >
          <Popper.Header
            title={
              eddyProjectUrl
                ? 'Your promo assets are here'
                : 'Want more promo assets?'
            }
            description={
              eddyProjectUrl
                ? 'Headliner AI created these assets from your audio file:'
                : 'We can use your media file to generate these assets:'
            }
          />

          <Popper.ContentsList items={popperItems} />

          <Popper.Footer className={block('footer')}>
            {eddyProjectUrl ? (
              <Button
                as="a"
                className={block('submit-button')}
                href={`${eddyProjectUrl}?source=DownloadPagePopper`}
                onClick={onOpenProject}
                rel="noreferrer noopener"
                target="_blank"
              >
                open project
              </Button>
            ) : (
              <Button
                className={block('submit-button')}
                isDisabled={isLoading}
                onPress={onCreateAssets}
              >
                create assets
              </Button>
            )}

            <LinkButton
              theme="cta"
              uppercase
              size="md"
              className={block('dismiss-button')}
              onClick={onDismissPromoPopper}
            >
              Dismiss
            </LinkButton>
          </Popper.Footer>
        </Popper>

        <Popper.Trigger onPress={toggleShowPopper} ref={triggerRef}>
          <HeadyGradient
            width="48px"
            height="48px"
            className={block('trigger-icon')}
          />
        </Popper.Trigger>
      </div>
    </ThemeProvider>
  );
};

export default PromoPopper;

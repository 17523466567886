import { CaptionFormat } from 'types';
import { createApiAction } from '../utils';
import * as types from './types';

function apiCallAction<M extends types.ServiceMethod, A>(method: M, args: A) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export const createTranscriptRequest = (
  transcript: types.IChunk[],
  participants: types.IParticipant[],
): types.CreateManualTranscriptAction =>
  apiCallAction(types.ServiceMethod.CREATE_MANUAL_TRANSCRIPT, [
    transcript,
    participants,
  ] as types.CreateManualTranscriptArgs);

export const fetchTranscriptByRevisionIdRequest = (
  transcriptId: string,
  revisionId: string,
): types.GetTranscriptByRevisionIdAction =>
  apiCallAction(types.ServiceMethod.GET_TRANSCRIPT_BY_REVISION_ID, [
    transcriptId,
    revisionId,
  ] as types.GetTranscriptByRevisionIdArgs);

export const fetchTranscriptByVideoIdRequest = (
  videoId: number,
): types.GetVideoTranscriptByVideoIdAction =>
  apiCallAction(types.ServiceMethod.GET_VIDEO_TRANSCRIPT_BY_VIDEO_ID, [
    videoId,
  ] as types.GetVideoTranscriptByVideoIdArgs);

export const downloadTranscriptByRevisionIdRequest = (
  transcriptId: string,
  revisionId: string,
  format?: string,
): types.DownloadTranscriptByRevisionIdAction =>
  apiCallAction(types.ServiceMethod.DOWNLOAD_TRANSCRIPT_BY_REVISION_ID, [
    transcriptId,
    revisionId,
    format,
  ] as types.DownloadTranscriptByRevisionIdResult);

export const downloadEpisodeTranscript = (
  episodeId: string,
  format: CaptionFormat,
): types.DownloadEpisodeTranscriptAction =>
  apiCallAction(types.ServiceMethod.DOWNLOAD_EPISODE_TRANSCRIPT, [
    episodeId,
    format,
  ]);

export const updateTranscriptSubchunkAction = (
  transcriptId: string,
  chunkId: string,
  subchunkId: string,
  subchunk: types.ISubChunk,
): types.UpdateTranscriptSubchunkAction =>
  apiCallAction(types.ServiceMethod.UPDATE_TRANSCRIPT_SUBCHUNK, [
    transcriptId,
    chunkId,
    subchunkId,
    subchunk,
  ] as types.UpdateTranscriptSubchunkArgs);

export const updateTranscriptAction = (
  transcriptId: string,
  transcript: types.IUpdateTranscriptRequest,
): types.UpdateTranscriptAction =>
  apiCallAction(types.ServiceMethod.UPDATE_TRANSCRIPT, [
    transcriptId,
    transcript,
  ] as types.UpdateTranscriptArgs);

export const importManualTranscriptAction = (
  file: File | string,
): types.ImportManualTranscriptionAction =>
  apiCallAction(types.ServiceMethod.IMPORT_MANUAL_TRANSCRIPT, [
    file,
  ] as types.ImportManualTranscriptArgs);

export const importFreeFormTranscriptAction = (
  textFile: File | string,
  recordingId: number | undefined,
  videoId: number | undefined,
  startMillis: number | null,
  endMillis: number | null,
): types.ImportFreeFormTranscriptionAction =>
  apiCallAction(types.ServiceMethod.IMPORT_FREE_FORM_TRANSCRIPT, [
    textFile,
    recordingId,
    videoId,
    startMillis,
    endMillis,
  ] as types.ImportFreeFormTranscriptArgs);

export function getFreeFormTranscript(
  jobId: number,
): types.GetFreeFormTranscriptAction {
  return apiCallAction(types.ServiceMethod.GET_FREE_FORM_TRANSCRIPT, [jobId]);
}

export function getTranscriptByVersionId(
  versionId: number,
): types.GetTranscriptByVersionIdAction {
  return apiCallAction(types.ServiceMethod.GET_TRANSCRIPT_BY_VERSION_ID, [
    versionId,
  ]);
}

export function addRecordingToWhitelist(
  recordingId: number,
  language: string,
): types.AddRecordingToWhitelistAction {
  return apiCallAction(types.ServiceMethod.ADD_RECORDING_TO_WHITELIST, [
    recordingId,
    language,
  ]);
}

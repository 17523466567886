import * as React from 'react';
import { useSelector } from 'react-redux';
import { creationConfigurationIdSelector } from 'redux/modules/wizard-export/selectors';
import { WizardExportVideoStep } from '../ExportVideoStep';

interface Props {
  durationSec: number;
  onError: (error: Error) => void;
}

function AudiogramExportVideoStep(props: Props) {
  const { durationSec, onError } = props;
  const widgetId = useSelector(creationConfigurationIdSelector);
  return (
    <WizardExportVideoStep
      widgetId={widgetId}
      modalName="RemoveDiscoClipsAudiogram"
      estimatedDurationSec={Math.max(durationSec * 2, 60)}
      onError={onError}
    />
  );
}

export default AudiogramExportVideoStep;

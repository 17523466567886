import React from 'react';
import ResizeDetector from 'react-resize-detector';

import BackgroundLayer from '../../layers/BackgroundLayer';
import { editorModalPreviewBlock as block } from '../../utils';
import EditorModalCaptionsLayer from './EditorModalCaptionsLayer';
import EditorModalFadeLayer from './EditorModalFadeLayer';
import EditorModalProgressOverlay from './EditorModalProgressLayer';
import EditorModalSlideshowLayer from './EditorModalSlideshowLayer';
import EditorModalSoundwaveLayer from './EditorModalSoundwaveLayer';
import EditorModalTextLayer from './EditorModalTextLayer';
import EditorModalVideoLayer from './EditorModalVideoLayer';
import EditorModalWatermarkLayer from './EditorModalWatermarkLayer';
import useEditorModalBackground from './useEditorModalBackground';

interface EditorModalStaticBackgroundProps {
  assetId: string;
  isCaptionsEditor?: boolean;
  showFadeLayer?: boolean;
}

// This component uses elements of the VideoTemplateEditor, but is rendered in the EditorModals,
// which don't have access to the VideoTemplateEditor context.
// Each asset layer needs the canvas size passed in as a prop, and then needs to correctly
// calculate the size and position of the asset.

const EditorModalStaticBackground: React.FunctionComponent<EditorModalStaticBackgroundProps> = props => {
  const { assetId, isCaptionsEditor, showFadeLayer } = props;

  const [canvasSize, setCanvasSize] = React.useState({ height: 0, width: 0 });

  const handleResize = React.useCallback((width, height) => {
    setCanvasSize({ height, width });
  }, []);

  const orderedLayers = useEditorModalBackground({
    isCaptionsEditor,
    selectedAssetId: assetId,
  });

  return (
    <div className={block('wrapper')}>
      <ResizeDetector handleWidth handleHeight onResize={handleResize}>
        <EditorModalFadeLayer visible={showFadeLayer} />
        <BackgroundLayer />
        {orderedLayers.map(orderedLayer => {
          switch (orderedLayer.type) {
            case 'video':
              return (
                <EditorModalVideoLayer
                  canvasSize={canvasSize}
                  clip={orderedLayer.videoLayer}
                  key={orderedLayer.id}
                />
              );
            case 'soundwave':
              return (
                <EditorModalSoundwaveLayer
                  canvasSize={canvasSize}
                  soundwave={orderedLayer.soundwave}
                  key={orderedLayer.id}
                />
              );
            case 'slideshow':
              return (
                <EditorModalSlideshowLayer
                  slideshow={orderedLayer.slideshow}
                  key={orderedLayer.id}
                />
              );
            case 'text-overlay':
              return (
                <EditorModalTextLayer
                  canvasSize={canvasSize}
                  textOverlay={orderedLayer.textOverlay}
                  key={orderedLayer.id}
                />
              );
            case 'captions':
              return (
                <EditorModalCaptionsLayer
                  canvasSize={canvasSize}
                  captionsOverride={orderedLayer.captionsOverride}
                  key={orderedLayer.id}
                />
              );
            case 'progress':
              return (
                <EditorModalProgressOverlay
                  key={orderedLayer.id}
                  progress={orderedLayer.progress}
                  timer={orderedLayer.timer}
                />
              );
            case 'watermark':
              return (
                <EditorModalWatermarkLayer
                  key={orderedLayer.id}
                  watermark={orderedLayer.watermark}
                />
              );

            default:
              return null;
          }
        })}
      </ResizeDetector>
    </div>
  );
};

export default EditorModalStaticBackground;

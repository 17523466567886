import * as React from 'react';
import { noop } from 'underscore';
import { ApplicationError } from 'utils/ApplicationError';
import { hoursToSec } from 'utils/time';

import { verifyVideo } from 'utils/video';

export type VerifyVideo = (file: File) => void;

interface IChildrenProps {
  verify: VerifyVideo;
}

interface IProps {
  children: (props: IChildrenProps) => JSX.Element;
  onFileAccepted?: (file: File) => void;
  onFileRejected?: (error: ApplicationError, file: File) => void;
  shouldVerify?: (file: File) => boolean;
  maxDurationHours?: number;
}

/**
 * Component to provide video verification.
 *
 * Both MediaUploader (by way of MediaImport) and VideoUploader need to share the same verification
 * logic.  This component provides a `validate` function to its child.  The validate function
 * accepts a file and can be passed as a callback to other components.  If the video passes
 * validation, the onFileAccepted prop will be called with the file.  If the video fails validation,
 * the onFileRejected prop will be called with both the error message and the file that caused
 * the error.
 */
export default class VideoVerification extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onFileAccepted: noop,
    onFileRejected: noop,
    shouldVerify: () => true,
  };

  public componentWillUnmount() {
    if (this.videoVerificationPromise) {
      // TODO need bluebird types for cancel but causes compile errors in other node_modules
      (this.videoVerificationPromise as any)?.cancel?.();
    }
  }

  private videoVerificationPromise: Promise<boolean>;

  private handleVerification = async (file: File) => {
    const {
      onFileRejected,
      onFileAccepted,
      shouldVerify,
      maxDurationHours,
    } = this.props;

    const maxDurationSeconds = hoursToSec(maxDurationHours);

    if (!shouldVerify(file)) {
      onFileAccepted(file);
    } else {
      try {
        this.videoVerificationPromise = verifyVideo(file, maxDurationSeconds);
        await this.videoVerificationPromise;
        this.videoVerificationPromise = undefined;
        onFileAccepted(file);
      } catch (err) {
        onFileRejected(err, file);
      }
    }
  };

  public render() {
    const { children } = this.props;
    return children({ verify: this.handleVerification });
  }
}

export {
  IProps as VideoVerificationProps,
  IChildrenProps as VideoVerificationChildrenProps,
};

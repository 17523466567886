import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PodcastHeading from 'blocks/AutomationCMS/components/PodcastHeading';
import { InfiniteScrollLoader } from 'components/InfiniteScroll';
import {
  cancelWaitForPodcastFeedRefresh,
  isRefreshingPodcastFeedSelector,
} from 'redux/modules/podcast';
import { cmsBlock as block } from '../../utils';
import PodcastFeedData from '../PodcastFeedData';
import ProjectFeedData from '../ProjectFeedData';

export interface AutomationCMSRichBodyProps {
  podcastFeedId: string | undefined;
  podcasts: string[];
}

const AutomationCMSRichBody: React.FC<AutomationCMSRichBodyProps> = ({
  podcastFeedId,
  podcasts,
}) => {
  const dispatch = useDispatch();

  const isRefreshingFeed = useSelector(isRefreshingPodcastFeedSelector);

  React.useEffect(() => {
    return () => {
      dispatch(cancelWaitForPodcastFeedRefresh(podcastFeedId));
    };
  }, [dispatch, podcastFeedId]);

  return (
    <div className={block('body')}>
      <div className={block('body-contents')}>
        <ProjectFeedData active={!podcastFeedId} />

        {isRefreshingFeed ? (
          <>
            <PodcastHeading podcastId={podcastFeedId} isDisabled />
            <InfiniteScrollLoader
              spinnerClassName={block('data-spinner')}
              loadingText="Loading episodes"
            />
          </>
        ) : (
          podcasts.map(id => (
            <PodcastFeedData
              key={id}
              active={id === podcastFeedId}
              podcastFeedId={id}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default AutomationCMSRichBody;

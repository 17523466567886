import { TextOverlay } from 'blocks/TextOverlayModal';
import {
  isLegacyOverlayConvertAble,
  TextOverlayV2,
  validateCompatibleLegacyConfig,
  validateOverlayV2Integrity,
} from 'blocks/TextOverlayModal/v2';
import { StartingActiveModal } from 'blocks/TextOverlaySwitchModal';
import bem from 'utils/bem';

export const block = bem('editor-text-modal');

export const getDefaultActiveModal = (
  selectedOverlay?: TextOverlay | TextOverlayV2,
  shouldDefaultToLegacyModal?: boolean,
): StartingActiveModal | undefined => {
  if (shouldDefaultToLegacyModal) {
    return 'legacy';
  }

  if (!selectedOverlay) {
    return undefined;
  }

  if (!validateCompatibleLegacyConfig(selectedOverlay)) {
    return 'legacy';
  }

  return !validateOverlayV2Integrity(selectedOverlay) &&
    !isLegacyOverlayConvertAble(selectedOverlay)
    ? 'legacy'
    : 'current';
};

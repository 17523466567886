import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'components/ConfirmationModal';
import { ClosedCaptioning } from 'components/icons';
import CircularGradient from 'components/icons/circular-gradient/CircularGradient';
import Modal from 'components/Modal';
import { Tier } from 'redux/middleware/api/plan-service/types';
import { onUnlockThisFeature } from 'redux/modules/mixpanel/actions';
import { replaceModal } from 'redux/modules/modal/actions';

function UnlimitedTranscriptionModalContent() {
  const dispatch = useDispatch();

  const handleUpgradeClick = () => {
    dispatch(onUnlockThisFeature('Transcription'));
    dispatch(
      replaceModal({
        name: 'AddBillingCycle',
        params: {
          tier: Tier.PRO,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
        },
      }),
    );
  };

  return (
    <>
      <ConfirmationModal.Body>
        <ConfirmationModal.Icon>
          <CircularGradient>
            <ClosedCaptioning width={40} />
          </CircularGradient>
        </ConfirmationModal.Icon>
        <ConfirmationModal.Title>
          Unlock unlimited transcription
        </ConfirmationModal.Title>
        <Spacer
          orientation="vertical"
          align="center"
          justify="center"
          space="32px"
        >
          <ConfirmationModal.Content>
            This lets you add captions to as many videos as you like, which
            makes viewers 80% more likely to watch them in full.
          </ConfirmationModal.Content>
        </Spacer>
      </ConfirmationModal.Body>
      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton theme="rainbow" onClick={handleUpgradeClick}>
            Unlock this feature
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
}

export default UnlimitedTranscriptionModalContent;

import { BlurredVideoBackground } from '@sparemin/blockhead';
import * as React from 'react';

import {
  getVideoContainerStyles,
  getVideoStyles,
} from 'blocks/VideoCropper/utils';
import { Pixels } from 'utils/measurement';
import { stringToPx } from 'utils/placement';

import { millisToSec } from 'utils/time';
import { useNavigation } from '../context/NavigationContext';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { MediaIntegrationId } from '../types';
import useEditorLoading from '../useEditorLoading';
import useVideo from '../useVideo';
import { canvasBlock as block } from '../utils';
import RndAsset, { RndAssetProps } from './RndAsset';
import useDraggableVideoAssetCrop from './useDraggableVideoAssetCrop';
import { useVideoTrimmer } from './useVideoTrimmer';
import {
  checkIsDynamicAutoframedVideoClip,
  getVideoClipFramingMethod,
} from './utils';

const { useCallback, useEffect } = React;

type PickedAssetProps = Pick<
  RndAssetProps,
  'onDragStop' | 'onResizeStop' | 'params'
>;

export interface DraggableVideoAssetProps extends PickedAssetProps {
  id: string;
  autoplayVideo?: boolean;
  useDefaultVideoStyle?: boolean;
  enableBlur?: boolean;
}

const VIDEO_COMPONENT_STYLE = {
  height: '100%',
  left: 0,
  top: 0,
  width: '100%',
};

const DraggableVideoAsset: React.FC<DraggableVideoAssetProps> = ({
  id,
  onDragStop,
  onResizeStop,
  params,
  autoplayVideo = true,
  useDefaultVideoStyle = true,
  enableBlur,
}) => {
  const dispatch = useTemplateDispatch();
  const [isEditorLoading, setEditorLoading] = useEditorLoading();
  const [, send] = useNavigation();
  const { canvas, aspectRatio } = useTemplateState();
  const videoClip = useVideo(id);
  const [currentVideoStyle, setCurrentVideoStyle] = React.useState({});
  const [
    videoComponent,
    setVideoComponent,
  ] = React.useState<HTMLVideoElement | null>(null);

  const videoClipRef = React.useRef(videoClip);

  const framingMethod = getVideoClipFramingMethod(videoClip);

  const {
    videoDimensions,
    boundingBox,
    assetContainerBoundingBox,
    onResize,
  } = useDraggableVideoAssetCrop({ videoId: id });

  const isDisabled = checkIsDynamicAutoframedVideoClip(
    videoClip,
    framingMethod,
    aspectRatio,
  );

  useVideoTrimmer(videoComponent, {
    enabled: videoClip?.integrationData?.id === MediaIntegrationId.DYNAMIC,
    startTimeSec: millisToSec(videoClip?.original?.trimStartMillis),
    endTimeSec: millisToSec(videoClip?.original?.trimEndMillis),
    loop: true,
  });

  // when mounted, when canvas size changes or the asset id changes, canvas and
  // original video are meassured.
  // if video placement was not already set, once meassured, VIDEOCLIP_LOAD action
  // is dispatched for setting initial placement
  useEffect(() => {
    const currentVideoClip = videoClipRef.current;

    // if current videoclip was not found, no futher actions are required
    if (!currentVideoClip) {
      return;
    }

    // meassure and video load action should only be dispatched placement was not
    // previously set.
    if (!currentVideoClip.placement) {
      // turn loader on for avoiding video come from nowhere
      setEditorLoading(true);

      // meassure canvas and original video size/position
      const canvasPx = {
        height: new Pixels(canvas.height),
        width: new Pixels(canvas.width),
      };

      const sizePx = stringToPx(currentVideoClip.original.style, canvasPx);

      const position = {
        top: currentVideoClip.original.position.top,
        left: currentVideoClip.original.position.left,
      };
      const positionPx = stringToPx(position, canvasPx);

      setCurrentVideoStyle({
        ...sizePx,
        ...positionPx,
      });

      dispatch({
        type: 'VIDEOCLIP_LOAD',
        payload: {
          id: currentVideoClip.id,
          size: sizePx,
          position: positionPx,
        },
      });
    }
  }, [canvas.height, canvas.width, dispatch, id, setEditorLoading]);

  const handleVideoAssetLoaded = React.useCallback(() => {
    if (isEditorLoading) {
      setEditorLoading(false);
    }
  }, [isEditorLoading, setEditorLoading]);

  const handleMouseDown = useCallback(() => {
    send({
      type: 'CHILD_VIEW_OPEN',
      payload: 'image',
      meta: { source: 'preview', id },
    });
  }, [id, send]);

  const VideoComponent = (
    <div
      className={block('video-container')}
      style={
        boundingBox &&
        getVideoContainerStyles(0, 0, videoDimensions, boundingBox)
      }
    >
      <video
        loop
        muted
        ref={setVideoComponent}
        src={videoClip.videoUrl}
        autoPlay={autoplayVideo}
        className={block('video-asset', {
          disabled: isDisabled,
        })}
        style={
          boundingBox
            ? getVideoStyles(boundingBox)
            : useDefaultVideoStyle
            ? VIDEO_COMPONENT_STYLE
            : currentVideoStyle
        }
        onCanPlay={handleVideoAssetLoaded}
      />
    </div>
  );

  return (
    <>
      <div className={block('blur-background', { active: enableBlur })}>
        <BlurredVideoBackground
          width={canvas.width}
          height={canvas.height}
          video={videoComponent}
        />
      </div>

      {!videoClip.placement ? (
        VideoComponent
      ) : (
        <RndAsset
          {...{ onDragStop, onResizeStop, params }}
          {...videoClip.placement}
          {...(boundingBox && assetContainerBoundingBox)}
          assetId={id}
          onResize={(_, dimensions) => onResize(dimensions)}
          onMouseDown={handleMouseDown}
          disableDragging={isDisabled}
          enableResizing={!isDisabled}
          disableOutline={isDisabled}
        >
          {VideoComponent}
        </RndAsset>
      )}
    </>
  );
};

export default DraggableVideoAsset;

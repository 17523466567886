import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  EditorState,
  VideoTemplateState,
} from 'components/VideoTemplateEditor';
import useVideoProcessor from 'hooks/useVideoProcessor';
import { showNotification } from 'redux/modules/notification';

interface UseCustomizeStepIntroOutroInitConfig {
  editorState: VideoTemplateState;
  setEditorState: (value: React.SetStateAction<VideoTemplateState>) => void;
}

// This hook is specifically intended for loading the initial intro/outro default preferences
// of a project at UCS. If any particular URL is set for either intro or outro it will process
// its url (if it exists), it will upload the video and if successful it will update the initial
// state.
const useCustomizeStepIntroOutroInit = (
  config: UseCustomizeStepIntroOutroInitConfig,
) => {
  const dispatch = useDispatch();
  const { editorState, setEditorState } = config;

  // Pre-uploads intro clip from default preferences
  useVideoProcessor(
    EditorState.getContent(editorState).introOutro.intro?.url,
    EditorState.getContent(editorState).aspectRatio,
    useCallback(() => {
      dispatch(
        showNotification({
          message:
            'Intro clip default preferences might not be loaded properly.',
          level: 'error',
          dismissAfterSec: 5,
          title: "Sorry, we couldn't load the intro info.",
        }),
      );
    }, [dispatch]),
    useCallback(
      ({ source, status, videoUpload }) => {
        if (status === 'ready') {
          setEditorState(s =>
            EditorState.modifyStateContent(s, content => ({
              ...content,
              introOutro: {
                ...content.introOutro,
                intro: {
                  ...content.introOutro.intro,
                  id: videoUpload.id,
                  fileName:
                    typeof source !== 'string' ? source.name : undefined,
                  loaded: true,
                  thumbnailUrl: videoUpload.previewThumbnail.thumbnails[0].url,
                },
              },
            })),
          );
        }
      },
      [setEditorState],
    ),
    undefined,
    'video' // intro/outro are only of type video
  );

  // Pre-uploads outro clip from default preferences
  useVideoProcessor(
    EditorState.getContent(editorState).introOutro.outro?.url,
    EditorState.getContent(editorState).aspectRatio,
    useCallback(() => {
      dispatch(
        showNotification({
          message:
            'Outro clip default preferences might not be loaded properly.',
          level: 'error',
          dismissAfterSec: 5,
          title: "Sorry, we couldn't load the outro info.",
        }),
      );
    }, [dispatch]),
    useCallback(
      ({ source, status, videoUpload }) => {
        if (status === 'ready') {
          setEditorState(s =>
            EditorState.modifyStateContent(s, content => ({
              ...content,
              introOutro: {
                ...content.introOutro,
                outro: {
                  ...content.introOutro.outro,
                  id: videoUpload.id,
                  fileName:
                    typeof source !== 'string' ? source.name : undefined,
                  loaded: true,
                  thumbnailUrl: videoUpload.previewThumbnail.thumbnails[0].url,
                },
              },
            })),
          );
        }
      },
      [setEditorState],
    ),
    undefined,
    'video' // intro/outro are only of type video
  );
};

export default useCustomizeStepIntroOutroInit;

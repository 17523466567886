import { MutableRefObject } from 'react';
import { pick } from 'underscore';
import WaveSurferJs from 'wavesurfer.js';

// eslint-disable-next-line
import { PluginDefinition } from 'wavesurfer.js/types/plugin';
import { Size } from 'types';
import bem from 'utils/bem';
import { clamp } from 'utils/numbers';
import { RegionProps } from './types';

export const block = bem('wavesurfer');

export function withCurrent<T>(ref: MutableRefObject<T>) {
  return <U>(fn: (val: T) => U): U => {
    if (!ref.current) {
      return undefined;
    }
    return fn(ref.current);
  };
}

export function positionToProgress(wavesurfer: WaveSurferJs, position: number) {
  return clamp(position / wavesurfer.getDuration(), 0, 1);
}

interface Observer {
  on: (name: string, fn: (...args: any[]) => void) => void;
  un: (name: string, fn: (...args: any[]) => void) => void;
}

export function attachHandler(name: string) {
  return <A extends any[]>(ws: Observer, handler: (...args: A) => void) => {
    ws.on(name, handler);
    return () => ws.un(name, handler);
  };
}

export function getRegionObject(props: RegionProps) {
  return pick(props, 'id', 'start', 'end', 'loop', 'drag', 'resize', 'color');
}

export const addPlugin = (ws: WaveSurferJs, plugin: PluginDefinition) => {
  ws.addPlugin(plugin);

  const destroyPlugin = () => {
    if (ws.getActivePlugins()[plugin.name]) {
      ws.destroyPlugin(plugin.name);
    }
  };

  if (ws.isReady) {
    ws.initPlugin(plugin.name);
    return destroyPlugin;
  }

  const { un } = ws.once('ready', () => {
    // condition prevents double initialization
    if (!ws.getActivePlugins()[plugin.name]) {
      ws.initPlugin(plugin.name);
    }
  });

  return () => {
    un();
    destroyPlugin();
  };
};

/**
 * Creates a smooth looping gradient wave pattern for the wavesurfer.
 */
export const createWaveColorGradient = (
  containerSize: Size<number>,
  startColor: string,
  endColor: string,
): CanvasGradient => {
  const { width } = containerSize;

  // Create a 2D rendering context for an offscreen canvas.
  const ctx = document.createElement('canvas').getContext('2d');
  // Create a linear gradient from left to right.
  const gradient = ctx.createLinearGradient(0, 0, width, 0);

  // Starting color.
  gradient.addColorStop(0, startColor);
  // Midpoint transition color.
  gradient.addColorStop(0.75, endColor);
  // End color matches start for smooth looping.
  gradient.addColorStop(1, startColor);

  // Return the generated gradient.
  return gradient;
};

import cn from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import AudioClipper, {
  AudioClipperComponent,
  AudioClipperProps,
} from 'components/AudioClipper';
import VideoPlayer, { VideoPlayerProps } from 'components/VideoPlayer';

import usePrevious from 'hooks/usePrevious';
import { AspectRatioName, Timing } from 'types';
import { createChainedFunction } from 'utils/functions';
import { block } from './utils';

export type VideoClipperInstance = {
  playSelection: (newStartMillis: number, newEndMillis: number) => void;
};

type PlayerProps = Pick<
  VideoPlayerProps,
  | 'src'
  | 'scaling'
  | 'poster'
  | 'onPlay'
  | 'onPause'
  | 'onDurationChange'
  | 'onLoadedMetadata'
>;

type ClipperProps = Pick<
  AudioClipperProps,
  | 'showMinimap'
  | 'showTimelineZoomSlider'
  | 'captionsControl'
  | 'wavesurferClassName'
  | 'controlsClassName'
  | 'formClassName'
>;

export type VideoClipperProps = PlayerProps &
  ClipperProps & {
    aspectRatioName: AspectRatioName;
    startMillis: number;
    endMillis: number;
    defaultClipperRegion?: Timing;
    durationMillis: number;
    maxDurationMillis?: number;
    defaultPositionMillis?: number;
    playing?: boolean;
    playerClassName?: string;
    disableClipper?: boolean;
    className?: string;
    playerRef?: (el: VideoPlayer) => void;
    onSelectedMillisChange?: (start: number, end: number) => void;
  };

const VideoClipper = forwardRef<VideoClipperInstance, VideoClipperProps>(
  (
    {
      src,
      startMillis,
      endMillis,
      defaultClipperRegion,
      durationMillis,
      maxDurationMillis,
      defaultPositionMillis = 0,
      disableClipper = false,
      aspectRatioName,
      scaling,
      poster,
      playing = false,
      captionsControl,
      wavesurferClassName,
      controlsClassName,
      formClassName,
      showMinimap,
      showTimelineZoomSlider,
      playerClassName,
      className,
      playerRef,
      onPlay,
      onPause,
      onDurationChange,
      onSelectedMillisChange,
      onLoadedMetadata,
    },
    ref,
  ) => {
    const [positionMillis, setPositionMillis] = useState<number>(
      defaultPositionMillis,
    );
    const [stopPlaybackAtMillis, setStopPlaybackAtMillis] = useState<
      number | null
    >(null);
    const [lastPlayPosMillis, setLastPlayPosMillis] = useState<number>(0);
    const [videoPlayer, setVideoPlayer] = useState<VideoPlayer | null>(null);

    const audioClipperRef = useRef<AudioClipperComponent | null>(null);

    const prevDisableClipper = usePrevious(disableClipper);

    const handlePlay = useCallback((): void => {
      setLastPlayPosMillis(positionMillis);
      onPlay();
    }, [onPlay, positionMillis]);

    const handleTimeUpdate = useCallback(
      (newPositionMillis: number): void => {
        videoPlayer.seek(newPositionMillis);

        setPositionMillis(newPositionMillis);

        if (
          playing &&
          lastPlayPosMillis < stopPlaybackAtMillis &&
          positionMillis >= stopPlaybackAtMillis
        ) {
          onPause();
        }

        if (
          playing &&
          lastPlayPosMillis < endMillis &&
          positionMillis >= endMillis
        ) {
          onPause();
        }
      },
      [
        endMillis,
        lastPlayPosMillis,
        onPause,
        videoPlayer,
        playing,
        positionMillis,
        stopPlaybackAtMillis,
      ],
    );

    const handleSeek = useCallback(
      (progressPercentage: number): void => {
        handleTimeUpdate(durationMillis * progressPercentage);
      },
      [handleTimeUpdate, durationMillis],
    );

    const playSelection = useCallback(
      (newStartMillis: number, newEndMillis: number): void => {
        if (videoPlayer) {
          videoPlayer.seek(newStartMillis);
          videoPlayer.play();
          setStopPlaybackAtMillis(newEndMillis);
        }
      },
      [videoPlayer],
    );

    useImperativeHandle(
      ref,
      () => ({
        playSelection,
      }),
      [playSelection],
    );

    useEffect(() => {
      if (prevDisableClipper !== disableClipper) {
        onPause();
        audioClipperRef?.current?.pause();
        handleTimeUpdate(0);
      }
    }, [disableClipper, handleTimeUpdate, onPause, prevDisableClipper]);

    return (
      <div className={cn(block(), className)}>
        <div className={cn(block('video-player-container'))}>
          <div
            className={cn(
              block('video-player-wrapper', { [aspectRatioName]: true }),
              playerClassName,
            )}
          >
            <VideoPlayer
              {...{
                src,
                poster,
                scaling,
                playing,
                onDurationChange,
                onLoadedMetadata,
              }}
              muted={!disableClipper}
              aspectRatio={aspectRatioName}
              bigPlayButton={false}
              controls={disableClipper ? 'show' : 'hide'}
              className={cn(block('video-player'))}
              ref={createChainedFunction(playerRef, setVideoPlayer)}
              volume={0}
              onPlay={() => {
                handlePlay();
                audioClipperRef?.current?.play();
              }}
              onPause={() => {
                onPause();
                audioClipperRef?.current?.pause();
              }}
            />
          </div>
        </div>

        {!disableClipper && (
          <AudioClipper
            {...{
              src,
              defaultPositionMillis,
              playing,
              wavesurferClassName,
              controlsClassName,
              formClassName,
              showMinimap,
              showTimelineZoomSlider,
              maxDurationMillis,
              captionsControl,
              onPause,
            }}
            defaultAudioClipDurationMillis={maxDurationMillis}
            ref={audioClipperRef}
            backend="MediaElement"
            className={block('audio-clipper-container')}
            defaultRegion={defaultClipperRegion}
            showWaveformLoader={false}
            region={{
              startMillis,
              endMillis,
            }}
            onPlay={handlePlay}
            onSeek={handleSeek}
            onRegionUpdate={region => {
              onSelectedMillisChange?.(region.startMillis, region.endMillis);
            }}
          />
        )}
      </div>
    );
  },
);

export default VideoClipper;

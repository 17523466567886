import { AutoframingEffect, Tooltip } from '@sparemin/blockhead';
import React, { ForwardedRef } from 'react';
import { getAspectRatioName } from 'utils/aspect-ratio';

import { useNavigation } from '../context/NavigationContext';
import { useEditorDispatch } from '../context/VideoEditorDispatchContext';

import { useEditorState } from '../context/VideoEditorStateContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import { OPTION_TILE_FEATURE_PATHS } from '../state/constants';
import AccessControlOptionTile, {
  AccessControlOptionTileProps,
} from './AccessControlOptionTile';

type PickedTileProps<P = any> = Pick<AccessControlOptionTileProps<P>, 'params'>;

export interface EffectsTileProps<P = unknown> extends PickedTileProps<P> {
  className?: string;
}

const EffectsTile = React.forwardRef(
  <P extends {} = unknown>(
    props: EffectsTileProps<P>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { className, params } = props;

    const dispatch = useEditorDispatch();
    const { disabled: isDisabledByEditor } = useEditorState();
    const { aspectRatio, videoClips } = useTemplateState();
    const [, send] = useNavigation();

    // This tile should only be visible if a dynamic video clip is available.
    // As a convention for now "dynamic" integrations videos are considered
    // the ones that support autoframing.
    const dynamicVideoClipId = Object.values(videoClips.data).find(
      videoClip => videoClip.integrationData.id === 'dynamic',
    )?.id;

    const handleClick = React.useCallback((): void => {
      if (dynamicVideoClipId) {
        send({
          type: 'CHILD_VIEW_OPEN',
          payload: 'effects',
          meta: { source: 'options', id: dynamicVideoClipId },
        });

        dispatch({
          type: 'ASSET_SELECT',
          payload: { id: dynamicVideoClipId },
        });
      }
    }, [dispatch, dynamicVideoClipId, send]);

    // Omits the tile when the dynamic video clip is not available:
    // this will hide it at other wizards different from the Video Wizard
    if (!dynamicVideoClipId) {
      return null;
    }

    // The effects tile should be disabled when the aspect ratio is landscape
    // as autoframing is not available for it.
    const isLandscape = getAspectRatioName(aspectRatio) === 'landscape';
    const isDisabled = isDisabledByEditor || isLandscape;

    return (
      <Tooltip
        arrow
        contents="Autoframe is not available on landscape orientations"
        isDisabled={!isLandscape}
      >
        <div>
          <AccessControlOptionTile
            ref={ref}
            className={className}
            disabled={isDisabled}
            featurePath={OPTION_TILE_FEATURE_PATHS.effects}
            icon={<AutoframingEffect fontSize={70} />}
            id="template-effects-tile"
            label="effects"
            onClick={handleClick}
            params={params}
          />
        </div>
      </Tooltip>
    );
  },
);

export default EffectsTile;

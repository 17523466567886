import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  canGenerateEmbedVideoSocialPostCaptionsSelector,
  createEmbedVideoSocialPostCaptions,
  embedVideoSocialPostCaptionInfoByPlatformSelector,
  hasEmbedVideoSocialPostCaptionInfoSelector,
} from 'redux/modules/download';
import { UseSocialPostCaptionsResult } from './types';

export type UseEmbedVideoSocialPostCaptionsResult = UseSocialPostCaptionsResult;

export default function useEmbedVideoSocialPostCaptions(
  videoId: number | undefined,
): UseEmbedVideoSocialPostCaptionsResult {
  const dispatch = useDispatch();

  const socialPostCaptionInfoByPlatform = useSelector(
    embedVideoSocialPostCaptionInfoByPlatformSelector,
  );

  const hasSocialPostCaptions = useSelector(
    hasEmbedVideoSocialPostCaptionInfoSelector,
  );

  const canGenerateSocialPostCaptions = useSelector(
    canGenerateEmbedVideoSocialPostCaptionsSelector,
  );

  const handleCreateSocialPostCaptions = useCallback(() => {
    if (videoId && canGenerateSocialPostCaptions) {
      dispatch(createEmbedVideoSocialPostCaptions(videoId));
    }
  }, [canGenerateSocialPostCaptions, videoId, dispatch]);

  return {
    canGenerateSocialPostCaptions,
    hasSocialPostCaptions,
    socialPostCaptionInfoByPlatform,
    createSocialPostCaptions: handleCreateSocialPostCaptions,
  };
}

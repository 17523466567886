import { List, Record } from 'immutable';

import { State } from './types';

export const stateFactory = Record<State>({
  groupClipSuggestionsStatus: undefined,
  dislikedGroupSuggestionIds: List(),
  creationClipSuggestionsStatus: undefined,
  dislikedCreationSuggestionIds: List(),
  subscriptionItemId: undefined,
  isBlockedError: undefined,
});

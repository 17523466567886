import React from 'react';

import { PostTaskProps } from 'blocks/PostTask/types';
import Modal from 'components/Modal';
import PostTask from '../../components/PostTask';
import { blockOverlay } from '../../utils';
import PostTaskDiscoSection from './PostTaskDiscoSection';

interface PostTaskDiscoOverlayProps
  extends Pick<PostTaskProps, 'title' | 'subTitle' | 'headerContent'> {
  visible: boolean;
}

const PostTaskDiscoOverlay: React.FunctionComponent<PostTaskDiscoOverlayProps> = props => {
  const { title, subTitle, headerContent, visible } = props;

  return (
    <Modal
      backdrop="static"
      show={visible}
      backdropClassName={blockOverlay('backdrop')}
      className={blockOverlay({ disco: true })}
    >
      <PostTask
        {...{ title, subTitle, headerContent }}
        banner={<PostTaskDiscoSection />}
      />
    </Modal>
  );
};

export default PostTaskDiscoOverlay;

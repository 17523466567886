import { ColorPaletteState } from '@sparemin/blockhead';
import * as React from 'react';
import _ from 'underscore';

import { FileUploadMethod } from 'components/FileUploader/FileUploaderDropzone';
import { SearchInputProps } from 'components/SearchInput';
import Tabs from 'components/Tabs';
import { MediaImportedArgs } from 'containers/MediaUploadModal/types';
import {
  AiImageType,
  IMediaMetadata,
} from 'redux/middleware/api/image-search-service';
import { constants as mediaConstants } from 'redux/modules/media';
import { AspectRatioName, ImageOriginId, SourceImageOrigin } from 'types';
import { ApplicationError } from 'utils/ApplicationError';
import { isSupportedVideoFile } from 'utils/formats';
import CreateTabContents from './CreateTabContents';
import GIFsSearch from './GIFsSearch';
import ImageSearch from './ImageSearch';
import MediaUploadTab from './MediaUploadTab';
import { ContentTypeStatus } from './types';
import VideoSearch from './VideoSearch';

export type MediaType = 'image' | 'video' | 'gif-video' | 'ai-asset';
export type TabName = 'image' | 'video' | 'gif-video' | 'upload' | 'canva';
interface PickedSearchProps {
  onSearchEngineSelect?: (engine: string) => void;
  onSearchInputChange?: (value: string) => void;
  onSearchSubmit?: SearchInputProps['onSubmit'];
  onImageClick?: (
    imageUrl: string,
    metadata: IMediaMetadata,
    engine: string,
  ) => void;
  onVideoClick?: (url: string, poster?: string, engine?: string) => void;
  onGifClick?: (url: string, poster: string, engine: string) => void;
  onAiAssetClick?: (url: string, poster: string) => void;
  onKeywordClick?: (keyword: string) => void;
  onMediaTypeSelect?: (type: MediaType) => void;
  onMediaImported?: (mediaImportedArgs: MediaImportedArgs) => void;
}

export interface IProps extends PickedSearchProps {
  aspectRatio: number;
  disableAudioUploadSuggestion?: boolean;
  isControlledModal?: boolean;
  selectedTab?: TabName;
  selectedGifEngine?: string;
  selectedImageEngine?: string;
  selectedVideoEngine?: string;
  contentType?: string;
  contentTypeStatus?: ContentTypeStatus;
  maxFileSizeMb: number;
  maxDurationHour: number;
  aspectRatioName?: AspectRatioName;
  imageType?: AiImageType;
  colorPaletteState: ColorPaletteState;
  onImageTypeChange: (value: AiImageType) => void;
  onAspectRatioNameChange: (value: AspectRatioName) => void;
  onError?: (error: ApplicationError, file?: File) => void;
  onFileAccepted?: (file: File, type: MediaType) => void;
  onImageAccepted?: (url: string) => void;
  onVideoAccepted?: (url: string) => void;
  onUrlRequested?: (url: string) => void;
  onUrlAccepted?: (
    url: string,
    type: MediaType,
    origin?: SourceImageOrigin,
  ) => void;
  onTabSelect?: (tabName: TabName) => void;
  onAnimateGatedClick?: () => void;
  searchInputValue?: string;
  uploadMethod?: FileUploadMethod;
  isPollingImageToVideo?: boolean;
}

export default class MediaImport extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    contentType: '',
    contentTypeStatus: undefined,
    onError: _.noop,
    onFileAccepted: _.noop,
    onImageClick: _.noop,
    onSearchEngineSelect: _.noop,
    onSearchSubmit: _.noop,
    onTabSelect: _.noop,
    onUrlAccepted: _.noop,
    selectedGifEngine: 'giphy',
    selectedImageEngine: 'textToImage',
    selectedTab: 'image',
    selectedVideoEngine: 'textToVideo',
  };

  private importUrl: string;

  private static isImage(fileType: string) {
    const imagePattern = /^image\//;
    return imagePattern.test(fileType);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>) {
    const {
      contentType: nextContentType,
      contentTypeStatus: nextStatus,
    } = nextProps;
    const {
      contentTypeStatus: oldStatus,
      onImageAccepted,
      onVideoAccepted,
      onError,
    } = this.props;

    if (
      oldStatus !== nextStatus &&
      oldStatus === mediaConstants.STATUS_PENDING
    ) {
      if (nextStatus === mediaConstants.STATUS_SUCCESS) {
        if (MediaImport.isImage(nextContentType)) {
          onImageAccepted(this.importUrl);
        } else if (isSupportedVideoFile(nextContentType)) {
          onVideoAccepted(this.importUrl);
        } else {
          onError(
            new ApplicationError(
              'Error importing from url: the url is not an image or a video.',
              'ER016',
            ),
          );
        }
      } else if (nextStatus === mediaConstants.STATUS_FAILURE) {
        onError(
          new ApplicationError(
            'Error importing from url: the url points to an unsupported file type.',
            'ER006',
          ),
        );
      }
    }
  }

  private handleUrlRequested = (url: string) => {
    const { onUrlRequested } = this.props;
    this.importUrl = url;
    onUrlRequested?.(url);
  };

  private isMediaTypeTab = (tabKey: string): tabKey is MediaType => {
    return ['image', 'video', 'gif-video'].includes(tabKey);
  };

  private handleTabSelect = (tabKey: string | number) => {
    const { onTabSelect, onMediaTypeSelect } = this.props;

    onTabSelect(tabKey as TabName);

    if (this.isMediaTypeTab(tabKey as string)) {
      onMediaTypeSelect(tabKey as MediaType);
    }
  };

  private handleFileAccepted = (file: File) => {
    const { onFileAccepted } = this.props;
    const type = isSupportedVideoFile(file) ? 'video' : 'image';
    onFileAccepted(file, type);
  };

  private handleImageCreated = (url: string, canvaDesignId: string) => {
    const { onUrlAccepted } = this.props;
    onUrlAccepted(url, 'image', {
      canvaDesignId,
      origin: ImageOriginId.CANVA,
    });
  };

  public render() {
    const {
      aspectRatio,
      disableAudioUploadSuggestion,
      maxFileSizeMb,
      maxDurationHour,
      onError,
      onGifClick,
      onImageClick,
      onVideoClick,
      onKeywordClick,
      onSearchEngineSelect,
      onSearchInputChange,
      onSearchSubmit,
      selectedGifEngine,
      selectedImageEngine,
      selectedTab,
      selectedVideoEngine,
      searchInputValue,
      aspectRatioName,
      imageType,
      colorPaletteState,
      uploadMethod,
      onImageTypeChange,
      onAspectRatioNameChange,
      onMediaImported,
      isPollingImageToVideo,
      onAnimateGatedClick,
      onAiAssetClick,
    } = this.props;

    return (
      <Tabs
        activeTabKey={selectedTab}
        className="add-media-modal__select-tabs"
        id="image-select-tabs"
        onTabSelect={this.handleTabSelect}
        tabs={[
          {
            content: (
              <ImageSearch
                id="image"
                className="add-media-modal__search"
                onKeywordClick={onKeywordClick}
                onEngineSelect={onSearchEngineSelect}
                onInputChange={onSearchInputChange}
                onImageClick={onImageClick}
                onTextToImageSuccess={onAiAssetClick}
                onSubmit={onSearchSubmit}
                selectedImageEngine={selectedImageEngine}
                query={searchInputValue}
                {...{
                  aspectRatioName,
                  imageType,
                  colorPaletteState,
                  onImageTypeChange,
                  onAspectRatioNameChange,
                  onAnimateGatedClick,
                }}
              />
            ),
            isDisabled: isPollingImageToVideo,
            contentClassName: 'add-media-modal__import-tab',
            tabKey: 'image',
            title: 'Images',
          },
          {
            content: (
              <VideoSearch
                id="video"
                className="add-media-modal__search"
                onKeywordClick={onKeywordClick}
                onEngineSelect={onSearchEngineSelect}
                onInputChange={onSearchInputChange}
                onVideoClick={onVideoClick}
                onUnlockFeatureClick={onAnimateGatedClick}
                onTextToVideoSuccess={onAiAssetClick}
                onSubmit={onSearchSubmit}
                selectedVideoEngine={selectedVideoEngine}
                query={searchInputValue}
                {...{
                  aspectRatioName,
                  imageType,
                  colorPaletteState,
                  onImageTypeChange,
                  onAspectRatioNameChange,
                }}
              />
            ),
            isDisabled: isPollingImageToVideo,
            contentClassName: 'add-media-modal__import-tab',
            tabKey: 'video',
            title: 'Videos',
          },
          {
            content: (
              <GIFsSearch
                id="gif-video"
                className="add-media-modal__search"
                onKeywordClick={onKeywordClick}
                onEngineSelect={onSearchEngineSelect}
                onInputChange={onSearchInputChange}
                onGifClick={onGifClick}
                onSubmit={onSearchSubmit}
                selectedGifEngine={selectedGifEngine}
                query={searchInputValue}
              />
            ),
            isDisabled: isPollingImageToVideo,
            contentClassName: 'add-media-modal__import-tab',
            tabKey: 'gif-video',
            title: 'GIFs',
          },
          {
            content: (
              <MediaUploadTab
                disableAudioUploadSuggestion={disableAudioUploadSuggestion}
                onFileAccepted={this.handleFileAccepted}
                onFileRejected={onError}
                aspectRatio={aspectRatio}
                maxFileSizeMb={maxFileSizeMb}
                maxDurationHour={maxDurationHour}
                onUrlRequested={this.handleUrlRequested}
                uploadMethod={uploadMethod}
                onMediaImported={onMediaImported}
              />
            ),
            isDisabled: isPollingImageToVideo,
            contentClassName: 'add-media-modal__import-tab',
            tabKey: 'upload',
            title: 'Upload',
          },
          {
            content: (
              <CreateTabContents
                aspectRatio={aspectRatio}
                onImageCreated={this.handleImageCreated}
              />
            ),
            isDisabled: isPollingImageToVideo,
            tabKey: 'canva',
            title: 'Canva',
          },
        ]}
      />
    );
  }
}

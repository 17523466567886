import * as Sentry from '@sentry/browser';
// @ts-ignore
import ExtendoError from 'extendo-error';
import request from 'superagent';
import { getAudioUpload } from 'redux/middleware/api/recording-upload-service/actions';
import { GetAudioUploadResult } from 'redux/middleware/api/recording-upload-service/types';
import { IApiResponse } from 'redux/middleware/api/types';
import { entireAudioInstancesSelector } from 'redux/modules/entities/selectors';
import { ThunkAction } from 'redux/types';
import {
  RECORDING_IMPORT_POLL_INTERVAL_MILLIS,
  RECORDING_IMPORT_POLL_MAX_ATTEMPTS,
  STANDARDIZATION_POLL_INTERVAL_MILLIS,
  STANDARDIZATION_POLL_MAX_ATTEMPTS,
} from 'utils/constants';
import reduxPoll, { PollingTimeoutError } from 'utils/redux-poll';
import { Type } from './action-types';
import {
  entireAudioByEntireAudioInstanceIdSelector,
  entireAudioIdSelector,
  waveformSelector,
  waveformUrlSelector,
} from './selectors';

const WAVEFORM_POLL_ID = 'async-audio-clipper/waveform-poll';
const STANDARDIZATION_POLL_ID = 'async-audio-clipper/standardization-poll';

class StandardizationError extends ExtendoError {}

const cancelStandardization = (entireAudioInstanceId: number) => ({
  type: Type.CANCEL_STANDARDIZATION,
  payload: { entireAudioInstanceId },
});

const waitForStandardization = (
  entireAudioInstanceId: number,
): ThunkAction<Promise<IApiResponse<GetAudioUploadResult>>> => (
  dispatch,
  getState,
) =>
  reduxPoll(dispatch, () => dispatch(getAudioUpload(entireAudioInstanceId)), {
    id: STANDARDIZATION_POLL_ID,
    intervalMillis: STANDARDIZATION_POLL_INTERVAL_MILLIS,
    maxAttempts: STANDARDIZATION_POLL_MAX_ATTEMPTS,
    shouldContinue: err => {
      const entireAudio = entireAudioByEntireAudioInstanceIdSelector(
        entireAudioInstanceId,
      )(getState());
      const standardizedStatus = entireAudio.getIn(['standardized', 'status']);

      if (err || ['error', 'errorAck'].includes(standardizedStatus)) {
        throw new StandardizationError(
          err?.message || `Standardization status: ${standardizedStatus}`,
        );
      }

      return !['completed', 'skipStandardization'].includes(standardizedStatus);
    },
  }).catch(error => {
    if (
      error instanceof PollingTimeoutError ||
      error instanceof StandardizationError
    ) {
      dispatch(cancelStandardization(entireAudioInstanceId));
      return null;
    }
    return Promise.reject(error);
  });

const waitForWaveform = (
  entireAudioInstanceId: number,
): ThunkAction<Promise<IApiResponse<GetAudioUploadResult>>> => (
  dispatch,
  getState,
) =>
  reduxPoll(dispatch, () => dispatch(getAudioUpload(entireAudioInstanceId)), {
    id: WAVEFORM_POLL_ID,
    intervalMillis: RECORDING_IMPORT_POLL_INTERVAL_MILLIS,
    maxAttempts: RECORDING_IMPORT_POLL_MAX_ATTEMPTS,
    shouldContinue: err => {
      const entireAudio = entireAudioByEntireAudioInstanceIdSelector(
        entireAudioInstanceId,
      )(getState());
      const waveformStatus = entireAudio.getIn(['waveform', 'status']);

      Sentry.addBreadcrumb({
        level: Sentry.Severity.Info,
        category: 'async-audio-clipper',
        data: {
          entireAudioInstanceId,
          entireAudioId: entireAudio.id,
          waveformStatus,
        },
      });

      if (err) {
        throw err;
      }

      if (['error', 'errorAck'].includes(waveformStatus)) {
        throw new Error('Error loading audio waveform');
      }

      return waveformStatus !== 'completed';
    },
  });

export const getAudioWaveform = (
  entireAudioInstanceId: number,
): ThunkAction<Promise<void>> => (dispatch, getState) => {
  const entireAudioInstances = entireAudioInstancesSelector(getState());
  const newAudioId = entireAudioInstances.getIn([
    `${entireAudioInstanceId}`,
    'entireAudio',
  ]);

  const currentAudioId = entireAudioIdSelector(getState());
  const currentWaveform = waveformSelector(getState());

  const dispatchSuccessAction = (waveform: number[]) =>
    dispatch({
      payload: { waveform },
      type: Type.WAIT_FOR_WAVEFORM_SUCCESS,
    });

  if (newAudioId === currentAudioId && currentWaveform) {
    dispatchSuccessAction(currentWaveform);
    return Promise.resolve();
  }

  dispatch({
    payload: { entireAudioInstanceId },
    type: Type.WAIT_FOR_WAVEFORM_REQUEST,
  });

  return dispatch(waitForStandardization(entireAudioInstanceId))
    .then(() => dispatch(waitForWaveform(entireAudioInstanceId)))
    .then(() => {
      const waveformUrl = waveformUrlSelector(getState());
      return request
        .get(waveformUrl)
        .then(res => {
          dispatchSuccessAction(res.body.data);
        })
        .catch(err => {
          dispatch({ type: Type.WAIT_FOR_WAVEFORM_FAILURE });
          throw err;
        });
    });
};

export const clearWaveform = () => dispatch =>
  dispatch({
    type: Type.WAVEFORM_CLEAR,
  });

export const loadMediaFailure = (error: Error) => dispatch => {
  dispatch({
    type: Type.LOAD_MEDIA_FAILURE,
    payload: error,
    error: true,
  });
};

import { Description, ThemeProvider } from '@sparemin/blockhead';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  makeProjectAspectRatioSelector,
  makeProjectSelector,
} from 'redux/modules/entities';
import { block, getProjectRowThumbnailAspectRatioValue } from '../utils';
import { useProjectRow } from './ProjectRowContext';

export interface ProjectRowThumbnailProps {}

const ProjectRowThumbnail: React.FC<ProjectRowThumbnailProps> = () => {
  const { projectId } = useProjectRow();

  const { projectType, detail } =
    useSelector(useMemo(() => makeProjectSelector(projectId), [projectId])) ??
    {};
  const aspectRatio = useSelector(
    useMemo(() => makeProjectAspectRatioSelector(projectId), [projectId]),
  );

  const aspectRatioValue = getProjectRowThumbnailAspectRatioValue(aspectRatio);

  return (
    <ThemeProvider theme="flip">
      <div className={block('thumbnail-container')}>
        <Description.Thumbnail
          alt="Project artwork."
          src={
            projectType === 'plainProject'
              ? detail?.thumbnailUrl
              : [
                  detail?.thumbnailUrl,
                  detail?.thumbnailUrl,
                  detail?.thumbnailUrl,
                ]
          }
          ratio={aspectRatioValue}
        />
      </div>
    </ThemeProvider>
  );
};

export default ProjectRowThumbnail;

import { MediaAssetType } from '../types';

type DoubleClickChangeTypeMap = Record<
  MediaAssetType,
  'IMAGE_LAYER_DOUBLE_CLICK' | 'VIDEOCLIP_LAYER_DOUBLE_CLICK'
>;
type PlacementChangeTypeMap = Record<
  MediaAssetType,
  'IMAGE_PLACEMENT_CHANGE' | 'VIDEOCLIP_PLACEMENT_CHANGE'
>;
type WheelChangeTypeMap = Record<
  MediaAssetType,
  'IMAGE_LAYER_WHEEL' | 'VIDEOCLIP_LAYER_WHEEL'
>;

export const DOUBLECLICK_CHANGE_TYPE_MAP: DoubleClickChangeTypeMap = {
  image: 'IMAGE_LAYER_DOUBLE_CLICK',
  videoClip: 'VIDEOCLIP_LAYER_DOUBLE_CLICK',
};

export const PLACEMENT_CHANGE_TYPE_MAP: PlacementChangeTypeMap = {
  image: 'IMAGE_PLACEMENT_CHANGE',
  videoClip: 'VIDEOCLIP_PLACEMENT_CHANGE',
};

export const WHEEL_CHANGE_TYPE_MAP: WheelChangeTypeMap = {
  image: 'IMAGE_LAYER_WHEEL',
  videoClip: 'VIDEOCLIP_LAYER_WHEEL',
};

import cn from 'classnames';
import * as React from 'react';

import { BannerPageHeader, BannerPageHeaderProps } from '../BannerPage';
import PaidFeatures from './PaidFeatures';
import { block } from './utils';

export type PaidSubscriptionHeaderProps = BannerPageHeaderProps;

interface PaidSubscriptionHeaderComponent
  extends React.FC<PaidSubscriptionHeaderProps> {
  Features: typeof PaidFeatures;
}

const PaidSubscriptionHeader: PaidSubscriptionHeaderComponent = ({
  className,
  ...props
}) => (
  <BannerPageHeader className={cn(block('header'), className)} {...props} />
);

PaidSubscriptionHeader.Features = PaidFeatures;

export default PaidSubscriptionHeader;

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { PurchaseableTier, Tier } from 'redux/middleware/api/plan-service';
import { unshiftModal } from 'redux/modules/modal';
import { Modal } from 'redux/modules/modal/types';
import { Dispatch } from 'redux/types';
import useBillingCycleModal from './useBillingCycleModal';

interface UseContextualUpgradeConfig {
  allowTierSelection?: boolean;
  openModal?: (modal: Modal, val?: any) => void;
  purchaseTier?: PurchaseableTier;
}

interface UseContextualUpgradeResult {
  onUpgrade?: () => Promise<boolean | undefined> | void;
}

export default function useContextualUpgrade({
  allowTierSelection,
  openModal = unshiftModal,
  purchaseTier = Tier.PRO,
}: UseContextualUpgradeConfig = {}): UseContextualUpgradeResult {
  const dispatch = useDispatch<Dispatch>();
  const { openBillingCycleModal } = useBillingCycleModal();

  const onUpgrade = useCallback(async () => {
    if (allowTierSelection) {
      dispatch(unshiftModal({ name: 'TierSelection' }));
    } else {
      return openBillingCycleModal(
        {
          tier: purchaseTier,
          defaultSubscriptionPeriod: 'yearly',
          disableSuccessRedirect: true,
        },
        openModal,
      );
    }

    return undefined;
  }, [
    allowTierSelection,
    dispatch,
    openBillingCycleModal,
    openModal,
    purchaseTier,
  ]);

  return { onUpgrade };
}

import React, { useCallback } from 'react';
import ModalFooterButton from 'components/Modal/ModalFooterButton';
import { Step } from 'components/SteppedModal';
import useLinkedinAuthenticator from 'hooks/useLinkedinAuthenticator';
import LinkedInAccountMessage from './LinkedInAccountMessage';

export interface UseSwitchAccountStepConfig {
  onError?: (error: Error) => void;
  onSwitchAccountSuccess?: () => void;
  onSwitchAccount?: () => void;
}

export default function useSwitchAccountStep(
  config: UseSwitchAccountStepConfig = {},
): Step {
  const { onError, onSwitchAccountSuccess, onSwitchAccount } = config;
  const { authenticate } = useLinkedinAuthenticator({
    onAuthSuccess: onSwitchAccountSuccess,
    onError: error => onError?.(error),
    force: true,
  });

  const handleAuthenticate = useCallback(() => {
    onSwitchAccount?.();
    authenticate();
  }, [authenticate, onSwitchAccount]);

  return {
    id: 'switch',
    title: 'linkedin',

    component: <LinkedInAccountMessage />,
    renderFooterButtons: () => [
      <ModalFooterButton
        theme="submit-alt"
        onClick={handleAuthenticate}
        size="large"
      >
        I have already signed out
      </ModalFooterButton>,
      <ModalFooterButton
        href="https://linkedin.com"
        rel="noopener noreferrer"
        target="_blank"
        theme="submit"
        size="large"
      >
        go to linkedin
      </ModalFooterButton>,
    ],
    fluidFooterButtons: true,
  };
}

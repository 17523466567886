import * as React from 'react';
import { TERMS_OF_SERVICE_URL } from 'utils/constants';

interface IRenderProps {
  href: string;
  text: string;
}

interface IProps {
  className?: string;
  render?: (props: IRenderProps) => JSX.Element;
}

const TosLink: React.SFC<IProps> = ({ className, render }) => {
  const href = TERMS_OF_SERVICE_URL;
  const text = 'Terms of Service';
  const renderProps = { href, text };

  return render ? (
    render(renderProps)
  ) : (
    <a className={className} href={href} target="__blank">
      {text}
    </a>
  );
};

export default TosLink;

import React from 'react';

import Image from 'components/Image';
import { WatermarkState } from 'components/VideoTemplateEditor/types';
import { viewportToPct } from 'utils/placement';

import Layer from '../../layers/Layer';
import { editorModalPreviewBlock as block } from '../../utils';

interface EditorModalWatermarkLayerProps {
  watermark: WatermarkState;
}

const EditorModalWatermarkLayer: React.FunctionComponent<EditorModalWatermarkLayerProps> = props => {
  const { watermark } = props;

  const placement = viewportToPct(watermark.position);
  const size = viewportToPct(watermark.size);

  return (
    <Layer>
      <Image
        className={block('image')}
        src={watermark.url}
        style={{
          ...size,
          ...placement,
        }}
      />
    </Layer>
  );
};

export default EditorModalWatermarkLayer;

import { connect } from 'react-redux';
import { loggedInSelector } from 'redux/modules/auth';
import {
  aspectRatioNameSelector,
  cancelEmbedVideoSocialPostCaptionsCreation,
  cancelSocialPostCaptionsCreation,
  cancelVideoSocialPostCaptionsCreation,
  canReclipSelector,
  eddyProjectUrlSelector,
  getEmbedVideoSocialPostCaptions,
  getVideoSocialPostCaptions,
  sendDownloadPageLoadEvent,
  videoUrlSelector,
} from 'redux/modules/download';

import { onDownloadPageLoaded } from 'redux/modules/mixpanel/actions';
import { Dispatch, State } from 'redux/types';
import { PropsOf } from 'types';
import DownloadPage, { DownloadPageProps as Props } from './DownloadPage';

type StateProps = Pick<
  Props,
  'aspectRatioName' | 'eddyProjectUrl' | 'canReclip' | 'videoUrl' | 'loggedIn'
>;
type DispatchProps = Pick<Props, 'onMount' | 'onUnmount'>;

const mapStateToProps = (state: State): StateProps => ({
  aspectRatioName: aspectRatioNameSelector(state),
  eddyProjectUrl: eddyProjectUrlSelector(state),
  canReclip: canReclipSelector(state),
  videoUrl: videoUrlSelector(state),
  loggedIn: loggedInSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onMount: (autogramReview, contentInfo) => {
    dispatch(
      onDownloadPageLoaded(autogramReview ? 'autoVideosMVPEmail' : undefined),
    );
    dispatch(sendDownloadPageLoadEvent());

    const { creationRequestId, videoId } = contentInfo;

    if (creationRequestId) {
      dispatch(getVideoSocialPostCaptions(creationRequestId));
    }

    if (videoId) {
      dispatch(getEmbedVideoSocialPostCaptions(videoId));
    }
  },
  onUnmount: () => {
    dispatch(cancelSocialPostCaptionsCreation());
    dispatch(cancelVideoSocialPostCaptionsCreation());
    dispatch(cancelEmbedVideoSocialPostCaptionsCreation());
  },
});

const component = connect(mapStateToProps, mapDispatchToProps)(DownloadPage);

export type DownloadPageProps = PropsOf<typeof component>;

export default component;

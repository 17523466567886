import { Map } from 'immutable';
import { connect } from 'react-redux';

import {
  actions as embedActions,
  constants as embedConstants,
  selectors as embedSelectors,
} from 'redux/modules/embed';
import { selectors as embedExportSelectors } from 'redux/modules/embed-export';
import { isActiveSelector } from 'redux/modules/modal';
import { discoEnabledSelector } from 'redux/modules/pricing/selectors';
import { Dispatch } from 'redux/types';
import { VideoEditorProps as IProps } from './types';
import VideoEditor from './VideoEditor';

const { EmbedPlayerStatus } = embedConstants;
const { videoExportProgressSelector } = embedExportSelectors;

type State = Map<string, any>;
type StateProps = Pick<
  IProps,
  | 'aspectRatio'
  | 'isSaving'
  | 'shouldShowDiscoOverlay'
  | 'isUpgradeModalActive'
  | 'videoExportStatus'
>;
type DispatchProps = Pick<
  IProps,
  | 'onPlayerReady'
  | 'onPlayerDestroy'
  | 'onSaveConfiguration'
  | 'onPause'
  | 'onPlay'
>;

const mapStateToProps = (state: State): StateProps => ({
  aspectRatio: embedSelectors.aspectRatioSelector(state),
  isSaving: embedSelectors.embedSavingSelector(state),
  shouldShowDiscoOverlay: discoEnabledSelector(state),
  isUpgradeModalActive: isActiveSelector('UpgradePlan')(state),
  videoExportStatus: videoExportProgressSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onPause: () => dispatch(embedActions.resumeAutosave()),
  onPlay: () => dispatch(embedActions.pauseAutosave()),
  onPlayerDestroy: () =>
    dispatch(
      embedActions.setEmbedPlayerStatus((EmbedPlayerStatus as any).LOADING),
    ),
  onPlayerReady: () =>
    dispatch(
      embedActions.setEmbedPlayerStatus((EmbedPlayerStatus as any).READY),
    ),
  onSaveConfiguration: () =>
    dispatch(embedActions.debouncedSaveConfiguration()),
});

const component = connect(mapStateToProps, mapDispatchToProps)(VideoEditor);
export type VideoEditorProps = React.ComponentPropsWithoutRef<typeof component>;
export default component;

import * as transcriptService from 'redux/middleware/api/transcript-service/actions';
import { IApiResponse } from 'redux/middleware/api/types';
import { ThunkAction } from 'redux/types';
import { getValue } from 'utils/collections';
import {
  TRANSCRIPTION_POLL_INTERVAL_MILLIS,
  TRANSCRIPTION_POLL_MAX_ATTEMPTS,
} from 'utils/constants';
import reduxPoll from 'utils/redux-poll';
import { TRANSCRIBE_POLL_ID } from '../constants';
import { cancelPolling } from './core';

export const transcriptResolved = (
  versionId: number,
): ThunkAction<Promise<boolean>> => dispatch => {
  const isResolvedPath = ['entities', 'transcripts', versionId, 'isResolved'];
  return dispatch(transcriptService.getTranscriptByVersionId(versionId)).then(
    ({ response }) => getValue(response, isResolvedPath) || false,
  );
};

export const addToWhitelist = (
  recordingId: number,
  language: string,
): ThunkAction<Promise<IApiResponse<any>>> => dispatch =>
  dispatch(transcriptService.addRecordingToWhitelist(recordingId, language));

export const pollForTranscript = (
  versionId: number,
  pollId: string = TRANSCRIBE_POLL_ID,
): ThunkAction<Promise<any>> => dispatch =>
  reduxPoll(dispatch, () => dispatch(transcriptResolved(versionId)), {
    id: pollId,
    intervalMillis: TRANSCRIPTION_POLL_INTERVAL_MILLIS,
    maxAttempts: TRANSCRIPTION_POLL_MAX_ATTEMPTS,
    shouldContinue: (err, isResolved) => !err && !isResolved,
  });

export const cancelTranscriptionPolling = (
  pollId: string = TRANSCRIBE_POLL_ID,
): ThunkAction<void> => dispatch => dispatch(cancelPolling(pollId));

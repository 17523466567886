import cn from 'classnames';
import React from 'react';
import { Picture } from 'components/icons';

import OptionChildView, { OptionChildViewProps } from '../OptionChildView';

import { captionsViewBlock as block } from '../utils';
import EffectsChildViewContent from './EffectsChildViewContent';

type PickedChildViewProps = Pick<
  OptionChildViewProps,
  'active' | 'className' | 'onClose'
>;

export interface CaptionsChildViewProps extends PickedChildViewProps {}

const EffectsChildView: React.FunctionComponent<CaptionsChildViewProps> = props => {
  const { active, className, onClose } = props;

  return (
    <OptionChildView
      title="Video podcast"
      icon={<Picture style={{ height: 22 }} />}
      active={active}
      className={cn(className, block())}
      bodyClassName={block('body')}
      onClose={onClose}
    >
      <EffectsChildViewContent />
    </OptionChildView>
  );
};

export default EffectsChildView;

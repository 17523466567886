import { connect } from 'react-redux';
import { getExternalMedia } from 'redux/middleware/api/audio-proxy-service/actions';

import { showError } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { createChainedFunction } from 'utils/functions';
import ImageCropperComponent, {
  ImageCropperProps as ImageCropperComponentProps,
  ImageCropperInstance,
  ImageCropperValues,
} from './ImageCropper';

type DispatchProps = Pick<
  ImageCropperComponentProps,
  'onDownloadImage' | 'onError'
>;
type OwnProps = Pick<ImageCropperComponentProps, 'onError'>;

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onDownloadImage: async url => {
    const result = await dispatch(getExternalMedia(url));
    return result.response;
  },
  onError: createChainedFunction(
    err => dispatch(showError(err.message)),
    props.onError,
  ),
});

const ImageCropper = connect<
  {},
  DispatchProps,
  OwnProps & React.RefAttributes<ImageCropperInstance>
>(null, mapDispatchToProps, undefined, { forwardRef: true })(
  ImageCropperComponent,
);

export type ImageCropperProps = React.ComponentPropsWithoutRef<
  typeof ImageCropper
>;

export { ImageCropperInstance, ImageCropperValues };
export default ImageCropper;

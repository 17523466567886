import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaxDurationShareButton, {
  MaxDurationShareButtonProps,
} from 'blocks/DownloadPage/components/MaxDurationShareButton';
import { ThreadsButton, ThreadsButtonProps } from 'components/SocialButton';
import ThreadsPostVideoModal from 'containers/ThreadsPostVideoModal';
import useThreadsAuthenticator from 'hooks/useThreadsAuthenticator';
import { videoIdSelector } from 'redux/modules/download';
import {
  postThreadsVideo,
  threadsProviderUserIdSelector,
} from 'redux/modules/social';
import { FatalError } from 'utils/FatalError';
import { createChainedFunction } from 'utils/functions';
import { formatDurationInFull } from 'utils/time';
import { useSocialSection } from '../DownloadPageSocialSection/SocialSectionContext';
import useOnDownloadPageAction from '../useOnDownloadPageAction';
import { MAX_VIDEO_DURATION_SEC } from './utils';

type PickedButtonProps = Pick<ThreadsButtonProps, 'disabled'>;
type PickedMaxDurationButtonProps = Pick<
  MaxDurationShareButtonProps,
  'videoDurationSec'
>;

export interface ThreadsPostButtonProps
  extends PickedButtonProps,
    PickedMaxDurationButtonProps {
  onAuthenticatedClick?: () => void;
}

const { useCallback, useState } = React;

const ThreadsPostButton: React.FC<ThreadsPostButtonProps> = ({
  disabled,
  videoDurationSec,
}) => {
  const { activeModal, toggleModalVisibility } = useSocialSection();

  const [shouldReauthenticate, setShouldReauthenticate] = useState<boolean>(
    false,
  );
  const [error, setError] = useState<string>(undefined);

  const providerUserId = useSelector(threadsProviderUserIdSelector);

  const { onDownloadPageAction } = useOnDownloadPageAction();

  const dispatch = useDispatch();

  const handleAuthSuccess = useCallback(() => {
    setError(undefined);
  }, [setError]);

  const handleModalHide = useCallback(() => {
    setError(undefined);
    toggleModalVisibility('threads', false);
  }, [toggleModalVisibility]);

  const handleError = useCallback(
    (err: Error) => {
      if (err instanceof FatalError) {
        setError('Error authenticating with Threads');
        setShouldReauthenticate(true);
        toggleModalVisibility('threads', false);
      } else {
        setError(err.message);
        toggleModalVisibility('threads', true);
      }
    },
    [toggleModalVisibility],
  );

  const handleSubmit = useCallback(
    ({ description, threadsUserId }) => {
      dispatch((_, getState) => {
        const videoId = videoIdSelector(getState());

        dispatch(
          postThreadsVideo(videoId, {
            description,
            threadsUserId,
            providerUserId,
          }),
        );
      });
    },
    [dispatch, providerUserId],
  );

  const handleClick = useCallback(() => {
    toggleModalVisibility('threads', true);
  }, [toggleModalVisibility]);

  const { authenticating, withAuthentication } = useThreadsAuthenticator({
    force: shouldReauthenticate,
    onAuthSuccess: handleAuthSuccess,
    onError: handleError,
  });

  return (
    <>
      <MaxDurationShareButton
        platform="threads"
        videoDurationSec={videoDurationSec}
        maxVideoDurationSec={MAX_VIDEO_DURATION_SEC}
        tooltTipContent={`Threads only allows videos ${formatDurationInFull(
          MAX_VIDEO_DURATION_SEC * 1000,
        )} min or less`}
      >
        <ThreadsButton
          size="small"
          params={withAuthentication}
          disabled={disabled || authenticating}
          onClick={createChainedFunction(
            () => onDownloadPageAction('threadsShare'),
            withAuthentication(handleClick),
          )}
        />
      </MaxDurationShareButton>

      <ThreadsPostVideoModal
        show={activeModal === 'threads'}
        error={error}
        onHide={handleModalHide}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default ThreadsPostButton;

import { AutoframingEffect, Heading, Spacer, Text } from '@sparemin/blockhead';
import React from 'react';
import { block } from './utils';

export interface VideoPodcastSettingsHeaderProps {}

const VideoPodcastSettingsHeader: React.FC<VideoPodcastSettingsHeaderProps> = () => {
  return (
    <Spacer
      orientation="horizontal"
      space="16px"
      align="center"
      justify="flex-start"
      className={block('header')}
    >
      <div className={block('icon-container')}>
        <AutoframingEffect fontSize={30} />
      </div>

      <Spacer
        orientation="vertical"
        space="4px"
        align="flex-start"
        justify="center"
        className={block('contents')}
      >
        <Heading level={3}>Video Effects</Heading>

        <Text variant="p" className={block('description')}>
          Adjust how your video podcast content will look.
        </Text>
      </Spacer>
    </Spacer>
  );
};

export default VideoPodcastSettingsHeader;

import { useCallback } from 'react';
import { EddyClipSuggestion, FocusClip } from './types';
import {
  useMessageDispatch,
  UseMessageDispatchProps,
} from './useEddyMessaging';

type UseClipHighlightMessagingProps = Pick<
  UseMessageDispatchProps,
  'iframe'
> & {
  suggestedClips?: EddyClipSuggestion[];
};

export default function useClipHighlightMessaging({
  iframe,
  suggestedClips,
}: UseClipHighlightMessagingProps) {
  const dispatch = useMessageDispatch({ iframe });

  const highlightClip: FocusClip = useCallback(
    clipTimesOrSuggestionId => {
      if (typeof clipTimesOrSuggestionId === 'number') {
        const suggestionId = clipTimesOrSuggestionId;
        const isSuggestion = !!suggestedClips?.find(s => s.id === suggestionId);

        if (isSuggestion) {
          dispatch({
            type: 'suggestedClipHighlight',
            clipId: suggestionId,
            clearSelection: true,
          });
        }
      } else {
        const { startMillis, endMillis } = clipTimesOrSuggestionId;

        dispatch({
          endMillis,
          type: 'clipHighlight',
          startMillis,
        });
      }
    },
    [dispatch, suggestedClips],
  );

  return { highlightClip };
}

import { useDispatch, useSelector } from 'react-redux';
import { fetchAutomationSupportedLanguages } from 'redux/middleware/api/headliner-user-service';
import { automationSupportedLanguagesListSelector } from 'redux/modules/entities';

export default function useAutomationSupportedLanguages() {
  const dispatch = useDispatch();

  const languages = useSelector(automationSupportedLanguagesListSelector);

  if (!languages.length) {
    dispatch(fetchAutomationSupportedLanguages());
  }

  return languages;
}

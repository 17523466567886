import cn from 'classnames';
import * as React from 'react';
import { noop } from 'underscore';

import Modal, { ModalProps } from 'components/Modal';
import { ImageCropperInstance } from 'containers/ImageCropper/ImageCropper';
import { CropMetadata } from 'types';
import CropperModalBody, { CropperModalBodyProps } from './CropperModalBody';
import { block } from './utils';

const { useCallback, useRef } = React;

type PickedModalProps = Pick<ModalProps, 'onExited' | 'show'>;
type PickedBodyProps = Pick<
  CropperModalBodyProps,
  | 'aspectRatio'
  | 'defaultConstrainImage'
  | 'defaultMetadata'
  | 'allowConstrainControl'
  | 'fileType'
  | 'imgSrc'
  | 'isSubmitting'
>;

export interface CropperModalProps extends PickedModalProps, PickedBodyProps {
  title?: string;
  className?: string;
  disabled?: boolean;
  onCancel?: (params: any) => void;
  onSubmit?: (croppedImage: Blob, metadata: CropMetadata, params: any) => void;
  params?: any;
}

const CropperModal: React.FC<CropperModalProps> = ({
  title = 'Crop Image',
  className,
  defaultConstrainImage,
  defaultMetadata,
  allowConstrainControl,
  disabled,
  fileType,
  imgSrc,
  isSubmitting,
  onCancel = noop,
  onExited,
  onSubmit = noop,
  params,
  show,
}) => {
  const cropper = useRef<ImageCropperInstance>();

  const handleSubmit = useCallback(async () => {
    const { croppedImage, cropData } = await cropper.current?.getValues();
    onSubmit(croppedImage, cropData, params);
  }, [onSubmit, params]);

  const handleCancel = useCallback(() => {
    onCancel(params);
  }, [onCancel, params]);

  return (
    <Modal
      backdrop="static"
      className={cn(block(), className)}
      onExited={onExited}
      onHide={handleCancel}
      show={show}
      title={title}
    >
      <Modal.Body className={block('body')}>
        <CropperModalBody
          {...{
            defaultConstrainImage,
            defaultMetadata,
            allowConstrainControl,
            disabled,
            fileType,
            imgSrc,
            isSubmitting,
          }}
          ref={cropper}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButtons>
          <Modal.FooterButton onClick={onCancel} disabled={disabled}>
            Cancel
          </Modal.FooterButton>
          <Modal.FooterButton
            disabled={disabled}
            onClick={handleSubmit}
            theme="submit"
          >
            Crop
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

export default CropperModal;

import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import { SPAREMIN_SERVICES_RECORDING_URL } from 'config';
import { createRequest } from '../utils';

import * as types from './types';

export const recordingSchema = new schema.Entity(
  'recordings',
  {},
  {
    idAttribute: 'recordingId',
  },
);

const updateRecording = (
  args: types.UpdateRecordingArgs,
  request: SuperAgentStatic,
): Promise<types.UpdateRecordingResult> => {
  const [recordingId, publishedStatus] = args;

  return request
    .put(`/api/v1/recording/${recordingId}`)
    .send({ published: publishedStatus })
    .then(res => res.body);
};

function getRecordingById(
  args: types.GetRecordingByIdArgs,
  request: SuperAgentStatic,
): Promise<types.GetRecordingByIdResult> {
  const [recordingId] = args;

  return request
    .get(`/api/v2/recording/${recordingId}`)
    .then(res => normalize(res.body, recordingSchema));
}

export const handle: types.IHandle = (
  method: types.ServiceMethod,
  args: any,
  token?: string,
): Promise<any> => {
  const request = createRequest({
    token,
    baseUrl: SPAREMIN_SERVICES_RECORDING_URL,
    omitBearerPrefix: true,
  });

  switch (method) {
    case types.ServiceMethod.UPDATE_RECORDING:
      return updateRecording(args, request);

    case types.ServiceMethod.RECORDING_BY_ID_GET:
      return getRecordingById(args, request);

    default:
      throw new Error(`${types.ACTION_KEY} cannot handle method ${method}`);
  }
};

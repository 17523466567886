import { createOverlayParagraphHtml } from 'utils/rte';
import { transformTextStyle } from '../../style-transform-utils';
import { getTextBuilderStyles } from '../../text-builder-styles';
import { StateTextStyles } from '../../types';

type TextLineType = 'empty-line' | 'full-line';

interface TextLine {
  type: TextLineType;
  text: string;
}

export const EMPTY_LINE_TYPE: TextLineType = 'empty-line';
export const FULL_LINE_TYPE: TextLineType = 'full-line';

const ZERO_WITH_SPACE = '\u200B';
const SPACE_CHAR = '\u00A0';
const cleanUpRegExp = /\u00A0/g;
const multiSpacesRegExp = /[^\S\r\n]{2,}/g;

/**
 * Obtains a list of text line objects with the formatted blank space chars
 * for being handled by the text editor.
 */
export const getTextLines = (textValue: string): TextLine[] => {
  // The first step is to split the lines into single line strings at the new line chars.
  const splittedValues = textValue.split('\n');

  // Then each line is processed.
  return splittedValues.map(line => {
    // If the line is empty the type is defaulted to empty line, and an empty space char
    // is added. The empty space makes the empty line to show something at the html string.
    if (!line) {
      return { type: EMPTY_LINE_TYPE, text: ZERO_WITH_SPACE };
    }

    // If the line has content but all the content is spaces, the line type is set to full
    // line, but the spaces at the content is replaced with empty space chars. This will prevent
    // the pre-wrap white spaces css rule from shrinking the spaces.
    if (!line.trim().length) {
      return { type: FULL_LINE_TYPE, text: line.replace(/\s/g, SPACE_CHAR) };
    }

    // From this point complex lines are parsed:
    // - All multispace consecutive strings will be extracted first.
    // - Then the text is splitted using that same regex. This creates the spaces into
    // which a replacement string with the same amount of space chars replaced but replaced
    // with the unicode space chars is placed instead.
    const multispaceMatches = line.match(multiSpacesRegExp) ?? [];
    const textChunks = line.split(multiSpacesRegExp);
    const spaceLines = multispaceMatches.map(chunk =>
      chunk.replace(/\s/g, SPACE_CHAR),
    );

    // Splitted text is now merged again. Based on the splitted array, the blank spaces
    // char sequences are injected into the target positions.
    let mergedText = textChunks.reduce((acc, curr, idx) => {
      // Managing the space chunks as a queue, removing one element on each iteration.
      const spacesChunk = spaceLines.shift();

      // If the first text chunk is empty, it means the first chunk should be placed
      // there.
      if (!curr && idx === 0) {
        return acc.concat(spacesChunk);
      }

      // Id the last text chunk is reached and the curr text chunk is empty, it means
      // that all the replacements have been done. So the current merged text is returned.
      if (idx === textChunks.length - 1 && !curr) {
        return acc;
      }

      // If the last text chunk is reached and there the chunk has content, but the
      // space lines queue still has content, it is appended.
      if (idx === textChunks.length - 1 && curr && !spaceLines.length) {
        return acc.concat(curr);
      }

      // Finally if none of the conditions aboved is met, the current text chunk is
      // appended to the string alongside with the spaces chunk.
      return acc.concat(curr, spacesChunk);
    }, '');

    // The last two checks are made for ensuring that highlighted spaces are not lost
    // either at the end or at the beginning of a line if they are a single space.
    if (mergedText.endsWith(' ')) {
      mergedText = `${mergedText.substring(
        0,
        mergedText.length - 1,
      )}${SPACE_CHAR}`;
    }

    if (mergedText.startsWith(' ')) {
      mergedText = `${SPACE_CHAR}${mergedText.substring(1, mergedText.length)}`;
    }

    return {
      type: FULL_LINE_TYPE,
      text: mergedText,
    };
  });
};

/**
 * Clear special space characters and casts them into regular space chars.
 */
export const cleanupText = (text: string): string => {
  return text.replace(cleanUpRegExp, ' ');
};

/**
 * Merges the text lines into a sigle string by joining all them into a single
 * string and using the new line char as join char.
 * For the case of empty-line type, the synthetic string content will be removed.
 */
export const mergeTextLines = (textLines: TextLine[]): string => {
  return textLines
    .map(textLine => {
      switch (textLine.type) {
        case EMPTY_LINE_TYPE:
          return '';
        case FULL_LINE_TYPE:
          return textLine.text;
        default:
          return '';
      }
    })
    .join('\n');
};

/**
 * Simulates the building of the HTML string but without requiring a real node to
 * be mounted of visible. The HTML string is built from a text string and the current
 * editor styles that are provided.
 * Text is splitted in lines using the same method the text modal preview uses before
 * providing the text to the react renderer. For each line a particular style is applied
 * depending on the resulting type of line and finally a an HTML paragraph is created.
 */
export const buildUnmountedHtmlString = (
  editorStyles: StateTextStyles,
  currOverlayText: string,
): string => {
  const updatedStyles = transformTextStyle(editorStyles);
  const { emptyLineStyle, lineStyle, paragraphStyle } = getTextBuilderStyles(
    updatedStyles,
  );
  const lines = getTextLines(currOverlayText);
  let htmlString = '';

  lines.forEach(line => {
    switch (line.type) {
      case EMPTY_LINE_TYPE:
        htmlString = htmlString.concat(
          createOverlayParagraphHtml(line.text, paragraphStyle, emptyLineStyle),
        );
        break;
      case FULL_LINE_TYPE:
        htmlString = htmlString.concat(
          createOverlayParagraphHtml(line.text, paragraphStyle, lineStyle),
        );
        break;
      default:
        break;
    }
  });

  return htmlString;
};

import { TemplateEditorStateExport } from 'components/VideoTemplateEditor';
import { IndexedStep, Navigator } from 'components/Wizard';
import { AspectRatioDimensions, AudioSourceType, FrameSize, Omit } from 'types';
import { ClipAudioStepProps } from '../ClipAudioStep';

export interface SubmitConfig
  extends Omit<TemplateEditorStateExport, 'progress'> {
  aspectRatio: AspectRatioDimensions;
  audioSource: File | string;
  audioSourceType: AudioSourceType;
  projectName: string;
  templateId: string | 'none';
  originalAudioUrl?: string;
  frameSizeOverride?: FrameSize;
  transcription: Transcription;
}

type OnSubmit = (config: SubmitConfig) => void;

interface OnStepChangeMetadata {
  templateId?: string;
  audioSource?: AudioSourceType;
}

export interface EpisodeWizardProps {
  defaultTranscription?: Transcription;
  fullEpisodeCaptionEnabled: boolean;
  onCancel?: (nav: Navigator) => void;
  onError?: (error: string | Error, file?: File) => void;
  onMount?: () => void;
  onUnmount?: () => void;
  onSubmit?: OnSubmit;
  onStepChange?: (
    to: IndexedStep,
    from: IndexedStep,
    meta?: OnStepChangeMetadata,
  ) => void;
}

export type Step =
  | 'source'
  | 'uploadAudio'
  | 'searchPodcast'
  | 'deliveryMethod'
  | 'language'
  | 'customize'
  | 'backCatalogCustomize'
  | 'backCatalogFinalMessage'
  | 'submitting'
  | 'export'
  | 'aspectRatio'
  | 'template';

export enum Steps {
  UPLOAD_AUDIO_STEP = 0,
  ASPECT_RATIO_STEP = 1,
  TEMPLATE_STEP = 2,
  CUSTOMIZE_SOUNDWAVE_STEP = 3,
  CREATING_PROJECT_STEP = 4,
}

export type Transcription = ClipAudioStepProps['transcription'] & {
  transcriptUrl: string;
  transcriptRequestSubmitted?: boolean;
};

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useClipSelectNavigation } from '../ClipSelectNavigationContext';
import {
  ClipSelectContextType,
  ClipSelectionType,
  ClipSelectProviderProps,
  OnSelectClip,
} from './types';
import useClipSelector from './useClipSelector';
import useDefaultHighlightedRegion from './useDefaultHighlightedRegion';
import useEddyTranscript from './useEddyTranscript';
import usePlayback from './usePlayback';
import useSuggestions from './useSuggestions';

const ClipSelectContext = React.createContext<
  ClipSelectContextType | undefined
>(undefined);

export const ClipSelectProvider: React.FC<ClipSelectProviderProps> = props => {
  const {
    eddyProjectId,
    suggestedClips,
    dislikedSuggestionIds,
    clipsPageInfo,
    mediaType,
    onReprocessClip,
    onSubmit,
    onDislikeClip,
    onOpenInAdvancedEditor,
    onSelectClip,
    onUpdateSuggestions,
    children,
  } = props;

  const [clipSelectionType, setClipSelectionType] = useState<
    ClipSelectionType | undefined
  >();

  const {
    findSuggestion,
    hasClipSuggestions,
    removeSuggestion,
    suggestionIds,
    visibleSuggestionIds,
  } = useSuggestions({ suggestedClips });

  const { eddySuggestedClips, onTranscriptUpdate } = useEddyTranscript({
    onUpdateSuggestions,
    suggestedClips,
  });

  const { activeSuggestionId, onChangeClip } = useClipSelector({
    suggestionIds,
  });

  const activeSuggestion = useMemo(() => findSuggestion(activeSuggestionId), [
    activeSuggestionId,
    findSuggestion,
  ]);

  const {
    defaultClipperRegion,
    defaultTranscriptRegion,
    highlightRegion,
  } = useDefaultHighlightedRegion({
    mediaDurationMillis: clipsPageInfo.mediaDurationMillis,
    suggestedClips,
    visibleSuggestionIds,
  });

  const {
    addVideoPlayerRef,
    audioClipperRef,
    pauseAll,
    isSuggestionPlaying,
    onChangeClip: onChangePlaybackClip,
    suggestionVideoPlayerRefs,
    toggleClipPlayback,
    videoClipperPlayerControls,
  } = usePlayback({
    activeSuggestion,
    suggestionIds,
  });

  const [, send] = useClipSelectNavigation();

  const onSuggestionSelect = (suggestionId: number) => {
    onChangePlaybackClip(suggestionId);
    onChangeClip(suggestionId);
  };

  const handleAdjust = useCallback(() => {
    if (activeSuggestion) {
      setClipSelectionType(ClipSelectionType.SUGGESTED_CLIP);
      highlightRegion(activeSuggestion.id);
      send({ type: 'ADJUST_CLICK' });
    }
  }, [activeSuggestion, highlightRegion, send]);

  const handleCustom = useCallback(() => {
    setClipSelectionType(ClipSelectionType.CUSTOM_CLIP);
    highlightRegion();
    send({ type: 'CUSTOM_CLICK' });
  }, [highlightRegion, send]);

  const handleSubmit = async (suggestionId: number) => {
    pauseAll();

    const suggestion = findSuggestion(suggestionId);

    if (suggestion) {
      onSubmit(suggestion);
    }
  };

  const handleSelectClip: OnSelectClip = async ({
    region: regionOverride,
  }): Promise<number | void> => {
    pauseAll();

    if (!suggestionIds.length) {
      send({ type: 'SUBMIT_SUCCESS' });
    }

    const suggestionId = await onSelectClip({
      region: regionOverride,
      activeSuggestionId:
        clipSelectionType === ClipSelectionType.SUGGESTED_CLIP
          ? activeSuggestionId
          : undefined,
    });

    if (suggestionId) {
      addVideoPlayerRef(suggestionId);
    }

    setClipSelectionType(undefined);

    return suggestionId;
  };

  const handleRemoveSuggestion = useCallback(
    (id: number) => {
      pauseAll();
      removeSuggestion(id);
    },
    [pauseAll, removeSuggestion],
  );

  const handleOpenInAdvancedEditor = useCallback(() => {
    onOpenInAdvancedEditor?.(activeSuggestion);
  }, [activeSuggestion, onOpenInAdvancedEditor]);

  useEffect(() => {
    send({
      type: 'DATA_LOAD_SUCCESS',
      payload: {
        hasTranscript: !!eddyProjectId,
        suggestionCount: suggestionIds.length,
      },
    });
  }, [eddyProjectId, highlightRegion, send, suggestionIds]);

  return (
    <ClipSelectContext.Provider
      value={{
        activeSuggestion,
        activeSuggestionId: activeSuggestion?.id,
        audioClipperRef,
        clipsPageInfo,
        defaultClipperRegion,
        defaultTranscriptRegion,
        dislikedSuggestionIds,
        eddyProjectId,
        hasClipSuggestions,
        clipSelectionType,
        isClipSuggestionLoading: activeSuggestion?.status !== 'completed',
        isSuggestionPlaying,
        mediaType,
        eddySuggestedClips,
        onTranscriptUpdate,
        onAdjust: handleAdjust,
        onCustom: handleCustom,
        onDislikeClip,
        onOpenInAdvancedEditor: handleOpenInAdvancedEditor,
        onReprocessClip,
        onSelectClip: handleSelectClip,
        onSubmit: handleSubmit,
        onSuggestionSelect,
        onUpdateSuggestions,
        removeSuggestion: handleRemoveSuggestion,
        suggestedClips,
        suggestionIds,
        suggestionVideoPlayerRefs,
        togglePlayback: toggleClipPlayback,
        videoPlayerControls: videoClipperPlayerControls,
        visibleSuggestionIds,
      }}
    >
      {children}
    </ClipSelectContext.Provider>
  );
};

export function useClipSelect() {
  const context = useContext(ClipSelectContext);

  if (context === undefined) {
    throw new Error('useClipSelect must be used within ClipSelectProvider');
  }

  return context;
}

import React from 'react';
import { ClipsPageInfo, ClipSuggestion } from 'blocks/ClipSelect/types';
import { EddyClipSuggestion } from 'blocks/EddyEmbed';
import { AudioClipperComponent } from 'components/AudioClipper';
import { AsyncAudioClipperProps } from 'containers/AsyncAudioClipper';
import { ClipSuggestionDislikeReason } from 'types';
import { HighlightedRegionState } from './useDefaultHighlightedRegion';

export type VideoPlayerRefs = Record<string, React.RefObject<HTMLVideoElement>>;

export type Region = Required<AsyncAudioClipperProps['region']> & {
  /**
   * a suggestionId should exist if this region corresponds to a suggestion
   */
  suggestionId?: number;
};

export enum ClipSelectionType {
  DEFAULT = 'Default',
  SUGGESTED_CLIP = 'SuggestedClip',
  CUSTOM_CLIP = 'CustomClip',
}

export interface FormValues {
  region: Region;
  lastClipSelectionType: ClipSelectionType;
  isCaptionEnabled: boolean;
  clipSuggestion: ClipSuggestion;
}

export type OnSelectClip = (
  clip: Pick<FormValues, 'region'> & {
    activeSuggestionId?: number;
  },
) => Promise<number | void>;

export type OnReprocessClip = (clipId: number) => Promise<void>;

export type OnOpenInAdvancedEditor = (
  clipSuggestion: ClipSuggestion,
) => Promise<void>;

export interface VideoPlayerControls {
  playing: boolean;
  onPlay: () => void;
  onPause: () => void;
}

export type ClipSelectMediaType = 'audio' | 'video';

export type ClipSelectContextType = {
  activeSuggestionId: number;
  defaultClipperRegion: HighlightedRegionState['clipper'];
  defaultTranscriptRegion: HighlightedRegionState['transcript'];
  dislikedSuggestionIds: number[];
  eddyProjectId?: string;
  suggestionVideoPlayerRefs: VideoPlayerRefs;
  audioClipperRef: React.RefObject<AudioClipperComponent>;
  isSuggestionPlaying: boolean;
  region?: Region;
  removeSuggestion: (id: number) => void;
  isClipSuggestionLoading: boolean;
  suggestionIds: number[] | undefined;
  onSuggestionSelect: (suggestionId: number) => void;
  onAdjust: () => void;
  onCustom?: () => void;
  onSubmit: (suggestionId: number) => void;
  onSelectClip: OnSelectClip;
  onReprocessClip: OnReprocessClip;
  onOpenInAdvancedEditor: () => void;
  togglePlayback: () => void;
  visibleSuggestionIds: number[];
  activeSuggestion: ClipSuggestion;
  hasClipSuggestions: boolean;
  clipSelectionType?: ClipSelectionType;
  suggestedClips: ClipSuggestion[];
  clipsPageInfo: ClipsPageInfo;
  videoPlayerControls: VideoPlayerControls;
  mediaType: ClipSelectMediaType;
  eddySuggestedClips: EddyClipSuggestion[];
  onTranscriptUpdate: () => void;
  onDislikeClip?: (
    reason: ClipSuggestionDislikeReason,
    clipSuggestion: ClipSuggestion,
  ) => void;
  onUpdateSuggestions?: () => void;
};

export type OnSubmit = (suggestion: ClipSuggestion) => Promise<void>;

export type ClipSelectProviderProps = React.PropsWithChildren<
  Pick<
    ClipSelectContextType,
    | 'suggestedClips'
    | 'dislikedSuggestionIds'
    | 'clipsPageInfo'
    | 'mediaType'
    | 'onDislikeClip'
    | 'onReprocessClip'
    | 'onSelectClip'
  > & {
    eddyProjectId?: string;
    onOpenInAdvancedEditor: OnOpenInAdvancedEditor;
    onSubmit: OnSubmit;
    onUpdateSuggestions?: () => void;
  }
>;

import { ButtonProps } from 'components/Button';
import { ProgressPositionId, ProgressSizeId } from 'types';
import { ViewportWidth } from 'utils/measurement';
import { PopperVerticalAlignRadioValue } from '../Popper/PopperVerticalAlignRadio';
import { OnImageProcessed } from '../useImageSource';
import { VideoTemplateState, VideoTemplateStateContent } from './state';
import { TemplateAction } from './template-actions';

export type WaveformAlignment = PopperVerticalAlignRadioValue;
export type WaveformSize = 'fill' | 'padded';
export type WaveformOptions = WaveformAlignment | WaveformSize | 'reset';
export type ImageSource = string | File;
export type VideoSource = string | File;

export interface WaveformPlacement {
  alignment?: WaveformAlignment;
  size?: WaveformSize;
}

export type WaveformFidelity = 'lo-fi' | 'hi-fi';

export type OnStateChange = (
  stateUpdater: (prevState: VideoTemplateState) => VideoTemplateState,
  action: TemplateAction,
) => void;

export type OnIsAutoFrameChange = (isAutoFrame: boolean) => void;

export type ProgressSize = Exclude<ProgressSizeId, 'custom'>;
export type ProgressAlignment = Exclude<ProgressPositionId, 'custom'>;

export interface ProgressPlacement {
  alignment: ProgressAlignment;
  size: ProgressSize;
}

export type ChildView =
  | 'effects'
  | 'image'
  | 'images'
  | 'waveform'
  | 'watermark'
  | 'text'
  | 'progress'
  | 'intro_outro_edit'
  | 'intro_outro'
  | 'background_color'
  | 'captions';

export interface CallToActionProps extends Pick<ButtonProps, 'onClick'> {
  render?: (props: RenderCtaProps) => JSX.Element;
  label?: string;
}

export interface RenderCtaProps
  extends Pick<CallToActionProps, 'onClick' | 'label'> {
  className: string;
  disabled: boolean;
  state: VideoTemplateStateContent;
}

export interface ImageOperations {
  imageSrc: string | Blob;
  blurRadius?: ViewportWidth;
  onAdd: OnImageProcessed;
  onCrop: () => void;
  onDelete: (context?: 'dynamic' | 'static') => void;
  onReplace: OnImageProcessed;
  onBlurChange: (radius: ViewportWidth) => void;
}

// "gif" and "upload" are variations of the upload type. Since this feature was added
// as an easter egg, this makes easier to handle the different assets that can appear
// from the media upload. In future this integration should be splitted and this enum
// changed to reflect that change.
export enum MediaIntegrationId {
  CANVA = 'canva',
  DYNAMIC = 'dynamic',
  GIF = 'gif',
  AI_ASSET = 'ai-asset',
  UPLOAD = 'upload',
  VIDEOCLIP = 'videoclip',
}

export enum TextIntegrationId {
  DYNAMIC = 'dynamic',
  STATIC = 'static',
}

interface BaseImageIntegrationData {
  id: MediaIntegrationId;
}

interface BaseVideoIntegrationData {
  id: MediaIntegrationId;
}

interface BaseTextIntegrationData {
  id: TextIntegrationId;
}

interface UploadImageIntegrationData extends BaseImageIntegrationData {
  id: MediaIntegrationId.UPLOAD;
}

interface AiAssetIntegrationData extends BaseImageIntegrationData {
  id: MediaIntegrationId.AI_ASSET;
}

interface CanvaIntegrationData extends BaseImageIntegrationData {
  id: MediaIntegrationId.CANVA;
  designId: string;
}

export interface DynamicIntegrationData extends BaseImageIntegrationData {
  id: MediaIntegrationId.DYNAMIC;
}

export interface DynamicVideoIntegrationData extends BaseVideoIntegrationData {
  id: MediaIntegrationId.DYNAMIC;
}

export type ImageIntegrationData =
  | UploadImageIntegrationData
  | CanvaIntegrationData
  | DynamicIntegrationData
  | AiAssetIntegrationData;

export interface GifIntegrationData extends BaseImageIntegrationData {
  id: MediaIntegrationId.GIF;
}

export interface RegularVideoIntegrationData extends BaseImageIntegrationData {
  id: MediaIntegrationId.VIDEOCLIP;
}

export type VideoIntegrationData =
  | GifIntegrationData
  | RegularVideoIntegrationData
  | AiAssetIntegrationData
  | DynamicVideoIntegrationData;

interface StaticTextIntegrationData extends BaseTextIntegrationData {
  id: TextIntegrationId.STATIC;
}

export type DynamicTitleType = 'podcast' | 'episode';

export type DynamicElementType = '%%Podcast%%' | '%%Episode%%';

export type MediaAssetType = 'image' | 'videoClip';

export interface DynamicTextIntegrationData extends BaseTextIntegrationData {
  id: TextIntegrationId.DYNAMIC;
  type: DynamicTitleType;
}

export type TextIntegrationData =
  | StaticTextIntegrationData
  | DynamicTextIntegrationData;

export type AssetCreationType = 'static' | 'dynamic';

export interface CustomizeStepBrandingOptions {
  template?: 'acast';
}

export type IntroOutroType = 'intro' | 'outro';

export type LayerOrderer = Record<string, number>;

import * as Immutable from 'immutable';
import ids from 'short-id';
import _ from 'underscore';

import { asArray, getValue, setValue } from 'utils/collections';
import { textContentFromHtmlString } from 'utils/dom';
import { applyTemplateToOverlays } from 'utils/embed/text-overlay';

import { convertOverlayToTextTemplate } from 'utils/rte';
import * as types from '../action-types';
import * as embedSelectors from '../selectors';
import {
  createEmbedConfigurationFromState,
  saveConfiguration,
  setEmbedDuration,
} from './embed';
import { addToTrack } from './tracks';

const updateTextOverlaysAction = textOverlayById => dispatch =>
  dispatch({
    type: types.EMBED_TEXT_OVERLAYS_UPDATE,
    payload: { textOverlayById },
  });

export const updateTextOverlays = updateFn => (dispatch, getState) => {
  const textOverlayById =
    embedSelectors.textOverlayByIdSelector(getState()) || Immutable.Map();
  const updatedTextOverlayById = updateFn(textOverlayById);

  dispatch(updateTextOverlaysAction(updatedTextOverlayById));
  dispatch(setEmbedDuration(true));
};

const updateLastTextOverlayTemplate = template => dispatch => {
  dispatch({
    type: types.EMBED_LAST_TEXT_TEMPLATE_UPDATE,
    payload: {
      template,
    },
  });
};

export const addToTextOverlays = (newOverlay, trackId) => (
  dispatch,
  getState,
) => {
  const newOverlays = asArray(newOverlay).map(overlay =>
    overlay.withMutations(mutableOverlay =>
      mutableOverlay
        .set('id', ids.generate())
        .set(
          'text',
          textContentFromHtmlString(mutableOverlay.get('textHtml'), ''),
        ),
    ),
  );

  const lastNewOverlay = newOverlays[newOverlays.length - 1];

  dispatch(
    updateTextOverlays(overlays =>
      newOverlays.reduce(
        (acc, overlay) => acc.set(overlay.get('id'), overlay),
        overlays,
      ),
    ),
  );
  dispatch(
    updateLastTextOverlayTemplate(convertOverlayToTextTemplate(lastNewOverlay)),
  );

  const addToTrackId =
    trackId || embedSelectors.selectAddingToTrack(getState());

  if (addToTrackId) {
    newOverlays.forEach(overlay =>
      dispatch(addToTrack(addToTrackId, overlay.get('id'), 'text')),
    );
  }

  return newOverlays;
};

export const addTextOverlays = (overlay, trackId) => dispatch => {
  dispatch({ type: types.EMBED_TEXT_OVERLAY_ADD_REQUEST });

  dispatch(addToTextOverlays(overlay, trackId));

  return dispatch(createEmbedConfigurationFromState())
    .then(() => dispatch({ type: types.EMBED_TEXT_OVERLAY_ADD_SUCCESS }))
    .catch(error => {
      dispatch({ type: types.EMBED_TEXT_OVERLAY_ADD_FAILURE });
      throw error;
    });
};

// overlay might be regular object or Immutable
export const updateTextOverlay = textOverlay => dispatch => {
  const id = getValue(textOverlay, 'id');
  const text = textContentFromHtmlString(getValue(textOverlay, 'textHtml'), '');
  dispatch({
    type: types.EMBED_TEXT_OVERLAY_UPDATE,
    payload: {
      id,
      textOverlay: _.isUndefined(text)
        ? textOverlay
        : setValue(textOverlay, 'text', text),
    },
  });

  dispatch(setEmbedDuration());
};

/**
 * deletes text overlays.  does not save embed config
 */
export const deleteTextOverlays = textOverlayIds => dispatch => {
  const overlayIds = asArray(textOverlayIds).filter(_.negate(_.isUndefined));

  if (overlayIds.length === 0) return;

  dispatch({
    payload: {
      textOverlayIds: overlayIds,
    },
    type: types.EMBED_TEXT_OVERLAYS_DELETE,
  });
  dispatch(setEmbedDuration(true));
};

/**
 * deletes overlay and saves to the embed configuration
 */
export const removeTextOverlay = textOverlayId => dispatch => {
  dispatch({ type: types.EMBED_TEXT_OVERLAY_REMOVE_REQUEST });
  dispatch(deleteTextOverlays(textOverlayId));

  return dispatch(createEmbedConfigurationFromState())
    .then(() =>
      dispatch({
        type: types.EMBED_TEXT_OVERLAY_REMOVE_SUCCESS,
      }),
    )
    .catch(error =>
      dispatch({
        ...error,
        type: types.EMBED_TEXT_OVERLAY_REMOVE_FAILURE,
      }),
    );
};

export const saveTextOverlay = textOverlay => dispatch => {
  dispatch(updateTextOverlay(textOverlay));
  dispatch(
    updateLastTextOverlayTemplate(convertOverlayToTextTemplate(textOverlay)),
  );
  dispatch(saveConfiguration());
};

export const getOverlaysByTrackId = trackId => (dispatch, getState) => {
  const tracksById = embedSelectors.tracksByIdSelector(getState());
  const overlaysById = embedSelectors.textOverlayByIdSelector(getState());
  const textOverlayIds = tracksById.getIn([trackId, 'data']);

  return overlaysById.filter((__, id) => textOverlayIds.includes(id));
};

export const applyTemplateToTextTrack = (trackId, template) => (
  dispatch,
  getState,
) => {
  dispatch({
    payload: { template },
    type: types.EMBED_LAST_GLOBAL_TEMPLATE_UPDATE,
  });

  const trackOverlays = dispatch(getOverlaysByTrackId(trackId));
  const updatedOverlays = applyTemplateToOverlays(trackOverlays, template);

  dispatch(updateTextOverlays(overlays => overlays.merge(updatedOverlays)));
  return dispatch(createEmbedConfigurationFromState());
};

export const duplicateTextOverlayToTrack = (textOverlay, trackId) => (
  dispatch,
  getState,
) => {
  // getting overlays on the selected track
  const trackOverlays = dispatch(getOverlaysByTrackId(trackId));
  // ordering by endMillis
  const orderedOverlaysByEndMillis = trackOverlays.sortBy(s =>
    s.getIn(['time', 'endMillis']),
  );
  // getting last overlay
  const lastOverlay = orderedOverlaysByEndMillis.last();
  // getting lastOverlay's endMillis
  const lastOverlayEndMillis = lastOverlay.getIn(['time', 'endMillis']) + 1;
  // getting duration of new overlay
  const durationInMillis =
    textOverlay.getIn(['time', 'endMillis']) -
    textOverlay.getIn(['time', 'startMillis']);

  // creating a new overlay by adjusting its start and end times
  const newOverlay = textOverlay
    .setIn(['time', 'startMillis'], lastOverlayEndMillis)
    .setIn(['time', 'endMillis'], lastOverlayEndMillis + durationInMillis);

  // adding to track and saving
  dispatch(addTextOverlays(newOverlay, trackId));

  return newOverlay;
};

export default { addTextOverlays, updateTextOverlays, removeTextOverlay };

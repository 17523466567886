import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import { SPAREMIN_SERVICES_THIRDPARTYAUTHENTICATION_URL } from 'config';
import { createRequest } from '../utils';
import { MY_SOCIAL_AUTH_URL } from './constants';

import * as types from './types';

const youtubePlaylistsSchema = new schema.Entity('youtubePlaylist');

const getGettyProviderToken = (
  args: types.GetGettyProviderTokenArgs,
  request: SuperAgentStatic,
): Promise<types.GetGettyProviderTokenResult> => {
  const [provider] = args;

  return request
    .get('/api/v1/third-party-auth/me')
    .query({ provider })
    .then(res => res.body);
};

const authorize = async (
  args: types.AuthorizeArgs,
  request: SuperAgentStatic,
): Promise<types.AuthorizeResult> => {
  const [provider] = args;

  await request.post('/api/v1/third-party-auth/social-auth').send({
    provider,
  });
};

async function getSocialAuthStatus(
  args: types.GetSocialAuthStatusArgs,
  request: SuperAgentStatic,
): Promise<types.GetSocialAuthStatusResult> {
  const [provider, scopeContext, forceLogin] = args;

  const { body } = await request
    .get(MY_SOCIAL_AUTH_URL)
    .withCredentials()
    .query({
      provider,
      scopeContext,
      forceLogin: String(!!forceLogin),
    });

  return body;
}

async function getSocialProfile(
  args: types.GetSocialProfileArgs,
  request: SuperAgentStatic,
): Promise<types.GetSocialProfileResult> {
  const [
    provider,
    providerUserId,
    idToken,
    accessToken,
    accessTokenSecret,
  ] = args;

  const { body } = await request
    .post('/api/v1/third-party-auth/social-auth/me/profile')
    .send({
      provider,
      providerUserId,
      idToken,
      accessToken,
      accessTokenSecret,
    });

  return body;
}

async function getLinkedinOrganizations(
  args: types.GetLinkedinOrganizationsArgs,
  request: SuperAgentStatic,
): Promise<types.GetSocialProfileResult> {
  const [provider, providerUserId, idToken, accessToken] = args;

  const { body } = await request
    .post('/api/v1/third-party-auth/linkedin/me/organizations')
    .send({
      provider,
      providerUserId,
      idToken,
      accessToken,
    });

  return body;
}

async function getInstagramAccounts(
  args: types.GetInstagramAccountsArgs,
  request: SuperAgentStatic,
): Promise<types.GetInstagramAccountsResult[]> {
  const [providerUserId, accessToken] = args;

  const { body } = await request
    .post('/api/v1/third-party-auth/facebook/me/instagram-accounts')
    .send({
      providerUserId,
      accessToken,
    });

  return body.data;
}

async function getFacebookPages(
  args: types.GetFacebookPagesArgs,
  request: SuperAgentStatic,
): Promise<types.GetFacebookPagesResult[]> {
  const [providerUserId, accessToken] = args;

  const { body } = await request
    .post('/api/v1/third-party-auth/facebook/me/pages')
    .send({
      providerUserId,
      accessToken,
    });

  return body.data;
}

export const getSocialYoutubeChannel = async (
  args: types.GetSocialYoutubeChannelArgs,
  request: SuperAgentStatic,
): Promise<types.GetSocialYoutubeChannelResult> => {
  const [providerUserId, accessToken] = args;

  const { body } = await request
    .post('/api/v1/third-party-auth/youtube/me/channel')
    .send({
      providerUserId,
      accessToken,
    });

  return body;
};

export const getAppsConnected = (
  args: types.GetAppsConnectedArgs,
  request: SuperAgentStatic,
): Promise<types.GetAppsConnectedResult> => {
  const { provider } = args;
  return request
    .get(`/api/v1/third-party-auth/social-auth/me/access`)
    .query({ provider })
    .then(res => res.body);
};

export const deleteAppsConnected = async (
  args: types.DeleteAppsConnectedArgs,
  request: SuperAgentStatic,
): Promise<void> => {
  const { provider } = args;

  await request
    .delete(`/api/v1/third-party-auth/social-auth/me/access`)
    .query({ provider })
    .send();
};

export const getYoutubePlaylist = (
  args: types.GetYoutubePlaylistsArgs,
  request: SuperAgentStatic,
): Promise<types.GetPlaylistsResult> => {
  const { providerUserId, accessToken } = args;
  return request
    .post(`/api/v1/third-party-auth/youtube/me/playlists`)
    .set('Content-Type', 'application/json')
    .send({
      providerUserId,
      accessToken,
    })
    .then(res => res.body.data);
};

export const getTikTokCreatorInfo = (
  args: types.GetTikTokCreatorInfoArgs,
  request: SuperAgentStatic,
): Promise<types.GetTikTokCreatorInfoResult> => {
  const { providerUserId, accessToken } = args;

  return request
    .post(`/api/v1/third-party-auth/tiktok/me/creator-info`)
    .set('Content-Type', 'application/json')
    .send({
      providerUserId,
      accessToken,
    })
    .then(res => res.body);
};

export const getInstagramAccountInfo = (
  args: types.GetInstagramAccountInfoArgs,
  request: SuperAgentStatic,
): Promise<types.GetInstagramAccountInfoResult> => {
  const { providerUserId, accessToken, instagramId } = args;

  return request
    .post(`/api/v1/third-party-auth/facebook/me/instagram-account-info`)
    .set('Content-Type', 'application/json')
    .send({
      providerUserId,
      accessToken,
      instagramId,
    })
    .then(res => res.body);
};

async function createYoutubePlaylist(
  args: types.CreateYoutubePlaylistArgs,
  request: SuperAgentStatic,
): Promise<types.CreateYoutubePlaylistResult> {
  const { body } = await request
    .post('/api/v1/third-party-auth/youtube/me/create-playlist')
    .send(args);

  return normalize(body, youtubePlaylistsSchema);
}

export const handle = (
  method: types.ServiceMethod,
  args: any,
  token?: string,
): Promise<any> => {
  const request = createRequest({
    token,
    baseUrl: SPAREMIN_SERVICES_THIRDPARTYAUTHENTICATION_URL,
  });

  switch (method) {
    case types.ServiceMethod.GET_YOUTUBE_PLAYLISTS:
      return getYoutubePlaylist(args, request);

    case types.ServiceMethod.CREATE_YOUTUBE_PLAYLIST:
      return createYoutubePlaylist(args, request);

    case types.ServiceMethod.GET_TIKTOK_CREATOR_INFO:
      return getTikTokCreatorInfo(args, request);

    case types.ServiceMethod.GET_INSTAGRAM_ACCOUNT_INFO:
      return getInstagramAccountInfo(args, request);

    case types.ServiceMethod.GET_GETTY_PROVIDER_TOKEN:
      return getGettyProviderToken(args, request);

    case types.ServiceMethod.AUTHORIZE:
      return authorize(args, request);

    case types.ServiceMethod.GET_SOCIAL_PROFILE:
      return getSocialProfile(args, request);

    case types.ServiceMethod.GET_LINKEDIN_ORGANIZATIONS:
      return getLinkedinOrganizations(args, request);

    case types.ServiceMethod.GET_INSTAGRAM_ACCOUNTS:
      return getInstagramAccounts(args, request);

    case types.ServiceMethod.GET_FACEBOOK_PAGES:
      return getFacebookPages(args, request);

    case types.ServiceMethod.GET_SOCIAL_AUTH_STATUS:
      return getSocialAuthStatus(args, request);

    case types.ServiceMethod.GET_SOCIAL_YOUTUBE_CHANNEL:
      return getSocialYoutubeChannel(args, request);

    case types.ServiceMethod.GET_APPS_CONNECTED:
      return getAppsConnected(args, request);

    case types.ServiceMethod.DELETE_APPS_CONNECTED:
      return deleteAppsConnected(args, request);

    default:
      throw new Error(`${types.ACTION_KEY} cannot handle method ${method}`);
  }
};

import { Map } from 'immutable';
import { CSSProperties } from 'styled-components';
import { compose, identity } from 'underscore';

import { asArray, omit, omitUndefined, pick } from 'utils/collections';
import {
  domTreeToHtmlString,
  htmlStringToDomTree,
  htmlStringToTreeWalker,
} from 'utils/dom';
import {
  formatHeadlinerStyleRules,
  parseTextShadowString,
  pxify,
  setStyle,
} from 'utils/ui';
import { IImmutableMap, Omit, OneOrMore } from '../types';

type InlineStyleKey =
  | 'fontFamily'
  | 'fontSize'
  | 'fontWeight'
  | 'fontStyle'
  | 'textDecoration'
  | 'color'
  | 'background' // this is text-highlight.  text box background is not an inline style
  | 'textAlign'
  | 'textShadow'
  | 'lineHeight';

type InlineStyle = { [k in InlineStyleKey]: any };

type OuterStyleKey =
  | 'background'
  | 'padding'
  | 'paddingBottom'
  | 'paddingLeft'
  | 'paddingRight'
  | 'paddingTop';

/*
 * keys for styles that are not applieid within tinymce
 */
export const OUTER_STYLE_KEYS: OuterStyleKey[] = [
  'background',
  'padding',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
];

interface ISplitResult<T extends { [k: string]: any }> {
  outerStyle: Pick<T, OuterStyleKey>;
  innerStyle: Omit<T, OuterStyleKey>;
}

type ImmutableSplitResult<T> = {
  [k in keyof ISplitResult<T>]: IImmutableMap<ISplitResult<T>[k]>;
};

export function splitStyle<T>(style: IImmutableMap<T>): ImmutableSplitResult<T>;
export function splitStyle<T>(style: T): ISplitResult<T>;
export function splitStyle(style: { [k: string]: any }): any {
  if (!style) {
    const isImmutable = Map.isMap(style);
    return {
      innerStyle: isImmutable ? Map() : {},
      outerStyle: isImmutable ? Map() : {},
    };
  }

  const outerStyle = pick(style, ...OUTER_STYLE_KEYS);
  const innerStyle = omit(style, ...OUTER_STYLE_KEYS);

  return { outerStyle, innerStyle };
}

const formatStyle = compose(pxify, formatHeadlinerStyleRules);

export function createTextHtml(text, containerStyle = {}, textStyle = {}) {
  const root = document.createElement('div');
  const container = document.createElement('p');
  const inner = document.createElement('span');

  root.appendChild(container);
  container.appendChild(inner);
  inner.textContent = text;

  setStyle(container, formatStyle(containerStyle));
  setStyle(inner, formatStyle(textStyle));

  return domTreeToHtmlString(root);
}

/**
 * Builds an html string for an overlay paragraph. It receives the text
 * string for a single line and both the paragraph (outer) and span (inner)
 * styles.
 *
 * @pre
 * Text should only refer to a single line of text.
 */
export function createOverlayParagraphHtml(
  text: string,
  paragraphStyle: CSSProperties,
  spanStyle: CSSProperties,
): string {
  const container = document.createElement('div');
  const paragraph = document.createElement('p');
  const inner = document.createElement('span');

  container.appendChild(paragraph);
  paragraph.appendChild(inner);
  inner.textContent = text;

  setStyle(paragraph, pxify(paragraphStyle));
  setStyle(inner, pxify(spanStyle));

  return domTreeToHtmlString(container);
}

/*
 * list of all RTE inline styles supported by headliner and functions to convert the string values
 * that are returned when reading the inline styles.
 *
 * headliner-specific assumptions are made here, e.g. fontSize gets parsed to a number which is
 * implied to be pixels, even though it could be percentage or em
 */
const INLINE_STYLE_FORMATTERS = {
  background: identity,
  color: identity,
  fontFamily: identity,
  fontSize: parseFloat,
  fontStyle: identity,
  fontWeight: parseFloat,
  lineHeight: parseFloat,
  paddingBottom: parseFloat,
  paddingLeft: identity,
  paddingRight: identity,
  paddingTop: parseFloat,
  textAlign: identity,
  textDecoration: identity,
  textHighlight: identity,
  textShadow: compose(Map, parseTextShadowString),
  webkitBoxDecorationBreak: identity,
  boxDecorationBreak: identity,
};

const SUPPORTED_INLINE_STYLES = Object.keys(INLINE_STYLE_FORMATTERS);

/**
 * takes a css style object and returns a new object with only supported keys which have a
 * nonempty (undefined or '') value
 */
function filterInlineStyle(styles) {
  if (!styles) return undefined;
  const supportedStyles = SUPPORTED_INLINE_STYLES.reduce((acc, key) => {
    acc[key] = styles[key];
    return acc;
  }, {});

  const x = Object.keys(supportedStyles).reduce((acc, key) => {
    const value = supportedStyles[key];
    // css values get parsed as strings so this just filters out undefined or empty
    if (value) acc[key] = value;
    return acc;
  }, {});

  return x;
}

/**
 * formats styles according to functions defined in INLINE_STYLE_FORMATTERS. if no formatter
 * is defined for a style, the input arg's value for that style gets returned
 */
function parseInlineStyles(styles) {
  if (!styles) return undefined;
  return Object.keys(styles).reduce((acc, key) => {
    const formatter = INLINE_STYLE_FORMATTERS[key] || identity;
    acc[key] = formatter(styles[key]);
    return acc;
  }, {});
}

const convertInlineStyle = compose(parseInlineStyles, filterInlineStyle);

/**
 * uses a tree walker to go as deep as possible, looking for the first node it finds that has no
 * children elements or whose first child is a text node.
 */
function getFirstTextParent(htmlString: string) {
  const walker = htmlStringToTreeWalker(htmlString);
  while (walker.nextNode()) {
    // safe cast to element since the default tree walker filter is SHOW_ELEMENTS
    const node = walker.currentNode as HTMLElement;
    if (!node.firstChild || node.firstChild.nodeType === Node.TEXT_NODE) {
      return node;
    }
  }
  return undefined;
}

/**
 * starting from node, walks up the tree taking the style prop of all elements and combining them.
 * parent styles will be overridden by children styles.
 *
 * NOTE that this likely doesn't work 100% like normal css inheritance, so there could be issues,
 * though we are only dealing with a small subset of css styles supported by the RTE, so for our
 * case it works well enough
 */
function getInheritedStyle(node: Node, style = {}) {
  if (!node) return style;
  if (node.nodeType !== Node.ELEMENT_NODE) {
    return getInheritedStyle(node.parentNode, style);
  }

  const nodeStyle = (node as HTMLElement).style;
  return getInheritedStyle(node.parentNode, {
    ...convertInlineStyle(nodeStyle),
    ...style,
  });
}

export function convertOverlayToTextTemplate(overlay) {
  if (!overlay) return undefined;
  const textHtml = overlay.get('textHtml');

  return pick(
    overlay,
    'style',
    'position',
    'size',
    'transitions',
    'viewport',
    'containerDecorator',
    'templateId',
  ).withMutations(o => {
    if (!textHtml) return o;
    const firstTextNode = getFirstTextParent(overlay.get('textHtml'));
    const style = firstTextNode ? getInheritedStyle(firstTextNode) : {};

    /*
     * remove background because it's used for text-highlight and will conflict with container
     * background
     */
    const {
      paddingLeft,
      paddingRight,
      background,
      webkitBoxDecorationBreak,
      boxDecorationBreak,
      backgroundColor,
      ...restStyle
    } = style;

    const styleOverrides = {
      ...restStyle,
      ...omitUndefined({
        textHighlight: background,
        textPaddingLeft: paddingLeft,
        textPaddingRight: paddingRight,
        boxDecorationBreak: boxDecorationBreak ?? webkitBoxDecorationBreak,
      }),
    };

    o.update('style', s => s.merge(styleOverrides));
    return o;
  });
}

export function applyStyleToHtml(
  textHtml: OneOrMore<string>,
  style: Partial<InlineStyle> = {},
) {
  const htmls = asArray(textHtml);

  return htmls.map(htmlString => {
    if (!htmlString) return htmlString;

    const domTree = htmlStringToDomTree(`<div>${htmlString}</div>`);

    Array.from(domTree.getElementsByTagName('p')).forEach(p => {
      const text = p.textContent;
      const textWrapper = document.createElement('span');

      textWrapper.textContent = text;
      p.innerHTML = '';
      p.removeAttribute('style');
      p.appendChild(textWrapper);

      const outerStyle = pick(style, 'textAlign');
      const innerStyle = omit(style, 'textAlign');

      setStyle(p, formatStyle(outerStyle));
      setStyle(textWrapper, formatStyle(innerStyle));
    });
    return domTreeToHtmlString(domTree);
  });
}

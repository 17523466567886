import { LocationDescriptor } from 'history';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import FontAwesome from 'components/FontAwesome';
import { H1, H2 } from 'components/Heading';
import { ClosedCaptioning, Picture } from 'components/icons';
import Audiogram from 'components/icons/Audiogram';
import Progress from 'components/icons/Progress';
import MailToSupport from 'components/MailToSupport';
import Modal from 'components/Modal';
import ProgressList from 'components/ProgressList';
import useOnMount from 'hooks/useOnMount';
import { CompatibilityDetails } from 'redux/middleware/api/headliner-user-service';
import {
  onClickFullLengthTemplatesModalCTA,
  onFullLengthTemplatesModalLoaded,
  onOpenTemplateInEditor,
} from 'redux/modules/mixpanel/actions';
import { getCompatibilityModalSource } from 'redux/modules/mixpanel/utils';
import { hideModal, replaceModal } from 'redux/modules/modal';
import { createProjectFromTemplate } from 'redux/modules/project/actions';
import { AspectRatioName, TemplateType } from 'types';
import CompatibilityListItem from './CompatibilityListItem';
import { block } from './utils';

interface FullLengthTemplateCompatibilityProps {
  compatibilityDetails?: CompatibilityDetails;
  onRedirect?: (location: LocationDescriptor) => void;
  templateId?: string;
  templateType?: TemplateType;
  aspectRatioName?: AspectRatioName;
  allowRefreshTemplateListFlow?: boolean;
}

const FullLengthTemplateCompatibility: React.FC<FullLengthTemplateCompatibilityProps> = props => {
  const [isLoadingProject, setIsLoadingProject] = useState(false);

  const {
    compatibilityDetails,
    templateId,
    aspectRatioName,
    allowRefreshTemplateListFlow = false,
  } = props;
  const {
    validVideoLayer,
    validAnimationSettings,
    validCaptionsSettings,
    validAssetStartAndEndTimes,
  } = compatibilityDetails || {};
  const dispatch = useDispatch();
  const source = getCompatibilityModalSource();

  useOnMount(() => {
    dispatch(
      onFullLengthTemplatesModalLoaded({
        source,
        fullLengthTemplateFixesNeeded: {
          gif: validVideoLayer,
          progress: validAnimationSettings,
          captions: validCaptionsSettings,
          assetLengths: validAssetStartAndEndTimes,
        },
      }),
    );
  });

  const handleHide = useCallback((): void => {
    dispatch(hideModal());
  }, [dispatch]);

  const handleReturnClick = useCallback(async (): Promise<void> => {
    dispatch(onClickFullLengthTemplatesModalCTA(source));

    if (allowRefreshTemplateListFlow) {
      setIsLoadingProject(true);

      const projectId = await dispatch(createProjectFromTemplate(templateId));

      setIsLoadingProject(false);

      dispatch(
        replaceModal({
          name: 'RefreshTemplateList',
          params: {
            aspectRatioName,
          },
        }),
      );
      dispatch(onOpenTemplateInEditor());

      window.open(`/project/${projectId}`);

      return;
    }

    handleHide();
  }, [
    allowRefreshTemplateListFlow,
    aspectRatioName,
    dispatch,
    handleHide,
    source,
    templateId,
  ]);

  return (
    <>
      <Modal.Body className={block('body')}>
        <div className={block('titles-section')}>
          <H1>Full-length templates</H1>
          <H2>
            To save a template for long-form audio, please follow these steps:
          </H2>
        </div>

        <div className={block('progress-list-container')}>
          <ProgressList>
            <CompatibilityListItem isValid={validVideoLayer} icon={Picture}>
              Only add videos or GIFs as the top or bottom layer
            </CompatibilityListItem>

            <CompatibilityListItem
              isValid={validAnimationSettings}
              icon={Progress}
            >
              Remove any transitions or progress animations
            </CompatibilityListItem>

            <CompatibilityListItem
              isValid={validCaptionsSettings}
              icon={ClosedCaptioning}
            >
              Turn off captions animations
            </CompatibilityListItem>

            <CompatibilityListItem
              isValid={validAssetStartAndEndTimes}
              icon={Audiogram}
            >
              All assets should start and end at the same time
            </CompatibilityListItem>
          </ProgressList>
        </div>

        <span>
          <FontAwesome icon="headset" />
          {'  '}
          Still confused? Email{' '}
          <MailToSupport subject="Problem saving full-length template">
            support@headliner.app
          </MailToSupport>
        </span>
      </Modal.Body>

      <Modal.Footer>
        <Modal.FooterButtons fluid>
          <Modal.FooterButton
            onClick={handleReturnClick}
            theme="submit"
            disabled={isLoadingProject}
          >
            {allowRefreshTemplateListFlow
              ? 'Open project in a new tab to make changes'
              : 'Return to your project to make changes'}
          </Modal.FooterButton>
        </Modal.FooterButtons>
      </Modal.Footer>
    </>
  );
};

export default FullLengthTemplateCompatibility;

import {
  AspectRatioName,
  OneOrMore,
  SocialSharePlatform,
  TemplateAdjustment,
  TemplateType,
} from 'types';
import { ASPECT_RATIOS } from 'utils/aspect-ratio';
import { asArray } from 'utils/collections';
import { int } from 'utils/numbers';
import { createApiAction } from '../utils';
import * as types from './types';

interface CreateBaseConfigurationOptions {
  podcastTitle?: string;
  episodeTitle?: string;
  imageUrl?: string;
}

function apiCallAction<M extends types.ServiceMethod, A = undefined>(
  method: M,
  args?: A,
) {
  return createApiAction(types.ACTION_KEY, method, args);
}

export function searchPodcasts(
  query: string,
  offset: number = 0,
): types.PodcastSearchAction {
  return apiCallAction(types.ServiceMethod.SEARCH_FOR_PODCAST, [
    query,
    offset,
  ] as types.PodcastSearchArgs);
}

export function searchFeed(url: string): types.PodcastSearchFeedAction {
  return apiCallAction(types.ServiceMethod.SEARCH_FOR_FEED, [
    url,
  ] as types.PodcastSearchFeedArgs);
}

export function getPodcastById(podcastId: string): types.GetPodcastByIdAction {
  return apiCallAction(types.ServiceMethod.GET_PODCAST_BY_ID, [podcastId]);
}

export function getPodcastEpisodes(
  podcastId: string,
  nextPubMillis?: number,
): types.GetEpisodesAction {
  return apiCallAction(types.ServiceMethod.GET_PODCAST_EPISODES, [
    podcastId,
    nextPubMillis,
  ] as types.GetEpisodesArgs);
}

export function createPodcastSubscription(
  podcastId: string,
  podcastLanguage: string,
  templateId: string,
  subscriptionTypes: OneOrMore<types.PodcastSubscriptionType>,
  templateAdjustment: TemplateAdjustment,
  templateType: TemplateType = TemplateType.HEADLINER_DEFAULT,
): types.CreateSubscriptionAction {
  return apiCallAction(types.ServiceMethod.CREATE_PODCAST_SUBSCRIPTION, [
    podcastId,
    podcastLanguage,
    templateId,
    asArray(subscriptionTypes),
    templateType,
    templateAdjustment,
  ] as types.CreateSubscriptionArgs);
}

export const createPodcastSubscriptionPreview = (
  podcastId: string,
  templateAdjustment: TemplateAdjustment,
  templateId: string,
  templateType: TemplateType = TemplateType.HEADLINER_DEFAULT,
): types.CreateSubscriptionPreviewAction => {
  return apiCallAction(
    types.ServiceMethod.CREATE_PODCAST_SUBSCRIPTION_PREVIEW,
    [podcastId, templateAdjustment, templateId, templateType],
  );
};

export const getPoscastSubscriptionPreview = (
  subscriptionPreviewJobId: number,
): types.GetSubscriptionPreviewAction => {
  return apiCallAction(types.ServiceMethod.GET_PODCAST_SUBSCRIPTION_PREVIEW, [
    subscriptionPreviewJobId,
  ]);
};

export function getMyPodcastSubscriptions(
  podcastFeedId?: string,
): types.GetMySubscriptionAction {
  return apiCallAction(types.ServiceMethod.GET_MY_PODCAST_SUBSCRIPTIONS, [
    podcastFeedId,
  ]);
}

export function deletePodcastSubscription(
  subscriptionId: number,
): types.DeleteSubscriptionAction {
  return apiCallAction(types.ServiceMethod.DELETE_PODCAST_SUBSCRIPTION, [
    subscriptionId,
  ] as types.DeleteSubscriptionArgs);
}

/**
 * API will merge in the update object to what's stored in the db, but does not handle
 * arrays so if `subscriptionTypes` is specified, existing fields should not be left
 * out of the update unless they are intended to be deleted from their respective objects
 */
export function updatePodcastSubscription({
  isEnabled,
  podcastLanguage,
  subscriptionId,
  subscriptionTypes,
  templateId,
  templateType,
  templateAdjustment,
}: types.UpdatePodcastSubscriptionActionArgs): types.UpdateSubscriptionAction {
  return apiCallAction(types.ServiceMethod.UPDATE_PODCAST_SUBSCRIPTION, [
    subscriptionId,
    podcastLanguage,
    templateId,
    asArray(subscriptionTypes),
    templateType,
    templateAdjustment,
    isEnabled,
  ]);
}

export function togglePodcastSubscription(
  subscriptionId: number,
  isEnabled: boolean,
): types.ToggleSubscriptionAction {
  return apiCallAction(types.ServiceMethod.TOGGLE_PODCAST_SUBSCRIPTION, [
    subscriptionId,
    isEnabled,
  ]);
}

export function getPodcastWorkflowTemplates(
  aspectRatioName: AspectRatioName,
  subjectImageUrl?: string,
): types.GetPodcastWorkflowTemplatesAction {
  const dimensions = ASPECT_RATIOS.get(aspectRatioName);

  if (!dimensions) {
    throw new Error(`Invalid aspect ratio "${aspectRatioName}"`);
  }

  return apiCallAction(types.ServiceMethod.GET_PODCAST_WORKFLOW_TEMPLATES, [
    dimensions.get('width'),
    dimensions.get('height'),
    subjectImageUrl,
  ] as types.GetPodcastWorkflowTemplatesArgs);
}

export function getPodcastWorkflowTemplate(
  templateId: string,
  templateType: TemplateType,
): types.GetPodcastWorkflowTemplateAction {
  return apiCallAction(types.ServiceMethod.GET_PODCAST_WORKFLOW_TEMPLATE, [
    templateId,
    templateType,
  ]);
}

export function getSeasons(podcastId: string): types.GetSeasonsAction {
  return apiCallAction(types.ServiceMethod.GET_SEASONS, [podcastId]);
}

export function createEpisodeVariation(
  episodeId: number,
  trimStartMillis: number,
  trimEndMillis: number,
  isCaptionEnabled: boolean,
): types.CreateEpisodeVariationAction {
  return apiCallAction(types.ServiceMethod.CREATE_EPISODE_VARIATION, [
    episodeId,
    trimStartMillis,
    trimEndMillis,
    isCaptionEnabled,
  ] as types.CreateEpisodeVariationArgs);
}

export function enableClipSuggestion(
  autoCreateEpisodeId: number,
): types.EnableClipSuggestionAction {
  return apiCallAction(types.ServiceMethod.ENABLE_CLIP_SUGGESTION, [
    autoCreateEpisodeId,
  ] as types.EnableClipSuggestionArgs);
}

export function createPreSelectVariation(
  episodeId: number,
  subscriptionItemId: number,
  subscriptionId: number,
  trimStartMillis: number,
  trimEndMillis: number,
  isCaptionEnabled: boolean,
): types.CreatePreSelectVariationAction {
  return apiCallAction(types.ServiceMethod.CREATE_PRE_SELECT_VARIATION, [
    episodeId,
    subscriptionItemId,
    subscriptionId,
    trimStartMillis,
    trimEndMillis,
    isCaptionEnabled,
  ]);
}

export function createEpisodeVideo(
  episodeId: string,
  autoCreateSubscriptionIds: number[],
): types.CreateEpisodeVideoAction {
  return apiCallAction(types.ServiceMethod.CREATE_EPISODE_VIDEO, [
    int(episodeId),
    autoCreateSubscriptionIds,
  ]);
}

export function getEpisodeTranscriptInfo(
  episodeId: number,
  subscriptionItemId: number,
) {
  return apiCallAction(types.ServiceMethod.GET_EPISODE_TRANSCRIPT_INFO, [
    episodeId,
    subscriptionItemId,
  ] as types.GetEpisodeTranscriptInfoArgs);
}

export function getGroupClipSuggestions(
  episodeId: number,
  subscriptionItemId: number,
  groupRequestId?: number,
) {
  return apiCallAction(types.ServiceMethod.GET_GROUP_CLIP_SUGGESTIONS, [
    episodeId,
    subscriptionItemId,
    groupRequestId,
  ] as types.GetGroupClipSuggestionsArgs);
}

export function createGroupClipSuggestion(
  episodeId: number,
  subItemId: number,
  groupRequestId: number,
  replaceAutoCreateEpisodeId: number,
  newTrimStartMillis: number,
  newTrimEndMillis: number,
) {
  return apiCallAction(types.ServiceMethod.CREATE_GROUP_CLIP_SUGGESTION, [
    episodeId,
    subItemId,
    groupRequestId,
    replaceAutoCreateEpisodeId,
    newTrimStartMillis,
    newTrimEndMillis,
  ] as types.CreateGroupClipSuggestionArgs);
}

export function getSubscriptionItemInformation(subscriptionItemId: number) {
  return apiCallAction(types.ServiceMethod.GET_SUBSCRIPTION_ITEM_INFORMATION, [
    subscriptionItemId,
  ] as types.GetSubscriptionItemInformationArgs);
}

export function getSubscriptionItemDetail(
  subscriptionId: number,
  subscriptionItemId: number,
) {
  return apiCallAction(types.ServiceMethod.GET_SUBSCRIPTION_ITEM_DETAIL, [
    subscriptionId,
    subscriptionItemId,
  ] as types.GetSubscriptionItemDetailArgs);
}

export function getMyPodcastFeeds(): types.GetMyPodcastFeedsAction {
  return apiCallAction(types.ServiceMethod.GET_MY_PODCAST_FEEDS);
}

export function deletePodcastFeed(
  podcastFeedId: string,
): types.DeletePodcastFeedAction {
  return apiCallAction(types.ServiceMethod.DELETE_PODCAST_FEED, [
    podcastFeedId,
  ]);
}

export function getMyPodcastFeedDetails(
  podcastFeedId: string,
  pageNumber?: number,
  pageSize?: number,
): types.GetMyPodcastFeedDetailsAction {
  return apiCallAction(types.ServiceMethod.GET_MY_PODCAST_FEED_DETAILS, [
    podcastFeedId,
    pageNumber,
    pageSize,
  ]);
}

export function getSubscriptionById(
  subscriptionId?: number,
): types.GetSubscriptionByIdAction {
  return apiCallAction(types.ServiceMethod.GET_SUBSCRIPTION_BY_ID, [
    subscriptionId,
  ]);
}

export function createBaseConfiguration(
  templateId: string,
  templateType: TemplateType,
  recordingId: number,
  durationMillis: number,
  opts: CreateBaseConfigurationOptions = {},
): types.CreateBaseConfigAction {
  const { podcastTitle, episodeTitle, imageUrl } = opts;

  return apiCallAction(types.ServiceMethod.CREATE_BASE_CONFIGURATION, [
    templateType,
    templateId,
    recordingId,
    durationMillis,
    podcastTitle,
    episodeTitle,
    imageUrl,
  ]);
}

export function getEpisodeByVideo(
  embedVideoId: number,
): types.GetEpisodeByVideoAction {
  return apiCallAction(types.ServiceMethod.GET_EPISODE_BY_VIDEO, [
    embedVideoId,
  ]);
}

export function getFavoritePodcasts(): types.GetFavoritePodcastsAction {
  return apiCallAction(
    types.ServiceMethod.GET_FAVORITE_PODCASTS,
    [] as types.GetFavoritePodcastsArgs,
  );
}

export function markPodcastFavorite(
  params: types.MarkPodcastFavoriteArgs[0],
): types.MarkPodcastFavoriteAction {
  return apiCallAction(types.ServiceMethod.MARK_PODCAST_FAVORITE, [params]);
}

export function getEpisodeById(
  episodeId: types.EpisodeId,
): types.GetEpisodeByIdAction {
  return apiCallAction(types.ServiceMethod.GET_EPISODE_BY_ID, [episodeId]);
}

export function createSocialPostCaptions(
  episodeId: types.EpisodeId,
): types.CreateSocialPostCaptionsAction {
  return apiCallAction(types.ServiceMethod.CREATE_SOCIAL_POST_CAPTIONS, [
    episodeId,
  ]);
}

export function getSocialPostCaptions(
  episodeId: types.EpisodeId,
  jobId: number,
): types.GetSocialPostCaptionsAction {
  return apiCallAction(types.ServiceMethod.GET_SOCIAL_POST_CAPTIONS, [
    episodeId,
    jobId,
  ] as [types.EpisodeId, number]);
}

export function getGroupClipSuggestion(
  autoCreateEpisodeId: number,
): types.GetGroupClipSuggestionAction {
  return apiCallAction(types.ServiceMethod.GET_GROUP_CLIP_SUGGESTION, [
    autoCreateEpisodeId,
  ] as [number]);
}

export function getAutoCreateEpisodeByVideo(
  videoId: types.VideoId,
): types.GetAutoCreateEpisodeByVideoAction {
  return apiCallAction(types.ServiceMethod.GET_AUTO_CREATE_EPISODE_BY_VIDEO, [
    videoId,
  ]);
}

export function getAutoCreateEpisode(
  autoCreateEpisodeId: types.AutoCreateEpisodeId,
): types.GetAutoCreateEpisodeAction {
  return apiCallAction(types.ServiceMethod.GET_AUTO_CREATE_EPISODE, [
    autoCreateEpisodeId,
  ]);
}

export function reprocessAutoCreateEpisode(
  args: types.ReprocessAutoCreateEpisodeArgs,
): types.ReprocessAutoCreateEpisodeAction {
  return apiCallAction(types.ServiceMethod.REPROCESS_AUTO_CREATE_EPISODE, args);
}

export function receiveAutoCreateEpisodeEvent(
  args: types.ReceiveAutoCreateEpisodeEventArgs,
): types.ReceiveAutoCreateEpisodeEventAction {
  return apiCallAction(
    types.ServiceMethod.RECEIVE_AUTO_CREATE_EPISODE_EVENT,
    args,
  );
}

export function getPodcastRemoteEpisode(
  podcastId: types.PodcastId,
  remoteEpisodeId: types.RemoteEpisodeId,
): types.GetPodcastRemoteEpisodeAction {
  return apiCallAction(types.ServiceMethod.GET_PODCAST_REMOTE_EPISODE, [
    podcastId,
    remoteEpisodeId,
  ]);
}

export function createEpisodeClipSuggestionFeedback(
  feedback: types.EpisodeClipSuggestionFeedback,
): types.CreateEpisodeClipSuggestionFeedbackAction {
  return apiCallAction(
    types.ServiceMethod.CREATE_EPISODE_CLIP_SUGGESTION_FEEDBACK,
    [feedback],
  );
}

export function deleteFavoritePodcast(podcastId: types.PodcastId) {
  return apiCallAction(types.ServiceMethod.DELETE_FAVORITE_PODCAST, [
    podcastId,
  ]);
}

export function createEntireEpisodeTranscript(
  episodeId: string,
): types.CreateEntireEpisodeTranscriptAction {
  return apiCallAction(types.ServiceMethod.CREATE_ENTIRE_EPISODE_TRANSCRIPT, [
    episodeId,
  ]);
}

export function getEntireEpisodeTranscriptInfo(
  episodeId: string | number,
): types.GetEntireEpisodeTranscriptInfoAction {
  return apiCallAction(types.ServiceMethod.GET_ENTIRE_EPISODE_TRANSCRIPT_INFO, [
    typeof episodeId === 'string' ? int(episodeId) : episodeId,
  ]);
}

export function getEntireEpisodeTranscriptInfos(
  episodeIds: string[],
): types.GetEntireEpisodeTranscriptInfosAction {
  return apiCallAction(
    types.ServiceMethod.GET_ENTIRE_EPISODE_TRANSCRIPT_INFOS,
    [episodeIds.map(id => (typeof id === 'string' ? int(id) : id))],
  );
}

export function shareVideo(
  embedVideoId: string,
  platform: SocialSharePlatform,
  accessToken: string,
  opts: types.ShareVideoOptions = {},
): types.ShareVideoAction {
  return apiCallAction(types.ServiceMethod.SHARE_VIDEO, [
    embedVideoId,
    platform,
    accessToken,
    opts.title,
    opts.description,
    opts.tags,
    opts.privacyStatus,
    opts.madeForKids,
    opts.categoryId,
    opts.playlists,
    opts.visibility,
    opts.linkedinAuthorId,
    opts.tiktokUserId,
    opts.twitterUserId,
    opts.defaultLanguage,
    opts.accessTokenSecret,
    opts.commentEnabled,
    opts.instagramUserId,
    opts.instagramMediaType,
    opts.providerUserId,
    opts.facebookPageId,
    opts.threadsUserId,
    opts.scopeContext,
  ]);
}

export const claimPodcastOwnership = (
  podcastId: string,
): types.PodcastClaimOwnershipAction => {
  return apiCallAction(types.ServiceMethod.CLAIM_PODCAST_OWNERSHIP, [
    podcastId,
  ]);
};

export const getOwnedPodcasts = (): types.PodcastGetOwnedAction => {
  return apiCallAction(types.ServiceMethod.GET_OWNED_PODCASTS);
};

export function refreshPodcastFeed(
  podcastId: types.PodcastId,
): types.RefreshPodcastFeedAction {
  return apiCallAction(types.ServiceMethod.REFRESH_PODCAST_FEED, [podcastId]);
}

export function getPodcastRefreshStatus(
  refreshJobId: types.RefreshJobId,
): types.PodcastGetRefreshStatusAction {
  return apiCallAction(types.ServiceMethod.GET_PODCAST_REFRESH_STATUS, [
    refreshJobId,
  ]);
}

export const getYoutubeCategoriesAction = (): types.GetYoutubeCategoriesAction => {
  return apiCallAction(types.ServiceMethod.GET_YOUTUBE_CATEGORIES);
};

export const getYoutubeLanguagesAction = (): types.GetYoutubeLanguagesAction => {
  return apiCallAction(types.ServiceMethod.GET_YOUTUBE_LANGUAGES);
};

import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';

import FileUploader, {
  FileUploaderProps,
  RejectedReason,
} from 'components/FileUploader';
import { VideoFile } from 'components/icons';
import VideoVerification, {
  VideoVerificationProps,
} from 'components/VideoVerification';
import {
  videoUploadLimitHoursSelector,
  videoUploadLimitMbSelector,
} from 'redux/modules/pricing';
import { Omit } from 'types';
import { ApplicationError } from 'utils/ApplicationError';
import {
  getSupportedVideoMimeTypes,
  getSupportedVideoTypes,
} from 'utils/formats';

type VideoFileUploaderProps = Omit<
  FileUploaderProps,
  | 'accept'
  | 'image'
  | 'maxFileSizeMb'
  | 'supportedFileTypes'
  | 'title'
  | 'defaultFile'
  | 'multiple'
  | 'onFileAccepted'
  | 'uploadMethod'
>;

type PickedVerificationProps = Pick<VideoVerificationProps, 'onFileAccepted'>;
export interface VideoUploaderProps
  extends VideoFileUploaderProps,
    PickedVerificationProps {
  onError?: (
    error: ApplicationError,
    file?: File,
    reason?: RejectedReason,
  ) => void;
}

const VideoUploader: React.FC<VideoUploaderProps> = ({
  onFileAccepted,
  onError = _.noop,
  ...props
}) => {
  const uploadLimitMb = useSelector(videoUploadLimitMbSelector);
  const uploadLimitHours = useSelector(videoUploadLimitHoursSelector);

  const handleFileRejected = useCallback(
    (__, file: File, reason: RejectedReason) => {
      const limit =
        uploadLimitMb >= 1000
          ? `${uploadLimitMb / 1000}GB`
          : `${uploadLimitMb}MB`;

      onError(
        new ApplicationError(
          `File must be a video that is ${limit} or smaller`,
          'IN002',
        ),
        file,
        reason,
      );
    },
    [onError, uploadLimitMb],
  );

  return (
    <VideoVerification
      onFileAccepted={onFileAccepted}
      onFileRejected={onError}
      maxDurationHours={uploadLimitHours}
    >
      {({ verify }) => (
        <FileUploader
          {...props}
          accept={getSupportedVideoMimeTypes().join(',')}
          onFileAccepted={verify}
          onFileRejected={handleFileRejected}
          image={<VideoFile width={84} />}
          supportedFileTypes={getSupportedVideoTypes()}
          supportedFileMaxSizeInMb={uploadLimitMb}
          additionalRestrictions={`${dayjs
            .duration(uploadLimitHours, 'hour')
            .format('H [hour]')} max`}
          maxFileSizeMb={uploadLimitMb}
        />
      )}
    </VideoVerification>
  );
};

export default VideoUploader;

import * as React from 'react';
import { isUndefined, noop } from 'underscore';

import LoadingOverlay from 'components/LoadingOverlay';
import VideoClipper, {
  VideoClipperInstance,
  VideoClipperProps,
} from 'components/VideoClipper';
import { VideoScaling } from 'components/VideoPlayer';
import FreeTranscriptionBalance from 'containers/FreeTranscriptionBalance';
import { AspectRatioName } from 'types';
import { getAspectRatio, getAspectRatioName } from 'utils/aspect-ratio';
import bem from 'utils/bem';
import { VIDEO_CLIP_MAX_DURATION_SECONDS } from 'utils/constants';
import { min } from 'utils/numbers';

import { block } from './utils';

type ClipperProps = Pick<
  VideoClipperProps,
  'src' | 'onDurationChange' | 'onSelectedMillisChange'
>;

export const clipMaxDuration = VIDEO_CLIP_MAX_DURATION_SECONDS * 1000;

export interface IProps extends ClipperProps {
  aspectRatioName: AspectRatioName;
  clipEndMillis: VideoClipperProps['endMillis'];
  clipStartMillis: VideoClipperProps['startMillis'];
  durationMillis: number;
  isFullEpisode?: boolean;
  showClipDisclaimer?: boolean;
  showTranscriptionBalance: boolean;
  transcriptionBalanceMillis: number;
  captionsControl?: React.ReactNode;
}

interface IState {
  playing: boolean;
}

export default class ClipVideoClipper extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onDurationChange: noop,
  };

  public state: Readonly<IState> = {
    playing: false,
  };

  private clipperRef = React.createRef<VideoClipperInstance>();

  private handlePlay = () => this.setPlaying(true);

  private handlePause = () => this.setPlaying(false);

  private handleDurationChange = (durationMillis: number) => {
    const { onDurationChange } = this.props;
    onDurationChange(durationMillis);
  };

  private setPlaying(playing: boolean) {
    this.setState({ playing });
  }

  public render() {
    const {
      aspectRatioName,
      clipEndMillis,
      clipStartMillis,
      durationMillis,
      onSelectedMillisChange,
      src,
      showTranscriptionBalance,
      captionsControl,
      isFullEpisode,
    } = this.props;
    const { playing } = this.state;

    const ratio = getAspectRatio(aspectRatioName);

    const b = bem('clip-video-step');

    return (
      <div className={b({ full: isFullEpisode })}>
        {isUndefined(durationMillis) && (
          <LoadingOverlay className={b('overlay')} title="Loading Video" />
        )}
        <div className={b('video-clipper-container')}>
          <VideoClipper
            aspectRatioName={getAspectRatioName(
              ratio.get('height'),
              ratio.get('width'),
            )}
            endMillis={clipEndMillis}
            maxDurationMillis={min(
              durationMillis,
              clipStartMillis + clipMaxDuration,
            )}
            wavesurferClassName={b('clip-video-waveform')}
            durationMillis={durationMillis}
            onDurationChange={this.handleDurationChange}
            onPause={this.handlePause}
            onPlay={this.handlePlay}
            onSelectedMillisChange={onSelectedMillisChange}
            playerClassName={b('player', { [aspectRatioName]: true })}
            playing={playing}
            scaling={VideoScaling.FIT}
            src={src}
            startMillis={clipStartMillis}
            ref={this.clipperRef}
            captionsControl={captionsControl}
            disableClipper={isFullEpisode}
          />
        </div>

        {showTranscriptionBalance && (
          <div className={block('balance-container')}>
            <FreeTranscriptionBalance
              className={block('balance')}
              source="wizard"
            />
          </div>
        )}
      </div>
    );
  }
}

import { Record, RecordOf } from 'immutable';
import { omit, pick } from 'underscore';

import { omitUndefined } from 'utils/collections';
import { withValue } from 'utils/control';
import { DEFAULT_PROGRESS_OPTIONS } from 'utils/embed/progress';
import { reviveRecord } from 'utils/immutable';
import {
  AudiogramData,
  AutogramData,
  FullEpisodeData,
  State,
  VideoTranscriptionData,
} from './types';

export const audiogramData = Record<AudiogramData>({
  aspectRatioName: 'square',
  soundwave: undefined,
  textOverlay: undefined,
  backgroundColor: 'black',
  timer: undefined,
  progress: DEFAULT_PROGRESS_OPTIONS,
});

export const autogramData = Record<AutogramData>({
  aspectRatioName: 'square',
  soundwave: undefined,
  textOverlay: undefined,
  backgroundColor: 'black',
  timer: undefined,
  progress: DEFAULT_PROGRESS_OPTIONS,
  transcription: {},
  captions: undefined,
});

export const fullEpisodeData = Record<FullEpisodeData>({
  aspectRatioName: 'landscape',
  soundwave: undefined,
  textOverlay: undefined,
  backgroundColor: 'black',
});

export const videoTranscriptionData = Record<VideoTranscriptionData>({
  aspectRatioName: 'portrait',
  soundwave: undefined,
  textOverlay: undefined,
  backgroundColor: 'black',
  timer: undefined,
  progress: undefined,
  transcription: {},
  captions: undefined,
});

export const stateFactory = Record<State>({
  audiogram: audiogramData(),
  autogram: autogramData(),
  fullEpisode: fullEpisodeData(),
  videoTranscription: videoTranscriptionData(),
});

export function stateFromJs({
  audiogram,
  autogram,
  fullEpisode,
  videoTranscription,
}: any = {}): RecordOf<State> {
  return reviveRecord(
    stateFactory,
    omitUndefined({
      audiogram: withValue(audiogram, agram =>
        reviveRecord(
          audiogramData,
          pick(agram, 'textOverlay'),
          omit(agram, 'textOverlay'),
        ),
      ),
      autogram: withValue(autogram, agram =>
        reviveRecord(
          autogramData,
          pick(agram, 'textOverlay'),
          omit(agram, 'textOverlay'),
        ),
      ),
      fullEpisode: withValue(fullEpisode, fe =>
        reviveRecord(
          fullEpisodeData,
          pick(fe, 'textOverlay'),
          omit(fe, 'textOverlay'),
        ),
      ),
      videoTranscription: withValue(videoTranscription, fe =>
        reviveRecord(
          videoTranscriptionData,
          pick(fe, 'textOverlay'),
          omit(fe, 'textOverlay'),
        ),
      ),
    }),
  );
}

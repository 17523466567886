import { Text } from '@sparemin/blockhead';
import React from 'react';
import AutomationPostModalHeader from 'components/AutomationPostModalHeader';
import DynamicElementsPopper from 'components/DynamicElementsPopper';
import EducationalCard from 'components/EducationalCard/EducationalCard';
import ShareFormTextarea from 'components/ShareForm/ShareFormTextarea';
import SocialAccountInfo from 'components/SocialAccountInfo';
import {
  captionsMetadata,
  MAX_CHARS_CAPTIONS,
} from 'containers/InstagramPostModal/constants';
import { block } from '../../utils';

export interface ComposerDefaultViewProps {
  username: string;
  profileImageUrl: string;
  description: string;
  onDescriptionChange: (newDescription: string) => void;
  onSwitchAccountClick?: () => void;
}

const ComposerDefaultView: React.FC<ComposerDefaultViewProps> = ({
  username,
  profileImageUrl,
  description,
  onDescriptionChange,
  onSwitchAccountClick,
}) => {
  return (
    <>
      <SocialAccountInfo
        {...{
          username,
          avatarSrc: profileImageUrl,
          onSwitchAccountClick,
        }}
      />

      <AutomationPostModalHeader
        title="Let’s set up your video captions"
        description="We'll post these captions with each video. You can pull information from your RSS feed, such as episode titles, to make each post unique."
      />

      <ShareFormTextarea
        hideProgress
        type="textarea"
        maxChars={MAX_CHARS_CAPTIONS}
        value={description}
        className={block('description-container')}
        controlClassName={block('description-input')}
        label={
          <Text variant="label">
            <b>Example of your captions</b> (up to {MAX_CHARS_CAPTIONS}{' '}
            characters)
          </Text>
        }
        onChange={onDescriptionChange}
      />

      <EducationalCard>
        <DynamicElementsPopper
          buttonContent="add content from your rss"
          elementsList={captionsMetadata}
          onClick={metaValue =>
            onDescriptionChange(`${description} ${metaValue}`)
          }
        />
      </EducationalCard>
    </>
  );
};

export default ComposerDefaultView;

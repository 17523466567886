import { useCallback, useState } from 'react';
import {
  DEFAULT_CLIP_END_MILLIS,
  DEFAULT_CLIP_START_MILLIS,
} from 'blocks/ClipSelect/constants';
import { ClipSuggestion } from 'blocks/ClipSelect/types';
import { Region } from './types';

export type UseDefaultSelectedRegionProps = {
  mediaDurationMillis: number | undefined;
  suggestedClips: ClipSuggestion[];
  visibleSuggestionIds: number[] | undefined;
};

export type HighlightedRegionState = {
  clipper: Region;
  transcript: Region | Record<string, never>;
};

/**
 * Hook to manage the default highlighted region state.
 *
 * When the default highlighted region changes, the corresponding edit view
 * should update to show that region as selected.
 *
 * Note that objects are used for the highlighted region state so that if the
 * same region has to be highlighed multiple times consecutively, the components will
 * see a new object each time and will update their state.
 */
export default function useDefaultHighlightedRegion({
  mediaDurationMillis = Infinity,
  suggestedClips,
  visibleSuggestionIds,
}: UseDefaultSelectedRegionProps) {
  const [prevVisibleSuggestionIds, setPrevVisibleSuggestionIds] = useState<
    number[]
  >();
  const createDefaultState = useCallback(
    () => ({
      clipper: {
        startMillis: DEFAULT_CLIP_START_MILLIS,
        endMillis: Math.min(mediaDurationMillis, DEFAULT_CLIP_END_MILLIS),
      },
      transcript: {},
    }),
    [mediaDurationMillis],
  );

  const [state, setState] = useState<HighlightedRegionState>(
    createDefaultState(),
  );

  const highlightRegion = useCallback(
    (suggestionId?: number) => {
      const clip =
        suggestionId === undefined
          ? undefined
          : suggestedClips.find(c => c.id === suggestionId);

      if (!clip) {
        setState({
          clipper: {
            startMillis: DEFAULT_CLIP_START_MILLIS,
            endMillis: Math.min(mediaDurationMillis, DEFAULT_CLIP_END_MILLIS),
          },
          transcript: {},
        });
      } else {
        const region = {
          startMillis: clip.startMillis,
          endMillis: clip.endMillis,
          suggestionId: clip.id,
        };
        setState({
          clipper: region,
          transcript: region,
        });
      }
    },
    [mediaDurationMillis, suggestedClips],
  );

  if (visibleSuggestionIds !== prevVisibleSuggestionIds) {
    setPrevVisibleSuggestionIds(visibleSuggestionIds);

    if (visibleSuggestionIds?.length === 0) {
      highlightRegion();
    }
  }

  return {
    highlightRegion,
    defaultClipperRegion: state.clipper,
    defaultTranscriptRegion: state.transcript,
  };
}

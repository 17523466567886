import React from 'react';
import ChildViewTip from '../assets/ChildViewTip';

import { CropTile, DeleteTile, ReplaceTile } from '../image-cta';
import TileGrid from '../TileGrid';
import { imageViewBlock as block } from '../utils';

export interface StaticImageChildContentsProps {}

const StaticImageChildContents: React.FC<StaticImageChildContentsProps> = () => (
  <>
    <ChildViewTip />
    <TileGrid className={block('grid')}>
      <DeleteTile />
      <CropTile />
      <ReplaceTile />
    </TileGrid>
  </>
);

export default StaticImageChildContents;

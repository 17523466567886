import cn from 'classnames';
import qs from 'query-string';
import React, { useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { EDDY_URL } from 'config';
import { tokenSelector } from 'redux/modules/auth';
import bem from 'utils/bem';
import { EddyContext, EddyElement, EddyEmbedProps } from './types';
import useClipHighlightMessaging from './useClipHighlightMessaging';
import useClipSelectMessaging from './useClipSelectMessaging';
import useLoadSuggestedClips from './useLoadSuggestedClips';
import usePauseMessaging from './usePauseMessaging';
import { useResetMessaging } from './useResetMessaging';
import useSuggestedClipsLoadedMessaging from './useSuggestedClipsLoadedMessaging';
import useTranscriptUpdateMessaging from './useTranscriptUpdateMessaging';

const block = bem('eddy-embed');

function createIframeUrl(
  projectId: string,
  accessToken?: string,
  context?: EddyContext,
  startMillis?: number,
  endMillis?: number,
  margin?: string,
) {
  const url = `${EDDY_URL}/transcript/${projectId}/embed`;

  return qs.stringifyUrl({
    url,
    query: { accessToken, context, startMillis, endMillis, margin },
  });
}

const EddyEmbed = React.forwardRef<EddyElement, EddyEmbedProps>(
  (
    {
      className,
      eddyProjectId,
      onClipSelect,
      onSuggestedClipsLoaded,
      onTranscriptUpdate,
      suggestedClips,
      context,
      margin,
      startMillis,
      endMillis,
      ...props
    },
    ref,
  ) => {
    const token = useSelector(tokenSelector);
    const [iframe, setIframe] = useState<HTMLIFrameElement | null>(null);

    useLoadSuggestedClips({ iframe, suggestedClips });
    useTranscriptUpdateMessaging({ onTranscriptUpdate });
    useClipSelectMessaging({ onClipSelect });
    useSuggestedClipsLoadedMessaging({ onSuggestedClipsLoaded });

    const { highlightClip } = useClipHighlightMessaging({
      iframe,
      suggestedClips,
    });

    const { pause } = usePauseMessaging({ iframe });
    const { reset } = useResetMessaging({ iframe });

    useImperativeHandle(
      ref,
      () => ({ focusClip: highlightClip, pause, reset }),
      [highlightClip, pause, reset],
    );

    return (
      <iframe
        className={cn(block(), className)}
        ref={setIframe}
        src={createIframeUrl(
          eddyProjectId,
          token,
          context,
          startMillis,
          endMillis,
          margin,
        )}
        {...props}
      />
    );
  },
);

export default EddyEmbed;

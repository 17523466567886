import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { ClipsPageInfo, ClipSuggestion } from 'blocks/ClipSelect/types';
import { PodcastEpisode } from 'redux/middleware/api/podcast-service';
import { dislikedGroupSuggestionIdsSelector } from 'redux/modules/clip-select';
import {
  groupClipSuggestionsEddyProjectIdSelector,
  makePodcastEpisodeSelector,
} from 'redux/modules/entities';
import { ClipSelectPageSource } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { parseSearch } from 'utils/routes';
import { ClipSelectPageLocationSearch } from 'utils/routes/types';
import {
  podcastSubscriptionSelector,
  suggestedClipsSelector,
} from './selectors';

export interface UseAutomationSuggestedClipInfoResult {
  eddyProjectId?: string;
  episodeId?: string;
  subscriptionItemId?: number;
  groupRequestId?: number;
  entireAudioInstanceId?: number;
  source?: ClipSelectPageSource;
  podcast?: PodcastEpisode['podcast'];
  episode?: PodcastEpisode;
  artwork?: string;
  aspectRatioName?: string;
  suggestedClips?: ClipSuggestion[];
  dislikedSuggestionIds?: number[];
  clipsPageInfo?: ClipsPageInfo;
}

export default function useAutomationSuggestedClipInfo(): UseAutomationSuggestedClipInfoResult {
  const location = useLocation();
  const {
    subscriptionItemId,
    groupRequestId,
    source,
  }: ClipSelectPageLocationSearch = parseSearch(location.search);

  const suggestedClips = useSelector(suggestedClipsSelector);

  const eddyProjectId = useSelector(groupClipSuggestionsEddyProjectIdSelector);

  const dislikedSuggestionIds = useSelector(dislikedGroupSuggestionIdsSelector);

  const {
    params: { episodeId },
  } = useRouteMatch<{
    episodeId?: string;
  }>();

  const subscription = useSelector(state =>
    podcastSubscriptionSelector(state, subscriptionItemId),
  );

  const episode = useSelector(
    useMemo(() => makePodcastEpisodeSelector(Number(episodeId)), [episodeId]),
  );

  const { dimension } = subscription || {};
  const artwork = episode?.thumbnailImageUrl || subscription?.thumbnailImageUrl;
  const aspectRatioName = getAspectRatioName(
    dimension?.height,
    dimension?.width,
  );
  const entireAudioInstanceId = episode?.entireAudioInstanceId;

  return {
    eddyProjectId,
    episodeId,
    subscriptionItemId,
    groupRequestId,
    entireAudioInstanceId,
    source,
    podcast: subscription,
    episode,
    suggestedClips,
    dislikedSuggestionIds,
    clipsPageInfo: {
      title: episode?.title,
      subtitle: subscription?.title,
      artwork,
      aspectRatioName,
      artworkAspectRatioName: 'square',
      entireAudioInstanceId,
      mediaDurationMillis: episode?.audioDurationMillis,
      publishedAtMillis: episode?.publishedAtMillis,
    },
  };
}

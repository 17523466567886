import {
  Button,
  Download,
  Ellipsis,
  HelpCircle,
  Menu,
  ShareIcon,
  ThemeProvider,
} from '@sparemin/blockhead';
import * as React from 'react';

import { Timeline } from 'components/icons';
import { ExtraOptionsAction } from './types';
import { block } from './utils';

interface ExtraOptionsProps {
  onDownloadClick?: () => void;
  onUploadClick?: () => void;
  onCopyAllToTimeline?: () => void;
}

const ExtraOptions: React.FC<ExtraOptionsProps> = ({
  onDownloadClick,
  onUploadClick,
  onCopyAllToTimeline,
}) => {
  const handleItemClick = React.useCallback(
    (action: ExtraOptionsAction): void => {
      switch (action) {
        case 'download':
          onDownloadClick();
          break;

        case 'upload':
          onUploadClick();
          break;

        case 'copy':
          onCopyAllToTimeline();
          break;

        case 'faq':
          window.open(
            'https://learn.headliner.app/hc/en-us/articles/360036539313',
            '_blank',
          );
          break;

        default:
      }
    },
    [onCopyAllToTimeline, onDownloadClick, onUploadClick],
  );

  return (
    <ThemeProvider theme="dark">
      <Menu
        button={
          <Button className={block('extra-options-button-trigger')}>
            <Ellipsis width="15px" />
          </Button>
        }
        offset={10}
        density="comfortable"
        placement="bottom end"
        onAction={handleItemClick}
      >
        <Menu.Item key="download" icon={<Download />}>
          Download captions
        </Menu.Item>

        <Menu.Item key="upload" icon={<ShareIcon />}>
          Upload captions
        </Menu.Item>

        <Menu.Item key="copy" icon={<Timeline />}>
          Copy to a new text track
        </Menu.Item>

        <Menu.Item key="faq" icon={<HelpCircle />}>
          See FAQs
        </Menu.Item>
      </Menu>
    </ThemeProvider>
  );
};

export default ExtraOptions;

import { useToggle } from '@sparemin/blockhead';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  CaptionsConfig,
  TextOverlayFooterExternalControls,
} from 'blocks/TextOverlayModal/v2';
import EditCaptionsModal from 'containers/EditCaptionsModal';
import { EditorVideoFramePreview } from 'containers/VideoFramePreview';
import {
  embedConfigAspectRatioSelector,
  embedConfigSelector,
} from 'redux/modules/embed/selectors';
import { ICaptions } from 'utils/embed/captions/types';

import { block } from '../../../utils';

import {
  DEFAULT_CAPTIONS_TEMPLATE_NAME,
  getCaptionsConfigFromEmbed,
  getCaptionsStyleFromEditorConfig,
  shouldEnableRechunk,
} from './utils';

interface CaptionsStyleEditorProps {
  onHide: () => void;
  onSubmit: (
    style: ICaptions['style'],
    maxChars: number,
    templateId: string,
    rechunk: boolean,
  ) => void;
  onSwitchLegacyMode: () => void;
  show: boolean;
}

const CaptionsStyleEditor: React.FunctionComponent<CaptionsStyleEditorProps> = props => {
  const { onHide, onSubmit, onSwitchLegacyMode, show } = props;

  const config = useSelector(embedConfigSelector);
  const aspectRatio = useSelector(embedConfigAspectRatioSelector);

  const {
    toggle: handleToggleCaptionsTimesLocked,
    value: captionsTimesLocked,
  } = useToggle(false);

  const captionsOverride = React.useMemo(
    () => getCaptionsConfigFromEmbed(config?.embedConfig?.captions),
    [config],
  );

  const handleSubmit = React.useCallback(
    (
      captionsConfig: CaptionsConfig,
      maxChars: number,
      hasBeenResized: boolean,
    ): void => {
      const style = getCaptionsStyleFromEditorConfig(captionsConfig);
      const shouldRechunk = shouldEnableRechunk(
        hasBeenResized,
        captionsTimesLocked,
      );
      onSubmit(style, maxChars, DEFAULT_CAPTIONS_TEMPLATE_NAME, shouldRechunk);
    },
    [captionsTimesLocked, onSubmit],
  );

  return (
    <EditCaptionsModal
      aspectRatio={aspectRatio}
      background={({ viewport }) => (
        <EditorVideoFramePreview
          // This preview component does not re render when the viewport
          // size is changed by a browser viewport size change.
          // This is not an event likely to happen in practise, but it is
          // good to support it.
          key={`${viewport.height}-${viewport.width}`}
          aspectRatio={aspectRatio}
          canvasDimensions={viewport}
          className={block('video-frame-preview')}
          backgroundFor={{
            type: 'captions',
          }}
        />
      )}
      captionsOverride={captionsOverride}
      animationsTabEnabled
      footerLeftActions={
        <TextOverlayFooterExternalControls.CaptionsTimesLock
          locked={captionsTimesLocked}
          onChange={handleToggleCaptionsTimesLocked}
        />
      }
      isCompatibilityMode
      onCancel={onHide}
      onSubmit={handleSubmit}
      onSwitchToLegacy={onSwitchLegacyMode}
      show={show}
      showRechunkAlert={!captionsTimesLocked}
    />
  );
};

export default CaptionsStyleEditor;

import { Button, Spacer, Text } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';

import AccountSelect from 'components/AccountSelect';
import AutomationPostModalHeader from 'components/AutomationPostModalHeader';

import DynamicElementsPopper from 'components/DynamicElementsPopper';
import EducationalCard from 'components/EducationalCard/EducationalCard';
import ModalContentWithMedia from 'components/Modal/ModalContentWithMedia';
import { Overrides } from 'components/Select';
import ShareFormTextarea from 'components/ShareForm/ShareFormTextarea';
import { Step } from 'components/SteppedModal';
import {
  captionsMetadata,
  MAX_CHARS_CAPTIONS,
} from 'containers/FacebookAutomationModal/constants';

import PageOption from 'containers/FacebookPostModal/FacebookPageSelector/PageOption';
import {
  FacebookPageOption,
  Option,
} from 'containers/FacebookPostModal/FacebookPageSelector/types';
import { block } from '../../utils';
import { FacebookAutomationModalStep } from '../types';
import { UsePostComposerBaseConfig } from './types';

export interface UsePostComposerStepConfig extends UsePostComposerBaseConfig {
  description: string;
  allowSubmit: boolean;
  facebookPageId: string;
  pages: FacebookPageOption[];
  onDescriptionChange: (newDescription: string) => void;
  onHideModal?: () => void;
  onPageChange?: (newUserId: string) => void;
  onSubmit?: () => void;
  onSwitchAccountClick?: () => void;
}

const selectComponentOverrides: Overrides<FacebookPageOption> = {
  control: {
    props: p => ({
      ...p,
      className: cn(p.className, block('control')),
    }),
  },
  option: {
    component: props => <PageOption {...props} />,
  },
};

export default function usePostComposerStep({
  description,
  allowSubmit,
  facebookPageId,
  pages,
  onDescriptionChange,
  onHideModal,
  onPageChange,
  onSubmit,
  onSwitchAccountClick,
}: UsePostComposerStepConfig): Step<FacebookAutomationModalStep> {
  return {
    id: 'composer',
    hideFooter: true,
    replaceBody: true,
    component: (
      <>
        <ModalContentWithMedia
          className="composer-step-content"
          onClose={onHideModal}
        >
          <ModalContentWithMedia.ModalContentWithMediaColumn>
            <img
              src="https://static.sparemin.com/static/webapp-assets/auto-posting-detail/auto-posting-detail-facebook-image-a-202407311738.png"
              className={block('composer-image')}
            />
          </ModalContentWithMedia.ModalContentWithMediaColumn>

          <ModalContentWithMedia.ModalContentWithMediaColumn className="composer-content-column">
            <Spacer space="20px" orientation="vertical" align="flex-start">
              <AccountSelect
                options={pages}
                overrides={selectComponentOverrides}
                // The pages list contains the same values as those returned by
                // `facebookPagesSelector`, but with different property names
                // assigned to accommodate the Select component. The `value`
                // property corresponds to the selected facebook page id.
                value={pages.find(({ value }) => value === facebookPageId)}
                className={block('accounts-list')}
                onChange={(val: Option) => onPageChange(val.value)}
                onSwitchAccountSelect={onSwitchAccountClick}
              />
              <AutomationPostModalHeader
                title="Let’s set up your video captions"
                description="We'll post these captions with each video. You can pull information from your RSS feed, such as episode titles, to make each post unique."
              />
              <ShareFormTextarea
                hideProgress
                type="textarea"
                maxChars={MAX_CHARS_CAPTIONS}
                value={description}
                className={block('description-container')}
                controlClassName={block('description-input')}
                label={
                  <Text variant="label">
                    <b>Example of your captions</b> (up to {MAX_CHARS_CAPTIONS}{' '}
                    characters)
                  </Text>
                }
                onChange={onDescriptionChange}
              />
              <EducationalCard>
                <DynamicElementsPopper
                  buttonContent="add content from your rss"
                  elementsList={captionsMetadata}
                  onClick={metaValue =>
                    onDescriptionChange(`${description} ${metaValue}`)
                  }
                />
              </EducationalCard>
            </Spacer>

            <Button
              fluid
              variant="solid"
              onPress={onSubmit}
              isDisabled={!allowSubmit}
            >
              confirm auto-posting details
            </Button>
          </ModalContentWithMedia.ModalContentWithMediaColumn>
        </ModalContentWithMedia>
      </>
    ),
  };
}

import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { loggedInSelector } from 'redux/modules/auth';

import { isFreeSelector } from 'redux/modules/pricing';
import { SocialPostCaptionInfoPlatform } from 'types';
import usePrevious from './usePrevious';
import useSocialPostCaptions from './useSocialPostCaptions';

export interface UsePostModalDescriptionConfig {
  platform: SocialPostCaptionInfoPlatform;
  defaultDescription: string;
}

export interface UsePostModalDescriptionResult {
  description: string;
  setDescription: (newDescription: string) => void;
  resetDescription: () => void;
}

export default function usePostModalDescription({
  platform,
  defaultDescription,
}: UsePostModalDescriptionConfig): UsePostModalDescriptionResult {
  const {
    socialPostCaptionInfoByPlatform,
    canGenerateSocialPostCaptions,
  } = useSocialPostCaptions();

  const socialPostCaption =
    socialPostCaptionInfoByPlatform?.[platform]?.postText;

  const isFree = useSelector(isFreeSelector);
  const isAuthenticated = useSelector(loggedInSelector);

  const prevSocialPostCaption = usePrevious(socialPostCaption);
  const prevIsFree = usePrevious(isFree);
  const shouldUseDefaultDescription =
    isFree ||
    !isAuthenticated ||
    !socialPostCaption ||
    !canGenerateSocialPostCaptions;

  const [description, setDescription] = useState(
    shouldUseDefaultDescription ? defaultDescription : socialPostCaption,
  );

  const resetDescription = useCallback((): void => {
    setDescription(
      shouldUseDefaultDescription ? defaultDescription : socialPostCaption,
    );
  }, [defaultDescription, shouldUseDefaultDescription, socialPostCaption]);

  useEffect(() => {
    if (
      (prevIsFree && !isFree && socialPostCaption) ||
      (!prevSocialPostCaption && socialPostCaption)
    ) {
      setDescription(socialPostCaption);
    }
  }, [
    defaultDescription,
    description,
    isFree,
    prevIsFree,
    prevSocialPostCaption,
    socialPostCaption,
  ]);

  return {
    description,
    setDescription,
    resetDescription,
  };
}

import cn from 'classnames';
import React from 'react';
import { headerBlock as block } from '../../utils';
import { useClipSelect } from '../ClipSelectContext';
import { useClipSelectNavigation } from '../ClipSelectNavigationContext';
import BackToSuggestionsButton from './BackToSuggestionsButton';
import EditClipViewSelector from './EditClipViewSelector';
import HeaderContainer from './HeaderContainer';
import HeaderPodcastInfo from './HeaderPodcastInfo';

export type EditClipHeaderProps = {
  className?: string;
};

const EditClipHeader: React.FC<EditClipHeaderProps> = ({ className }) => {
  const { suggestionIds } = useClipSelect();
  const [navState] = useClipSelectNavigation();

  // every project will have a waveform view, but only some projects will have
  // a transcript
  const hasTranscriptView = navState.context.hasTranscript;

  return (
    <HeaderContainer className={cn(block('edit'), className)}>
      {suggestionIds.length > 0 ? (
        <BackToSuggestionsButton />
      ) : (
        <HeaderPodcastInfo />
      )}
      {hasTranscriptView && (
        <EditClipViewSelector className={block('edit-view-toggle')} />
      )}
    </HeaderContainer>
  );
};

export default EditClipHeader;

import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import { SPAREMIN_SERVICES_IMAGE_URL } from 'config';
import { createRequest } from '../utils';

import * as types from './types';

const imageSchema = new schema.Entity(
  'images',
  {},
  {
    idAttribute: image => image.id,
  },
);

const uploadImage = (
  args: types.UploadImageArgs,
  request: SuperAgentStatic,
): Promise<types.UploadImageResult> => {
  const [imageData] = args;

  return request
    .put('/api/v1/image/background')
    .send({ imageData })
    .then(res => normalize(res.body, imageSchema));
};

const uploadImageBlob = (
  args: types.UploadImageBlobArgs,
  request: SuperAgentStatic,
  userId?: number,
): Promise<types.UploadImageResult> => {
  const [imageBlob] = args;
  const formData = new FormData();
  formData.append('imageFile', imageBlob);

  return request
    .put('/api/v1/image/background/blob')
    .query({ userId })
    .send(formData)
    .then(res => normalize(res.body, imageSchema));
};

const getDataUrl = (
  args: types.GetDataUrlArgs,
  request: SuperAgentStatic,
): Promise<types.GetDataUrlResult> => {
  const [url, maxWidth, maxHeight] = args;

  return request
    .get('/api/v1/image/dataUrl')
    .query({ url, maxWidth, maxHeight })
    .then(res => ({
      dataUrl: res.body.dataUrl,
    }));
};

export const handle: types.IHandle = (
  method: types.ServiceMethod,
  args: any,
  token?: string,
  userId?: number,
): Promise<any> => {
  const request = createRequest({
    token,
    baseUrl: SPAREMIN_SERVICES_IMAGE_URL,
    omitBearerPrefix: true,
  });

  switch (method) {
    case types.ServiceMethod.UPLOAD_IMAGE:
      return uploadImage(args, request);

    case types.ServiceMethod.UPLOAD_IMAGE_BLOB:
      return uploadImageBlob(args, request, userId);

    case types.ServiceMethod.GET_DATA_URL:
      return getDataUrl(args, request);

    default:
      throw new Error(`${types.ACTION_KEY} cannot handle method ${method}`);
  }
};

import { Card, Spacer, Switch, Text } from '@sparemin/blockhead';
import React from 'react';

import { block } from './utils';

export interface ControlToggleProps {
  description?: string;
  icon: React.ReactNode;
  id?: string;
  label: string;
  isActive: boolean;
  onChange: (isActive: boolean) => void;
}

const DEFAULT_ID = 'control-toggle';

const ControlToggle: React.FC<ControlToggleProps> = props => {
  const {
    description,
    icon,
    id = DEFAULT_ID,
    label,
    isActive,
    onChange,
  } = props;

  const controlId = `${id}-switch`;
  const labelId = `${id}-label`;

  return (
    <Card className={block('control-toggle')} header={<></>} footer={<></>}>
      <div className={block('control-toggle-content')}>
        <div className={block('control-toggle-top-row')}>
          <Spacer
            orientation="horizontal"
            space="10px"
            align="center"
            justify="center"
          >
            <div className={block('control-toggle-icon-wrapper')}>{icon}</div>
            <Text
              id={labelId}
              as="label"
              className={block('control-toggle-label')}
              htmlFor={controlId}
              variant="label"
            >
              {label}
            </Text>
          </Spacer>

          <Switch
            id={controlId}
            as="div"
            aria-labelledby={labelId}
            isSelected={isActive}
            className={block('toggle')}
            onChange={onChange}
          />
        </div>
        {description && (
          <span id={labelId} className={block('control-toggle-description')}>
            {description}
          </span>
        )}
      </div>
    </Card>
  );
};

export default ControlToggle;

import * as React from 'react';
import { noop } from 'underscore';

import BlurToggle from 'components/BlurToggle';
import { ButtonField, DurationField } from 'components/Form';
import { getValue } from 'utils/collections';
import { VideoScaling } from 'utils/embed/video';
import VideoOptionsField from './VideoOptionsField';
import VideoOptionsSection from './VideoOptionsSection';
import VideoResizeField from './VideoResizeField';
import VideoTransitionField from './VideoTransitionField';

interface IBounds {
  lower?: number;
  upper?: number;
}

interface IOnChange {
  (value: boolean, field: 'blurredBackground'): void;
  (value: VideoScaling, field: 'resize'): void;
  (value: string, field: 'transitionIn' | 'transitionOut'): void;
  (value: number, field: 'startMillis' | 'endMillis'): void;
}

interface IProps {
  blurredBackground?: boolean;
  endMillis: number;
  endMillisBounds?: IBounds;
  onChange?: IOnChange;
  onAdjustCrop?: () => void;
  resize?: VideoScaling;
  showTransformOptions?: boolean;
  startMillis: number;
  startMillisBounds?: IBounds;
  transitionIn: string;
  transitionOut: string;
}

export default class VideoSection extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onChange: noop,
  };

  private handleStartTimeChange = (millis: number) =>
    this.props.onChange(millis, 'startMillis');

  private handleEndTimeChange = (millis: number) =>
    this.props.onChange(millis, 'endMillis');

  private handleTransitionInChange = (val: string) =>
    this.props.onChange(val, 'transitionIn');

  private handleTransitionOutChange = (val: string) =>
    this.props.onChange(val, 'transitionOut');

  private handleResizeChange = (val: VideoScaling) =>
    this.props.onChange(val, 'resize');

  private handleBlurredBackgroundChange = (enabled: boolean) =>
    this.props.onChange(enabled, 'blurredBackground');

  public render() {
    const {
      blurredBackground,
      endMillis,
      endMillisBounds,
      resize,
      showTransformOptions,
      startMillis,
      startMillisBounds,
      transitionIn,
      transitionOut,
      onAdjustCrop,
    } = this.props;

    return (
      <VideoOptionsSection title="Video">
        <VideoOptionsField>
          <DurationField
            label="Start Time"
            maxMillis={getValue(startMillisBounds, 'upper')}
            value={startMillis}
            minMillis={getValue(startMillisBounds, 'lower')}
            onChange={this.handleStartTimeChange}
          />
        </VideoOptionsField>
        <VideoOptionsField>
          <DurationField
            label="End Time"
            maxMillis={getValue(endMillisBounds, 'upper')}
            value={endMillis}
            minMillis={getValue(endMillisBounds, 'lower')}
            onChange={this.handleEndTimeChange}
          />
        </VideoOptionsField>
        <VideoTransitionField
          label="Entry Transition"
          value={transitionIn}
          onChange={this.handleTransitionInChange}
        />
        <VideoTransitionField
          label="Exit Transition"
          value={transitionOut}
          onChange={this.handleTransitionOutChange}
        />
        {showTransformOptions && (
          <VideoResizeField value={resize} onChange={this.handleResizeChange} />
        )}

        {showTransformOptions && (
          <>
            <VideoOptionsField>
              <ButtonField label="Crop" theme="submit" onClick={onAdjustCrop}>
                adjust crop
              </ButtonField>
            </VideoOptionsField>
            <VideoOptionsField sm={12}>
              <BlurToggle
                isActive={blurredBackground}
                onChange={this.handleBlurredBackgroundChange}
              />
            </VideoOptionsField>
          </>
        )}
      </VideoOptionsSection>
    );
  }
}

export { IOnChange as OnVideoOptionChange };
export { IProps as VideoSectionProps };

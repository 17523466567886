import { Button } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useClipSelect } from '../ClipSelectContext';
import { useClipSelectTracking } from '../ClipSelectTrackingContext';
import HeaderItem from './HeaderItem';

type HeaderSelectClipButtonProps = {};

const HeaderSelectClipButton: React.FC<HeaderSelectClipButtonProps> = () => {
  const { onCustom } = useClipSelect();
  const { onSelectOwnClip } = useClipSelectTracking();

  const handleButtonClick = useCallback(() => {
    onCustom();
    onSelectOwnClip();
  }, [onCustom, onSelectOwnClip]);

  return (
    <HeaderItem>
      <Button onPress={handleButtonClick} size="small" variant="ghost">
        select your own clip
      </Button>
    </HeaderItem>
  );
};
export default HeaderSelectClipButton;

import dayjs from 'dayjs';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import ids from 'short-id';
import _ from 'underscore';

import { embedLoadingSelector } from '../embed/selectors';
import { exportInProgressSelector } from '../embed-export/selectors';
import { projectsCountSelector } from '../project/selectors';

export const notificationsSelector = state =>
  state.getIn(['notification', 'notifications']);
export const notificationsByIdSelector = state =>
  state.getIn(['notification', 'notificationsById']);
export const showedIntroSelector = state =>
  state.getIn(['notification', 'showedIntro']);

export const showExportNotificationSelector = createSelector(
  [embedLoadingSelector, exportInProgressSelector],
  (isLoading, inProgress) =>
    /*
     * return false if `isLoading` is either true or undefined.  before `isLoading` gets set to
     * true on page load, we're not sure if the notification is needed and messing up this
     * conditional can lead the notification showing multiple times
     */
    isLoading === false && inProgress,
);

const exportNotificationSelector = createSelector(
  [showExportNotificationSelector],
  show => {
    if (!show) return undefined;

    return Immutable.Map({
      level: 'info',
      dismissAfterSec: 0,
      type: 'export',
      id: ids.generate(),
      ts: dayjs().unix(),
    });
  },
);

export const mapStateToNotifications = createSelector(
  [
    notificationsSelector,
    notificationsByIdSelector,
    exportNotificationSelector,
  ],
  (notifications, notificationsById, exportNotification) => {
    const notificationList = notifications || Immutable.List();
    const notificationMap = notificationsById || Immutable.Map();

    const exportList = !exportNotification
      ? Immutable.List()
      : Immutable.List([exportNotification.get('id')]);

    const exportMap = !exportNotification
      ? Immutable.Map()
      : Immutable.Map({
          [exportNotification.get('id')]: exportNotification,
        });

    return Immutable.Map({
      ids: exportList.concat(notificationList).toSet(),
      data: exportMap.merge(notificationMap),
    });
  },
);

export const shouldShowIntroSelector = createSelector(
  [showedIntroSelector, projectsCountSelector],
  (showedIntro, projectsCount) => {
    if (showedIntro || _.isUndefined(projectsCount)) return false;

    return projectsCount === 0;
  },
);

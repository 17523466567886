import { useCallback } from 'react';
import {
  useMessageDispatch,
  UseMessageDispatchProps,
} from './useEddyMessaging';

export type UsePauseMessagingProps = Pick<UseMessageDispatchProps, 'iframe'>;

export default function usePauseMessaging({ iframe }: UsePauseMessagingProps) {
  const dispatch = useMessageDispatch({ iframe });

  const pause = useCallback(() => {
    dispatch({
      type: 'pause',
    });
  }, [dispatch]);

  return { pause };
}
